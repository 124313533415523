define("affinio/components/modules/delta-card-gallery", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Element
    classNames: ['delta-card-gallery'],
    tagName: 'div',
    modalManager: Ember.inject.service(),
    actions: {
      openFocusModal: function openFocusModal(delta) {
        Ember.get(this, 'modalManager').openModal('modals/modal-focus', {
          label: Ember.get(delta, 'label'),
          metric: Ember.get(delta, 'type'),
          cluster: Ember.get(this, 'model.cluster'),
          defaultView: 'examples',
          report: Ember.get(this, 'model.report')
        });
      }
    }
  });
});