define("affinio/models/clusters/pinterest", ["exports", "affinio/models/clusters/-base", "affinio/mixins/models/breakdown-overlap"], function (_exports, _base, _breakdownOverlap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _base.default.extend(_breakdownOverlap.default, {
    interests: Ember.computed('_interests.loaded', 'numberOfMembers.response.value', 'report.{numberOfMembers.response.value,percentTotal.response.value}', function () {
      var interests = Ember.get(this, '_interests.response');
      if (interests && Ember.isArray(interests)) {
        // tempfix: scores over 100. While the backend issue is being solved, limit on front-end.
        // this uses the same logic that cross-tribe uses.
        var fl = null;
        var maxUser;
        if (interests.objectAt(0) && Ember.get(interests.objectAt(0), 'relevance') > 100) {
          var percentTotal = Ember.get(this, 'report.percentTotal.response.value');
          var termOffset = 100 / percentTotal;
          var numberOfMembers = Ember.get(this, 'isAggregate') ? Ember.get(this, 'report.numberOfMembers.response.value') : Ember.get(this, 'numberOfMembers.response.value');
          maxUser = interests.objectAt(0);
          fl = Ember.get(maxUser, 'score') / (Math.log(732574190.0 / (Ember.get(maxUser, 'followersCount') + 1)) + 1) * termOffset / numberOfMembers;
          fl = fl < 1.01 && fl > 0.99 ? 1 : fl;
        }
        return {
          label: 'interests',
          response: interests.map(function (obj) {
            if (obj.tags && !obj.tags.length) {
              obj.tags.pushObject('Uncategorized');
            }
            if (fl) {
              Ember.set(obj, 'relevance', Ember.get(obj, 'score') / Ember.get(maxUser, 'score') * 100 * fl); // eslint-disable-line ember/no-side-effects
            }
            return obj;
          }),
          loaded: Ember.get(this, '_interests.loaded'),
          loading: Ember.get(this, '_interests.loading')
        };
      } else {
        return {
          label: 'interests',
          response: [],
          loaded: Ember.get(this, '_interests.loaded'),
          loading: Ember.get(this, '_interests.loading')
        };
      }
    })
  });
});