define("affinio/templates/components/network-graph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "CkKYCrJF",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"svgEl\",\"isFulfilled\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"html-safe\",[[23,[\"svgEl\",\"content\",\"outerHTML\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[21,\"loading-spinner\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/network-graph.hbs"
    }
  });
});