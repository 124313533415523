define("affinio/components/record-block", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Element
    classNames: ['record-block'],
    tagName: 'span',
    attributeBindings: ['cluster:data-cluster', 'colorOrder:data-color-order', 'record.normalizedPercentage:data-normalized-percentage', 'record.normalizedOffset:data-normalized-offset', 'record.metricNormalizedOffset:data-metric-normalized-offset', 'record.rawOffset:data-raw-offset', 'style', 'record.value:title'],
    cluster: Ember.computed.alias('record.cluster'),
    style: Ember.computed.alias('record.style'),
    colorOrder: Ember.computed.alias('record.colorOrder')
  });
});