define("affinio/templates/components/histo-gram", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "6fyGsCSj",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[21,\"graph\"],false],[0,\"\\n\"],[1,[21,\"axis\"],false],[0,\"\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/histo-gram.hbs"
    }
  });
});