define("affinio/templates/components/embedded-media", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "xvoroM0o",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"iframe\"],[12,\"src\",[21,\"modifiedURL\"]],[11,\"frameborder\",\"0\"],[11,\"scrolling\",\"no\"],[9],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"metric-visualization\"],[9],[0,\"\\n  \"],[1,[27,\"metric-instance\",null,[[\"model\",\"singleBar\",\"instance\",\"records\",\"nomenclature\",\"normalized\"],[[23,[\"model\"]],true,[23,[\"instance\"]],[27,\"map-get\",[[23,[\"recordsMap\"]],[23,[\"instance\",\"label\"]]],null],\"shares\",\"cluster-bias\"]]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/embedded-media.hbs"
    }
  });
});