define("affinio/components/instagram-post", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Element
    classNames: ['instagram-post', 'insta-frame'],
    modifiedURL: Ember.computed('instance', function () {
      if (Ember.get(this, 'instance')) {
        var link = Ember.get(this, 'instance.link');
        return link.indexOf('embed') > -1 ? link : "".concat(link, "embed/");
      } else {
        return '';
      }
    })
  });
});