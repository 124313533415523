define("affinio/routes/settings/benchmarks", ["exports", "affinio/mixins/document-title", "affinio/utils/queries"], function (_exports, _documentTitle, _queries) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_documentTitle.default, {
    // Mixin Dependencies
    documentTitle: 'Benchmarks - Settings - Affinio',
    // Services
    graph: Ember.inject.service(),
    user: Ember.inject.service(),
    path: Ember.inject.service(),
    model: function model() {
      var _this = this;
      // Its not an expensive fetch, and will keep people up to date on company benchmarks -JK Jun 2017
      return Ember.get(this, 'graph').query(_queries.default.myBenchmarks, false).then(function () {
        return Ember.Object.create({
          account: Ember.get(_this, 'user.account.assetGroups'),
          company: Ember.get(_this, 'user.company.assetGroups')
        });
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      Ember.set(controller, 'user', Ember.get(this, 'user'));
    }
  });
});