define("affinio/services/app", ["exports", "affinio/config/environment", "affinio/utils/queries"], function (_exports, _environment, _queries) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    graph: Ember.inject.service(),
    store: Ember.inject.service(),
    ENV: _environment.default.APP,
    environment: _environment.default.environment,
    allOutages: Ember.computed(function () {
      return Ember.get(this, 'store').peekAll('outage-info');
    }),
    outageInfo: Ember.computed('allOutages.[]', function () {
      var allOutages = Ember.get(this, 'allOutages').rejectBy('dateCompleted').sort(function (a, b) {
        return Ember.get(b, 'dateCreated') - Ember.get(a, 'dateCreated');
      });
      if (allOutages.length) {
        return allOutages[0];
      } else {
        return null;
      }
    }),
    loadOutageInfo: function loadOutageInfo() {
      return Ember.get(this, 'graph').query(_queries.default.outageInfo);
    }
  });
});