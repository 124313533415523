define("affinio/services/admin", ["exports", "moment", "affinio/utils/queries", "affinio/utils/get-with-default"], function (_exports, _moment, _queries, _getWithDefault) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  var _default = _exports.default = Ember.Service.extend({
    graph: Ember.inject.service(),
    store: Ember.inject.service(),
    tracker: Ember.inject.service(),
    loadPaginatedReports: function loadPaginatedReports(id, fetchMap, type) {
      var _this = this;
      var page = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
      var size = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 25;
      var graph = Ember.get(this, 'graph');
      var query = _queries.default.combinedReports(id, fetchMap, type, page, size);
      if (!query) {
        return;
      }
      var pageStartTime = new Date().getTime();
      return graph.query(query, true).then(function (response) {
        var newFetchMap = {
          pinterest: false,
          twitter: false,
          instagram: false,
          custom: false
        };
        Object.keys(query.fetchMap).forEach(function (key) {
          if (query.fetchMap[key]) {
            newFetchMap[key] = response.data[type]["paginated".concat(key.capitalize(), "Reports")].length === size;
          }
        });
        return graph.setupQueryResponse(Ember.RSVP.resolve(response), query).then(function (response) {
          var timeTaken = (0, _moment.default)().diff(pageStartTime) / 1000;
          var reportCount = Object.keys(response).map(function (key) {
            return response[key].length;
          }).reduce(function (a, b) {
            return a + b;
          }, 0);
          Ember.get(_this, 'tracker').track('admin report pagination page loaded', {
            id: id,
            type: type,
            page: page,
            timeTaken: timeTaken,
            reportCount: reportCount
          });
          return _this.loadPaginatedReports(id, newFetchMap, type, page + 1, size);
        });
      });
    },
    getReportsForEntity: function getReportsForEntity(id, type) {
      var key = '';
      if (type === 'account') {
        key = 'accounts';
      } else if (type === 'company') {
        key = 'companies';
      } else {
        throw new Error('unsupported type for getReportsForEntity');
      }
      var store = Ember.get(this, 'store');
      var allReports = store.peekAll('taxonomy').map(function (taxo) {
        return store.peekAll("reports/".concat(taxo.id.toLowerCase())).toArray();
      }).flatten().rejectBy('state', 'failed');
      var customReports = store.peekAll('reports/custom').toArray().rejectBy('state', 'failed');
      return [].concat(_toConsumableArray(allReports), _toConsumableArray(customReports)).filter(function (report) {
        return (0, _getWithDefault.default)(report, key, []).mapBy('id').includes(id);
      });
    }
  });
});