define("affinio/routes/settings", ["exports", "affinio/mixins/auth-check"], function (_exports, _authCheck) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_authCheck.default, {
    loggedInOnly: true,
    user: Ember.inject.service(),
    app: Ember.inject.service(),
    model: function model() {
      return {
        customFeatures: Ember.get(this, 'app.ENV.features.custom'),
        twitterFeatures: Ember.get(this, 'app.ENV.features.twitterReports')
      };
    }
  });
});