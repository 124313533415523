define("affinio/templates/aton", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "snWWyyyq",
    "block": "{\"symbols\":[],\"statements\":[[1,[21,\"top-level-nav\"],false],[0,\"\\n\"],[7,\"nav\"],[11,\"class\",\"sub-nav\"],[9],[0,\"\\n  \"],[4,\"link-to\",[\"aton.about\"],null,{\"statements\":[[0,\"About\"]],\"parameters\":[]},null],[0,\"\\n  \"],[4,\"link-to\",[\"aton.sources\"],null,{\"statements\":[[0,\"Data Sources\"]],\"parameters\":[]},null],[0,\"\\n  \"],[4,\"link-to\",[\"aton.taxonomy\"],null,{\"statements\":[[0,\"Taxonomy\"]],\"parameters\":[]},null],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"section\"],[11,\"id\",\"overview\"],[11,\"class\",\"clearfix has-sub-nav\"],[9],[0,\"\\n  \"],[1,[21,\"outlet\"],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/aton.hbs"
    }
  });
});