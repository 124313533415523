define("affinio/components/old/vote-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['vote-box'],
    classNameBindings: ['shown'],
    attributeBindings: ['style'],
    wordSource: '',
    style: Ember.computed('target', function () {
      var target = Ember.get(this, 'target');
      if (target) {
        return Ember.String.htmlSafe("left: ".concat(Ember.$(target).position().left - 10, "px; top: ").concat(Ember.$(target).position().top - 40, "px;"));
      }
    }),
    actions: {
      upvote: function upvote(word, wordSource) {
        if (window.getSelection().toString().trim().length) {
          word.label = window.getSelection().toString().trim();
        }
        var upvote = Ember.get(this, 'upvote');
        if (upvote && typeof upvote === 'function') {
          upvote(word, wordSource);
        }
      },
      downvote: function downvote(word, wordSource) {
        if (window.getSelection().toString().trim().length) {
          word.label = window.getSelection().toString().trim();
        }
        var downvote = Ember.get(this, 'downvote');
        if (downvote && typeof downvote === 'function') {
          downvote(word, wordSource);
        }
      }
    }
  });
});