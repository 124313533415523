define("affinio/services/path", ["exports", "moment", "affinio/utils/get-with-default"], function (_exports, _moment, _getWithDefault) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    user: Ember.inject.service(),
    tracker: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      Ember.set(this, 'pathTimeStorage', new Map());
    },
    currentPath: null,
    // set at application controller
    currentCategory: null,
    // set at reports.report.version.cluster.category.subcategory route model
    currentSubcategory: null,
    // set at reports.report.version.cluster.category.subcategory route model

    currentReportId: null,
    // set at reports.report route model / deactivate
    currentVersion: null,
    // set at reports.report.version route model
    currentClusterId: null,
    // set at reports.report.version.cluster route model
    currentClusterSuffix: null,
    // set at reports.report.version.cluster route model
    currentClusterIndex: null,
    // set at reports.report.version.cluster route model

    // TODO: need to handle edge cases for currentTab outside of metrics routes.
    // See https://app.clubhouse.io/affinio/story/27682/handle-edge-cases-for-path-currenttab

    currentTab: Ember.computed('currentPath', 'currentSubcategory', function () {
      var tab = (0, _getWithDefault.default)(this, 'currentPath', '').split('.').pop();
      if (tab === 'subcategory') {
        return Ember.get(this, 'currentSubcategory');
      }
      return tab;
    }),
    inReport: Ember.computed.notEmpty('currentReportId'),
    inCluster: Ember.computed('currentClusterSuffix', function () {
      var suffix = Ember.get(this, 'currentClusterSuffix');
      var aggregateSuffixes = ['all', 'group'];
      return !aggregateSuffixes.includes(suffix);
    }),
    // Timing how long they are in paths
    pathTimeStorage: null,
    // js Map
    exitingPath: null,
    currentPathClass: Ember.computed('currentPath', 'currentSubcategory', function () {
      var currentPathClass = Ember.get(this, 'exitingPath');
      return currentPathClass.replace('.category.subcategory', ".".concat(Ember.get(this, 'currentCategory'), ".").concat(Ember.get(this, 'currentSubcategory'))).trim();
    }),
    pathTimeWatcher: Ember.observer('currentPath', 'currentReportId', 'currentClusterId', 'currentSubcategory', function () {
      var currentPath = Ember.get(this, 'currentPath') ? Ember.get(this, 'currentPath').replace('.category.subcategory', ".".concat(Ember.get(this, 'currentCategory'), ".").concat(Ember.get(this, 'currentSubcategory'))).trim() : '';
      var pathTimeStorage = Ember.get(this, 'pathTimeStorage');
      var exitingPath = Ember.get(this, 'exitingPath');
      if (currentPath) {
        if (pathTimeStorage.has(exitingPath)) {
          /* eslint-disable */
          // jsmap
          var timeThen = pathTimeStorage.get(exitingPath);
          /* eslint-enable */
          var timeAgo = (0, _moment.default)().diff(timeThen);
          var secondsAgo = (0, _moment.default)().diff(timeThen, 'seconds');
          var userEmail = Ember.get(this, 'user.email');
          Ember.get(this, 'tracker').track('Page Exited', {
            exitedPath: exitingPath,
            enteredPath: currentPath,
            timeOnPage: timeAgo,
            secondsOnPage: secondsAgo,
            inTribe: Ember.get(this, 'inCluster'),
            // TODO PHIL should this be inCluster going to woopra now
            userTime: "".concat(userEmail, "@@@").concat(secondsAgo),
            userPathTime: "".concat(userEmail, "@@@").concat(exitingPath, "@@@").concat(secondsAgo)
          });
        }
        if (!pathTimeStorage.has(currentPath)) {
          /* eslint-disable */
          // jsmap
          pathTimeStorage.set(currentPath, new Date().getTime());
          /* eslint-enable */
        }
      }
      pathTimeStorage.delete(exitingPath);
      Ember.set(this, 'exitingPath', currentPath);
    })
  });
});