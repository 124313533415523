define("affinio/components/spark-line", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['spark-line'],
    classNameBindings: ['affectedTerm'],
    weirdnessOff: false,
    timeBased: false,
    showPoints: false,
    showY: false,
    showX: false,
    affectedTerm: Ember.computed(function () {
      return "term-".concat(Ember.get(this, 'term').replace('#', '').replace('@', '').replace(/\W/g, ''));
    }),
    timesObserver: Ember.observer('times', function () {
      var isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
      if (!isFirefox) {
        this.send('draw');
      }
    }),
    theCounts: Ember.computed.map('times', function (obj /* ,iter*/) {
      var weird = Ember.get(this, 'weirdnessOff'); // lol wat - js
      if (!weird) {
        return Math.round(obj.value / _.reduce(_.pluck(Ember.get(this, 'times'), 'value'), function (memo, num) {
          return memo + num;
        }) * Ember.get(this, 'record.value')); // math.random()*0.01 to prevent zeroes
      } else {
        return Math.round((obj.value / _.reduce(_.pluck(Ember.get(this, 'times'), 'value'), function (memo, num) {
          return memo + num;
        })).toFixed(4) * Ember.get(this, 'record.value')); // math.random()*0.01 to prevent zeroes
      }
    }),
    theDates: Ember.computed.map('times', function (time) {
      var theDate = time.date.toString();
      return new Date(theDate);
    }),
    valueRange: Ember.computed('', function () {
      var min = _.min(Ember.get(this, 'theCounts').slice(1, Ember.get(this, 'theCounts.length'))).value;
      var max = _.max(Ember.get(this, 'theCounts').slice(1, Ember.get(this, 'theCounts.length'))).value;
      return {
        min: {
          value: min,
          instances: Ember.get(this, 'theCounts').slice(1, Ember.get(this, 'theCounts.length')).filter(function (data) {
            return data.value === min;
          })
        },
        max: {
          value: max,
          instances: Ember.get(this, 'theCounts').slice(1, Ember.get(this, 'theCounts.length')).filter(function (data) {
            return data.value === max;
          })
        }
      };
    }),
    min: Ember.computed('theCounts', function () {
      return _.min(Ember.get(this, 'theCounts').slice(1, Ember.get(this, 'theCounts.length')));
    }),
    max: Ember.computed('theCounts', function () {
      return _.max(Ember.get(this, 'theCounts').slice(1, Ember.get(this, 'theCounts.length')));
    }),
    didInsertElement: function didInsertElement() {
      this.send('draw');

      // bullshit code to make the dots not be clipped and then to overlay them ontop of the chart - js
      // https://stackoverflow.com/a/32476581
      if (Ember.get(this, 'showPoints')) {
        var chartLayer = d3.select(this.$('.c3-chart')[0]);
        var chartLayerParentNode = chartLayer.node().parentNode;
        var chartLayerNode = chartLayer.remove();
        chartLayerParentNode.appendChild(chartLayerNode.node());
        chartLayer.attr('clip-path', null);
      }
    },
    // didInsertElement
    actions: {
      draw: function draw() {
        var _this = this;
        var time = Ember.get(this, 'timeBased');
        var theDates = Ember.get(this, 'theDates');
        var theCounts = Ember.get(this, 'theCounts');
        var theTerm = Ember.get(this, 'term').replace('#', '').replace('@', '').replace(/\W/g, '');

        // Change the dates to have 'date' as the first marker, and sort them.
        theDates.unshift('date'); // add to beginning; c3/d3 need this value to know it's not a collection
        theCounts.unshift(theTerm);
        c3.generate({
          // bindto: '.sparkline.term-'+theTerm+'.'+self.get('check'),
          bindto: d3.select(this.$()[0]),
          // bindto: '.sparkline',
          data: {
            x: 'date',
            columns: [theDates, theCounts],
            color: function color(_color, d) {
              return Math.round(d.value) >= Ember.get(_this, 'max') ? '#00C853' : Math.round(d.value) <= Ember.get(_this, 'min') ? '#F44336' : '#999';
            }
          },
          // color: {
          //   pattern: ['#999']
          // },
          padding: {
            right: 30,
            left: 30
          },
          point: {
            show: Ember.get(this, 'showPoints')
          },
          tooltip: {
            format: {
              value: function value(_value /* , ratio, id, index*/) {
                return !time ? _value.toFixed(0) : _value.toFixed(2);
              },
              title: function title(value /* , ratio, id, index*/) {
                return (0, _moment.default)(value).format('MMMM Do, YYYY');
              }
            }
          },
          axis: {
            x: {
              show: Ember.get(this, 'showX'),
              type: 'timeseries',
              tick: {
                count: 2,
                format: '%Y-%m-%d'
              },
              padding: {
                top: 0,
                bottom: 0,
                left: 0,
                right: 0
              }
            },
            y: {
              show: Ember.get(this, 'showY'),
              min: Ember.get(this, 'min'),
              max: Ember.get(this, 'max'),
              tick: {
                count: 2
              },
              padding: {
                top: 0,
                bottom: 0,
                left: 0,
                right: 0
              }
            }
          },
          legend: {
            show: false
          }
        }); // timeSeriesChart
      }
    }
  });
});