define("affinio/components/api-key-input", ["exports", "affinio/utils/mutations", "affinio/utils/get-with-default"], function (_exports, _mutations, _getWithDefault) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Element
    classNames: ['api-key-input'],
    // Services
    assetMap: Ember.inject.service(),
    graph: Ember.inject.service(),
    notify: Ember.inject.service('notify'),
    tracker: Ember.inject.service(),
    // Get talkwalker token
    key: Ember.computed('account.thirdPartyIntegrations', 'source', function () {
      var integration = (0, _getWithDefault.default)(this, 'account.thirdPartyIntegrations', []).findBy('source', Ember.get(this, 'source'));
      if (integration) {
        return Ember.get(integration, 'key');
      }
    }),
    // Get the integration's icon/logo
    sourceIcon: Ember.computed('source', 'assetMap.map', function () {
      return Ember.get(this, 'assetMap').resolve("assets/images/".concat(Ember.get(this, 'source'), ".jpg"));
    }),
    actions: {
      submit: function submit(nu) {
        var _this = this;
        var notify = Ember.get(this, 'notify');
        if (nu) {
          if (!Ember.get(this, 'keyToAdd')) {
            return;
          }
          var key = Ember.get(this, 'keyToAdd');

          // Add the token
          Ember.get(this, 'graph').mutate(_mutations.default.createIntegrationToken(Ember.get(this, 'source'), key)).then(function () {
            Ember.get(_this, 'onChange') && Ember.get(_this, 'onChange')(key);
          }).then(function () {
            Ember.get(_this, 'tracker').track('Talkwalker api key saved', {
              source: Ember.get(_this, 'source')
            });
            notify.success('Successfully added the Talkwalker Token');
          }).catch(function () {
            notify.error('An error occurred while adding the Talkwalker Token. Please contact our support team for assistance.');
          });
        } else {
          // Add the token
          Ember.get(this, 'graph').mutate(_mutations.default.removeIntegrationToken(Ember.get(this, 'source'), Ember.get(this, 'account.id'))).then(function () {
            Ember.get(_this, 'onChange') && Ember.get(_this, 'onChange')(null);
          }).then(function () {
            notify.success('Successfully removed the Talkwalker Token');
            Ember.get(_this, 'tracker').track('Talkwalker api key removed', {
              source: Ember.get(_this, 'source')
            });
            Ember.set(_this, 'keyToAdd', null);
          }).catch(function () {
            notify.error('An error occurred while removing the Talkwalker Token. Please contact our support team for assistance.');
          });
        }
      }
    }
  });
});