define("affinio/templates/components/delta-metric", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "j4DmCtvB",
    "block": "{\"symbols\":[],\"statements\":[[0,\"  \"],[7,\"em\"],[9],[0,\"\\n    \"],[4,\"if\",[[23,[\"icon\"]]],null,{\"statements\":[[1,[27,\"font-awesome\",[[23,[\"icon\"]]],null],false]],\"parameters\":[]},null],[0,\"\\n    \"],[1,[21,\"description\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/delta-metric.hbs"
    }
  });
});