define("affinio/templates/components/module-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "B6RpQxsR",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h3\"],[9],[0,\"Source Report:\"],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"cluster\"]]],null,{\"statements\":[[0,\"  \"],[7,\"a\"],[9],[1,[21,\"reportName\"],false],[0,\" - \"],[1,[21,\"clusterName\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"a\"],[9],[1,[21,\"reportName\"],false],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/module-info.hbs"
    }
  });
});