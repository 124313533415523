define("affinio/models/reports/pinterest", ["exports", "ember-data", "affinio/models/reports/-base", "affinio/mixins/models/lurker-percentage"], function (_exports, _emberData, _base, _lurkerPercentage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var hasMany = _emberData.default.hasMany;
  var belongsTo = _emberData.default.belongsTo;
  var _default = _exports.default = _base.default.extend(_lurkerPercentage.default, {
    clusters: hasMany('clusters/pinterest'),
    _clusters: Ember.computed.alias('clusters'),
    clusterAggregation: belongsTo('clusters/pinterest')
  });
});