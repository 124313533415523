define("affinio/components/modules/audience-share", ["exports", "affinio/mixins/affinio-module"], function (_exports, _affinioModule) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var STATIC_COLORS = ['#17becf', '#bcbd22', '#e377c2', '#8c564b', '#9467bd', '#d62728', '#2ca02c', '#ff7f0e', '#1f77b4', '#9edae5', '#dbdb8d', '#c7c7c7'];
  var _default = _exports.default = Ember.Component.extend(_affinioModule.default, {
    // Element
    classNames: ['audience-share'],
    // Services
    preferences: Ember.inject.service(),
    staticColors: STATIC_COLORS,
    records: Ember.computed('model.cluster.breakdown', 'preferences.normalized', function () {
      return Ember.get(this, 'preferences.normalized') === 'cluster-bias' ? Ember.get(this, 'model.cluster.breakdown.normalized') : Ember.get(this, 'model.cluster.breakdown.standard');
    }),
    noRecords: Ember.computed.empty('records'),
    actions: {
      hideHandle: function hideHandle(handle) {
        var hidden = Ember.get(this, 'preferences.hiddenOverlaps');
        Ember.set(this, 'preferences.overlapSelected', '1');
        if (hidden.includes(handle)) {
          hidden.removeObject(handle);
          return;
        }
        if (hidden.length >= Ember.get(this, 'model.report.filters.followersOf.length') - 1) {
          return;
        } else {
          hidden.pushObject(handle);
          return;
        }
      }
    }
  });
});