define("affinio/templates/components/affinio-table-cell/multi-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ChwpjRPc",
    "block": "{\"symbols\":[\"column\"],\"statements\":[[4,\"power-select-multiple\",null,[[\"options\",\"selected\",\"placeholder\",\"renderInPlace\",\"onchange\"],[[23,[\"columns\"]],[23,[\"row\",\"related\"]],\"Select Columns\",true,[27,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"row\",\"related\"]]],null]],null]]],{\"statements\":[[0,\"  \"],[1,[22,1,[]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/affinio-table-cell/multi-select.hbs"
    }
  });
});