define("affinio/components/spark-bars", ["exports", "moment", "affinio/utils/constants/visualizations"], function (_exports, _moment, _visualizations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['spark-bars'],
    /**
     * @property {Number} maxTime
     */
    maxTime: Ember.computed('relativizedTimes', function () {
      return Ember.get(_.max(Ember.get(this, 'relativizedTimes'), 'value'), 'value');
    }),
    /**
     * We've noticed that the times that come back don't always add up to the instance's `value` number
     * Therefore, we choose to think of these times' values as a percentage of that instance's value number, instead
     * @property {Array} relativizedTimes
     */
    relativizedTimes: Ember.computed('totalInstanceValue', '_times', function () {
      var times = Ember.get(this, '_times');
      var totalInstanceValue = Ember.get(this, 'totalInstanceValue');
      var totalTimesInstances = times.mapBy('value').reduce(function (m, n) {
        return m + n;
      });
      times = times.map(function (time) {
        var percOfTimes = Ember.get(time, 'value') / totalTimesInstances;
        var modifiedValue = Math.round(percOfTimes * totalInstanceValue);
        Ember.set(time, 'value', modifiedValue); // eslint-disable-line ember/no-side-effects
        return time;
      });
      return times;
    }),
    /**
     * @property {Array} relativizedBars
     */
    relativizedBars: Ember.computed.map('relativizedTimes', function (time, iter) {
      return {
        style: Ember.String.htmlSafe("\n        width: ".concat(1 / Ember.get(this, 'relativizedTimes.length') * 100, "%;\n        left: ").concat(iter * (1 / Ember.get(this, 'relativizedTimes.length') * 100), "%;\n        height: ").concat(Ember.get(time, 'value') / Ember.get(this, 'maxTime') * 100, "%;\n        background-color: ").concat((0, _visualizations.redGreenScale)(Ember.get(time, 'value') / Ember.get(this, 'maxTime') * 100), "\n      ")),
        title: Ember.String.htmlSafe("".concat((0, _moment.default)(Ember.get(time, 'date')).format('MMMM Do, YYYY'), ": ").concat(Ember.get(time, 'value')))
      };
    })
  });
});