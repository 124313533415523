define("affinio/routes/admin/groups/company/group", ["exports", "affinio/utils/queries", "affinio/utils/mutations", "affinio/mixins/auth-check", "affinio/mixins/document-title"], function (_exports, _queries, _mutations, _authCheck, _documentTitle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_authCheck.default, _documentTitle.default, {
    graph: Ember.inject.service(),
    store: Ember.inject.service(),
    user: Ember.inject.service(),
    notify: Ember.inject.service('notify'),
    tracker: Ember.inject.service(),
    authOnly: true,
    documentTitle: 'Group - Admin - Affinio',
    showAccounts: false,
    model: function model(params) {
      var store = Ember.get(this, 'store');
      var companyID = this.modelFor('admin.groups.company');
      var groupId = params.group_id;
      var graph = Ember.get(this, 'graph');
      return groupId === 'new' ? Ember.Object.create({
        company: store.peekRecord('company', companyID),
        group: {}
      }) : graph.query(_queries.default.group(groupId, companyID)).then(function (response) {
        // groups save reports as pojos (plain old js objects) not as proper models
        // therefore these reports do not contain numberOfMembers as a proper metric
        // we create a pojo instead of a metric model to make it consistent
        Ember.get(response, 'group.reports').forEach(function (report, i) {
          if (typeof Ember.get(report, 'numberOfMembers') === 'number') {
            Ember.set(report, 'numberOfMembers', {
              id: i,
              level: 'report',
              response: {
                value: Ember.get(report, 'numberOfMembers')
              }
            });
          }
        });
        return {
          group: response.group,
          company: response.company
        };
      });
    },
    actions: {
      addToGroup: function addToGroup(entity) {
        var _this = this;
        var notify = Ember.get(this, 'notify');
        var tracker = Ember.get(this, 'tracker');
        var graph = Ember.get(this, 'graph');
        var groupID = Ember.get(this.controller, 'model.group.id');
        var accountID = Ember.get(entity, 'id');
        graph.mutate(_mutations.default.shareGroupToAccount(accountID, groupID)).then(function () {
          tracker.track('Added User to Group');
          notify.success("".concat(Ember.get(entity, 'name'), " added to ").concat(Ember.get(_this.controller, 'model.group.name')));
          Ember.get(_this.controller, 'model.group.accounts').pushObject(entity);
        }).catch(function (err) {
          Ember.get(_this, 'notify').error(err.payload.errors[0].message);
        });
      },
      removeFromGroup: function removeFromGroup(entity) {
        var _this2 = this;
        var notify = Ember.get(this, 'notify');
        var tracker = Ember.get(this, 'tracker');
        var graph = Ember.get(this, 'graph');
        var groupID = Ember.get(this.controller, 'model.group.id');
        var accountID = Ember.get(entity, 'id');
        graph.mutate(_mutations.default.unshareGroupFromAccount(accountID, groupID)).then(function () {
          tracker.track('Added User to Group');
          notify.success("".concat(Ember.get(entity, 'name'), " removed from ").concat(Ember.get(_this2.controller, 'model.group.name')));
          Ember.get(_this2.controller, 'model.group.accounts').removeObject(entity);
        }).catch(function (err) {
          Ember.get(_this2, 'notify').error(err.payload.errors[0].message);
        });
      },
      directShareReportToGroup: function directShareReportToGroup(reportID, shareOption, cb) {
        var _this3 = this;
        var notify = Ember.get(this, 'notify');
        var tracker = Ember.get(this, 'tracker');
        var graph = Ember.get(this, 'graph');
        var groupID = Ember.get(this.controller, 'model.group.id');
        var permission = shareOption === 'Edit' ? true : false;
        graph.mutate(_mutations.default.directShareReportToGroup(reportID, groupID, permission)).then(function (response) {
          var reportResponse = response.data.shareReportToGroup;
          // TODO update this to new format
          var report = graph.recordFromGraphData('single', null, reportResponse);
          tracker.track('Added Report to Group');
          notify.success("".concat(Ember.get(report, 'name'), " added to ").concat(Ember.get(_this3.controller, 'model.group.name')));
          Ember.get(_this3.controller, 'model.group.reports').pushObject(report);
        }).then(function () {
          if (cb && typeof cb === 'function') {
            cb();
          }
        }).catch(function (err) {
          Ember.get(_this3, 'notify').error(err.payload.errors[0].message);
        });
      }
    }
  });
});