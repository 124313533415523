define("affinio/templates/components/preferences/interests-search-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "TyxPMd+s",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"input\",null,[[\"type\",\"placeholder\",\"value\",\"input\"],[\"text\",\"Search\",[22,0,[\"textQuery\"]],[27,\"perform\",[[22,0,[\"textSearch\"]]],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/preferences/interests-search-bar.hbs"
    }
  });
});