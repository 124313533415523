define("affinio/components/loading-screen", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    auth: Ember.inject.service(),
    path: Ember.inject.service(),
    user: Ember.inject.service(),
    phrases: Ember.A(['Reticulating Splines', 'Scanning for selfies', 'Listening for chirps', 'Herding Emojis', 'Aligning solar panel array', 'Pinging tweets in low Earth orbit', 'Expanding tensor of hypermaxed eigentweets']),
    pulse: function pulse(el) {
      el.fadeIn(500);
      Ember.run.later(this, function () {
        if (el) {
          el.fadeOut(500);
        }
      });
    },
    didInsertElement: function didInsertElement() {
      Ember.run.scheduleOnce('afterRender', this, function () {
        var _this = this;
        var i = Math.floor(Math.random() * 6);
        Ember.set(this, 'phrase', Ember.get(this, 'phrases').objectAt(i));
        this.$('p em, p span').fadeIn(2000);
        // Ember.run.later(() => {
        this.$('.phrase em, .phrase span').fadeOut(500);
        // }, 4000);
        Ember.set(this, 'interval', setInterval(function () {
          var phrases = _.without(Ember.get(_this, 'phrases'), Ember.get(_this, 'phrase'));
          i = Math.floor(Math.random() * phrases.length);
          Ember.set(_this, 'phrase', phrases[i]);
          _this.pulse(_this.$('.phrase em, .phrase span'));
        }, 5000)); // setInterval
      });
    },
    // didInsertElement
    willDestroyElement: function willDestroyElement() {
      clearInterval(Ember.get(this, 'interval'));
    } // didDestroyElement
  });
});