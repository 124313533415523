define("affinio/components/cluster-bar-graph", ["exports", "affinio/utils/get-with-default"], function (_exports, _getWithDefault) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNameBindings: ['activePosition', 'isLurker:cross-tribe-lurkers', 'network', 'metric'],
    tagName: 'div',
    classNames: ['bar-graph', 'cluster-bar-graph', 'clearfix', 'chart'],
    showAverageBar: Ember.computed('metric', function () {
      var metric = Ember.get(this, 'metric');
      return metric !== 'lurkers' && metric !== 'interestAffinity' && metric !== 'imageShareCBG';
    }),
    // showAverageBar

    // showPercentiles: computed.equal('metric', 'postsPerMonthCBG'),
    showPercentiles: Ember.computed('metric', function () {
      if (Ember.get(this, 'metric') === 'postsPerMonthCBG' || Ember.get(this, 'metric') === 'engagement_shares' || Ember.get(this, 'metric') === 'engagement_likes' || Ember.get(this, 'metric') === 'engagement_comments') {
        return true;
      } else {
        return false;
      }
    }),
    showLurkerAverage: Ember.computed('metric', function () {
      return Ember.get(this, 'metric') === 'lurkers';
    }),
    // showAverageBar

    isLurker: Ember.computed('metric', function () {
      return Ember.get(this, 'metric') === 'lurkers';
    }),
    isReddit: Ember.computed.equal('network', 'Reddit'),
    lowStyle: Ember.computed('metric', function () {
      return Ember.String.htmlSafe(Ember.get(this, 'metric') === 'interestAffinity' ? 'left: 0%;' : '');
    }),
    highStyle: Ember.computed('metric', function () {
      return Ember.String.htmlSafe(Ember.get(this, 'metric') === 'interestAffinity' ? 'left: 50%;' : '');
    }),
    veryHighStyle: Ember.computed('metric', function () {
      return Ember.String.htmlSafe(Ember.get(this, 'metric') === 'interestAffinity' ? 'left: 75%;' : '');
    }),
    reportAverageStyle: Ember.computed('metric', 'twitterAverage', 'max', function () {
      return Ember.String.htmlSafe("left:".concat(Ember.get(this, 'metric') === 'interestAffinity' ? 25 : Ember.get(this, 'reportAverage') / Ember.get(this, 'max.value') * 100, "%"));
    }),
    aggregateAverageWidth: Ember.computed('metric', 'twitterAverage', 'max', function () {
      return Ember.get(this, 'reportAverage') / Ember.get(this, 'max').value * 100;
    }),
    twitterAverageStyle: Ember.computed('metric', 'twitterAverage', 'max', function () {
      return Ember.String.htmlSafe("left:".concat(Ember.get(this, 'metric') === 'interestAffinity' ? 25 : Ember.get(this, 'twitterAverage') / Ember.get(this, 'max.value') * 100, "%"));
    }),
    reportAverage: Ember.computed('source', 'metric', 'network', 'initialMetricDependency.@each.value', function () {
      return Ember.get(this, 'source').mapBy(Ember.get(this, 'metric')).mapBy('value').reduce(function (memo, curr) {
        return (!isNaN(memo) ? memo : 0) + (!isNaN(curr) ? curr : 0);
      }) / Ember.get(this, 'source.length'); // isNaN is hacky but gets around non-existent values from audience pass.
    }),
    // Hardcoded 25th, 50th, and 75th percentiles for average posts per month
    perc25: Ember.computed('metric', 'network', function () {
      var perc;
      if (Ember.get(this, 'metric') === 'postsPerMonthCBG') {
        switch (Ember.get(this, 'network')) {
          case 'Twitter':
            perc = Ember.get(this, 'isContentReport') ? 79.78 : 7.32;
            break;
          case 'Pinterest':
            perc = 33.96;
            break;
          default:
            break;
        }
        return perc;
      } else if (Ember.get(this, 'metric') === 'engagement_shares') {
        perc = 0.13;
        return perc;
      } else if (Ember.get(this, 'metric') === 'engagement_likes') {
        perc = 2.51;
        return perc;
      } else if (Ember.get(this, 'metric') === 'engagement_comments') {
        perc = 1.08;
        return perc;
      } else {
        return null;
      }
    }),
    // perc25

    perc50: Ember.computed('metric', 'network', function () {
      var perc;
      if (Ember.get(this, 'metric') === 'postsPerMonthCBG') {
        switch (Ember.get(this, 'network')) {
          case 'Twitter':
            perc = Ember.get(this, 'isContentReport') ? 94.94 : 13.71;
            break;
          case 'Pinterest':
            perc = 58.63;
            break;
          default:
            break;
        }
        return perc;
      } else if (Ember.get(this, 'metric') === 'engagement_shares') {
        perc = 0.27;
        return perc;
      } else if (Ember.get(this, 'metric') === 'engagement_likes') {
        perc = 3.72;
        return perc;
      } else if (Ember.get(this, 'metric') === 'engagement_comments') {
        perc = 1.88;
        return perc;
      } else {
        return null;
      }
    }),
    // perc50

    perc75: Ember.computed('metric', 'network', function () {
      var perc;
      if (Ember.get(this, 'metric') === 'postsPerMonthCBG') {
        switch (Ember.get(this, 'network')) {
          case 'Twitter':
            perc = Ember.get(this, 'isContentReport') ? 107.88 : 22.72;
            break;
          case 'Pinterest':
            perc = 108.18;
            break;
          default:
            break;
        }
        return perc;
      } else if (Ember.get(this, 'metric') === 'engagement_shares') {
        perc = 0.43;
        return perc;
      } else if (Ember.get(this, 'metric') === 'engagement_likes') {
        perc = 5.40;
        return perc;
      } else if (Ember.get(this, 'metric') === 'engagement_comments') {
        perc = 2.51;
        return perc;
      } else {
        return null;
      }
    }),
    // perc75

    perc25style: Ember.computed('metric', 'perc25', 'max', function () {
      return Ember.String.htmlSafe("left: ".concat(Ember.get(this, 'perc25') / Ember.get(this, 'max').value * 100, "%"));
    }),
    perc50style: Ember.computed('metric', 'perc25', 'max', function () {
      return Ember.String.htmlSafe("left: ".concat(Ember.get(this, 'perc50') / Ember.get(this, 'max').value * 100, "%"));
    }),
    perc75style: Ember.computed('metric', 'perc25', 'max', function () {
      return Ember.String.htmlSafe("left: ".concat(Ember.get(this, 'perc75') / Ember.get(this, 'max').value * 100, "%"));
    }),
    twitterAverage: Ember.computed('metric', 'network', function () {
      // TODO this is dirty
      if (Ember.get(this, 'metric') === 'postsPerMonthCBG') {
        var network = Ember.get(this, 'network');
        return network === 'Twitter' ? 25.53 : network === 'Pinterest' ? 86.515 : 6.55;
      } else if (Ember.get(this, 'metric') === 'interconnectivityCBG') {
        return 0.16;
      } else if (Ember.get(this, 'metric') === 'interestAffinity') {
        return 25;
      } else if (Ember.get(this, 'metric') === 'uniqueInterestsPercentage') {
        return 35; // TODO: forreal tho
      } else if (Ember.get(this, 'metric') === 'verifiedMembersPercentageCBG') {
        return 0.05367033201;
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);
      var depString = "source.@each.".concat(Ember.get(this, 'metric')).replace(/ /g, '_');
      Ember.defineProperty(this, 'initialMetricDependency', Ember.computed('metric', depString, function () {
        return Ember.get(this, 'source').mapBy(Ember.get(this, 'metric'));
      }));
    },
    max: Ember.computed('metric', 'source', 'initialMetricDependency.@each.value', function () {
      var theReturn;
      var metric = Ember.get(this, 'metric');
      var sourceArray = Ember.get(this, 'source').toArray();
      // console.log('sourceArray', sourceArray);
      // Lurkers bars have 2 widths; one for the bar itself (grey bg) and another for the lurker%.
      // Grey bar depends on members_count
      if (metric === 'lurkers') {
        theReturn = Object.create({
          value: Ember.get(_.max(sourceArray, function (record) {
            return parseFloat(Ember.get(record, 'numberOfMembers.response.value'));
          }), 'numberOfMembers.response.value')
        }); // theReturn
      } else {
        // console.log('here matters', metric, sourceArray);
        theReturn = Object.create({
          value: Ember.get(_.max(sourceArray, function (record) {
            var val = Ember.get(record, metric) ? Ember.get(record, metric).value : 0.0;
            return isNaN(val) ? 0.0 : val;
          }), "".concat(metric, ".value"))
        }); // theReturn
      } // if lurkers

      // maxwidth of any of the bars can be trumped by the network average.
      // But, for those where we're showing percentiles, let the 75th matter, and not the network avg.
      var upperNetworkLimit = Ember.get(this, 'showPercentiles') ? 'perc75' : 'twitterAverage';
      if (metric === 'interestAffinity' && Ember.get(this, 'network') !== 'Reddit') {
        theReturn.value = 50;
      } else if (Ember.get(this, 'network') === 'Twitter') {
        if (theReturn.value < Ember.get(this, upperNetworkLimit) && metric !== 'interestAffinity') {
          theReturn.value = Ember.get(this, upperNetworkLimit);
        }
      }
      // // Make the width of the component the same as the max betwen currentCluster.metric.value and avg.value
      // if (get(this, 'showAggregateAverage') && !get(this, 'isLurker') && !get(this, 'isAffinity')) {
      //   let avg = get(this, 'reportAverage');
      //   let currentCluster = get(this, 'source').findBy('id', get(this, 'currentCluster'));
      //   if (avg > currentCluster.get(metric).get('value')) {
      //     theReturn.value = avg;
      //   } else if (currentCluster.get(metric).get('value')) {
      //     theReturn.value = currentCluster.get(metric).get('value');
      //   }
      // }

      return theReturn;
    }),
    theSource: Ember.computed('source', function () {
      return Ember.get(this, 'source').toArray().sortBy('id').reverse().sortBy('numberOfMembers.response.value').reverse();
    }),
    audienceLurkerPercentage: Ember.computed('model.report.everyClusterLurkerPercentage.@each.response.value', function () {
      return (0, _getWithDefault.default)(this, 'model.report.everyClusterLurkerPercentage', []).mapBy('response.value').reduce(function (m, n) {
        return m + n;
      }, 0) * 100 / Ember.get(this, 'source.length');
    }),
    audienceInfluencerPercentage: Ember.computed('source', function () {
      return 100 - parseFloat(Ember.get(this, 'audienceLurkerPercentage'));
    }),
    activePosition: Ember.computed('theSource', 'currentCluster', function () {
      return "spot_".concat(Ember.get(this, 'theSource').indexOf(_.findWhere(Ember.get(this, 'theSource'), {
        id: Ember.get(this, 'currentCluster')
      })));
    }),
    legendColor: Ember.computed('currentCluster', function () {
      if (Ember.get(this, 'currentCluster')) {
        return Ember.String.htmlSafe("border-color:".concat(Ember.get(this, 'currentCluster.color'), ";"));
      } else {
        return Ember.String.htmlSafe('border-color:#ebebeb;');
      }
    })
  });
});