define("affinio/components/report-builder/new-report-summary", ["exports", "affinio/utils/affinio", "affinio/utils/get-with-default"], function (_exports, _affinio, _getWithDefault) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // define the action handle you want to use when the remove element is clicked
  var removeActionDefinitions = {
    minInfluencerFollowers: 'clearAllFilters',
    maxInfluencerFollowers: 'clearAllFilters',
    contentTo: 'clearAllFilters',
    contentFrom: 'clearAllFilters',
    benchmarkGroups: 'clearAllFilters',
    fileURL: 'clearAllFilters',
    contentQuery: 'removeContentQueryFilterProperty',
    default: 'removeItemFromFilterProperty'
  };
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['new-report-summary'],
    user: Ember.inject.service(),
    tracker: Ember.inject.service(),
    preferences: Ember.inject.service(),
    localStorage: Ember.inject.service(),
    taxos: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      Ember.set(this, 'removeActionDefinitions', removeActionDefinitions);
    },
    numberOfClusters: Ember.computed.or('model.numberOfClusters', 'model.estimatedNumberOfClusters'),
    showPrimaryBoolean: Ember.computed('model.filters.{fileURL,followersOf.[],bioKeywords.[],contentQuery.[],bioLocations.[]}', 'network', 'sourceReport', function () {
      var _this = this;
      if (Ember.get(this, 'network.value') === 'twitter' && Ember.get(this, 'model.filters')) {
        var primaryBooleanMetrics = ['fileURL', 'followersOf', 'bioKeywords', 'contentQuery', 'bioLocations'];
        var numberOfPrimaryBooleanFilters = Ember.get(primaryBooleanMetrics.map(function (key) {
          return Ember.get(_this, "model.filters.".concat(key, ".length")) > 0 ? key : null;
        }).compact(), 'length');
        return numberOfPrimaryBooleanFilters > 1;
      } else if (Ember.get(this, 'network.isCustom')) {
        return true;
      } else {
        return false;
      }
    }),
    exclusionFilters: Ember.computed(function () {
      return ['excludedFollowersOf', 'excludedBioLocations', 'excludedBioKeywords'];
    }),
    hiddenFilters: Ember.computed(function () {
      return ['followersStartDate', 'followersEndDate', 'fileID'];
    }),
    hasExclusionFilters: Ember.computed('model.filters.{followersOf.[],bioKeywords.[],bioLocations.[],excludedFollowersOf.[],excludedBioLocations.[],excludedBioKeywords.[]}', function () {
      // check to see if this is the last filter
      // we need to use this here no access to the report base
      return (0, _affinio.hasExclusions)(Ember.get(this, 'model.filters'));
    }),
    andWarning: Ember.computed('model.{primaryBoolean,followersOfBoolean,contentQueryBoolean,bioKeywordsBoolean,filterChange,customBooleans.fields.articles,customBooleans.fields.domains}', 'model.filters.{followersOf.[],bioKeywords.[],bioLocations.[],excludedFollowersOf.[],excludedBioLocations.[],excludedBioKeywords.[],contentQuery,followersOfCSV}', 'activeNetwork.taxoID', function () {
      if (Ember.get(this, 'model.filters.followersOfCSV')) {
        return true;
      }

      // if has followersOf 'AND' Boolean and has followers
      // if has primaryBoolean 'AND' with either bioKeywords or bioLocations show the warning
      var filters = Ember.get(this, 'model.filters');
      return filters ? Ember.get(this, 'model.followersOfBoolean') === 'AND' && filters.followersOf && filters.followersOf.length > 1 || Ember.get(this, 'model.contentQueryBoolean') === 'AND' && filters.contentQuery && filters.contentQuery.length > 1 || Ember.get(this, 'model.bioKeywordsBoolean') === 'AND' && filters.bioKeywords && filters.bioKeywords.length > 1
      // The logic below is functionaly the same as report-builder numberOfClustersObs
      || Ember.get(this, 'model.primaryBoolean') === 'AND' && [filters.followersOf, filters.bioLocations, filters.bioKeywords, filters.contentQuery].compact().mapBy('length').filter(function (n) {
        return n > 0;
      }).length > 1 || Ember.get(this, 'hasExclusionFilters') : false;
    }),
    dataSourceIds: Ember.computed('network.{isCustom,taxoID}', function () {
      if (Ember.get(this, 'network.isCustom')) {
        return Ember.get(this, 'network.types.0.stages.0.modules').mapBy('datasourceid').uniq();
      } else {
        return [''];
      }
    }),
    newFollowerWarning: Ember.computed('model.{newFollowersOnly,filters.followersOf.[]}', function () {
      if (Ember.get(this, 'model.newFollowersOnly')) {
        return (0, _getWithDefault.default)(this, 'model.filters.followersOf', []).length > 0;
      }
    }),
    actions: {
      clearAllFilters: function clearAllFilters(type) {
        var _this2 = this;
        if (type === 'contentQuery') {
          Ember.get(this, 'model').toggleProperty('resetInputOptimizer');
        }
        /** the API is always returning the exclusion filters even when null
         * here were checking to return either array of null when an excluded filter is cleared
         */
        var clearType = null;
        if (['excludedFollowersOf', 'excludedBioLocations', 'excludedBioKeywords'].includes(type)) {
          clearType = [];
        }
        Ember.set(this, "model.filters.".concat(type), clearType);
        Object.keys(Ember.get(this, 'model.excludeFilters')).forEach(function (k) {
          return Ember.set(_this2, "model.excludeFilters.".concat(k), false);
        });
        this.toggleProperty('model.filterChange');
      },
      // TODO remove if not used
      toggleBoolean: function toggleBoolean(boolean, val) {
        Ember.set(this, "model[".concat(boolean, "]"), val);
      },
      removeItemFromFilterProperty: function removeItemFromFilterProperty(type, value) {
        var _this3 = this;
        // if this is the last filter in an inclusion filter then reset the exclusion checkbox
        Ember.set(this, "model.filters.".concat(type), Ember.get(this, "model.filters.".concat(type)).without(value));
        if (Ember.get(this, "model.filters.".concat(type)).length === 0 && ['followersOf', 'bioKeywords', 'bioLocations'].includes(type)) {
          Object.keys(Ember.get(this, 'model.excludeFilters')).forEach(function (k) {
            return Ember.set(_this3, "model.excludeFilters.".concat(k), false);
          });
        }
        this.toggleProperty('model.filterChange');
      },
      removeContentQueryFilterProperty: function removeContentQueryFilterProperty(type, value) {
        Ember.get(this, "model.filters.".concat(type)).removeObject(value);
        Ember.get(this, 'model').toggleProperty('resetInputOptimizer');
      },
      removeGroupFromBenchmarkGroup: function removeGroupFromBenchmarkGroup(benchmarkIndex) {
        Ember.get(this, 'model.filters.benchmarkGroups').removeAt(benchmarkIndex);
      },
      removeAssetFromBenchmarkGroup: function removeAssetFromBenchmarkGroup(benchmarkIndex, removedHandle) {
        var benchmarkGroup = Ember.get(this, 'model.filters.benchmarkGroups').objectAt(benchmarkIndex);
        var filteredMembers = Ember.get(benchmarkGroup, 'members').reject(function (handle) {
          return handle === removedHandle.replace('@', '');
        });
        if (Ember.get(filteredMembers, 'length') > 0) {
          Ember.set(Ember.get(this, 'model.filters.benchmarkGroups').objectAt(benchmarkIndex), 'members', filteredMembers);
        } else {
          // remove benchmark group from filters if no members left
          var filteredBenchmarkGroups = Ember.get(this, 'model.filters.benchmarkGroups').reject(function (group, i) {
            return i === benchmarkIndex;
          });
          Ember.set(this, 'model.filters.benchmarkGroups', filteredBenchmarkGroups);
        }
      },
      shouldShowPrimary: function shouldShowPrimary(filters, type, dsid) {
        // we want to see if there is another filter of this dsid after this one
        var show = false;
        var keys = Object.keys(filters);
        keys.forEach(function (key, index, array) {
          if (key !== type) {
            if (index > array.indexOf(type)) {
              if (filters[key] && filters[key].length && filters[key][0].datasourceid === dsid) {
                show = true;
              }
            }
          }
        });
        return show;
      },
      thisDatasourceHasFilters: function thisDatasourceHasFilters(dsid, filters, filterChange, sqlMode, sqlQuery) {
        var hasFilters = false;
        if (sqlMode) {
          hasFilters = sqlQuery && sqlQuery.length > 0;
        } else {
          var keys = Object.keys(filters);
          keys.forEach(function (key) {
            if (filters[key] && filters[key].length && filters[key][0].datasourceid === dsid) {
              hasFilters = true;
            }
          });
        }
        return hasFilters;
      },
      futureDatasourceHasFilters: function futureDatasourceHasFilters(dsid, dsindex, dataSourceIds, filters, filterChange, sqlMode) {
        var hasFilters = false;
        // last datasource
        if (dsindex >= dataSourceIds.length - 1) {
          return hasFilters;
        } else {
          var index = dsindex + 1;
          var _loop = function _loop() {
            var nextDs = dataSourceIds[index];
            if (sqlMode) {
              hasFilters = (0, _getWithDefault.default)(filters, "sqlQueries.".concat(nextDs), '').length > 0;
            } else {
              Object.keys(filters).forEach(function (key) {
                if (filters[key] && filters[key].length && filters[key][0].datasourceid === nextDs) {
                  hasFilters = true;
                }
              });
            }
            index++;
          };
          while (index < dataSourceIds.length && !hasFilters) {
            _loop();
          }
        }
        return hasFilters;
      },
      getDataSourceName: function getDataSourceName(dsid) {
        return (0, _getWithDefault.default)(Ember.get(this, 'taxos.dataSources').findBy('id', dsid), 'label', '');
      },
      getFilterAlias: function getFilterAlias(dsid, filter) {
        var taxo = Ember.get(this, 'taxos.taxonomies').findBy('id', Ember.get(this, 'activeNetwork.taxoID'));
        var dataSource = Ember.get(taxo, 'dataSources').findBy('id', dsid);
        var camalizedAliasesObject = {};
        for (var x in dataSource.aliases) {
          camalizedAliasesObject[Ember.String.camelize(x)] = dataSource.aliases[x];
        }
        return camalizedAliasesObject[filter];
      },
      calculateSortedFilters: function calculateSortedFilters(network, filters) {
        var keys = Object.keys(filters).without('fileID');
        var sort = null;
        if (network.taxoID === 'Twitter') {
          sort = ['contentQuery', 'fileURL', 'queryID', 'followersOf', 'excludedFollowersOf', 'bioKeywords', 'excludedBioKeywords', 'bioLocations', 'excludedBioLocations', 'contentFrom', 'contentTo', 'explicitWords', 'minInfluencerFollowers', 'maxInfluencerFollowers', 'benchmarkGroups'];
        }
        if (sort) {
          keys.sort(function (a, b) {
            return sort.indexOf(a) - sort.indexOf(b);
          });
        }
        return keys;
      }
    }
  });
});