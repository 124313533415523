define("affinio/templates/components/instagram-post", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "xzKQTy/p",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"iframe\"],[12,\"src\",[21,\"modifiedURL\"]],[11,\"frameborder\",\"0\"],[11,\"scrolling\",\"no\"],[11,\"width\",\"320\"],[11,\"height\",\"390\"],[9],[10],[0,\"\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/instagram-post.hbs"
    }
  });
});