define("affinio/templates/components/modules/numeric-visualization", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "3fGPoYNH",
    "block": "{\"symbols\":[\"comp\",\"&default\"],\"statements\":[[14,2,[[22,0,[]]]],[0,\"\\n\"],[7,\"section\"],[11,\"class\",\"numeric-box\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"moduleRequest\",\"nestedCompare\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"compare\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"compareValues\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"compare-item\"],[9],[0,\"\\n          \"],[7,\"span\"],[11,\"class\",\"name\"],[9],[1,[22,1,[\"name\"]],false],[10],[0,\"\\n          \"],[7,\"article\"],[11,\"class\",\"value\"],[9],[1,[27,\"abbreviate-number\",[[22,1,[\"value\"]],[23,[\"cull\"]]],null],false],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"article\"],[11,\"class\",\"value\"],[9],[1,[27,\"commafy-number\",[[23,[\"value\"]],[23,[\"cull\"]]],null],false],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[7,\"span\"],[11,\"class\",\"compare-to\"],[9],[1,[23,[\"comparison\",\"method\"]],false],[0,\" among all clusters: \"],[1,[27,\"commafy-number\",[[23,[\"comparison\",\"value\"]],[23,[\"cull\"]]],null],false],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"histogram\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"histo-gram\",null,[[\"data\",\"labels\"],[[23,[\"histogramData\"]],[23,[\"histogramLabels\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[27,\"ridge-lines\",null,[[\"data\",\"hoveredCluster\"],[[23,[\"comparableBins\"]],[23,[\"model\",\"report\",\"hoveredCluster\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/modules/numeric-visualization.hbs"
    }
  });
});