define("affinio/services/preferences", ["exports", "affinio/utils/get-with-default", "moment"], function (_exports, _getWithDefault, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    path: Ember.inject.service(),
    user: Ember.inject.service(),
    tracker: Ember.inject.service(),
    localStorage: Ember.inject.service(),
    socialNetwork: 'Twitter',
    // assume twitter //This is dirty -JK

    initialSet: false,
    heldCluster: null,
    heldDelta: false,
    lurkerFormat: 'lurkers',
    clusterView: 'size',
    timeSeriesFormat: 'dailyGrowth',
    visFormat: 'networkgraph',
    showVisualizationLabels: true,
    audienceVizBg: 'black',
    networkRectBg: 'black',
    // engagement
    timezone: _moment.default.tz.guess(),
    activeEngagement: null,
    highlightedTime: null,
    normalized: 'cluster-bias',
    interestShadingSelected: true,
    selectedBenchmarkGroupId: 'all-groups',
    overlapSelected: '2',
    customMetricEditorCurrentView: 'configure',
    init: function init() {
      this._super.apply(this, arguments);
      Ember.setProperties(this, {
        normalizedMap: Ember.A(),
        hiddenOverlaps: Ember.A()
      });
    },
    hasTwitterGlobalComparison: false,
    // settable from routes/audiences/audience

    competitiveOptions: Ember.A([{
      value: 'cluster-bias',
      label: 'Normalized'
    }, {
      value: 'term-relative',
      label: 'Standard'
    }]),
    contentLinksOptions: Ember.A([{
      value: 'cluster-bias',
      label: 'Normalized'
    }, {
      value: 'niche-ranked',
      label: 'Niche Ranked'
    }]),
    // TODO: this should be a Map not an Array
    // A user can set their own custom categorical colo(u)rs
    categoricalSets: Ember.A([Ember.Object.create({
      name: 'Standard Categorical',
      colors: ['#1f77b4', '#aec7e8', '#ff7f0e', '#ffbb78', '#2ca02c', '#98df8a', '#d62728', '#ff9896', '#9467bd', '#c5b0d5', '#8c564b', '#c49c94', '#e377c2', '#f7b6d2', '#7f7f7f', '#c7c7c7', '#bcbd22', '#dbdb8d', '#17becf', '#9edae5']
    }), Ember.Object.create({
      name: 'Alternate Categorical',
      colors: ['#393b79', '#5254a3', '#6b6ecf', '#9c9ede', '#637939', '#8ca252', '#b5cf6b', '#cedb9c', '#8c6d31', '#bd9e39', '#e7ba52', '#e7cb94', '#843c39', '#ad494a', '#d6616b', '#e7969c', '#7b4173', '#a55194', '#ce6dbd', '#de9ed6']
    }), Ember.Object.create({
      name: 'Affinio',
      colors: ['#00c4bc', '#ff0068', '#ffba33', '#3f9eea', '#99ccff', '#ff9900', '#7feaea', '#999999', '#666666', '#cccccc', '#00c4bc', '#ff0068', '#ffba33', '#3f9eea', '#99ccff', '#ff9900', '#7feaea', '#999999', '#666666', '#cccccc']
    }), Ember.Object.create({
      name: 'Ellsworth Kelly',
      colors: ['#F8B800', '#4DB930', '#00A14D', '#008874', '#0758BB', '#093CB3', '#58266E', '#AB2049', '#DE032C', '#F52324', '#FA540E', '#F8B800', '#4DB930', '#00A14D', '#008874', '#0758BB', '#093CB3', '#58266E', '#AB2049', '#DE032C']
    }), Ember.Object.create({
      name: 'Deuteranopia',
      colors: ['#5d5a80', '#4c409b', '#5744c3', '#8d80f5', '#aca4f3', '#b0acdc', '#56573b', '#a3a855', '#939663', '#eaf154', '#dfe48e', '#e9ecc0', '#5d5a80', '#4c409b', '#5744c3', '#8d80f5', '#aca4f3', '#b0acdc', '#56573b', '#a3a855']
    }), Ember.Object.create({
      name: 'Protanopia',
      colors: ['#696781', '#3a259c', '#5e4fc1', '#9c92f4', '#9387f3', '#c5c3d9', '#35343d', '#888b56', '#a5a961', '#eaf154', '#ecf28d', '#e5e8c0', '#696781', '#3a259c', '#5e4fc1', '#9c92f4', '#9387f3', '#c5c3d9', '#35343d', '#888b56']
    }), Ember.Object.create({
      name: 'Tritanopia',
      colors: ['#006c8a', '#883546', '#00a8d8', '#ff889f', '#00cbfb', '#c5003b', '#ff6388', '#ffdae0', '#95e7fd', '#006c8a', '#883546', '#00a8d8', '#ff889f', '#00cbfb', '#c5003b', '#ff6388', '#ffdae0', '#95e7fd', '#006c8a', '#883546']
    }), Ember.Object.create({
      name: 'Titan',
      colors: ['#EDAA3B', '#C62F38', '#4CB076', '#3981F7', '#B92B63', '#7F5DF6', '#EC672C', '#69DE4F', '#35C0CB', '#33754F', '#EA338F', '#F7CE4A', '#AF604B', '#902CF5', '#56AAA0', '#65201F', '#4C97C7', '#EA4338', '#BDEC4A', '#4F4DD7']
    })]),
    categoricalColors: Ember.computed('localStorage.categoricalColors', 'user.account.preferences.categoricalColors', function () {
      var r = (0, _getWithDefault.default)(this, 'localStorage.categoricalColors', Ember.get(this, 'user.account.preferences.categoricalColors'));
      return r ? r : 'Standard Categorical';
    }),
    clusterIndexToColor: function clusterIndexToColor(clusterIndex) {
      // let colorMap = get(this, 'colorMap');
      return Ember.get(Ember.get(this, 'categoricalSets').findBy('name', Ember.get(this, 'categoricalColors')), 'colors').objectAt(clusterIndex);
    }
  });
});