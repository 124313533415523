define("affinio/models/taxonomies/data-set", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var Model = _emberData.default.Model;
  var _default = _exports.default = Model.extend({
    name: attr(),
    dateCreated: attr(),
    dateUpdated: attr(),
    creator: attr(),
    columns: attr(),
    file: belongsTo('file')
  });
});