define("affinio/components/monitor/new-audience-summary-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['added_user', 'clearfix'],
    classNameBindings: ['isInline:inline'],
    isInline: Ember.computed('type', function () {
      return !['full', 'large'].includes(Ember.get(this, 'type'));
    }),
    isMini: Ember.computed('type', function () {
      return Ember.get(this, 'type') === 'mini';
    })
  });
});