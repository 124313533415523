define("affinio/controllers/oauth-twitter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    localStorage: Ember.inject.service(),
    oauth: Ember.inject.service(),
    queryParams: {
      token: 'oauth_token',
      verifier: 'oauth_verifier',
      denied: 'denied'
    },
    token: null,
    verifier: null,
    denied: null,
    tokenObserver: Ember.observer('token', 'verifier', 'denied', function () {
      var _this = this;
      var promise = Ember.RSVP.resolve();
      if (Ember.get(this, 'denied')) {
        this.transitionToRoute('reports');
      } else {
        var token = Ember.get(this, 'token');
        var verifier = Ember.get(this, 'verifier');
        if (token && verifier) {
          promise = Ember.get(this, 'oauth').saveAuthentication('twitter', {
            token: token,
            verifier: verifier
          });
        }

        // we temporarily save report ids, incase oauth was started inside the activate interface, we want to redirect there
        var lastAdvertisingReportId = Ember.get(this, 'localStorage.lastAdvertisingReportId');
        var lastAdvertisingReportVersion = Ember.get(this, 'localStorage.lastAdvertisingReportVersion');
        var lastAdvertisingClusterId = Ember.get(this, 'localStorage.lastAdvertisingClusterId');
        if (lastAdvertisingReportId && lastAdvertisingReportVersion && lastAdvertisingClusterId) {
          promise.then(function () {
            Ember.setProperties(_this, {
              'localStorage.lastAdvertisingReportId': null,
              'localStorage.lastAdvertisingReportVersion': null,
              'localStorage.lastAdvertisingClusterId': null
            });
            _this.transitionToRoute('reports.report.version.cluster.category.subcategory', lastAdvertisingReportId, lastAdvertisingReportVersion, lastAdvertisingClusterId, 'actions', 'advertise');
          });
        } else {
          promise.then(function () {
            return _this.transitionToRoute('reports');
          });
        }
      }
    })
  });
});