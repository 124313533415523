define("affinio/components/modules/new-module", ["exports", "affinio/mixins/affinio-module"], function (_exports, _affinioModule) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var metricBlacklist = ['metrics', 'numberOfMembers', 'clusterSVGURL', 'density'];
  var _default = _exports.default = Ember.Component.extend(_affinioModule.default, {
    classNames: ['new-module'],
    schemaEditor: Ember.inject.service(),
    selectedMetric: null,
    _possibleMetrics: Ember.computed.mapBy('model.report.taxonomy.metricDefinitions', 'label'),
    possibleMetrics: Ember.computed('_possibleMetrics', function () {
      return Ember.get(this, '_possibleMetrics').filter(function (n) {
        return !metricBlacklist.includes(n);
      });
    }),
    actions: {
      createModule: function createModule() {
        var selectedMetric = Ember.get(this, 'selectedMetric');

        // Can't make a module without a metric
        if (!selectedMetric) {
          return;
        }
        Ember.get(this, 'schemaEditor').addModule(selectedMetric, Ember.get(this, 'model.category'), Ember.get(this, 'model.subcategory'));

        // Toggle traits to refire on all clusters because we have new modules in schema
        Ember.get(this, 'model.report').recalculateTraits();

        // There is no need to reset the state of this component,
        // Because after adding new module to the schema,
        // module-grid's parsedMap will refire,
        // causing a new instance to be rendered
        // - JK May 2019
      }
    }
  });
});