define("affinio/components/modules/audience-details", ["exports", "moment", "affinio/utils/constants/date-formats"], function (_exports, _moment, _dateFormats) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // TODO remove unused vars, add moment
  var _default = _exports.default = Ember.Component.extend({
    // Element
    classNames: ['audience-details'],
    tagName: 'div',
    // Services
    tracker: Ember.inject.service(),
    path: Ember.inject.service(),
    // Computed Properties
    activity: Ember.computed('model.report.clusterAggregation.{averagePostsPerMonth.loaded,average_number_of_posts}', function () {
      var model = Ember.get(this, 'model');
      var averagePostsPerMonth = Ember.get(this, 'model.isReddit') ? Ember.get(this, 'model.report.clusterAggregation.average_number_of_posts') : Ember.get(this, 'model.report.clusterAggregation.averagePostsPerMonth.response.value');
      if (Ember.get(model, 'socialNetwork') === 'Reddit') {
        return averagePostsPerMonth;
      }
      if (averagePostsPerMonth) {
        return averagePostsPerMonth.toFixed(2);
      } else {
        return null;
      }
    }),
    // activity
    // TODO remove and use report model data props
    dateStart: Ember.computed('model.report.originalReportPeriod', 'path.currentVersion', function () {
      var currentVersion = parseInt(Ember.get(this, 'path.currentVersion'));
      var date;
      if (currentVersion || currentVersion === 0) {
        if (currentVersion === 0 && !Ember.get(this, 'model.report.versions.length')) {
          date = Ember.get(this, 'model.report.originalReportPeriod.start');
        } else {
          date = Ember.get(this, 'model.report.versions').findBy('version', currentVersion);
          date = date && (date.dateFinished || date.dateUpdated);
          if (date) {
            date = (0, _moment.default)(date).subtract(29, 'days');
          }
        }
      } else {
        date = Ember.get(this, 'model.report.originalReportPeriod.start');
      }
      return (0, _dateFormats.fullShortMonth)(date);
    }),
    // dateStart
    // TODO remove and use report model data props
    dateEnd: Ember.computed('model.report.originalReportPeriod', 'path.currentVersion', function () {
      var currentVersion = parseInt(Ember.get(this, 'path.currentVersion'));
      var date;
      if (currentVersion || currentVersion === 0) {
        if (currentVersion === 0 && !Ember.get(this, 'model.report.versions.length')) {
          date = Ember.get(this, 'model.report.originalReportPeriod.end');
        } else {
          date = Ember.get(this, 'model.report.versions').findBy('version', currentVersion);
          date = date && (date.dateFinished || date.dateUpdated);
        }
      } else {
        date = Ember.get(this, 'model.report.originalReportPeriod.end');
      }
      return (0, _dateFormats.fullShortMonth)(date);
    }) // dateEnd
  });
});