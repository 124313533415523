define("affinio/models/taxonomy", ["exports", "ember-data", "affinio/utils/data/array-attr"], function (_exports, _emberData, _arrayAttr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var hasMany = _emberData.default.hasMany;
  var attr = _emberData.default.attr;
  var Model = _emberData.default.Model;
  var objAttr = attr({
    defaultValue: function defaultValue() {
      return {};
    }
  });
  var _default = _exports.default = Model.extend({
    user: Ember.inject.service(),
    store: Ember.inject.service(),
    accountID: attr(),
    noReports: attr(),
    currentUserIsOwner: attr(),
    canEdit: attr(),
    meta: objAttr,
    builder: objAttr,
    schema: hasMany('taxonomies/category'),
    metricDefinitions: hasMany('taxonomies/metric-definition', {
      inverse: 'taxonomy'
    }),
    exportSchema: hasMany('taxonomies/export-definition'),
    dataSources: _arrayAttr.default
  });
});