define("affinio/components/modals/modal-feedback", ["exports", "affinio/components/modals/modal-base", "affinio/utils/mutations"], function (_exports, _modalBase, _mutations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _modalBase.default.extend({
    user: Ember.inject.service(),
    path: Ember.inject.service(),
    tracker: Ember.inject.service(),
    graph: Ember.inject.service(),
    detailsInput: '',
    subjectInput: '',
    emailInput: '',
    nameInput: '',
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments); // TODO: spread operator.
      this.$('input').focus(function () {
        this.select();
      });
      this.$('input').focus();
    },
    canSubmit: Ember.computed('user.account', 'subjectInput', 'detailsInput', 'emailInput', 'nameInput', function () {
      return !!(Ember.get(this, 'subjectInput') && Ember.get(this, 'detailsInput') && (Ember.get(this, 'user.account') || Ember.get(this, 'emailInput') && Ember.get(this, 'nameInput')));
    }),
    // TODO: I don't think we actually use this for anything?
    tweetLink: Ember.computed(function () {
      var via = encodeURIComponent('affinio');
      var hashtags = encodeURIComponent(['DevsAreGreat']);
      // TODO: maybe populate text param with sliced off subject / details?

      return "https://twitter.com/intent/tweet?via=".concat(via, "&hashtags=").concat(hashtags);
    }),
    actions: {
      ok: function ok() {
        var _this = this;
        var path = Ember.get(this, 'path');
        var email = Ember.get(this, 'user.account.email') || Ember.get(this, 'emailInput');
        var subject = Ember.get(this, 'subjectInput');
        Ember.get(this, 'tracker').track('User sent zendesk ticket', {
          user: email,
          currentCampaign: Ember.get(path, 'audienceID'),
          currentTab: Ember.get(path, 'currentTab'),
          currentPath: Ember.get(path, 'currentPath'),
          subject: subject
        });
        Ember.set(this, 'isSending', true);
        var footer = '--------------------\n' + "Submitted from: ".concat(window.location);
        Ember.get(this, 'graph').mutate(_mutations.default.createZendeskTicket(Ember.get(this, 'user.account.name') || Ember.get(this, 'nameInput'), email, subject, "".concat(Ember.get(this, 'detailsInput'), "\n").concat(footer))).then(function () {
          return _this.closeAction();
        });
      }
    }
  });
});