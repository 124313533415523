define("affinio/models/reports/instagram", ["exports", "ember-data", "affinio/models/reports/-base"], function (_exports, _emberData, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var hasMany = _emberData.default.hasMany;
  var belongsTo = _emberData.default.belongsTo;
  var _default = _exports.default = _base.default.extend({
    // Clusters
    clusters: hasMany('clusters/instagram'),
    _clusters: Ember.computed.alias('clusters'),
    clusterAggregation: belongsTo('clusters/instagram')
  });
});