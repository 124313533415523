define("affinio/routes/sources/new", ["exports", "affinio/mixins/document-title"], function (_exports, _documentTitle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_documentTitle.default, {
    documentTitle: 'Data Sources - Settings - Affinio',
    // Services
    graph: Ember.inject.service(),
    store: Ember.inject.service(),
    model: function model() {
      // Its not an expensive fetch, and will keep people up to date on preferences -JK Jun 2017
      return Ember.Object.create({
        stage: 'intro',
        supportedDataSourceConnections: ['Axciom', 'Datalogix', 'Oracle Data Cloud', 'Microsoft Dynamics'],
        dataSource: {
          link: ''
        },
        sources: {
          private: Ember.get(this, 'store').peekAll('taxonomy').rejectBy('meta.public').sortBy('id'),
          public: Ember.get(this, 'store').peekAll('taxonomy').filterBy('meta.public').sortBy('id')
        }
      });
    }
  });
});