define("affinio/models/taxonomies/data-source", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var hasMany = _emberData.default.hasMany;
  var Model = _emberData.default.Model;
  var _default = _exports.default = Model.extend({
    user: Ember.inject.service(),
    label: attr('string'),
    accountID: attr(),
    companyID: attr(),
    canEdit: attr(),
    columns: hasMany('taxonomies/data-source-column', {
      inverse: 'dataSource'
    }),
    format: attr('string', {
      defaultValue: 'csv'
    }),
    credentials: attr(),
    dataSetName: attr(),
    dataSetId: attr(),
    columnsCount: attr(),
    selected: attr() // needed for taxonomy-creator tests (as it doesn't have modal access) :( - JK Oct 2019
  });
});