define("affinio/helpers/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.add = add;
  _exports.default = void 0;
  function add() {
    var numbers = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    return numbers.reduce(function (prev, curr) {
      return prev + curr;
    }, 0);
  }

  // TODO:: Check if it is not required then delete.
  var _default = _exports.default = Ember.Helper.helper(add);
});