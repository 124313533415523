define("affinio/utils/constants/personas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.personas = void 0;
  var personas = _exports.personas = [{
    label: 'Gamers',
    description: 'General gaming',
    followersOf: ['NintendoAmerica', 'RockstarGames', 'Twitch', 'Playstation', 'Xbox', 'Ubisoft', 'YouTubeGaming', 'EA', 'Razer', 'IGN', 'Minecraft', 'Steam', 'EpicGames', 'GameSpot', 'CallofDuty', 'EAStarWars', 'SmashBrosUS'],
    bioKeywords: ['gamer', 'streamer', 'twitch', 'Xbox']
  }, {
    label: 'Esports Fans',
    description: 'General esports',
    followersOf: ['TwitchEsports', 'G2esports', 'ESL', 'R6esports', 'EAFIFAesports', 'RLEsports', 'ESPN_Esports', 'OGesports', 'TeamLiquid', 'FNATIC', 'TeamVitality', 'G2esports', 'FaZeClan', 'Cloud9', 'TSM', '100T_Esports', 'ROGUE', 'Luminosity', 'Astralisgg', 'EvilGeniuses'],
    bioKeywords: ['esports']
  }, {
    label: 'Gen Z',
    description: 'Generic Gen Z',
    followersOf: ['charlidamelio', 'dixiedamelio', 'jakepaul', 'LoganPaul', 'Olivia_Rodrigo', 'TomHolland1996', 'HollisterCo', 'AEO', 'GarageClothing', 'DynamiteStyle', 'UrbanOutfitters', 'DoveCameron', 'ArchieComics', 'TeenVogue', 'seventeen', 'neverhaveiever', 'DaBabyDaBaby', 'LilNasX', 'YaraShahidi', 'whoisaddison'],
    bioKeywords: ['stan', 'tik tok', 'snapchat', 'tiktok']
  }, {
    label: 'True Crime Fans',
    description: 'Movie or docuseries, nonfiction',
    followersOf: ['MakingAMurderer', 'serial', 'MyFavMurder', 'case_file', 'TrueCrimeObsess', 'AMorbidPodcast', 'CrimeWatchDaily', 'DatelineNBC', '48hours', 'Unsolved', 'oxygen', 'DiscoveryID', 'upandvanished', 'TruthJusticePod', 'SwordAndScale', 'ACSFX'],
    bioKeywords: []
  }, {
    label: 'Crime Drama Fans',
    description: 'Movie or TV, fictional',
    followersOf: ['NarcosNetflix', 'BreakingBad', 'BetterCallSaul', 'OITNB', 'TrueDetective', 'SonsofAnarchy', 'QueenOnUSA', 'TheSinnerUSA', 'SHO_Jackie', 'thePeakyBlinder'],
    bioKeywords: []
  }, {
    label: 'Sci-Fi Fans',
    description: 'General sci-fi movie and TV',
    followersOf: ['Stranger_Things', 'SYFY', 'SyfyUK', 'WestworldHBO', 'StarTrek', 'StarTrekOnPPlus', 'StarTrekNetflix', 'bbcdoctorwho', 'DoctorWho_BBCA', 'starwars', 'StarWarsUK', 'themandalorian', 'SnowpiercerTV', 'dunemovie', 'TENETfilm', 'thexfiles', 'cwthe100', 'TheOrville', 'LovecraftHBO', 'NY_Comic_Con', 'Comic_Con', 'wandavision', 'Guardians'],
    bioKeywords: []
  }, {
    label: 'Upscale Woman',
    description: 'Likes upscale brands, college-educated, edgy Tv, 35+',
    followersOf: ['Harvard', 'Stanford', 'Princeton', 'BrownUniversity', 'Columbia', 'Cornell', 'Yale', 'dartmouth', 'CHANEL', 'Burberry', 'Dior', 'LouisVuitton', 'gucci', 'dolcegabbana', 'Versace', 'YSL', 'armani', 'Succession_HBO', 'Big_Little_Lies', 'NetflixTheQG', 'ScandalABC', 'SHO_Homeland', 'LittleFiresHulu', 'NetflixOzark', 'HoltRenfrew', 'Harrods', 'Bergdorfs', 'Nordstrom', 'BarneysNY', 'neimanmarcus', 'saks', 'Bloomingdales'],
    bioKeywords: ['mom', 'wife', 'sister', 'daughter', 'aunt', 'godmother', 'mama', 'she', 'her', 'alumna']
  }, {
    label: 'Historical Dramatics',
    description: 'Movie and TV',
    followersOf: ['bridgerton', 'TheCrownNetflix', 'DowntonAbbey', 'LittleWomen', 'SpanishPrincess', 'Sanditon', 'Outlander_STARZ', 'CallTheMidwife1', 'PoldarkTV', 'the_favourite', '1917', 'NetflixTheQG'],
    bioKeywords: []
  }, {
    label: 'Fantasy Fans',
    description: 'Movie and TV',
    followersOf: ['cw_spn', 'darkmaterials', 'daemonsanddust', 'americangodsus', 'MagiciansSYFY', 'witchernetflix', 'GoodOmensPrime', 'readyplayerone', 'HarryPotterFilm', 'FantasticBeasts', 'BeastsMovieUK', 'LOTRonPrime', 'ChaosWalking', 'ShadowhuntersTV'],
    bioKeywords: []
  }, {
    label: 'Action Fans',
    description: 'Movie and TV',
    followersOf: ['007', 'KingsmanMovie', 'TopGunMovie', 'GodzillaVsKong', 'MissionFilm', 'TheBoysTV', 'jackryanamazon', 'SEALTeamCBS', 'UmbrellaAcad', 'oceans8movie', 'KillingEve', 'Shooter_USA', 'NBCBlindspot', 'CovertAffairs', 'TakenMovies', 'JohnWickMovie', 'expendables', 'MadMaxMovie', 'WidowsMovie', 'Terminator2Mov', 'FastFuriousCR'],
    bioKeywords: []
  }, {
    label: 'Dramatics',
    description: 'TV Channels & Shows',
    followersOf: ['GreysABC', 'HandmaidsOnHulu', 'NBCNewAmsterdam', 'NBCThisisUs', 'LittleFiresHulu', 'Yellowstone', 'thewildsonprime', 'MrsAm_FXonHulu', 'euphoriaHBO', 'Succession_HBO', 'Big_Little_Lies', 'ScandalABC', 'NetflixOzark', 'NBCGoodGirls', 'CW_Riverdale', 'SuitsPeacock', 'GoodDoctorABC'],
    bioKeywords: []
  }, {
    label: 'Comedy Fans',
    description: 'Movie and TV',
    followersOf: ['ComedyCentral', 'ComedyCentralUK', 'NetflixIsAJoke', 'nbcsnl', 'nbcbrooklyn99', 'bigmouth', 'TheSimpsons', 'SeinfeldTV', 'ComedyBangBang', 'hasanminhaj', 'mulaney', 'theofficetv', 'parksandrec', 'chrisrock', 'BoratSagdiyev', 'SouthPark', 'jackassworld', 'FullFrontalSamB', 'amyschumer', 'aliwong', 'iamwandasykes', 'TigNotaro', 'TiffanyHaddish', 'WhitneyCummings', 'Lesdoggg', 'iliza', 'loneblockbuster', 'billburr', 'boburnham', 'hannibalburess'],
    bioKeywords: []
  }, {
    label: 'Reality Bingers',
    description: 'TV',
    followersOf: ['KUWTK', 'BravoTVRHOBH', 'BravoTV', 'hayu', 'JerseyShore', 'WifeSwap', 'extremehome', '90DayFiance', 'Bethenny', 'LisaVanderpump', 'Porsha4real', 'Andy', 'TeenMom', 'QueerEye', 'syttd', 'ABCSharkTank', 'BravoTVRHOA', 'BravoTVRHOP', 'BravoTVRHONJ', 'BravoTVRHOC', 'BravoTVRHONY', 'BravoTVRHOSLC'],
    bioKeywords: []
  }, {
    label: 'Competition Show Fans',
    description: 'TV',
    followersOf: ['TheRWrp', 'RuPaulsDragRace', 'survivorcbs', 'BachelorABC', 'MASTERCHEFonFOX', 'Jeopardy', 'FamilyFeud', 'WheelofFortune', 'AmazingRaceCBS', 'NailedIt', 'BritishBakeOff', 'BravoTopChef', 'ProjectRunway', 'inkmaster', 'AmericanIdol', 'NBCTheVoice', 'AGT', 'DancingABC', 'CBSBigBrother', 'BigBrotherCA', 'HellsKitchenFOX', 'CircleNetflix', 'C4TheCircle', 'MasterChefUK', 'BGT', 'TheXFactor', 'DANCEonFOX'],
    bioKeywords: []
  }, {
    label: 'Horror Fans',
    description: 'Movie and TV',
    followersOf: ['AHSFX', 'castlerockhulu', 'haunting', 'NBCGrimm', 'Castle_ABC', 'WalkingDead_AMC', 'HorrorSociety', 'TheMist', 'ScreamMovies', 'ChuckyIsReal', 'CandymanMovie', 'ResidentEvil', 'StephenKing', 'TheConjuring', 'TweetYourScream', 'annabellemovie'],
    bioKeywords: []
  }, {
    label: 'Coming of Age Fans',
    description: 'Movie and TV',
    followersOf: ['CW_Riverdale', 'cwthe100', 'neverhaveiever', 'euphoriaHBO', 'PLLTVSeries', 'DeadlyClassSYFY', 'Heatherstv', 'Atypical', '13ReasonsWhy', 'EverythingSuxTV', 'Stranger_Things', 'toalltheboys', 'Twilight', 'artemisfowl', 'PeregrinesMovie'],
    bioKeywords: []
  }, {
    label: 'Podcast Listeners',
    description: 'Podcasts/Audio',
    followersOf: ['forpodcasters', 'spotifypodcasts', 'iHeartPodcasts', 'BuzzSprout', 'podbeancom', 'Simplecast', 'Stitcher', 'NPR', 'ApplePodcasts', 'WTFpod', 'LPontheleft', 'UpFirst', 'callherdaddy', 'SYSKPodcast', 'ArmchairExpPod', 'Slate', 'crookedmedia', 'Gimletmedia', 'radiotopia', 'iraglass', 'JadAbumrad', 'HardcoreHistory', 'KarinaLongworth', 'RememberThisPod', 'benshapiro', 'KarenKilgariff', '2DopeQueens', 'callyrgf'],
    bioKeywords: []
  }];
});