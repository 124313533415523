define("affinio/components/animated-orphans", ["exports", "ember-animated/components/animated-orphans"], function (_exports, _animatedOrphans) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _animatedOrphans.default;
    }
  });
});