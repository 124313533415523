define("affinio/templates/components/metric-finder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "niLvOI74",
    "block": "{\"symbols\":[\"metric\",\"&default\"],\"statements\":[[1,[27,\"input\",null,[[\"type\",\"placeholder\",\"value\",\"input\"],[\"text\",\"metric lookup\",[23,[\"metricQuery\"]],[27,\"perform\",[[23,[\"textSearch\"]]],null]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"foundMetrics\",\"length\"]]],null,{\"statements\":[[0,\"  \"],[7,\"section\"],[11,\"class\",\"found-metrics\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"foundMetrics\"]]],null,{\"statements\":[[4,\"link-to\",[\"reports.report.version.cluster.category.subcategory\",[22,1,[\"category\",\"value\"]],[22,1,[\"subcategory\",\"value\"]]],null,{\"statements\":[[0,\"        \"],[7,\"span\"],[11,\"class\",\"metric\"],[9],[1,[22,1,[\"module\",\"label\"]],false],[10],[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\"category\"],[9],[0,\"in \"],[1,[22,1,[\"category\",\"label\"]],false],[0,\" / \"],[1,[22,1,[\"subcategory\",\"label\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[14,2]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/metric-finder.hbs"
    }
  });
});