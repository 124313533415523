define("affinio/templates/components/asset-group-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "137WuEUl",
    "block": "{\"symbols\":[\"assetGroup\",\"asset\"],\"statements\":[[4,\"each\",[[23,[\"assetGroups\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"clearfix\"],[9],[0,\"\\n    \"],[7,\"h4\"],[12,\"class\",[28,[\"flex \",[27,\"if\",[[22,1,[\"folded\"]],\"open\"],null]]]],[9],[0,\"\\n      \"],[7,\"span\"],[11,\"role\",\"button\"],[9],[1,[22,1,[\"name\"]],false],[3,\"action\",[[22,0,[]],\"foldGroup\",[22,1,[]]]],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"role\",\"button\"],[11,\"class\",\"addAssetGroup\"],[9],[0,\"Add\"],[3,\"action\",[[22,0,[]],\"addAssetGroup\",[22,1,[]]]],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[0,\"    \"],[7,\"div\"],[12,\"class\",[28,[\"assetList \",[27,\"unless\",[[22,1,[\"folded\"]],\"hidden\"],null]]]],[9],[0,\"\\n      \"],[4,\"each\",[[22,1,[\"assets\"]]],null,{\"statements\":[[7,\"span\"],[11,\"class\",\"asset\"],[9],[0,\"@\"],[1,[22,2,[\"name\"]],false],[10]],\"parameters\":[2]},null],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"individual\"]]],null,{\"statements\":[[0,\"  \"],[7,\"label\"],[11,\"class\",\"clearfix\"],[9],[0,\"\\n    \"],[1,[27,\"auto-suggest\",null,[[\"inputValue\",\"endpoint\",\"submitAction\",\"allowCustomValue\"],[[23,[\"autoSuggestInput\"]],[27,\"if\",[[23,[\"isTwitter\"]],[23,[\"twEndpoint\"]],[23,[\"fbEndpoint\"]]],null],[27,\"action\",[[22,0,[]],\"addAdditionalAsset\"],null],[23,[\"isTwitter\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"p\"],[11,\"class\",\"helper\"],[9],[0,\"\\n    You may add tracked interests accounts from your \"],[4,\"if\",[[27,\"is-equal\",[[23,[\"type\"]],\"owned\"],null]],null,{\"statements\":[[0,\"own portfolio\"]],\"parameters\":[]},{\"statements\":[[0,\"industry\"]],\"parameters\":[]}],[0,\" to compare Interest results against.\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/asset-group-picker.hbs"
    }
  });
});