define("affinio/templates/components/modules/network-graph-export", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "cqtmaRyj",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[22,0,[]]]],[0,\"\\n\"],[4,\"if\",[[23,[\"model\",\"report\",\"isReaggregating\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\"],[11,\"class\",\"info-notification\"],[9],[0,\"\\n    \"],[7,\"span\"],[9],[0,\"This action is not available while your report is being updated. Please check back later.\"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[27,\"network-graph\",null,[[\"model\",\"clusters\",\"current\",\"showLabels\",\"labelBackground\",\"svg\",\"reportId\",\"updateSvgURI\",\"labelRoute\",\"noLabels\"],[[23,[\"model\"]],[23,[\"report\",\"clustersLite\"]],[27,\"if\",[[23,[\"model\",\"cluster\",\"isAggregate\"]],null,[23,[\"model\",\"cluster\",\"id\"]]],null],[23,[\"preferences\",\"showVisualizationLabels\"]],[23,[\"preferences\",\"networkRectBg\"]],[23,[\"model\",\"report\",\"clusterSVGURL\",\"response\",\"value\"]],[23,[\"report\",\"id\"]],[27,\"action\",[[22,0,[]],\"updateSvgURI\"],null],\"reports.report.version.cluster.category.subcategory\",true]]],false],[0,\"\\n\"],[4,\"if\",[[23,[\"model\",\"report\",\"clusterSVGURL\",\"loaded\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"download-button\",null,[[\"hrefKey\",\"downloadKey\",\"label\",\"controller\"],[[23,[\"hrefKey\"]],[23,[\"downloadKey\"]],[23,[\"label\"]],[22,0,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/modules/network-graph-export.hbs"
    }
  });
});