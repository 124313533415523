define("affinio/components/focus-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.TextField.extend({
    click: function click(evt) {
      evt.preventDefault();
    },
    didInsertElement: function didInsertElement() {
      var _this = this;
      Ember.run.scheduleOnce('afterRender', function () {
        return _this.$().focus();
      });
    }
  });
});