define("affinio/components/report-builder/numeric-input", ["exports", "affinio/mixins/input-module", "affinio/utils/get-with-default"], function (_exports, _inputModule, _getWithDefault) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_inputModule.default, {
    classNames: ['numeric-input'],
    condition: 'equal',
    customNumericConditions: Ember.A(['greater than', 'greater than or equal', 'lesser than', 'lesser than or equal', 'equal']),
    showAdvancedFilters: Ember.computed('moduleSchema.advanced', function () {
      return (0, _getWithDefault.default)(this, 'moduleSchema.advanced', true);
    }),
    actions: {
      addCustomFilter: function addCustomFilter() {
        // if we already have an add action use that instead
        var customAddAction = Ember.get(this, 'moduleSchema.addAction');
        if (typeof customAddAction === 'function') {
          return customAddAction(this, Ember.get(this, 'model'));
        }
        var existingFilter = Ember.get(this, "model.filters.".concat(Ember.get(this, 'moduleSchema.label').camelize()));
        if (!existingFilter || !existingFilter.length) {
          Ember.set(this, "model.filters.".concat(Ember.get(this, 'moduleSchema.label').camelize()), []);
        }
        var filterObject = {};
        filterObject = {
          field: Ember.get(this, 'moduleSchema.label'),
          condition: Ember.get(this, 'condition'),
          value: Ember.get(this, 'filter'),
          datasourceid: Ember.get(this, 'moduleSchema.datasourceid')
        };
        Ember.set(this, 'filter', null);
        Ember.get(this, "model.filters.".concat(Ember.get(this, 'moduleSchema.label').camelize())).pushObject(filterObject);
        // I hate toggling here, but its needed to recompute if primary boolean can show up for custom within a data source
        // :thinking: - JK Aug 2019
        this.toggleProperty('model.filterChange');
      }
    }
  });
});