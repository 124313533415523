define("affinio/components/modules/top-interests", ["exports", "affinio/mixins/affinio-module", "affinio/utils/get-with-default"], function (_exports, _affinioModule, _getWithDefault) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_affinioModule.default, {
    // Element
    classNames: ['compact', 'top-interests'],
    tagName: 'div',
    classNameBindings: ['moduleClass', 'displayClass', 'moduleRequest.primaryMetric'],
    parentSelector: '.top-interests.grid',
    defaultClasses: Ember.computed('interestsLook', function () {
      return Ember.get(this, 'interestsLook') ? 'scrollable influencers' : 'influencers interests-vis-main chart';
    }),
    displayClass: Ember.computed('interestsLook', function () {
      return Ember.get(this, 'interestsLook') ? 'list' : 'grid';
    }),
    moduleClass: Ember.computed('interestsLook', function () {
      return Ember.get(this, 'interestsLook') ? 'interests-vis top-interests-module' : 'top-interests';
    }),
    interestsLook: Ember.computed.alias('moduleRequest.type'),
    // Services
    tracker: Ember.inject.service(),
    recordsMap: Ember.computed('model.report.recordsMap', function () {
      /* eslint-disable */
      // jsmap
      return Ember.get(this, 'model.report.recordsMap') ? Ember.get(this, 'model.report.recordsMap').get(Ember.get(this, 'primaryMetric')) : null;
      /* eslint-enable */
    }),
    // Computed Properties

    // TODO: This duplicates logic in cluster base model
    uniqueInterests: Ember.computed('slicedInfluencers.[]', 'model.report.recordsCollection.loaded', function () {
      var recordsInstanceKey = Ember.get(this, 'moduleRequest.recordsInstanceKey');
      if (Ember.get(this, 'model.report.recordsCollection.loaded')) {
        var recordsMap = Ember.get(this, 'model.report.recordsMap');
        if (recordsMap && recordsMap.has(Ember.get(this, 'primaryMetric'))) {
          var interests = Ember.get(this, 'slicedInfluencers');
          /* eslint-disable */
          // jsmap
          var interestsMap = recordsMap.get(Ember.get(this, 'primaryMetric'));
          /* eslint-enable */
          return interests.filter(function (int) {
            if (interestsMap.has(Ember.get(int, recordsInstanceKey))) {
              /* eslint-disable */
              // jsmap
              return interestsMap.get(Ember.get(int, recordsInstanceKey)).filter(function (r) {
                return r.normalizedScore !== 0;
              }).length === 1;
              /* eslint-enable */
            } else {
              return false;
            }
          });
        }
      }
      return [];
    }),
    // This is an overwrite of the metric cp in mixins/affinio-module
    // We do this because pinterest/twitter interests are a cp and not a metric model
    // When the API does the munging we do, this can go away
    // Until then we need this or slicedInfluencers won't refire on pinterest/twitter loading
    // This has the same dependencies as affinio-module (it won't work unless defined again)
    // With the addition of model.cluster.interests.response.[] for twitter/pinterest support
    metric: Ember.computed('model.{version,cluster.id,cluster.interests.response.[]}', 'moduleRequest', 'moduleRequest.{primaryMetric,metrics.@each.everyCluster}', function () {
      return this._super.apply(this, arguments);
    }),
    primaryMetric: Ember.computed('moduleRequest', 'model.subcategory.value', function () {
      return (0, _getWithDefault.default)(this, 'moduleRequest.primaryMetric', Object.values(Ember.get(this, 'moduleRequest.metrics').objectAt(0)).objectAt(0));
    }),
    slicedInfluencers: Ember.computed('metric.response.[]', 'moduleRequest.sliceSize', function () {
      var interests = Ember.get(this, 'metric.response');
      var sliceSize = Ember.get(this, 'moduleRequest.sliceSize') || 200;
      return interests && interests.sortBy('rank').slice(0, sliceSize);
    }),
    // slicedInfluencers

    actions: {
      setActiveInfluencer: function setActiveInfluencer(influencer) {
        Ember.set(this, 'activeInfluencer', influencer);
      }
    }
  });
});