define("affinio/templates/components/time-series", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "zBkFYGEH",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"id\",\"timeseries\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"data\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"bar\"],[9],[1,[27,\"moment-format\",[[23,[\"time\"]],\"MM-D\"],null],false],[0,\" - \"],[1,[21,\"count\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/time-series.hbs"
    }
  });
});