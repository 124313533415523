define("affinio/components/modules/engagement-timezone", ["exports", "affinio/mixins/affinio-module", "affinio/utils/auto-suggest"], function (_exports, _affinioModule, _autoSuggest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_affinioModule.default, {
    // Element
    classNames: ['engagement-timezone', 'card', 'module'],
    tagName: 'div',
    // Services
    preferences: Ember.inject.service(),
    timezoneEndpoint: (0, _autoSuggest.timezoneEndpoint)({
      inputKey: 'typedTimezone'
    }),
    actions: {
      setTimezone: function setTimezone(timezone) {
        Ember.set(this, 'preferences.timezone', timezone.label);
        Ember.set(this, 'typedTimezone', null);
      } // setTimezone
    }
  });
});