define("affinio/routes/reports/report/version/cluster/category", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    model: function model(_ref) {
      var _this = this;
      var category = _ref.category;
      var report = this.modelFor('reports.report');
      var cluster = this.modelFor('reports.report.version.cluster');
      var categoryRecord = Ember.get(this, 'store').peekRecord('taxonomies/category', "".concat(Ember.get(report, 'socialNetwork') !== 'Custom' ? Ember.get(report, 'socialNetwork') : Ember.get(report, '_taxonomy.id'), "_").concat(category));
      var tryValidInstead = function tryValidInstead() {
        var validCats = Ember.get(report, 'taxonomy.schema').filter(function (cat) {
          return cat.dependencyCheck({
            report: report,
            cluster: cluster
          });
        });
        if (validCats.length) {
          _this.transitionTo('reports.report.version.cluster.category', Ember.get(validCats, '0.value'));
        } else {
          _this.transitionTo('reports.report.version.cluster.category.subcategory', '404');
        }
      };

      // Ensure this category is within the schema
      if (!categoryRecord) {
        tryValidInstead();
      } else {
        // Do our dependency check. Normally this takes a report-state model, but we are too high up for that, so we mock it
        if (!categoryRecord.dependencyCheck({
          report: report,
          cluster: cluster
        })) {
          tryValidInstead();
        }
        return categoryRecord;
      }
    },
    afterModel: function afterModel(model, transition) {
      // If the user didn't enter any ids past category, still get them into the report
      if (transition.targetName === 'reports.report.version.cluster.category.index') {
        var report = this.modelFor('reports.report');
        var version = this.modelFor('reports.report.version');
        var cluster = this.modelFor('reports.report.version.cluster');
        var subcat = '404';
        var validSubcats = Ember.get(model, 'subcategories').filter(function (subcat) {
          return subcat.dependencyCheck({
            report: report,
            cluster: cluster
          });
        });
        if (validSubcats.length) {
          subcat = Ember.get(validSubcats, '0.value');
        }
        this.transitionTo('reports.report.version.cluster.category.subcategory', Ember.get(report, 'id'), version, Ember.get(cluster, 'id'), Ember.get(model, 'value'), subcat);
      }
    }
  });
});