define("affinio/components/report-builder/talkwalker-query", ["exports", "affinio/utils/queries", "affinio/mixins/input-module", "affinio/utils/get-with-default"], function (_exports, _queries, _inputModule, _getWithDefault) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  var _default = _exports.default = Ember.Component.extend(_inputModule.default, {
    store: Ember.inject.service(),
    app: Ember.inject.service(),
    graph: Ember.inject.service(),
    user: Ember.inject.service(),
    fileUpload: Ember.inject.service(),
    modalManager: Ember.inject.service(),
    notify: Ember.inject.service('notify'),
    source: 'TalkWalkerAPIToken',
    account: null,
    // Element
    classNames: ['talkwalker-query'],
    tagName: 'div',
    selection: null,
    file: Ember.computed.alias('model.filters.fileURL'),
    showWarningMessage: false,
    init: function init() {
      this._super.apply(this, arguments);
      if (!Ember.get(this, 'model.talkwalker')) {
        Ember.set(this, 'model.talkwalker', {
          project: null,
          sourcePanels: []
        });
      }
      Ember.set(this, 'fileUpload.progress', 0);
      this.loadProjects();
      if (!this.sourcePanels) {
        Ember.set(this, 'sourcePanels', []);
      }
      var project = Ember.get(this, 'model.talkwalker.project');
      if (project) {
        this.loadSourcePanels(project, false);
        Ember.set(this, 'selectedSourcePanels', Ember.get(this, 'model.talkwalker.sourcePanel') || []);
      }
      Ember.set(this, 'model.filters.isHandles', false);
    },
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    selectedSourcePanels: [],
    loadSourcePanels: function loadSourcePanels(project, showWarnMessage) {
      var _this = this;
      var notify = Ember.get(this, 'notify');
      Ember.get(this, 'graph').query(_queries.default.getTalkWalkerSourcePanels(project), true).then(function (response) {
        Ember.set(_this, 'sourcePanels', response.data.getTalkWalkerSourcePanels);
        if (Ember.get(_this, 'sourcePanels').length <= 0 && !showWarnMessage) {
          Ember.set(_this, 'showWarningMessage', true);
        } else {
          Ember.set(_this, 'showWarningMessage', false);
        }
      }).catch(function () {
        notify.error('Unable to load Source Panels. Please contact the support team.');
      });
    },
    loadProjects: function loadProjects() {
      var _this2 = this;
      Ember.get(this, 'graph').query(_queries.default.getTalkWalkerProjects(), true).then(function (response) {
        Ember.set(_this2, 'projects', response.data.getTalkWalkerProjects);
      });
    },
    disableUploadBtn: Ember.computed('eligibleAuthorsCount', function () {
      if (Ember.get(this, 'eligibleAuthorsCount') < 150) {
        return true;
      }
      return false;
    }),
    eligibleAuthorsCount: Ember.computed('selectedSourcePanels', function () {
      var _this3 = this;
      var totalSize = this.selectedSourcePanels.reduce(function (result, value) {
        var matchingPanels = Ember.get(_this3, 'sourcePanels').filter(function (item) {
          return item.id === value;
        });
        if (matchingPanels.length > 0) {
          var matchingPanelSizes = matchingPanels.map(function (item) {
            return item.size;
          });
          var sumOfSizes = matchingPanelSizes.reduce(function (accumulator, currentValue) {
            return accumulator + currentValue;
          });
          result += sumOfSizes;
        }
        return result;
      }, 0);
      return totalSize;
    }),
    createCsvFile: function createCsvFile(sourcePanelAuthors) {
      var csvContent = sourcePanelAuthors.join('\n');
      return new Blob([csvContent], {
        type: 'text/csv'
      });
    },
    showErrorMessage: function showErrorMessage(errorMessage) {
      Ember.get(this, 'modalManager').openModal('modals/modal-error', {
        title: 'Upload Error',
        reason: "Something went wrong during file upload.".concat(errorMessage),
        fix: 'Please try again'
      });
    },
    actions: {
      reloadProjects: function reloadProjects(apiKey) {
        if (apiKey) {
          this.loadProjects();
        } else {
          Ember.set(this, 'projects', []);
          this.send('projectChange', null);
        }
        Ember.set(this, 'model.filters.apiKey', !!apiKey);
      },
      projectChange: function projectChange(value) {
        if (value) {
          this.loadSourcePanels(value, false);
        } else {
          Ember.set(this, 'sourcePanels', []);
          Ember.set(this, 'showWarningMessage', false);
        }
        this.send('filterChanged', 'project', value);
        Ember.set(this, 'selectedSourcePanels', []);
        this.send('filterChanged', 'sourcePanel', null);
      },
      toggleSelectItem: function toggleSelectItem(event) {
        var itemId = event.target.value;
        if (this.selectedSourcePanels.includes(itemId)) {
          var itemIndex = this.selectedSourcePanels.indexOf(itemId);
          this.selectedSourcePanels.splice(itemIndex, 1);
        } else {
          this.selectedSourcePanels.push(itemId);
        }
        Ember.set(this, 'selectedSourcePanels', _toConsumableArray(this.selectedSourcePanels));
        this.send('filterChanged', 'sourcePanel', null);
        this.send('filterChanged', 'sourcePanel', this.selectedSourcePanels);
      },
      filterChanged: function filterChanged(filter, value) {
        Ember.set(this, "model.talkwalker.".concat(filter), value);
      },
      uploadCSV: function uploadCSV() {
        var _this4 = this;
        Ember.set(this, 'uploadingFile', true);
        Ember.get(this, 'graph').query(_queries.default.getTalkWalkerSourcePanelAuthors(Ember.get(this, 'model.talkwalker.project'), Ember.get(this, 'model.talkwalker.sourcePanel')), true).then(function (response) {
          if (response) {
            var file = _this4.createCsvFile(response.data.getTalkWalkerSourcePanelAuthors);
            _this4.fileUpload.upload(file, "Talkwalker".concat(Date.now()), 'Twitter CSV').then(function (response) {
              Ember.set(_this4, 'model.filters.fileID', response.id);
              Ember.set(_this4, 'model.filters.fileURL', response.id);
              Ember.set(_this4, 'model.filters.isHandles', false);
              _this4.toggleProperty('model.filterChange');
            }).catch(function (e) {
              var message = (0, _getWithDefault.default)(e, 'payload.errors.0.message', '');
              if (message.length) {
                message = "<br><br>".concat(message, "<br><br>");
              }
              Ember.get(_this4, 'tracker').track('Error on CSV Upload');
              _this4.showErrorMessage(message);
            }).then(function () {
              return Ember.set(_this4, 'uploadingFile', false);
            });
          } else {
            _this4.showErrorMessage('Unable to load Source Panel Authors.');
          }
        }).catch(function (e) {
          Ember.set(_this4, 'uploadingFile', false);
          var message = (0, _getWithDefault.default)(e, 'payload.errors.0.message', '');
          _this4.showErrorMessage(message);
        });
      },
      clearFile: function clearFile() {
        Ember.set(this, 'model.filters.fileID', null);
        Ember.set(this, 'model.filters.fileURL', null);
        Ember.set(this, 'uploadingFile', false);
        Ember.set(this, 'fileUpload.progress', 0);
        this.toggleProperty('model.filterChange');
      }
    }
  });
});