define("affinio/templates/settings/preferences", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "kk27Kbqq",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\"],[11,\"class\",\"content-proper\"],[9],[0,\"\\n  \"],[1,[27,\"modules/page-title\",null,[[\"title\"],[\"Preferences\"]]],false],[0,\"\\n\\n  \"],[1,[27,\"account-preferences\",null,[[\"account\"],[[23,[\"user\",\"account\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/settings/preferences.hbs"
    }
  });
});