define("affinio/templates/components/modules/members-profiles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "J64alLYD",
    "block": "{\"symbols\":[\"cluster\",\"member\",\"&default\"],\"statements\":[[14,3,[[22,0,[]]]],[0,\"\\n\"],[4,\"each\",[[23,[\"model\",\"report\",\"clusters\"]]],null,{\"statements\":[[4,\"if\",[[27,\"or\",[[27,\"is-equal\",[[23,[\"model\",\"cluster\",\"id\"]],[22,1,[\"id\"]]],null],[23,[\"model\",\"cluster\",\"isAggregate\"]]],null]],null,{\"statements\":[[4,\"each\",[[27,\"if\",[[23,[\"model\",\"requestsFulfilled\"]],[27,\"slice\",[0,[27,\"if\",[[23,[\"model\",\"cluster\",\"isAggregate\"]],[27,\"if\",[[27,\"gt\",[[23,[\"model\",\"report\",\"clusters\",\"length\"]],10],null],10,20],null],100],null],[22,1,[\"followerProfiles\",\"response\"]]],null],[27,\"repeat\",[[27,\"if\",[[23,[\"model\",\"cluster\",\"isAggregate\"]],[27,\"if\",[[27,\"gt\",[[23,[\"model\",\"report\",\"clusters\",\"length\"]],10],null],10,20],null],100],null],[27,\"hash\",null,[[\"profileImageURL\"],[\"/assets/images/twitter-user.png\"]]]],null]],null]],null,{\"statements\":[[0,\"      \"],[7,\"a\"],[11,\"class\",\"member-profile member\"],[11,\"target\",\"_blank\"],[11,\"rel\",\"noopener\"],[12,\"href\",[27,\"if\",[[23,[\"model\",\"requestsFulfilled\"]],[27,\"social-href\",[[22,2,[]],[23,[\"model\",\"report\",\"socialNetwork\"]]],null]],null]],[12,\"style\",[27,\"html-safe\",[[27,\"concat\",[\"border-color:\",[22,1,[\"color\"]],\";\"],null]],null]],[9],[0,\"\\n        \"],[1,[27,\"skeleton-img\",null,[[\"class\",\"src\",\"fallbackSrc\"],[\"member-image icon_img\",[27,\"or\",[[22,2,[\"profileImageURLHTTPS\"]],[22,2,[\"profileImageURL\"]]],null],\"/assets/images/twitter-user.png\"]]],false],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"pumpup\"],[9],[0,\" \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/modules/members-profiles.hbs"
    }
  });
});