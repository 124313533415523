define("affinio/models/report-state", ["exports", "ember-data", "affinio/utils/data/array-attr"], function (_exports, _emberData, _arrayAttr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  var attr = _emberData.default.attr;
  var Model = _emberData.default.Model;
  var _default = _exports.default = Model.extend({
    user: Ember.inject.service(),
    report: attr(),
    version: attr('number', {
      defualtValue: 0
    }),
    cluster: attr(),
    category: attr(),
    subcategory: attr(),
    metricLabelMap: attr(),
    // overridden with js Map in subcategory route

    // See metrics-route afterModel for explanation
    inFlightMetrics: _arrayAttr.default,
    inFlightMetricRequestsFulfilled: Ember.computed('inFlightMetrics.@each.loaded', function () {
      return Ember.get(this, 'inFlightMetrics').every(function (n) {
        return Ember.get(n, 'loaded');
      });
    }),
    requestCount: attr('number', {
      defualtValue: 0
    }),
    metricRouteRequestsFulfilled: Ember.computed.equal('requestCount', 0),
    doneLoadingAlwaysLoadMetrics: Ember.computed.not('report.alwaysLoadMetricsLoading'),
    requestsFulfilled: Ember.computed.and('metricRouteRequestsFulfilled', 'inFlightMetricRequestsFulfilled', 'doneLoadingAlwaysLoadMetrics'),
    recordsMapLoaded: Ember.computed('report.{taxonomy.meta.loadRecordsMap,recordsCollection.loaded}', function () {
      if (Ember.get(this, 'report.taxonomy.meta.loadRecordsMap')) {
        return Ember.get(this, 'report.recordsCollection.loaded');
      }
      return true; // if custom needs better logic for screenshots, this is where you would put it
    }),
    is404: Ember.computed.equal('subcategory', '404'),
    // This is essentially an alias of report.taxonomy.schema
    // However it calls the dependency check which updates the valid property
    reportMap: Ember.computed('report.taxonomy.schema.[]', 'requestsFulfilled', 'cluster.id', 'category.subcategories.[]', 'subcategory.{id,modules.[]}', 'version', function () {
      var _this = this;
      // console.log('reportMap cp', get(this, 'report.taxonomy.schema'));
      var filteredCategories = Ember.get(this, 'report.taxonomy.schema').filter(function (category) {
        var valid = category.dependencyCheck(_this);
        category.save();
        return valid;
      }).map(function (category) {
        Ember.get(category, 'subcategories').filter(function (subcategory) {
          var valid = subcategory.dependencyCheck(_this);
          subcategory.save();
          return valid;
        }).map(function (subcategory) {
          Ember.get(subcategory, 'modules').forEach(function (module) {
            var valid = module.dependencyCheck(_this);
            module.save();
            return valid;
          });
          return subcategory;
        });
        return category;
      });
      return filteredCategories;
    }),
    // This is like reportMap but is pojo representation of the models with failing dependencies removed
    reportMapPojo: Ember.computed('report.taxonomy.schema', 'requestsFulfilled', 'cluster.id', 'category.subcategories.[]', 'subcategory.{id,modules.[]}', 'version', 'screenshotsToggle', function () {
      var _this2 = this;
      // console.log('reportMapPojo cp');
      var ret = Ember.get(this, 'report.taxonomy.schema').filter(function (category) {
        return category.dependencyCheck(_this2);
      }).map(function (category) {
        var pojoCat = category.toJSON();
        pojoCat.subcategories = Ember.get(category, 'subcategories').filter(function (subcategory) {
          return subcategory.dependencyCheck(_this2);
        }).map(function (subcategory) {
          var pojoSubcat = subcategory.toJSON();
          pojoSubcat.modules = Ember.get(subcategory, 'modules').filter(function (module) {
            return module.dependencyCheck(_this2);
          }).map(function (n) {
            return n.toJSON();
          });
          return pojoSubcat;
        });
        return pojoCat;
      });
      return ret;
    }),
    relatedBaselineReports: Ember.computed(function () {
      var reports = Ember.get(this, 'user.reports')
      // .reject((report) => get(report, 'id') === get(this, 'report.id')) // dont include your current report
      .rejectBy('id', Ember.get(this, 'report.id')).filterBy('state', 'finished').sortBy('dateUpdated') // sort with most recent atop
      .reverse().filterBy('taxonomy.id', Ember.get(this, 'report.taxonomy.id')); // only show those of the report type you're in

      var baselineReports = reports.filterBy('isBaseline');
      var nonBaselineReports = _.difference(reports, baselineReports);
      return [].concat(_toConsumableArray(baselineReports), _toConsumableArray(nonBaselineReports));
    })
  });
});