define("affinio/components/modals/modal-toolbox-asset-groups", ["exports", "affinio/components/modals/modal-base", "affinio/utils/queries"], function (_exports, _modalBase, _queries) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _modalBase.default.extend({
    graph: Ember.inject.service(),
    assetGroups: null,
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      Ember.get(this, 'graph').query(_queries.default.getAssetGroupsByID(this.id), true).then(function (response) {
        Ember.set(_this, 'assetGroups', response.data.toolboxGetAssetGroupByOwnerID);
      });
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.$('.close-modal').focus();
    }
  });
});