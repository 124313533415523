define("affinio/utils/data/array-attr", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var _default = _exports.default = attr({
    defaultValue: function defaultValue() {
      return Ember.A();
    }
  });
});