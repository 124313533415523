define("affinio/components/tags-suggestion-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Element
    classNames: ['tags-suggestion-list'],
    tagName: 'div',
    // Services
    user: Ember.inject.service(),
    tracker: Ember.inject.service(),
    graph: Ember.inject.service(),
    // Properties
    entities: null,
    // Lifecycle
    init: function init() {
      this._super.apply(this, arguments);
      if (!Ember.get(this, 'entities')) {
        Ember.set(this, 'entities', Ember.A());
      }
    },
    actions: {
      toggleStatus: function toggleStatus(suggestion) {
        Ember.set(suggestion, 'status', suggestion.status === 'approved' ? 'rejected' : 'approved');
      }
    }
  });
});