define("affinio/mixins/changed-relationship", ["exports", "affinio/utils/get-with-default"], function (_exports, _getWithDefault) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
  var _default = _exports.default = Ember.Mixin.create({
    save: function save() {
      var _this = this;
      // Save this model
      return this._super.apply(this, arguments).then(function () {
        // Save nested related models, update canonical
        _this.eachRelationship(function (name, meta) {
          if (meta.kind === 'hasMany') {
            Ember.get(_this, name).forEach(function (n) {
              return n.save();
            });
          }

          // See tests for description
          // else if (meta.kind === 'belongsTo') {
          //   let record = get(this, `${name}.content`);
          //   if (record) {
          //     console.log('record', record);
          //     record.save();
          //   }
          // }

          // Update canonical lists
          Ember.set(_this, "_internalModel._relationships.initializedRelationships.".concat(name, ".canonicalMembers"), Ember.get(_this, "_internalModel._relationships.initializedRelationships.".concat(name, ".members")).copy());
        });
      });
    },
    changedRelationships: function changedRelationships() {
      var _this2 = this;
      // ids are always undefined until initialized
      if (Ember.get(this, '_internalModel.dataHasInitialized') === false) {
        return;
      }
      var relationships = {};
      this.eachRelationship(function (name, meta) {
        var basePath = "_internalModel._relationships.initializedRelationships.".concat(name);
        var hasCanonical = Ember.get(_this2, "".concat(basePath, ".canonicalMembers.list.length")) > 0;
        if (meta.kind === 'belongsTo') {
          var newId = Ember.get(_this2, "".concat(name, ".id"));
          var initialId;
          if (hasCanonical) {
            var _Ember$get = Ember.get(_this2, "".concat(basePath, ".canonicalMembers.list")),
              _Ember$get2 = _slicedToArray(_Ember$get, 1),
              firstObject = _Ember$get2[0];
            if (firstObject) {
              initialId = firstObject.id;
            }
          }
          if (initialId !== newId) {
            relationships[name] = [initialId, newId];
          }
        } else if (meta.kind === 'hasMany') {
          var newIds = (0, _getWithDefault.default)(_this2, "".concat(basePath, ".members.list"), []).mapBy('id');
          var initialIds;
          if (hasCanonical) {
            initialIds = (0, _getWithDefault.default)(_this2, "".concat(basePath, ".canonicalMembers.list"), []).mapBy('id');
          } else {
            initialIds = [];
          }
          if (_.isEqual(initialIds, newIds) === false) {
            relationships[name] = [initialIds, newIds];
          }
        }
      });
      return relationships;
    }
  });
});