define("affinio/templates/components/profile-cover", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "4+LQ1Lzi",
    "block": "{\"symbols\":[\"profile\"],\"statements\":[[7,\"div\"],[11,\"class\",\"img-wrap\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"slicedProfiles\"]]],null,{\"statements\":[[4,\"if\",[[22,1,[\"iconImage\"]]],null,{\"statements\":[[0,\"      \"],[7,\"img\"],[11,\"alt\",\"profile cover\"],[12,\"src\",[27,\"html-safe\",[[27,\"get\",[[22,1,[]],[23,[\"imageKey\"]]],null]],null]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[27,\"ember-initials\",null,[[\"size\",\"class\",\"name\",\"title\"],[null,[27,\"if\",[[23,[\"isList\"]],\"list\"],null],[27,\"de-paren\",[[27,\"or\",[[22,1,[\"label\"]],[22,1,[\"id\"]],[22,1,[\"networkID\"]]],null]],null],[27,\"or\",[[22,1,[\"label\"]],[22,1,[\"id\"]],[22,1,[\"networkID\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null],[4,\"each\",[[23,[\"emptyImages\"]]],null,{\"statements\":[[0,\"    \"],[7,\"img\"],[12,\"src\",[27,\"html-safe\",[[27,\"get\",[[27,\"object-at\",[0,[23,[\"slicedProfiles\"]]],null],[23,[\"imageKey\"]]],null]],null]],[11,\"alt\",\"\"],[11,\"class\",\"empty\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"howManyMore\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"more\"],[9],[0,\"\\n      +\"],[1,[21,\"howManyMore\"],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"],[7,\"span\"],[11,\"class\",\"title\"],[9],[1,[21,\"title\"],false],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/profile-cover.hbs"
    }
  });
});