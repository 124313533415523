define("affinio/components/password-manager", ["exports", "affinio/utils/mutations"], function (_exports, _mutations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['password-manager'],
    tagName: 'div',
    notify: Ember.inject.service('notify'),
    user: Ember.inject.service(),
    tracker: Ember.inject.service(),
    graph: Ember.inject.service(),
    newPassword: '',
    repeatPassword: '',
    completionAction: null,
    headingText: 'Change your Password',
    passwordRequirments: Ember.computed('currentPassword', 'newPassword', 'repeatPassword', 'showEmail', 'email', function () {
      var ret = [{
        label: 'Has at least 8 characters',
        value: Ember.get(this, 'newPassword').length >= 8
      }, {
        label: 'Has an alphabetical letter',
        value: Ember.get(this, 'newPassword').match(/[A-Za-z]/)
      }, {
        label: 'Has a number or special character from !@#$%^&*()',
        value: Ember.get(this, 'newPassword').match(/[!@#$%^&*()]/) || Ember.get(this, 'newPassword').match(/\d+/)
      }, {
        label: 'Passwords match',
        value: Ember.get(this, 'newPassword.length') > 0 ? Ember.get(this, 'repeatPassword') === Ember.get(this, 'newPassword') : false
      }];
      if (Ember.get(this, 'requireCurrentPassword')) {
        ret.pushObject({
          label: 'Has current password',
          value: Ember.get(this, 'currentPassword')
        });
      }
      if (Ember.get(this, 'showEmail')) {
        ret.pushObject({
          label: 'Has email address',
          value: Ember.get(this, 'email')
        });
      }
      return ret;
    }),
    allChecksPassing: Ember.computed('passwordRequirments.@each.value', function () {
      return Ember.get(this, 'passwordRequirments').every(function (n) {
        return n.value;
      });
    }),
    actions: {
      submit: function submit(currentPassword, newPass, repeatPass, cb) {
        var _this = this;
        var currentNeededAndSet = Ember.get(this, 'requireCurrentPassword') ? currentPassword : true;
        if (currentNeededAndSet && newPass && repeatPass) {
          if (newPass === repeatPass) {
            var externalSubmitAction = Ember.get(this, 'submitAction');
            if (externalSubmitAction && typeof externalSubmitAction === 'function') {
              if (cb && typeof cb === 'function') {
                cb();
              }
              externalSubmitAction(newPass, Ember.get(this, 'email'));
            } else {
              Ember.get(this, 'graph').mutate(_mutations.default.updateAccountPassword(Ember.get(this, 'user.account.id'), newPass, currentPassword)).then(function () {
                Ember.get(_this, 'notify').success('Your Password Has Been Updated');
                if (cb && typeof cb === 'function') {
                  cb();
                }
                var cb2 = Ember.get(_this, 'completionAction');
                if (cb2 && typeof cb2 === 'function') {
                  cb2();
                }
                Ember.get(_this, 'tracker').track('Password Change', {
                  user: Ember.get(_this, 'user.email')
                });
              }).catch(function (err) {
                Ember.get(_this, 'notify').error(err.payload.errors[0].message);
              });
            }
          } else {
            Ember.get(this, 'tracker').track('Password Change Error', {
              which: 'passwords do not match'
            });
          }
        } else {
          Ember.get(this, 'tracker').track('Password Change Error', {
            which: 'not all fields filled out'
          });
        }
        Ember.$('html,body').animate({
          scrollTop: '0px'
        }, 300);
      }
    }
  });
});