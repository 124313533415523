define("affinio/templates/components/modules/cluster-summaries", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "jfSt8n5c",
    "block": "{\"symbols\":[\"cluster\",\"&default\"],\"statements\":[[14,2,[[22,0,[]]]],[0,\"\\n\"],[7,\"section\"],[11,\"class\",\"clusters\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"clusters\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"cluster-summary\",null,[[\"model\",\"cluster\",\"report\",\"network\",\"hasEditRights\",\"selectedSummaryView\",\"summaryViews\",\"selectedTraitType\",\"traitTypes\",\"selectedBaselineOption\",\"baselineOptions\"],[[23,[\"model\"]],[22,1,[]],[23,[\"model\",\"report\"]],[23,[\"model\",\"report\",\"socialNetwork\"]],[23,[\"model\",\"report\",\"hasEditRights\"]],[23,[\"selectedSummaryView\"]],[23,[\"summaryViews\"]],[23,[\"selectedTraitType\"]],[23,[\"traitTypes\"]],[23,[\"selectedBaselineOption\"]],[23,[\"baselineOptions\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/modules/cluster-summaries.hbs"
    }
  });
});