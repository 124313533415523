define("affinio/templates/components/custom-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "TnkboTrJ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[27,\"concat\",[\"checkbox\",[27,\"if\",[[23,[\"checked\"]],\" checked\"],null],[27,\"if\",[[23,[\"disabled\"]],\" disabled\"],null]],null]],[11,\"role\",\"button\"],[9],[0,\"\\n  \"],[7,\"label\"],[11,\"class\",\"check\"],[9],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"showSuggested\"]]],null,{\"statements\":[[0,\"    \"],[7,\"small\"],[9],[0,\"(suggested)\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[3,\"action\",[[22,0,[]],[27,\"if\",[[23,[\"disabled\"]],[27,\"optional\",[\"boop\"],null],[27,\"queue\",[[27,\"action\",[[22,0,[]],[27,\"toggle\",[\"checked\",[22,0,[]]],null]],null],[27,\"action\",[[22,0,[]],[27,\"optional\",[[23,[\"checkAction\"]]],null],[23,[\"checked\"]]],null]],null]],null]]],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/custom-checkbox.hbs"
    }
  });
});