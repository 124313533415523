define("affinio/mixins/metrics-route", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  var _default = _exports.default = Ember.Mixin.create({
    graph: Ember.inject.service(),
    tracker: Ember.inject.service(),
    afterModel: function afterModel(model) {
      var _this = this;
      this._super.apply(this, arguments);
      if (Ember.get(model, 'subcategory') === '404') {
        return;
      }
      // eslint-disable-next-line no-unused-vars
      var reportMap = Ember.get(model, 'reportMap');
      var desiredModules = Ember.get(model, 'subcategory.modules').filter(function (module) {
        return Ember.get(module, 'valid') || Ember.get(module, 'metricsRequiredForDependency');
      }); // if you don't get reportMap before this, the cp doesn't fire which sets/updates valid property
      var isExport = Ember.get(model, 'subcategory.value') === 'export';
      var cluster = Ember.get(model, 'cluster');
      var report = Ember.get(model, 'report');
      var everySubCluster = Ember.get(model, 'report.clusters');
      var everyCluster = Ember.get(model, 'report.allClusters');
      var clusterAggregation = Ember.get(model, 'report.clusterAggregation');

      // Export page requires all metrics are loaded for screenshots
      var desiredMetrics = isExport ? _.flatten(reportMap.mapBy('subcategories').map(function (n) {
        return n.mapBy('modules');
      }).map(function (n) {
        return n.map(function (m) {
          return m.mapBy('metrics');
        });
      })).compact() : _.flatten(desiredModules.mapBy('metrics')).compact();
      var groupedRequestedMetrics = _.groupBy(desiredMetrics, function (metric) {
        return Object.keys(metric)[0];
      });
      var allTheMetrics = Object.keys(groupedRequestedMetrics).map(function (metricContextType) {
        // console.log('metricContextType', metricContextType, groupedRequestedMetrics[metricContextType]);
        var metricKeys = groupedRequestedMetrics[metricContextType].map(function (n) {
          return n[metricContextType];
        });
        var metrics;
        switch (metricContextType) {
          case 'cluster':
            metrics = metricKeys.map(function (key) {
              return Ember.get(cluster, key);
            });
            break;
          case 'clusterAggregation':
            metrics = metricKeys.map(function (key) {
              return Ember.get(clusterAggregation, key);
            });
            break;
          case 'clusterWithAg':
            metrics = [].concat(_toConsumableArray(metricKeys.map(function (key) {
              return Ember.get(cluster, key);
            })), _toConsumableArray(metricKeys.map(function (key) {
              return Ember.get(clusterAggregation, key);
            })));
            break;
          case 'everyCluster':
            metrics = [];
            everyCluster.forEach(function (cluster) {
              return metricKeys.forEach(function (key) {
                return metrics.pushObject(Ember.get(cluster, key));
              });
            });
            break;
          case 'everySubCluster':
            metrics = [];
            everySubCluster.forEach(function (cluster) {
              return metricKeys.forEach(function (key) {
                return metrics.pushObject(Ember.get(cluster, key));
              });
            });
            break;
          case 'report':
            metrics = metricKeys.map(function (key) {
              return Ember.get(report, key);
            });
            break;
          case 'paired':
            metrics = metricKeys.map(function (key) {
              return Ember.get(cluster, key);
            });
            break;
        }
        // Some layouts have metrics for multiple networks, remove those ones
        metrics = metrics.compact();
        // console.log('woooo metrics', {metrics, subcategory, category});
        return metrics;
      });
      allTheMetrics = _.flatten(allTheMetrics).uniq();

      // There are some cases where a metric is loaded early (say with initial report load)
      // If you go to a page directly, and its only uses metrics previously loaded
      // that haven't resolved yet. Then model.requestsCount will be 0, thus tricking it
      // into believing the requests needed for this route are fulfilled
      // JK & JW - May 2018
      var nonInFlightMetrics = allTheMetrics.rejectBy('loading');
      if (allTheMetrics.length && !nonInFlightMetrics.length) {
        Ember.set(model, 'inFlightMetrics', allTheMetrics);
      } else {
        Ember.set(model, 'inFlightMetrics', []);
      }
      model.incrementProperty('requestCount');
      var metricStartTime = new Date().getTime();
      var metricsLoaded = nonInFlightMetrics.length;
      var metricsSkipped = allTheMetrics.filterBy('loading').length;
      Ember.get(this, 'graph').loadMetrics(allTheMetrics).then(function () {
        var timeTaken = (0, _moment.default)().diff(metricStartTime) / 1000;
        Ember.get(_this, 'tracker').track('metrics loaded', {
          metricsLoaded: metricsLoaded,
          metricsSkipped: metricsSkipped,
          timeTaken: timeTaken
        });
        model.decrementProperty('requestCount');
        Ember.set(model, 'allMetricsToLoad', allTheMetrics); // used as a dep prop on affinio-module's processedModuleSchema comp prop
      }).catch(function () {
        return model.decrementProperty('requestCount');
      });
    }
  });
});