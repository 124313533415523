define("affinio/models/notification", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var Model = _emberData.default.Model;
  var _default = _exports.default = Model.extend({
    acknowledged: attr('boolean'),
    ctaData: attr(),
    dateAcknowledged: attr('date'),
    dateCreated: attr('date'),
    dateUpdated: attr('date'),
    description: attr('string'),
    title: attr('string'),
    goodLink: Ember.computed('ctaData', function () {
      var ctaData = Ember.get(this, 'ctaData');
      if (ctaData.hasOwnProperty('link') && ctaData.link === 'audiences.audience') {
        return 'reports.report';
      } else {
        return Ember.get(ctaData, 'link');
      }
    })
  });
});