define("affinio/templates/components/modules/scatter-plot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "sDjGbmGI",
    "block": "{\"symbols\":[\"properties\",\"&default\"],\"statements\":[[14,2],[0,\"\\n\"],[4,\"if\",[[23,[\"moduleRequest\",\"moduleProperties\",\"visualizationProperties\"]]],null,{\"statements\":[[0,\"  \"],[7,\"select\"],[12,\"onchange\",[27,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"visualizationPropertiesKey\"]]],null]],[[\"value\"],[\"target.value\"]]]],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"moduleRequest\",\"moduleProperties\",\"visualizationProperties\"]]],null,{\"statements\":[[0,\"      \"],[7,\"option\"],[12,\"value\",[22,1,[\"label\"]]],[9],[1,[22,1,[\"label\"]],false],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"section\"],[11,\"id\",\"scatter-plot-chart\"],[9],[0,\"\\n  \"],[1,[27,\"ember-chart\",null,[[\"type\",\"data\",\"options\",\"height\"],[\"bubble\",[23,[\"chartData\"]],[23,[\"chartOptions\"]],[23,[\"chartOptions\",\"height\"]]]]],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/modules/scatter-plot.hbs"
    }
  });
});