define("affinio/templates/components/modules/powerpoint-export", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "G31mD2QQ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[22,0,[]]]],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"user\",\"account\",\"isViewerSeat\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\"],[11,\"class\",\"info-notification\"],[9],[0,\"\\n    \"],[7,\"span\"],[9],[0,\"Viewer users cannot export slides. If you want this permission, please contact your Affinio admin or account representative.\"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"model\",\"report\",\"isReaggregating\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\"],[11,\"class\",\"info-notification\"],[9],[0,\"\\n    \"],[7,\"span\"],[9],[0,\"This action is not available while your report is being updated. Please check back later.\"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"model\",\"requestsFulfilled\"]]],null,{\"statements\":[[0,\"    \"],[7,\"label\"],[9],[1,[27,\"input\",null,[[\"type\",\"checked\"],[\"checkbox\",[23,[\"clusterLevel\"]]]]],false],[0,\" \"],[4,\"if\",[[23,[\"model\",\"cluster\",\"isAggregate\"]]],null,{\"statements\":[[0,\"Audience\"]],\"parameters\":[]},{\"statements\":[[0,\"Cluster\"]],\"parameters\":[]}],[0,\" Summary\"],[10],[0,\"\\n    \"],[7,\"br\"],[9],[10],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"primary\"],[12,\"disabled\",[27,\"unless\",[[23,[\"clusterLevel\"]],\"disabled\"],null]],[12,\"onclick\",[27,\"perform\",[[23,[\"makePPTXRequest\"]]],null]],[9],[0,\"\\n      Export PPTX\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[21,\"loading-spinner\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/modules/powerpoint-export.hbs"
    }
  });
});