define("affinio/components/toolbox/toolbox-tool", ["exports", "affinio/utils/queries", "affinio/utils/affinio"], function (_exports, _queries, _affinio) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['toolbox-tool'],
    classNameBindings: ['isExpanded'],
    tagName: 'div',
    graph: Ember.inject.service(),
    notify: Ember.inject.service('notify'),
    store: Ember.inject.service(),
    user: Ember.inject.service(),
    tracker: Ember.inject.service(),
    modalManager: Ember.inject.service(),
    hasValidInputs: false,
    maxCount: 200,
    didReceiveAttrs: function didReceiveAttrs() {
      this.setAutoSuggestEndpoints();
      this.validateIsTags();
    },
    setAutoSuggestEndpoints: function setAutoSuggestEndpoints() {
      var _this = this;
      var inputs = Ember.get(this, 'inputs');
      if (inputs && inputs.length) {
        inputs.forEach(function (input, i) {
          if (!input.isPassword && !input.isTags) {
            Ember.defineProperty(_this, "".concat(input.label.classify(), "-endpoint"), input.endpoint({
              inputKey: "".concat(i, "-inputValue")
            }));
          }
        });
      }
    },
    tags: '',
    isTags: Ember.computed('inputs', function () {
      var inputs = Ember.get(this, 'inputs');
      return inputs.every(function (input) {
        return input.isTags;
      });
    }),
    validateIsTags: function validateIsTags() {
      if (Ember.get(this, 'isTags')) {
        Ember.set(this, 'hasValidInputs', true);
      }
    },
    isExpanded: Ember.computed('currentlyOpenTool', 'label', function () {
      return Ember.get(this, 'currentlyOpenTool') === Ember.get(this, 'label');
    }),
    newPassword: '',
    passwordRequirments: Ember.computed('newPassword', function () {
      return [{
        label: 'Has at least 8 characters',
        value: Ember.get(this, 'newPassword').length >= 8
      }, {
        label: 'Has an alphabetical letter',
        value: Ember.get(this, 'newPassword').match(/[A-Za-z]/)
      }, {
        label: 'Has a number or special character from !@#$%^&*()',
        value: Ember.get(this, 'newPassword').match(/[!@#$%^&*()]/) || Ember.get(this, 'newPassword').match(/\d+/)
      }];
    }),
    actions: {
      toggle: function toggle() {
        if (Ember.get(this, 'isExpanded')) {
          Ember.get(this, 'setCurrentlyOpenTool')(null);
        } else {
          Ember.get(this, 'setCurrentlyOpenTool')(Ember.get(this, 'label'));
        }
      },
      setInputValue: function setInputValue(result, index, context) {
        Ember.set(this, "".concat(context.label.classify(), "-value"), result);
        this.send('checkHasValidInputs');
      },
      removeInputValue: function removeInputValue(input) {
        Ember.set(this, input, null);
        Ember.set(this, 'hasValidInputs', false);
      },
      checkHasValidInputs: function checkHasValidInputs() {
        var _this2 = this;
        var values = [];
        var inputs = Ember.get(this, 'inputs');
        if (inputs && inputs.length) {
          inputs.forEach(function (input) {
            if (!input.isPassword) {
              values.push(Ember.get(_this2, "".concat(input.label.classify(), "-value")));
            }
          });
          var isValid = values.every(function (n) {
            return _typeof(n) === 'object';
          });
          Ember.set(this, 'hasValidInputs', isValid);
        }
      },
      submit: function submit(successMsg, confirm, successProperty, errorMessage, trackerInfo) {
        var _this3 = this;
        var values = [];
        var inputs = Ember.get(this, 'inputs');
        if (inputs && inputs.length) {
          inputs.forEach(function (input) {
            if (!input.isPassword && !input.isTags) {
              values.push(Ember.get(_this3, "".concat(input.label.classify(), "-value")).value);
            }
          });
        }
        if (Ember.get(this, 'hasPasswordInput')) {
          values.push(Ember.get(this, 'newPassword'));
        }
        if (Ember.get(this, 'isTags')) {
          values.push(Ember.get(this, 'tags'));
        }
        if (confirm) {
          Ember.get(this, 'modalManager').openModal('modals/modal-text', {
            title: 'Are you Sure?',
            message: confirm,
            okText: 'Delete',
            okAction: function okAction() {
              _this3.send('runMutation', successMsg, successProperty, values, inputs, errorMessage, trackerInfo);
            },
            cancelAction: true
          });
        } else {
          if (Ember.get(this, 'mutation') === 'benchmark') {
            var isCompany = inputs[0].type === 'Company';
            Ember.get(this, 'modalManager').openModal('modals/modal-toolbox-asset-groups', {
              id: values[0],
              isCompany: isCompany,
              okAction: function okAction() {},
              cancelAction: true
            });
          } else {
            this.send('runMutation', successMsg, successProperty, values, inputs, errorMessage, trackerInfo);
          }
        }
      },
      runMutation: function runMutation(successMsg, successProperty, values, inputs, errorMessage, trackerInfo) {
        var _this4 = this;
        var shareAction = Ember.get(this, 'shareAction');
        Ember.get(this, 'graph').mutate(Ember.get(this, 'mutation').apply(void 0, _toConsumableArray(values))).then(function (response) {
          if (successProperty) {
            successMsg = "".concat(successMsg, " ").concat(Ember.get(response, successProperty));
            // clear the input fields
            inputs.forEach(function (input) {
              _this4.send('removeInputValue', "".concat(input.label.classify(), "-value"));
            });
          }
          if (successMsg) {
            Ember.get(_this4, 'notify').success(successMsg);
          }
          if (trackerInfo) {
            Ember.get(_this4, 'tracker').track(trackerInfo.label, _defineProperty({}, trackerInfo.key, values[0]));
          }
        }).then(function () {
          var promise = Ember.RSVP.resolve();
          if (inputs && inputs.length) {
            inputs.forEach(function (input) {
              if (shareAction) {
                var reportId = Ember.get(_this4, "".concat(input.label.classify(), "-value")).value;
                var report = (0, _affinio.peekReport)(Ember.get(_this4, 'store'), reportId);
                if (report) {
                  promise = Ember.get(_this4, 'graph').query(_queries.default.updateReportPostShare(reportId, Ember.get(report, 'socialNetwork')));
                }
              }
            });
          }
          return promise;
        }).then(function () {
          if (shareAction) {
            Ember.get(_this4, 'user').toggleProperty('reportsUpdateByShare');
          }
        }).then(function () {
          if (inputs && inputs.length) {
            inputs.forEach(function (input) {
              if (Ember.get(_this4, "".concat(input.label.classify(), "-value"))) {
                Ember.set(_this4, "".concat(input.label.classify(), "-value"), null);
              }
              if (Ember.get(_this4, 'hasPasswordInput')) {
                Ember.set(_this4, 'newPassword', '');
              }
              if (Ember.get(_this4, 'isTags')) {
                Ember.set(_this4, 'tags', '');
              }
            });
          }
        }).catch(function (err) {
          if (errorMessage) {
            Ember.get(_this4, 'notify').error(errorMessage);
          } else {
            Ember.get(_this4, 'notify').error(err.message);
          }
        });
      }
    }
  });
});