define("affinio/mixins/piano-nav", ["exports", "ember-keyboard-shortcuts/mixins/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create(_route.default, {
    modalManager: Ember.inject.service(),
    keyboardShortcuts: {
      'shift+/': {
        action: 'showPianoNav',
        // action to trigger
        global: false,
        // whether to trigger inside input (default: true)
        preventDefault: true // (default: true)
      },
      'shift+left': {
        action: 'pianoLeft',
        // action to trigger
        global: false,
        // whether to trigger inside input (default: true)
        preventDefault: true // (default: true)
      },
      'shift+right': {
        action: 'pianoRight',
        // action to trigger
        global: false,
        // whether to trigger inside input (default: true)
        preventDefault: true // (default: true)
      },
      'shift+down': {
        action: 'pianoDown',
        // action to trigger
        global: false,
        // whether to trigger inside input (default: true)
        preventDefault: true // (default: true)
      },
      'shift+up': {
        action: 'pianoUp',
        // action to trigger
        global: false,
        // whether to trigger inside input (default: true)
        preventDefault: true // (default: true)
      },
      'shift+1': {
        action: 'pianoNormalize',
        // action to trigger
        global: false,
        // whether to trigger inside input (default: true)
        preventDefault: true // (default: true)
      },
      'shift+2': {
        action: 'pianoBarChart',
        // action to trigger
        global: false,
        // whether to trigger inside input (default: true)
        preventDefault: true // (default: true)
      },
      'shift+3': {
        action: 'pianoNicheRank',
        // action to trigger
        global: false,
        // whether to trigger inside input (default: true)
        preventDefault: true // (default: true)
      },
      'shift+4': {
        action: 'pianoWordCloud',
        // action to trigger
        global: false,
        // whether to trigger inside input (default: true)
        preventDefault: true // (default: true)
      },
      'shift+5': {
        action: 'pianoTimeline',
        // action to trigger
        global: false,
        // whether to trigger inside input (default: true)
        preventDefault: true // (default: true)
      },
      'shift+6': {
        action: 'pianoCompare',
        // action to trigger
        global: false,
        // whether to trigger inside input (default: true)
        preventDefault: true // (default: true)
      },
      'shift+7': {
        action: 'pianoMetricNormalize',
        // action to trigger
        global: false,
        // whether to trigger inside input (default: true)
        preventDefault: true // (default: true)
      }
    },
    actions: {
      showPianoNav: function showPianoNav() {
        Ember.get(this, 'tracker').track('Keyboard Nav Helper Opened', {
          path: Ember.get(this, 'path.currentPath')
        });
        Ember.get(this, 'modalManager').openModal('modals/modal-keynav', {
          title: 'Keyboard Shortcuts'
        });
      },
      pianoRight: function pianoRight() {
        Ember.get(this, 'tracker').track('Keyboard Nav: Right', {
          path: Ember.get(this, 'path.currentPath')
        });
        var nextLink;
        if (Ember.$('.sub-nav:not(".influencers-nav")') && Ember.$('.sub-nav:not(".influencers-nav")').children('.active').next('a').length) {
          nextLink = Ember.$('.sub-nav:not(".influencers-nav")').children('.active').next('a');
        } else if (Ember.$('.campaign-header nav.tabs') && Ember.$('.campaign-header nav.tabs').children('.active').next('a').length) {
          nextLink = Ember.$('.campaign-header nav.tabs').children('.active').next('a');
        } else {
          nextLink = Ember.$('.campaign-header nav.tabs').children('a').eq(0); // back to overview
        }
        nextLink.click();
      },
      pianoLeft: function pianoLeft() {
        Ember.get(this, 'tracker').track('Keyboard Nav: Left', {
          path: Ember.get(this, 'path.currentPath')
        });
        var prevLink;
        if (Ember.$('.sub-nav:not(".influencers-nav")') && Ember.$('.sub-nav:not(".influencers-nav")').children('.active').prev('a').length) {
          prevLink = Ember.$('.sub-nav:not(".influencers-nav")').children('.active').prev('a');
        } else if (Ember.$('.campaign-header nav.tabs') && Ember.$('.campaign-header nav.tabs').children('.active').prev('a').length) {
          prevLink = Ember.$('.campaign-header nav.tabs').children('.active').prev('a');
        } else {
          prevLink = Ember.$('.campaign-header nav.tabs').children('a:last-of-type'); // back to overview
        }
        prevLink.click();
      },
      pianoDown: function pianoDown() {
        Ember.get(this, 'tracker').track('Keyboard Nav: Down', {
          path: Ember.get(this, 'path.currentPath')
        });
        var nextLink;
        if (Ember.get(this, 'path.inCluster')) {
          // if next sub cluster go there
          if (Ember.$('.sub-clusters').children('a.active').length && Ember.$('.sub-clusters').children('a.active').next('a').length) {
            nextLink = Ember.$('.sub-clusters').children('a.active').next('a');
          }
          // else go to all cluster
          else {
            nextLink = Ember.$('.cluster-nav').children('a').eq(0);
          }
        }
        // choose first sub cluster
        else {
          nextLink = Ember.$('.sub-clusters').children('a').eq(0);
        }
        nextLink.click();
      },
      pianoUp: function pianoUp() {
        Ember.get(this, 'tracker').track('Keyboard Nav: Up', {
          path: Ember.get(this, 'path.currentPath')
        });
        var prevLink;
        if (Ember.get(this, 'path.inCluster')) {
          // if prev sub cluster go there
          if (Ember.$('.sub-clusters').children('a.active').length && Ember.$('.sub-clusters').children('a.active').prev('a').length) {
            prevLink = Ember.$('.sub-clusters').children('a.active').prev('a');
          }
          // else go to all cluster
          else {
            prevLink = Ember.$('.cluster-nav').children('a').eq(0);
          }
        }
        // choose last sub cluster
        else {
          prevLink = Ember.$('.sub-clusters').children('a:last-of-type');
        }
        prevLink.click();
      },
      pianoNormalize: function pianoNormalize() {
        Ember.get(this, 'tracker').track('Keyboard Nav: Normalized', {
          path: Ember.get(this, 'path.currentPath')
        });
        Ember.$('.metric-visualization').find('select').each(function (iter, el) {
          if (Ember.$(el).children('option[value="cluster-bias"]').length) {
            Ember.$(el).val('cluster-bias').change();
          }
        });
      },
      pianoBarChart: function pianoBarChart() {
        Ember.get(this, 'tracker').track('Keyboard Nav: Bar Chart', {
          path: Ember.get(this, 'path.currentPath')
        });
        Ember.$('.metric-visualization').find('select').each(function (iter, el) {
          if (Ember.$(el).children('option[value="term-relative"]').length) {
            Ember.$(el).val('term-relative').change();
          }
        });
      },
      pianoNicheRank: function pianoNicheRank() {
        Ember.get(this, 'tracker').track('Keyboard Nav: Niche Rank', {
          path: Ember.get(this, 'path.currentPath')
        });
        Ember.$('.metric-visualization').find('select').each(function (iter, el) {
          if (Ember.$(el).children('option[value="niche-ranked"]').length) {
            Ember.$(el).val('niche-ranked').change();
          }
        });
      },
      pianoWordCloud: function pianoWordCloud() {
        Ember.get(this, 'tracker').track('Keyboard Nav: Word Cloud', {
          path: Ember.get(this, 'path.currentPath')
        });
        Ember.$('.metric-visualization').find('select').each(function (iter, el) {
          if (Ember.$(el).children('option[value="word-cloud"]').length) {
            Ember.$(el).val('word-cloud').change();
          }
        });
      },
      pianoTimeline: function pianoTimeline() {
        Ember.get(this, 'tracker').track('Keyboard Nav: Timeline', {
          path: Ember.get(this, 'path.currentPath')
        });
        Ember.$('.metric-visualization').find('select').each(function (iter, el) {
          if (Ember.$(el).children('option[value="timeline"]').length) {
            Ember.$(el).val('timeline').change();
          }
        });
      },
      pianoCompare: function pianoCompare() {
        Ember.get(this, 'tracker').track('Keyboard Nav: Compare', {
          path: Ember.get(this, 'path.currentPath')
        });
        Ember.$('.metric-visualization').find('select').each(function (iter, el) {
          if (Ember.$(el).children('option[value="global-comparison"]').length) {
            Ember.$(el).val('global-comparison').change();
          }
        });
      },
      pianoMetricNormalize: function pianoMetricNormalize() {
        Ember.get(this, 'tracker').track('Keyboard Nav: Metric Normalized', {
          path: Ember.get(this, 'path.currentPath')
        });
        Ember.$('.metric-visualization').find('select').each(function (iter, el) {
          if (Ember.$(el).children('option[value="metric-normalized"]').length) {
            Ember.$(el).val('metric-normalized').change();
          }
        });
      }
    }
  });
});