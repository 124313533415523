define("affinio/components/zendesk-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Element
    classNames: ['zendesk-link'],
    attributeBindings: ['href', 'target'],
    tagName: 'a',
    target: '_blank',
    localStorage: Ember.inject.service(),
    tracker: Ember.inject.service(),
    helpCenterURL: 'https://help.audiense.com/knowledge/affinio',
    href: Ember.computed('path', 'localStorage.zendeskAuth', function () {
      return "".concat(Ember.get(this, 'helpCenterURL')).concat(Ember.get(this, 'path') ? Ember.get(this, 'path') : '', "?jwt=").concat(Ember.get(this, 'localStorage.zendeskAuth'));
    }),
    click: function click() {
      Ember.get(this, 'tracker').track('zendesk help center link clicked', {
        url: Ember.get(this, 'href')
      });
    }
  });
});