define("affinio/templates/components/preferences/traits-preferences", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "J5cozK6H",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"open\"]]],null,{\"statements\":[[0,\"  \"],[7,\"h5\"],[9],[0,\"\\n    Filter trait saturation\\n    \"],[7,\"a\"],[11,\"title\",\"Adjusting the saturation lets you experiment with how the top results change when you require more minimum member coverage.\"],[9],[0,\"\\n      ?\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[1,[27,\"multi-slider\",null,[[\"start\",\"min\",\"max\",\"step\",\"decimalPoints\",\"connect\",\"on-change\"],[[27,\"array\",[[27,\"or\",[[27,\"get\",[[23,[\"model\"]],\"report.clusterAggregation.lowerSaturationBound\"],null],0],null],[27,\"or\",[[27,\"get\",[[23,[\"model\"]],\"report.clusterAggregation.upperSaturationBound\"],null],100],null]],null],0,100,0.1,1,true,[27,\"action\",[[22,0,[]],\"sliderChanged\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"a\"],[11,\"role\",\"button\"],[11,\"class\",\"instance-search-toggle\"],[9],[4,\"if\",[[23,[\"open\"]]],null,{\"statements\":[[1,[27,\"font-awesome\",[\"chevron-up\"],null],false]],\"parameters\":[]},{\"statements\":[[1,[27,\"font-awesome\",[\"search\"],null],false]],\"parameters\":[]}],[3,\"action\",[[22,0,[]],[27,\"toggle\",[\"open\",[22,0,[]]],null]]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/preferences/traits-preferences.hbs"
    }
  });
});