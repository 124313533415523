define("affinio/components/cluster-summary", ["exports", "affinio/utils/get-with-default", "affinio/utils/mutations", "affinio/utils/affinio"], function (_exports, _getWithDefault, _mutations, _affinio) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Element
    classNames: ['cluster-summary'],
    classNameBindings: ['clusterID', 'active', 'indexLabel', 'suffixLabel'],
    // Services
    graph: Ember.inject.service(),
    localStorage: Ember.inject.service(),
    modalManager: Ember.inject.service(),
    path: Ember.inject.service(),
    tracker: Ember.inject.service(),
    // Properties
    activeTrait: null,
    // Computed Properties
    clusterID: Ember.computed.alias('cluster.id'),
    max: Ember.computed.alias('cluster.traits.max'),
    active: Ember.computed.and('inClusterSummary', 'trait'),
    indexLabel: Ember.computed('cluster.index', function () {
      return "index-".concat(Ember.get(this, 'cluster.index'));
    }),
    suffixLabel: Ember.computed('cluster.suffix', function () {
      return "suffix-".concat(Ember.get(this, 'cluster.suffix'));
    }),
    interestsRecordsMap: Ember.computed('report.recordsCollection.loaded', function () {
      if (Ember.get(this, 'report.recordsCollection.loaded')) {
        /* eslint-disable */
        // This one is an actual JS map and has its own get method
        return Ember.get(this, 'report.recordsMap').get('influencers');
        /* eslint-enable */
      } else {
        return new Map();
      }
    }),
    processedUnTraits: Ember.computed('cluster.unTraits', function () {
      return (0, _getWithDefault.default)(this, 'cluster.unTraits.data', []).map(function (t) {
        return t;
      });
    }),
    processedTraits: Ember.computed('cluster.traits', function () {
      return (0, _getWithDefault.default)(this, 'cluster.traits.data', []).map(function (t) {
        return t;
      });
    }),
    slicedInterests: Ember.computed('cluster.uniqueInterests', function () {
      var interests = Ember.get(this, 'cluster.uniqueInterests');
      return Ember.isArray(interests) && interests.length && interests.slice(0, 5);
    }),
    audienceDemographics: Ember.computed('report.clusterAggregation.demographics.loaded', function () {
      return Ember.get(this, 'report.clusterAggregation.demographics.response');
    }),
    clusterDemographics: Ember.computed('cluster.demographics.loaded', function () {
      return Ember.get(this, 'cluster.demographics.response');
    }),
    genderSummary: Ember.computed('clusterDemographics', 'audienceDemographics', function () {
      var clusterDemographics = Ember.get(this, 'clusterDemographics');
      var audienceDemographics = Ember.get(this, 'audienceDemographics');
      if (!clusterDemographics || !audienceDemographics) {
        return null;
      }
      var principalGender = Ember.get(clusterDemographics, 'maleTotal') > Ember.get(clusterDemographics, 'femaleTotal') ? 'male' : 'female';
      var diff = Math.abs(Ember.get(audienceDemographics, "".concat(principalGender, "Total")) - Ember.get(clusterDemographics, "".concat(principalGender, "Total")));
      var moreOrLess = Ember.get(clusterDemographics, "".concat(principalGender, "Total")) > Ember.get(audienceDemographics, "".concat(principalGender, "Total")) ? 'more' : 'less';
      return {
        principalGender: principalGender,
        diff: diff,
        moreOrLess: moreOrLess,
        clusterPercentGender: Ember.get(clusterDemographics, "".concat(principalGender, "Total")),
        audiencePercentGender: Ember.get(audienceDemographics, "".concat(principalGender, "Total"))
      };
    }),
    ageSummary: Ember.computed('clusterDemographics', 'audienceDemographics', function () {
      var clusterDemographics = Ember.get(this, 'clusterDemographics');
      var audienceDemographics = Ember.get(this, 'audienceDemographics');
      if (!clusterDemographics || !audienceDemographics) {
        return null;
      }
      var ageGroups = [{
        label: 'total1824',
        pseudoAge: 21
      }, {
        label: 'total2534',
        pseudoAge: 30
      }, {
        label: 'total3544',
        pseudoAge: 40
      }, {
        label: 'total4554',
        pseudoAge: 50
      }, {
        label: 'total5564',
        pseudoAge: 60
      }, {
        label: 'total65',
        pseudoAge: 65
      }];
      var ageTotals = ageGroups.map(function (ageGroup) {
        return {
          label: Ember.get(ageGroup, 'label'),
          value: clusterDemographics[Ember.get(ageGroup, 'label')],
          pseudoAge: Ember.get(ageGroup, 'pseudoAge')
        };
      });
      var audienceAgeTotals = ageGroups.map(function (ageGroup) {
        return {
          label: Ember.get(ageGroup, 'label'),
          value: audienceDemographics[Ember.get(ageGroup, 'label')],
          pseudoAge: Ember.get(ageGroup, 'pseudoAge')
        };
      });
      var perceivedAge = ageTotals.map(function (group) {
        return group.value * group.pseudoAge;
      }).reduce(function (m, n) {
        return m + n;
      }) / ageTotals.mapBy('value').reduce(function (m, n) {
        return m + n;
      });
      var audiencePerceivedAge = audienceAgeTotals.map(function (group) {
        return group.value * group.pseudoAge;
      }).reduce(function (m, n) {
        return m + n;
      }) / ageTotals.mapBy('value').reduce(function (m, n) {
        return m + n;
      });
      return {
        perceivedAge: perceivedAge,
        audiencePerceivedAge: audiencePerceivedAge
      };
    }),
    selectedSummaryData: Ember.computed('processedTraits', 'selectedSummaryView', 'selectedTraitType', 'selectedBaselineOption', 'report.recordsMap.size', function () {
      var data = Ember.get(this, Ember.get(Ember.get(this, 'summaryViews').findBy('label', Ember.get(this, 'selectedSummaryView')), 'data'));
      if (Ember.get(this, 'selectedTraitType') !== 'traits') {
        data = data.filterBy('metric', Ember.get(this, 'selectedTraitType'));
      }
      if (Ember.get(this, 'selectedBaselineOption') === 'baseline') {
        data = data.filterBy('timesMoreLikelyThanBaseline').sortBy('timesMoreLikelyThanBaseline').reverse();
      }
      return data;
    }),
    distinctivenessFromBaseline: Ember.computed('cluster.soWhat', function () {
      var soWhat = Ember.get(this, 'cluster.soWhat');
      return Ember.get(_affinio.baselineAudienceDistinctionRanges.find(function (range) {
        return soWhat > Ember.get(range, 'minSoWhat');
      }), 'level');
    }),
    baselineDistinctivenessColor: Ember.computed('distinctivenessFromBaseline', function () {
      // Doing a little extra work here to allow for baselineAudienceDistinctionRanges to grow in length/complexity.
      var audienceLevel = _affinio.baselineAudienceDistinctionRanges.indexOf(_affinio.baselineAudienceDistinctionRanges.findBy('level', Ember.get(this, 'distinctivenessFromBaseline')));
      var colorScale = d3.scale.linear().domain([0, _affinio.baselineAudienceDistinctionRanges.length * 0.5, _affinio.baselineAudienceDistinctionRanges.length]).range(['rgba(157,238,205,1)', 'rgba(220,220,220,1)', 'rgba(247, 167, 186,1)']);
      return colorScale(audienceLevel);
    }),
    actions: {
      setActiveInterest: function setActiveInterest(interest) {
        Ember.set(this, 'activeInterest', interest);
      },
      setActiveTrait: function setActiveTrait(trait) {
        Ember.set(this, 'activeTrait', trait);
      },
      openNameChangeInterface: function openNameChangeInterface(cluster) {
        var _this = this;
        window.console.log('TODO: cluster-summary.js lets pass in the openNameChangeInterface instead - jk', cluster);
        Ember.get(this, 'modalManager').openModal('modals/modal-edit-cluster', {
          title: 'Rename Cluster',
          inputValue: Ember.get(cluster, 'name'),
          clusterColor: Ember.get(cluster, 'color'),
          reportHasRecommendedNames: Ember.get(this, 'model.report.hasRecommendedNames'),
          recommendedName: Ember.get(cluster, 'recommendedName'),
          report: Ember.get(this, 'report'),
          cluster: cluster,
          okText: 'Save Changes',
          max: Ember.get(cluster, 'traits.max'),
          okAction: function okAction(newName) {
            var network = Ember.get(_this, 'report.isCustom') ? 'custom' : Ember.get(_this, 'report.socialNetwork').toLowerCase();
            Ember.get(_this, 'graph').mutate(_mutations.default.updateClusterName(Ember.get(cluster, 'id'), newName, network)).then(function () {
              return Ember.get(_this, 'tracker').track('cluster name changed', {
                newName: newName,
                cluster: Ember.get(cluster, 'id')
              });
            });
          }
        });
      }
    }
  });
});