define("affinio/components/report-builder/date-input", ["exports", "moment", "affinio/mixins/input-module"], function (_exports, _moment, _inputModule) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_inputModule.default, {
    classNames: ['date-input'],
    tagName: 'div',
    condition: 'on',
    customDateConditions: Ember.A(['before', 'after', 'on']),
    conentTo: null,
    contentFrom: null,
    today: Ember.computed(function () {
      return new Date((0, _moment.default)());
    }),
    minDate: Ember.computed(function () {
      return new Date((0, _moment.default)().subtract(29, 'days'));
    }),
    maxDate: Ember.computed(function () {
      return new Date((0, _moment.default)());
    }),
    disallowConflictingDates: Ember.observer('contentFrom', 'contentTo', function () {
      var _this = this;
      // If the diff between From and To is > 0, it means they're in an illegal order.
      // Reset To to be Today in this case.
      if (Ember.get(this, 'contentFrom') && Ember.get(this, 'contentTo') && (0, _moment.default)(Ember.get(this, 'contentFrom')).diff((0, _moment.default)(Ember.get(this, 'contentTo'))) > 0) {
        Ember.set(this, 'conflictingDates', true);
        Ember.set(this, 'contentTo', new Date((0, _moment.default)()));
        Ember.run.later(function () {
          return Ember.set(_this, 'conflictingDates', false);
        }, 5000);
      }
      if (Ember.get(this, 'contentFrom')) {
        Ember.set(this, 'model.filters.contentFrom', "".concat((0, _moment.default)(Ember.get(this, 'contentFrom'), 'MMMM Do').format('YYYYMMDD'), "0000"));
        this.toggleProperty('model.filterChange');
      }
      if (Ember.get(this, 'contentTo')) {
        Ember.set(this, 'model.filters.contentTo', "".concat((0, _moment.default)(Ember.get(this, 'contentTo'), 'MMMM Do').format('YYYYMMDD'), "2359"));
        this.toggleProperty('model.filterChange');
      }
    }),
    actions: {
      addCustomFilter: function addCustomFilter() {
        var existingFilter = Ember.get(this, "model.filters.".concat(Ember.get(this, 'moduleSchema.label').camelize()));
        if (!existingFilter || !existingFilter.length) {
          Ember.set(this, "model.filters.".concat(Ember.get(this, 'moduleSchema.label').camelize()), []);
        }
        var filterObject = {};
        filterObject = {
          field: Ember.get(this, 'moduleSchema.label'),
          condition: Ember.get(this, 'condition'),
          value: (0, _moment.default)(Ember.get(this, 'value')).format('YYYY-MM-DD'),
          datasourceid: Ember.get(this, 'moduleSchema.datasourceid')
        };
        Ember.set(this, 'filter', null);
        Ember.get(this, "model.filters.".concat(Ember.get(this, 'moduleSchema.label').camelize())).pushObject(filterObject);
        // I hate toggling here, but its needed to recompute if primary boolean can show up for custom within a data source
        // :thinking: - JK Aug 2019
        this.toggleProperty('model.filterChange');
      }
    }
  });
});