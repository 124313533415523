define("affinio/components/modules/traits-dashboard", ["exports", "affinio/utils/constants/metrics", "affinio/mixins/affinio-module"], function (_exports, _metrics, _affinioModule) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_affinioModule.default, {
    // Element
    classNames: ['traits-dashboard'],
    tagName: 'div',
    metrics: _metrics.default,
    path: Ember.inject.service(),
    actions: {
      updateSvgURI: function updateSvgURI(uri) {
        this.toggleProperty('forceUpdate');
        Ember.set(this, 'svgURI', uri);
      }
    }
  });
});