define("affinio/templates/components/cluster-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "HU6pXfHH",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"isInput\"]]],null,{\"statements\":[[0,\"  \"],[7,\"form\"],[11,\"autocomplete\",\"off\"],[9],[0,\"\\n    \"],[1,[27,\"input\",null,[[\"type\",\"placeholder\",\"value\",\"disabled\",\"focus-out\"],[\"text\",[23,[\"placeholderSafe\"]],[23,[\"inputValue\"]],[27,\"if\",[[23,[\"canEdit\"]],null,\"true\"],null],[27,\"action\",[[22,0,[]],\"changeName\"],null]]]],false],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],\"changeName\"],[[\"on\"],[\"submit\"]]],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"h2\"],[9],[1,[21,\"clusterName\"],false],[10],[0,\"\\n\"]],\"parameters\":[]}],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/cluster-panel.hbs"
    }
  });
});