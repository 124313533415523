define("affinio/templates/admin/groups/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "CDEeJtQ/",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h2\"],[9],[0,\"Groups\"],[10],[0,\"\\n\\n\"],[4,\"link-to\",[\"admin.groups.company.group\",[23,[\"model\",\"company\",\"id\"]],\"new\"],[[\"class\"],[\"create-new-button\"]],{\"statements\":[[0,\"Create Group \"],[1,[27,\"font-awesome\",[\"plus\"],null],false]],\"parameters\":[]},null],[0,\"\\n\\n\"],[1,[27,\"admin-list\",null,[[\"entities\",\"isGroups\",\"showGroups\",\"sectionSearch\",\"deleteGroup\"],[[23,[\"model\",\"company\",\"groups\"]],true,true,\"groups-search\",[27,\"route-action\",[\"showDeleteGroupModal\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/admin/groups/index.hbs"
    }
  });
});