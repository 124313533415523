define("affinio/components/range-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Element
    classNames: ['range-bar'],
    classNameBindings: ['metric'],
    tagName: 'div',
    // Services
    preferences: Ember.inject.service(),
    // Properties
    activeRange: null,
    TRANSITION_TIME: 750,
    showObserver: Ember.observer('activeRange', function () {
      if (Ember.get(this, 'activeRange') === 'max') {
        d3.select(".".concat(Ember.get(this, 'metric'), " stop.min")).transition(Ember.get(this, 'TRANSITION_TIME'))
        // .ease()
        .attr('stop-opacity', 0.1);
        d3.select(".".concat(Ember.get(this, 'metric'), " stop.median")).transition(Ember.get(this, 'TRANSITION_TIME'))
        // .ease()
        .attr('stop-opacity', 0.5);
        d3.select(".".concat(Ember.get(this, 'metric'), " stop.max")).transition(Ember.get(this, 'TRANSITION_TIME'))
        // .ease()
        .attr('stop-opacity', 1);
      } else if (Ember.get(this, 'activeRange') === 'min') {
        d3.select(".".concat(Ember.get(this, 'metric'), " stop.min")).transition(Ember.get(this, 'TRANSITION_TIME'))
        // .ease()
        .attr('stop-opacity', 1);
        d3.select(".".concat(Ember.get(this, 'metric'), " stop.median")).transition(Ember.get(this, 'TRANSITION_TIME'))
        // .ease()
        .attr('stop-opacity', 0.5);
        d3.select(".".concat(Ember.get(this, 'metric'), " stop.max")).transition(Ember.get(this, 'TRANSITION_TIME'))
        // .ease()
        .attr('stop-opacity', 0.1);
      } else {
        d3.select(".".concat(Ember.get(this, 'metric'), " stop.min")).transition(Ember.get(this, 'TRANSITION_TIME'))
        // .ease()
        .attr('stop-opacity', 1);
        d3.select(".".concat(Ember.get(this, 'metric'), " stop.median")).transition(Ember.get(this, 'TRANSITION_TIME'))
        // .ease()
        .attr('stop-opacity', 1);
        d3.select(".".concat(Ember.get(this, 'metric'), " stop.max")).transition(Ember.get(this, 'TRANSITION_TIME'))
        // .ease()
        .attr('stop-opacity', 1);
      }
    }),
    // highlighted percentage position
    posScale: Ember.computed('highlighted.value', 'postsTimeSeries', 'max', 'min', function () {
      return d3.scale.linear().domain([Ember.get(this, 'min'), Ember.get(this, 'max') - (Ember.get(this, 'max') - Ember.get(this, 'min')) / 2, Ember.get(this, 'max')]).range([0, 50, 100]);
    }),
    highlightValueStyle: Ember.computed('preferences.highlightedTime', 'highlighted.value', 'max', 'min', function () {
      var pos = Ember.get(this, 'posScale')(Ember.get(this, 'highlighted.value'));
      // console.log('HERE:', pos, get(this, 'highlighted.value'), get(this, 'max'), get(this, 'min'));
      return Ember.String.htmlSafe("\n        left: ".concat(pos, "%;\n      "));
    }),
    // Lifecycle
    init: function init() {
      this._super.apply(this, arguments);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var svg = d3.select(".".concat(Ember.get(this, 'metric'), " svg.bar"));
      var defs = svg.append('defs');
      var gradient = defs.append('linearGradient').attr('id', 'svgGradient').attr('x1', '0%').attr('x2', '100%').attr('y1', '0%').attr('y2', '0%');
      gradient.append('stop').attr('class', 'min').attr('offset', '0%').attr('stop-color', Ember.get(this, 'colorScale')(Ember.get(this, 'min'))).attr('stop-opacity', 1);
      gradient.append('stop').attr('class', 'median').attr('offset', '50%').attr('stop-color', Ember.get(this, 'colorScale')(Ember.get(this, 'max') - (Ember.get(this, 'max') - Ember.get(this, 'min')) / 2)).attr('stop-opacity', 1);
      gradient.append('stop').attr('class', 'max').attr('offset', '100%').attr('stop-color', Ember.get(this, 'colorScale')(Ember.get(this, 'max'))).attr('stop-opacity', 1);
      svg.append('rect').attr('width', '100%').attr('height', '100%').attr('fill', 'url(#svgGradient)');
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      var svg = d3.select(".".concat(Ember.get(this, 'metric'), " svg.bar"));
      var defs = d3.select('svg defs');
      var gradient = defs.select('linearGradient').attr('id', 'svgGradient').attr('x1', '0%').attr('x2', '100%').attr('y1', '0%').attr('y2', '0%');
      gradient.select('.min').attr('offset', '0%').attr('stop-color', Ember.get(this, 'colorScale')(Ember.get(this, 'min'))).attr('stop-opacity', 1);
      gradient.select('.median').attr('offset', '50%').attr('stop-color', Ember.get(this, 'colorScale')(Ember.get(this, 'max') - (Ember.get(this, 'max') - Ember.get(this, 'min')) / 2)).attr('stop-opacity', 1);
      gradient.select('.max').attr('offset', '100%').attr('stop-color', Ember.get(this, 'colorScale')(Ember.get(this, 'max'))).attr('stop-opacity', 1);
      svg.select('rect').attr('width', '100%').attr('height', '100%').attr('fill', 'url(#svgGradient)');
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
    },
    actions: {}
  });
});