define("affinio/templates/sign-in-ms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Fv3TfB3o",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\"],[11,\"href\",\"http://affinio.com\"],[11,\"class\",\"back-to-home\"],[9],[0,\"\\n  \"],[7,\"img\"],[11,\"src\",\"/assets/images/affinio.png\"],[11,\"alt\",\"Affinio\"],[11,\"title\",\"Back Home\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"section\"],[11,\"class\",\"wrapper sign-in-section clearfix\"],[9],[0,\"\\n  \"],[1,[27,\"sign-in\",null,[[\"email\",\"type\",\"ms\"],[[23,[\"email\"]],\"new\",true]]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[1,[21,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/sign-in-ms.hbs"
    }
  });
});