define("affinio/templates/components/site-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ywrF6sdI",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"non-embed-link\"],[9],[0,\"\\n  \"],[7,\"a\"],[12,\"href\",[21,\"url\"]],[11,\"target\",\"_blank\"],[11,\"rel\",\"noopener\"],[9],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"title\"],[9],[1,[27,\"if\",[[23,[\"title\"]],[23,[\"title\"]],[23,[\"url\"]]],null],false],[10],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"source_url\"],[9],[1,[21,\"url\"],false],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"image\"]]],null,{\"statements\":[[0,\"      \"],[7,\"img\"],[11,\"class\",\"link-image\"],[12,\"src\",[21,\"image\"]],[11,\"alt\",\"site image\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"description\"]]],null,{\"statements\":[[0,\"      \"],[7,\"p\"],[12,\"class\",[28,[\"description \",[27,\"if\",[[23,[\"image\"]],\"bumped\"],null]]]],[9],[0,\"\\\"\"],[1,[21,\"description\"],false],[0,\"\\\"\"],[10],[0,\"\\n    \"]],\"parameters\":[]},null],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"metric-visualization\"],[9],[0,\"\\n  \"],[1,[27,\"metric-instance\",null,[[\"model\",\"singleBar\",\"instance\",\"records\",\"nomenclature\",\"normalized\"],[[23,[\"model\"]],true,[23,[\"instance\"]],[27,\"map-get\",[[23,[\"recordsMap\"]],[23,[\"url\"]]],null],[27,\"if\",[[27,\"eq\",[[23,[\"model\",\"report\",\"socialNetwork\"]],\"Reddit\"],null],\"commenting members\",\"shares\"],null],\"cluster-bias\"]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/site-link.hbs"
    }
  });
});