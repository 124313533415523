define("affinio/templates/components/modal-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "NH6/HJO5",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"modal\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"class\",[28,[\"modal-container \",[27,\"camelize\",[[23,[\"preferences\",\"categoricalColors\"]]],null],\" shown clearfix\"]]],[9],[0,\"\\n    \"],[1,[27,\"component\",[[23,[\"modal\"]]],[[\"properties\"],[[23,[\"modalProperties\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/modal-display.hbs"
    }
  });
});