define("affinio/components/preferences/select-dropdown", ["exports", "affinio/mixins/module-preference", "affinio/utils/get-with-default"], function (_exports, _modulePreference, _getWithDefault) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
  var _default = _exports.default = Ember.Component.extend(_modulePreference.default, {
    // Required:
    // value - the currently selected option
    // options - the available options,
    //           ['Canada', 'USA', 'Mexico']
    //           [{value: 'word-cloud', label: 'Word Cloud'}]

    // Optional:
    // disabled - boolean
    // required - boolean
    // multiple - boolean
    // name - string

    tagName: 'select',
    attributeBindings: ['disabled', 'required', 'multiple', 'name'],
    didReceiveAttrs: function didReceiveAttrs() {
      Ember.set(this, 'noDefault', Ember.get(this, 'value') && !Ember.get(this, 'keys') ? false : true);
    },
    didInsertElement: function didInsertElement() {
      var value = Ember.get(this, 'value');
      var options = Ember.get(this, 'options');
      if (value === undefined && options && options.length > 0) {
        var _options = _slicedToArray(options, 1),
          firstOption = _options[0];
        Ember.set(this, 'value', firstOption.value);
      }
      this.$().val(value || '');
    },
    didRender: function didRender() {
      this.$().val(Ember.get(this, 'value'));
      if (Ember.get(this, 'isUserRoles') && Ember.get(this, 'value') === '') {
        Ember.set(this, 'value', Ember.get(this, 'originalRole'));
      }
    },
    willDestroyElement: function willDestroyElement() {
      if (Ember.get(this, 'isUserRoles')) {
        Ember.set(this, 'value', '');
      }
    },
    // For when value changes
    valueWatcher: Ember.observer('value', function () {
      var propValue = Ember.get(this, 'value');
      var elValue = this.element.value;
      if (elValue !== propValue) {
        this.element.value = propValue;
      }
    }),
    // For when element changes
    change: function change(event) {
      var _this = this;
      var oldValue = Ember.get(this, 'value');
      var newValue = event.target.value;
      if (oldValue !== newValue) {
        Ember.set(this, 'value', newValue);
        if (Ember.get(this, 'changeAction')) {
          Ember.run.later(function () {
            return Ember.get(_this, 'changeAction')();
          }, (0, _getWithDefault.default)(this, 'changeDelay', 0));
        }
      }
    }
  });
});