define("affinio/templates/components/range-slider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "LWnJkZ8R",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"input\",null,[[\"type\",\"min\",\"max\",\"step\",\"value\",\"input\"],[\"range\",[23,[\"min\"]],[23,[\"max\"]],[23,[\"step\"]],[23,[\"tempValue\"]],[27,\"perform\",[[23,[\"changeValue\"]]],null]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/range-slider.hbs"
    }
  });
});