define("affinio/components/icon-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Element
    tagName: 'label',
    classNames: ['icon-upload'],
    notify: Ember.inject.service(),
    actions: {
      imageLoaded: function imageLoaded(_ref) {
        var _this = this;
        var fileInfo = _ref.fileInfo;
        var reader = new FileReader();
        var maxSize = Ember.get(this, 'maxSize');
        reader.onload = function (e) {
          // TODO likely need to upload here instead of setting in memory

          if (maxSize && e.target.result.length > maxSize) {
            Ember.get(_this, 'notify').error('Icon file size too big');
            return;
          }
          Ember.set(_this, 'value', e.target.result);
        };
        reader.readAsDataURL(fileInfo);
      }
    }
  });
});