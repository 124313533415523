define("affinio/templates/components/affinio-table-cell/data-set-crud-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "OE7/G2IC",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"customFeatures\"]]],null,{\"statements\":[[4,\"link-to\",[\"aton.sources\",[27,\"query-params\",null,[[\"dataSetID\"],[[23,[\"row\",\"id\"]]]]]],[[\"title\"],[\"Create Data Source with this Data Set\"]],{\"statements\":[[0,\"    \"],[1,[27,\"font-awesome\",[\"plus\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/affinio-table-cell/data-set-crud-actions.hbs"
    }
  });
});