define("affinio/utils/week-year-calculator", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = weekYearCalculator;
  function weekYearCalculator(date) {
    var m = (0, _moment.default)(date);
    var week = m.week() - 1; // 1 based
    var month = m.month(); // 0 based... (╯°□°)╯︵ ┻━┻
    var year = m.year();

    // Handle the case of last week of December being week 0 of next year
    if (month === 11 && week === 0) {
      year = year + 1;
    }
    return "".concat(week, "_").concat(year);
  }
});