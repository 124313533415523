define("affinio/components/comparison-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['comparison-bar'],
    tagName: 'section',
    // Services
    preferences: Ember.inject.service(),
    path: Ember.inject.service(),
    // Properties
    noComparison: false,
    noBaseline: false,
    // Computed Properties
    activeColor: Ember.computed('tribeId', 'data', 'cluster.color', function () {
      var data = Ember.get(this, 'data');
      var tribeId = Ember.get(this, 'tribeId');
      if (tribeId === 'all') {
        return '#777777';
      } else {
        return tribeId !== null && tribeId !== undefined ? Ember.get(this, 'cluster.color') : data.active.hasOwnProperty('color') ? data.active.color : '#777777';
      }
    }),
    activeStyle: Ember.computed('data', 'activeColor', function () {
      var data = Ember.get(this, 'data');
      var color = Ember.get(this, 'skeletonState') ? 'rgba(0,0,0,0)' : Ember.get(this, 'activeColor');
      var width = data.active.percent > 0 && data.max > 0 ? (data.active.percent / data.max * 100).toFixed(2) : 0;
      return Ember.String.htmlSafe("background:".concat(color, ";width:").concat(width, "%;"));
    }),
    activeColorStyle: Ember.computed('data', 'activeColor', function () {
      return Ember.String.htmlSafe("color:".concat(Ember.get(this, 'activeColor'), ";"));
    }),
    comparisonStyle: Ember.computed('data', 'noComparison', function () {
      if (!Ember.get(this, 'noComparison')) {
        var data = Ember.get(this, 'data');
        var width = data && data.comparison ? data.comparison.percent > 0 && data.max > 0 ? (data.comparison.percent / data.max * 100).toFixed(2) : 0 : 0;
        return Ember.String.htmlSafe("width:".concat(width, "%;"));
      } else {
        return Ember.String.htmlSafe('');
      }
    }),
    baselineStyle: Ember.computed('data', 'noBaseline', function () {
      if (!Ember.get(this, 'noBaseline')) {
        var data = Ember.get(this, 'data');
        var width = data && data.baseline ? data.baseline.percent > 0 && data.max > 0 ? (data.baseline.percent / data.max * 100).toFixed(2) : 0 : 0;
        return Ember.String.htmlSafe("width:".concat(width, "%;"));
      } else {
        return Ember.String.htmlSafe('');
      }
    }),
    activePercent: Ember.computed('data', function () {
      return "".concat(Ember.get(this, 'data.active.percent').toFixed(1), "%");
    }),
    comparisonPercent: Ember.computed('data', 'noComparison', function () {
      if (!Ember.get(this, 'noComparison')) {
        return "".concat(Ember.get(this, 'data.comparison.percent').toFixed(1), "%");
      } else {
        return '0%';
      }
    }),
    baselinePercent: Ember.computed('data', 'noBaseline', function () {
      if (!Ember.get(this, 'noBaseline')) {
        return "".concat(Ember.get(this, 'data.baseline.percent').toFixed(1), "%");
      } else {
        return '0%';
      }
    })
  });
});