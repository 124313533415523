define("affinio/components/affinio-table-cell/data-source-crud-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['data-source-crud-actions'],
    app: Ember.inject.service(),
    customFeatures: Ember.computed.alias('app.ENV.features.custom')
  });
});