define("affinio/components/drag-wrapper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['drag-wrapper'],
    attributeBindings: ['draggable'],
    classNameBindings: ['draggedOver', 'draggable', 'bigTarget', 'smallTarget'],
    tagName: 'div',
    dragOver: function dragOver(event) {
      event.preventDefault();
      Ember.set(this, 'draggedOver', true);
    },
    dragEnter: function dragEnter() {
      Ember.set(this, 'draggedOver', true);
    },
    dragLeave: function dragLeave() {
      Ember.set(this, 'draggedOver', false);
    },
    drop: function drop() {
      Ember.set(this, 'draggedOver', false);
      var dropped = Ember.get(this, 'dropped');
      if (dropped && typeof dropped === 'function') {
        dropped();
      }
    }
  });
});