define("affinio/components/modules/segment-audience-builder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Element
    classNames: ['segment-audience-builder'],
    tagName: 'div',
    // Services
    user: Ember.inject.service(),
    tracker: Ember.inject.service(),
    // Properties
    myArray: null,
    // Computed Properties
    account: Ember.computed.alias('user.account'),
    // Lifecycle
    init: function init() {
      this._super.apply(this, arguments);
      // If this component uses an array and it needs to be independent of other component instances, set it here
      Ember.set(this, 'myArray', Ember.A());
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
    },
    actions: {
      testAction: function testAction() {
        // console.log('segment-audience-builder.js testAction()');
      }
    }
  });
});