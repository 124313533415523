define("affinio/utils/report-builder/twitter/tweet-content", ["exports", "affinio/utils/auto-suggest", "affinio/utils/report-builder/twitter/bio-add-action", "affinio/utils/constants/string", "affinio/utils/report-builder/advanced-modules"], function (_exports, _autoSuggest, _bioAddAction, _string, _advancedModules) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    label: 'Tweet Content',
    naturalLanguageLabel: 'What they talk about',
    value: 'tweet-content',
    description: 'Find people based on what they have tweeted or retweeted within the past 30 days.',
    stages: [{
      label: 'Query',
      heading: 'Build your tweet query:',
      description: 'This report type requires a word-based query which can be entered using <strong>Classic Boolean</strong>. Optionally, you can filter your audience to people who tweeted within a subset of the past 30 days by setting a Tweet Date Range.',
      modules: [{
        value: 'boolean-input-optimizer',
        requiredFilters: ['contentQuery']
      }, {
        label: 'Tweet Date Range:',
        description: 'Adjust the date range to filter down to people who have tweeted within a subset of the past 30 days. Your report output will still cover a 30 day range.',
        value: 'date-input'
      }]
    }, {
      label: 'Attributes',
      heading: 'Apply additional audience attributes:',
      description: 'These attributes are optional for this report type.',
      modules: [{
        label: 'Handle(s):',
        description: 'Filter an audience down to those who follow a given handle(s). If the handle does not appear as a recommendation, it can still be added by entering the full handle into the field and clicking Add. Input one handle at a time. Toggle AND/OR operators between multiple handles.',
        value: 'auto-suggest-input',
        valueKey: 'requestedFollowersOf',
        endpoint: _autoSuggest.twitterHandlesEndpoint,
        placeholder: '@nike',
        addAction: function addAction(inputController, model, result) {
          var existingFollowersOf = Ember.get(model, 'filters.followersOf');
          var resultScreenName = Ember.get(result, 'label');
          if (!existingFollowersOf) {
            Ember.set(model, 'filters.followersOf', []);
          }
          if (!Ember.get(model, 'filters.followersOf').includes(resultScreenName.toLowerCase())) {
            Ember.get(model, 'filters.followersOf').pushObject(resultScreenName);
          }
          Ember.set(inputController, 'requestedFollowersOf', '');
        }
      }, {
        label: 'Bio Keyword(s):',
        description: 'Filter an audience based on a word(s) used in their bios. Input phrases in quotes (e.g. "loyal fan"). Comma separate between multiple terms. AND/OR operators may be used. Format combinations in parentheses. E.g. (fan AND baseball) OR (fan AND hockey). E.g. (VP OR Director OR Manager) AND ("customer experience" OR "customer service").',
        value: 'text-input',
        valueKey: 'requestedBioKeyword',
        placeholder: 'Fashion, blogger, travel',
        modelFilterKey: 'bioKeywords',
        addAction: _bioAddAction.default
      }, {
        label: 'Location(s):',
        description: 'Filter an audience based on the location specified in their bio. Comma separate between multiple places. Use quotations when your entry contains spaces to capture the entire location name (i.e. "San Francisco" or "Bangor, Maine"). If you enter multiple locations and use the OR operator, a user will be included if they specify any of them.',
        value: 'text-input',
        valueKey: 'requestedBioLocation',
        placeholder: '"San Francisco", Belgium',
        modelFilterKey: 'bioLocations',
        addAction: _bioAddAction.default
      }, {
        label: 'URL:',
        description: 'Filter an audience based on specific URLs they have tweeted. Input one URL at a time. If you enter multiple URLs, a user will be included if they tweeted any of them (OR operator only).',
        value: 'text-input',
        valueKey: 'requestedURL',
        placeholder: 'http://www.theverge.com',
        addAction: function addAction(inputController, model) {
          var existingContentQuery = Ember.get(model, 'filters.contentQuery');
          var existingCount = 0;
          var urlInputValue = Ember.get(inputController, 'requestedURL').replace(/http(s)?:\/\/(www\.)?/gi, '');
          if (!existingContentQuery) {
            Ember.set(model, 'filters.contentQuery', []);
          } else {
            existingCount = existingContentQuery.mapBy('length').reduce(function (a, b) {
              return a + b;
            }, 0);
          }
          var splitUrl = urlInputValue.split(',');
          var urls = [];
          splitUrl.forEach(function (url) {
            while (url.indexOf(' ') === 0) {
              url = url.substr(1);
            }
            if (url.length > 0) {
              urls.push(url);
            }
          });
          var newlyAddedCount = urls.map(function (url) {
            return "url:".concat(url).length;
          }).reduce(function (a, b) {
            return a + b;
          }, 0);
          if (newlyAddedCount + existingCount > 2048) {
            // TODO this is hacky, and requires having the modalManager on text input
            // Until we move all add actions to the report-builder component proper this is likely
            // the best/easiest way to go - JK Sept 2018
            Ember.get(inputController, 'modalManager').openModal('modals/modal-text', {
              title: 'Query too large',
              message: 'Our system is optimized to support queries of 2048 characters or fewer. Please remove some keywords or operators',
              okText: 'Got it',
              okAction: true
            });
          } else {
            urls.forEach(function (url) {
              if (!Ember.get(model, 'filters.contentQuery').includes("url:".concat(url))) {
                Ember.get(model, 'filters.contentQuery').pushObject("url:".concat(url));
              }
            });
            Ember.set(inputController, 'requestedURL', '');
          }
        }
      }]
    }, {
      label: 'Options',
      heading: 'Adjust advanced report features:',
      description: 'Changing these options will not impact the makeup of your audience but can be used to fine tune the desired output of your report.',
      modules: [{
        label: 'Number of Clusters:',
        description: 'We auto-recommend a number based on estimated audience size, but you can adjust between 1-20 clusters. Select fewer clusters to break out high-level segments or more clusters to uncover niche segments. Small audiences are capped at 6 clusters (size 150-499).',
        value: 'number-input',
        min: 1,
        max: 20,
        valueKey: 'numberOfClusters',
        placeholder: 'Number of Clusters'
      }, {
        label: 'Track these Terms:',
        description: 'Track hashtags, mentions, and keywords that may fall outside of the top 20 terms we display by default. Only single terms without quotes are supported, and not phrases. Comma-separate between multiple terms.',
        value: 'text-input',
        valueKey: 'requestedTrackedTerm',
        placeholder: '#trip, @Nike',
        addAction: function addAction(inputController, model) {
          var existingTrackedTerms = Ember.get(model, 'filters.explicitWords');
          var trackedTermInputValue = Ember.get(inputController, 'requestedTrackedTerm');
          if (!existingTrackedTerms) {
            Ember.set(model, 'filters.explicitWords', []);
          }
          var terms = trackedTermInputValue.split(',').map(function (t) {
            while (t.indexOf(' ') === 0) {
              t = t.substr(1);
            }
            return t;
          });
          var cleanTerms = terms.filter(function (t) {
            return !(t.indexOf(' ') > 0);
          });
          if (cleanTerms.length) {
            cleanTerms.forEach(function (t) {
              var type;
              if (t.lastIndexOf('#', 0) === 0) {
                type = 'hashtag';
              } else if (t.lastIndexOf('@', 0) === 0) {
                type = 'mention';
              } else if (t.lastIndexOf('https:', 0) === 0 || t.lastIndexOf('http:', 0) === 0 || t.lastIndexOf('www.', 0) === 0 || t.lastIndexOf('bit.ly', 0) === 0 || t.lastIndexOf('t.co', 0) === 0 || t.indexOf('.com') > 0 || t.indexOf('.net') > 0 || t.indexOf('.org') > 0) {
                type = 'link';
              } else {
                type = 'keyword';
              }
              if (!Ember.get(model, 'filters.explicitWords').findBy('term', t)) {
                Ember.get(model, 'filters.explicitWords').pushObject({
                  term: t,
                  type: type
                });
              }
            });
            Ember.set(inputController, 'requestedTrackedTerm', '');
          } else {
            Ember.set(inputController, 'requestedTrackedTerm', '');
          }
        }
      }, {
        label: 'Interests\' Follower Range:',
        description: 'Only identify interests with a follower count that falls within a set range. This feature can help you find relevant micro-influencers. Setting a range will not impact the assignment of users to clusters.',
        value: 'min-max-input'
      }, _advancedModules.audienceSample, _advancedModules.metricDepth]
    }, {
      label: 'Set tracked interests',
      heading: 'Set tracked interests:',
      description: _string.default.reportBuilder.setBenchmarks,
      modules: [{
        label: null,
        value: 'benchmark-groups',
        placeholder: 'Tracked interests'
      }]
    }, {
      label: 'Confirm and Launch',
      heading: 'Confirm and launch:',
      description: 'Review summary and name report to launch.',
      modules: [{
        label: null,
        value: 'launch-input',
        placeholder: 'Report Name'
      }]
    }]
  };
});