define("affinio/components/report-builder/auto-suggest-input", ["exports", "affinio/mixins/input-module"], function (_exports, _inputModule) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_inputModule.default, {
    classNames: ['auto-suggest-input'],
    graph: Ember.inject.service(),
    ajax: Ember.inject.service(),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.setAutoSuggestEndpoints();
    },
    setAutoSuggestEndpoints: function setAutoSuggestEndpoints() {
      var endpoint = Ember.get(this, 'moduleSchema.endpoint');
      endpoint = endpoint instanceof Ember.ComputedProperty ? endpoint : Ember.get(this, 'moduleSchema.endpoint')({
        inputKey: Ember.get(this, 'moduleSchema.valueKey')
      });
      Ember.defineProperty(this, 'endpoint', endpoint);
    },
    allowCustomValue: Ember.computed('moduleSchema.customAdd', function () {
      var moduleSchema = Ember.get(this, 'moduleSchema');
      if (moduleSchema.hasOwnProperty('customAdd')) {
        return moduleSchema.customAdd;
      } else {
        return true;
      }
    }),
    actions: {
      runAddActionClosure: function runAddActionClosure(result, index) {
        Ember.get(this, 'moduleSchema.addAction')(this, Ember.get(this, 'model'), result, index);
        this.toggleProperty('model.filterChange');
      }
    }
  });
});