define("affinio/models/taxonomies/data-source-column", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var Model = _emberData.default.Model;
  var _default = _exports.default = Model.extend({
    dataSource: belongsTo('taxonomies/data-source'),
    label: attr('string'),
    multiple: attr('boolean', {
      defaultValue: false
    }),
    multipleDelimeter: attr('string'),
    // dataSet: arrayAttr,
    dataType: attr(),
    distinctPercent: Ember.computed('dataSet', function () {
      if (Ember.get(this, 'dataSet')) {
        var percent = (Ember.get(Ember.get(this, 'dataSet').uniq(), 'length') / Ember.get(this, 'dataSet').length * 100).toFixed(2);
        return parseFloat(percent);
      }
    }),
    uniqueValues: Ember.computed.equal('distinctPercent', 100),
    nonEmptyPercent: Ember.computed('dataSet', function () {
      if (Ember.get(this, 'dataSet')) {
        var percent = (Ember.get(this, 'dataSet').filter(function (x) {
          return !Ember.isEmpty(x);
        }).length / Ember.get(this, 'dataSet').length * 100).toFixed(2);
        return parseFloat(percent);
      }
    }),
    dataMetrics: Ember.computed('dataSet', 'dataType', function () {
      if (Ember.get(this, 'dataSet')) {
        var dataType = Ember.get(this, 'dataType');
        var min = dataType === 'integer' || dataType === 'decimal' ? Math.min.apply(Math, _toConsumableArray(Ember.get(this, 'dataSet').map(function (x) {
          return parseFloat(x);
        }).filter(function (z) {
          return !isNaN(z);
        }))) : dataType === 'category' || dataType === 'relationship' ? Math.min.apply(Math, _toConsumableArray(Ember.get(this, 'dataSet').map(function (x) {
          return x.length;
        }))) : '-';
        var max = dataType === 'integer' || dataType === 'decimal' ? Math.max.apply(Math, _toConsumableArray(Ember.get(this, 'dataSet').map(function (x) {
          return parseFloat(x);
        }).filter(function (z) {
          return !isNaN(z);
        }))) : dataType === 'category' || dataType === 'relationship' ? Math.max.apply(Math, _toConsumableArray(Ember.get(this, 'dataSet').map(function (x) {
          return x.length;
        }))) : '-';
        var mean = dataType === 'integer' || dataType === 'decimal' ? Ember.get(this, 'dataSet').map(function (x) {
          return parseFloat(x);
        }).filter(function (z) {
          return !isNaN(z);
        }).reduce(function (a, b) {
          return a + b;
        }, 0) / Ember.get(this, 'dataSet').length : dataType === 'category' || dataType === 'relationship' ? Ember.get(this, 'dataSet').map(function (x) {
          return x.length;
        }).reduce(function (a, b) {
          return a + b;
        }, 0) / Ember.get(this, 'dataSet').length : '-';
        return {
          min: min,
          max: max,
          mean: mean
        };
      }
    }),
    examples: Ember.computed('dataSet', function () {
      if (Ember.get(this, 'dataSet')) {
        return _.sample(Ember.get(this, 'dataSet').uniq(), 3);
      }
    })
  });
});