define("affinio/controllers/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    path: Ember.inject.service(),
    preferences: Ember.inject.service(),
    user: Ember.inject.service(),
    modalManager: Ember.inject.service(),
    errorHandler: Ember.inject.service(),
    localStorage: Ember.inject.service(),
    queryParams: ['JWT'],
    JWT: null,
    userNavOpen: false,
    // TODO JOEL: is this the best place for this?

    watchCurrentPath: Ember.observer('currentPath', function () {
      Ember.set(this, 'path.currentPath', Ember.get(this, 'currentPath'));
      // Used for displaying help message on audiences new route
      // TODO: Phil
      // @Phil should this be set here or on path service? - JK
      if (Ember.get(this, 'currentPath') === 'reports.new') {
        Ember.set(this, 'newAudienceNotice', true);
      } else {
        Ember.set(this, 'newAudienceNotice', false);
      }
    })
  });
});