define("affinio/components/interests-visualization", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // TODO NEPHEW DELETE THIS
  var _default = _exports.default = Ember.Component.extend({
    // Element
    classNames: ['interests-visualization'],
    tagName: 'div'
  });
});