define("affinio/templates/components/report-builder/launch-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "5DHm1Dmb",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[22,0,[]]]],[0,\"\\n\"],[7,\"form\"],[9],[0,\"\\n  \"],[1,[27,\"input\",null,[[\"value\",\"type\",\"placeholder\"],[[23,[\"model\",\"name\"]],\"text\",[23,[\"moduleSchema\",\"placeholder\"]]]]],false],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\"primary launch-btn\"],[12,\"disabled\",[27,\"if\",[[27,\"or\",[[23,[\"launching\"]],[27,\"not\",[[23,[\"canLaunch\"]]],null]],null],\"disabled\"],null]],[11,\"type\",\"submit\"],[9],[0,\"\\n    \"],[1,[21,\"launchButtonText\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[3,\"action\",[[22,0,[]],\"launch\"],[[\"on\"],[\"submit\"]]],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/report-builder/launch-input.hbs"
    }
  });
});