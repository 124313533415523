define("affinio/mixins/models/breakdown-overlap", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    // Used for compare/competitive-analysis
    modernBreakdown: Ember.computed('_breakdown.response.[]', function () {
      // console.log('_breakdown', get(this, '_breakdown'), get(this, '_breakdown.0'));
      return !!Ember.get(this, '_breakdown.response.0.users');
    }),
    breakdown: Ember.computed('_breakdown.response.[]', function () {
      var _this = this;
      var modernBreakdown = Ember.get(this, 'modernBreakdown');
      var breakdown = Ember.get(this, '_breakdown.response');
      if (breakdown.length) {
        // Get entries with 1 user only
        breakdown = breakdown.filterBy("".concat(modernBreakdown ? 'users' : 'screenNames', ".length"), 1);
        // Change into desired format
        var maxFollowers = modernBreakdown ? Ember.get(_.max(breakdown, function (n) {
          return Ember.get(n, 'users.0.followerCount');
        }), 'users.0.followerCount') : 0;
        breakdown = breakdown.map(function (n, i) {
          return {
            id: n.users[0].id ? n.users[0].id : "".concat(Ember.get(_this, 'report.id'), "_").concat(i),
            label: modernBreakdown ? n.users[0].screenName : n.screenNames[0],
            value: n.size,
            normalizingRatio: modernBreakdown ? 1 / (n.users[0].followerCount / maxFollowers) : 1
          };
        }).sortBy('normalizingRatio');

        // Add ones from aggregate not included
        if (!Ember.get(this, 'isAggregate')) {
          var _AggBreakdown = Ember.get(this, 'report.clusterAggregation._breakdown.response');
          _AggBreakdown.filter(function (item) {
            return item.users.length === 1; // the breakdown is big, includes cart. permutations -- go for only the singulars.
          }).forEach(function (n, i) {
            var id = n.users[0].id ? n.users[0].id : "".concat(Ember.get(_this, 'report.id'), "_").concat(i);
            var label = modernBreakdown ? n.users[0].screenName : n.screenNames[0];
            if (!breakdown.findBy('label', label)) {
              breakdown.pushObject({
                id: id,
                label: label,
                value: 0,
                normalizingRatio: 1
              });
            }
          });
        }
        return Ember.Object.create({
          normalized: breakdown.map(function (n) {
            return Ember.Object.create({
              id: n.id,
              label: n.label,
              value: (n.value * n.normalizingRatio).toFixed(0)
            });
          }),
          standard: breakdown.map(function (n) {
            return Ember.Object.create({
              id: n.id,
              label: n.label,
              value: n.value
            });
          })
        });
      } else {
        return Ember.Object.create({
          normalized: [],
          standard: []
        });
      }
    })
  });
});