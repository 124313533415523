define("affinio/templates/components/modules/traits-demographics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "bCGg2A4+",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[22,0,[]]]],[0,\"\\n\\n\"],[4,\"link-to\",[\"reports.report.version.cluster.category.subcategory\",\"members\",\"profiles\"],null,{\"statements\":[[0,\"Demographics \"],[1,[27,\"font-awesome\",[\"angle-double-right\"],null],false]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"p\"],[11,\"class\",\"helper\"],[9],[0,\"Age\"],[10],[0,\"\\n  \"],[1,[27,\"member-demographics\",null,[[\"type\",\"clusterData\",\"allData\",\"tribe\",\"top\",\"reportName\",\"tribeName\",\"skeletonState\"],[\"age\",[27,\"if\",[[23,[\"model\",\"cluster\",\"demographics\",\"loaded\"]],[23,[\"model\",\"cluster\",\"demographics\",\"response\"]],[23,[\"mockData\"]]],null],[27,\"if\",[[23,[\"model\",\"report\",\"clusterAggregation\",\"demographics\",\"loaded\"]],[23,[\"model\",\"report\",\"clusterAggregation\",\"demographics\",\"response\"]],[23,[\"mockData\"]]],null],true,true,[23,[\"model\",\"report\",\"name\"]],[23,[\"model\",\"cluster\",\"name\"]],[27,\"not\",[[23,[\"model\",\"cluster\",\"demographics\",\"loaded\"]]],null]]]],false],[0,\"\\n  \"],[7,\"p\"],[11,\"class\",\"helper\"],[9],[0,\"Gender\"],[10],[0,\"\\n  \"],[1,[27,\"member-demographics\",null,[[\"type\",\"clusterData\",\"allData\",\"tribe\",\"top\",\"reportName\",\"tribeName\",\"skeletonState\"],[\"gender\",[27,\"if\",[[23,[\"model\",\"cluster\",\"demographics\",\"loaded\"]],[23,[\"model\",\"cluster\",\"demographics\",\"response\"]],[23,[\"mockData\"]]],null],[27,\"if\",[[23,[\"model\",\"report\",\"clusterAggregation\",\"demographics\",\"loaded\"]],[23,[\"model\",\"report\",\"clusterAggregation\",\"demographics\",\"response\"]],[23,[\"mockData\"]]],null],true,true,[23,[\"model\",\"report\",\"name\"]],[23,[\"model\",\"cluster\",\"name\"]],[27,\"not\",[[23,[\"model\",\"cluster\",\"demographics\",\"loaded\"]]],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/modules/traits-demographics.hbs"
    }
  });
});