define("affinio/templates/components/behavior-graph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "7XOTGKZe",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"recordsArray\",\"isFulfilled\"]]],null,{\"statements\":[[0,\"  \"],[7,\"svg\"],[11,\"class\",\"icon-box\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[21,\"loading-spinner\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/behavior-graph.hbs"
    }
  });
});