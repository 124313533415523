define("affinio/components/modules/traits-summary", ["exports", "affinio/mixins/affinio-module"], function (_exports, _affinioModule) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_affinioModule.default, {
    // Element
    classNames: ['traits-summary'],
    tagName: 'div',
    // Services
    preferences: Ember.inject.service(),
    tracker: Ember.inject.service(),
    metric: Ember.computed('processedModuleSchema.preferences', 'model.requestsFulfilled', function () {
      return Ember.get(this, 'processedModuleSchema.preferences').findBy('value', 'select-dropdown').options[0].value;
    })
  });
});