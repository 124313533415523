define("affinio/templates/components/modules/audience-overlap", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "B/dmSXNM",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[22,0,[]]]],[0,\"\\n\\n\"],[0,\"\\n\"],[4,\"if\",[[23,[\"model\",\"requestsFulfilled\"]]],null,{\"statements\":[[4,\"if\",[[23,[\"noOverlap\"]]],null,{\"statements\":[[0,\"    There is no overlap for followers of \"],[1,[23,[\"preferences\",\"overlapSelected\"]],false],[0,\" accounts.\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[27,\"bar-graph\",null,[[\"instances\",\"metric\",\"recordsMap\",\"socialNetwork\",\"model\"],[[23,[\"overlaps\",\"records\"]],[23,[\"metric\"]],[27,\"map-get\",[[23,[\"model\",\"report\",\"recordsMap\"]],\"overlaps\"],null],[23,[\"model\",\"report\",\"socialNetwork\"]],[23,[\"model\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/modules/audience-overlap.hbs"
    }
  });
});