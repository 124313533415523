define("affinio/components/pagination-pager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Element
    classNames: ['pagination-pager'],
    tagName: 'nav',
    // Props to be passed in
    page: 0,
    // Note this component will modify
    pageSize: 5,
    itemCount: 25,
    // Computeds
    prevPageNeeded: Ember.computed.gt('page', 0),
    nextPageNeeded: Ember.computed('pages.length', 'page', function () {
      return Ember.get(this, 'page') < Ember.get(this, 'pages.length') - 1;
    }),
    pages: Ember.computed('page', 'pageSize', 'itemCount', function () {
      var _this = this;
      var page = Ember.get(this, 'page');
      var pageSize = Ember.get(this, 'pageSize');
      var itemCount = Ember.get(this, 'itemCount');
      var pages = [];
      var count = itemCount / pageSize;
      var lastIndex = parseInt(count);
      for (var i = 0; i < count; i++) {
        pages.push({
          first: pageSize * i + 1,
          last: i === lastIndex ? itemCount : pageSize * (i + 1),
          active: page === i
        });
      }

      // TODO not a huge fan of this here (but it matches how it was before)
      // unsure if an observer is any better - JK Jan 19
      if (page + 1 > pages.length) {
        Ember.run.scheduleOnce('afterRender', function () {
          return Ember.set(_this, 'page', 0);
        }); // eslint-disable-line ember/no-side-effects
      }
      return pages;
    }),
    actions: {
      changePage: function changePage(i) {
        if (i !== Ember.get(this, 'page') && i >= 0 && i <= Ember.get(this, 'pages.length') - 1) {
          Ember.set(this, 'page', i);
        }
      }
    }
  });
});