define("affinio/helpers/filter-type-description", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.filterTypeDescription = filterTypeDescription;
  function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
  function filterTypeDescription(_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
      string = _ref2[0],
      type = _ref2[1];
    if (type.value === 'custom') {
      return string;
    }
    if (typeof string === 'string') {
      if (string === 'followersOf') {
        return 'Handle(s)';
      } else if (string === 'bioKeywords') {
        return 'Bio Keyword(s)';
      } else if (string === 'bioLocations') {
        return 'Location(s)';
      } else if (string === 'explicitWords') {
        return 'Tracked Term(s)';
      } else if (string === 'fileURL') {
        return 'CSV File';
      } else if (string === 'fileID') {
        return 'File ID';
      } else if (string === 'contentQuery') {
        if (type.value === 'url-share') {
          return 'People who have shared the following site(s)';
        } else {
          return 'People who talk about';
        }
      } else if (string === 'queryID') {
        return 'Query';
      } else if (string === 'benchmarkGroups') {
        return 'Tracked Interests';
      } else {
        return "".concat(string.classify().split(/(?=[A-Z])/).join(' '));
      }
    } else {
      return string;
    }
  }
  var _default = _exports.default = Ember.Helper.helper(filterTypeDescription);
});