define("affinio/templates/components/preferences/select-inline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Oi+sEB2y",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[4,\"each\",[[23,[\"options\"]]],null,{\"statements\":[[0,\"  \"],[7,\"a\"],[12,\"class\",[28,[\"\\n      \",[27,\"dasherize\",[[22,1,[\"value\"]]],null],\"\\n        \",[27,\"if\",[[27,\"is-equal\",[[27,\"get\",[[23,[\"controller\"]],[23,[\"preference\",\"valueKey\"]]],null],[22,1,[\"value\"]]],null],\"active\"],null],\"\\n    \"]]],[11,\"role\",\"button\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"setControllerValue\",[22,1,[\"value\"]]],null]],[9],[0,\"\\n    \"],[7,\"i\"],[12,\"class\",[28,[\"\\n      \",[27,\"if\",[[27,\"eq\",[[22,1,[\"value\"]],\"grid\"],null],\"fa fa-th\"],null],\"\\n      \",[27,\"if\",[[27,\"eq\",[[22,1,[\"value\"]],\"list\"],null],\"fa fa-list\"],null],\"\\n      \"]]],[9],[10],[0,\"\\n    \"],[1,[22,1,[\"label\"]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/preferences/select-inline.hbs"
    }
  });
});