define("affinio/models/taxonomies/module", ["exports", "ember-data", "affinio/utils/data/array-attr", "affinio/mixins/dependency-check"], function (_exports, _emberData, _arrayAttr, _dependencyCheck) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var _default = _exports.default = Model.extend(_dependencyCheck.default, {
    modules: Ember.inject.service(),
    label: attr(),
    value: attr(),
    metrics: _arrayAttr.default,
    network: attr('string'),
    subcategory: belongsTo('taxonomies/subcategory'),
    preferences: Ember.computed('value', function () {
      var prefs = Ember.get(Ember.get(this, 'modules.schema'), Ember.String.camelize(Ember.get(this, 'value')));
      return Ember.get(prefs, 'preferences');
    }),
    sliceSize: attr({
      defaultValue: 20
    }),
    description: attr(),
    primaryMetric: attr(),
    pairedMetrics: Ember.computed('metrics.@each.paired', function () {
      return Ember.get(this, 'metrics').filterBy('paired');
    }),
    moduleProperties: attr({
      defaultValue: function defaultValue() {
        return {};
      }
    }),
    recordsKey: attr(),
    recordsInstanceKey: attr({
      defaultValue: 'label'
    }),
    metricDefinition: Ember.computed('metrics.[]', function () {
      if (Ember.get(this, 'metrics') && Ember.get(this, 'metrics.length') && Ember.get(this, 'metrics').rejectBy('paired').length) {
        return Ember.get(this, 'subcategory.category.taxonomy.metricDefinitions').findBy('modelAlias', Object.values(Ember.get(this, 'metrics').rejectBy('paired').objectAt(0)).objectAt(0));
      }
    })
  });
});