define("affinio/templates/components/modals/modal-keynav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "lE4w7zcw",
    "block": "{\"symbols\":[\"view\"],\"statements\":[[7,\"form\"],[11,\"class\",\"modal modal-keynav\"],[9],[0,\"\\n  \"],[7,\"a\"],[11,\"class\",\"close-modal\"],[11,\"href\",\"#\"],[9],[0,\"×\"],[3,\"action\",[[22,0,[]],\"close\"]],[10],[0,\"\\n  \"],[7,\"h3\"],[11,\"class\",\"modal-title\"],[9],[1,[21,\"title\"],false],[10],[0,\"\\n  \"],[7,\"ul\"],[9],[0,\"\\n    \"],[7,\"li\"],[9],[7,\"strong\"],[9],[0,\"Shift + Left\"],[10],[0,\" Previous tab\"],[10],[0,\"\\n    \"],[7,\"li\"],[9],[7,\"strong\"],[9],[0,\"Shift + Right\"],[10],[0,\" Next tab\"],[10],[0,\"\\n    \"],[7,\"li\"],[9],[7,\"strong\"],[9],[0,\"Shift + Up\"],[10],[0,\" Previous cluster\"],[10],[0,\"\\n    \"],[7,\"li\"],[9],[7,\"strong\"],[9],[0,\"Shift + Down\"],[10],[0,\" Next cluster\"],[10],[0,\"\\n    \"],[7,\"hr\"],[9],[10],[0,\"\\n\"],[4,\"each\",[[23,[\"viewOptions\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\"],[12,\"class\",[27,\"if\",[[22,1,[\"inactive\"]],\"inactive\"],null]],[9],[0,\"\\n        \"],[7,\"strong\"],[9],[1,[22,1,[\"keyNav\"]],false],[10],[0,\"\\n        Change bar charts to \"],[1,[22,1,[\"label\"]],false],[0,\"\\n\"],[4,\"if\",[[22,1,[\"caveat\"]]],null,{\"statements\":[[0,\"          (\"],[1,[22,1,[\"caveat\"]],false],[0,\")\\n\"]],\"parameters\":[]},null],[0,\"      \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"okAction\"]]],null,{\"statements\":[[0,\"    \"],[7,\"footer\"],[9],[0,\"\\n      \"],[7,\"a\"],[11,\"class\",\"button primary\"],[11,\"role\",\"button\"],[9],[0,\"\\n        \"],[1,[27,\"if\",[[23,[\"okText\"]],[23,[\"okText\"]],\"Ok\"],null],false],[0,\"\\n      \"],[3,\"action\",[[22,0,[]],\"close\"]],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"backdrop\"],[11,\"role\",\"button\"],[9],[3,\"action\",[[22,0,[]],\"close\"]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/modals/modal-keynav.hbs"
    }
  });
});