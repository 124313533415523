define("affinio/templates/components/modules/audience-growth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "YCToLFx6",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[22,0,[]]]],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"model\",\"report\",\"gains\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"time-series\",null,[[\"data\",\"idNameHash\",\"highlightedId\",\"height\"],[[23,[\"model\",\"report\",\"gains\"]],[23,[\"model\",\"report\",\"clusterIdNameHash\"]],[23,[\"path\",\"currentClusterId\"]],500]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/modules/audience-growth.hbs"
    }
  });
});