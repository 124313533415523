define("affinio/components/modules/engagement-card", ["exports", "affinio/mixins/affinio-module"], function (_exports, _affinioModule) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_affinioModule.default, {
    // Element
    classNames: ['engagement-card', 'module', 'card'],
    classNameBindings: ['isActive:active', 'isMax:max:min'],
    tagName: 'div',
    // Services
    preferences: Ember.inject.service(),
    tracker: Ember.inject.service(),
    isMax: Ember.computed('type', function () {
      return Ember.get(this, 'type') === 'max';
    }),
    isActive: Ember.computed('type', 'preferences.activeEngagement', function () {
      return Ember.get(this, 'type') === Ember.get(this, 'preferences.activeEngagement');
    }),
    actions: {
      setActiveEngagementRange: function setActiveEngagementRange(day, type) {
        if (!Ember.get(this, 'preferences.activeEngagementStickied')) {
          if (type) {
            Ember.set(day, 'active', true);
            Ember.set(this, 'preferences.activeEngagement', type);
          } else if (!type) {
            Ember.set(day, 'active', false);
            Ember.set(this, 'preferences.activeEngagement', null);
          }
        }
      },
      stickyActiveEngagementRange: function stickyActiveEngagementRange(day, type) {
        // console.log('stickyActiveEngagementRange', day, type);
        if (!Ember.get(this, 'preferences.activeEngagementStickied')) {
          if (type === 'min') {
            Ember.set(Ember.get(this, 'postsTimeSeries.hours.max'), 'active', false);
            Ember.set(Ember.get(this, 'postsTimeSeries.hours.min'), 'active', true);
            Ember.set(this, 'preferences.activeEngagement', type);
            Ember.set(this, 'preferences.activeEngagementStickied', true);
          } else if (type === 'max') {
            Ember.set(Ember.get(this, 'postsTimeSeries.hours.max'), 'active', true);
            Ember.set(Ember.get(this, 'postsTimeSeries.hours.min'), 'active', false);
            Ember.set(this, 'preferences.activeEngagement', type);
            Ember.set(this, 'preferences.activeEngagementStickied', true);
          }
        } else {
          if (Ember.get(this, 'preferences.activeEngagement') === 'min' && type === 'max') {
            Ember.set(Ember.get(this, 'postsTimeSeries.hours.max'), 'active', true);
            Ember.set(Ember.get(this, 'postsTimeSeries.hours.min'), 'active', false);
            Ember.set(this, 'preferences.activeEngagement', type);
            Ember.set(this, 'preferences.activeEngagementStickied', true);
          } else if (Ember.get(this, 'preferences.activeEngagement') === 'max' && type === 'min') {
            Ember.set(Ember.get(this, 'postsTimeSeries.hours.max'), 'active', false);
            Ember.set(Ember.get(this, 'postsTimeSeries.hours.min'), 'active', true);
            Ember.set(this, 'preferences.activeEngagement', type);
            Ember.set(this, 'preferences.activeEngagementStickied', true);
          } else {
            Ember.set(Ember.get(this, 'postsTimeSeries.hours.max'), 'active', false);
            Ember.set(Ember.get(this, 'postsTimeSeries.hours.min'), 'active', false);
            Ember.set(this, 'preferences.activeEngagementStickied', false);
            Ember.set(this, 'preferences.activeEngagement', null);
          }
        }
      }
    }
  });
});