define("affinio/services/modal-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    modal: null,
    modalProperties: null,
    tracker: Ember.inject.service(),
    openModal: function openModal(modal, modalProperties) {
      Ember.get(this, 'tracker').track('Modal Open', {
        modal: modal
      });
      try {
        Ember.setProperties(this, {
          modal: modal,
          modalProperties: modalProperties
        });
      } catch (e) {
        Ember.get(this, 'tracker').track('Opening Modal Failed');
        Ember.setProperties(this, {
          'modal': null,
          'modalProperties': null
        });
      }
    },
    closeModal: function closeModal() {
      Ember.setProperties(this, {
        'modal': null,
        'modalProperties': null
      });
    }
  });
});