define("affinio/models/outage-info", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var Model = _emberData.default.Model;
  var _default = _exports.default = Model.extend({
    message: attr('string'),
    platformService: attr('string'),
    dateCreated: attr('date'),
    dateCompleted: attr('date'),
    isAll: Ember.computed.equal('platformService', 'ALL'),
    isBuilder: Ember.computed.equal('platformService', 'REPORT_BUILDER'),
    isList: Ember.computed.equal('platformService', 'REPORT_LIST'),
    onReportBuilder: Ember.computed.or('isAll', 'isBuilder'),
    onReportList: Ember.computed.or('isAll', 'isList')
  });
});