define("affinio/components/preferences/select-checkbox", ["exports", "affinio/mixins/module-preference"], function (_exports, _modulePreference) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_modulePreference.default, {
    // Element
    tagName: 'label'
  });
});