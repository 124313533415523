define("affinio/templates/reset-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "XDu90Fcu",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\"],[11,\"href\",\"http://affinio.com\"],[11,\"class\",\"back-to-home\"],[9],[0,\"\\n  \"],[7,\"img\"],[11,\"src\",\"/assets/images/affinio.png\"],[11,\"alt\",\"Affinio\"],[11,\"title\",\"Back Home\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"section\"],[11,\"class\",\"reset-password-section\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"wrapper\"],[9],[0,\"\\n    \"],[7,\"header\"],[9],[0,\"\\n      \"],[7,\"h4\"],[9],[1,[27,\"if\",[[23,[\"newAccount\"]],\"Welcome to Affinio!\",\"Password Reset\"],null],false],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[4,\"if\",[[27,\"and\",[[23,[\"validateToken\",\"isIdle\"]],[23,[\"tokenValid\"]]],null]],null,{\"statements\":[[4,\"if\",[[23,[\"newAccount\"]]],null,{\"statements\":[[0,\"        \"],[7,\"p\"],[9],[0,\"Before getting started, we require setting your password\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[1,[27,\"password-manager\",null,[[\"submitAction\",\"showEmail\",\"headingText\"],[[27,\"action\",[[22,0,[]],\"submit\"],null],true,\"\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[21,\"loading-spinner\"],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n  \"],[7,\"footer\"],[9],[0,\"\\n    \"],[7,\"em\"],[9],[4,\"link-to\",[\"sign-in\"],null,{\"statements\":[[0,\"Return to login\"]],\"parameters\":[]},null],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/reset-password.hbs"
    }
  });
});