define("affinio/templates/components/affinio-table-cell/basic-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "l9F9IBJC",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"column\",\"showIcon\"]]],null,{\"statements\":[[4,\"if\",[[23,[\"row\",\"icon\"]]],null,{\"statements\":[[0,\"    \"],[7,\"img\"],[12,\"src\",[23,[\"row\",\"icon\"]]],[11,\"width\",\"20\"],[11,\"height\",\"20\"],[11,\"alt\",\"\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[27,\"network-icon\",[[23,[\"row\",\"id\"]],\"#3F4040\"],null],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[1,[21,\"value\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/affinio-table-cell/basic-cell.hbs"
    }
  });
});