define("affinio/components/modules/engagement-legacy", ["exports", "affinio/mixins/affinio-module"], function (_exports, _affinioModule) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_affinioModule.default, {
    // Element
    classNames: ['engagement-legacy', 'vertical-bar-chart'],
    tagName: 'div',
    // Services
    user: Ember.inject.service(),
    tracker: Ember.inject.service(),
    preferences: Ember.inject.service(),
    // Computed Properties
    type: Ember.computed.alias('moduleRequest.type'),
    data: Ember.computed('model.requestsFulfilled', function () {
      if (Ember.get(this, 'model.requestsFulfilled')) {
        var dataKey = "".concat(Ember.get(this, 'moduleRequest.metrics.0.everyCluster'), ".response");
        var allClusters = Ember.get(this, 'model.report.allClusters');
        var firstClusterHistogram = Ember.get(allClusters.objectAt(0), dataKey);
        if (firstClusterHistogram) {
          var columns = [];
          var times = Ember.get(this, 'type') === 'hour' ? firstClusterHistogram.map(function (n) {
            return n.hour < 10 ? "0".concat(n.hour, ":00") : "".concat(n.hour, ":00");
          }) : ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
          times.unshift('x');
          columns.push(times);
          return columns.concat(allClusters.map(function (cluster, i) {
            var theHistogram = Ember.get(cluster, dataKey).mapBy('count');
            if (theHistogram.length) {
              var total = theHistogram.reduce(function (m, n) {
                return m + n;
              });
              theHistogram = theHistogram.map(function (n) {
                return (n / total * 100).toFixed(2);
              });
              theHistogram.unshift(i === 0 ? 'all' : Ember.get(cluster, 'name'));
              return theHistogram;
            }
          })).compact();
        } else {
          return [];
        }
      } else {
        return [];
      }
    }),
    colors: Ember.computed('preferences.categoricalColors', function () {
      var color;
      var prefColor = Ember.get(this, 'preferences.categoricalColors');
      if (prefColor && prefColor !== 'Standard Categorical') {
        color = d3.scale.ordinal().domain(d3.range(20)).range(Ember.get(this, 'preferences.categoricalSets').findBy('name', prefColor).colors).range();
      } else {
        color = d3.scale.category20().domain(d3.range(20)).range();
      }
      color = ['#222222'].concat(color);
      return color;
    }),
    chart: Ember.computed('data', function () {
      var color = Ember.get(this, 'color');
      var chart = c3.generate({
        point: {
          show: false
        },
        size: {
          height: 400
        },
        bindto: this.$('.engagement-legacy-chart')[0],
        data: {
          x: 'x',
          columns: Ember.get(this, 'data'),
          type: 'spline'
        },
        color: {
          pattern: color
        },
        bar: {
          width: {
            ratio: 1
          }
        },
        axis: {
          x: {
            type: 'category',
            label: Ember.get(this, 'type') === 'hour' ? 'Time of Day (UTC)' : 'Day of the Week'
          },
          y: {
            label: '% of Updates'
          }
        }
      });
      return chart;
    }),
    // Lifecycle
    didRender: function didRender() {
      Ember.get(this, 'chart');
    }
  });
});