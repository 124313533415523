define("affinio/components/auth-notify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Element
    tagName: '',
    // Services
    auth: Ember.inject.service(),
    localStorage: Ember.inject.service(),
    _extending: false,
    extending: Ember.computed.or('_extending', 'localStorage.refreshLock'),
    visible: Ember.computed.alias('auth.notificaitonVisible'),
    actions: {
      onClick: function onClick() {
        var _this = this;
        if (!Ember.get(this, 'extending') && Ember.get(this, 'visible')) {
          Ember.set(this, '_extending', true);
          Ember.get(this, 'auth').extendAuth().then(function () {
            return Ember.set(_this, '_extending', false);
          }).catch(function () {
            return Ember.set(_this, '_extending', false);
          });
        }
      }
    }
  });
});