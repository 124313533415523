define("affinio/components/image-frame", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    modalManager: Ember.inject.service(),
    preferences: Ember.inject.service(),
    user: Ember.inject.service(),
    tracker: Ember.inject.service(),
    // tagName: ['div'],
    classNames: ['image-frame'],
    attributeBindings: ['style:style'],
    classNameBindings: ['immune', 'tweetClass', 'immune:hovery'],
    isAll: Ember.computed('category', 'image', function () {
      return Ember.get(this, 'category') === 'all' ? true : false;
    }),
    tweetClass: Ember.computed('image', function () {
      return Ember.String.htmlSafe(Ember.get(this, 'image.tweet'));
    }),
    clusterName: Ember.computed('source', function () {
      return Ember.get(Ember.get(this, 'source').findBy('id', "".concat(Ember.get(this, 'report'), "_").concat(Ember.get(this, 'image.sourceCluster'))), 'name');
    }),
    style: Ember.computed('width', 'isAll', function () {
      var widthStyle = "width:".concat(Ember.get(this, 'isAll') ? "".concat(Ember.get(this, 'width'), "%") : '22.5%', ";");
      return Ember.String.htmlSafe(widthStyle);
    }),
    // style

    width: Ember.computed(function () {
      return Ember.get(this, 'image.relativeSize') * 50;
    }),
    // width
    mouseEnter: function mouseEnter() {
      Ember.$('.image-frame').addClass('hovery');
      this.$().addClass('hovery');
      Ember.set(this, 'immune', true);
    },
    mouseLeave: function mouseLeave() {
      Ember.$('.image-frame').removeClass('hovery');
      this.$().removeClass('hovery');
      Ember.set(this, 'immune', false);
    },
    click: function click() {
      Ember.get(this, 'expandClosure')(Ember.get(this, 'image.imageAnalysisCluster').toString(), Ember.get(this, 'image'));
    },
    actions: {
      viewSource: function viewSource(tweet) {
        // console.log('view source', tweet);
        Ember.get(this, 'modalManager').openModal('modals/modal-stream', {
          examples: [tweet],
          term: 'Source Tweet'
        }); // openModal
        // TODO track
        // get(this, 'tracker').track('View Image Analysis Source', {
        //   id_of_campaign: get(this, 'report'),
        //   user: get(this, 'user.email'),
        //   tweet: get(this, 'image.tweet'),
        //   src_cluster: get(this, 'image.src_cluster'),
        //   cluster_id: get(this, 'image.cluster_id'),
        //   labels: get(this, 'image.labels.length') > 0 ? get(this, 'image.labels').join('$$$') : '',
        //   hashtags: get(this, 'image.hashtags.length') > 0 ? get(this, 'image.hashtags').join('$$$') : '',
        //   is_general: get(this, 'general')
        // });
      },
      // viewSource
      expandAction: function expandAction(category) {
        // console.log('expand action', category);
        Ember.get(this, 'expandClosure')(category.toString(), Ember.get(this, 'image'));
      }
    }
  });
});