define("affinio/components/report-nav-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['report-nav-editor'],
    tagName: 'div',
    schemaEditor: Ember.inject.service(),
    notify: Ember.inject.service(),
    actions: {
      moveSection: function moveSection(section, parentArray, newParentArray, index, acceptedModel) {
        var push = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : false;
        Ember.get(this, 'schemaEditor').moveSection(section, parentArray, newParentArray, index, acceptedModel, push);
      },
      deleteSection: function deleteSection(section, parentArray) {
        Ember.get(this, 'schemaEditor').deleteSection(section, parentArray);
        Ember.get(this, 'model.report').recalculateTraits();
      },
      addSection: function addSection(name, sectionType, parentArray, taxoID, categoryLabel) {
        if (name) {
          var worked = Ember.get(this, 'schemaEditor').addSection(name, sectionType, parentArray, taxoID, categoryLabel);
          if (!worked) {
            Ember.get(this, 'notify').error("Unable to create ".concat(sectionType.capitalize(), ", there is already one with this name"));
          }
        }
      },
      resetSchema: function resetSchema() {
        var _this = this;
        Ember.get(this, 'schemaEditor').resetSchema().then(function () {
          return Ember.get(_this, 'model.report').recalculateTraits();
        });
      }
    }
  });
});