define("affinio/components/modules/audience-source", ["exports", "affinio/utils/constants/date-formats", "affinio/utils/constants/countries", "affinio/utils/is-URL", "moment", "affinio/utils/affinio", "affinio/utils/queries"], function (_exports, _dateFormats, _countries, _isURL, _moment, _affinio, _queries) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  var notAllowed = ['ownedBenchmarks', 'competitiveBenchmarks', 'contentFrom', 'contentTo', 'explicitWords', 'followersIntersection', 'union', 'segmentedReportSource', 'isHandles', 'followersStartDate', 'followersEndDate', 'followersStartWeekYear', 'followersEndWeekYear'];
  var _default = _exports.default = Ember.Component.extend({
    // Element
    classNames: ['audience-source'],
    tagName: 'div',
    store: Ember.inject.service(),
    notify: Ember.inject.service(),
    graph: Ember.inject.service(),
    tracker: Ember.inject.service(),
    modalManager: Ember.inject.service(),
    expandedLanguages: _countries.expandedLanguages,
    // imported from utils/constants/countries

    filteredFilters: Ember.computed('model.report.{filters,filters.translationCode}', function () {
      if (Ember.get(this, 'model.report.filters')) {
        // converting to POJO so there is no changes to report fitlers
        var originalFilters = Object.assign({}, JSON.parse(JSON.stringify(Ember.get(this, 'model.report.filters'))));
        var hasSegmentedReportSource = !!originalFilters.segmentedReportSource;
        if (Ember.get(this, 'model.report.sharedURLs')) {
          notAllowed.push('contentQuery');
          Ember.set(originalFilters, 'sharedURLs', Ember.get(this, 'model.report.sharedURLs')); // eslint-disable-line ember/no-side-effects
        }
        var dataSource = [];
        var currentDataSource = [];
        var isCustomBoolean = false;
        if (originalFilters.data_sources && originalFilters.data_sources.length) {
          var isNewFormat = (0, _affinio.isNewFormatCustomFilters)(originalFilters);
          if (isNewFormat) {
            var isSql = (0, _affinio.isCustomSQLFilters)(originalFilters);
            if (isSql) {
              originalFilters.data_sources.forEach(function (ds) {
                var existing = dataSource.findBy('key', 'SQL');
                if (existing) {
                  existing.value.pushObjects(ds.filters.leaves.mapBy('value'));
                } else {
                  dataSource.pushObject({
                    key: 'SQL',
                    value: ds.filters.leaves.mapBy('value')
                  });
                }
              });
            } else {
              originalFilters.data_sources.forEach(function (ds) {
                if (ds.filters.op === 'AND') {
                  isCustomBoolean = true;
                }
                ds.filters.branches.forEach(function (branch) {
                  if (branch.op === 'AND') {
                    isCustomBoolean = true;
                  }
                  branch.leaves.forEach(function (leaf) {
                    var key = "".concat(leaf.source, " (").concat(leaf.condition, ")");
                    var existing = dataSource.findBy('key', key);
                    var cleanedFilter = {
                      key: key,
                      value: [leaf.value.replace("\\'", "'")]
                    };
                    if (existing) {
                      existing.value.pushObject(leaf.value);
                    } else {
                      dataSource.pushObject(cleanedFilter);
                    }
                  });
                });
              });
            }
          } else {
            // if we have data sources with filters
            if (originalFilters.data_sources[0].filters) {
              originalFilters.data_sources.forEach(function (currentSource) {
                // if the current datasource has filters
                if (currentSource.filters && Ember.typeOf(currentSource.filters) === 'array') {
                  currentDataSource = currentSource.filters.reduce(function (a, n) {
                    var uniqueFields = {};
                    uniqueFields = a.find(function (f) {
                      return f.field === n.field && f.condition === n.condition;
                    });
                    if (uniqueFields) {
                      uniqueFields.key ? uniqueFields.key : uniqueFields.key = "".concat(uniqueFields.field, " (").concat(uniqueFields.condition, ")");
                      var multi = [];
                      multi = Ember.typeOf(uniqueFields.value) === 'array' ? uniqueFields.value.includes(n.value) ? uniqueFields.value : [].concat(_toConsumableArray(uniqueFields.value), [n.value]) : [uniqueFields.value, n.value];
                      uniqueFields.value = multi;
                      return _toConsumableArray(a);
                    } else {
                      n.key = "".concat(n.field, " (").concat(n.condition, ")");
                      n.value = n.value.replace("\\'", "'");
                      return [].concat(_toConsumableArray(a), [n]);
                    }
                  }, []);
                  dataSource = dataSource.concat(currentDataSource);
                }
              });
            }
          }
        }

        // Get data source names
        if (dataSource.length && isCustomBoolean) {
          dataSource.unshift({
            key: 'Advanced Boolean',
            value: 'True'
          });
        }
        if (Ember.get(this, 'model.report._taxonomy.dataSources')) {
          Ember.get(this, 'model.report._taxonomy.dataSources').forEach(function (ds) {
            dataSource.unshift({
              key: 'Data Source',
              value: ds.label
            });
          });
        }

        // Get data source drivers
        if (Ember.get(this, 'model.report.taxonomy.dataSources')) {
          Ember.get(this, 'model.report.taxonomy.dataSources').forEach(function (ds) {
            dataSource.push({
              key: 'Cluster Drivers',
              value: ds.clusterable
            });
          });
        }

        // Hack in new followers dates to single property
        if (originalFilters.followersStartDate && originalFilters.followersEndDate) {
          originalFilters.newFollowersOnly = "".concat((0, _moment.default)(originalFilters.followersStartDate).format(_dateFormats.fullMonth), " - ").concat((0, _moment.default)(originalFilters.followersEndDate).format(_dateFormats.fullMonth));
        }

        // realtime
        if (originalFilters.queryID) {
          delete originalFilters.queryID;
          // TODO in the future we could setup a second one for the url, thats in flux so later
        }
        return dataSource.length ? dataSource : Object.keys(originalFilters).map(function (k) {
          if (originalFilters[k]) {
            return {
              key: k,
              value: originalFilters[k]
            };
          }
        }).filter(function (n) {
          return n !== undefined;
        }).filter(function (n) {
          return !notAllowed.includes(n.key);
        }).filter(function (n) {
          return hasSegmentedReportSource ? n.key !== 'fileURL' : true;
        });
      }
    }),
    hasSocialLink: Ember.computed(function () {
      return ['followersOf', 'excludedFollowersOf'];
    }),
    filteredFiltersLength: Ember.computed('filteredFilters', function () {
      return Object.keys(Ember.get(this, 'filteredFilters')).length;
    }),
    dateStart: Ember.computed('model.report.{dateCreated,filters.contentFrom}', function () {
      var start = Ember.get(this, 'model.report.filters.contentFrom') ? Ember.get(this, 'model.report.filters.contentFrom').slice(0, Ember.get(this, 'model.report.filters.contentFrom.length') - 4) : Ember.get(this, 'model.report.dateCreated');
      return (0, _dateFormats.fullShortMonth)(start);
    }),
    // dateStart

    dateEnd: Ember.computed('model.report.{dateFinished,filters.contentTo}', function () {
      var end = Ember.get(this, 'model.report.filters.contentTo') ? Ember.get(this, 'model.report.filters.contentTo').slice(0, Ember.get(this, 'model.report.filters.contentTo.length') - 4) : Ember.get(this, 'model.report.dateFinished');
      return (0, _dateFormats.fullShortMonth)(end);
    }),
    // dateEnd
    actions: {
      isURL: function isURL(val) {
        // val contains a link
        return (0, _isURL.isUrl)(val);
      },
      downloadFileId: function downloadFileId(id) {
        var _this = this;
        Ember.get(this, 'graph').query(_queries.default.getFileDownloadSignature(id), true).then(function (response) {
          (0, _affinio.downloadURL)(response.data.files[0].signature.signature, response.data.files[0].signature.signature, "".concat(id, ".csv"));
          Ember.get(_this, 'tracker').track('downloaded csv file for report');
        }).catch(function () {
          Ember.get(_this, 'tracker').track('failed to download csv file for report');
          Ember.get(_this, 'notify').error('You don\'t have permission to download this file');
        });
      },
      openDriverModal: function openDriverModal(drivers) {
        Ember.get(this, 'modalManager').openModal('modals/modal-text', {
          title: 'Cluster Drivers',
          message: "<ul>".concat(drivers.map(function (n) {
            return "<li>".concat(n, "</li>");
          }).join(''), "</ul>"),
          okAction: true
        });
      }
    }
  });
});