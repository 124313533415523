define("affinio/components/interests-container", ["exports", "affinio/utils/get-with-default"], function (_exports, _getWithDefault) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  var _default = _exports.default = Ember.Component.extend({
    // Element
    tagName: '',
    view: 'grid',
    // TODO should this live here or interests-vis
    activeInterest: null,
    clusterAsset: Ember.computed('interests', function () {
      var keys = ['locationScoresMap', 'locationTimesMoreLikelyMap', 'scoresMap', 'timesMoreLikelyMap'];
      return this.byColumnToRow(Ember.get(this, 'interests'), keys);
    }),
    valueNomanclature: Ember.computed('visualizationProperties', function () {
      if (!Ember.get(this, 'visualizationProperties')) {
        return {
          value: 'Value'
        };
      }
      return (0, _getWithDefault.default)(this, 'visualizationProperties', []).findBy('label', 'valueNomenclature');
    }),
    /**
     * this will take the exisiting assets array and remaps a new array
     * based on the rows / clusters
     * @param {Array} assets
     * @param {Array} keys
     */
    byColumnToRow: function byColumnToRow(assets, keys) {
      var maps = [];
      keys.forEach(function (key) {
        var _ref;
        var clusterItems = [];
        assets.map(function (asset) {
          return clusterItems.push(asset[key]);
        });
        var byCluster = (_ref = _).zip.apply(_ref, clusterItems);
        byCluster.forEach(function (m, i) {
          return _typeof(maps[i]) === 'object' ? maps[i][key] = m : maps[i] = _defineProperty({}, key, m);
        });
      });
      return maps;
    }
  });
});