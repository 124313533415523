define("affinio/models/folder", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var Model = _emberData.default.Model;
  var _default = _exports.default = Model.extend({
    user: Ember.inject.service(),
    name: attr('string'),
    reports: attr(),
    // reports must be an attr here, or we will lose shareinfo when it overwrites the report
    accounts: attr(),
    // Accounts must be an attr here, or we will lose shareinfo when it overwrites the account
    groups: attr(),
    companies: attr(),
    owner: attr(),
    iOwn: Ember.computed('user.account.id', 'owner.id', function () {
      return Ember.get(this, 'user.account.id') === Ember.get(this, 'owner.id');
    }),
    notViewerSeat: Ember.computed.not('user.account.isViewerSeat'),
    folderEditPermissions: Ember.computed.or('accountEdit', 'companyEdit', 'groupEdit'),
    canEdit: Ember.computed.and('notViewerSeat', 'folderEditPermissions')
  });
});