define("affinio/templates/components/modules/traits-gallery", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "m+Yuleno",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"trait-chart\",null,[[\"_instances\",\"cluster\",\"recordsMap\",\"title\",\"loading\",\"selectedSummaryView\",\"summaryViews\",\"selectedTraitType\",\"traitTypes\",\"sliceSize\",\"staticSummaryView\",\"hideVersus\"],[[23,[\"traits\"]],[23,[\"cluster\"]],[23,[\"report\",\"recordsMap\"]],[23,[\"selectedSummaryView\"]],[27,\"and\",[[23,[\"report\",\"recordsCollection\",\"loading\"]],[27,\"not\",[[23,[\"report\",\"recordsCollection\",\"loaded\"]]],null]],null],\"most\",[23,[\"summaryViews\"]],[23,[\"selectedTraitType\"]],[23,[\"traitTypes\"]],20,true,true]]],false],[0,\"\\n\\n\"],[1,[27,\"trait-chart\",null,[[\"_instances\",\"cluster\",\"recordsMap\",\"title\",\"loading\",\"selectedSummaryView\",\"summaryViews\",\"selectedTraitType\",\"traitTypes\",\"sliceSize\",\"staticSummaryView\",\"hideVersus\"],[[23,[\"unTraits\"]],[23,[\"cluster\"]],[23,[\"report\",\"recordsMap\"]],[23,[\"selectedSummaryView\"]],[27,\"and\",[[23,[\"report\",\"recordsCollection\",\"loading\"]],[27,\"not\",[[23,[\"report\",\"recordsCollection\",\"loaded\"]]],null]],null],\"least\",[23,[\"summaryViews\"]],[23,[\"selectedTraitType\"]],[23,[\"traitTypes\"]],20,true,true]]],false],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/modules/traits-gallery.hbs"
    }
  });
});