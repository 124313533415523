define("affinio/components/api-key-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Element
    classNames: ['api-key-list-item'],
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    keyTypes: [{
      source: 'TalkWalkerAPIToken',
      description: 'Your Talkwalker rep can provide an API key upon request to enable the integration. Simply enter it here. <a href="https://affinio.zendesk.com/hc/en-us/articles/15184693455764" target="_blank" rel="noopener">See more information in the Help Center.</a>'
    }]
  });
});