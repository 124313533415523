define("affinio/utils/mutations/reports", ["exports", "affinio/utils/get-with-default", "affinio/utils/constants/queries"], function (_exports, _getWithDefault, _queries) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.updateTwitterReportBenchmarkGroups = _exports.updateReports = _exports.updateClusters = _exports.requestClusterNameRecommendations = _exports.removeAccountReportsAccess = _exports.launchReport = _exports.deleteReports = _exports.default = _exports.addTwitterMonitoredInformation = void 0;
  var deleteReports = _exports.deleteReports = function deleteReports(ids) {
    var mutations = Ember.A();
    ids.forEach(function (id, i) {
      mutations.pushObject("deleteReport".concat(i, ":deleteReport(id: \"").concat(id, "\")"));
    });
    return {
      networkName: 'deleteReports',
      queryString: "mutation {\n      ".concat(mutations, "\n    }")
    };
  };
  var updateReports = _exports.updateReports = function updateReports(_ref) {
    var reports = _ref.reports,
      editedProperties = _ref.editedProperties,
      sendEmail = _ref.sendEmail,
      notes = _ref.notes;
    var mutations = Ember.A();
    var reportResponseCompanies = "\n    id\n    name\n    socialNetwork\n    companies {\n      id\n      shareInfo {\n        canEdit\n      }\n    }\n  ";
    var reportResponseAccounts = "\n    id\n    name\n    socialNetwork\n    accounts {\n      id\n      email\n      shareInfo {\n        canEdit\n      }\n    }\n  ";
    var reportResponseFolders = "\n    id\n    name\n    socialNetwork\n    folders {\n      id\n      shareInfo {\n        canEdit\n      }\n    }\n  ";
    var reportResponseGroups = "\n    id\n    name\n    socialNetwork\n    groups {\n      id\n      shareInfo {\n        canEdit\n      }\n    }\n  ";
    var modelNameToResponse = function modelNameToResponse(modelName) {
      switch (modelName) {
        case 'account':
          return reportResponseAccounts;
        case 'company':
          return reportResponseCompanies;
        case 'group':
          return reportResponseGroups;
        case 'folder':
          return reportResponseFolders;
      }
    };
    var sharedEntitiesIndex = 0;
    var unsharedEntitiesIndex = 0;
    var name;
    var modelNames = (0, _getWithDefault.default)(editedProperties, 'sharedEntities', []).mapBy('modelName');
    var isEditingName = Ember.get(editedProperties, 'name');
    var isSharing = notes && (modelNames.includes('account') || modelNames.includes('company') || modelNames.includes('group'));
    reports.forEach(function (report) {
      if (Ember.get(editedProperties, 'sharedEntities')) {
        Ember.get(editedProperties, 'sharedEntities').forEach(function (entity) {
          var modelName = Ember.get(entity, 'modelName');
          var email = modelName === 'folder' ? '' : ", shouldSendEmail: ".concat(sendEmail);
          var notesArg = modelName === 'folder' ? '' : notes ? ', notes: $notes' : '';
          mutations.pushObject("sharedEntities".concat(sharedEntitiesIndex, ": shareReportTo").concat(modelName.capitalize(), "(reportID: \"").concat(Ember.get(report, 'id'), "\", ").concat(modelName, "ID: \"").concat(Ember.get(entity, 'id'), "\", canEdit: ").concat(Ember.get(entity, 'canEdit')).concat(email).concat(notesArg, ") {\n            ").concat(modelNameToResponse(modelName), "\n          }"));
          sharedEntitiesIndex++;
        });
      }
      if (Ember.get(editedProperties, 'unsharedEntities')) {
        Ember.get(editedProperties, 'unsharedEntities').forEach(function (entity) {
          var modelName = Ember.get(entity, 'modelName');
          mutations.pushObject("unsharedEntities".concat(unsharedEntitiesIndex, ": unshareReportFrom").concat(modelName.capitalize(), "(reportID: \"").concat(Ember.get(report, 'id'), "\", ").concat(modelName, "ID: \"").concat(Ember.get(entity, 'id'), "\") {\n            ").concat(modelNameToResponse(modelName), "\n          }"));
          unsharedEntitiesIndex++;
        });
      }
      if (isEditingName) {
        name = Ember.get(report, 'name');
        mutations.pushObject("updateReport(id: \"".concat(Ember.get(report, 'id'), "\", name: $name) {\n            id\n            name\n            socialNetwork\n        }"));
      }
    }); // reports.forEach

    var variables = isSharing && isEditingName ? {
      notes: notes,
      name: name
    } : isSharing && !isEditingName ? {
      notes: notes
    } : !isSharing && isEditingName ? {
      name: name
    } : null;
    var params = isSharing && isEditingName ? '($notes: String, $name:String!)' : isSharing && !isEditingName ? '($notes: String )' : !isSharing && isEditingName ? '($name: String!)' : '';
    return {
      networkName: 'updateReports',
      queryString: "mutation ".concat(params, " {\n      ").concat(mutations, "\n    }"),
      variables: variables,
      update: function update(response, graph) {
        var sharedEntitiesIndex = 0;
        var unsharedEntitiesIndex = 0;
        reports.forEach(function (report) {
          if (Ember.get(editedProperties, 'sharedEntities')) {
            Ember.get(editedProperties, 'sharedEntities').forEach(function () {
              var network = Ember.get(report, 'isCustom') ? 'Custom' : response.data["sharedEntities".concat(sharedEntitiesIndex)].socialNetwork.toLowerCase();
              graph.recordFromGraphData(false, "reports/".concat(network), Ember.get(response, "data.sharedEntities".concat(sharedEntitiesIndex)));
              sharedEntitiesIndex++;
            });
          }
          if (Ember.get(editedProperties, 'unsharedEntities')) {
            Ember.get(editedProperties, 'unsharedEntities').forEach(function () {
              var network = Ember.get(report, 'isCustom') ? 'Custom' : response.data["unsharedEntities".concat(unsharedEntitiesIndex)].socialNetwork.toLowerCase();
              graph.recordFromGraphData(false, "reports/".concat(network), Ember.get(response, "data.unsharedEntities".concat(unsharedEntitiesIndex)));
              unsharedEntitiesIndex++;
            });
          }
        });
        if (isEditingName) {
          var network = Ember.get(reports.objectAt(0), 'isCustom') ? 'Custom' : response.data.updateReport.socialNetwork.toLowerCase();
          graph.recordFromGraphData(false, "reports/".concat(network), response.data.updateReport);
        }
      }
    };
  };
  var removeAccountReportsAccess = _exports.removeAccountReportsAccess = function removeAccountReportsAccess(reportIds, accountId) {
    var mutations = Ember.A();
    reportIds.forEach(function (id, i) {
      mutations.pushObject("unshareReportFromAccount".concat(i, ":unshareReportFromAccount(reportID: \"").concat(id, "\", accountID: \"").concat(accountId, "\"){\n        id\n      }"));
      // TODO ^^^ may want some more info from accounts obj in return
    });
    return {
      networkName: 'removeAccountReportsAccess',
      queryString: "mutation {\n      ".concat(mutations, "\n    }")
    };
  };
  var launchReport = _exports.launchReport = function launchReport(report) {
    var network = report.isCustomReport ? 'Custom' : report.socialNetwork.capitalize();
    var filters = report.filters,
      taxoId = report.taxoId;
    var name = report.name;
    var numberOfClusters = report.numberOfClusters;
    var queryString = '';
    var response = '';
    switch (network) {
      case 'Twitter':
        response = (0, _queries.twitterReportResponseDefault)(true);
        break;
      case 'Pinterest':
        response = (0, _queries.pinterestReportResponseDefault)(true);
        break;
      case 'Instagram':
        response = (0, _queries.instagramReportResponseDefault)(true);
        break;
      case 'Custom':
        response = (0, _queries.customReportResponseDefault)(true);
        break;
      default:
        response = (0, _queries.twitterReportResponseDefault)(true);
        break;
    }
    if (network === 'Custom') {
      var taxonomy;
      if (taxoId) {
        taxonomy = ", taxonomyID: \"".concat(taxoId, "\"");
      }
      queryString = "mutation ($filters: String!, $name: String!) {\n      launch".concat(network, "Report(name: $name, numberOfClusters: ").concat(numberOfClusters, ", filters: $filters, socialNetwork: \"").concat(report.socialNetwork, "\"").concat(taxonomy, "){\n        ").concat(response, "\n      }\n    }");
    } else {
      queryString = "mutation ($filters: ".concat(network, "ReportFiltersArg!, $name: String!) {\n        launch").concat(network, "Report(name: $name, numberOfClusters: ").concat(numberOfClusters, ", filters: $filters){\n          ").concat(response, "\n        }\n      }");
    }
    return {
      networkName: 'launchReport',
      queryString: queryString,
      variables: {
        filters: filters,
        name: name
      },
      update: function update(response, graph) {
        graph.transformDates(response.data["launch".concat(network, "Report")]);
        var nestedMetrics = [];
        if (response.data["launch".concat(network, "Report")].storedBenchmarkGroups) {
          nestedMetrics.push({
            key: 'storedBenchmarkGroups',
            modelName: 'metric'
          });
        }
        return graph.recordFromGraphData(false, "reports/".concat(network.toLowerCase()), response.data["launch".concat(network, "Report")], nestedMetrics);
      }
    };
  };
  var requestClusterNameRecommendations = _exports.requestClusterNameRecommendations = function requestClusterNameRecommendations(report) {
    var notify = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
    return {
      networkName: 'requestClusterNameRecommendations',
      queryString: "mutation {\n      requestClusterNameRecommendations(reportID: \"".concat(Ember.get(report, 'id'), "\", shouldCreateNotification: ").concat(notify, "){\n        id\n      }\n    }")
    };
  };
  var addTwitterMonitoredInformation = _exports.addTwitterMonitoredInformation = function addTwitterMonitoredInformation(report, frequency, isActive, isImmediate) {
    return {
      networkName: 'addTwitterMonitoredInformation',
      queryString: "mutation {\n      addTwitterMonitoredInformation(reportID: \"".concat(Ember.get(report, 'id'), "\", frequency: \"").concat(frequency, "\", isActive: ").concat(isActive, ", isImmediate: ").concat(isImmediate, "){\n        id\n        monitoredInformation {\n          frequency\n          lastUpdate\n          nextUpdate\n          isActive\n        }\n      }\n    }"),
      update: function update(response, graph) {
        graph.recordFromGraphData(false, 'reports/twitter', Ember.get(response, 'data.addTwitterMonitoredInformation'));
      }
    };
  };
  var updateTwitterReportBenchmarkGroups = _exports.updateTwitterReportBenchmarkGroups = function updateTwitterReportBenchmarkGroups(report, groups) {
    return {
      networkName: 'updateTwitterReportBenchmarkGroups',
      queryString: "mutation($groups:[benchmarkGroupRequestInputType!]) {\n      updateTwitterReportBenchmarkGroups(reportID: \"".concat(Ember.get(report, 'id'), "\", benchmarkGroups: $groups){\n        id\n        storedBenchmarkGroups {\n          name\n          members\n        }\n        filters {\n          followersOf\n          bioKeywords\n          bioLocations\n          contentQuery\n          ownedBenchmarks\n          competitiveBenchmarks\n          fileURL\n          followersOfCSV\n          isHandles\n          minInfluencerFollowers\n          maxInfluencerFollowers\n          followersIntersection\n          contentFrom\n          contentTo\n          union\n          allowSmall\n        }\n      }\n    }"),
      variables: {
        groups: groups
      },
      update: function update(response, graph) {
        graph.recordFromGraphData(false, 'reports/twitter', Ember.get(response, 'data.updateTwitterReportBenchmarkGroups'), [{
          key: 'storedBenchmarkGroups',
          modelName: 'metric'
        }]);
      }
    };
  };

  /* clusters
  // Merge
  [
    { id: "123_3", mergedInto: "123_2" }
  ]
  // Remove
  [
    { id: "123_3", isRemoved: true }
  ]
  // Clear
  [
    { id: "123_3", isRemoved: false, mergedInto: null }
  ]
  */
  var updateClusters = _exports.updateClusters = function updateClusters(reportID, clusters) {
    return {
      networkName: 'updateClusters',
      queryString: "mutation($clusters:[ClusterInput!]!) {\n      updateClusters(\n        reportID: \"".concat(reportID, "\"\n        clusters: $clusters\n      ) {\n        id\n      }\n    }"),
      variables: {
        clusters: clusters
      }
      // update(response, graph) {
      //   return graph.recordFromGraphData(true, 'clusters/twitter', response.data.updateClusters);
      // }
    };
  };
  var _default = _exports.default = {
    deleteReports: deleteReports,
    updateReports: updateReports,
    removeAccountReportsAccess: removeAccountReportsAccess,
    launchReport: launchReport,
    requestClusterNameRecommendations: requestClusterNameRecommendations,
    addTwitterMonitoredInformation: addTwitterMonitoredInformation,
    updateTwitterReportBenchmarkGroups: updateTwitterReportBenchmarkGroups,
    updateClusters: updateClusters
  };
});