define("affinio/components/auto-complete", ["exports", "affinio/utils/affinio"], function (_exports, _affinio) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['auto-complete'],
    user: Ember.inject.service(),
    tracker: Ember.inject.service(),
    placeholder: '',
    textInput: '',
    primaryDisplayKey: null,
    secondaryDisplayKey: null,
    highlightedHandleIndex: 0,
    suggestionsOpen: false,
    sliceNum: 4,
    defaultType: null,
    searchFilters: false,
    init: function init() {
      this._super.apply(this, arguments);
      Ember.setProperties(this, {
        data: [],
        searchKeys: [],
        selectedSuggestion: {},
        suggestions: []
      });
    },
    didInsertElement: function didInsertElement() {
      var _this = this;
      // TODO: do these with ember actions instead
      this.$().focusout(function (e) {
        if (!Ember.$.contains(_this.$()[0], e.relatedTarget)) {
          (0, _affinio.maybeRunLater)(_this, function () {
            Ember.set(_this, 'suggestionsOpen', false);
          }, 500);
        }
      });
      this.$('.suggestionInput').focus(function () {
        Ember.set(_this, 'suggestionsOpen', true);
        if (Ember.get(_this, 'onFocusAction')) {
          _this.onFocusAction();
        }
      });
      this.$('.suggestionInput').blur(function () {
        if (Ember.get(_this, 'onBlurAction')) {
          _this.onBlurAction();
        }
      });
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this.setQuery();
    },
    autoCompleteWatch: Ember.observer('textInput', function () {
      Ember.run.throttle(this, this.setQuery, 200, false);
    }),
    setQuery: function setQuery() {
      Ember.set(this, 'query', Ember.get(this, 'textInput'));
      this.sendExternalAction();
    },
    sendExternalAction: function sendExternalAction() {
      var query = Ember.get(this, 'query');
      var externalFilterAction = Ember.get(this, 'externalFilterAction');
      if (typeof externalFilterAction === 'function') {
        externalFilterAction(Ember.get(this, 'searchComputer.suggestions'), query);
      }
    },
    setManualInput: Ember.observer('manualInput', function () {
      Ember.set(this, 'textInput', Ember.get(this, 'manualInput'));
    }),
    searchComputer: Ember.computed('searchKeys.[]', function () {
      var _Ember;
      var tracker = Ember.get(this, 'tracker');
      var userEmail = Ember.get(this, 'user.email');
      return Ember.Object.extend({
        suggestions: (_Ember = Ember).computed.apply(_Ember, ['model.query'].concat(_toConsumableArray(Ember.get(this, 'searchKeys').map(function (key) {
          return "model.data.@each.".concat(key);
        })), [function () {
          var model = Ember.get(this, 'model');
          // let rawTextInput = get(model, 'textInput');
          var textInput = Ember.get(model, 'textInput').toLowerCase();
          var sliceNum = Ember.get(model, 'sliceNum');
          var searchFilters = Ember.get(model, 'searchFilters');
          var filteredData = [];
          if (textInput.length) {
            if (searchFilters && textInput.indexOf(':') > -1) {
              var _textInput$split$map = textInput.split(':').map(function (obj) {
                  return obj.trim();
                }),
                _textInput$split$map2 = _slicedToArray(_textInput$split$map, 2),
                label = _textInput$split$map2[0],
                searched = _textInput$split$map2[1];
              // let text = searchFilters.findBy('label', filteredArray[0]) ? searchFilters.findBy('label', filteredArray[0]).get('value') : ''; //map user input to possibleKeys value
              var foundKey = searchFilters.findBy('label', label) ? searchFilters.findBy('label', label) : null; // map user input to possibleKeys value
              if (foundKey) {
                filteredData = Ember.get(model, 'data').filter(function (item) {
                  var matched = false;
                  Ember.get(foundKey, 'value').forEach(function (text) {
                    if (Ember.get(item, 'filters') && Ember.get(item, 'filters')[text] && typeof Ember.get(item, 'filters')[text] === 'string' && Ember.get(item, 'filters')[text].toLowerCase().indexOf(searched) > -1) {
                      // the plain ol' string case
                      matched = true;
                    } else if (
                    // the array of strings case
                    Ember.get(item, 'filters') && Ember.get(item, 'filters')[text] && Ember.isArray(Ember.get(item, 'filters')[text]) && typeof Ember.get(item, 'filters')[text][0] === 'string' && Ember.get(item, 'filters')[text].any(function (obj) {
                      return obj.toLowerCase().indexOf(searched) > -1;
                    })) {
                      matched = true;
                    } else if (
                    // the array of objects with "term" properties case
                    Ember.get(item, 'filters') && Ember.get(item, 'filters')[text] && Ember.isArray(Ember.get(item, 'filters')[text]) && Ember.get(item, 'filters')[text][0] && Ember.get(item, 'filters')[text][0].term && Ember.get(item, 'filters')[text].any(function (obj) {
                      return obj.term.toLowerCase().indexOf(searched) > -1;
                    })) {
                      matched = true;
                    }
                  }); // forEach foundKey.value
                  return matched;
                }); // .filter
              } // if foundKey

              tracker.track('user searched on a filter', {
                filter: textInput.split(':').map(function (obj) {
                  return obj.trim();
                })[0],
                query: textInput.split(':').map(function (obj) {
                  return obj.trim();
                })[1],
                user: userEmail,
                results: filteredData.length
              });
            } else {
              filteredData = Ember.get(model, 'data').filter(function (item) {
                return Ember.get(model, 'searchKeys').some(function (key) {
                  var value = Ember.get(item, key);
                  return value && value.toLowerCase().indexOf(textInput) >= 0;
                });
              });
            } // if searchFilters and a colon
          } else {
            filteredData = Ember.get(model, 'data');
          } // if textInput.length

          if (sliceNum) {
            filteredData = filteredData.slice(0, sliceNum);
          }
          return filteredData;
        }]))
      }).create({
        model: this
      });
    }),
    dataObserver: Ember.observer('data.[]', function () {
      Ember.run.throttle(this, this.sendExternalAction, 2000, false);
    }),
    suggestionsLength: Ember.computed.alias('searchComputer.suggestions.length'),
    keyDown: function keyDown(e) {
      var keyCode = e.keyCode;
      if (Ember.get(this, 'suggestionsOpen') && (keyCode === 38 || keyCode === 40)) {
        var currentIndex = Ember.get(this, 'highlightedHandleIndex');
        e.preventDefault();
        if (keyCode === 38 && currentIndex > 0) {
          currentIndex = Ember.set(this, 'highlightedHandleIndex', currentIndex - 1);
        } else if (keyCode === 40 && currentIndex < Ember.get(this, 'suggestionsLength')) {
          currentIndex = Ember.set(this, 'highlightedHandleIndex', currentIndex + 1);
        }
        if (currentIndex > 0) {
          var elements = this.$('.suggestions').children();
          var selectedElement = elements[currentIndex - 1];
          if (selectedElement) {
            selectedElement.focus();
          }
        } else {
          Ember.set(this, 'highlightedHandleIndex', 0);
          this.$('.suggestionInput').focus();
        }
      }
    },
    actions: {
      autoCompleteSelect: function autoCompleteSelect(suggestion) {
        Ember.set(this, 'selectedSuggestion', suggestion);
        // console.log('complete was fired');
        var primaryKey = Ember.get(this, 'primaryDisplayKey');
        var secondaryKey = Ember.get(this, 'secondaryDisplayKey');
        var primaryVal = primaryKey && Ember.get(suggestion, primaryKey);
        var secondaryVal = secondaryKey && Ember.get(suggestion, secondaryKey);
        Ember.set(this, 'textInput', primaryVal && secondaryVal ? "".concat(primaryVal, " - ").concat(secondaryVal) : "".concat(primaryVal || secondaryVal, " "));
        var updateAction = Ember.get(this, 'updateSelectedAction');
        if (typeof updateAction === 'function') {
          updateAction(suggestion);
        }
        Ember.set(this, 'suggestionsOpen', false);
        this.$('.suggestionInput').focus();
      }
    }
  });
});