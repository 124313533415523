define("affinio/utils/queries/superUser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.companySearchByNameAdmin = _exports.accountSearchByNameOrEmailAdmin = void 0;
  var accountSearchByNameOrEmailAdmin = _exports.accountSearchByNameOrEmailAdmin = function accountSearchByNameOrEmailAdmin(name) {
    return {
      networkName: "accountSearchByNameOrEmailAdmin-".concat(name),
      queryString: "query {\n      searchAccountsByNameOrEmail(nameOrEmail: \"".concat(name, "\"){\n        id\n        firstName\n        lastName\n        email\n        isActive\n        permissions\n        company {\n          id\n          trialInfo {\n            isActive\n            startDate\n            endDate\n          }\n        }\n      }\n    }"),
      queryMap: {
        accounts: {
          array: true,
          modelName: 'account',
          responseKey: 'searchAccountsByNameOrEmail'
        }
      }
    };
  };
  var companySearchByNameAdmin = _exports.companySearchByNameAdmin = function companySearchByNameAdmin(name) {
    return {
      networkName: "companySearchByNameAdmin-".concat(name),
      queryString: "query {\n      searchCompaniesByName(name: \"".concat(name, "\"){\n        id\n        name\n        isActive\n        trialInfo {\n          isActive\n          startDate\n          endDate\n        }\n        seatLimit\n        viewerSeatLimit\n        numberOfAccounts\n      }\n    }"),
      queryMap: {
        companies: {
          array: true,
          modelName: 'company',
          responseKey: 'searchCompaniesByName'
        }
      }
    };
  };
  var _default = _exports.default = {
    accountSearchByNameOrEmailAdmin: accountSearchByNameOrEmailAdmin,
    companySearchByNameAdmin: companySearchByNameAdmin
  };
});