define("affinio/components/report-builder/slider-input", ["exports", "affinio/mixins/report-builder-account-requirements"], function (_exports, _reportBuilderAccountRequirements) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_reportBuilderAccountRequirements.default, {
    classNames: ['report-builder-slider-input']
  });
});