define("affinio/templates/components/skeleton-img", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ylhXGx5r",
    "block": "{\"symbols\":[],\"statements\":[[7,\"img\"],[12,\"class\",[27,\"if\",[[23,[\"errorSrc\"]],\"fallbackSrc\",\"\"],null]],[12,\"src\",[27,\"or\",[[23,[\"errorSrc\"]],[23,[\"src\"]],[23,[\"fallbackSrc\"]]],null]],[12,\"alt\",[21,\"alt\"]],[12,\"onerror\",[27,\"action\",[[22,0,[]],\"onError\"],null]],[9],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/skeleton-img.hbs"
    }
  });
});