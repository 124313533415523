define("affinio/components/modules/cluster-summaries", ["exports", "affinio/mixins/affinio-module"], function (_exports, _affinioModule) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  // import { titleCase } from 'affinio/helpers/title-case';
  var _default = _exports.default = Ember.Component.extend(_affinioModule.default, {
    // Element
    classNames: ['cluster-summaries'],
    tagName: 'div',
    summaryViews: Ember.computed(function () {
      return [{
        label: 'most',
        type: 'traits',
        data: 'processedTraits'
      }, {
        label: 'least',
        type: 'traits',
        data: 'processedUnTraits'
      }];
    }),
    baselineOptions: Ember.computed(function () {
      return [{
        label: 'clusters',
        type: 'baselineViews',
        data: 'clusters'
      }, {
        label: 'baseline',
        type: 'baselineViews',
        data: 'baseline'
      }];
    }),
    traitTypes: Ember.computed('model.report.clusters.@each.traits', function () {
      var _this = this;
      var allClusterTraits = Ember.get(this, 'model.report.clusters').mapBy('traits');
      var traits = ['traits'].concat(_toConsumableArray(allClusterTraits.compact().mapBy('meta').map(function (c) {
        return Ember.get(c, 'data').mapBy('label');
      }).flatten().uniq())).map(function (trait) {
        var label;
        var metricDef = Ember.get(_this, 'model.report.taxonomy.metricDefinitions').findBy('label', trait);
        if (metricDef) {
          var referencingModules = Ember.get(metricDef, 'referencingModules');
          if (referencingModules.length && Ember.get(_this, 'model.report.socialNetwork') === 'Custom') {
            label = Ember.get(referencingModules.objectAt(0), 'label');
          } else {
            label = Ember.get(metricDef, 'title');
          }
        } else {
          label = trait === 'traits' ? 'Attributes' : Ember.String.dasherize(trait).replace(/-/g, ' ');
        }
        return {
          label: label,
          value: trait
        };
      });
      traits = traits.sortBy('label');
      var traitSpot = traits.findBy('value', 'traits');
      traits.splice(traits.indexOf(traitSpot), 1);
      traits.unshift(traitSpot);
      return traits;
    }),
    selectedSummaryView: Ember.computed('summaryViews', function () {
      return Ember.get(Ember.get(this, 'summaryViews').objectAt(0), 'label');
    }),
    selectedTraitType: Ember.computed('traitTypes', function () {
      return Ember.get(Ember.get(this, 'traitTypes').objectAt(0), 'value');
    }),
    selectedBaselineOption: Ember.computed('baselineOptions', function () {
      return Ember.get(Ember.get(this, 'baselineOptions').findBy('data', 'clusters'), 'label');
    }),
    clusters: Ember.computed('model.report.{baselineReport,clusters.@each.soWhat}', function () {
      if (Ember.get(this, 'model.report.baselineReport')) {
        return Ember.get(this, 'model.report.clusters').rejectBy('isHidden').sortBy('soWhat').reverse();
      } else {
        return Ember.get(this, 'model.report.clusters').rejectBy('isHidden');
      }
    })
  });
});