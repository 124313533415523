define("affinio/components/modals/modal-stream", ["exports", "affinio/components/modals/modal-base"], function (_exports, _modalBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _modalBase.default.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.$('.close-modal').focus();
    },
    classNames: ['tweetstream']
  });
});