define("affinio/components/asset-group-list", ["exports", "affinio/components/report-builder", "affinio/utils/mutations", "affinio/utils/get-with-default"], function (_exports, _reportBuilder, _mutations, _getWithDefault) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
  var _default = _exports.default = Ember.Component.extend({
    // Element
    classNames: ['asset-group-list'],
    tagName: 'div',
    // Services
    user: Ember.inject.service(),
    tracker: Ember.inject.service(),
    modalManager: Ember.inject.service(),
    graph: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      var sortByOptions = {
        name: {
          asc: null,
          dsc: null
        },
        handles: {
          asc: null,
          dsc: null
        },
        updated: {
          asc: null,
          dsc: true
        }
      };
      Ember.set(this, 'sortBy', sortByOptions);
      Ember.set(this, 'sortType', 'updated');
      Ember.set(this, 'sortDirection', 'dsc');
    },
    // TODO use ember-macro-helpers!
    hasGroups: Ember.computed('groups.[]', function () {
      var groupsOfType = Ember.get(this, 'actualGroups') ? Ember.get(this, 'actualGroups').filterBy('socialNetwork', Ember.get(this, 'socialNetwork')) : null;
      return groupsOfType ? Ember.get(groupsOfType, 'length') > 0 : null;
    }),
    actualGroups: Ember.computed('groups.[]', 'socialNetwork', 'isCompany', function () {
      if (Ember.get(this, 'socialNetwork') !== 'Twitter' || !Ember.get(this, 'isCompany') || !Ember.get(this, 'groups')) {
        return Ember.get(this, 'groups');
      }
      var groupsWithDefault = Ember.A();
      groupsWithDefault.pushObjects(Ember.get(this, 'groups').toArray());
      groupsWithDefault.pushObject(Ember.Object.create({
        id: '_default',
        name: _reportBuilder.DEFAULT_BENCHMARK_GROUP.name,
        assetType: 'owned',
        assets: _reportBuilder.DEFAULT_BENCHMARK_GROUP.members.map(function (name) {
          return {
            name: name,
            id: null
          };
        }),
        socialNetwork: 'Twitter',
        dateCreated: Date.now(),
        dateUpdated: Date.now()
      }));
      return groupsWithDefault;
    }),
    // Computed Properties
    filteredGroups: Ember.computed('groups.[]', 'socialNetwork', 'filterInputValue', 'sortBy', 'sortKey', 'sortType', 'sortDirection', function () {
      var sortTypePropertyMap = {
        name: 'name',
        handles: 'assets.length',
        updated: 'dateUpdated'
      };
      var filterInputValue = Ember.get(this, 'filterInputValue') ? Ember.get(this, 'filterInputValue').trim().toLowerCase() : null;
      var socialNetwork = Ember.get(this, 'socialNetwork');
      var filteredGroups = Ember.get(this, 'actualGroups').filter(function (group) {
        return (filterInputValue ? Ember.get(group, 'name').toLowerCase().match(filterInputValue) : true) && Ember.get(group, 'socialNetwork') === socialNetwork;
      }).sortBy(Ember.get(this, 'sortType') ? sortTypePropertyMap[Ember.get(this, 'sortType')] : 'updated');
      return Ember.get(this, 'sortDirection') === 'dsc' || Ember.get(this, 'sortDirection') === undefined ? filteredGroups.reverse() : filteredGroups;
    }),
    actions: {
      sortAssets: function sortAssets(type) {
        var sortByHash = Ember.get(this, 'sortBy');
        var currentSortDirection = '';
        for (var directionKey in sortByHash[type]) {
          if (sortByHash[type][directionKey] === true) {
            currentSortDirection = directionKey;
          }
        }
        // clear
        for (var sortTypeKey in sortByHash) {
          for (var _directionKey in sortByHash[sortTypeKey]) {
            Ember.set(sortByHash, "".concat(sortTypeKey, ".").concat(_directionKey), null);
          }
        }
        var _Object$keys$reject = Object.keys(sortByHash[type]).reject(function (key) {
            return key === currentSortDirection;
          }),
          _Object$keys$reject2 = _slicedToArray(_Object$keys$reject, 1),
          newSortDirection = _Object$keys$reject2[0];
        // sortByHash[type][newSortDirection] = true;
        Ember.set(sortByHash, "".concat(type, ".").concat(newSortDirection), true);
        // set passed in
        Ember.set(this, 'sortKey', "".concat(type, ".").concat(newSortDirection));
        Ember.set(this, 'sortType', type);
        Ember.set(this, 'sortDirection', newSortDirection);
      },
      addAction: function addAction(assetGroup) {
        Ember.get(this, 'addAction')(assetGroup);
      },
      showDeleteAssetGroupModal: function showDeleteAssetGroupModal(assetGroup) {
        if (assetGroup.id === '_default') {
          return;
        }
        var name = Ember.get(assetGroup, 'name');
        Ember.set(this, 'assetGroupToDelete', assetGroup);
        Ember.get(this, 'modalManager').openModal('modals/modal-boolean', {
          question: "Are you sure you want to delete ".concat(name, "?"),
          okText: 'Delete Tracked Interests Group',
          okAction: Ember.get(this, 'actions.deleteAssetGroup').bind(this)
        });
      },
      deleteAssetGroup: function deleteAssetGroup() {
        var assetGroup = Ember.get(this, 'assetGroupToDelete');
        var id = Ember.get(assetGroup, 'id');
        // get(this, 'tracker').track('deleting asset group', { id });
        Ember.get(this, 'graph').mutate(_mutations.default.deleteAssetGroup(id)).then(function () {
          return assetGroup.destroyRecord();
        }).catch(window.console.log);
      },
      assetGroupModal: function assetGroupModal(assetGroup) {
        if (assetGroup.id === '_default') {
          return;
        }
        var newAssetGroup;
        if (!assetGroup) {
          newAssetGroup = {
            name: '',
            socialNetwork: Ember.get(this, 'socialNetwork'),
            assets: []
          };
        }
        Ember.get(this, 'modalManager').openModal('modals/modal-asset-group', {
          title: "New ".concat(Ember.get(this, 'socialNetwork'), " Tracked Interests Group"),
          okText: 'OK',
          assetGroup: assetGroup ? assetGroup : newAssetGroup,
          isToolbox: (0, _getWithDefault.default)(this, 'isToolbox', false),
          toolboxID: Ember.get(this, 'toolboxID'),
          socialNetwork: Ember.get(this, 'socialNetwork')
        });
      },
      // assetGroupModal
      createGroup: function createGroup() {
        var _this = this;
        // console.log('createGroup');
        if (!Ember.get(this, 'creatingGroup')) {
          var name = 'untitled group';
          var isCompany = Ember.get(this, 'isCompany');
          var isToolbox = (0, _getWithDefault.default)(this, 'isToolbox', false);
          var assetType = 'owned';
          var socialNetwork = Ember.get(this, 'socialNetwork');
          var entityId = isToolbox ? Ember.get(this, 'toolboxID') : isCompany ? Ember.get(this, 'user.company.id') : Ember.get(this, 'user.account.id');
          return Ember.get(this, 'graph').mutate(_mutations.default.createAssetGroup(name, assetType, socialNetwork, entityId, isCompany)).then(function (assetGroup) {
            // console.log('create assetGroup return', assetGroup);
            Ember.get(_this, 'tracker').track('created benchmark group', {
              id: Ember.get(assetGroup, 'id')
            });
            Ember.get(_this, "user.".concat(isCompany ? 'company' : 'account', ".assetGroups")).pushObject(assetGroup);
            Ember.set(_this, 'creatingGroup', false);
            Ember.set(_this, 'newAssetGroup', null);
            return assetGroup;
          }).catch(function (err) {
            Ember.get(_this, 'tracker').track('failed to add asset group', {
              error: JSON.stringify(err)
            });
            return null;
          });
        }
      } // createGroup
    }
  });
});