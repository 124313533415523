define("affinio/components/asset-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var color = function color(value, array) {
    if (array.every(function (a) {
      return a === 0;
    })) {
      return 'background-color: #ffffff';
    }
    // TODO this shouldnt have anything to do with the css string, just return the color hex
    var min = _.min(array).toFixed(2);
    var max = _.max(array).toFixed(2);
    var colorScale = d3.scale.linear().domain([min, max]).range(['white', '#3F9EEA']);
    return "background-color: ".concat(colorScale(value));
  };
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['asset-row'],
    preferences: Ember.inject.service(),
    scores: Ember.computed('asset', 'shadeBy', 'asset.scoresMap.@each.isHidden', 'preferences.interestShadingSelected', 'isTML', 'isLocationTimesMoreLikely', 'isLocationRelevance', function () {
      var _this = this;
      var _Ember$get = Ember.get(this, 'asset'),
        timesMoreLikelyMap = _Ember$get.timesMoreLikelyMap,
        scoresMap = _Ember$get.scoresMap,
        locationTimesMoreLikelyMap = _Ember$get.locationTimesMoreLikelyMap,
        locationScoresMap = _Ember$get.locationScoresMap;
      return scoresMap && scoresMap.map(function (tribe, index) {
        var id = tribe.tribe;
        return {
          id: id,
          // name: tribe.get('name'),
          isHidden: tribe.isHidden,
          score: scoresMap.findBy('tribe', id) ? scoresMap.findBy('tribe', id).score.toFixed(1) : 0,
          color: scoresMap.findBy('tribe', id) && Ember.get(_this, 'shadeBy') !== 'none' ? color(scoresMap.findBy('tribe', id).score.toFixed(1), scoresMap.mapBy('score')) : 'background-color: #ffffff',
          colorCluster: scoresMap.findBy('tribe', id).score && Ember.get(_this, 'shadeBy') !== 'none' ? color(scoresMap.findBy('tribe', id).score, Ember.get(_this, 'clusterAsset')[index].scoresMap.mapBy('score')) : 'background-color: #ffffff',
          tmlscore: timesMoreLikelyMap.findBy('tribe', id) ? timesMoreLikelyMap.findBy('tribe', id).score.toFixed(0) : '0',
          tmlcolor: timesMoreLikelyMap.findBy('tribe', id) && Ember.get(_this, 'shadeBy') !== 'none' ? color(timesMoreLikelyMap.findBy('tribe', id).score, timesMoreLikelyMap.mapBy('score')) : 'background-color: #ffffff',
          tmlCluster: timesMoreLikelyMap.findBy('tribe', id).score && Ember.get(_this, 'shadeBy') !== 'none' ? color(timesMoreLikelyMap.findBy('tribe', id).score, Ember.get(_this, 'clusterAsset')[index].timesMoreLikelyMap.mapBy('score')) : 'background-color: #ffffff',
          locationscore: locationScoresMap.findBy('tribe', id) ? locationScoresMap.findBy('tribe', id).score.toFixed(1) : 0,
          locationscorecolor: locationScoresMap.findBy('tribe', id) && Ember.get(_this, 'shadeBy') !== 'none' ? color(locationScoresMap.findBy('tribe', id).score.toFixed(1), locationScoresMap.mapBy('score')) : 'background-color: #ffffff',
          locationscoreCluster: locationScoresMap.findBy('tribe', id).score && Ember.get(_this, 'shadeBy') !== 'none' ? color(locationScoresMap.findBy('tribe', id).score.toFixed(1), Ember.get(_this, 'clusterAsset')[index].locationScoresMap.mapBy('score')) : 'background-color: #ffffff',
          locationtmlscore: locationTimesMoreLikelyMap.findBy('tribe', id) ? locationTimesMoreLikelyMap.findBy('tribe', id).score.toFixed(0) : '0',
          locationtmlcolor: locationTimesMoreLikelyMap.findBy('tribe', id) && Ember.get(_this, 'shadeBy') !== 'none' ? color(locationTimesMoreLikelyMap.findBy('tribe', id).score, locationTimesMoreLikelyMap.mapBy('score')) : 'background-color: #ffffff',
          locationtmlCluster: locationTimesMoreLikelyMap.findBy('tribe', id).score && Ember.get(_this, 'shadeBy') !== 'none' ? color(locationTimesMoreLikelyMap.findBy('tribe', id).score, Ember.get(_this, 'clusterAsset')[index].locationTimesMoreLikelyMap.mapBy('score')) : 'background-color: #ffffff'
        };
      });
    })
  });
});