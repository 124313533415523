define("affinio/components/module-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Element
    classNames: ['module-info'],
    classNameBindings: ['active::hidden'],
    attributeBindings: ['style'],
    style: Ember.computed('top', 'right', function () {
      return Ember.String.htmlSafe("right: ".concat(Ember.get(this, 'right'), "px; top: ").concat(Ember.get(this, 'top'), "px;"));
    })
  });
});