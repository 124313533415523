define("affinio/templates/components/modules/audience-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "2KwSehtg",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1],[0,\"\\n\"],[7,\"ul\"],[9],[0,\"\\n\"],[4,\"if\",[[27,\"is-equal\",[[23,[\"model\",\"report\",\"socialNetwork\"]],\"Custom\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"li\"],[9],[7,\"strong\"],[9],[0,\"Date:\"],[10],[0,\" \"],[7,\"span\"],[9],[1,[21,\"dateEnd\"],false],[10],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"li\"],[9],[7,\"strong\"],[9],[0,\"Date Range:\"],[10],[0,\" \"],[7,\"span\"],[9],[1,[21,\"dateStart\"],false],[0,\" - \"],[1,[21,\"dateEnd\"],false],[10],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[7,\"li\"],[9],[7,\"strong\"],[9],[0,\"Size:\"],[10],[0,\" \"],[7,\"span\"],[9],[1,[27,\"commafy-number\",[[23,[\"model\",\"report\",\"numberOfMembers\",\"response\",\"value\"]]],null],false],[10],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"activity\"]]],null,{\"statements\":[[4,\"if\",[[27,\"is-equal\",[[23,[\"model\",\"report\",\"socialNetwork\"]],\"Reddit\"],null]],null,{\"statements\":[[0,\"      \"],[7,\"li\"],[9],[7,\"strong\"],[9],[0,\"Average Number of Posts:\"],[10],[0,\" \"],[7,\"span\"],[9],[1,[21,\"activity\"],false],[0,\" \"],[10],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"li\"],[9],[7,\"strong\"],[9],[0,\"Engagement:\"],[10],[0,\" \"],[7,\"span\"],[9],[1,[21,\"activity\"],false],[0,\" \"],[1,[27,\"plural-ify\",[[23,[\"model\",\"report\",\"postType\"]]],null],false],[0,\"/month\"],[10],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[4,\"if\",[[23,[\"model\",\"report\",\"clusterAggregation\",\"interestAffinity\",\"value\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\"],[9],[7,\"strong\"],[9],[0,\"Interest Relevance:\"],[10],[0,\" \"],[7,\"span\"],[9],[1,[27,\"cull-number\",[[23,[\"model\",\"report\",\"clusterAggregation\",\"interestAffinity\",\"value\"]],2],null],false],[10],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/modules/audience-details.hbs"
    }
  });
});