define("affinio/components/report-builder/radio-input", ["exports", "moment", "affinio/utils/constants/date-formats", "affinio/utils/get-with-default"], function (_exports, _moment, _dateFormats, _getWithDefault) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
  var defaultInput = [{
    'label': 'Last 30 days',
    'value': 30,
    'isDefault': true
  }, {
    'label': 'Last 60 days',
    'value': 60
  }];
  var _default = _exports.default = Ember.Component.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      this.valueChange();
    },
    // Element
    classNames: ['radio-input'],
    tagName: 'div',
    schemaLabel: Ember.computed(function () {
      return (0, _getWithDefault.default)(this, 'moduleSchema.label', 'default');
    }),
    schemaInputs: Ember.computed('moduleSchema.inputs', function () {
      return (0, _getWithDefault.default)(this, 'moduleSchema.inputs', defaultInput);
    }),
    groupValue: Ember.computed(function () {
      var _getWithDefault$filte = (0, _getWithDefault.default)(this, 'moduleSchema.inputs', defaultInput).filterBy('isDefault').mapBy('value'),
        _getWithDefault$filte2 = _slicedToArray(_getWithDefault$filte, 1),
        value = _getWithDefault$filte2[0];
      return value;
    }),
    valueChange: Ember.observer('groupValue', function () {
      var val = Ember.get(this, 'groupValue');
      var startDate = (0, _moment.default)().format(_dateFormats.longYearDashSeparated);
      var dateRange = {};
      switch (val) {
        case 60:
          dateRange = {
            end: startDate,
            start: (0, _moment.default)(startDate).subtract(60, 'days').format(_dateFormats.longYearDashSeparated)
          };
          break;
        default:
          dateRange = {
            end: startDate,
            start: (0, _moment.default)(startDate).subtract(30, 'days').format(_dateFormats.longYearDashSeparated)
          };
          break;
      }
      var filterObject = {};
      filterObject = {
        field: Ember.get(this, 'schemaLabel'),
        condition: 'after',
        value: dateRange.start,
        datasourceid: Ember.get(this, 'moduleSchema.datasourceid')
      };
      Ember.set(this, "model.filters.".concat(Ember.get(this, 'schemaLabel')), []);
      Ember.get(this, "model.filters.".concat(Ember.get(this, 'schemaLabel'))).pushObject(filterObject);
      filterObject = {
        field: Ember.get(this, 'schemaLabel'),
        condition: 'before',
        value: dateRange.end,
        datasourceid: Ember.get(this, 'moduleSchema.datasourceid')
      };
      Ember.get(this, "model.filters.".concat(Ember.get(this, 'schemaLabel'))).pushObject(filterObject);
      // I hate toggling here, but its needed to recompute if primary boolean can show up for custom within a data source
      // :thinking: - JK Sept 2019
      this.toggleProperty('model.filterChange');
    })
  });
});