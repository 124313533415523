define("affinio/router", ["exports", "affinio/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  router.map(function () {
    this.route('reports', function () {
      this.route('new');
      this.route('build');
      this.route('loading'); // if we skip this we render the report and therefore our skeleton states.
      this.route('report', {
        path: '/:report_id'
      }, function () {
        this.route('version', {
          path: '/:version_id'
        }, function () {
          this.route('cluster', {
            path: '/:cluster_id'
          }, function () {
            this.route('category', {
              path: '/:category'
            }, function () {
              this.route('subcategory', {
                path: '/:subcategory'
              });
            });
          });
        });
      });
    });
    this.route('sign-in');
    this.route('settings', function () {
      this.route('preferences');
      this.route('security');
      this.route('benchmarks');
      this.route('integrations');
    });
    this.route('admin', function () {
      this.route('companies', function () {
        this.route('company', {
          path: '/:id'
        });
      });
      this.route('groups', function () {
        this.route('company', {
          path: '/:company_id'
        }, function () {
          this.route('group', {
            path: '/:group_id'
          });
        });
      });
      this.route('users', function () {
        this.route('company', {
          path: '/:company_id'
        }, function () {
          this.route('user', {
            path: '/:user_id'
          });
        });
      });
      this.route('tags-suggestion');
      this.route('user-search');
      this.route('metrics');
      this.route('report-search');
      this.route('toolbox');
    });
    this.route('sign-out');
    this.route('search');
    this.route('sign-in-ms');
    this.route('oauth-twitter');
    this.route('wildcard', {
      path: '/*'
    });
    this.route('reset-password');
    if (_environment.default.APP.features.custom) {
      this.route('aton', function () {
        this.route('about');
        this.route('sources');
        this.route('taxonomy');
      });
    }
  });
  var _default = _exports.default = router;
});