define("affinio/templates/components/spark-bars", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "vGHo0uNY",
    "block": "{\"symbols\":[\"number\",\"bar\",\"&default\"],\"statements\":[[4,\"each\",[[27,\"range\",[0,[27,\"get\",[[23,[\"relativizedBars\"]],\"length\"],null]],null]],null,{\"statements\":[[4,\"with\",[[27,\"object-at\",[[22,1,[]],[23,[\"relativizedBars\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"spark-bar\"],[12,\"style\",[22,2,[\"style\"]]],[12,\"title\",[22,2,[\"title\"]]],[9],[10],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]},null],[0,\"\\n\"],[14,3]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/spark-bars.hbs"
    }
  });
});