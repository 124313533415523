define("affinio/templates/reports/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "HQjlF3Dk",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\"],[11,\"id\",\"campaign-upper\"],[9],[0,\"\\n  \"],[7,\"header\"],[11,\"class\",\"campaign-header\"],[9],[0,\"\\n    \"],[1,[27,\"macro-menu\",null,[[\"audiences\"],[[23,[\"model\"]]]]],false],[0,\"\\n    \"],[1,[27,\"user-nav\",null,[[\"open\"],[[23,[\"userNavOpen\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"app\",\"outageInfo\",\"onReportBuilder\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"modules/page-message\",null,[[\"class\",\"moduleRequest\"],[\"module data-missing\",[27,\"hash\",null,[[\"message\",\"returnURL\",\"returnCopy\"],[[23,[\"app\",\"outageInfo\",\"message\"]],\"reports\",\"Back to Reports List\"]]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"unless\",[[23,[\"user\",\"account\",\"isViewerSeat\"]]],null,{\"statements\":[[0,\"    \"],[7,\"section\"],[11,\"class\",\"report-builder-container\"],[9],[0,\"\\n      \"],[1,[27,\"report-builder\",null,[[\"model\",\"editReports\",\"stage\",\"reportType\",\"network\",\"sourceReport\"],[[23,[\"model\"]],[27,\"route-action\",[\"editReports\"],null],[23,[\"stage\"]],[23,[\"reportType\"]],[23,[\"network\"]],[23,[\"sourceReport\"]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[27,\"modules/page-message\",null,[[\"class\",\"moduleRequest\"],[\"module data-missing\",[27,\"hash\",null,[[\"message\",\"returnURL\",\"returnCopy\"],[\"Viewer users cannot create reports. If you want this permission, please contact your Affinio admin or account representative.\",\"reports\",\"Back to Reports List\"]]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/reports/new.hbs"
    }
  });
});