define("affinio/services/local-storage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var storage = {
    local: window.localStorage,
    session: window.sessionStorage
  };
  var _default = _exports.default = Ember.Service.extend({
    prefix: 'as',
    // affinio storage :wow:
    type: 'local',
    _prefix: function _prefix(key) {
      return "".concat(Ember.get(this, 'prefix'), "__").concat(key);
    },
    setUpEventListener: Ember.on('init', function () {
      var _this = this;
      var regexp = new RegExp("^(".concat(Ember.get(this, 'prefix'), "__)"));
      this._notify = function (evnt) {
        _this.notifyPropertyChange(evnt.key.replace(regexp, ''));
      };
      window.addEventListener('storage', this._notify, false);
    }),
    willDestroy: function willDestroy() {
      this._super();
      window.removeEventListener('storage', this._notify, false);
    },
    unknownProperty: function unknownProperty(k) {
      var key = this._prefix(k);
      var type = Ember.get(this, 'type');
      // if we don't use JSON.parse here then observing a boolean doesn't work
      return storage[type][key] && JSON.parse(storage[type][key]);
    },
    setUnknownProperty: function setUnknownProperty(k, value) {
      var key = this._prefix(k);
      var type = Ember.get(this, 'type');
      if (Ember.isNone(value)) {
        delete storage[type][key];
      } else {
        storage[type][key] = JSON.stringify(value);
      }
      this.notifyPropertyChange(k);
      return value;
    },
    clear: function clear(keyPrefix) {
      var _this2 = this;
      this.beginPropertyChanges();
      var prefix = keyPrefix || Ember.get(this, 'prefix');
      var regexp = new RegExp("^(".concat(prefix, "__)"));
      var type = Ember.get(this, 'type');
      var toDelete = [];
      for (var i = 0; i < storage[type].length; i++) {
        var key = storage[type].key(i);
        // don't nuke *everything* in localStorage... just keys that match our pattern
        if (key.match(regexp)) {
          toDelete.push(key);
        }
      }
      toDelete.forEach(function (key) {
        delete storage[type][key];
        key = key.replace(regexp, '');
        Ember.set(_this2, key, null);
      });
      this.endPropertyChanges();
    },
    clearAuth: function clearAuth() {
      Ember.setProperties(this, {
        affinioAuth: null,
        affinioAuthExpiry: null,
        lastRefreshTime: null,
        tabAuthInfo: null,
        tabLogoutInfo: null,
        tabAuthEarly: null,
        refreshLock: null
      });
    },
    clearDeprecated: function clearDeprecated() {
      Ember.setProperties(this, {
        promisesResolved: null,
        explicitSignIn: null,
        explicitSignOut: null
      });
    }
  });
});