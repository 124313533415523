define("affinio/templates/components/report-builder/taxonomy-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "c5KAUzyM",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"info\"],[9],[0,\"\\n  \"],[7,\"p\"],[9],[0,\"You currently have \"],[1,[27,\"or\",[[23,[\"schema\",\"length\"]],0],null],false],[0,\" custom audience sources.\"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"customFeatures\"]]],null,{\"statements\":[[4,\"link-to\",[\"aton.about\"],[[\"class\"],[\"button add-custom-source-bt\"]],{\"statements\":[[0,\"      \"],[1,[27,\"font-awesome\",[\"plus\"],null],false],[0,\" Audience Source\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"report-search\"],[9],[0,\"\\n  \"],[1,[27,\"font-awesome\",[\"search\"],null],false],[0,\"\\n  \"],[1,[27,\"input\",null,[[\"type\",\"placeholder\",\"value\",\"input\"],[\"text\",\"Search by name\",[23,[\"query\"]],[27,\"perform\",[[23,[\"search\"]]],null]]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[1,[27,\"affinio-table\",null,[[\"columns\",\"rows\",\"onSelect\",\"selection\"],[[23,[\"columns\"]],[23,[\"rows\"]],[27,\"action\",[[22,0,[]],\"selectRow\"],null],[23,[\"selection\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/report-builder/taxonomy-selector.hbs"
    }
  });
});