define("affinio/utils/get-with-default", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getWithDefault;
  /**
   * Retrieves the value of a property, or a default value in the case that the property returns null or undefined.
   * @method getWithDefault
   * @param {Object} root The object to retrieve from.
   * @param {String} key The name of the property to retrieve
   * @param {Object} defaultValue The value to return if the property value is undefined or null
   * @return {Object} The property value or the defaultValue.
   */
  function getWithDefault(root, key, defaultValue) {
    if (!root) {
      return defaultValue;
    }
    var value = Ember.get(root, key);
    if (value === undefined || value === null) {
      return defaultValue;
    }
    return value;
  }
});