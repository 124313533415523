define("affinio/templates/components/modules/top-interests", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "CFgKWFkN",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[22,0,[]]]],[0,\"\\n\"],[7,\"section\"],[12,\"class\",[28,[[21,\"defaultClasses\"],\" \",[27,\"if\",[[23,[\"showUnique\"]],\"unique-view\"],null]]]],[9],[0,\"\\n\"],[0,\"  \"],[1,[27,\"interests-container\",null,[[\"loaded\",\"interests\",\"pageSize\",\"view\",\"visualizationProperties\",\"interestsLook\",\"sortBy\",\"selectedBenchmarkGroupId\",\"model\",\"uniqueArray\",\"activeInterest\",\"parentSelector\",\"showUnique\",\"metric\",\"recordsMap\",\"shadeBy\",\"recordsInstanceKey\"],[[23,[\"model\",\"requestsFulfilled\"]],[23,[\"slicedInfluencers\"]],[23,[\"moduleRequest\",\"sliceSize\"]],\"list\",[23,[\"moduleRequest\",\"moduleProperties\",\"visualizationProperties\"]],[23,[\"interestsLook\"]],[23,[\"currentSortBy\"]],[23,[\"selectedBenchmarkGroupId\"]],[23,[\"model\"]],[23,[\"uniqueInterests\"]],[23,[\"activeInfluencer\"]],[23,[\"parentSelector\"]],[23,[\"showUnique\"]],[23,[\"metric\"]],[23,[\"recordsMap\"]],[23,[\"interestsShadeBy\"]],[23,[\"moduleRequest\",\"recordsInstanceKey\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/modules/top-interests.hbs"
    }
  });
});