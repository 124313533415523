define("affinio/components/affinio-module", ["exports", "affinio/utils/get-with-default"], function (_exports, _getWithDefault) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Element
    classNames: ['affinio-module-wrapper'],
    classNameBindings: ['activelyEditing:active', 'fullWidth', 'stretch'],
    fullWidth: Ember.computed.or('moduleRequest.fullWidth', 'moduleSchema.fullWidth'),
    stretch: Ember.computed.or('moduleRequest.stretch', 'moduleSchema.stretch'),
    attributeBindings: ['moduleRequest.id:data-module-id'],
    tagName: 'article',
    // Services
    modules: Ember.inject.service(),
    tracker: Ember.inject.service(),
    moduleSchema: Ember.computed('moduleRequest.value', function () {
      return Ember.get(this, "modules.schema.".concat((0, _getWithDefault.default)(this, 'moduleRequest.value', '').camelize()));
    })
  });
});