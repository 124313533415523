define("affinio/utils/queries/users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.users = _exports.userPermissions = _exports.user = _exports.twitterAdsAccounts = _exports.getTwitterCredentials = _exports.generateTwitterOauthRequestToken = _exports.default = _exports.allUsers = void 0;
  var allUsers = _exports.allUsers = {
    cacheKey: 'allUsers',
    queryString: "query {\n      allAccounts {\n        id\n        firstName\n        lastName\n        email\n        isActive\n        permissions\n        company{\n          id\n        }\n    }\n  }",
    queryMap: {
      allAccounts: {
        array: true,
        modelName: 'account',
        responseKey: 'allAccounts'
      }
    }
  };
  var user = _exports.user = function user(accountId, companyId, schema) {
    return {
      queryString: "query {\n      company(id:\"".concat(companyId, "\") {\n        id\n        name\n        groups{\n          id\n          name\n        }\n      }\n      account(id:\"").concat(accountId, "\") {\n        id\n        firstName\n        lastName\n        email\n        isActive\n        twitterCredentials {\n          screenName\n        }\n        thirdPartyIntegrations {\n          source\n          key\n        }\n        preferences {\n          categoricalColors\n          shouldOpenFolders\n          shouldNameClusters\n          dateUpdated\n        }\n        company {\n          id\n          trialInfo {\n            isActive\n            startDate\n            endDate\n          }\n        }\n        ").concat(schema === 'private' ? 'notes' : '', "\n        groups{\n          id\n          name\n        }\n        permissions\n      }\n    }"),
      queryMap: {
        company: {
          array: false,
          modelName: 'company',
          responseKey: 'company'
        },
        account: {
          array: false,
          modelName: 'account',
          responseKey: 'account'
        }
      }
    };
  };
  var users = _exports.users = function users(companyId) {
    return {
      queryString: "query {\n      company(id:\"".concat(companyId, "\") {\n        id\n        seatLimit\n        viewerSeatLimit\n        accounts{\n          id\n          firstName\n          lastName\n          email\n          isActive\n          permissions\n        }\n      }\n    }"),
      queryMap: {
        company: {
          array: false,
          modelName: 'company',
          responseKey: 'company'
        }
      }
    };
  };
  var userPermissions = _exports.userPermissions = {
    queryString: "query {\n    me {\n      id\n      permissions\n    }\n  }",
    queryMap: {
      company: {
        array: false,
        modelName: 'account',
        responseKey: 'me'
      }
    }
  };
  var twitterAdsAccounts = _exports.twitterAdsAccounts = {
    queryString: "query {\n    twitterAdAccounts {\n      value:id\n      label:name\n    }\n  }",
    queryMap: {}
  };
  var generateTwitterOauthRequestToken = _exports.generateTwitterOauthRequestToken = function generateTwitterOauthRequestToken(callbackURL) {
    return {
      queryString: "query {\n      twitterOauthRequestToken(callbackURL:\"".concat(callbackURL, "\")\n    }"),
      queryMap: {}
    };
  };
  var getTwitterCredentials = _exports.getTwitterCredentials = {
    queryString: "query {\n    me {\n      id\n      twitterCredentials {\n        userID\n        screenName\n      }\n    }\n  }",
    queryMap: {
      account: {
        array: false,
        modelName: 'account',
        responseKey: 'me'
      }
    }
  };
  var _default = _exports.default = {
    allUsers: allUsers,
    user: user,
    users: users,
    userPermissions: userPermissions,
    twitterAdsAccounts: twitterAdsAccounts,
    generateTwitterOauthRequestToken: generateTwitterOauthRequestToken,
    getTwitterCredentials: getTwitterCredentials
  };
});