define("affinio/components/modules/traits-demographics", ["exports", "affinio/mixins/affinio-module"], function (_exports, _affinioModule) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_affinioModule.default, {
    // Element
    classNames: ['traits-demographics'],
    tagName: 'div',
    init: function init() {
      this._super.apply(this, arguments);
      Ember.set(this, 'mockData', {
        ageMax: 0,
        female65: 0,
        female1824: 0,
        female2534: 0,
        female3544: 0,
        female4554: 0,
        female5564: 0,
        femaleTotal: 0,
        genderMax: 0,
        male65: 0,
        male1824: 0,
        male2534: 0,
        male3544: 0,
        male4554: 0,
        male5564: 0,
        maleTotal: 0,
        totalFaces: 0
      });
    }
  });
});