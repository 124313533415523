define("affinio/components/modules/engagement-grid", ["exports", "affinio/mixins/affinio-module"], function (_exports, _affinioModule) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_affinioModule.default, {
    // Element
    classNames: ['engagement-grid', 'module', 'card'],
    tagName: 'div',
    preferences: Ember.inject.service(),
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;
      Ember.run.scheduleOnce('afterRender', this, function () {
        if (Ember.get(_this, 'postsTimeSeries') && Ember.get(_this, 'preferences.highlightedTime')) {
          // update preferences.highlightedTime
          Ember.set(_this, 'preferences.highlightedTime', Ember.get(_this, 'postsTimeSeries').find(function (day) {
            return day.label === Ember.get(_this, 'preferences.highlightedTime.day');
          }).hours.find(function (hour) {
            return hour.label === Ember.get(_this, 'preferences.highlightedTime.label');
          }));
          Ember.set(_this, 'preferences.highlightedTime.highlight', true);
          Ember.set(_this, 'preferences.highlightedTime.stickied', true);
        } // if highlightedTime
        if (Ember.get(_this, 'preferences.activeEngagement')) {
          if (Ember.get(_this, 'preferences.activeEngagement') === 'min') {
            Ember.set(_this, 'postsTimeSeries.hours.min.active', true);
          }
          if (Ember.get(_this, 'preferences.activeEngagement') === 'max') {
            Ember.set(_this, 'postsTimeSeries.hours.max.active', true);
          }
        }
      });
    }
  });
});