define("affinio/components/modules/csv-exporter", ["exports", "affinio/mixins/affinio-module", "affinio/utils/regex", "affinio/utils/get-with-default", "affinio/utils/affinio", "affinio/utils/constants/date-formats", "moment", "affinio/utils/interests"], function (_exports, _affinioModule, _regex, _getWithDefault, _affinio, _dateFormats, _moment, _interests3) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  var keys = window.Object.keys;
  var _default = _exports.default = Ember.Component.extend(_affinioModule.default, {
    // Element
    classNames: ['csv-exporter', 'export-tab-interface'],
    tagName: 'div',
    graph: Ember.inject.service(),
    path: Ember.inject.service(),
    tracker: Ember.inject.service(),
    user: Ember.inject.service(),
    modalManager: Ember.inject.service(),
    metricDefinitions: Ember.computed.alias('model.report.metricDefinitions'),
    exportSchema: Ember.computed.alias('model.report.taxonomy.exportSchema'),
    explicitWords: Ember.computed.alias('model.report.filters.explicitWords'),
    filteredExportSchema: Ember.computed('metricDefinitions', 'exportSchema.[]', 'explicitWords', 'model.{report.recordsCollection.loaded,cluster.id,requestsFulfilled}', function () {
      var _this = this;
      if (Ember.get(this, 'model.report.recordsCollection.loaded')) {
        var exportSchema = Ember.get(this, 'exportSchema');
        var metricDefinitions = Ember.get(this, 'metricDefinitions');
        var recordsMap = Ember.get(this, 'model.report.recordsMap');
        var explicitWords = Ember.get(this, 'explicitWords');
        var hasTrackedHashtags = explicitWords && explicitWords.some(function (n) {
          return n.type === 'hashtag';
        });
        var hasTrackedMentions = explicitWords && explicitWords.some(function (n) {
          return n.type === 'mention';
        });
        var hasTrackedKeywords = explicitWords && explicitWords.some(function (n) {
          return n.type === 'keyword';
        });
        var hasBenchmarkData = Ember.get(this, 'model.report.storedBenchmarkGroups.response.length') > 0;
        var hasTopRankedMetricDeltaData = Ember.get(this, 'model.cluster.topRankedMetricDelta') && Ember.get(this, 'model.cluster.topRankedMetricDelta.response.length');
        var hasCompetitive = Ember.get(this, 'model.report.hasCompetitive');
        var hasClusterOverview = Ember.get(this, 'model.report.socialNetwork') === 'Twitter' || Ember.get(this, 'model.report.socialNetwork') === 'Pinterest';

        // Filter based on whats in the records map, exceptions for non records things
        exportSchema = exportSchema.filter(function (n) {
          return n.metrics.some(function (m) {
            return recordsMap.has(m);
          }) || n.exportType === 'topicExplorer' && !Ember.isEmpty(Ember.get(_this, 'model.report.topicExplorer.response')) || n.exportType === 'clusterOverview' && hasClusterOverview || n.exportType === 'members' || n.exportType === 'tags' || n.exportType === 'interest' || n.exportType === 'breakdown' && hasCompetitive || n.exportType.indexOf('benchmarks') > -1 && hasBenchmarkData || n.metrics.indexOf('trackedHashtags') > -1 && hasTrackedHashtags || n.metrics.indexOf('trackedMentions') > -1 && hasTrackedMentions || n.metrics.indexOf('trackedKeywords') > -1 && hasTrackedKeywords || n.metrics.indexOf('topRankedMetricDelta') > -1 && hasTopRankedMetricDeltaData || n.exportType === 'clusterOverlap' || n.exportType === 'numeric';
        });

        // Filter location interests if report won't have it
        if (!Ember.get(this, 'model.report.hasLocationInterests')) {
          exportSchema = exportSchema.filter(function (n) {
            return !n.metrics.includes('_locationInterests');
          });
        }

        // Uncomment the below 2 lines if you want to remove the "Member's Profile Download to CSV" under the:
        // Sample report > Action > Export > All Clusters > Data Downloads > Export CSV
        // Filter based on cluster status
        // let isCluster = get(this, 'model.cluster.isCluster');
        // exportSchema = exportSchema.filter((n) => !get(n, isCluster ? 'aggregateOnly' : 'clusterOnly'));

        // Update labels from metricDefinitions
        exportSchema.forEach(function (n) {
          if (!Ember.get(n, 'label')) {
            Ember.set(n, 'label', Ember.get(metricDefinitions.findBy('label', n.metrics[0]) || metricDefinitions.findBy('modelAlias', n.metrics[0]), 'title')); // eslint-disable-line ember/no-side-effects
          }
        });
        return exportSchema;
      } else {
        return [];
      }
    }),
    selectedFilteredExportSchema: Ember.computed.filterBy('filteredExportSchema', 'selected', true),
    shouldHaveDelta: Ember.computed('model.version', function () {
      return parseInt(Ember.get(this, 'model.version')) > 0;
    }),
    // Generates a CSV from a single metric input. Expects the data to be fetched already
    generateCSV: function generateCSV(exportMetric, metricInstances) {
      var _this2 = this;
      var clusters = Ember.get(this, 'model.report.clusters');
      var intersectionExport = exportMetric.intersection && Ember.get(this, 'model.cluster.isAggregate');
      var compiledExport = intersectionExport || exportMetric.aggregation === 'compiled' && !Ember.get(this, 'model.cluster.isCluster');
      var uniqExport = exportMetric.uniqable && exportMetric.uniq === 'uniq' && Ember.get(this, 'model.cluster.isCluster');
      var shouldHaveDelta = Ember.get(this, 'shouldHaveDelta');
      var clusterName = Ember.get(this, 'model.cluster.isCluster') ? " - ".concat(Ember.get(this, 'model.cluster.name')) : '';
      var clusterJsonData = clusters.reduce(function (acc, cluster) {
        acc[cluster.suffix] = cluster.name;
        return acc;
      }, {});
      var csv = Ember.Object.create({
        rows: Ember.A(),
        name: intersectionExport ? "".concat(Ember.get(this, 'model.report.name')).concat(clusterName, " ").concat(exportMetric.label, " cluster overlap") : compiledExport ? "".concat(Ember.get(this, 'model.report.name')).concat(clusterName, " compiled ").concat(exportMetric.label) : "".concat(Ember.get(this, 'model.report.name')).concat(clusterName, " top ").concat(exportMetric.label),
        csvContent: ''
      });
      var rows = Ember.get(csv, 'rows');
      // Keys for interests
      var tagsKey = 'interestTags';
      var selectedKey = 'interestTagsAllSelected';
      if (exportMetric.metrics.includes('_locationInterests')) {
        tagsKey = 'locationInterestTags';
        selectedKey = 'locationInterestTagsAllSelected';
      }

      // get a reference to the actual metric instance
      // TODO this doesn't support multi metrics, but we don't really need it (yet)
      var metric;
      var trackedMetricLabels = ['trackedHashtags', 'trackedMentions', 'trackedKeywords'];
      if (trackedMetricLabels.includes(exportMetric.metrics[0])) {
        // TODO this may need to change if we get more tracked things that aren't the same as this
        metric = {
          label: exportMetric.metrics[0]
        };
      } else if (Ember.get(exportMetric, 'exportType') === 'numeric') {
        metric = metricInstances.objectAt(0);
      } else {
        metric = metricInstances.findBy('label', exportMetric.metrics[0]) || metricInstances.findBy('modelAlias', exportMetric.metrics[0]);
      }
      var linkPrefix = '';
      var isTwitter = false;
      switch (Ember.get(this, 'model.report.socialNetwork')) {
        case 'Twitter':
          linkPrefix = 'https://twitter.com/';
          isTwitter = true;
          break;
        case 'Instagram':
          linkPrefix = 'https://instagram.com/';
          break;
        case 'Pinterest':
          linkPrefix = 'https://pinterest.com/';
          break;
      }
      var linkHeader = 'Profile Link';
      if (Ember.get(this, 'model.report.socialNetwork') === 'Reddit') {
        linkHeader = 'Interest Link';
      }

      // Form data into csv.rows
      switch (exportMetric.exportType) {
        case 'standard':
          if (compiledExport) {
            var label = Ember.get(exportMetric, 'exportLabelAlias');
            if (!label) {
              label = 'label';
            }
            var value = Ember.get(exportMetric, 'exportValueAlias');
            if (!value) {
              value = 'value';
            }
            var _shouldHaveDelta = exportMetric.metrics.includes('overlaps') ? false : shouldHaveDelta;
            var rowHeader = _shouldHaveDelta ? "".concat(label, ",").concat(value, ",cluster,current rank,rank difference,value difference") : "".concat(label, ",").concat(value, ",cluster");
            rows.pushObject(rowHeader);
            clusters.forEach(function (cluster) {
              Ember.get(cluster, "".concat(Ember.get(metric, 'label'), ".response")).forEach(function (datum, i) {
                var row = "".concat((0, _getWithDefault.default)(datum, 'label', '').replace(_regex.commaRegex, ' '), ",").concat((0, _getWithDefault.default)(datum, 'value', 0).toFixed(0), ",").concat((0, _getWithDefault.default)(cluster, 'name', '').replace(_regex.commaRegex, ' '));
                if (_shouldHaveDelta) {
                  row = "".concat(row, ",").concat(i + 1, ",").concat(-Ember.get(datum, 'delta.rankDelta'), ",").concat(Ember.get(datum, 'delta.valueDelta'));
                }
                rows.pushObject(row);
              });
            });
          } else {
            var _label = Ember.get(exportMetric, 'exportLabelAlias');
            if (!_label) {
              _label = 'label';
            }
            var _value = Ember.get(exportMetric, 'exportValueAlias');
            if (!_value) {
              _value = 'value';
            }
            var _shouldHaveDelta2 = exportMetric.metrics.includes('overlaps') ? false : shouldHaveDelta;
            var _rowHeader = _shouldHaveDelta2 ? "".concat(_label, ",").concat(_value, ",current rank,rank difference,value difference") : "".concat(_label, ",").concat(_value);
            rows.pushObject(_rowHeader);
            Ember.get(this, "model.cluster.".concat(Ember.get(metric, 'label'), ".response")).forEach(function (datum, i) {
              var row = "".concat((0, _getWithDefault.default)(datum, 'label', '').replace(_regex.commaRegex, ' '), ",").concat((0, _getWithDefault.default)(datum, 'value', 0).toFixed(0));
              if (_shouldHaveDelta2) {
                row = "".concat(row, ",").concat(i + 1, ",").concat(-Ember.get(datum, 'delta.rankDelta'), ",").concat(Ember.get(datum, 'delta.valueDelta'));
              }
              rows.pushObject(row);
            });
          }
          break;
        case 'tags':
          {
            var isSingleCluster = Ember.get(this, 'model.cluster.isCluster');
            var _clusters = Ember.get(this, 'model.report.clusters');
            var childrenTags = _interests3.tagHierarchyMap.flatMap(function (parent) {
              return parent.children ? parent.children : [];
            });
            var tags = [];
            if (!isSingleCluster) {
              var networkIds = {};
              _clusters.forEach(function (c) {
                var response = c[Ember.get(metric, 'label')];
                if (response && response.response) {
                  response.response.forEach(function (item) {
                    if (!networkIds[item.networkID]) {
                      var _tags;
                      networkIds[item.networkID] = true;
                      (_tags = tags).push.apply(_tags, _toConsumableArray(item.tags.map(function (tagValue) {
                        if (!tagValue) {
                          return null;
                        }
                        var childFound = childrenTags.find(function (_ref) {
                          var value = _ref.value;
                          return value === tagValue;
                        });
                        return childFound ? childFound.label : null;
                      }).filter(Boolean)));
                    }
                  });
                }
              });
            } else {
              var interests = Ember.get(this, "model.cluster.".concat(Ember.get(metric, 'label'), ".response"));
              tags = _.flatten(interests.mapBy('tags')).map(function (tagValue) {
                if (!tagValue) {
                  return null;
                }
                var childFound = childrenTags.find(function (_ref2) {
                  var value = _ref2.value;
                  return value === tagValue;
                });
                return childFound ? childFound.label : null;
              }).filter(Boolean);
            }
            var tagCount = _.countBy(tags);
            var sortedTagLabels = Object.entries(tagCount).sort(function (_ref3, _ref4) {
              var _ref5 = _slicedToArray(_ref3, 2),
                value1 = _ref5[1];
              var _ref6 = _slicedToArray(_ref4, 2),
                value2 = _ref6[1];
              return value2 > value1 ? 1 : -1;
            });
            rows.pushObject('category,count');
            for (var i = 0; i < sortedTagLabels.length - 1; i++) {
              rows.pushObject("".concat(sortedTagLabels[i][0], ",").concat(sortedTagLabels[i][1]));
            }
            _toConsumableArray(new Set(childrenTags.filter(function (_ref7) {
              var label = _ref7.label;
              return tagCount[label] === null || tagCount[label] === undefined;
            }).map(function (_ref8) {
              var label = _ref8.label;
              return label;
            }))).forEach(function (label) {
              rows.pushObject("".concat(label, ",0"));
            });
            break;
          }
        case 'interest':
          if (compiledExport) {
            var interestsMap = clusters.mapBy("".concat(Ember.get(metric, 'label'), ".response"));

            // If intersection, we only want to show for aggregate cluster
            if (intersectionExport) {
              clusters = [Ember.get(this, 'model.report.clusterAggregation')];
            }

            // Determine if there is an intersection
            var intersection;
            if (intersectionExport) {
              var _ref9;
              var interestsLabelMap = interestsMap.map(function (n) {
                return n.mapBy('screenName');
              });
              intersection = (_ref9 = _).intersection.apply(_ref9, _toConsumableArray(interestsLabelMap));
              if (!intersection.length) {
                return "There are no ".concat(exportMetric.label, "s in this audience that are shared by every cluster.");
              }
            }

            // Setup header row
            var flatInterests = _.flatten(interestsMap);
            var hasLocationInterests = Ember.get(this, 'model.report.hasLocationInterests');
            var hasLocationAffinity = hasLocationInterests && flatInterests.some(function (n) {
              return Ember.get(n, 'locationAffinity.value');
            });
            var hasLocationRelevance = hasLocationInterests && flatInterests.some(function (n) {
              return Ember.get(n, 'locationRelevance');
            });
            var maxTagCount = _.max(flatInterests.mapBy('tags.length'));

            // If intersection because its only the aggregate cluster, we don't want to show the cluster name
            var headerRow = intersectionExport ? shouldHaveDelta ? "handle,name,relevance score,affinity score,followers,description,".concat(linkHeader, ",current rank,rank difference,relevance difference,affinity difference") : "handle,name,relevance score,affinity score,followers,description,".concat(linkHeader) : shouldHaveDelta ? "handle,name,relevance score,affinity score,followers,description,cluster,".concat(linkHeader, ",current rank,rank difference,relevance difference,affinity difference") : "handle,name,relevance score,affinity score,followers,description,cluster,".concat(linkHeader);
            if (hasLocationAffinity) {
              headerRow = "".concat(headerRow, ",location affinity");
            }
            if (hasLocationRelevance) {
              headerRow = "".concat(headerRow, ",location relevance");
            }
            for (var _i = 1; _i <= maxTagCount; _i++) {
              headerRow = "".concat(headerRow, ",tag").concat(_i);
            }
            rows.pushObject(headerRow);

            // Setup data
            clusters.forEach(function (cluster) {
              var interests = Ember.get(cluster, "".concat(Ember.get(metric, 'label'), ".response"));
              // if we have an intersection, filter interests
              if (intersection && intersection.length) {
                interests = interests.filter(function (n) {
                  return intersection.includes(n.screenName);
                });
              }

              // If we don't have all tags selected, filter interests
              if (Ember.get(_this2, "model.cluster.".concat(tagsKey, ".length")) && !Ember.get(_this2, "model.cluster.".concat(selectedKey))) {
                var selectedTags = (0, _getWithDefault.default)(_this2, "model.cluster.".concat(tagsKey), []).filterBy('selected').mapBy('title');
                interests = interests.filter(function (i) {
                  return i.tags.any(function (tag) {
                    return selectedTags.indexOf(tag) > -1;
                  });
                });
              }
              // Get data into csv format
              interests.forEach(function (d) {
                var linkName = Ember.get(d, 'screenName');
                if (Ember.get(_this2, 'model.report.socialNetwork') === 'Reddit' || Ember.get(_this2, 'model.report.socialNetwork') === 'LinkedIn') {
                  linkName = Ember.get(d, 'name');
                }
                var link = "".concat(linkPrefix).concat(linkName);
                var row = "".concat((0, _getWithDefault.default)(d, 'screenName', '').replace(_regex.commaRegex, ' '), ",").concat((0, _getWithDefault.default)(d, 'name', '').replace(_regex.doubleQuoteRegex, '\'').replace(_regex.lineBreakRegex, ' ').replace(_regex.commaRegex, ' ').replace(_regex.semicolonRegex, ''), ",").concat((0, _getWithDefault.default)(d, 'relevance', 0).toFixed(1), ",").concat(Ember.get(d, 'affinity.isInfinite') ? 'Infinity' : (0, _getWithDefault.default)(d, 'affinity.value', 0).toFixed(1), ",").concat(Ember.get(d, 'followersCount'), ",\"").concat((0, _getWithDefault.default)(d, 'description', '').replace(_regex.doubleQuoteRegex, '\'').replace(_regex.lineBreakRegex, ' ').replace(_regex.commaRegex, ' ').replace(_regex.semicolonRegex, ''), "\"").concat(intersectionExport ? '' : ",".concat(Ember.get(cluster, 'name').replace(_regex.doubleQuoteRegex, '\'').replace(_regex.lineBreakRegex, ' ').replace(_regex.commaRegex, ' ')), ",").concat(link);
                if (shouldHaveDelta) {
                  row = Ember.get(d, 'previousSnapshot.isNew') ? "".concat(row, ",").concat(Ember.get(d, 'rank'), ",,,") : "".concat(row, ",").concat(Ember.get(d, 'rank'), ",").concat(-(Ember.get(d, 'rank') - (0, _getWithDefault.default)(d, 'previousSnapshot.previousRank', Ember.get(d, 'rank'))), ",").concat((0, _getWithDefault.default)(d, 'relevance', 0) - (0, _getWithDefault.default)(d, 'previousSnapshot.previousRelevance', (0, _getWithDefault.default)(d, 'relevance', 0)), ",").concat(Ember.get(d, 'affinity.isInfinite') ? 'Infinity' : (0, _getWithDefault.default)(d, 'affinity.value', 0).toFixed(1) - (0, _getWithDefault.default)(d, 'previousSnapshot.previousAffinity.value', (0, _getWithDefault.default)(d, 'affinity.value', 0).toFixed(1)));
                }
                if (hasLocationAffinity) {
                  row = "".concat(row, ",").concat(Ember.get(d, 'locationAffinity.isInfinite') ? 'Infinity' : (0, _getWithDefault.default)(d, 'locationAffinity.value', 0).toFixed(1));
                }
                if (hasLocationRelevance) {
                  row = "".concat(row, ",").concat((0, _getWithDefault.default)(d, 'locationRelevance', 0).toFixed(1));
                }
                for (var _i2 = 0; _i2 < maxTagCount; _i2++) {
                  row = "".concat(row, ",").concat((0, _getWithDefault.default)(d, "tags.".concat(_i2), '').replace(_regex.commaRegex, ' ').replace(_regex.doubleQuoteRegex, '\''));
                }
                rows.pushObject(row);
              });
            });
          } else {
            var _interests = Ember.get(this, "model.cluster.".concat(Ember.get(metric, 'label'), ".response"));

            // I tried to make this generic, but still quite specific to interests, sorry - JK May 2019
            // This is also duplicated from the base model uniqueInterests cp
            if (uniqExport) {
              if (Ember.get(this, 'model.report.recordsCollection.loaded')) {
                var recordsMap = Ember.get(this, 'model.report.recordsMap');
                var recordsMapKey = Ember.get(metric, 'label');
                var interestIdentifier = 'networkID';
                if (recordsMap && recordsMap.has(recordsMapKey)) {
                  /* eslint-disable */
                  // jsmap
                  var _interestsMap = recordsMap.get(recordsMapKey);
                  /* eslint-enable */
                  _interests = _interests.filter(function (int) {
                    if (_interestsMap.has(Ember.get(int, interestIdentifier))) {
                      /* eslint-disable */
                      // jsmap
                      return _interestsMap.get(Ember.get(int, interestIdentifier)).filter(function (r) {
                        return r.normalizedScore !== 0;
                      }).length === 1;
                      /* eslint-enable */
                    } else {
                      return false;
                    }
                  });
                }
              }
            }
            var _hasLocationInterests = Ember.get(this, 'model.report.hasLocationInterests');
            var _hasLocationAffinity = _hasLocationInterests && _interests.some(function (m) {
              return Ember.get(m, 'locationAffinity.value');
            });
            var _hasLocationRelevance = _hasLocationInterests && _interests.some(function (m) {
              return Ember.get(m, 'locationRelevance');
            });
            var _maxTagCount = _.max(_interests.mapBy('tags.length'));
            var _headerRow = shouldHaveDelta ? "handle,name,relevance score,affinity score,followers,description,".concat(linkHeader, ",current rank,rank difference,relevance difference, affinity difference") : "handle,name,relevance score,affinity score,followers,description,".concat(linkHeader);
            if (_hasLocationAffinity) {
              _headerRow = "".concat(_headerRow, ",location affinity");
            }
            if (_hasLocationRelevance) {
              _headerRow = "".concat(_headerRow, ",location relevance");
            }
            for (var _i3 = 1; _i3 <= _maxTagCount; _i3++) {
              _headerRow = "".concat(_headerRow, ",tag").concat(_i3);
            }
            rows.pushObject(_headerRow);

            // If we don't have all tags selected, filter interests
            if (Ember.get(this, "model.cluster.".concat(tagsKey, ".length")) && !Ember.get(this, "model.cluster.".concat(selectedKey))) {
              var selectedTags = (0, _getWithDefault.default)(this, "model.cluster.".concat(tagsKey), []).filterBy('selected').mapBy('title');
              _interests = _interests.filter(function (i) {
                return i.tags.any(function (tag) {
                  return selectedTags.indexOf(tag) > -1;
                });
              });
            }

            // Get data into csv format
            _interests.forEach(function (d) {
              var linkName = Ember.get(d, 'screenName');
              if (Ember.get(_this2, 'model.report.socialNetwork') === 'Reddit' || Ember.get(_this2, 'model.report.socialNetwork') === 'LinkedIn') {
                linkName = Ember.get(d, 'name');
              }
              var link = "".concat(linkPrefix).concat(linkName);
              var row = "".concat((0, _getWithDefault.default)(d, 'screenName', '').replace(_regex.commaRegex, ' '), ",").concat((0, _getWithDefault.default)(d, 'name', '').replace(_regex.doubleQuoteRegex, '\'').replace(_regex.lineBreakRegex, ' ').replace(_regex.commaRegex, ' ').replace(_regex.semicolonRegex, ''), ",").concat((0, _getWithDefault.default)(d, 'relevance', 0).toFixed(1), ",").concat(Ember.get(d, 'affinity.isInfinite') ? 'Infinity' : (0, _getWithDefault.default)(d, 'affinity.value', 0).toFixed(1), ",").concat(Ember.get(d, 'followersCount'), ",\"").concat((0, _getWithDefault.default)(d, 'description', '').replace(_regex.doubleQuoteRegex, '\'').replace(_regex.lineBreakRegex, ' ').replace(_regex.commaRegex, ' ').replace(_regex.semicolonRegex, ''), "\",").concat(link);
              if (shouldHaveDelta) {
                row = Ember.get(d, 'previousSnapshot.isNew') ? "".concat(row, ",").concat(Ember.get(d, 'rank'), ",,,") : "".concat(row, ",").concat(Ember.get(d, 'rank'), ",").concat(-(Ember.get(d, 'rank') - (0, _getWithDefault.default)(d, 'previousSnapshot.previousRank', Ember.get(d, 'rank'))), ",").concat((0, _getWithDefault.default)(d, 'relevance', 0) - (0, _getWithDefault.default)(d, 'previousSnapshot.previousRelevance', (0, _getWithDefault.default)(d, 'relevance', 0)), ",").concat(Ember.get(d, 'affinity.isInfinite') ? 'Infinity' : (0, _getWithDefault.default)(d, 'affinity.value', 0).toFixed(1) - (0, _getWithDefault.default)(d, 'previousSnapshot.previousAffinity.value', (0, _getWithDefault.default)(d, 'affinity.value', 0).toFixed(1)));
              }
              if (_hasLocationAffinity) {
                row = "".concat(row, ",").concat(Ember.get(d, 'locationAffinity.isInfinite') ? 'Infinity' : (0, _getWithDefault.default)(d, 'locationAffinity.value', 0).toFixed(1));
              }
              if (_hasLocationRelevance) {
                row = "".concat(row, ",").concat((0, _getWithDefault.default)(d, 'locationRelevance', 0).toFixed(1));
              }
              for (var _i4 = 0; _i4 < _maxTagCount; _i4++) {
                row = "".concat(row, ",").concat((0, _getWithDefault.default)(d, "tags.".concat(_i4), '').replace(_regex.commaRegex, ' ').replace(_regex.doubleQuoteRegex, '\''));
              }
              rows.pushObject(row);
            });
          }
          break;
        case 'topicExplorer':
          rows.pushObject('url,page title,labels');
          Ember.get(this, "model.report.".concat(Ember.get(metric, 'label'), ".response.nodes")).forEach(function (node) {
            return rows.pushObject("".concat(node.url, ",").concat((0, _getWithDefault.default)(node, 'meta.title', '').replace(_regex.nonWordNonWhitespaceRegex, ''), ",").concat(node.words.mapBy('label').join(' ')));
          });
          break;
        case 'rankedDelta':
          rows.pushObject('type,label,current rank,rank difference,current value,value difference');
          Ember.get(this, 'model.cluster.topRankedMetricDelta.response').forEach(function (d) {
            rows.pushObject("".concat(Ember.get(d, 'type'), ",").concat(Ember.get(d, 'label'), ",").concat(Ember.get(d, 'origin.rank'), ",").concat(Ember.get(d, 'origin.delta.rankDelta'), ",").concat(Ember.get(d, 'origin.value'), ",").concat(Ember.get(d, 'origin.delta.valueDelta')));
          });
          break;
        case 'clusterOverview':
          rows.pushObject('cluster name,members,density,interest affinity,engagement (posts per user per month),unique interest %');
          Ember.get(this, 'model.report.clusters').forEach(function (cluster) {
            return rows.pushObject("".concat(Ember.get(cluster, 'name'), ",").concat(Ember.get(cluster, 'numberOfMembers.response.value'), ",").concat(Ember.get(cluster, 'density.response.value'), ",").concat(Ember.get(cluster, 'interestAffinity.value'), ",").concat((0, _getWithDefault.default)(cluster, 'activity.response.mean', 0).toFixed(2), ",").concat(Ember.get(cluster, 'uniqueInterestsPercentage.value')));
          });
          break;
        case 'members':
          {
            // If I don't put the {} eslint gets mad about defining variables at case level, weird, - JK Nov 2017
            var _rowHeader2 = shouldHaveDelta ? 'handle,name,twitter ID,location,followers,following,tweets and retweets,url,bio,current rank,rank difference,value difference' : 'handle,name,twitter ID,location,followers,following,tweets and retweets,url,bio';
            if (isTwitter) {
              _rowHeader2 += ',Last Date Tweeted,Verified User,Most Liked Tweets,Most Retweeted Tweets';
            }
            _rowHeader2 += ',cluster name';
            rows.pushObject(_rowHeader2);
            var clusterData;
            var isAllCluster = Ember.get(this, 'model.cluster.suffix') === 'all';
            if (Ember.get(this, 'model.cluster.suffix') && isAllCluster) {
              clusterData = Ember.get(this, "model.cluster.".concat(Ember.get(metric, 'label'), ".response")).sortBy('followersCount').reverse();
            } else {
              clusterData = Ember.get(this, "model.cluster.".concat(Ember.get(metric, 'label'), ".response")).slice(0, 5000).sortBy('followersCount').reverse();
            }
            clusterData.forEach(function (d, i) {
              var row = "".concat(Ember.get(d, 'screenName'), ",").concat((0, _getWithDefault.default)(d, 'name', '').replace(_regex.doubleQuoteRegex, '\'').replace(_regex.commaRegex, ' ').replace(_regex.lineBreakRegex, ' ').replace(_regex.nonWordNonWhitespaceRegex, ''), ",").concat(Ember.get(d, 'id'), ",").concat((0, _getWithDefault.default)(d, 'location', '').replace(_regex.doubleQuoteRegex, '\'').replace(_regex.commaRegex, ' ').replace(_regex.lineBreakRegex, ' ').replace(_regex.nonWordNonWhitespaceRegex, ''), ",").concat(Ember.get(d, 'followersCount'), ",").concat(Ember.get(d, 'friendsCount'), ",").concat(Ember.get(d, 'statusesCount'), ",\"").concat((0, _getWithDefault.default)(d, 'url', '<a href="">').match(_regex.hrefRegex) ? (0, _getWithDefault.default)(d, 'url', '<a href="">').match(_regex.hrefRegex)[1] : (0, _getWithDefault.default)(d, 'url', ''), "\",\"").concat((0, _getWithDefault.default)(d, 'description', '').replace(_regex.doubleQuoteRegex, '\'').replace(_regex.commaRegex, ' ').replace(_regex.lineBreakRegex, ' '), "\"");
              if (shouldHaveDelta) {
                row = "".concat(row, ",").concat(i + 1, ",").concat(-Ember.get(d, 'delta.rankDelta'), ",").concat(Ember.get(d, 'delta.valueDelta'));
              }
              if (isTwitter) {
                var lastDate = Ember.get(d, 'lastPost.dateCreated');
                if (lastDate) {
                  lastDate = (0, _moment.default)(lastDate).format(_dateFormats.longYearDashSeparatedInverse);
                } else {
                  lastDate = '';
                }
                var isUserVerified = Ember.get(d, 'isVerified');
                var isUserMostLiked = Ember.get(d, 'isMostLiked');
                var isUserMostTweeted = Ember.get(d, 'isMostTweeted');
                if (isUserVerified) {
                  isUserVerified = 'Y';
                } else {
                  isUserVerified = '';
                }
                if (isUserMostLiked) {
                  isUserMostLiked = 'Y';
                } else {
                  isUserMostLiked = '';
                }
                if (isUserMostTweeted) {
                  isUserMostTweeted = 'Y';
                } else {
                  isUserMostTweeted = '';
                }
                row = "".concat(row, ",").concat(lastDate, ",").concat(isUserVerified, ",").concat(isUserMostLiked, ",").concat(isUserMostTweeted);
              }
              if (isAllCluster) {
                row += ",".concat(clusterJsonData[Ember.get(d, 'clusterID')]);
              } else {
                row += ",".concat(Ember.get(_this2, 'model.cluster.name'));
              }
              rows.pushObject(row);
            });
            break;
          }
        case 'breakdown':
          {
            var parseAdd = function parseAdd(m, n) {
              return parseInt(m) + parseInt(n);
            };
            if (compiledExport) {
              var _rowHeader3 = 'handle,share normalized,share standard,followers,cluster';
              rows.pushObject(_rowHeader3);
              clusters.forEach(function (cluster) {
                var breakdown = Ember.get(cluster, "".concat(Ember.get(metric, 'label')));
                var totalNormalized = _.reduce(Ember.get(breakdown, 'normalized').mapBy('value'), parseAdd, 0);
                var totalStandard = _.reduce(Ember.get(breakdown, 'standard').mapBy('value'), parseAdd, 0);
                for (var _i5 = 0; _i5 < Ember.get(breakdown, 'normalized.length'); _i5++) {
                  var handle = Ember.get(breakdown, "normalized.".concat(_i5, ".label"));
                  var normalizedShare = (Ember.get(breakdown, "normalized.".concat(_i5, ".value")) / totalNormalized * 100).toFixed(1);
                  var standardShare = (Ember.get(breakdown, "standard.".concat(_i5, ".value")) / totalStandard * 100).toFixed(1);
                  var followers = Ember.get(breakdown, "standard.".concat(_i5, ".value"));
                  var _clusterName = Ember.get(cluster, 'name');
                  rows.pushObject("".concat(handle, ",").concat(normalizedShare, ",").concat(standardShare, ",").concat(followers, ",").concat(_clusterName));
                }
              });
            } else {
              var _rowHeader4 = 'handle,share normalized,share standard,followers';
              rows.pushObject(_rowHeader4);
              var breakdown = Ember.get(this, "model.cluster.".concat(Ember.get(metric, 'label')));
              var totalNormalized = _.reduce(Ember.get(breakdown, 'normalized').mapBy('value'), parseAdd, 0);
              var totalStandard = _.reduce(Ember.get(breakdown, 'standard').mapBy('value'), parseAdd, 0);
              for (var _i6 = 0; _i6 < Ember.get(breakdown, 'normalized.length'); _i6++) {
                var handle = Ember.get(breakdown, "normalized.".concat(_i6, ".label"));
                var normalizedShare = (Ember.get(breakdown, "normalized.".concat(_i6, ".value")) / totalNormalized * 100).toFixed(1);
                var standardShare = (Ember.get(breakdown, "standard.".concat(_i6, ".value")) / totalStandard * 100).toFixed(1);
                var followers = Ember.get(breakdown, "standard.".concat(_i6, ".value"));
                rows.pushObject("".concat(handle, ",").concat(normalizedShare, ",").concat(standardShare, ",").concat(followers));
              }
            }
            break;
          }
        case 'benchmarks-all':
          if (compiledExport) {
            var allInterests = _.flatten(Ember.get(this, 'model.report.everyClusterBenchmarkGroups.all-members').mapBy('benchmark.members'));
            var _hasLocationInterests2 = Ember.get(this, 'model.report.hasLocationInterests');
            var _hasLocationAffinity2 = _hasLocationInterests2 && allInterests.some(function (n) {
              return Ember.get(n, 'locationAffinity.value');
            });
            var _hasLocationRelevance2 = _hasLocationInterests2 && allInterests.some(function (n) {
              return Ember.get(n, 'locationRelevance');
            });
            var _maxTagCount2 = _.max(allInterests.mapBy('tags.length'));
            var _headerRow2 = shouldHaveDelta ? 'cluster,group,handle,name,relevance score,affinity score,followers,description,current rank,rank difference,relevance difference,affinity difference' : 'cluster,group,handle,name,relevance score,affinity score,followers,description';
            if (_hasLocationAffinity2) {
              _headerRow2 = "".concat(_headerRow2, ",location affinity");
            }
            if (_hasLocationRelevance2) {
              _headerRow2 = "".concat(_headerRow2, ",location relevance");
            }
            for (var _i7 = 1; _i7 <= _maxTagCount2; _i7++) {
              _headerRow2 = "".concat(_headerRow2, ",tag").concat(_i7);
            }
            rows.pushObject(_headerRow2);

            // Get data into csv format
            keys(Ember.get(this, 'model.report.everyClusterBenchmarkGroups')).without('all-members').without('all-groups').forEach(function (benchmarkGroupId) {
              Ember.get(_this2, "model.report.everyClusterBenchmarkGroups.".concat(benchmarkGroupId)).forEach(function (clusterBenchmarkRecord) {
                var benchmarkGroupName = (0, _getWithDefault.default)(clusterBenchmarkRecord, 'benchmark.name', 'none');
                var clusterName = Ember.get(clusterBenchmarkRecord, 'id') === Ember.get(_this2, 'model.report.clusterAggregation.id') ? "Report: ".concat(Ember.get(_this2, 'model.report.name')) : Ember.get(Ember.get(_this2, 'model.report.clusters').findBy('id', Ember.get(clusterBenchmarkRecord, 'id')), 'name');
                clusterName = clusterName.replace(_regex.doubleQuoteRegex, '\'').replace(_regex.lineBreakRegex, ' ').replace(_regex.commaRegex, ' ');
                Ember.get(clusterBenchmarkRecord, 'benchmark.members').forEach(function (d) {
                  // this is very similar to interest csv with some tweaks
                  var row = "".concat(clusterName, ",").concat(benchmarkGroupName, ",").concat((0, _getWithDefault.default)(d, 'screenName', '').replace(_regex.commaRegex, ' '), ",").concat((0, _getWithDefault.default)(d, 'name', '').replace(_regex.doubleQuoteRegex, '\'').replace(_regex.lineBreakRegex, ' ').replace(_regex.commaRegex, ' ').replace(_regex.semicolonRegex, ''), ",").concat((0, _getWithDefault.default)(d, 'relevance', 0).toFixed(1), ",").concat(Ember.get(d, 'affinity.isInfinite') ? 'Infinity' : (0, _getWithDefault.default)(d, 'affinity.value', 0).toFixed(1), ",").concat(Ember.get(d, 'followersCount'), ",\"").concat((0, _getWithDefault.default)(d, 'description', '').replace(_regex.doubleQuoteRegex, '\'').replace(_regex.lineBreakRegex, ' ').replace(_regex.commaRegex, ' ').replace(_regex.semicolonRegex, ''), "\"");
                  if (shouldHaveDelta) {
                    row = Ember.get(d, 'previousSnapshot.isNew') ? "".concat(row, ",").concat(Ember.get(d, 'rank'), ",,,") : "".concat(row, ",").concat(Ember.get(d, 'rank'), ",").concat(-(Ember.get(d, 'rank') - (0, _getWithDefault.default)(d, 'previousSnapshot.previousRank', Ember.get(d, 'rank'))), ",").concat((0, _getWithDefault.default)(d, 'relevance', 0) - (0, _getWithDefault.default)(d, 'previousSnapshot.previousRelevance', (0, _getWithDefault.default)(d, 'relevance', 0)), ",").concat(Ember.get(d, 'affinity.isInfinite') ? 'Infinity' : (0, _getWithDefault.default)(d, 'affinity.value', 0).toFixed(1) - (0, _getWithDefault.default)(d, 'previousSnapshot.previousAffinity.value', (0, _getWithDefault.default)(d, 'affinity.value', 0).toFixed(1)));
                  }
                  if (_hasLocationAffinity2) {
                    row = "".concat(row, ",").concat(Ember.get(d, 'locationAffinity.isInfinite') ? 'Infinity' : (0, _getWithDefault.default)(d, 'locationAffinity.value', 0).toFixed(1));
                  }
                  if (_hasLocationRelevance2) {
                    row = "".concat(row, ",").concat((0, _getWithDefault.default)(d, 'locationRelevance', 0).toFixed(1));
                  }
                  for (var _i8 = 0; _i8 < _maxTagCount2; _i8++) {
                    row = "".concat(row, ",").concat((0, _getWithDefault.default)(d, "tags.".concat(_i8), '').replace(_regex.commaRegex, ' ').replace(_regex.doubleQuoteRegex, '\''));
                  }
                  rows.pushObject(row);
                });
              });
            });
          } else {
            var _interests2 = Ember.get(Ember.get(this, 'model.report.everyClusterBenchmarkGroups.all-members').findBy('id', Ember.get(this, 'model.cluster.id')), 'benchmark.members');
            var _hasLocationInterests3 = Ember.get(this, 'model.report.hasLocationInterests');
            var _hasLocationAffinity3 = _hasLocationInterests3 && _interests2.some(function (n) {
              return Ember.get(n, 'locationAffinity.value');
            });
            var _hasLocationRelevance3 = _hasLocationInterests3 && _interests2.some(function (n) {
              return Ember.get(n, 'locationRelevance');
            });
            var _maxTagCount3 = _.max(_interests2.mapBy('tags.length'));
            var _headerRow3 = shouldHaveDelta ? 'handle,name,relevance score,affinity score,followers,description,current rank,rank difference,relevance difference,affinity difference' : 'handle,name,relevance score,affinity score,followers,description';
            if (_hasLocationAffinity3) {
              _headerRow3 = "".concat(_headerRow3, ",location affinity");
            }
            if (_hasLocationRelevance3) {
              _headerRow3 = "".concat(_headerRow3, ",location relevance");
            }
            for (var _i9 = 1; _i9 <= _maxTagCount3; _i9++) {
              _headerRow3 = "".concat(_headerRow3, ",tag").concat(_i9);
            }
            rows.pushObject(_headerRow3);

            // Get data into csv format
            keys(Ember.get(this, 'model.report.everyClusterBenchmarkGroups')).without('all-members').without('all-groups').forEach(function (benchmarkGroupId) {
              Ember.get(_this2, "model.report.everyClusterBenchmarkGroups.".concat(benchmarkGroupId)).filterBy('id', Ember.get(_this2, 'model.cluster.id')).forEach(function (clusterBenchmarkRecord) {
                Ember.get(clusterBenchmarkRecord, 'benchmark.members').forEach(function (d) {
                  // this is very similar to interest csv with some tweaks
                  var row = "".concat((0, _getWithDefault.default)(d, 'screenName', '').replace(_regex.commaRegex, ' '), ",").concat((0, _getWithDefault.default)(d, 'name', '').replace(_regex.doubleQuoteRegex, '\'').replace(_regex.lineBreakRegex, ' ').replace(_regex.commaRegex, ' ').replace(_regex.semicolonRegex, ''), ",").concat((0, _getWithDefault.default)(d, 'relevance', 0).toFixed(1), ",").concat(Ember.get(d, 'affinity.isInfinite') ? 'Infinity' : (0, _getWithDefault.default)(d, 'affinity.value', 0).toFixed(1), ",").concat(Ember.get(d, 'followersCount'), ",\"").concat((0, _getWithDefault.default)(d, 'description', '').replace(_regex.doubleQuoteRegex, '\'').replace(_regex.lineBreakRegex, ' ').replace(_regex.commaRegex, ' ').replace(_regex.semicolonRegex, ''), "\"");
                  if (shouldHaveDelta) {
                    row = Ember.get(d, 'previousSnapshot.isNew') ? "".concat(row, ",").concat(Ember.get(d, 'rank'), ",,,") : "".concat(row, ",").concat(Ember.get(d, 'rank'), ",").concat(-(Ember.get(d, 'rank') - (0, _getWithDefault.default)(d, 'previousSnapshot.previousRank', Ember.get(d, 'rank'))), ",").concat((0, _getWithDefault.default)(d, 'relevance', 0) - (0, _getWithDefault.default)(d, 'previousSnapshot.previousRelevance', (0, _getWithDefault.default)(d, 'relevance', 0)), ",").concat(Ember.get(d, 'affinity.isInfinite') ? 'Infinity' : (0, _getWithDefault.default)(d, 'affinity.value', 0).toFixed(1) - (0, _getWithDefault.default)(d, 'previousSnapshot.previousAffinity.value', (0, _getWithDefault.default)(d, 'affinity.value', 0).toFixed(1)));
                  }
                  if (_hasLocationAffinity3) {
                    row = "".concat(row, ",").concat(Ember.get(d, 'locationAffinity.isInfinite') ? 'Infinity' : (0, _getWithDefault.default)(d, 'locationAffinity.value', 0).toFixed(1));
                  }
                  if (_hasLocationRelevance3) {
                    row = "".concat(row, ",").concat((0, _getWithDefault.default)(d, 'locationRelevance', 0).toFixed(1));
                  }
                  for (var _i10 = 0; _i10 < _maxTagCount3; _i10++) {
                    row = "".concat(row, ",").concat((0, _getWithDefault.default)(d, "tags.".concat(_i10), '').replace(_regex.commaRegex, ' ').replace(_regex.doubleQuoteRegex, '\''));
                  }
                  rows.pushObject(row);
                });
              });
            });
          }
          break;
        case 'benchmarks-all-group':
          if (compiledExport) {
            rows.pushObject('cluster,group,average affinity,average relevance,average location affinity,average location relevance');
            // Get data into csv format
            Ember.get(this, 'model.report.everyClusterBenchmarkGroups.all-groups').forEach(function (clusterBenchmarkRecord) {
              var clusterName = Ember.get(clusterBenchmarkRecord, 'id') === Ember.get(_this2, 'model.report.clusterAggregation.id') ? "Report: ".concat(Ember.get(_this2, 'model.report.name')) : Ember.get(Ember.get(_this2, 'model.report.clusters').findBy('id', Ember.get(clusterBenchmarkRecord, 'id')), 'name');
              clusterName = clusterName.replace(_regex.doubleQuoteRegex, '\'').replace(_regex.lineBreakRegex, ' ').replace(_regex.commaRegex, ' ');
              var row = "".concat(clusterName, ",All Groups,").concat(Ember.get(clusterBenchmarkRecord, 'averageAffinity'), ",").concat(Ember.get(clusterBenchmarkRecord, 'averageRelevance'), ",").concat(Ember.get(clusterBenchmarkRecord, 'averageLocationAffinity'), ",").concat(Ember.get(clusterBenchmarkRecord, 'averageLocationRelevance'));
              rows.pushObject(row);
              Ember.get(clusterBenchmarkRecord, 'benchmark.members').forEach(function (group) {
                var benchmarkGroupName = group.name;
                var row = "".concat(clusterName, ",").concat(benchmarkGroupName, ",").concat(Ember.get(group, 'affinity.value'), ",").concat(Ember.get(group, 'relevance'), ",").concat(Ember.get(group, 'locationAffinity.value'), ",").concat(Ember.get(group, 'locationRelevance'));
                rows.pushObject(row);
              });
            });
          } else {
            rows.pushObject('group,average affinity,average relevance,average location affinity,average location relevance');
            // Get data into csv format
            Ember.get(this, 'model.report.everyClusterBenchmarkGroups.all-groups').filterBy('id', Ember.get(this, 'model.cluster.id')).forEach(function (clusterBenchmarkRecord) {
              var row = "All Groups,".concat(Ember.get(clusterBenchmarkRecord, 'averageAffinity'), ",").concat(Ember.get(clusterBenchmarkRecord, 'averageRelevance'), ",").concat(Ember.get(clusterBenchmarkRecord, 'averageLocationAffinity'), ",").concat(Ember.get(clusterBenchmarkRecord, 'averageLocationRelevance'));
              rows.pushObject(row);
              Ember.get(clusterBenchmarkRecord, 'benchmark.members').forEach(function (group) {
                var benchmarkGroupName = group.name;
                var row = "".concat(benchmarkGroupName, ",").concat(Ember.get(group, 'affinity.value'), ",").concat(Ember.get(group, 'relevance'), ",").concat(Ember.get(group, 'locationAffinity.value'), ",").concat(Ember.get(group, 'locationRelevance'));
                rows.pushObject(row);
              });
            });
          }
          break;
        case 'clusterOverlap':
          {
            rows.pushObject(",".concat(clusters.mapBy('name').join(',')));
            var clusterOverlap = Ember.get(this, 'model.report.clusterOverlap');
            clusterOverlap.data.forEach(function (row, i) {
              rows.pushObject("".concat(Ember.get(clusters.objectAt(i), 'name'), ",").concat(row.map(function (n) {
                return n.toFixed(4);
              }).join(',')));
            });
            break;
          }
        case 'numeric':
          {
            var clusterColumn = ',Cluster';
            var _rowHeader5 = "Average,Min,Max,Standard Deviation".concat(clusterColumn);
            var hasCluster = true;
            if (!compiledExport) {
              _rowHeader5 = _rowHeader5.replace(clusterColumn, '');
              hasCluster = false;
              clusters = [Ember.get(this, 'model.cluster')];
            }
            rows.pushObject(_rowHeader5);
            clusters.forEach(function (cluster) {
              var response = Ember.get(cluster, "".concat(Ember.get(metric, 'label'), ".response"));
              rows.pushObject("".concat(response.value.toFixed(1), ",").concat(response.min.toFixed(1), ",").concat(response.max.toFixed(1), ",").concat(response.stddev.toFixed(1)).concat(hasCluster ? ",".concat(Ember.get(cluster, 'name')) : ''));
            });
            break;
          }
      }

      // Form into proper csv format with new lines
      Ember.get(csv, 'rows').forEach(function (row) {
        return csv.csvContent += "".concat(row, "\n");
      });
      return csv;
    },
    metricsForKeyByType: function metricsForKeyByType(key, type) {
      var metrics = [];
      switch (type) {
        case 'cluster':
          metrics.pushObject(Ember.get(this, "model.cluster.".concat(key)));
          break;
        case 'clusterAggregation':
          metrics.pushObject(Ember.get(this, "model.report.clusterAggregation.".concat(key)));
          break;
        case 'everyCluster':
          metrics.pushObjects(Ember.get(this, 'model.report.allClusters').mapBy(key));
          break;
        case 'report':
          metrics.pushObject(Ember.get(this, "model.report.".concat(key)));
          break;
      }
      return metrics;
    },
    actions: {
      // Fetch data, call generateCSV, download data to files
      exportData: function exportData() {
        var _this3 = this;
        // Gather data to fetch
        var selectedFilteredExportSchema = Ember.get(this, 'selectedFilteredExportSchema');
        Ember.set(this, 'exporting', true);

        // Metrics we need data for
        var metricsInstancesToExport = selectedFilteredExportSchema.map(function (n) {
          return n.metrics.map(function (metricLabel) {
            var type = Ember.get(_this3, 'model.cluster.isCluster') ? 'cluster' : 'clusterAggregation';
            var trackedMetricLabels = ['trackedHashtags', 'trackedMentions', 'trackedKeywords'];
            if (trackedMetricLabels.includes(metricLabel)) {
              if (n.aggregation === 'compiled') {
                type = 'everyCluster';
              }
              // TODO this may need to change if we get more tracked things that aren't the same as this
              metricLabel.replace('tracked', '').toLowerCase();
            } else {
              if (metricLabel === 'topicExplorer') {
                type = 'report';
              } else if (n.aggregation === 'compiled' || n.exportType === 'clusterOverview') {
                type = 'everyCluster';
              }
              return _this3.metricsForKeyByType(metricLabel, type);
            }
          });
        });
        metricsInstancesToExport = _.flatten(metricsInstancesToExport);
        // Woopra
        selectedFilteredExportSchema.forEach(function (metric) {
          var trackedExport = {
            dataRequested: metric.label,
            aggregation: metric.aggregation,
            intersection: metric.intersection,
            clusterLevel: Ember.get(_this3, 'model.cluster.isCluster')
          };
          if (['interest', 'tags'].includes(metric.exportType) && Ember.get(_this3, 'model.report.socialNetwork') === 'Twitter') {
            trackedExport.interestTags = Ember.get(_this3, 'model.cluster.interestTags').filterBy('selected').mapBy('title').join(', ');
          }
          Ember.get(_this3, 'tracker').track('Data Exported', trackedExport);
        });
        // Fetch data
        Ember.get(this, 'graph').loadMetrics(metricsInstancesToExport).then(function () {
          selectedFilteredExportSchema.forEach(function (exportMetric, i) {
            // Generate CSV
            var csv = _this3.generateCSV(exportMetric, metricsInstancesToExport);
            if (typeof csv === 'string') {
              // error
              Ember.get(_this3, 'modalManager').openModal('modals/modal-text', {
                title: 'CSV Export Error',
                message: csv
              });
            } else {
              // Download CSV
              var data = new Blob([Ember.get(csv, 'csvContent')], {
                type: 'text/csv'
              });
              var dataUrl = URL.createObjectURL(data);
              var fileName = "".concat(Ember.get(csv, 'name'), ".csv");
              (0, _affinio.downloadURL)(data, dataUrl, fileName, i);
            }
          });
          Ember.set(_this3, 'exporting', false);
        }).catch(function () {
          return Ember.set(_this3, 'exporting', false);
        });
      },
      toggleSelectionAggregation: function toggleSelectionAggregation(metric) {
        Ember.set(metric, 'intersection', false);
        Ember.set(metric, 'aggregation', Ember.get(metric, 'aggregation') === 'top' ? 'compiled' : 'top');
      },
      toggleSelectionIntersection: function toggleSelectionIntersection(metric) {
        if (Ember.get(metric, 'intersection')) {
          Ember.set(metric, 'intersection', false);
          Ember.set(metric, 'aggregation', 'top');
        } else {
          Ember.set(metric, 'intersection', true);
          Ember.set(metric, 'aggregation', null);
        }
      },
      toggleSelectionUniq: function toggleSelectionUniq(metric) {
        Ember.set(metric, 'uniq', Ember.get(metric, 'uniq') === 'uniq' ? 'all' : 'uniq');
      },
      selectAllInterestCategories: function selectAllInterestCategories() {
        Ember.get(this, 'model.cluster.interestTags').setEach('selected', Ember.get(this, 'model.cluster.interestTagsAllSelected') ? false : true);
      },
      selectAllLocationCategories: function selectAllLocationCategories() {
        Ember.get(this, 'model.cluster.locationInterestTags').setEach('selected', Ember.get(this, 'model.cluster.locationInterestTagsAllSelected') ? false : true);
      }
    }
  });
});