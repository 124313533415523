define("affinio/templates/components/report-builder/radio-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "UAeljULu",
    "block": "{\"symbols\":[\"input\",\"&default\"],\"statements\":[[14,2,[[22,0,[]]]],[0,\"\\n\"],[4,\"each\",[[23,[\"schemaInputs\"]]],null,{\"statements\":[[0,\"  \"],[7,\"label\"],[9],[0,\"\\n    \"],[1,[27,\"radio-button\",null,[[\"name\",\"groupValue\",\"value\"],[\"input.name\",[23,[\"groupValue\"]],[22,1,[\"value\"]]]]],false],[0,\"\\n    \"],[1,[22,1,[\"label\"]],false],[0,\" \\n  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/report-builder/radio-input.hbs"
    }
  });
});