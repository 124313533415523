define("affinio/models/clusters/twitter", ["exports", "ember-data", "affinio/models/clusters/-base", "affinio/utils/constants/countries", "affinio/mixins/models/engagements", "affinio/mixins/models/breakdown-overlap", "affinio/utils/url-parser", "affinio/utils/interests"], function (_exports, _emberData, _base, _countries, _engagements, _breakdownOverlap, _urlParser, _interests) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
  function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  var attr = _emberData.default.attr;
  var _default = _exports.default = _base.default.extend(_engagements.default, _breakdownOverlap.default, {
    // Plain attrs: todo: should be metric?
    interestSimilarity: attr('number'),
    lurkerHist: attr('number'),
    postsPerMonth: attr(),
    tweetDayHist: attr(),
    tweetHist: attr(),
    staticMemberRank: attr(),
    verifiedMembersPercentage: attr(),
    // TODO I really hate this, it makes other things hard
    // This should be API fixed
    interests: Ember.computed('_interests.loaded', function () {
      var store = Ember.get(this, 'store');
      var interests = Ember.get(this, '_interests.response');
      // map the tag hierarchy for child->parent connections
      // loop each parent, map children to parent
      var childParentMap = _interests.tagHierarchyMap.flatMap(function (parent) {
        if (parent.children) {
          return parent.children.map(function (child) {
            return {
              child: child.value,
              parent: parent.value,
              label: child.label
            };
          });
        } else {
          return null;
        }
      }).compact();

      // group tags by label
      var labelGroups = childParentMap.reduce(function (groups, tag) {
        return _objectSpread(_objectSpread({}, groups), {}, _defineProperty({}, tag.label, [].concat(_toConsumableArray(groups[tag.label] || []), [tag])));
      }, {});

      // make a map similar to child->parent map
      var dupeMapping = Object.entries(labelGroups).flatMap(function (group) {
        var mainTag = group[1][0].child;
        return group[1].map(function (link) {
          return mainTag !== link.child ? {
            mainTag: mainTag,
            dupeTag: link.child
          } : null;
        }, 1);
      }).compact();
      interests = interests.map(function (n) {
        if (n.tags && !n.tags.length) {
          n.tags.pushObject('uncategorized');
        } else {
          // de-dupe tags based on labels
          var dupeLabelTags = []; // tags to remove
          var newTags = []; // tags to replace with
          // add missing tag parents
          var missingParents = [];
          n.tags.forEach(function (tag) {
            // child->parent mapping
            var parentLink = childParentMap.find(function (l) {
              return l.child === tag.toLowerCase();
            });
            if (parentLink && !missingParents.includes(parentLink.parent) && !n.tags.includes(parentLink.parent)) {
              missingParents.push(parentLink.parent);
            }
            var dupeLink = dupeMapping.find(function (d) {
              return d.dupeTag === tag.toLowerCase();
            });
            if (dupeLink) {
              if (!newTags.includes(dupeLink.mainTag) && !n.tags.includes(dupeLink.mainTag)) {
                newTags.push(dupeLink.mainTag);
              }
              if (!dupeLabelTags.includes(tag)) {
                dupeLabelTags.push(tag);
              }
            }
          });
          n.tags.pushObjects(missingParents);
          n.tags.pushObjects(newTags);
          n.tags = n.tags.reject(function (tag) {
            return dupeLabelTags.includes(tag);
          });
          // lowercase everything
          n.tags = n.tags.map(function (t) {
            return t.toLowerCase();
          });
        }
        if (n.previousSnapshot && n.previousSnapshot.previousRank) {
          n.rankChange = n.previousSnapshot.previousRank - n.rank;
        }
        return n;
      });
      if (interests && Ember.isArray(interests)) {
        return Ember.get(this, '_interests');
      } else {
        return store.createRecord('metric', {
          label: 'interests',
          response: [],
          loaded: Ember.get(this, '_interests.loaded'),
          loading: Ember.get(this, '_interests.loading'),
          network: Ember.get(this, '_interests.network'),
          parentId: Ember.get(this, '_interests.parentId'),
          metricLevel: Ember.get(this, '_interests.metricLevel'),
          properties: Ember.get(this, '_interests.properties'),
          key: Ember.get(this, '_interests.key')
        });
      }
    }),
    locationInterests: Ember.computed('_locationInterests.loaded', function () {
      var interests = Ember.get(this, '_locationInterests.response');
      if (interests && Ember.isArray(interests)) {
        return {
          label: 'locationInterests',
          response: interests.map(function (n) {
            if (n.tags && !n.tags.length) {
              n.tags.pushObject('Uncategorized');
            }
            if (n.previousSnapshot && n.previousSnapshot.previousRank) {
              n.rankChange = n.previousSnapshot.previousRank - n.rank;
            }
            return n;
          })
        };
      } else {
        return {
          label: 'locationInterests',
          response: [],
          loaded: Ember.get(this, '_locationInterests.loaded'),
          loading: Ember.get(this, '_locationInterests.loading'),
          network: Ember.get(this, '_locationInterests.network'),
          parentId: Ember.get(this, '_locationInterests.parentId'),
          metricLevel: Ember.get(this, '_locationInterests.metricLevel'),
          properties: Ember.get(this, '_locationInterests.properties'),
          key: Ember.get(this, '_locationInterests.key')
        };
      }
    }),
    benchmarkGroups: Ember.computed('_benchmarkGroups.response', 'report.storedBenchmarkGroups.loaded', function () {
      var benchmarkGroups = Ember.get(this, '_benchmarkGroups.response');
      var storedBenchmarkGroups = Ember.get(this, 'report.storedBenchmarkGroups.response');
      // Add benchmarks in that didn't score TODO remove this when this happens during report generation
      if (benchmarkGroups) {
        storedBenchmarkGroups.forEach(function (storedGroup) {
          var benchmarkGroup = benchmarkGroups.findBy('name', storedGroup.name);
          if (!benchmarkGroup) {
            benchmarkGroup = {
              id: storedGroup.name,
              members: [],
              name: storedGroup.name
            };
            benchmarkGroups.push(benchmarkGroup);
          }
          var benchmarkMembers = benchmarkGroup.members.mapBy('screenName').map(function (mem) {
            return mem.toLowerCase();
          });
          storedGroup.members.forEach(function (member) {
            if (!benchmarkMembers.includes(member.toLowerCase())) {
              var dummyData = {
                affinity: {
                  value: 0.0,
                  isInfinite: false
                },
                bannerImage: null,
                color: null,
                description: 'This tracked interest did not score.',
                followersCount: 0,
                friendsCount: 0,
                headerImage: null,
                iconImage: null,
                label: member,
                lastPost: null,
                locationAffinity: null,
                locationRelevance: null,
                name: member,
                networkID: null,
                previousSnapshot: null,
                rank: null,
                relevance: 0.0,
                score: 0.0,
                screenName: member,
                tags: [],
                verified: false
              };
              benchmarkGroup.members.push(dummyData);
            }
          });
        });
      }
      return Ember.get(this, '_benchmarkGroups');
    }),
    // Computed properties

    // TODO not that big a deal but I dont like that this data has a different format than all the other media arrays.
    // The different format is handled in the media-gallery component with `hasGroupedMedia` computed prop. - js
    internalLinks: Ember.computed('report.sharedURLs', 'links.loaded', function () {
      var key;
      var visitedSites = Ember.get(this, 'report.sharedURLs');
      var modelRecords = Ember.get(this, 'links.response');
      modelRecords = modelRecords.filter(function (obj) {
        return obj.embedType === 'screenshot';
      });
      var theURLs = modelRecords.filter(function (url) {
        return _.some(visitedSites.filter(function (site) {
          return (0, _urlParser.matchUrl)(url, site);
        })); // are _.some of the visitedSites contained in this link?
      });
      var grouped = _.groupBy(theURLs, function (url /* ,iter*/) {
        return visitedSites.filter(function (site) {
          key = site;
          return (0, _urlParser.matchUrl)(url, site);
        });
      }, key);
      var ret = Ember.A();
      Object.keys(grouped).forEach(function (key /* ,val*/) {
        ret.pushObject({
          visitedSite: key,
          urls: grouped[key]
        });
      }); // object.keys
      ret = _.sortBy(ret, function (obj) {
        return obj.urls.length;
      }).reverse();
      return ret;
    }),
    // links with the sharedURLs filtered out
    externalLinks: Ember.computed.filter('links.response', function (link) {
      return !_.some(Ember.get(this, 'report.sharedURLs').filter(function (obj) {
        return (0, _urlParser.matchUrl)(link, obj);
      })); // are !_.some of the visitedSites contained in this link?
    }),
    // links filtered for `mediaType` === `video` only
    videos: Ember.computed('links.loaded', function () {
      return Ember.get(this, 'links.response').filterBy('mediaType', 'video');
    }),
    // used for export
    interestTags: Ember.computed('interests.loaded', function () {
      var _ref;
      var interests = Ember.get(this, 'interests.response');
      var tags = (_ref = []).concat.apply(_ref, _toConsumableArray(interests.mapBy('tags'))).uniq().filter(function (tag) {
        return !_countries.countryBindings.findBy('value', tag) && tag !== 'GLOBAL';
      }).map(function (tag) {
        return Ember.Object.create({
          title: tag,
          tally: interests.filter(function (i) {
            return i.tags.indexOf(tag) > -1;
          }).length,
          selected: true
        });
      }).sortBy('tally').reverse();
      return tags;
    }),
    locationInterestTags: Ember.computed('_locationInterests.loaded', function () {
      var _ref2;
      var interests = Ember.get(this, 'locationInterests.response');
      var tags = (_ref2 = []).concat.apply(_ref2, _toConsumableArray(interests.mapBy('tags'))).uniq().filter(function (tag) {
        return !_countries.countryBindings.findBy('value', tag) && tag !== 'GLOBAL';
      }).map(function (tag) {
        return Ember.Object.create({
          title: tag,
          tally: interests.filter(function (i) {
            return i.tags.indexOf(tag) > -1;
          }).length,
          selected: true
        });
      }).sortBy('tally').reverse();
      return tags;
    }),
    locationInterestTagsAllSelected: Ember.computed('locationInterestTags.@each.selected', function () {
      return Ember.get(this, 'locationInterestTags').every(function (inf) {
        return inf.selected;
      });
    }),
    interestTagsAllSelected: Ember.computed('interestTags.@each.selected', function () {
      return Ember.get(this, 'interestTags').every(function (inf) {
        return inf.selected;
      });
    }),
    // the last tweet from every interest (used in Stream page)
    interestTweets: Ember.computed('interests.loaded', function () {
      return Ember.get(this, 'interests.response').slice(0, 50).map(function (interest) {
        if (interest.lastPost && interest.lastPost.id) {
          return {
            id: interest.lastPost.id,
            dateCreated: interest.lastPost.dateCreated
          };
        }
      }).sortBy('dateCreated').reverse().compact();
    }),
    trackedHashtags: Ember.computed('report.{filters,baselineReport}', 'hashtags.loaded', function () {
      if (Ember.get(this, 'report.filters.explicitWords') && Ember.get(this, 'hashtags.loaded')) {
        var explicitHashtags = Ember.get(this, 'report.filters.explicitWords').filterBy('type', 'hashtag').map(function (m) {
          return Ember.get(m, 'term').replace('#', '').toLowerCase();
        });
        var hashtags = Ember.get(this, 'hashtags.response');
        var trackedHashtags = explicitHashtags.map(function (kw) {
          var foundKw = hashtags.findBy('label', kw);
          return foundKw || {
            label: kw,
            value: 0
          };
        });
        return {
          cluster: this,
          baselineEstablished: Ember.get(this, 'report.baselineReport') ? true : false,
          label: 'trackedHashtags',
          loaded: true,
          response: trackedHashtags // TODO why isn't this sorted like the rest?
        };
      }
    }),
    trackedMentions: Ember.computed('report.{filters,baselineReport}', 'mentions.loaded', function () {
      if (Ember.get(this, 'report.filters.explicitWords') && Ember.get(this, 'mentions.loaded')) {
        var explicitMentions = Ember.get(this, 'report.filters.explicitWords').filterBy('type', 'mention').map(function (m) {
          return Ember.get(m, 'term').replace('@', '').toLowerCase();
        });
        var mentions = Ember.get(this, 'mentions.response');
        var trackedMentions = explicitMentions.map(function (kw) {
          var foundKw = mentions.findBy('label', kw);
          return foundKw || {
            label: kw,
            value: 0
          };
        });
        return {
          cluster: this,
          baselineEstablished: Ember.get(this, 'report.baselineReport') ? true : false,
          label: 'trackedMentions',
          loaded: true,
          response: trackedMentions.sortBy('value').reverse()
        };
      }
    }),
    trackedKeywords: Ember.computed('report.{filters,baselineReport}', 'keywords.loaded', function () {
      if (Ember.get(this, 'report.filters.explicitWords') && Ember.get(this, 'keywords.loaded')) {
        var explicitKeywords = Ember.get(this, 'report.filters.explicitWords').filterBy('type', 'keyword').map(function (m) {
          return Ember.get(m, 'term').toLowerCase();
        });
        var keywords = Ember.get(this, 'keywords.response');
        var trackedKeywords = explicitKeywords.map(function (kw) {
          var foundKw = keywords.findBy('label', kw);
          return foundKw || {
            label: kw,
            value: 0
          };
        });
        return {
          cluster: this,
          baselineEstablished: Ember.get(this, 'report.baselineReport') ? true : false,
          label: 'trackedKeywords',
          loaded: true,
          response: trackedKeywords.sortBy('value').reverse()
        };
      }
    }),
    benchmarkHasData: Ember.computed('benchmarkGroups.{loaded,response.length}', function () {
      if (Ember.get(this, 'benchmarkGroups.loaded')) {
        return Ember.get(this, 'benchmarkGroups.response.length') ? true : false;
      }
      return false;
    }),
    verifiedMembersPercentageCBG: Ember.computed('verifiedMembersPercentage', function () {
      var description = 'This metric represents the percentage of members within each cluster who hold verified/badged status, as a way to measure authenticity and influence.';
      var verifiedMembersPercentage = Ember.get(this, 'verifiedMembersPercentage');
      return Ember.Object.create({
        description: description,
        label: '% verified members',
        value: verifiedMembersPercentage,
        specificity: 2
      });
    })
  });
});