define("affinio/components/multi-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Element
    classNames: ['multi-button'],
    open: false,
    actions: {
      callback: function callback(action) {
        action();
        Ember.set(this, 'open', false);
      }
    }
  });
});