define("affinio/utils/constants/metrics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.types = _exports.names = _exports.links = _exports.icons = _exports.default = void 0;
  var names = _exports.names = {
    'locations': 'Location',
    'interestCategories': 'Interest Category',
    'influencers': 'Skill',
    // TODO ?
    'tweetKeywords': 'Tweet Keyword',
    'bioKeywords': 'Bio Keyword',
    'mentions': '@Mention',
    'hashtags': 'Hashtag',
    'topTopics': 'Topic',
    'lifestyles': 'Lifestyle Category',
    'jobTitles': 'Job Title',
    'interfacesUsed': 'Interface Used',
    'ages': 'Age',
    'genders': 'Gender',
    'languages': 'Language',
    'householdIncome': 'Household Income',
    'homeOwnership': 'Home Ownership',
    'educationLevel': 'Education Level',
    'genres': 'Genre',
    'artists': 'Artist',
    'albums': 'Album',
    'tracks': 'Track',
    // pinterest
    'comments': 'Comments'
  };
  var types = _exports.types = {
    'countries': 'isCountries',
    'locations': 'isLocation',
    'interestCategories': 'isInterestCategory',
    'bioKeywords': 'isBioKw',
    'hashtags': 'isHashtag',
    'topTopics': 'isTopic',
    'lifestyles': 'isLifestyle',
    'jobTitles': 'isJobTitle',
    'educationLevel': 'isEducationLevel',
    'interfacesUsed': 'isInterfaceUsed',
    'interestCategoriesFb': 'isInterestCategoryFb',
    // pinterest
    'comments': 'isComments'
  };
  var icons = _exports.icons = {
    'locations': 'map-marker',
    'countries': 'map-marker',
    'interestCategories': 'star',
    'influencers': 'star',
    // TODO
    'tweetKeywords': 'commenting',
    'mentions': 'user',
    'bioKeywords': 'user',
    'hashtags': 'hashtag',
    'topTopics': 'newspaper-o',
    'ages': 'user',
    'genders': 'venus-mars',
    'languages': 'language',
    'householdIncome': 'dollar',
    'homeOwnership': 'home',
    'genres': 'music',
    'artists': 'music',
    'albums': 'music',
    'tracks': 'music',
    'jobCategories': 'briefcase',
    'jobSectors': 'briefcase',
    'degreeGroups': 'graduation-cap',
    'articleEntities': 'newspaper-o',
    'lifestyles': 'heart',
    'educationLevel': 'graduation-cap',
    'educationStatuses': 'university',
    'interfacesUsed': 'mobile',
    'interestCategoriesFb': 'star',
    'jobTitles': 'id-card-o',
    'careerProfiles': 'briefcase',
    'employerSize': 'building',
    'industryFollowers': 'binoculars',
    'followedIndustries': 'industry',
    'seniorities': 'certificate',
    // pinterest
    'comments': 'commenting'
  };
  var links = _exports.links = {
    'locations': {
      category: 'members',
      subcategory: 'locations'
    },
    'countries': {
      category: 'members',
      subcategory: 'locations'
    },
    'interestCategories': {
      category: 'interests',
      subcategory: 'index'
    },
    'bioKeywords': {
      category: 'members',
      subcategory: 'profiles'
    },
    'hashtags': {
      category: 'content',
      subcategory: 'topics'
    },
    'mentions': {
      category: 'content',
      subcategory: 'topics'
    },
    'topTopics': {
      category: 'content',
      subcategory: 'topics'
    },
    'ages': {
      category: 'members',
      subcategory: 'profiles'
    },
    'genders': {
      category: 'members',
      subcategory: 'profiles'
    },
    'languages': {
      category: 'members',
      subcategory: 'profiles'
    },
    'householdIncome': {
      category: 'members',
      subcategory: 'profiles'
    },
    'homeOwnership': {
      category: 'members',
      subcategory: 'profiles'
    },
    'genres': {
      category: 'members',
      subcategory: 'profiles'
    },
    'artists': {
      category: 'members',
      subcategory: 'profiles'
    },
    'albums': {
      category: 'members',
      subcategory: 'profiles'
    },
    'tracks': {
      category: 'members',
      subcategory: 'profiles'
    },
    'jobCategories': {
      category: 'members',
      subcategory: 'profiles'
    },
    'jobSectors': {
      category: 'members',
      subcategory: 'profiles'
    },
    'customSegments': {
      category: 'members',
      subcategory: 'profiles'
    },
    'degreeGroups': {
      category: 'members',
      subcategory: 'profiles'
    },
    'employerSize': {
      category: 'members',
      subcategory: 'profiles'
    },
    'articleEntities': {
      category: 'members',
      subcategory: 'profiles'
    },
    'careerProfiles': {
      category: 'members',
      subcategory: 'profiles'
    },
    'followedIndustries': {
      category: 'members',
      subcategory: 'profiles'
    },
    'industryFollowers': {
      category: 'members',
      subcategory: 'profiles'
    },
    'seniorities': {
      category: 'members',
      subcategory: 'profiles'
    },
    'lifestyles': {
      category: 'members',
      subcategory: 'profiles'
    },
    'jobTitles': {
      category: 'members',
      subcategory: 'profiles'
    },
    'educationLevel': {
      category: 'members',
      subcategory: 'profiles'
    },
    'educationStatuses': {
      category: 'members',
      subcategory: 'profiles'
    },
    'interfacesUsed': {
      category: 'members',
      subcategory: 'profiles'
    },
    'interestCategoriesFb': {
      category: 'interests',
      subcategory: 'index'
    },
    'influencers': {
      category: 'interests',
      subcategory: 'index'
    },
    'posts': {
      category: 'content',
      subcategory: 'posts'
    },
    'links': {
      category: 'content',
      subcategory: 'links'
    },
    'keywords': {
      category: 'content',
      subcategory: 'topics'
    },
    'domains': {
      category: 'content',
      subcategory: 'domains'
    },
    'comments': {
      category: 'content',
      subcategory: 'topics'
    }
  };
  var _default = _exports.default = {
    names: names,
    types: types,
    icons: icons,
    links: links
  };
});