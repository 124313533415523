define("affinio/components/ridge-lines", ["exports", "d3v5"], function (_exports, _d3v) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['ridge-lines'],
    path: Ember.inject.service(),
    structuredData: Ember.computed('data', function () {
      var _this = this;
      var data = Ember.get(this, 'data').sortBy('lower');
      var yScale = _d3v.default.scaleLinear().range([0, 100]).domain([0, _d3v.default.max(data.mapBy('value'))]);
      return data.map(function (datum) {
        var relativeHeight = yScale(Ember.get(datum, 'value'));
        return {
          relativeHeight: relativeHeight,
          value: Ember.get(datum, 'value'),
          style: Ember.String.htmlSafe("\n          height: ".concat(relativeHeight, "%;\n          border-color: ").concat(Ember.get(_this, 'color'), ";\n          background-color: ").concat(Ember.get(_this, 'color'), ";\n        "))
        };
      });
    }),
    axis: Ember.computed('data', function () {
      var clusters = Ember.get(this, 'data');
      var ticks = Ember.get(clusters.objectAt(0), 'bins').mapBy('lower');
      var x = _d3v.default.scaleLinear().domain([_d3v.default.min(ticks), _d3v.default.max(ticks)]).range([3.5, 95.5]);
      var xAxis = function xAxis(g) {
        return g.call(_d3v.default.axisBottom(x).tickSizeInner(2).tickValues(ticks));
      };
      var svg = _d3v.default.select(this.$()[0]).append('svg:svg').attr('preserveAspectRatio', 'none').attr('viewBox', '0 0 100 40').classed('axis', true);
      var axis = svg.append('g').attr('class', 'x-axis').call(xAxis);
      axis.selectAll('.tick')._groups[0].forEach(function (t) {
        var tick = _d3v.default.select(t);
        var format = _d3v.default.format(',.0f');
        var value = format(tick.data());
        tick.insert('title').html(value);
        if (value.length > 5) {
          var truncatedValue = "".concat(value.substring(0, 4), "...");
          tick.select('text').html(truncatedValue);
        }
      });
      return svg.node();
    }),
    chart: Ember.computed('data', 'path.currentClusterId', function () {
      var w = 100;
      var height = '100px';
      var width = '100%';
      var clusters = Ember.get(this, 'data');

      // bring current cluster to front
      for (var i in clusters) {
        if (clusters[i].cluster === Ember.get(this, 'path.currentClusterId')) {
          clusters.push(clusters.splice(i, 1)[0]);
        }
      }
      var areaColor = function areaColor(values, iter) {
        return Ember.get(clusters.objectAt(iter), 'color');
      };
      var opacity = 0.5;
      var data = clusters.mapBy('bins').map(function (x) {
        return x.mapBy('value');
      });
      var longest = Ember.get(_.max(data, 'length'), 'length');

      // Normalize the data within each cluster to be 0-1 bound
      data = data.map(function (cluster) {
        var clusterRange = _d3v.default.scaleLinear().domain([0, _d3v.default.max(cluster)]).range([0, 1]);
        cluster = cluster.map(function (point) {
          return clusterRange(point);
        });
        return cluster;
      });
      var margin = {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      };
      var area = _d3v.default.area().curve(_d3v.default.curveBasis).x(function (d, i) {
        return x(i);
      }).y0(0).y1(function (d) {
        return z(d);
      });
      var line = area.lineY1();
      var x = _d3v.default.scaleLinear().domain([0, longest - 1]).range([margin.left, w - margin.right]);
      var z = _d3v.default.scaleLinear().domain([0, _d3v.default.max(data, function (d) {
        return _d3v.default.max(d, function (i) {
          return i;
        });
      })]).nice().range([0, -100]);
      var svg = _d3v.default.select(this.$()[0]).append('svg:svg').attr('height', height).attr('preserveAspectRatio', 'none').attr('viewBox', '0 0 100 100').classed('ridges', true);
      var g = svg.append('g').attr('transform', 'translate(0,100) scale(1)');
      svg.append('rect').attr('fill', 'none').attr('pointer-events', 'all').attr('width', width).attr('height', height);
      var serie = g.append('g').selectAll('g').data(data).enter().append('g').attr('data-cluster', function (d, iter) {
        return Ember.get(clusters.objectAt(iter), 'cluster');
      });
      // .attr('transform', (d, i) => {
      //   return 'translate(0,0)'; // transform can be applied to move them vertically up; commenting out for later use on hover
      // });

      serie.append('path').attr('fill', areaColor).attr('opacity', opacity).attr('d', function (d) {
        // filledD expands a wave with zeroes to be as long an array as the longest sibling wave.
        // The absence of this will make hard vertical cutoffs on shorter waves. -PR
        var filledD = d.length < longest ? [].concat(_toConsumableArray(d), _toConsumableArray(_d3v.default.range(longest - d.length).map(function () {
          return 0;
        }))) : d;
        return area(filledD);
      });
      serie.append('path').attr('fill', 'none').attr('d', function (d) {
        return line(d);
      });
      return svg.node();
    }),
    // Lifecycle
    didReceiveAttrs: function didReceiveAttrs() {
      var _this2 = this;
      this._super.apply(this, arguments);
      if (Ember.get(this, 'data')) {
        Ember.run.scheduleOnce('afterRender', this, function () {
          var chart = Ember.get(_this2, 'chart');
          if (Ember.get(_this2, 'hoveredCluster')) {
            _d3v.default.select(chart).selectAll('g path').style('opacity', 0.1);
            _d3v.default.select(chart).select("g[data-cluster=\"".concat(Ember.get(_this2, 'path.currentClusterId'), "\"] path")).style('opacity', .3);
            _d3v.default.select(chart).select("g[data-cluster=\"".concat(Ember.get(_this2, 'hoveredCluster'), "\"] path")).style('opacity', 1);
          } else {
            _d3v.default.select(chart).selectAll('g path').style('opacity', 0.5);
            _d3v.default.select(chart).select("g[data-cluster=\"".concat(Ember.get(_this2, 'path.currentClusterId'), "\"] path")).style('opacity', .7);
          }
        });
      }
    }
  });
});