define("affinio/templates/components/report-builder/numeric-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "YknzbnZ5",
    "block": "{\"symbols\":[\"example\",\"&default\"],\"statements\":[[14,2,[[22,0,[]]]],[0,\"\\n\\n\"],[7,\"section\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"showAdvancedFilters\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"preferences/select-dropdown\",null,[[\"class\",\"options\",\"value\"],[\"business-type-options\",[23,[\"customNumericConditions\"]],[23,[\"condition\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[1,[27,\"input\",null,[[\"value\",\"placeholder\",\"enter\"],[[23,[\"filter\"]],[23,[\"moduleSchema\",\"placeholder\"]],[27,\"if\",[[23,[\"filter\"]],[27,\"action\",[[22,0,[]],\"addCustomFilter\"],null]],null]]]],false],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\"primary\"],[12,\"disabled\",[27,\"if\",[[23,[\"filter\"]],false,true],null]],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"addCustomFilter\"],null]],[9],[0,\"\\n    \"],[1,[27,\"or\",[[23,[\"moduleSchema\",\"addNomenclature\"]],\"Add\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"moduleSchema\",\"examples\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\"],[9],[0,\"Example \"],[1,[23,[\"moduleSchema\",\"label\"]],false],[0,\" Data Points:\"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"examples\"],[9],[0,\"\\n    \"],[7,\"ul\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"moduleSchema\",\"examples\"]]],null,{\"statements\":[[0,\"        \"],[7,\"li\"],[9],[0,\"\\n          • \"],[1,[22,1,[]],false],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/report-builder/numeric-input.hbs"
    }
  });
});