define("affinio/helpers/network-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.networkIcon = networkIcon;
  function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
  function networkIcon(_ref /* , hash*/) {
    var _ref2 = _slicedToArray(_ref, 2),
      network = _ref2[0],
      _ref2$ = _ref2[1],
      color = _ref2$ === void 0 ? '#000000' : _ref2$;
    network = network && network.toLowerCase();
    var icon;
    switch (network) {
      case 'facebook':
        icon = 'facebook-official';
        break;
      case 'twitter':
        icon = 'twitter';
        break;
      case 'instagram':
        icon = 'instagram';
        break;
      case 'pinterest':
        icon = 'pinterest';
        break;
      case 'youtube':
        icon = 'youtube-play';
        break;
      case 'recycle':
        icon = 'recycle';
        break;
      case 'first-party-tease':
      case 'first-party-selection':
        icon = 'database';
        break;
      default:
        icon = !network ? 'spinner fa-pulse' : null;
        break;
    }
    if (icon === 'twitter') {
      return Ember.String.htmlSafe("\n      <svg\n        class=\"x-twitter-icon\"\n        xmlns=\"http://www.w3.org/2000/svg\"\n        viewBox=\"0 0 512 512\"\n      >\n        <path d=\"M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z\" />\n      </svg>");
    } else if (icon) {
      return Ember.String.htmlSafe("<i class=\"fa fa-fw fa-".concat(icon, "\"></i>"));
    } else {
      return Ember.String.htmlSafe("<?xml version=\"1.0\" encoding=\"UTF-8\" standalone=\"no\"?>\n                      <svg\n                        xmlns:dc=\"http://purl.org/dc/elements/1.1/\"\n                        xmlns:cc=\"http://creativecommons.org/ns#\"\n                        xmlns:rdf=\"http://www.w3.org/1999/02/22-rdf-syntax-ns#\"\n                        xmlns:svg=\"http://www.w3.org/2000/svg\"\n                        xmlns=\"http://www.w3.org/2000/svg\"\n                        viewBox=\"0 0 800 800\"\n                        height=\"800\"\n                        width=\"800\"\n                        id=\"svg3767\"\n                        version=\"1.1\">\n                        <g\n                          style=\"display:inline\"\n                          id=\"layer1\">\n                          <path\n                            id=\"path3778\"\n                            d=\"m 566.01953,64.919922 -20.96484,5.410156 C 419.27278,214.59607 307.36224,350.85697 169.73828,499.07031 c -13.93474,5.46538 -43.99867,15.76952 -41.25195,16.23047 -3.55441,5.94973 3.9437,20.58663 -2.02735,35.83984 -21.64244,37.21906 -50.959295,68.2798 -74.388668,105.49415 13.658972,16.54975 43.886013,45.0041 66.272798,45.31007 58.53899,-64.8544 81.6454,-106.96966 123.75259,-152.83156 70.96637,-19.49929 152.6615,-41.42975 215.04641,-43.95666 -15.10284,41.34186 -29.5295,88.54565 -44.63234,136.60119 13.86325,66.34301 129.89943,72.79222 142.01171,40.57617 L 705.32617,144.7168 703.97266,120.37109 691.125,104.14258 671.51367,93.998047 614.03125,73.035156 Z m -15.55469,127.810548 -66.27148,225.86719 -10.14453,9.46679 -161.62305,34.48828 C 392.2135,362.27186 468.54968,268.95625 550.46484,192.73047 Z\"\n                            style=\"fill:".concat(color, ";fill-opacity:0.99004978;stroke:").concat(color, ";stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1\" />\n                        </g>\n                      </svg>"));
    }
  }
  var _default = _exports.default = Ember.Helper.helper(networkIcon);
});