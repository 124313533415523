define("affinio/templates/components/report-builder/boolean-input-optimizer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "TYEFsLb4",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[22,0,[]]]],[0,\"\\n\"],[1,[27,\"old/input-optimizer\",null,[[\"model\",\"openSupport\",\"controller\"],[[23,[\"model\"]],[27,\"action\",[[22,0,[]],\"openSupport\"],null],[23,[\"controller\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/report-builder/boolean-input-optimizer.hbs"
    }
  });
});