define("affinio/routes/sign-in", ["exports", "affinio/mixins/auth-check", "affinio/mixins/document-title"], function (_exports, _authCheck, _documentTitle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_authCheck.default, _documentTitle.default, {
    loggedInOnly: false,
    loggedOutOnly: true,
    documentTitle: 'Sign in - Affinio',
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        Ember.setProperties(controller, {
          email: null
        });
      }
    }
  });
});