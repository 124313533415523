define("affinio/templates/components/auto-complete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "TNTGjd5M",
    "block": "{\"symbols\":[\"suggestion\"],\"statements\":[[4,\"if\",[[23,[\"focus\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"focus-input\",null,[[\"class\",\"type\",\"placeholder\",\"value\",\"autocomplete\"],[\"suggestionInput\",\"text\",[23,[\"placeholder\"]],[23,[\"textInput\"]],\"off\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[27,\"input\",null,[[\"class\",\"type\",\"placeholder\",\"value\",\"autocomplete\"],[\"suggestionInput\",\"text\",[23,[\"placeholder\"]],[23,[\"textInput\"]],\"off\"]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"unless\",[[23,[\"externalFilterAction\"]]],null,{\"statements\":[[4,\"if\",[[23,[\"suggestionsOpen\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"suggestions\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"searchComputer\",\"suggestions\"]]],null,{\"statements\":[[0,\"        \"],[7,\"button\"],[11,\"class\",\"suggestionBtn\"],[9],[0,\"\\n          \"],[1,[27,\"if\",[[22,1,[\"type\"]],[27,\"entity-icon\",[[22,1,[\"type\"]]],null],[27,\"if\",[[23,[\"defaultType\"]],[27,\"entity-icon\",[[23,[\"defaultType\"]]],null]],null]],null],false],[0,\" \"],[1,[22,1,[\"name\"]],false],[0,\"\\n        \"],[3,\"action\",[[22,0,[]],\"autoCompleteSelect\",[22,1,[]]]],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/auto-complete.hbs"
    }
  });
});