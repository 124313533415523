define("affinio/templates/components/modals/modal-boolean", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "rbx5Pvh9",
    "block": "{\"symbols\":[],\"statements\":[[7,\"form\"],[11,\"class\",\"modal\"],[11,\"tabindex\",\"0\"],[9],[0,\"\\n  \"],[7,\"a\"],[11,\"class\",\"close-modal\"],[11,\"href\",\"#\"],[9],[0,\"×\"],[3,\"action\",[[22,0,[]],\"close\"]],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"question\"]]],null,{\"statements\":[[0,\"    \"],[7,\"p\"],[11,\"class\",\"question\"],[9],[1,[21,\"question\"],false],[10],[0,\"\\n  \"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"message\"]]],null,{\"statements\":[[0,\"    \"],[7,\"p\"],[9],[1,[21,\"message\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"footer\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"okAction\"]]],null,{\"statements\":[[0,\"      \"],[7,\"button\"],[11,\"class\",\"ok-button\"],[11,\"type\",\"submit\"],[9],[1,[21,\"okText\"],false],[10],[0,\"\\n    \"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"secondaryAction\"]]],null,{\"statements\":[[0,\"      \"],[7,\"button\"],[11,\"class\",\"ok-button\"],[9],[1,[21,\"secondaryActionText\"],false],[3,\"action\",[[22,0,[]],[23,[\"secondaryAction\"]]]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"button\"],[11,\"class\",\"cancel-button\"],[9],[0,\"Cancel\"],[3,\"action\",[[22,0,[]],\"cancel\"]],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[3,\"action\",[[22,0,[]],[23,[\"submitAction\"]]],[[\"on\"],[\"submit\"]]],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"backdrop\"],[11,\"role\",\"button\"],[9],[3,\"action\",[[22,0,[]],\"close\"]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/modals/modal-boolean.hbs"
    }
  });
});