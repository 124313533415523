define("affinio/utils/mutations", ["exports", "affinio/utils/constants/queries", "affinio/utils/mutations/reports", "affinio/utils/mutations/integrations", "affinio/utils/mutations/toolbox", "affinio/utils/mutations/folders", "affinio/utils/mutations/account", "affinio/utils/mutations/advertising", "affinio/utils/mutations/tags-suggestion", "affinio/utils/mutations/asset-group"], function (_exports, _queries, _reports, _integrations, _toolbox, _folders, _account, _advertising, _tagsSuggestion, _assetGroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.updateTaxonomy = _exports.updateReportName = _exports.updateNotes = _exports.updateGroup = _exports.updateDataSource = _exports.updateCompany = _exports.updateClusterName = _exports.updateClusterIsHidden = _exports.updateAccount = _exports.unshareGroupFromAccount = _exports.toggleCompanyActive = _exports.toggleAccountActive = _exports.shareGroupToAccount = _exports.setFileUploaded = _exports.removePermissionFromAllAccountsInCompany = _exports.removePermissionFromAccount = _exports.removeIntegrationToken = _exports.directShareReportToGroup = _exports.directShareReportToCompany = _exports.directShareReportToAccount = _exports.deleteGroup = _exports.deleteDataSource = _exports.default = _exports.createZendeskTicket = _exports.createTwitterAdsTACSV = _exports.createTaxonomy = _exports.createSubSegmentedReportCSV = _exports.createSnowflakeDataSet = _exports.createLookALikeExport = _exports.createIntegrationToken = _exports.createGroup = _exports.createFileUploadSignature = _exports.createDataSource = _exports.createDataSet = _exports.createCompany = _exports.createAccount = _exports.addPermissionToAllAccountsInCompany = _exports.addPermissionToAccount = _exports.acknowledgeNotification = _exports.acknowledgeAllNotifications = void 0;
  var createSubSegmentedReportCSV = _exports.createSubSegmentedReportCSV = function createSubSegmentedReportCSV(name, reportID, clusterSuffix, filters, numberOfClusters, requestSize) {
    return {
      networkName: 'createSubSegmentedReportCSV',
      queryString: "mutation($filters: TwitterReportFiltersArg!) {\n      createSubSegmentedReportCSV(\n        name: \"".concat(name, "\",\n        reportID: \"").concat(reportID, "\",\n        clusterSuffix: \"").concat(clusterSuffix, "\",\n        filters: $filters,\n        numberOfClusters: ").concat(numberOfClusters, ",\n        requestSize: ").concat(requestSize, "\n      ) {\n        ").concat(_queries.twitterReportResponse, "\n      }\n    }"),
      variables: {
        filters: filters
      },
      update: function update(response, graph) {
        graph.transformDates(response.data.createSubSegmentedReportCSV);
        return graph.recordFromGraphData(false, 'reports/twitter', response.data.createSubSegmentedReportCSV);
      }
    };
  };
  var createLookALikeExport = _exports.createLookALikeExport = function createLookALikeExport(reportID, clusterSuffix, requestSize, version) {
    return {
      queryString: "mutation {\n      createLookALikeExport(\n        reportID: \"".concat(reportID, "\",\n        clusterSuffix: \"").concat(clusterSuffix, "\",\n        requestSize: ").concat(requestSize, ",\n        version: ").concat(version, "\n      ) {\n        id\n      }\n    }")
    };
  };
  var createTwitterAdsTACSV = _exports.createTwitterAdsTACSV = function createTwitterAdsTACSV(reportID, clusterSuffix, requestSize, adAccountID) {
    return {
      queryString: "mutation {\n      createTwitterAdsTACSV(\n        reportID: \"".concat(reportID, "\",\n        clusterSuffix: \"").concat(clusterSuffix, "\",\n        requestSize: ").concat(requestSize, ",\n        adAccountID: \"").concat(adAccountID, "\",\n      ) {\n        id\n      }\n    }")
    };
  };
  var acknowledgeAllNotifications = _exports.acknowledgeAllNotifications = function acknowledgeAllNotifications() {
    return {
      queryString: 'mutation { acknowledgeAllNotifications { id } }'
    };
  };
  var acknowledgeNotification = _exports.acknowledgeNotification = function acknowledgeNotification(id) {
    return {
      queryString: "mutation {\n      acknowledgeNotification(id: \"".concat(id, "\") {\n        id\n        acknowledged\n      }\n    }"),
      update: function update(response, graph) {
        return graph.recordFromGraphData(false, 'notification', response.data.acknowledgeNotification);
      }
    };
  };
  var updateCompany = _exports.updateCompany = function updateCompany(id, name, credits, seatLimit, viewerSeatLimit, sfid, isActive, businessModelType, verticalType, trialInfo, notes) {
    /* eslint-disable */
    var trial = trialInfo ? "{\n      isActive: ".concat(trialInfo.isActive, ",\n      startDate: \"").concat(moment(trialInfo.startDate).toISOString(), "\",\n      endDate: \"").concat(moment(trialInfo.endDate).toISOString(), "\"\n    }") : null;
    /* eslint-enable */
    return {
      queryString: "mutation ($notes: String, $name: String){\n      updateCompany(id: \"".concat(id, "\", name: $name, credits: ").concat(credits, ", seatLimit: ").concat(seatLimit, ", viewerSeatLimit: ").concat(viewerSeatLimit, ", sfid: \"").concat(sfid, "\",  isActive: ").concat(isActive, ", businessModelType: \"").concat(businessModelType, "\", verticalType: \"").concat(verticalType, "\", trialInfo: ").concat(trial, ", notes: $notes) {\n        id,\n        credits,\n        seatLimit,\n        viewerSeatLimit,\n        sfid,\n        isActive,\n        businessModelType,\n        verticalType,\n        trialInfo{\n          isActive\n          startDate\n          endDate\n        },\n        notes\n      }\n    }"),
      variables: {
        notes: notes,
        name: name
      },
      update: function update(response, graph) {
        return graph.recordFromGraphData(false, 'company', response.data.updateCompany);
      }
    };
  };
  var createCompany = _exports.createCompany = function createCompany(name, credits, seatLimit, viewerSeatLimit, sfid, businessModelType, verticalType, trialInfo, notes) {
    var trial = trialInfo ? "{\n      isActive: ".concat(trialInfo.isActive, ",\n      startDate: \"").concat(trialInfo.startDate.toISOString(), "\",\n      endDate: \"").concat(trialInfo.endDate.toISOString(), "\"\n    }") : null;
    return {
      queryString: "mutation ($name: String!, $notes: String){\n      createCompany(name: $name, credits: ".concat(credits, ", seatLimit: ").concat(seatLimit, ", viewerSeatLimit: ").concat(viewerSeatLimit, ", sfid: \"").concat(sfid, "\", businessModelType: \"").concat(businessModelType, "\", verticalType: \"").concat(verticalType, "\", trialInfo: ").concat(trial, ", notes: $notes) {\n        id\n      }\n    }"),
      variables: {
        name: name,
        notes: notes
      }
    };
  };
  var createAccount = _exports.createAccount = function createAccount(id, email, companyID, firstName, lastName, notes, schema, isViewerSeat, isAdmin, isMS) {
    if (schema === 'private') {
      return {
        queryString: "mutation ($firstName: String!, $lastName: String!, $notes: String){\n        createAccount(email: \"".concat(email.trim(), "\", companyID: \"").concat(companyID, "\", firstName: $firstName, lastName: $lastName, notes: $notes, isViewerSeat: ").concat(isViewerSeat, ", isAdmin: ").concat(isAdmin, ", isMicrosoft: ").concat(isMS, ") {\n          id\n          company{\n            id\n            numberOfAccounts\n          }\n        }\n      }"),
        variables: {
          firstName: firstName,
          lastName: lastName,
          notes: notes
        },
        update: function update(response, graph) {
          graph.recordFromGraphData(false, 'company', response.data.createAccount.company);
          return response.data.createAccount.id;
        }
      };
    } else {
      return {
        queryString: "mutation ($firstName: String!, $lastName: String!) {\n        createAccount(email: \"".concat(email.trim(), "\", companyID: \"").concat(companyID, "\", firstName: $firstName, lastName: $lastName, isViewerSeat: ").concat(isViewerSeat, ", isAdmin: ").concat(isAdmin, ", isMicrosoft: ").concat(isMS, ") {\n          id\n          company{\n            id\n            numberOfAccounts\n          }\n        }\n      }"),
        variables: {
          firstName: firstName,
          lastName: lastName
        },
        update: function update(response, graph) {
          graph.recordFromGraphData(false, 'company', response.data.createAccount.company);
          return response.data.createAccount.id;
        }
      };
    }
  };
  var createGroup = _exports.createGroup = function createGroup(name, companyID) {
    return {
      queryString: "mutation($name: String!) {\n      createGroup(name: $name, companyID: \"".concat(companyID, "\") {\n        id\n      }\n    }"),
      variables: {
        name: name
      }
    };
  };
  var updateAccount = _exports.updateAccount = function updateAccount(id, firstName, lastName, isActive, notes, schema) {
    if (schema === 'private') {
      return {
        queryString: "mutation ($notes: String, $firstName: String, $lastName: String){\n        updateAccount(id: \"".concat(id, "\", firstName: $firstName, lastName: $lastName, isActive: ").concat(isActive, ", notes: $notes) {\n          id,\n          firstName,\n          lastName,\n          isActive,\n          notes\n        }\n      }"),
        variables: {
          notes: notes,
          firstName: firstName,
          lastName: lastName
        },
        update: function update(response, graph) {
          return graph.recordFromGraphData(false, 'account', response.data.updateAccount);
        }
      };
    } else {
      return {
        queryString: "mutation ($firstName: String, $lastName: String) {\n        updateAccount(id: \"".concat(id, "\", firstName: $firstName, lastName: $lastName, isActive: ").concat(isActive, ") {\n          id,\n          firstName,\n          lastName,\n          isActive\n        }\n      }"),
        variables: {
          firstName: firstName,
          lastName: lastName
        },
        update: function update(response, graph) {
          return graph.recordFromGraphData(false, 'account', response.data.updateAccount);
        }
      };
    }
  };
  var toggleAccountActive = _exports.toggleAccountActive = function toggleAccountActive(id, isActive) {
    return {
      queryString: "mutation {\n      updateAccount(id: \"".concat(id, "\", isActive: ").concat(isActive, ") {\n        id,\n        isActive,\n      }\n    }"),
      update: function update(response, graph) {
        return graph.recordFromGraphData(false, 'account', response.data.updateAccount);
      }
    };
  };
  var toggleCompanyActive = _exports.toggleCompanyActive = function toggleCompanyActive(id, isActive) {
    return {
      queryString: "mutation {\n      updateCompany(id: \"".concat(id, "\", isActive: ").concat(isActive, ") {\n        id,\n        isActive,\n      }\n    }"),
      update: function update(response, graph) {
        return graph.recordFromGraphData(false, 'company', response.data.updateCompany);
      }
    };
  };
  var deleteGroup = _exports.deleteGroup = function deleteGroup(id) {
    return {
      queryString: "mutation {\n      deleteGroup(id: \"".concat(id, "\")\n    }")
    };
  };
  var updateGroup = _exports.updateGroup = function updateGroup(id, name) {
    return {
      queryString: "mutation($name: String!) {\n      updateGroup(id: \"".concat(id, "\", name: $name) {\n        id,\n        name\n      }\n    }"),
      variables: {
        name: name
      },
      update: function update(response, graph) {
        return graph.recordFromGraphData(false, 'group', response.data.updateGroup);
      }
    };
  };
  var shareGroupToAccount = _exports.shareGroupToAccount = function shareGroupToAccount(accountID, id) {
    return {
      queryString: "mutation {\n      shareGroupToAccount(accountID: \"".concat(accountID, "\", groupID: \"").concat(id, "\"){\n        id\n        numberOfAccounts\n      }\n    }"),
      update: function update(response, graph) {
        return graph.recordFromGraphData(false, 'group', response.data.shareGroupToAccount);
      }
    };
  };
  var unshareGroupFromAccount = _exports.unshareGroupFromAccount = function unshareGroupFromAccount(accountID, id) {
    return {
      queryString: "mutation {\n      unshareGroupFromAccount(accountID: \"".concat(accountID, "\", groupID: \"").concat(id, "\"){\n        id\n        numberOfAccounts\n      }\n    }"),
      update: function update(response, graph) {
        return graph.recordFromGraphData(false, 'group', response.data.unshareGroupFromAccount);
      }
    };
  };

  // Asset Groups

  var addPermissionToAccount = _exports.addPermissionToAccount = function addPermissionToAccount(id, permission) {
    return {
      queryString: "mutation {\n      addPermissionToAccount(accountID: \"".concat(id, "\", accountPermission: \"").concat(permission, "\"){\n        id\n        permissions\n      }\n    }"),
      update: function update(response, graph) {
        return graph.recordFromGraphData(false, 'account', response.data.addPermissionToAccount);
      }
    };
  };
  var removePermissionFromAccount = _exports.removePermissionFromAccount = function removePermissionFromAccount(id, permission) {
    return {
      queryString: "mutation {\n      removePermissionFromAccount(accountID: \"".concat(id, "\", accountPermission: \"").concat(permission, "\"){\n        id\n        permissions\n      }\n    }"),
      update: function update(response, graph) {
        return graph.recordFromGraphData(false, 'account', response.data.removePermissionFromAccount);
      }
    };
  };
  var addPermissionToAllAccountsInCompany = _exports.addPermissionToAllAccountsInCompany = function addPermissionToAllAccountsInCompany(id, permission) {
    return {
      queryString: "mutation {\n      addPermissionToAllAccountsInCompany(companyID: \"".concat(id, "\", accountPermission: \"").concat(permission, "\"){\n        id\n        permissions\n      }\n    }")
    };
  };
  var removePermissionFromAllAccountsInCompany = _exports.removePermissionFromAllAccountsInCompany = function removePermissionFromAllAccountsInCompany(id, permission) {
    return {
      queryString: "mutation {\n      removePermissionFromAllAccountsInCompany(companyID: \"".concat(id, "\", accountPermission: \"").concat(permission, "\"){\n        id\n        permissions\n      }\n    }")
    };
  };
  var directShareReportToCompany = _exports.directShareReportToCompany = function directShareReportToCompany(reportID, companyID, permission) {
    return {
      queryString: "mutation {\n      shareReportToCompany(reportID: \"".concat(reportID, "\", companyID: \"").concat(companyID, "\", canEdit: ").concat(permission, ", shouldSendEmail: false){\n        id\n        name\n        numberOfClusters\n        numberOfMembers\n        dateCreated\n        dateUpdated\n        socialNetwork\n        creator{\n          id\n          firstName\n          lastName\n          email\n        }\n        accounts {\n          id\n          email\n          shareInfo {\n            canEdit\n          }\n        }\n        folders {\n          id\n          name\n          shareInfo {\n            canEdit\n          }\n        }\n        companies {\n          id\n          name\n          shareInfo {\n            canEdit\n          }\n        }\n      }\n    }")
    };
  };
  var directShareReportToGroup = _exports.directShareReportToGroup = function directShareReportToGroup(reportID, groupID, permission) {
    return {
      queryString: "mutation {\n      shareReportToGroup(reportID: \"".concat(reportID, "\", groupID: \"").concat(groupID, "\", canEdit: ").concat(permission, ", shouldSendEmail: false){\n        id\n        name\n        numberOfClusters\n        _numberOfMembers:numberOfMembers\n        dateCreated\n        dateUpdated\n        socialNetwork\n        creator{\n          id\n          firstName\n          lastName\n          email\n        }\n        accounts {\n          id\n          email\n          shareInfo {\n            canEdit\n          }\n        }\n        folders {\n          id\n          name\n          shareInfo {\n            canEdit\n          }\n        }\n        companies {\n          id\n          name\n          shareInfo {\n            canEdit\n          }\n        }\n      }\n    }")
    };
  };
  var directShareReportToAccount = _exports.directShareReportToAccount = function directShareReportToAccount(reportID, accountID, permission) {
    return {
      queryString: "mutation {\n      shareReportToAccount(reportID: \"".concat(reportID, "\", accountID: \"").concat(accountID, "\", canEdit: ").concat(permission, ", shouldSendEmail: false){\n        id\n        name\n        numberOfClusters\n        _numberOfMembers:numberOfMembers\n        dateCreated\n        dateUpdated\n        socialNetwork\n        creator{\n          id\n          firstName\n          lastName\n          email\n        }\n        accounts {\n          id\n          email\n          shareInfo {\n            canEdit\n          }\n        }\n        folders {\n          id\n          name\n          shareInfo {\n            canEdit\n          }\n        }\n        companies {\n          id\n          name\n          shareInfo {\n            canEdit\n          }\n        }\n      }\n    }")
    };
  };
  var updateNotes = _exports.updateNotes = function updateNotes(id, notes, network, type) {
    if (type === 'Report') {
      return {
        queryString: "mutation($notes: String) {\n        updateReport(id: \"".concat(id, "\" notes: $notes){\n          id\n          notes{\n            text\n            dateUpdated\n            updatedByID\n          }\n        }\n      }"),
        variables: {
          notes: notes
        },
        update: function update(response, graph) {
          return graph.recordFromGraphData(false, "reports/".concat(network.toLowerCase()), response.data.updateReport);
        }
      };
    } else {
      return {
        queryString: "mutation ($notes: String){\n        updateCluster(id: \"".concat(id, "\" notes: $notes){\n          id\n          notes{\n            text\n            dateUpdated\n            updatedByID\n          }\n        }\n      }"),
        variables: {
          notes: notes
        },
        update: function update(response, graph) {
          return graph.recordFromGraphData(false, "clusters/".concat(network.toLowerCase()), response.data.updateCluster);
        }
      };
    }
  };
  var updateClusterName = _exports.updateClusterName = function updateClusterName(id, name, network) {
    return {
      queryString: "mutation($name: String!) {\n      updateCluster(id: \"".concat(id, "\", name: $name){\n        id\n        name\n        isUserApprovedName\n      }\n    }"),
      variables: {
        name: name
      },
      update: function update(response, graph) {
        return graph.recordFromGraphData(false, "clusters/".concat(network), response.data.updateCluster);
      }
    };
  };
  var updateClusterIsHidden = _exports.updateClusterIsHidden = function updateClusterIsHidden(id, isHidden, network) {
    return {
      queryString: "mutation {\n      updateCluster(id: \"".concat(id, "\", isHidden: ").concat(isHidden, "){\n        id\n        isHidden\n      }\n    }"),
      update: function update(response, graph) {
        return graph.recordFromGraphData(false, "clusters/".concat(network), response.data.updateCluster);
      }
    };
  };
  var updateReportName = _exports.updateReportName = function updateReportName(id, name, network) {
    return {
      queryString: "mutation($name: String!) {\n      updateReport(id: \"".concat(id, "\", name: $name){\n        id\n        name\n      }\n    }"),
      variables: {
        name: name
      },
      update: function update(response, graph) {
        return graph.recordFromGraphData(false, "reports/".concat(network.toLowerCase()), response.data.updateReport);
      }
    };
  };
  var createZendeskTicket = _exports.createZendeskTicket = function createZendeskTicket(name, email, subject, comment) {
    return {
      queryString: "mutation($subject:String!, $comment:String!) {\n      createZendeskTicket(name: \"".concat(name, "\", email: \"").concat(email, "\", subject: $subject, comment: $comment)\n    }"),
      variables: {
        subject: subject,
        comment: comment
      }
    };
  };
  var createIntegrationToken = _exports.createIntegrationToken = function createIntegrationToken(source, key) {
    return {
      queryString: "mutation($key:String!) {\n      createIntegrationToken(source:\"".concat(source, "\", key:$key){\n        id\n        thirdPartyIntegrations{\n          source\n          key\n        }\n      }\n    }"),
      variables: {
        key: key
      },
      update: function update(response, graph) {
        return graph.recordFromGraphData(false, 'account', response.data.createIntegrationToken);
      }
    };
  };
  var removeIntegrationToken = _exports.removeIntegrationToken = function removeIntegrationToken(source, accountID) {
    return {
      queryString: "mutation {\n      deleteIntegrationToken(source:\"".concat(source, "\", accountID:\"").concat(accountID, "\"){\n        id\n        thirdPartyIntegrations{\n          source\n          key\n        }\n      }\n    }"),
      update: function update(response, graph) {
        return graph.recordFromGraphData(false, 'account', response.data.deleteIntegrationToken);
      }
    };
  };
  var createTaxonomy = _exports.createTaxonomy = function createTaxonomy(json) {
    return {
      queryString: "mutation($json:String!) {\n      createTaxonomy(json:$json) {\n        id\n      }\n    }",
      variables: {
        json: JSON.stringify(json)
      }
    };
  };
  var updateTaxonomy = _exports.updateTaxonomy = function updateTaxonomy(json) {
    return {
      queryString: "mutation($json:String!) {\n      updateTaxonomy(json:$json, taxonomyID:\"".concat(json.id, "\") {\n        id\n      }\n    }"),
      variables: {
        json: JSON.stringify(json)
      }
    };
  };
  var createDataSource = _exports.createDataSource = function createDataSource(json, dataSetId) {
    return {
      queryString: "mutation($json:String!) {\n      createDataSource(json:$json, dataSetId:\"".concat(dataSetId, "\") {\n        id\n      }\n    }"),
      variables: {
        json: JSON.stringify(json)
      }
    };
  };
  var updateDataSource = _exports.updateDataSource = function updateDataSource(json, sourceID) {
    return {
      queryString: "mutation($json:String!) {\n      updateDataSource(json:$json, dataSourceID:\"".concat(sourceID, "\") {\n        id\n      }\n    }"),
      variables: {
        json: JSON.stringify(json)
      }
    };
  };
  var deleteDataSource = _exports.deleteDataSource = function deleteDataSource(sourceID) {
    return {
      queryString: "mutation {\n      deleteDataSource(dataSourceID:\"".concat(sourceID, "\")\n    }")
    };
  };
  var createFileUploadSignature = _exports.createFileUploadSignature = function createFileUploadSignature(name, fileType) {
    return {
      queryString: "mutation($name:String!, $fileType:String!) {\n      createFileUploadSignature(fileName:$name, fileType:$fileType){\n        signature\n        expiry\n        file {\n          id\n          saveName\n        }\n      }\n    }",
      variables: {
        name: name,
        fileType: fileType
      }
    };
  };
  var setFileUploaded = _exports.setFileUploaded = function setFileUploaded(id) {
    return {
      queryString: "mutation {\n      setFileUploaded(fileID:\"".concat(id, "\") {\n        ").concat(_queries.fileResponse, "\n      }\n    }"),
      update: function update(response, graph) {
        return graph.recordFromGraphData(false, 'file', response.data.setFileUploaded);
      }
    };
  };
  var createDataSet = _exports.createDataSet = function createDataSet(name, fileID) {
    var dataSetType = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'CSV';
    var containerID = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
    return {
      queryString: "mutation($name:String!) {\n      createDataSet(name:$name dataSetType:\"".concat(dataSetType, "\" fileID: \"").concat(fileID, "\" ").concat(containerID.length ? "optionalContainerID:\"".concat(containerID, "\"") : '', ") {\n        ").concat(_queries.dataSetResponse, "\n      }\n    }"),
      variables: {
        name: name
      },
      update: function update(response, graph) {
        graph.transformDates(response.data.createDataSet);
        return graph.recordFromGraphData(false, 'taxonomies/data-set', response.data.createDataSet);
      }
    };
  };
  var createSnowflakeDataSet = _exports.createSnowflakeDataSet = function createSnowflakeDataSet(name, tableName, containerID) {
    return {
      queryString: "mutation($name:String!) {\n      createDataSet(\n        name:$name\n        dataSetType:\"SQL\"\n        optionalContainerTableName:\"".concat(tableName, "\"\n        optionalContainerID:\"").concat(containerID, "\"\n      ){\n        ").concat(_queries.dataSetResponse, "\n      }\n    }"),
      variables: {
        name: name
      },
      update: function update(response, graph) {
        graph.transformDates(response.data.createDataSet);
        return graph.recordFromGraphData(false, 'taxonomies/data-set', response.data.createDataSet);
      }
    };
  };
  var _default = _exports.default = {
    createSubSegmentedReportCSV: createSubSegmentedReportCSV,
    createLookALikeExport: createLookALikeExport,
    createTwitterAdsTACSV: createTwitterAdsTACSV,
    deleteReports: _reports.deleteReports,
    removeAccountReportsAccess: _reports.removeAccountReportsAccess,
    launchReport: _reports.launchReport,
    acknowledgeAllNotifications: acknowledgeAllNotifications,
    acknowledgeNotification: acknowledgeNotification,
    updateReports: _reports.updateReports,
    updateReportName: updateReportName,
    updateCompany: updateCompany,
    updateAccount: updateAccount,
    storeTwitterAccessTokens: _account.storeTwitterAccessTokens,
    updateGroup: updateGroup,
    shareGroupToAccount: shareGroupToAccount,
    createCompany: createCompany,
    createAccount: createAccount,
    createGroup: createGroup,
    toggleAccountActive: toggleAccountActive,
    deleteGroup: deleteGroup,
    toggleCompanyActive: toggleCompanyActive,
    unshareGroupFromAccount: unshareGroupFromAccount,
    addPermissionToAccount: addPermissionToAccount,
    removePermissionFromAccount: removePermissionFromAccount,
    addPermissionToAllAccountsInCompany: addPermissionToAllAccountsInCompany,
    removePermissionFromAllAccountsInCompany: removePermissionFromAllAccountsInCompany,
    directShareReportToCompany: directShareReportToCompany,
    directShareReportToGroup: directShareReportToGroup,
    directShareReportToAccount: directShareReportToAccount,
    deleteFolder: _folders.deleteFolder,
    updateFolder: _folders.updateFolder,
    removeAccountFolderAccess: _folders.removeAccountFolderAccess,
    createFolder: _folders.createFolder,
    updateAccountPreferences: _account.updateAccountPreferences,
    updateAccountPassword: _account.updateAccountPassword,
    createAssetGroup: _assetGroup.createAssetGroup,
    addAssetsToAssetGroup: _assetGroup.addAssetsToAssetGroup,
    deleteAssetGroup: _assetGroup.deleteAssetGroup,
    deleteAssetsFromGroupByName: _assetGroup.deleteAssetsFromGroupByName,
    deleteAssetsFromGroupByID: _assetGroup.deleteAssetsFromGroupByID,
    updateAssetGroupName: _assetGroup.updateAssetGroupName,
    updateNotes: updateNotes,
    updateClusterName: updateClusterName,
    updateClusterIsHidden: updateClusterIsHidden,
    requestClusterNameRecommendations: _reports.requestClusterNameRecommendations,
    addTwitterMonitoredInformation: _reports.addTwitterMonitoredInformation,
    updateTwitterReportBenchmarkGroups: _reports.updateTwitterReportBenchmarkGroups,
    createZendeskTicket: createZendeskTicket,
    toolboxDeleteReport: _toolbox.toolboxDeleteReport,
    toolboxTransferReport: _toolbox.toolboxTransferReport,
    toolboxShareAccountReportsToCompany: _toolbox.toolboxShareAccountReportsToCompany,
    toolboxShareAccountReportsToFolder: _toolbox.toolboxShareAccountReportsToFolder,
    toolboxShareAccountReportsToAccount: _toolbox.toolboxShareAccountReportsToAccount,
    toolboxTransferAccount: _toolbox.toolboxTransferAccount,
    toolboxClearAccountLockout: _toolbox.toolboxClearAccountLockout,
    toolboxSetPassword: _toolbox.toolboxSetPassword,
    toolboxReRunReport: _toolbox.toolboxReRunReport,
    toolboxReaggregateReport: _toolbox.toolboxReaggregateReport,
    toolboxReRunGraphGen: _toolbox.toolboxReRunGraphGen,
    toolboxReRunRecordsGen: _toolbox.toolboxReRunRecordsGen,
    toolboxResendWelcome: _toolbox.toolboxResendWelcome,
    toolboxConvertToBaseline: _toolbox.toolboxConvertToBaseline,
    toolboxRevertBaseline: _toolbox.toolboxRevertBaseline,
    toolboxUpdateReportAvailability: _toolbox.toolboxUpdateReportAvailability,
    toolboxDisableMonitoredReport: _toolbox.toolboxDisableMonitoredReport,
    toolboxAddTwitterHandleToInfluencers: _toolbox.toolboxAddTwitterHandleToInfluencers,
    toolboxAddToTags: _toolbox.toolboxAddToTags,
    toolboxAddCompanyContainer: _toolbox.toolboxAddCompanyContainer,
    removeTwitterAccessTokens: _advertising.removeTwitterAccessTokens,
    createIntegrationToken: createIntegrationToken,
    removeIntegrationToken: removeIntegrationToken,
    createTaxonomy: createTaxonomy,
    updateTaxonomy: updateTaxonomy,
    createDataSource: createDataSource,
    updateDataSource: updateDataSource,
    deleteDataSource: deleteDataSource,
    createFileUploadSignature: createFileUploadSignature,
    setFileUploaded: setFileUploaded,
    createDataSet: createDataSet,
    updateClusters: _reports.updateClusters,
    createSnowflakeDataSet: createSnowflakeDataSet,
    pushReportClusterToTalkWalkerSourcePanel: _integrations.pushReportClusterToTalkWalkerSourcePanel,
    pushReportClusterToTalkWalkerTopicQuery: _integrations.pushReportClusterToTalkWalkerTopicQuery,
    createCrowdSourcingTag: _tagsSuggestion.createCrowdSourcingTag,
    toolboxUpdateCrowdSourceTag: _tagsSuggestion.toolboxUpdateCrowdSourceTag
  };
});