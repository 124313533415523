define("affinio/models/clusters/custom", ["exports", "ember-data", "affinio/models/clusters/-base", "affinio/utils/data/array-attr"], function (_exports, _emberData, _base, _arrayAttr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var _default = _exports.default = _base.default.extend({
    domains: _arrayAttr.default,
    members: _arrayAttr.default,
    keywords: _arrayAttr.default,
    interests: _arrayAttr.default,
    engagements: _arrayAttr.default,
    posts: _arrayAttr.default,
    links: _arrayAttr.default,
    averageLinkKarma: attr(),
    averageCommentKarma: attr(),
    averageAccountAge: attr(),
    averageNumberOfPosts: attr(),
    averageNumberOfComments: attr(),
    numericSuffix: Ember.computed('suffix', function () {
      return parseInt(Ember.get(this, 'suffix'));
    }),
    memberRank: attr(),
    metrics: Ember.computed('_metrics.loaded', function () {
      var _this = this;
      var metrics = Ember.get(this, '_metrics.response').map(function (metric) {
        var parsed = JSON.parse(metric);
        if (Ember.isArray(Ember.get(parsed, 'data'))) {
          Ember.set(parsed, 'data', Ember.get(parsed, 'data').map(function (instance) {
            // eslint-disable-line ember/no-side-effects
            if (Ember.get(instance, 'saturations')) {
              Ember.get(instance, 'saturations').forEach(function (sat) {
                Ember.set(instance, "".concat(Ember.get(sat, 'filterType'), "_").concat(Ember.get(sat, 'filterValue')), Ember.get(sat, 'saturation')); // eslint-disable-line ember/no-side-effects
                // ODC-specific code, but generally pretty friendly: Takes the old-style way of doing saturations and presents it in the format that paired-metrics has for output.
                if (!Ember.get(sat, 'filters')) {
                  Ember.set(sat, 'filters', {
                    // eslint-disable-line ember/no-side-effects
                    type: Ember.get(sat, 'filterType'),
                    value: "".concat(Ember.get(sat, 'filterType'), " ").concat(Ember.get(sat, 'filterValue'))
                  });
                  Ember.set(sat, 'value', Ember.get(sat, 'saturation')); // eslint-disable-line ember/no-side-effects
                }
                Ember.set(instance, "".concat(Ember.get(sat, 'filterType'), "_").concat(Ember.get(sat, 'filterValue')), Ember.get(sat, 'saturation')); // eslint-disable-line ember/no-side-effects
              });
            }
            return instance;
          }));
        } else {
          if (Ember.get(parsed, 'data.bin')) {
            var structuredBins = Ember.get(parsed, 'data.bin').map(function (x) {
              return x.replace(/["'()[]/g, '');
            }).map(function (x) {
              return x.split(',');
            }).map(function (x) {
              return {
                lower: parseFloat(x[0] === '-Infinity' ? x[1] - 1 : x[0]),
                // weird, but this makes -Infinity into the "upper" value of this bin. Might make it upper-1 or something. -PR
                upper: parseFloat(x[1]),
                value: parseFloat(x[2])
              };
            }).sortBy('lower');
            Ember.set(parsed, 'data.structuredBins', structuredBins); // eslint-disable-line ember/no-side-effects
          }
        }
        return parsed;
      });
      var metricDefinitions = Ember.get(this, 'report.metricDefinitions').rejectBy('level', 'report').rejectBy('defaultValue', 'string').map(function (defn) {
        var label = Ember.get(defn, 'label');
        label = label === 'metrics' ? '_metrics' : label;
        var clusterSetMetric = Ember.get(_this, label);
        var metric = metrics.findBy('name', label);
        if (clusterSetMetric && metric) {
          Ember.set(clusterSetMetric, '_response', Ember.get(metric, 'data')); // eslint-disable-line ember/no-side-effects
        } else {
          if (clusterSetMetric) {
            // TODO: ODC Temp-hack (to handle _brandSpend and _shareOfWallet below)
            if (Ember.get(clusterSetMetric, 'response.value') === 0 && Ember.get(_this, "_".concat(Ember.get(clusterSetMetric, 'label')))) {
              Ember.set(clusterSetMetric, '_response', Ember.get(Ember.get(_this, "_".concat(Ember.get(clusterSetMetric, 'label'))), 'response')); // eslint-disable-line ember/no-side-effects
            } else {
              Ember.set(clusterSetMetric, '_response', Ember.get(clusterSetMetric, 'response')); // eslint-disable-line ember/no-side-effects
            }
          } else {
            Ember.set(clusterSetMetric, '_response', []); // eslint-disable-line ember/no-side-effects
          }
        }
        return metric;
      });
      return metricDefinitions.compact();
    }),
    // TODO: temp, ODC hackathon
    _shareOfWallet: Ember.computed(function () {
      window.console.log('TODO: remove ODC Hackathon hardcoded cluster metrics (models/clusters/custom.js)');
      if (Ember.get(this, 'UPC.response')) {
        var UPCSpend = Ember.get(this, 'UPC.response').map(function (instance) {
          return Ember.get(instance, 'saturations').filterBy('filterType', 'audience').findBy('filterValue', 'avg_Netsales').saturation * Ember.get(instance, 'value');
        }).reduce(function (m, n) {
          return m + n;
        });
        var brandSpend = Ember.get(this, 'Brand.response').map(function (instance) {
          return Ember.get(instance, 'saturations').filterBy('filterType', 'audience').findBy('filterValue', 'avg_Netsales').saturation * Ember.get(instance, 'value');
        }).reduce(function (m, n) {
          return m + n;
        });
        return {
          response: {
            value: UPCSpend / brandSpend
          }
        };
      } else {
        return {
          response: null
        };
      }
    }),
    _brandSpend: Ember.computed(function () {
      window.console.log('TODO: remove ODC Hackathon hardcoded cluster metrics (models/clusters/custom.js)');
      if (Ember.get(this, 'Brand.response')) {
        return {
          response: {
            label: 'Brand Spend',
            // value: (counts[get(this, 'id').split('_').pop()] / flatImages.length) * 100,
            // value: Math.random() * 10,
            value: Ember.get(this, 'Brand.response').map(function (instance) {
              return Ember.get(instance, 'saturations').filterBy('filterType', 'audience').findBy('filterValue', 'avg_Netsales').saturation;
            }).reduce(function (m, n) {
              return m + n;
            }) / Ember.get(this, 'Brand.response.length'),
            specificity: 2
          }
        };
      } else {
        return {
          response: null
        };
      }
    })
  });
});