define("affinio/utils/is-URL", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isUrl = isUrl;
  /**
   * looks to see if the string can be
   * validatided with the built in URL type
   * @param {string} string
   */

  function isUrl(string) {
    try {
      new URL(string);
      return true;
    } catch (error) {
      return false;
    }
  }
});