define("affinio/components/modals/modal-sign-in", ["exports", "affinio/components/modals/modal-text"], function (_exports, _modalText) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _modalText.default.extend({
    classNames: ['modal-sign-in'],
    init: function init() {
      this._super.apply(this, arguments);
      var existing = Ember.get(this, 'modalProperties');
      var properties = Object.assign({
        noClose: true
      }, existing);
      Ember.set(this, 'properties', properties);
    }
  });
});