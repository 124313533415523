define("affinio/templates/components/report-builder/translation-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ETGTgLJ6",
    "block": "{\"symbols\":[\"language\",\"&default\"],\"statements\":[[14,2,[[22,0,[]]]],[0,\"\\n\\n\"],[7,\"select\"],[11,\"class\",\"report-translation-selector\"],[12,\"value\",[23,[\"model\",\"filters\",\"translationCode\"]]],[12,\"onchange\",[27,\"action\",[[22,0,[]],\"setTranslationCode\"],[[\"value\"],[\"target.value\"]]]],[9],[0,\"\\n  \"],[7,\"option\"],[11,\"value\",\"\"],[9],[0,\"Don't Translate\"],[10],[0,\"\\n\"],[4,\"each\",[[23,[\"expandedLanguages\"]]],null,{\"statements\":[[0,\"    \"],[7,\"option\"],[12,\"selected\",[27,\"is-equal\",[[23,[\"model\",\"filters\",\"translationCode\"]],[22,1,[\"label\"]]],null]],[12,\"value\",[22,1,[\"label\"]]],[9],[1,[22,1,[\"icon\"]],false],[0,\" \"],[1,[22,1,[\"expanded\"]],false],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/report-builder/translation-input.hbs"
    }
  });
});