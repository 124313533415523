define("affinio/services/oauth", ["exports", "affinio/utils/queries", "affinio/utils/mutations"], function (_exports, _queries, _mutations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    graph: Ember.inject.service(),
    tracker: Ember.inject.service(),
    user: Ember.inject.service(),
    // Public API
    authenticate: function authenticate(network) {
      var _this = this;
      switch (network) {
        case 'twitter':
          return Ember.get(this, 'graph').query(_queries.default.generateTwitterOauthRequestToken(window.location.href), true).then(function (data) {
            var token = data.data.twitterOauthRequestToken;
            // console.log(token);
            if (token) {
              Ember.get(_this, 'tracker').track('generate Twitter Ads request token', {}, function () {
                // console.log('tracking callback');
                window.location = "https://api.twitter.com/oauth/authenticate?oauth_token=".concat(token);
              });
            }
          });
        default:
          window.console.warn('unsupported network');
          return Ember.RSVP.resolve();
      }
    },
    clearAuthentication: function clearAuthentication(network, id) {
      switch (network) {
        case 'twitter':
          return Ember.get(this, 'graph').mutate(_mutations.default.removeTwitterAccessTokens(id));
        default:
          return Ember.RSVP.resolve();
      }
    },
    saveAuthentication: function saveAuthentication(network, data) {
      var _this2 = this;
      switch (network) {
        case 'twitter':
          // TODO seems like this is current account only?
          return Ember.get(this, 'graph').mutate(_mutations.default.storeTwitterAccessTokens(data.token, data.verifier)).then(function (response) {
            Ember.set(_this2, 'user.account.twitterCredentials', response.data.storeTwitterAccessTokens);
          });
        default:
          return Ember.RSVP.resolve();
      }
    } // Private API
  });
});