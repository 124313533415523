define("affinio/utils/affinio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.baselineAudienceDistinctionRanges = _exports.badNames = void 0;
  _exports.createMetric = createMetric;
  _exports.csvParse = csvParse;
  _exports.downloadURL = _exports.default = void 0;
  _exports.getOrCreateMetric = getOrCreateMetric;
  _exports.getParameterByName = getParameterByName;
  _exports.isNewFormatCustomFilters = _exports.isEmptyObject = _exports.isCustomSQLFilters = _exports.inputOptimizerSpecialRegex = _exports.hasExclusions = void 0;
  _exports.maxBy = maxBy;
  _exports.maybeRunLater = maybeRunLater;
  _exports.nonExistToZero = nonExistToZero;
  _exports.peekReport = peekReport;
  _exports.predictDataType = void 0;
  _exports.reportTypeFromFilters = reportTypeFromFilters;
  _exports.setupReportMetricInstances = setupReportMetricInstances;
  _exports.sumKey = sumKey;
  _exports.timer = void 0;
  function maybeRunLater(context, callback) {
    var _Ember$run;
    for (var _len = arguments.length, lastArgs = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
      lastArgs[_key - 2] = arguments[_key];
    }
    return (_Ember$run = Ember.run).later.apply(_Ember$run, [context, function () {
      if (!Ember.get(context, 'isDestroyed')) {
        callback.apply(context);
      }
    }].concat(lastArgs));
  }
  function sumKey(enumerable, key) {
    return enumerable.reduce(function (total, item) {
      return total + Ember.get(item, key);
    }, 0);
  }
  function csvParse(data, options) {
    var rows = d3.csv.parseRows(data);
    // TODO shouldnt we just be using d3.csv.parse if they have headers? - js
    // https://github.com/d3/d3-3.x-api-reference/blob/master/CSV.md#parse
    if (options.headers) {
      var keys = rows.shift();
      return rows.map(function (row) {
        var rowObj = {};
        for (var i = 0; i < keys.length; i++) {
          rowObj[keys[i]] = row[i];
        }
        return rowObj;
      });
    } else {
      return rows;
    }
  }
  function getParameterByName(name, url) {
    if (!url) {
      url = window.location.href;
    }
    name = name.replace(/[[\]]/g, '\\$&');
    var regex = new RegExp("[?&]".concat(name, "(=([^&#]*)|&|#|$)"), 'i');
    var results = regex.exec(url);
    // console.log(results);
    if (!results) {
      return null;
    }
    if (!results[2]) {
      return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  } // getParameterByName

  /** @description A functional helper to transform undefined or null to 0. */
  function nonExistToZero(m) {
    return m === null || m === undefined ? 0 : m;
  }
  function _reduceBy(enumerable, key, callback, initialValue) {
    if (typeof callback !== 'function') {
      throw new TypeError();
    }
    var currentValue = initialValue;
    var ret;
    enumerable.forEach(function (item, i) {
      if (callback(currentValue, Ember.get(item, key)) !== currentValue) {
        currentValue = Ember.get(item, key);
        ret = enumerable.objectAt(i);
      }
    });
    return ret;
  }
  function _max(previousValue, currentValue) {
    return parseFloat(previousValue) >= parseFloat(currentValue) ? previousValue : currentValue;
  }
  function maxBy(enumerable, key) {
    return _reduceBy(enumerable, key, _max);
  }
  function peekReport(store, reportID) {
    if (!store || !reportID) {
      return null;
    }
    var networks = ['twitter', 'pinterest', 'instagram', 'custom'];
    var reportRecord;
    for (var i = 0; i < networks.length; i++) {
      reportRecord = store.peekRecord("reports/".concat(networks[i]), reportID);
      if (reportRecord) {
        break;
      }
    }
    return reportRecord;
  }
  function reportTypeFromFilters(network, filters) {
    var reportTypeHash = {
      twitter: [{
        type: 'network-graph'
      }, {
        type: 'tweet-content'
      }, {
        type: 'csv-upload'
      }, {
        type: 'url-share'
      }, {
        type: 'talkwalker-integration'
      }],
      youtube: [{
        type: 'engagement-graph'
      }]
    };
    var activeRecycledFilters = _.pick(filters, function (value) {
      return Ember.isPresent(value);
    });
    if (network === 'twitter') {
      if (activeRecycledFilters.hasOwnProperty('contentQuery')) {
        return reportTypeHash[network].findBy('type', 'tweet-content');
      } else if (activeRecycledFilters.hasOwnProperty('fileURL')) {
        return reportTypeHash[network].findBy('type', 'csv-upload');
      } else if (activeRecycledFilters.hasOwnProperty('fileURL')) {
        return reportTypeHash[network].findBy('type', 'talkwalker-integration');
      } else {
        return reportTypeHash[network].findBy('type', 'network-graph');
      }
    } else {
      var engagementGraph = false;
      if (reportTypeHash[network]) {
        engagementGraph = reportTypeHash[network].findBy('type', 'engagement-graph');
      }
      return engagementGraph ? engagementGraph : network;
    }
  }
  function getOrCreateMetric(store, id, network, metricDefinition) {
    var record = store.peekRecord('metric', id);
    if (record) {
      return record;
    } else {
      return store.createRecord('metric', {
        id: id,
        metricDefinition: metricDefinition,
        network: network
      });
    }
  }
  function createMetric(dataObject, store, level, value) {
    ['twitter', 'pinterest', 'instagram', 'custom'].forEach(function (network) {
      Ember.get(dataObject, "".concat(level, ".").concat(network, "Reports")).forEach(function (report) {
        if (typeof Ember.get(report, "".concat(value)) === 'number') {
          var id = "".concat(Ember.get(report, 'id'), "___").concat(Ember.get(report, 'version'), "___").concat(value);
          var metric = store.peekRecord('metric', id);
          if (!metric) {
            metric = store.createRecord('metric', {
              id: id,
              level: 'report',
              response: {
                value: Ember.get(report, "".concat(value))
              }
            });
            Ember.set(report, "".concat(value), metric);
          }
        }
      });
    });
  }
  function setupReportMetricInstances(store, report, version) {
    // let network = get(report, 'socialNetwork');
    var network = Ember.get(report, 'isCustom') ? 'custom' : Ember.get(report, 'socialNetwork');
    var metrics = Ember.get(report, 'taxonomy.metricDefinitions').reject(function (metric) {
      return Ember.get(metric, 'label').includes('.');
    });

    // cluster level
    metrics.rejectBy('level', 'report').forEach(function (metric) {
      var key = Ember.get(metric, 'modelAlias') ? Ember.get(metric, 'modelAlias') : Ember.get(metric, 'label');
      var forcedVersion = Ember.get(metric, 'initialVersionOnly') ? 0 : version;
      // Individual clusters
      Ember.get(report, '_clusters').forEach(function (cluster) {
        var metricInstance = getOrCreateMetric(store, "".concat(Ember.get(cluster, 'id'), "___").concat(forcedVersion, "___").concat(Ember.get(metric, 'label')), network, metric);
        Ember.set(cluster, key, metricInstance);
      });
      // Aggregate cluster
      Ember.set(report, "clusterAggregation.".concat(key), getOrCreateMetric(store, "".concat(Ember.get(report, 'clusterAggregation.id'), "___").concat(forcedVersion, "___").concat(Ember.get(metric, 'label')), network, metric));
    });

    // report metrics
    metrics.filterBy('level', 'report').forEach(function (metric) {
      var key = Ember.get(metric, 'modelAlias') ? Ember.get(metric, 'modelAlias') : Ember.get(metric, 'label');
      var forcedVersion = Ember.get(metric, 'initialVersionOnly') ? 0 : version;
      Ember.set(report, key, getOrCreateMetric(store, "".concat(Ember.get(report, 'id'), "___").concat(forcedVersion, "___").concat(Ember.get(metric, 'label')), network, metric));
    });
  }
  var inputOptimizerSpecialRegex = _exports.inputOptimizerSpecialRegex = /[!%^&*()+|~=`\\{}[\]:';<>?,]/g;

  // https://stackoverflow.com/a/49729848
  var isEmptyObject = _exports.isEmptyObject = function isEmptyObject(obj) {
    return Object.getOwnPropertyNames(obj).length === 0 && Object.getOwnPropertySymbols(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype;
  };

  /**
   * Downloads a file to the client
   * @method downloadURL
   * @param {Object} data blob object to be included in the file
   * @param {String} dataUrl object URL represents the specified blob object
   * @param {String} fileName name of the file to be downloaded
   * @param {String} i index value if multiple files need to be downloaded
   * @param {Number} delay optional time delay for larger files
   * @return {Object} a downloaded file
   */
  var downloadURL = _exports.downloadURL = function downloadURL(data, dataUrl, fileName) {
    var i = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
    var delay = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 100;
    var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    var isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);
    var isFirefox = /Firefox/.test(navigator.userAgent);
    if (isChrome || isSafari || isFirefox) {
      Ember.run.later(function () {
        var link = document.createElement('a');
        document.body.appendChild(link);
        link.setAttribute('href', dataUrl);
        link.setAttribute('download', fileName);
        link.click(); // This will download the data file
        document.body.removeChild(link);
      }, (i + 1) * delay);
    } else if (navigator && navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(data, fileName);
    } else {
      window.open(dataUrl);
    }
  };

  /** the API is always returning the exclusion filters even when null
   * thus we're using the isPresent to show reports that have excluded filters
   *
   * @param {Array} filters list of filters
   * @return {Boolean} this has exclusions
   */
  var hasExclusions = _exports.hasExclusions = function hasExclusions(filters) {
    var excludeKeys = ['excludedFollowersOf', 'excludedBioLocations', 'excludedBioKeywords'];
    return excludeKeys.some(function (key) {
      return Ember.isPresent(filters[key]);
    });
  };
  var predictDataType = _exports.predictDataType = function predictDataType(dataSet) {
    if (!dataSet.every(isNaN)) {
      // Check for ints
      // Determine if there are decimals or not, decimals will return false for isInteger and
      // since there is already a check for isNaN, we can assume it will be a decimal
      if (dataSet.every(function (x) {
        return Number.isInteger(parseFloat(x));
      })) {
        return 'integer';
      } else {
        return 'decimal';
      }
    } else {
      return 'category';
    }
  };

  /**
   * timer used for async functions. Uses Ember's run loop later method
   * @method timer
   * @param {Number} ms time in miliseconds to delay
   * @return {Promise} A promise that will resolve after ms time has elapsed
   * @private
   */
  var timer = _exports.timer = function timer(ms) {
    return new Promise(function (r) {
      return Ember.run.later(r, ms);
    });
  };
  var badNames = _exports.badNames = ['name', 'id', 'identifier', 'numberofmembers', 'metrics', 'label', 'cluster', 'value', 'rank', 'category'];

  /**
   * Helps make sense of cluster-to-baseline scores in a repeatable way
   */
  var baselineAudienceDistinctionRanges = _exports.baselineAudienceDistinctionRanges = [{
    level: 'extremely distinct',
    minSoWhat: 50
  }, {
    level: 'very distinct',
    minSoWhat: 40
  }, {
    level: 'fairly distinct',
    minSoWhat: 30
  }, {
    level: 'somewhat distinct',
    minSoWhat: 20
  }, {
    level: 'not particularly distinct',
    minSoWhat: 10
  }, {
    level: 'indistinct',
    minSoWhat: 0
  }];
  var isNewFormatCustomFilters = _exports.isNewFormatCustomFilters = function isNewFormatCustomFilters(filters) {
    return filters.data_sources && filters.data_sources.length && filters.data_sources[0].filters && !Ember.isArray(filters.data_sources[0].filters);
  };
  var isCustomSQLFilters = _exports.isCustomSQLFilters = function isCustomSQLFilters(filters) {
    return filters.data_sources && filters.data_sources.length && filters.data_sources[0].filters.leaves && filters.data_sources[0].filters.leaves.length && filters.data_sources[0].filters.leaves[0].type === 'SQL';
  };
  var _default = _exports.default = {
    maybeRunLater: maybeRunLater,
    csvParse: csvParse,
    getParameterByName: getParameterByName,
    sumKey: sumKey,
    nonExistToZero: nonExistToZero,
    maxBy: maxBy,
    peekReport: peekReport,
    getOrCreateMetric: getOrCreateMetric,
    createMetric: createMetric,
    setupReportMetricInstances: setupReportMetricInstances,
    inputOptimizerSpecialRegex: inputOptimizerSpecialRegex,
    isEmptyObject: isEmptyObject,
    reportTypeFromFilters: reportTypeFromFilters,
    downloadURL: downloadURL,
    hasExclusions: hasExclusions,
    predictDataType: predictDataType,
    timer: timer,
    badNames: badNames,
    baselineAudienceDistinctionRanges: baselineAudienceDistinctionRanges,
    isNewFormatCustomFilters: isNewFormatCustomFilters,
    isCustomSQLFilters: isCustomSQLFilters
  };
});