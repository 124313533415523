define("affinio/utils/mutations/toolbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.toolboxUpdateReportAvailability = _exports.toolboxTransferReport = _exports.toolboxTransferAccount = _exports.toolboxShareAccountReportsToFolder = _exports.toolboxShareAccountReportsToCompany = _exports.toolboxShareAccountReportsToAccount = _exports.toolboxSetPassword = _exports.toolboxRevertBaseline = _exports.toolboxResendWelcome = _exports.toolboxReaggregateReport = _exports.toolboxReRunReport = _exports.toolboxReRunRecordsGen = _exports.toolboxReRunGraphGen = _exports.toolboxDisableMonitoredReport = _exports.toolboxDeleteReport = _exports.toolboxConvertToBaseline = _exports.toolboxClearAccountLockout = _exports.toolboxAddTwitterHandleToInfluencers = _exports.toolboxAddToTags = _exports.toolboxAddCompanyContainer = _exports.default = void 0;
  var toolboxDeleteReport = _exports.toolboxDeleteReport = function toolboxDeleteReport(id) {
    return {
      queryString: "mutation{toolboxDeleteReport(id:\"".concat(id, "\")}")
    };
  };
  var toolboxTransferReport = _exports.toolboxTransferReport = function toolboxTransferReport(reportID, accountID) {
    return {
      queryString: "mutation{toolboxTransferReport(reportID:\"".concat(reportID, "\" accountID:\"").concat(accountID, "\")}")
    };
  };
  var toolboxShareAccountReportsToCompany = _exports.toolboxShareAccountReportsToCompany = function toolboxShareAccountReportsToCompany(accountID) {
    return {
      queryString: "mutation{toolboxShareAccountReportsToCompany(accountID:\"".concat(accountID, "\")}")
    };
  };
  var toolboxShareAccountReportsToFolder = _exports.toolboxShareAccountReportsToFolder = function toolboxShareAccountReportsToFolder(accountID, folderID) {
    return {
      queryString: "mutation{toolboxShareAccountReportsToFolder(accountID:\"".concat(accountID, "\" folderID:\"").concat(folderID, "\")}")
    };
  };
  var toolboxShareAccountReportsToAccount = _exports.toolboxShareAccountReportsToAccount = function toolboxShareAccountReportsToAccount(sharerAccountID, recipientAccountID) {
    return {
      queryString: "mutation{toolboxShareAccountReportsToAccount(sharerAccountID:\"".concat(sharerAccountID, "\" recipientAccountID:\"").concat(recipientAccountID, "\")}")
    };
  };
  var toolboxTransferAccount = _exports.toolboxTransferAccount = function toolboxTransferAccount(accountID, companyID) {
    return {
      queryString: "mutation{toolboxTransferAccount(accountID:\"".concat(accountID, "\" companyID:\"").concat(companyID, "\")}")
    };
  };
  var toolboxClearAccountLockout = _exports.toolboxClearAccountLockout = function toolboxClearAccountLockout(accountID) {
    return {
      queryString: "mutation{toolboxClearAccountLockout(accountID:\"".concat(accountID, "\")}")
    };
  };
  var toolboxSetPassword = _exports.toolboxSetPassword = function toolboxSetPassword(id, password) {
    return {
      networkName: 'toolboxSetPassword',
      queryString: "mutation{toolboxSetPassword(id:\"".concat(id, "\" password:\"").concat(password, "\"){id}}")
    };
  };
  var toolboxReRunReport = _exports.toolboxReRunReport = function toolboxReRunReport(id) {
    return {
      networkName: 'toolboxReRunReport',
      queryString: "mutation{toolboxReRunReport(reportID:\"".concat(id, "\"){id}}")
    };
  };
  var toolboxReaggregateReport = _exports.toolboxReaggregateReport = function toolboxReaggregateReport(id) {
    return {
      networkName: 'toolboxReaggregateReport',
      queryString: "mutation{updateClusters(reportID:\"".concat(id, "\" clusters:[]){id}}")
    };
  };
  var toolboxReRunGraphGen = _exports.toolboxReRunGraphGen = function toolboxReRunGraphGen(id) {
    return {
      networkName: 'toolboxReRunGraphGen',
      queryString: "mutation{toolboxReRunGraphGen(reportID:\"".concat(id, "\"){id}}")
    };
  };
  var toolboxReRunRecordsGen = _exports.toolboxReRunRecordsGen = function toolboxReRunRecordsGen(id) {
    return {
      networkName: 'toolboxReRunRecordsGen',
      queryString: "mutation{toolboxReRunRecordsGen(reportID:\"".concat(id, "\"){id}}")
    };
  };
  var toolboxResendWelcome = _exports.toolboxResendWelcome = function toolboxResendWelcome(accountID) {
    return {
      networkName: 'toolboxResendWelcome',
      queryString: "mutation{toolboxResendWelcome(accountID:\"".concat(accountID, "\")}")
    };
  };
  var toolboxConvertToBaseline = _exports.toolboxConvertToBaseline = function toolboxConvertToBaseline(id, availability) {
    var isBaseline = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
    return {
      networkName: isBaseline ? 'toolboxConvertToBaseline' : 'toolboxRevertBaseline',
      queryString: "mutation{toolboxUpdateReportBaseline(reportID:\"".concat(id, "\", isBaseline: ").concat(isBaseline, ", availability: \"").concat(availability, "\"){id}}")
    };
  };
  var toolboxRevertBaseline = _exports.toolboxRevertBaseline = function toolboxRevertBaseline(id, availability) {
    return toolboxConvertToBaseline(id, availability, false);
  };
  var toolboxUpdateReportAvailability = _exports.toolboxUpdateReportAvailability = function toolboxUpdateReportAvailability(id, availability) {
    return {
      networkName: 'toolboxUpdateReportAvailability',
      queryString: "mutation{toolboxUpdateReportAvailability(reportID:\"".concat(id, "\", availability: \"").concat(availability, "\"){id}}")
    };
  };
  var toolboxDisableMonitoredReport = _exports.toolboxDisableMonitoredReport = function toolboxDisableMonitoredReport(id) {
    return {
      queryString: "mutation{toolboxDisableMonitoredReport(reportID:\"".concat(id, "\"){id}}")
    };
  };
  var toolboxAddTwitterHandleToInfluencers = _exports.toolboxAddTwitterHandleToInfluencers = function toolboxAddTwitterHandleToInfluencers(handle) {
    return {
      networkName: 'toolboxAddTwitterHandleToInfluencers',
      queryString: "mutation { toolboxAddTwitterHandleToInfluencers(handle:\"".concat(handle, "\")}")
    };
  };
  var toolboxAddToTags = _exports.toolboxAddToTags = function toolboxAddToTags(tags) {
    return {
      networkName: 'toolboxAddToTags',
      queryString: "mutation{ toolboxAddToTags(influencerTags:\"".concat(tags, "\") }")
    };
  };
  var toolboxAddCompanyContainer = _exports.toolboxAddCompanyContainer = function toolboxAddCompanyContainer(id, name, type, db) {
    return {
      networkName: 'toolboxAddCompanyContainer',
      queryString: "mutation($name:String! $type:String! $id:String! $db:String!){\n    toolboxAddCompanyContainer(\n      containerName:$name\n      containerType:$type\n      companyID:$id\n      dbConnectionString:$db\n    ){\n      containerType\n      company{\n        name\n      }\n      id\n    }\n  }",
      variables: {
        name: name,
        type: type,
        id: id,
        db: db
      }
    };
  };
  var _default = _exports.default = {
    toolboxDeleteReport: toolboxDeleteReport,
    toolboxTransferReport: toolboxTransferReport,
    toolboxShareAccountReportsToCompany: toolboxShareAccountReportsToCompany,
    toolboxShareAccountReportsToFolder: toolboxShareAccountReportsToFolder,
    toolboxShareAccountReportsToAccount: toolboxShareAccountReportsToAccount,
    toolboxTransferAccount: toolboxTransferAccount,
    toolboxClearAccountLockout: toolboxClearAccountLockout,
    toolboxSetPassword: toolboxSetPassword,
    toolboxReRunReport: toolboxReRunReport,
    toolboxReaggregateReport: toolboxReaggregateReport,
    toolboxReRunGraphGen: toolboxReRunGraphGen,
    toolboxReRunRecordsGen: toolboxReRunRecordsGen,
    toolboxResendWelcome: toolboxResendWelcome,
    toolboxConvertToBaseline: toolboxConvertToBaseline,
    toolboxRevertBaseline: toolboxRevertBaseline,
    toolboxUpdateReportAvailability: toolboxUpdateReportAvailability,
    toolboxDisableMonitoredReport: toolboxDisableMonitoredReport,
    toolboxAddTwitterHandleToInfluencers: toolboxAddTwitterHandleToInfluencers,
    toolboxAddCompanyContainer: toolboxAddCompanyContainer
  };
});