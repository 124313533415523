define("affinio/templates/components/modals/modal-success", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "/FaQ6sTT",
    "block": "{\"symbols\":[],\"statements\":[[7,\"form\"],[11,\"class\",\"modal modal-success\"],[9],[0,\"\\n  \"],[7,\"a\"],[11,\"class\",\"close-modal\"],[11,\"href\",\"#\"],[9],[0,\"×\"],[3,\"action\",[[22,0,[]],\"close\"]],[10],[0,\"\\n  \"],[7,\"h3\"],[11,\"class\",\"modal-title\"],[9],[1,[21,\"title\"],false],[10],[0,\"\\n  \"],[1,[27,\"html-safe\",[[23,[\"message\"]]],null],false],[0,\"\\n\"],[4,\"if\",[[23,[\"okAction\"]]],null,{\"statements\":[[0,\"    \"],[7,\"footer\"],[9],[0,\"\\n      \"],[7,\"a\"],[11,\"class\",\"button primary\"],[11,\"role\",\"button\"],[9],[0,\"\\n        \"],[1,[27,\"if\",[[23,[\"okText\"]],[23,[\"okText\"]],\"Ok\"],null],false],[0,\"\\n      \"],[3,\"action\",[[22,0,[]],\"close\"]],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"backdrop\"],[11,\"role\",\"button\"],[9],[3,\"action\",[[22,0,[]],\"close\"]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/modals/modal-success.hbs"
    }
  });
});