define("affinio/utils/constants/queries", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.twitterReportResponseDefault = _exports.twitterReportResponse = _exports.taxoRequest = _exports.standardReport = _exports.simpleReportResponseForClusterDetails = _exports.pinterestReportResponseDefault = _exports.pinterestReportResponse = _exports.instagramReportResponseDefault = _exports.instagramReportResponse = _exports.fullTaxoRequest = _exports.fileResponse = _exports.default = _exports.dataSetResponse = _exports.customReportResponseDefault = _exports.customReportResponse = void 0;
  var taxoRequest = _exports.taxoRequest = '_taxonomy:taxonomy { id icon runnable label }';
  var fullTaxoRequest = _exports.fullTaxoRequest = '_taxonomy:taxonomy { id icon runnable label json dataSources { label id } }';
  var standardReportResponse = "\n  id\n  name\n  ".concat(taxoRequest, "\n  socialNetwork\n  numberOfMembers\n  numberOfClusters\n  state\n  dateUpdated\n  dateCreated\n  isBaseline\n  notes {\n    text\n    dateUpdated\n    updatedByID\n  }\n  accounts {\n    id\n    email\n    shareInfo {\n      canEdit\n    }\n  }\n  folders {\n    id\n    name\n    shareInfo {\n      canEdit\n    }\n  }\n  groups {\n    id\n    shareInfo {\n      canEdit\n    }\n  }\n  companies {\n    id\n    name\n    shareInfo {\n      canEdit\n    }\n  }\n  creator {\n    firstName\n    lastName\n    email\n    id\n    company {\n      id\n      name\n    }\n  }\n");
  var standardReport = _exports.standardReport = "\n  dateFinished\n  ".concat(standardReportResponse, "\n");
  var simpleReportResponseForClusterDetails = _exports.simpleReportResponseForClusterDetails = "\n  id\n  clusterNameIds {\n    name\n    id\n    suffix\n    isClusterRemoved\n    mergedInto\n  }\n";
  var pinterestReportResponseDefault = _exports.pinterestReportResponseDefault = function pinterestReportResponseDefault() {
    var launch = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
    return "\n  ".concat(launch ? standardReportResponse.replace(taxoRequest, '') : standardReport, "\n  filters {\n    followersOf\n  }\n");
  };
  var pinterestReportResponse = _exports.pinterestReportResponse = "\n  ".concat(pinterestReportResponseDefault(), "\n");
  var twitterReportResponseDefault = _exports.twitterReportResponseDefault = function twitterReportResponseDefault() {
    var launch = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
    return "\n  ".concat(launch ? standardReportResponse.replace(taxoRequest, '') : standardReport, "\n  version\n  monitoredInformation {\n    isActive\n    lastUpdate\n    frequency\n  }\n  storedBenchmarkGroups {\n    name\n    members\n  }\n  filters {\n    followersOf\n    excludedFollowersOf\n    excludedBioKeywords\n    excludedBioLocations\n    bioKeywords\n    bioLocations\n    ownedBenchmarks\n    competitiveBenchmarks\n    explicitWords { term type }\n    contentQuery\n    fileURL\n    followersOfCSV\n    isHandles\n    minInfluencerFollowers\n    maxInfluencerFollowers\n    followersIntersection\n    contentFrom\n    contentTo\n    union\n    segmentedReportSource {\n      reportName\n      reportID\n      clusterName\n      clusterID\n    }\n    translationCode\n    followersStartDate\n    followersEndDate\n    followersStartWeekYear\n    followersEndWeekYear\n    queryID\n    fileID\n  }\n");
  };
  var twitterReportResponse = _exports.twitterReportResponse = "\n  ".concat(twitterReportResponseDefault(), "\n");
  var instagramReportResponseDefault = _exports.instagramReportResponseDefault = function instagramReportResponseDefault() {
    var launch = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
    return "\n  ".concat(launch ? standardReportResponse.replace(taxoRequest, '') : standardReport, "\n  filters {\n    followersOf\n    bioKeywords\n  }\n");
  };
  var instagramReportResponse = _exports.instagramReportResponse = "\n  ".concat(instagramReportResponseDefault(), "\n");
  var customReportResponseDefault = _exports.customReportResponseDefault = function customReportResponseDefault() {
    var launch = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
    return "\n  ".concat(launch ? standardReportResponse : standardReport, "\n  _filters:filters\n");
  };
  var customReportResponse = _exports.customReportResponse = "\n  ".concat(customReportResponseDefault(), "\n  _filters:filters\n");
  var fileResponse = _exports.fileResponse = "\n  id\n  saveName\n  displayName\n  container\n  fileType\n  isUploaded\n  creator { id }\n  dateCreated\n";
  var dataSetResponse = _exports.dataSetResponse = "\n  id\n  name\n  dateCreated\n  dateUpdated\n  creator { id }\n  file { ".concat(fileResponse, " }\n  path\n  container\n  columns {\n    id\n    name\n    position\n    suggestedDataType\n  }\n  dataSetType\n");
  var _default = _exports.default = {
    simpleReportResponseForClusterDetails: simpleReportResponseForClusterDetails,
    pinterestReportResponse: pinterestReportResponse,
    pinterestReportResponseDefault: pinterestReportResponseDefault,
    twitterReportResponse: twitterReportResponse,
    twitterReportResponseDefault: twitterReportResponseDefault,
    instagramReportResponse: instagramReportResponse,
    instagramReportResponseDefault: instagramReportResponseDefault,
    customReportResponse: customReportResponse,
    customReportResponseDefault: customReportResponseDefault,
    standardReport: standardReport,
    taxoRequest: taxoRequest,
    fullTaxoRequest: fullTaxoRequest,
    fileResponse: fileResponse,
    dataSetResponse: dataSetResponse
  };
});