define("affinio/templates/components/crud-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "mdPsG0pP",
    "block": "{\"symbols\":[\"@columns\",\"@editDataSource\",\"@showDeleteSourceModal\",\"@newButtonCopy\",\"@newButtonAction\",\"@searchPlaceholder\",\"@rows\"],\"statements\":[[7,\"header\"],[9],[0,\"\\n  \"],[7,\"label\"],[11,\"class\",\"report-search\"],[9],[0,\"\\n\"],[4,\"if\",[[22,7,[]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"font-awesome\",[\"search\"],null],false],[0,\"\\n      \"],[1,[27,\"input\",null,[[\"type\",\"placeholder\",\"input\",\"value\"],[\"text\",[22,6,[]],[27,\"perform\",[[22,0,[\"textSearch\"]]],null],[22,0,[\"textQuery\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\\n\"],[4,\"if\",[[27,\"and\",[[22,4,[]],[22,5,[]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"button\"],[11,\"class\",\"primary\"],[9],[0,\"\\n      \"],[1,[22,4,[]],false],[0,\"\\n    \"],[3,\"action\",[[22,0,[]],[22,5,[]]]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\\n\"],[4,\"if\",[[27,\"and\",[[22,1,[]],[22,7,[]]],null]],null,{\"statements\":[[0,\"  \"],[5,\"affinio-table\",[],[[\"@rows\",\"@columns\",\"@editDataSource\",\"@showDeleteSourceModal\"],[[22,0,[\"filteredRows\"]],[22,1,[]],[22,2,[]],[22,3,[]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/crud-table.hbs"
    }
  });
});