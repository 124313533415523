define("affinio/templates/components/modals/modal-data-set-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "/HLtsTpI",
    "block": "{\"symbols\":[],\"statements\":[[7,\"form\"],[11,\"class\",\"modal modal-data-set-upload\"],[11,\"tabindex\",\"0\"],[9],[0,\"\\n  \"],[7,\"a\"],[11,\"class\",\"close-modal\"],[11,\"href\",\"#\"],[9],[0,\"×\"],[3,\"action\",[[22,0,[]],\"close\"]],[10],[0,\"\\n  \"],[7,\"h3\"],[11,\"class\",\"modal-sub-nav-header\"],[9],[0,\"Upload Data Set\"],[10],[0,\"\\n\\n\"],[4,\"if\",[[22,0,[\"uploading\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"uploading-message\"],[9],[0,\"\\n      Uploading (\"],[1,[23,[\"fileUpload\",\"progress\"]],false],[0,\"%)\\n      \"],[1,[21,\"loading-spinner\"],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"p\"],[9],[0,\"Please ensure the filename does not contain any spaces. A minimum of 2 columns and 150 rows are required to generate a successful report.\"],[10],[0,\"\\n\\n    \"],[7,\"label\"],[9],[0,\"\\n      \"],[7,\"span\"],[9],[0,\"Optional: Rename the file before upload\"],[10],[0,\"\\n      \"],[1,[27,\"input\",null,[[\"type\",\"value\"],[\"text\",[23,[\"userEnteredName\"]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"footer\"],[9],[0,\"\\n      \"],[7,\"label\"],[11,\"class\",\"button primary\"],[9],[0,\"\\n        \"],[1,[27,\"font-awesome\",[\"file-o\"],null],false],[0,\"\\n        Choose File\\n        \"],[1,[27,\"file-upload\",null,[[\"class\",\"accept\",\"successAction\",\"disabled\",\"parseData\"],[\"data-set-upload\",\".csv\",[27,\"action\",[[22,0,[]],\"addFile\"],null],[22,0,[\"uploading\"]],false]]],false],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"a\"],[11,\"class\",\"button\"],[11,\"role\",\"button\"],[9],[0,\"\\n        Cancel\\n      \"],[3,\"action\",[[22,0,[]],\"close\"]],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"backdrop\"],[11,\"role\",\"button\"],[9],[3,\"action\",[[22,0,[]],\"close\"]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/modals/modal-data-set-upload.hbs"
    }
  });
});