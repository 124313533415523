define("affinio/utils/taxonomies/sales", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.taxonomy = _exports.default = void 0;
  var taxonomy = _exports.taxonomy = {
    id: 'Sales',
    meta: {
      title: 'Sales',
      icon: 'sales.png',
      customDataFetch: true,
      colors: {
        primary: '#00ffff',
        secondary: '#ff00cc'
      },
      alwaysLoadMetrics: {
        blocking: true,
        report: ['numberOfMembers'],
        cluster: ['numberOfMembers']
      }
    },
    schema: [{
      label: 'Overview',
      value: 'overview',
      subcategories: [{
        label: 'Summary',
        value: 'summary',
        modules: [{
          label: 'Audience Details and Source',
          value: 'audience-header',
          dependencies: ['cluster.isAggregate'],
          metrics: [{
            clusterAggregation: 'interests'
          }]
        }, {
          label: 'Audience Visualization',
          value: 'audience-visualization',
          dependencies: ['cluster.isAggregate'],
          metrics: [{
            report: 'clusterSVGURL'
          }],
          description: 'This diagram represents the different clusters within this audience. The lines connecting clusters indicate their shared interests. More lines = more in common.'
        }, {
          label: 'Audience Summary',
          value: 'audience-summary',
          dependencies: ['cluster.isAggregate'],
          metrics: [{
            everyCluster: 'density'
          }
          // { everyCluster: 'numberOfMembers' },
          ]
        }, {
          label: 'Cluster Summaries',
          value: 'cluster-summaries',
          dependencies: ['cluster.isAggregate'],
          description: 'This section provides a consolidated overview of key over-indexing attributes by cluster. Click-through to see more.'
        }, {
          label: 'Traits Dash',
          value: 'traits-dashboard',
          dependencies: ['cluster.isCluster'],
          metrics: [{
            report: 'clusterSVGURL'
          }]
        }, {
          label: 'Top Traits',
          value: 'traits-gallery',
          dependencies: ['cluster.isCluster']
        }, {
          label: 'Cluster Attributes',
          value: 'traits-summary',
          metrics: [{
            everyCluster: 'interests'
          }, {
            everyCluster: 'density'
          }, {
            report: 'imageAnalysis'
          }, {
            everyCluster: 'lurkerPercentage'
          }, {
            everyCluster: 'averagePostsPerMonth'
          }, {
            everyCluster: 'averageAccountAge'
          }, {
            everyCluster: 'averageLinkKarma'
          }, {
            everyCluster: 'averageCommentKarma'
          }, {
            everyCluster: 'averageNumberOfPosts'
          }, {
            everyCluster: 'averagePostsPerMonth'
          }, {
            everyCluster: 'averageNumberOfComments'
          }, {
            everyCluster: 'averagePostsPerMonth'
          }, {
            everyCluster: 'lurkerPercentage'
          }, {
            everyCluster: 'density'
          }, {
            everyCluster: 'interests'
          }],
          dependencies: ['cluster.isCluster']
        }]
      }]
    }, {
      label: 'Sources',
      value: 'sources',
      subcategories: [{
        label: 'Purchases',
        value: 'purchases',
        modules: [
        // {
        //   value: 'metric-visualization',
        //   metrics: [{ everyCluster: 'Ship Date' }],
        //   label: 'Ship Date'
        // },
        {
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Ship Mode'
          }],
          primaryMetric: 'Ship Mode',
          label: 'Ship Mode'
        }, {
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Segment'
          }],
          label: 'Segment',
          primaryMetric: 'Segment'
        },
        // {
        //   value: 'metric-visualization',
        //   metrics: [{ everyCluster: 'Product ID' }],
        //   label: 'Product ID'
        // },
        {
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Category'
          }],
          label: 'Category',
          primaryMetric: 'Category'
        }, {
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Sub-Category'
          }],
          label: 'Sub Category',
          primaryMetric: 'Sub-Category'
        }, {
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Product Name'
          }],
          label: 'Product Name',
          primaryMetric: 'Product Name'
        },
        // {
        //   value: 'metric-visualization',
        //   metrics: [{ everyCluster: 'Sales' }],
        //   label: 'Sales'
        // },
        // {
        //   value: 'metric-visualization',
        //   metrics: [{ everyCluster: 'Quantity' }],
        //   label: 'Quantity'
        // },
        {
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Discount'
          }],
          label: 'Discount',
          primaryMetric: 'Discount'
        }
        // {
        //   value: 'metric-visualization',
        //   metrics: [{ everyCluster: 'Profit' }],
        //   label: 'Profit'
        // },
        ]
      }, {
        label: 'Survey',
        value: 'survey',
        modules: [{
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'City'
          }],
          label: 'City',
          primaryMetric: 'City'
        }, {
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'State'
          }],
          label: 'State',
          primaryMetric: 'State'
        }, {
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Postal Code'
          }],
          label: 'Postal Code',
          primaryMetric: 'Postal Code'
        }, {
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Region'
          }],
          label: 'Region',
          primaryMetric: 'Region'
        }, {
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Residence Type'
          }],
          label: 'Residence Type',
          primaryMetric: 'Residence Type'
        }, {
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Sex'
          }],
          label: 'Gender',
          primaryMetric: 'Sex'
        }, {
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Age'
          }],
          label: 'Age',
          primaryMetric: 'Age'
        }, {
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Marital Status'
          }],
          label: 'Marital Status',
          primaryMetric: 'Marital Status'
        }, {
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Student'
          }],
          label: 'Student',
          primaryMetric: 'Student'
        }, {
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Country of Birth'
          }],
          label: 'Country of Birth',
          primaryMetric: 'Country of Birth'
        }, {
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Health'
          }],
          label: 'Health',
          primaryMetric: 'Health'
        }, {
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Ethnic Group'
          }],
          label: 'Ethnic Group',
          primaryMetric: 'Ethnic Group'
        }, {
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Industry'
          }],
          label: 'Industry',
          primaryMetric: 'Industry'
        }, {
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Hours worked per week'
          }],
          label: 'Hours worked per week',
          primaryMetric: 'Hours worked per week'
        }, {
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Annual Income Range'
          }],
          label: 'Annual Income Range',
          primaryMetric: 'Annual Income Range'
        }]
      }]
    }, {
      label: 'Compare',
      value: 'compare',
      subcategories: [{
        label: 'Compare Metrics',
        value: 'index',
        modules: [{
          label: 'Compare Display',
          value: 'compare-display'
        }]
      }]
    }, {
      label: 'Actions',
      value: 'actions',
      subcategories: [{
        label: 'Export',
        value: 'export',
        modules: [{
          label: 'Audience Export',
          value: 'page-title'
        }, {
          label: 'Data Downloads (.csv)',
          value: 'csv-exporter',
          metrics: [{
            everyCluster: 'interests'
          }]
        }, {
          label: 'Screenshots Export',
          value: 'screenshots-exporter'
        }]
      }]
    }],
    metricDefinitions: [{
      label: 'clusterSVGURL',
      title: 'Network Graph',
      properties: [],
      defaultValue: 'string',
      level: 'report'
    }, {
      label: 'numberOfMembers',
      level: 'cluster',
      properties: [],
      defaultValue: 'number'
    }, {
      label: 'numberOfMembers',
      level: 'report',
      properties: [],
      defaultValue: 'number'
    }, {
      label: 'Order ID',
      defaultValue: 'array',
      properties: ['label', 'value'],
      level: 'cluster',
      traitWeight: 0
    }, {
      label: 'Quantity',
      level: 'cluster',
      defaultValue: 'array',
      properties: ['label', 'value']
    }, {
      label: 'Category',
      level: 'cluster',
      defaultValue: 'array',
      properties: ['label', 'value'],
      comparable: true,
      aggregate: {
        checked: true,
        type: 'instancePercentage',
        instance: 'Office Supplies',
        title: '% Office Supplies'
      }
    }, {
      label: 'Segment',
      level: 'cluster',
      defaultValue: 'array',
      properties: ['label', 'value'],
      aggregate: {
        checked: true,
        type: 'instancePercentage',
        instance: 'Consumer',
        title: '% Consumer'
      },
      comparable: true
    }, {
      label: 'Marital Status',
      level: 'cluster',
      defaultValue: 'array',
      properties: ['label', 'value'],
      // aggregate: { checked: true, type: 'instancePercentage', instance: 'Single', title: '% Single Person' },
      comparable: true
    }, {
      label: 'Residence Type',
      level: 'cluster',
      defaultValue: 'array',
      properties: ['label', 'value'],
      comparable: true
    }, {
      label: 'Ship Mode',
      level: 'cluster',
      defaultValue: 'array',
      properties: ['label', 'value'],
      comparable: true
    }, {
      label: 'Country of Birth',
      level: 'cluster',
      defaultValue: 'array',
      properties: ['label', 'value'],
      comparable: true
    }, {
      label: 'Sales',
      level: 'cluster',
      defaultValue: 'array',
      properties: ['label', 'value']
    }, {
      label: 'Discount',
      level: 'cluster',
      defaultValue: 'array',
      properties: ['label', 'value']
    }, {
      label: 'Country',
      level: 'cluster',
      defaultValue: 'array',
      properties: ['label', 'value']
    }, {
      label: 'Postal Code',
      level: 'cluster',
      defaultValue: 'array',
      properties: ['label', 'value']
    }, {
      label: 'Religion',
      level: 'cluster',
      defaultValue: 'array',
      properties: ['label', 'value'],
      comparable: true
    }, {
      label: 'Region',
      level: 'cluster',
      defaultValue: 'array',
      properties: ['label', 'value'],
      comparable: true
    }, {
      label: 'Product Name',
      level: 'cluster',
      defaultValue: 'array',
      properties: ['label', 'value'],
      comparable: true
    }, {
      label: 'Annual Income Range',
      level: 'cluster',
      defaultValue: 'array',
      properties: ['label', 'value'],
      comparable: true
    }, {
      label: 'Profit',
      level: 'cluster',
      defaultValue: 'array',
      properties: ['label', 'value']
    }, {
      label: 'Health',
      level: 'cluster',
      defaultValue: 'array',
      properties: ['label', 'value'],
      comparable: true
    }, {
      label: 'Industry',
      level: 'cluster',
      defaultValue: 'array',
      properties: ['label', 'value'],
      comparable: true
    }, {
      label: 'Ship Date',
      level: 'cluster',
      defaultValue: 'array',
      properties: ['label', 'value']
    }, {
      label: 'Hours worked per week',
      level: 'cluster',
      defaultValue: 'array',
      properties: ['label', 'value'],
      comparable: true
    }, {
      label: 'Age',
      level: 'cluster',
      defaultValue: 'array',
      properties: ['label', 'value'],
      comparable: true
    }, {
      label: 'Sub-Category',
      level: 'cluster',
      defaultValue: 'array',
      properties: ['label', 'value'],
      comparable: true
    }, {
      label: 'Family Composition',
      level: 'cluster',
      defaultValue: 'array',
      properties: ['label', 'value'],
      comparable: true
    }, {
      label: 'Customer ID',
      level: 'cluster',
      defaultValue: 'array',
      properties: ['label', 'value'],
      traitWeight: 0
    }, {
      label: 'City',
      level: 'cluster',
      defaultValue: 'array',
      properties: ['label', 'value'],
      comparable: true
    }, {
      label: 'Product ID',
      level: 'cluster',
      defaultValue: 'array',
      properties: ['label', 'value']
    }, {
      label: 'Ethnic Group',
      level: 'cluster',
      defaultValue: 'array',
      properties: ['label', 'value'],
      comparable: true
    }, {
      label: 'Row ID',
      level: 'cluster',
      defaultValue: 'array',
      properties: ['label', 'value']
    }, {
      label: 'State',
      level: 'cluster',
      defaultValue: 'array',
      properties: ['label', 'value'],
      comparable: true
      // aggregate: { checked: true, type: 'instancePercentage', instance: 'California', title: '% Californians' }
    }, {
      label: 'Order Date',
      level: 'cluster',
      defaultValue: 'array',
      properties: ['label', 'value']
    }, {
      label: 'Sex',
      level: 'cluster',
      defaultValue: 'array',
      properties: ['label', 'value'],
      comparable: true
    }, {
      label: 'Student',
      level: 'cluster',
      defaultValue: 'array',
      properties: ['label', 'value'],
      comparable: true
    }]
  };
  var _default = _exports.default = {
    taxonomy: taxonomy
  };
});