define("affinio/templates/components/report-builder/auto-suggest-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "aEUnkBpQ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[22,0,[]]]],[0,\"\\n\"],[1,[27,\"auto-suggest\",null,[[\"inputValue\",\"endpoint\",\"network\",\"submitAction\",\"closeAfterSubmit\",\"allowCustomValue\",\"required\",\"fallbackImage\"],[[27,\"get\",[[22,0,[]],[23,[\"moduleSchema\",\"valueKey\"]]],null],[23,[\"endpoint\"]],[27,\"lowercase\",[[23,[\"model\",\"socialNetwork\"]]],null],[27,\"action\",[[22,0,[]],\"runAddActionClosure\"],null],true,[23,[\"allowCustomValue\"]],[23,[\"moduleSchema\",\"required\"]],[23,[\"moduleSchema\",\"fallbackImage\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/report-builder/auto-suggest-input.hbs"
    }
  });
});