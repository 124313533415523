define("affinio/components/file-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.TextField.extend({
    // Element
    classNames: ['file-upload'],
    attributeBindings: ['name', 'accept'],
    type: 'file',
    parseData: true,
    // Services
    tracker: Ember.inject.service(),
    modalManager: Ember.inject.service(),
    // Helpers
    clear: function clear() {
      // This neat little hack, wraps the input in a form, resets the form, and then unwraps it, weird but it works
      /* eslint-disable */
      // jquery .get method
      this.$().wrap('<form>').closest('form').get(0).reset();
      /* eslint-enable */
      this.$().unwrap();
    },
    // Actions passed in
    successAction: function successAction() {
      window.console.warn('Didn\'t pass successAction to file-upload');
    },
    // Lifecycle
    change: function change(evt) {
      var _this = this;
      var input = evt.target;
      var successAction = Ember.get(this, 'successAction');
      var accept = Ember.get(this, 'accept');
      if (input.files && input.files[0]) {
        // Sometimes we want to allow generic accept types (like images)
        // Add them here so we don't pop up the error modal because the
        // mime type will be something like image/png and thus fail this check
        if (accept && accept !== 'image/*') {
          if (!accept.split(',').some(function (n) {
            return input.files[0].name.endsWith(n);
          })) {
            Ember.get(this, 'modalManager').openModal('modals/modal-error', {
              title: 'File Upload Error',
              reason: 'This file was not of the expected type',
              fix: "Try uploading a \"".concat(accept, "\" file.")
            });
            return;
          }
        }
        var reader = new FileReader();
        reader.onload = function (e) {
          successAction({
            fileInfo: input.files[0],
            data: e.target.result
          });
        };
        reader.onerror = function (e) {
          Ember.get(_this, 'tracker').track('file failed to upload', {
            error: JSON.stringify(e)
          });
          Ember.get(_this, 'modalManager').openModal('modals/modal-error', {
            title: 'File Upload Error',
            reason: 'There was an error uploading this file',
            fix: 'Try uploading again and ensure the file is not corrupt.'
          });
          _this.clear();
        };
        if (Ember.get(this, 'parseData')) {
          reader.readAsText(input.files[0]);
        } else {
          successAction({
            fileInfo: input.files[0]
          });
        }
      }
    }
  });
});