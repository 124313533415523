define("affinio/controllers/admin/metrics", ["exports", "affinio/objects/object-promise-proxy", "affinio/utils/constants/date-formats", "affinio/utils/regex", "moment"], function (_exports, _objectPromiseProxy, _dateFormats, _regex, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  var today = (0, _moment.default)().format(_dateFormats.default.longYearDashSeparated);
  var thirtyDaysAgo = (0, _moment.default)(today).add(-30, 'days').format(_dateFormats.default.longYearDashSeparated);
  var _default = _exports.default = Ember.Controller.extend({
    api: Ember.inject.service(),
    user: Ember.inject.service(),
    loading: true,
    tracker: Ember.inject.service(),
    companyNames: Ember.computed('model', function () {
      var isUserAffinio = Ember.get(this, 'user.account.isAffinio');
      return isUserAffinio ? Ember.get(this, 'model.companiesLite').mapBy('name') : [Ember.get(this, 'model')];
    }),
    selectedCompany: Ember.computed('model', function () {
      var isUserAffinio = Ember.get(this, 'user.account.isAffinio');
      return isUserAffinio ? 'Affinio Inc' : Ember.get(this, 'model');
    }),
    startDate: thirtyDaysAgo,
    mockStartDate: thirtyDaysAgo,
    endDate: today,
    mockEndDate: today,
    dateUpdatedTracker: Ember.observer('endDate', 'startDate', function () {
      Ember.get(this, 'tracker').track('metrics date search updated');
    }),
    users: Ember.computed('selectedCompany', 'startDate', 'endDate', function () {
      var _this = this;
      Ember.set(this, 'loading', true); // eslint-disable-line ember/no-side-effects
      var company = Ember.get(this, 'selectedCompany');
      var startDate = Ember.get(this, 'startDate');
      var endDate = Ember.get(this, 'endDate');
      var promise = Ember.get(this, 'api').post('/statsoverview', {
        data: JSON.stringify({
          /* eslint-disable */
          start_day: (0, _moment.default)(startDate).toISOString(),
          end_day: (0, _moment.default)(endDate).toISOString(),
          company_name: company
          /* eslint-enable */
        }),
        contentType: 'application/json; charset=utf-8'
      }).then(function (response) {
        Ember.set(_this, 'loading', false); // eslint-disable-line ember/no-side-effects
        return response;
      });
      return _objectPromiseProxy.default.create({
        promise: promise
      });
    }),
    filteredUsers: Ember.computed('users.content', 'searchValue', function () {
      var _this2 = this;
      var filter = Ember.get(this, 'searchValue');
      if (filter && typeof filter === 'string') {
        filter = filter.toLowerCase();
      }
      var users = Ember.get(this, 'users.content');
      var filtered = filter ? users.filter(function (n) {
        return Ember.get(n, 'name').toLowerCase().indexOf(filter) > -1;
      }) : users;
      filtered.forEach(function (item, i) {
        if (Ember.get(_this2, "toggleReportView".concat(i))) {
          _this2.send('closeReportViewAction', "toggleReportView".concat(i));
        }
      });
      return filtered;
    }),
    actions: {
      timesFinished: function timesFinished(userReports) {
        return userReports.filterBy('state', 'finished').length;
      },
      closeReportViewAction: function closeReportViewAction(action) {
        Ember.set(this, action, false);
      },
      inputFocusIn: function inputFocusIn() {
        Ember.$('.metrics-search').css('box-shadow', '0px 2px 12px -2px rgba(0, 0, 0, 0.4)');
      },
      inputFocusOut: function inputFocusOut() {
        Ember.$('.metrics-search').css('box-shadow', '0px 2px 12px -2px rgba(0, 0, 0, 0.2)');
      },
      exportUserMetrics: function exportUserMetrics() {
        var userData = Ember.get(this, 'users.content');
        var maxLengthGroup = _.max(userData.mapBy('groups.length'));
        var company = Ember.get(this, 'selectedCompany');
        var startDate = Ember.get(this, 'startDate');
        var endDate = Ember.get(this, 'endDate');
        var totalFinished = userData.reduce(function (a, b) {
          return a + b.reports.filterBy('state', 'finished').length;
        }, 0);
        /* eslint-disable */
        var headers = ['Name', 'Email', 'Reports Created', 'Start Date', 'End Date', 'Report Name', 'Report ID', 'Network', 'Members', 'Date Created', 'Folder name'].concat(_toConsumableArray(_toConsumableArray(Array(maxLengthGroup)).map(function (_, i) {
          return "Group ".concat(i + 1);
        })), ['Monitored', 'Monitored Updates', "Status (".concat(totalFinished, ")")]).join(',') + '\n';
        var isValid = function isValid(value) {
          return value ? value : 0;
        };
        var cleanString = function cleanString(data) {
          ;
          return data ? data.replace(_regex.doubleQuoteRegex, '\'').replace(_regex.commaRegex, ' ').replace(_regex.lineBreakRegex, ' ').replace(_regex.nonWordNonWhitespaceRegex, '') : '';
        };
        var rows = userData.map(function (user) {
          var finalRow = '';
          var name = user.name,
            email = user.email,
            reports = user.reports,
            reportsCreated = user.reportsCreated,
            startDate = user.startDate,
            endDate = user.endDate;
          name = cleanString(name);
          var row = "".concat(name, ",").concat(email, ",").concat(reportsCreated, ",").concat((0, _moment.default)(startDate).format(_dateFormats.default.longYearDashSeparated), ",").concat((0, _moment.default)(endDate).format(_dateFormats.default.longYearDashSeparated));
          if (reportsCreated) {
            var reportList = reports.map(function (r) {
              var reportName = r.name,
                id = r.id,
                socialNetwork = r.socialNetwork,
                numberOfMembers = r.numberOfMembers,
                dateCreated = r.dateCreated,
                folder = r.folder,
                isMonitored = r.isMonitored,
                monitoredUpdates = r.monitoredUpdates,
                state = r.state;
              reportName = cleanString(reportName);
              folder = cleanString(folder);
              isMonitored = isMonitored ? 'YES' : 'NO';
              var groupString = '';
              for (var i = 0; i < maxLengthGroup; i++) {
                if (i < user.groups.length) {
                  groupString += user.groups[i].replace(_regex.doubleQuoteRegex, '\'').replace(_regex.commaRegex, ' ').replace(_regex.lineBreakRegex, ' ').replace(_regex.nonWordNonWhitespaceRegex, '');
                  groupString += ',';
                } else {
                  groupString += ',';
                }
              }
              return "".concat(row, ",").concat(reportName, ",").concat(id, ",").concat(socialNetwork, ",").concat(numberOfMembers, ",").concat((0, _moment.default)(dateCreated).format(_dateFormats.default.longYearDashSeparated), ",").concat(folder ? folder : 'N/A', ",").concat(groupString).concat(isMonitored, ",").concat(monitoredUpdates, ",").concat(state === 'almost_finished' ? 'pending' : state);
            }).join('\n');
            finalRow = "".concat(reportList);
          } else {
            finalRow = row;
          }
          return finalRow;
        }).join('\n');
        var csv = headers + rows;
        Ember.get(this, 'tracker').track('Export Company Metrics');
        var encodedUri = "data:text/csv;base64," + window.btoa(window.unescape(encodeURIComponent(csv)));
        var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
        var isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);
        if (isChrome || isSafari) {
          var link = document.createElement("a");
          link.setAttribute("href", encodedUri);
          link.setAttribute("download", company + " Stats " + startDate + " - " + endDate + ".csv");
          link.click(); // This will download the data file
        } else {
          window.open(encodedUri);
        }
        /* eslint-enable */
      }
    }
  });
});