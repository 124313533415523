define("affinio/templates/components/bar-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "J8JyEHlM",
    "block": "{\"symbols\":[\"record\",\"number\",\"record\"],\"statements\":[[7,\"section\"],[9],[0,\"\\n\"],[4,\"each\",[[27,\"range\",[0,[27,\"get\",[[23,[\"processedRecords\"]],\"length\"],null]],null]],null,{\"statements\":[[4,\"with\",[[27,\"object-at\",[[22,2,[]],[23,[\"processedRecords\"]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"span\"],[12,\"style\",[22,3,[\"style\"]]],[12,\"class\",[27,\"if\",[[22,3,[\"highlight\"]],\"highlight\"],null]],[9],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[3]},null]],\"parameters\":[2]},null],[10],[0,\"\\n\"],[7,\"ul\"],[11,\"class\",\"labels\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"processedRecords\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\"],[12,\"class\",[27,\"if\",[[22,1,[\"highlight\"]],\"highlight\"],null]],[9],[0,\"\\n      \"],[7,\"span\"],[9],[1,[22,1,[\"label\"]],false],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/bar-chart.hbs"
    }
  });
});