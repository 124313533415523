define("affinio/templates/components/loading-animation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Rf/j7wIO",
    "block": "{\"symbols\":[],\"statements\":[[7,\"g\"],[11,\"class\",\"loader-nodes\"],[9],[10],[0,\"\\n\"],[7,\"g\"],[11,\"transform\",\"matrix(0.32331412,0,0,0.32331412,304.48009,235.90646)\"],[11,\"class\",\"loader-filter\"],[9],[0,\"\\n  \"],[7,\"polygon\"],[11,\"style\",\"fill:#c9c8c8\"],[11,\"points\",\"349.8,448.4 245.9,448.4 0,238.9 612,238.9 \"],[9],[10],[0,\"\\n  \"],[7,\"rect\"],[11,\"style\",\"fill:#333333\"],[11,\"height\",\"104.7\"],[11,\"width\",\"150.7\"],[11,\"y\",\"429.39999\"],[11,\"x\",\"222.89999\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/loading-animation.hbs"
    }
  });
});