define("affinio/templates/aton-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "F/YnhYk+",
    "block": "{\"symbols\":[],\"statements\":[[1,[21,\"top-level-nav\"],false],[0,\"\\n\\n\"],[7,\"section\"],[11,\"id\",\"overview\"],[11,\"class\",\"clearfix\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"loading-spinner-center-wrapper\"],[9],[0,\"\\n    \"],[7,\"p\"],[9],[0,\"Please wait while we load your data\"],[10],[0,\"\\n    \"],[1,[21,\"loading-spinner\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/aton-loading.hbs"
    }
  });
});