define("affinio/models/reports/twitter", ["exports", "ember-data", "ember-copy", "affinio/models/reports/-base", "affinio/utils/get-with-default", "affinio/mixins/models/lurker-percentage"], function (_exports, _emberData, _emberCopy, _base, _getWithDefault, _lurkerPercentage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  var attr = _emberData.default.attr;
  var hasMany = _emberData.default.hasMany;
  var belongsTo = _emberData.default.belongsTo;
  var assign = window.Object.assign;
  var _default = _exports.default = _base.default.extend(_lurkerPercentage.default, {
    path: Ember.inject.service(),
    user: Ember.inject.service(),
    _clusters: hasMany('clusters/twitter'),
    clusters: Ember.computed('_clusters.@each.{isClusterRemoved,mergedInto}', function () {
      return Ember.get(this, '_clusters').rejectBy('isClusterRemoved').rejectBy('mergedInto');
    }),
    clusterAggregation: belongsTo('clusters/twitter'),
    name: attr('string'),
    sharedURLs: Ember.computed('filters', function () {
      if (!Ember.get(this, 'filters')) {
        return false;
      }
      var gnipQuery = Ember.get(this, 'filters').contentQuery;
      if (gnipQuery) {
        if (gnipQuery.indexOf('url_contains:') > -1) {
          return gnipQuery && gnipQuery.indexOf('url_contains:') > -1 && gnipQuery.split('url_contains:').filter(function (url) {
            return url;
          }).map(function (url) {
            return url.replace(' OR ', '').replace(/"/g, '');
          });
        } else if (gnipQuery.indexOf('url:') > -1) {
          return gnipQuery && gnipQuery.indexOf('url:') > -1 && gnipQuery.split('url:').filter(function (url) {
            return url;
          }).map(function (url) {
            return url.replace(' OR ', '').replace(/"/g, '');
          });
        }
      }
    }),
    isURLShare: Ember.computed.notEmpty('sharedURLs'),
    isNotURLShare: Ember.computed.not('isURLShare'),
    monitoredInformation: attr(),
    hasEditedDate: Ember.computed.or('filters.contentFrom', 'filters.contentTo'),
    notHasEditedDate: Ember.computed.not('hasEditedDate'),
    newFollowersOnly: Ember.computed.and('filters.followersStartDate', 'filters.followersEndDate'),
    notNewFollowersOnly: Ember.computed.not('newFollowersOnly'),
    notQueryBased: Ember.computed.not('filters.queryID'),
    // TODO make actual checks
    hasRankedDeltas: true,
    hasLocations: true,
    hasPopular: true,
    hasEngagement: true,
    hasSampleStream: true,
    hasLikes: true,
    hasLinks: true,
    visitedSites: true,
    hasMedia: true,
    hasImageCategories: true,
    hasDomains: true,
    hasExplore: true,
    hasVerifiedPercentages: Ember.computed('clusters', function () {
      return Ember.get(this, 'clusters').every(function (cluster) {
        return cluster.verifiedMembersPercentage;
      });
    }),
    hasLocationInterests: Ember.computed('filters', 'dateCreated', function () {
      return Ember.get(this, 'filters.bioLocations.length') && Ember.get(this, 'dateCreated') > new Date(2017, 10, 14);
    }),
    imageCategories: Ember.computed('imageAnalysis.loaded', function () {
      var _this = this;
      if (!Ember.get(this, 'imageAnalysis.loaded')) {
        return [];
      }
      var images = Ember.get(this, 'imageAnalysis.response');
      if (!images || !images.length) {
        return [];
      }
      var groupedImages = _.groupBy(images, 'imageAnalysisCluster');
      groupedImages = Object.keys(groupedImages).map(function (k) {
        return {
          cluster: k,
          images: _.shuffle(groupedImages[k])
        };
      }).sortBy('images.length').reverse();
      groupedImages = groupedImages.filter(function (cat) {
        return +cat.cluster > -1;
      });
      groupedImages.forEach(function (cat) {
        var hashtags = _.countBy(_.flatten(cat.images.mapBy('hashtags')));
        hashtags = Object.keys(hashtags).map(function (k) {
          return {
            label: k,
            value: hashtags[k],
            icon: 'hashtag',
            type: 'hashtag'
          };
        }).sortBy('value').reverse();
        var labels = _.countBy(_.flatten(cat.images.mapBy('labels')));
        labels = Object.keys(labels).map(function (k) {
          return {
            label: k,
            value: labels[k],
            icon: 'eye',
            type: 'label'
          };
        }).sortBy('value').reverse().map(function (label) {
          label.records = Ember.get(_this, 'clusters').map(function (cluster) {
            var value = _.countBy(cat.images, 'sourceCluster')[Number(Ember.get(cluster, 'suffix'))];
            return {
              value: value ? value : 0,
              cluster: "".concat(Ember.get(cluster, 'suffix'))
            };
          });
          return label;
        });
        var terms = hashtags.concat(labels).sortBy('value').reverse();
        // console.log(terms);
        cat.labels = labels;
        cat.hashtags = hashtags;
        cat.terms = Ember.A(terms).slice(0, 25);
        cat.combo = hashtags.slice(0, 3).mapBy('label').join(', ');
        cat.general = cat.images.length > 100 ? true : false;
      }); // forEach

      // Calculate relativeSize as proportional to the largest number of images among categories.
      // This will be used to size them.
      var max = _.max(groupedImages, function (cat) {
        return cat.images.length;
      }).images.length;
      var logger = d3.scale.log().domain([1, 100, 200]);
      groupedImages.forEach(function (cat) {
        if (cat.cluster !== 'all') {
          var relativeSize = cat.images.length / max;
          relativeSize = logger(relativeSize * 60 + 1);
          // console.log('b4', relativeSize);
          var count = _.countBy(cat.images, 'sourceCluster');
          var records = Ember.get(_this, 'clusters').map(function (cluster) {
            return {
              value: count[Ember.get(cluster, 'suffix')] ? count[Ember.get(cluster, 'suffix')] : 0,
              cluster: "".concat(Ember.get(cluster, 'suffix'))
            };
          });
          cat.records = records;
          var value = cat.images.length / images.length * 100;
          cat.value = value > 0.01 ? value : 0.01;
          relativeSize = 0.1 * Math.round(relativeSize / 0.1); // round to nearest .1, regardless of size
          // console.log('after', relativeSize);
          // cat.images = cat.images.slice(0,150);
          cat.relativeSize = relativeSize;
          cat.images.forEach(function (image) {
            // image.clusterName = get(this, 'clusters').findBy('id', get(this, 'id')+'_'+ image.sourceCluster).get('name');
            var records = Ember.get(_this, 'clusters').map(function (cluster) {
              return {
                cluster: Ember.get(cluster, 'suffix'),
                value: image.sourceCluster === Ember.get(cluster, 'suffix') ? 1 : 0
              };
            });
            // console.log('cat records', records);
            Ember.set(image, 'records', records); // eslint-disable-line ember/no-side-effects
            // image.clusterName = get(this, 'clustersLite').find((cluster)=>{
            //   return cluster.id === `${get(this, 'id')}_${image.sourceCluster}`;
            // }).name;
          });
        }
      }); // forEach
      groupedImages.push({
        cluster: 'all',
        images: groupedImages.map(function (cat, i) {
          var coverImage = (0, _emberCopy.copy)(cat.images[Math.floor(Math.random() * cat.images.length - 1) + 1]);
          coverImage.relativeSize = groupedImages[i].relativeSize;
          coverImage.size = cat.images.length;
          coverImage.general = cat.images.length > 100 ? true : false;
          coverImage.topLabels = cat.labels.slice(0, 3);
          coverImage.labels = cat.labels;
          var count = _.countBy(cat.images, 'sourceCluster');
          // console.log('count', count, _.values(count).reduce((num,memo)=> num + memo));
          var records = Ember.get(_this, 'clusters').map(function (cluster) {
            return {
              value: count[Ember.get(cluster, 'suffix')] ? count[Ember.get(cluster, 'suffix')] / _.values(count).reduce(function (num, memo) {
                return num + memo;
              }) * 100 : 0,
              cluster: "".concat(Ember.get(cluster, 'suffix'))
            };
          });
          // console.log('all records', records);
          Ember.set(coverImage, 'records', records); // eslint-disable-line ember/no-side-effects
          return coverImage;
        })
      });
      return groupedImages;
    }),
    gains: Ember.computed('versions', 'clusterNumberOfMembers.@each.loaded', function () {
      var numberOfMembersLoaded = Ember.get(this, 'clusterNumberOfMembers').every(function (n) {
        return Ember.get(n, 'loaded');
      });
      if (numberOfMembersLoaded && Ember.get(this, 'versions.length') > 0) {
        var clusters = Ember.get(this, 'clusters');
        var versionObjects = Ember.get(this, 'versions');
        var version0Object = versionObjects.findBy('version', 0);
        var latestVersion = Ember.get(this, 'version');
        var xAxis = ['x'];
        var allData = {
          cumulativeSize: ['all'],
          dailyGrowth: ['all'],
          percentChange: ['all']
        };
        var clusterDataHash = {
          dailyGrowth: {},
          cumulativeSize: {},
          percentChange: {}
        };
        var previousVersion;
        var startAggregateSize = version0Object.numberOfMembers;
        var startClusterSizes = {};

        // prep cluster hash object with keys
        var _loop = function _loop(type) {
          clusters.forEach(function (cluster) {
            clusterDataHash[type][cluster.id] = [cluster.id];
            startClusterSizes[cluster.id] = version0Object.clusters.findBy('id', cluster.id).numberOfMembers;
          });
        };
        for (var type in clusterDataHash) {
          _loop(type);
        }

        // Get data for all previous versions
        var _loop2 = function _loop2() {
          var version = versionObjects.findBy('version', i);
          previousVersion = i > 0 ? versionObjects.findBy('version', i - 1) : null;
          xAxis.push(new Date(version.dateFinished || version.dateUpdated).getTime());
          // Report level
          allData.cumulativeSize.push(version.numberOfMembers);
          allData.dailyGrowth.push(previousVersion ? version.numberOfMembers - previousVersion.numberOfMembers : 0);
          allData.percentChange.push((version.numberOfMembers / startAggregateSize * 100 - 100).toFixed(2));

          // Cluster level
          clusters.forEach(function (cluster) {
            var thisVersionCluster = version.clusters.findBy('id', cluster.id);
            var previousVersionCluster = previousVersion ? previousVersion.clusters.findBy('id', cluster.id) : null;
            for (var _type2 in clusterDataHash) {
              switch (_type2) {
                case 'percentChange':
                  clusterDataHash[_type2][cluster.id].push((thisVersionCluster.numberOfMembers / startClusterSizes[cluster.id] * 100 - 100).toFixed(2));
                  break;
                case 'dailyGrowth':
                  clusterDataHash[_type2][cluster.id].push(previousVersionCluster ? thisVersionCluster.numberOfMembers - previousVersionCluster.numberOfMembers : 0);
                  break;
                case 'cumulativeSize':
                  clusterDataHash[_type2][cluster.id].push(thisVersionCluster.numberOfMembers);
                  break;
              } // switch
            } // for type in clusterDataHash
          });
        };
        for (var i = 0; i < latestVersion; i++) {
          _loop2();
        }

        // Get data for most recent version
        {
          xAxis.push(new Date(Ember.get(this, 'dateFinished')).getTime());
          // Report level
          previousVersion = versionObjects.findBy('version', latestVersion - 1);
          allData.cumulativeSize.push(Ember.get(this, 'numberOfMembers.response.value'));
          allData.dailyGrowth.push(previousVersion ? Ember.get(this, 'numberOfMembers.response.value') - previousVersion.numberOfMembers : 0);
          allData.percentChange.push((Ember.get(this, 'numberOfMembers.response.value') / startAggregateSize * 100 - 100).toFixed(2));
          // Cluster level
          clusters.forEach(function (cluster) {
            var previousVersionCluster = previousVersion ? previousVersion.clusters.findBy('id', cluster.id) : null;
            for (var _type in clusterDataHash) {
              switch (_type) {
                case 'percentChange':
                  clusterDataHash[_type][cluster.id].push((Ember.get(cluster, 'numberOfMembers.response.value') / startClusterSizes[cluster.id] * 100 - 100).toFixed(2));
                  break;
                case 'dailyGrowth':
                  clusterDataHash[_type][cluster.id].push(previousVersionCluster ? Ember.get(cluster, 'numberOfMembers.response.value') - previousVersionCluster.numberOfMembers : 0);
                  break;
                case 'cumulativeSize':
                  clusterDataHash[_type][cluster.id].push(Ember.get(cluster, 'numberOfMembers.response.value'));
                  break;
              } // switch
            } // for type in clusterDataHash
          });
        }

        // spread the data in the object that gets used by the timeseries graph
        var growthDataHash = {
          dailyGrowth: [xAxis, allData.dailyGrowth].concat(_toConsumableArray(clusters.map(function (cluster) {
            return clusterDataHash.dailyGrowth[cluster.id];
          }))),
          cumulativeSize: [xAxis, allData.cumulativeSize].concat(_toConsumableArray(clusters.map(function (cluster) {
            return clusterDataHash.cumulativeSize[cluster.id];
          }))),
          percentChange: [xAxis, allData.percentChange].concat(_toConsumableArray(clusters.map(function (cluster) {
            return clusterDataHash.percentChange[cluster.id];
          })))
        };

        // console.log(clusterDataHash, growthDataHash);
        return growthDataHash;
      } // if !gains
      else if (Ember.get(this, 'versions.length') > 0) {
        return {
          cumulativeSize: [],
          dailyGrowth: [],
          percentChange: []
        };
      }
    }),
    allTheBenchmarkGroups: Ember.computed.mapBy('allClusters', 'benchmarkGroups'),
    // TODO I suspect this isn't refiring on data load - JK
    benchmarkGroupsFullMembersHash: Ember.computed('allTheBenchmarkGroups.@each.loaded', function () {
      var benchmarkGroupsFullMembersHash = {};
      var allClusters = _.flatten(Ember.get(this, 'allClusters').mapBy('benchmarkGroups.response'));

      // This is getWithDefault because if open the share interface before loading a report
      // as the metrics will not be set and this return undefined
      // for some reason this cp is being called and breaking the share interface

      // when we started allowing null data to come thru, this made the response be
      // { value: [] } which breaks this logic
      // some logic is added here to support this
      var aggBenchmarks = (0, _getWithDefault.default)(this, 'clusterAggregation.benchmarkGroups.response', []);
      if (_typeof(aggBenchmarks) === 'object' && aggBenchmarks.hasOwnProperty('value') && !aggBenchmarks.value) {
        aggBenchmarks = [];
      }
      aggBenchmarks.forEach(function (group) {
        benchmarkGroupsFullMembersHash[group.id] = {
          fullMembers: _.max(allClusters.filterBy('id', group.id), function (n) {
            return n.members.length;
          }).members
        };
      });
      return benchmarkGroupsFullMembersHash;
    }),
    // TODO I suspect this isn't refiring on data load - JK
    everyClusterBenchmarkGroups: Ember.computed('allTheBenchmarkGroups.@each.loaded', function () {
      var _this2 = this;
      Ember.get(this, 'allTheBenchmarkGroups');
      var everyClusterBenchmarkGroupHash = {};
      var benchmarkGroupsFullMembersHash = Ember.get(this, 'benchmarkGroupsFullMembersHash');
      var median = function median(arr) {
        // https://stackoverflow.com/a/39639518
        if (Ember.get(arr, 'length') > 1) {
          arr = arr.sort(function (a, b) {
            return a - b;
          });
          var i = arr.length / 2;
          return i % 1 === 0 ? (arr[i - 1] + arr[i]) / 2 : arr[Math.floor(i)];
        } else if (Ember.get(arr, 'length') === 1) {
          return arr[0];
        } else {
          return 0;
        }
      };
      var mean = function mean(members, metric) {
        return members.mapBy(metric).compact() // boiled for safety
        .reduce(function (m, n) {
          return m + n;
        }, 0) / Ember.get(members, 'length') || 0;
      };
      var createBenchmarkGroupObject = function createBenchmarkGroupObject(members, benchmark, clusterid) {
        return Ember.Object.create({
          id: clusterid,
          benchmark: benchmark,
          averageAffinity: median(members.map(function (member) {
            return Ember.get(member, 'affinity.value') || 0;
          })),
          averageRelevance: mean(members, 'relevance'),
          averageLocationAffinity: median(members.map(function (member) {
            return Ember.get(member, 'locationAffinity.value') || 0;
          })),
          averageLocationRelevance: mean(members, 'locationRelevance')
        });
      };
      // Get all groups and put their id as a key in object with averages per cluster
      // This is getWithDefault because if open the share interface before loading a report
      // as the metrics will not be set and this return undefined
      // for some reason this cp is being called and breaking the share interface

      // when we started allowing null data to come thru, this made the response be
      // { value: [] } which breaks this logic
      // some logic is added here to support this
      var aggBenchmarks = (0, _getWithDefault.default)(this, 'clusterAggregation.benchmarkGroups.response', []);
      if (_typeof(aggBenchmarks) === 'object' && aggBenchmarks.hasOwnProperty('value') && !aggBenchmarks.value) {
        aggBenchmarks = [];
      }
      aggBenchmarks.rejectBy('members.length', 0).mapBy('id').compact().forEach(function (benchmarkGroupId) {
        everyClusterBenchmarkGroupHash[benchmarkGroupId] = Ember.get(_this2, 'allClusters').map(function (cluster) {
          var benchmark = Ember.get(cluster, 'benchmarkGroups.response').findBy('id', benchmarkGroupId);
          if (!benchmark) {
            benchmark = {
              name: Ember.get(Ember.get(_this2, 'clusterAggregation.benchmarkGroups.response').findBy('id', benchmarkGroupId), 'name'),
              id: benchmarkGroupId,
              members: []
            };
          }
          var members = Ember.get(benchmark, 'members');
          // Fill in any missing members so they are properly counted as 0s
          // TODO this should be done on backend at RG time ch8478
          var maxMembersGroup = benchmarkGroupsFullMembersHash[benchmarkGroupId].fullMembers;
          if (members.length !== maxMembersGroup.length) {
            var memberNames = members.mapBy('name');
            maxMembersGroup.filter(function (n) {
              return !memberNames.includes(n.name);
            }).forEach(function (missingMember) {
              var copy = assign({}, missingMember, {
                affinity: {
                  value: 0,
                  isInfinite: false
                },
                relevance: 0,
                isDummy: true
              });
              members.push(copy);
            });
          }
          // compare against allBenchMarkMember groups and add any dummy//
          return createBenchmarkGroupObject(members, benchmark, Ember.get(cluster, 'id'));
        });
      });
      // get every member from every group, uniqify
      everyClusterBenchmarkGroupHash['all-members'] = Ember.get(this, 'allClusters').map(function (cluster) {
        // when we started allowing null data to come thru, this made the response be
        // { value: [] } which breaks this logic
        // some logic is added here to support this
        var clusterbenchmarks = (0, _getWithDefault.default)(cluster, 'benchmarkGroups.response', []);
        if (_typeof(clusterbenchmarks) === 'object' && clusterbenchmarks.hasOwnProperty('value') && !clusterbenchmarks.value) {
          clusterbenchmarks = [];
        }
        var members = _.flatten(clusterbenchmarks.mapBy('members')).uniqBy('screenName');
        var benchmark = {
          id: 'all-members',
          members: members
        };
        return createBenchmarkGroupObject(members, benchmark, Ember.get(cluster, 'id'));
      });
      everyClusterBenchmarkGroupHash['all-groups'] = Ember.get(this, 'allClusters').map(function (cluster) {
        var clusterbenchmarks = (0, _getWithDefault.default)(cluster, 'benchmarkGroups.response', []);
        if (_typeof(clusterbenchmarks) === 'object' && clusterbenchmarks.hasOwnProperty('value') && !clusterbenchmarks.value) {
          clusterbenchmarks = [];
        }
        var members = clusterbenchmarks.rejectBy('members.length', 0).map(function (group) {
          var ret = {
            name: Ember.get(group, 'name'),
            label: Ember.get(group, 'name'),
            screenName: Ember.get(group, 'name'),
            affinity: {
              value: Ember.get(group, 'members').map(function (member) {
                return Ember.get(member, 'affinity.value') || 0;
              }).reduce(function (m, n) {
                return m + n;
              }) / Ember.get(group, 'members.length')
            },
            relevance: Ember.get(group, 'members').map(function (member) {
              return Ember.get(member, 'relevance') || 0;
            }).reduce(function (m, n) {
              return m + n;
            }) / Ember.get(group, 'members.length')
          };
          if (Ember.get(_this2, 'hasLocationInterests')) {
            ret.locationAffinity = {
              value: Ember.get(group, 'members').map(function (member) {
                return Ember.get(member, 'locationAffinity.value') || 0;
              }).reduce(function (m, n) {
                return m + n;
              }) / Ember.get(group, 'members.length')
            };
            ret.locationRelevance = Ember.get(group, 'members').map(function (member) {
              return Ember.get(member, 'locationRelevance') || 0;
            }).reduce(function (m, n) {
              return m + n;
            }) / Ember.get(group, 'members.length');
          }
          return ret;
        });
        var benchmark = {
          id: 'all-groups',
          members: members
        };
        return createBenchmarkGroupObject(members, benchmark, Ember.get(cluster, 'id'));
      });
      // console.log('everyClusterBenchmarkGroupHash', everyClusterBenchmarkGroupHash);
      return everyClusterBenchmarkGroupHash;
    })
  });
});