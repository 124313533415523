define("affinio/components/modules/scatter-plot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  var _default = _exports.default = Ember.Component.extend({
    // Element
    classNames: ['scatter-plot'],
    tagName: 'article',
    path: Ember.inject.service(),
    label: Ember.computed.alias('model.cluster'),
    cluster: Ember.computed.alias('model.cluster'),
    report: Ember.computed.alias('model.report'),
    visualizationProperties: Ember.computed('visualizationPropertiesKey', 'moduleRequest.moduleProperties.visualizationProperties', function () {
      var props = Ember.get(this, 'moduleRequest.moduleProperties.visualizationProperties').findBy('label', Ember.get(this, 'visualizationPropertiesKey')) || Ember.get(this, 'moduleRequest.moduleProperties.visualizationProperties').objectAt(0);
      this.send('updateTitle', Ember.get(props, 'label'));
      return props;
    }),
    columns: Ember.computed('visualizationProperties', 'model.cluster', function () {
      var _ref;
      var model = Ember.get(this, 'model');
      var visualizationProperties = Ember.get(this, 'visualizationProperties') || Ember.get(this, 'moduleRequest.moduleProperties.visualizationProperties').objectAt(0);
      var columns = Ember.get(model, Ember.get(visualizationProperties, 'columns'));
      if (Ember.get(visualizationProperties, 'mapBy')) {
        // Handle the case when a single cluster or node is being iter'd over
        if (!Ember.isArray(columns)) {
          columns = [columns];
        }
        columns = columns.map(function (column) {
          return {
            label: Ember.get(column, 'name') || Ember.get(column, 'title') || Ember.get(column, 'label'),
            color: Ember.get(column, 'color') || '#333333',
            data: Ember.get(column, Ember.get(visualizationProperties, 'mapBy')),
            beep: 'boop'
          };
        });
      } else {
        columns = columns.map(function (column) {
          var datum = {};
          datum[Ember.get(visualizationProperties, 'xAxis')] = Ember.get(Ember.get(column, Ember.get(visualizationProperties, 'xAxis')), 'response.value');
          datum[Ember.get(visualizationProperties, 'yAxis')] = Ember.get(Ember.get(column, Ember.get(visualizationProperties, 'yAxis')), 'response.value');
          datum[Ember.get(visualizationProperties, 'radius')] = Ember.get(Ember.get(column, Ember.get(visualizationProperties, 'radius')), 'response.value');
          return {
            label: Ember.get(column, 'name') || Ember.get(column, 'title') || Ember.get(column, 'label'),
            color: Ember.get(column, 'color') || '#333333',
            data: [datum]
          };
        });
      }
      var masterList = (_ref = []).concat.apply(_ref, _toConsumableArray(columns.mapBy('data'))).uniqBy('label');
      columns.forEach(function (column) {
        Ember.set(column, 'data', masterList.map(function (instance) {
          // eslint-disable-line ember/no-side-effects
          var found = column.data.findBy('label', Ember.get(instance, 'label'));
          if (found) {
            return found;
          } else {
            var ret = {};
            Object.keys(instance).forEach(function (key) {
              ret[key] = 0;
            });
            ret.label = Ember.get(instance, 'label');
            return ret;
          }
        }));
      });
      return columns;
    }),
    chartOptions: Ember.computed('visualizationProperties', function () {
      return {
        height: 545,
        maintainAspectRatio: false,
        legend: {
          position: 'top'
        },
        tooltips: {
          callbacks: {
            label: function label(datum, data) {
              var datasets = Ember.get(data, 'datasets');
              var foundDatum = Ember.get(datasets[Ember.get(datum, 'datasetIndex')], 'data')[Ember.get(datum, 'index')];
              return ["".concat(Ember.get(foundDatum, 'title')), "".concat(Ember.get(foundDatum, 'yLabel'), " : ").concat(Ember.get(foundDatum, 'y').toFixed(2)), "".concat(Ember.get(foundDatum, 'xLabel'), " : ").concat(Ember.get(foundDatum, 'x').toFixed(2)), "".concat(Ember.get(foundDatum, 'rLabel'), " : ").concat(Ember.get(foundDatum, 'rawRValue').toFixed(2))];
            }
          }
        }
      };
    }),
    chartData: Ember.computed('model.cluster', 'visualizationProperties', function () {
      var columns = Ember.get(this, 'columns');
      var vizProps = Ember.get(this, 'visualizationProperties');
      // console.log('vizProps', vizProps)
      var radii = [1];
      if (Ember.get(vizProps, 'radius')) {
        radii = columns.mapBy('data').flatten().mapBy(Ember.get(vizProps, 'radius'));
      }
      var radiusScale = d3.scale.linear().domain([_.min(radii), _.max(radii) + 0.01]).range([5, 30]);
      var datasets = {
        datasets: columns.map(function (column) {
          return {
            label: Ember.get(column, 'label'),
            backgroundColor: "rgba(".concat([].concat(_toConsumableArray(Object.values(d3.rgb(Ember.get(column, 'color')))), [0.5]).toString(), ")"),
            data: Ember.get(column, 'data').map(function (datum) {
              var rawR = Ember.get(vizProps, 'radius') ? Ember.get(datum, Ember.get(vizProps, 'radius')) : 0;
              return {
                x: Ember.get(datum, Ember.get(vizProps, 'xAxis')),
                y: Ember.get(datum, Ember.get(vizProps, 'yAxis')),
                r: Ember.get(vizProps, 'radius') ? radiusScale(Ember.get(datum, Ember.get(vizProps, 'radius'))) : 5,
                title: Ember.get(datum, 'label') || Ember.get(column, 'label'),
                xLabel: Ember.get(vizProps, 'xAxis'),
                yLabel: Ember.get(vizProps, 'yAxis'),
                rLabel: Ember.get(vizProps, 'radius'),
                rawRValue: rawR
              };
            })
          };
        })
      };
      return datasets;
    }),
    actions: {
      updateTitle: function updateTitle(title) {
        var _this = this;
        Ember.run.scheduleOnce('afterRender', this, function () {
          Ember.set(_this, 'moduleRequest.label', title);
        });
      }
    }
  });
});