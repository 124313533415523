define("affinio/utils/report-builder/twitter/bio-add-action", ["exports", "affinio/utils/regex"], function (_exports, _regex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = function _default(inputController, model) {
    var valueKey = Ember.get(inputController, 'moduleSchema.valueKey');
    var filterKey = Ember.get(inputController, 'moduleSchema.requiredFilters.0'); // assumes only one
    // TODO we really need to clean this up at a higher level, so all add actions can be genericized
    // and not depend on requiredFilters (in the case where a filter is optional)
    if (!filterKey) {
      filterKey = Ember.get(inputController, 'moduleSchema.modelFilterKey');
    }
    var excludedFilterKey = "excluded".concat(filterKey.capitalize());

    // if model doesn't have these filters already, set an array
    var existingBio = Ember.get(model, "filters.".concat(filterKey));
    var existingExcludedBio = Ember.get(model, "filters.".concat(excludedFilterKey));
    if (!existingBio) {
      Ember.set(model, "filters.".concat(filterKey), []);
    }
    if (!existingExcludedBio) {
      Ember.set(model, "filters.".concat(excludedFilterKey), []);
    }

    // Get input and replace curly quotes
    var inputValue = Ember.get(inputController, valueKey).replace(_regex.curlyDoubleQuoteRegex, '"').replace(_regex.curlySingleQuoteRegex, '\'');
    var bios = [];

    // handle quoted ones directly
    var matches = inputValue.match(_regex.quotedContent);
    var brackets = inputValue.match(_regex.bracketRegex);
    if (matches && !brackets) {
      matches.forEach(function (match) {
        bios.push("\"".concat(match.replace(_regex.doubleQuoteRegex, '').replace(_regex.singleQuoteRegex, '').trim(), "\""));
        inputValue = inputValue.replace("".concat(match, ","), ''); // incase it was multiple
        inputValue = inputValue.replace(match, ''); // incase it was a single
      });
    }

    // handle the rest
    var splitBios = inputValue.split(',');
    splitBios.forEach(function (b) {
      b = b.trim();
      if (b.length > 0) {
        bios.push(b);
      }
    });

    // wrap in quotes if keyword contains space
    bios = bios.map(function (n) {
      var r = n;
      if (n.indexOf(',') > -1 || n.indexOf(' ') > -1 && n.indexOf(' AND ') === -1 && n.indexOf(' OR ') === -1 && n.indexOf(' and ') === -1 && n.indexOf(' or ') === -1 && n.indexOf('"') !== 0 && n.indexOf('"') !== n.length - 1) {
        r = "\"".concat(n, "\"");
      }
      return r;
    });

    // Handle exclusion and pushing to right filter
    bios.forEach(function (term) {
      // if the exclude key is set (user wants to exclude term)
      if (Ember.get(inputController, "model.excludeFilters.".concat(valueKey))) {
        // if excludedFilters does not contain term and filters does not contain the term, push to exclusion filter
        if (!Ember.get(model, "filters.".concat(excludedFilterKey)).some(function (filter) {
          return filter.toLowerCase() === term.toLowerCase();
        }) && !Ember.get(model, "filters.".concat(filterKey)).some(function (filter) {
          return filter.toLowerCase() === term.toLowerCase();
        })) {
          Ember.get(model, "filters.".concat(excludedFilterKey)).pushObject(term);
        }
      }
      // if filters does not contain term and excludedFilters does not contain term, push to regular filter
      else if (!Ember.get(model, "filters.".concat(filterKey)).some(function (filter) {
        return filter.toLowerCase() === term.toLowerCase();
      }) && !Ember.get(model, "filters.".concat(excludedFilterKey)).some(function (filter) {
        return filter.toLowerCase() === term.toLowerCase();
      })) {
        Ember.get(model, "filters.".concat(filterKey)).pushObject(term);
      }
      // if term is not in regular filters
      else if (!Ember.get(model, "filters.".concat(filterKey)).some(function (filter) {
        return filter.toLowerCase() === term.toLowerCase();
      })) {
        // if term is in excluded filters remove it
        var index = Ember.get(model, "filters.".concat(excludedFilterKey)).findIndex(function (i) {
          return i.toLowerCase() === term.toLowerCase();
        });
        if (index !== -1) {
          Ember.get(model, "filters.".concat(excludedFilterKey)).removeObject(Ember.get(model, "filters.".concat(excludedFilterKey))[index]);
        }
        // add term to regular filters
        Ember.get(model, "filters.".concat(filterKey)).pushObject(term);
      }
    });

    // Reset input
    Ember.set(inputController, valueKey, '');
  };
});