define("affinio/components/bar-graph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  var _default = _exports.default = Ember.Component.extend({
    // Element
    classNames: ['bar-graph'],
    tagName: 'div',
    records: Ember.computed('instances.[]', function () {
      var instances = Ember.get(this, 'instances');
      var metric = Ember.get(this, 'metric');
      if (instances && Ember.get(instances, 'length')) {
        // let preSorted = get(metric, 'preSorted');
        var preSorted = true; // TODO, see below
        var sliceSize = Ember.get(metric, 'sliceSize') || 100; // TODO: the OR is a temphack for custom reports. If it's October 2017 and this is still here, yell at Phil.
        // let globalMax = get(metric, 'globalMax');

        if (instances && instances.length) {
          Ember.set(this, 'fullset', instances); // eslint-disable-line ember/no-side-effects
          var slicedInstances /* , explicits */;

          // If presorted is set, keep order and slice appropriately.
          // Otherwise, sort descending by value and slice
          if (preSorted) {
            slicedInstances = instances.slice(0, sliceSize);
          } else {
            slicedInstances = instances.sortBy('value').reverse().slice(0, sliceSize);
          }
          slicedInstances = slicedInstances.filter(function (instance) {
            return instance.value !== 0;
          });
          Ember.set(metric, 'max', slicedInstances.length ? Ember.get(_.max(slicedInstances, function (instance) {
            return Ember.get(instance, 'value');
          }), 'value') : 0); // eslint-disable-line ember/no-side-effects
          return slicedInstances;
        } else {
          return null;
        }
      } else {
        return _toConsumableArray(Array(Ember.get(metric, 'sliceSize'))).map(function () {
          return Ember.Object.create();
        });
      }
    }),
    max: Ember.computed('records', function () {
      return Ember.get(this, 'records')[0].value;
    })
  });
});