define("affinio/routes/admin/user-search", ["exports", "affinio/mixins/auth-check", "affinio/mixins/document-title"], function (_exports, _authCheck, _documentTitle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_authCheck.default, _documentTitle.default, {
    authOnly: true,
    documentTitle: 'User Search - Admin - Affinio',
    beforeModel: function beforeModel() {
      if (!Ember.get(this, 'user.account.isAffinio')) {
        this.transitionTo('admin.users');
      }
    }
  });
});