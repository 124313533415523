define("affinio/components/star-rating", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['star-rating'],
    rating: 0,
    maxRating: 5,
    stars: Ember.computed('rating', 'maxRating', function () {
      var rating = Math.round(Ember.get(this, 'rating'));
      var starsArray = [];
      for (var i = 1; i <= Ember.get(this, 'maxRating'); i++) {
        starsArray.push({
          rating: i,
          full: rating >= i
        });
      }
      return starsArray;
    })
  });
});