define("affinio/models/clusters/-base", ["exports", "ember-data", "affinio/utils/affinio", "affinio/utils/get-with-default", "ember-cli-string-helpers/helpers/humanize", "ember-cli-string-helpers/helpers/titleize"], function (_exports, _emberData, _affinio, _getWithDefault2, _humanize, _titleize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var Model = _emberData.default.Model;
  var _default = _exports.default = Model.extend({
    preferences: Ember.inject.service(),
    path: Ember.inject.service(),
    graph: Ember.inject.service(),
    // Base attributes
    name: attr('string'),
    isUserApprovedName: attr('boolean'),
    notes: attr(),
    suffix: attr('string'),
    dateCreated: attr('date'),
    dateUpdated: attr('date'),
    note: attr(),
    isHidden: attr(),
    isClusterRemoved: attr(),
    mergedInto: attr(),
    // Computeds
    isAggregate: Ember.computed('path.currentClusterId', 'suffix', function () {
      var suffix = Ember.get(this, 'suffix') ? Ember.get(this, 'suffix') : Ember.get(this, 'path.currentClusterId').split('_').pop(); // had to add since custom reports dont have suffix atm
      var aggregateSuffixes = ['all', 'group'];
      return aggregateSuffixes.includes(suffix);
    }),
    isCluster: Ember.computed.not('isAggregate'),
    // TODO isSubCluster a better name?

    totalMembersPercentage: Ember.computed('report.clusterMembersTotal', 'numberOfMembers.{loaded,response.value}', function () {
      if (!Ember.get(this, 'numberOfMembers.loaded')) {
        return '-';
      } else {
        return Number.parseInt((Ember.get(this, 'numberOfMembers.response.value') / Ember.get(this, 'report.clusterMembersTotal') * 100).toFixed(0));
      }
    }),
    // TODO this is only used by cluster-summary component & This logic is also duplicated in top-interests component
    uniqueInterests: Ember.computed('interests.loaded', 'pages.response', 'report.recordsCollection.loaded', function () {
      // TODO: just like top-interests, use interestsRecordsKey here
      var recordsInstanceKey = Ember.get(this, 'report.network') === 'Custom' ? 'label' : 'networkID';
      if (Ember.get(this, 'report.recordsCollection.loaded')) {
        var recordsMap = Ember.get(this, 'report.recordsMap');
        var recordsMapKey = 'interests';
        var propertyKey = 'interests.response';
        var interestIdentifier = recordsInstanceKey;
        if (recordsMap && recordsMap.has(recordsMapKey)) {
          var interests = (0, _getWithDefault2.default)(this, propertyKey, []);
          /* eslint-disable */
          // jsmap
          var interestsMap = recordsMap.get(recordsMapKey);
          /* eslint-enable */
          return interests.filter(function (int) {
            if (interestsMap.has(Ember.get(int, interestIdentifier))) {
              /* eslint-disable */
              // jsmap
              return interestsMap.get(Ember.get(int, interestIdentifier)).filter(function (r) {
                return r.normalizedScore !== 0;
              }).length === 1;
              /* eslint-enable */
            } else {
              return false;
            }
          });
        }
      } else {
        return [];
      }
    }),
    uniqueInterestsPercentage: Ember.computed('uniqueInterests.[]', function () {
      var description = 'This shows the percentage of a cluster\'s interests that no other cluster in this audience is interested in.';
      var uniqueInterests = Ember.get(this, 'uniqueInterests');
      var key = 'interests.response';
      var interests = Ember.get(this, key);

      // if the key is not present set to 0
      return !uniqueInterests || !interests ? Ember.Object.create({
        description: description,
        label: '% Unique Interests',
        value: 0,
        specificity: 1
      }) : uniqueInterests.length && interests.length && Ember.Object.create({
        description: description,
        label: '% Unique Interests',
        value: uniqueInterests.length / interests.length * 100 || 0,
        specificity: 1
      }) || Ember.Object.create({
        description: description,
        label: '% Unique Interests',
        value: 0,
        specificity: 1
      });
    }),
    interestAffinity: Ember.computed('requestsFulfilled', 'interests.response', 'pages.response', /* 'snapshot',*/ // TODO: snapshot - js
    function () {
      var key = 'interests.response';
      var description = 'This shows the average Relevance score among a cluster’s top 100 interests. Relevance is a custom measurement of an interest’s significance. It’s likely that clusters with greater Interest Relevance have more common interests.';
      var interests = (0, _getWithDefault2.default)(this, key, []).slice(0, 100);
      var reducedInfluencerScore = interests.mapBy('relevance').reduce(function (totalScore, score) {
        return totalScore + score;
      }, 0) / interests.length;
      return Ember.Object.create({
        description: description,
        value: reducedInfluencerScore || 0,
        category: 'Shared Interests',
        specificity: 2,
        label: 'Interest Relevance'
      });
    }),
    index: Ember.computed('report._clusters.[]', 'staticMemberRank', function () {
      // console.log('ughhh??', this.get('id'), this.get('report.clusters'),  this.get('report.clusters').indexOf(this));
      // made getWithDefault for weird collections edge case
      // TODO this is used for more places then just color, if this breaks stuff move staticMemberRank to just color
      var staticMemberRank = Ember.get(this, 'staticMemberRank');
      if (staticMemberRank) {
        // all cluster is always 0, but we don't count that as a cluster here
        return staticMemberRank - 1;
      } else {
        return (0, _getWithDefault2.default)(this, 'report._clusters', []).indexOf(this);
      }
    }),
    color: Ember.computed('preferences.{colorMap,categoricalColors}', 'index', function () {
      return Ember.get(this, 'preferences').clusterIndexToColor(Ember.get(this, 'index')) || '#333333';
    }),
    traits: Ember.computed('traitsTrigger', 'numberOfMembers.loaded', 'report.{recordsMap.size,version,metricDefinitions.@each.traitWeight,taxonomy.meta.traits,baselineReport,moduleLabelUpdated}', 'lowerSaturationBound', 'upperSaturationBound', 'isCluster', function () {
      var _this = this;
      // Suffix for this Cluster
      var suffix = Ember.get(this, 'suffix');
      // console.log('traits for', get(this, 'id'));
      if (Ember.get(this, 'report.recordsMap.size') !== 0 && Ember.get(this, 'numberOfMembers.loaded') && Ember.get(this, 'isCluster')) {
        // TODO: address trait calculation times on big reports
        // console.time('traits');
        // ID for this Cluster
        var id = Ember.get(this, 'id');
        // Get the number of members for this cluster
        var clusterSize = Ember.get(this, 'numberOfMembers.response.value');
        // Get all other clusters other than this one
        var otherClusters = Ember.get(this, 'report.clusters').rejectBy('id', id);
        // Get the numbers of members for all other clusters combined
        var otherClusterSize = (0, _affinio.sumKey)(otherClusters, 'numberOfMembers.response.value');
        // Set up potentialTraits array
        var potentialTraits = [];
        // let everything = [];
        var potentialUnTraits = [];
        // Get the records map for this report
        var recordsMap = Ember.get(this, 'report.recordsMap');

        // Taxonomies can define thresholds and minimums for ascribing something as a trait
        var _getWithDefault = (0, _getWithDefault2.default)(this, 'report.taxonomy.meta.traits', {}),
          _getWithDefault$minim = _getWithDefault.minimumValue,
          minimumValue = _getWithDefault$minim === void 0 ? 10 : _getWithDefault$minim,
          _getWithDefault$minim2 = _getWithDefault.minimumTimesMoreLikely,
          minimumTimesMoreLikely = _getWithDefault$minim2 === void 0 ? 1.5 : _getWithDefault$minim2,
          _getWithDefault$minim3 = _getWithDefault.minimumSaturation,
          minimumSaturation = _getWithDefault$minim3 === void 0 ? 0.5 : _getWithDefault$minim3,
          _getWithDefault$minim4 = _getWithDefault.minimumTraitScore,
          minimumTraitScore = _getWithDefault$minim4 === void 0 ? 1 : _getWithDefault$minim4,
          _getWithDefault$satur = _getWithDefault.saturationMultiplier,
          saturationMultiplier = _getWithDefault$satur === void 0 ? 10 : _getWithDefault$satur,
          _getWithDefault$drive = _getWithDefault.driver,
          driver = _getWithDefault$drive === void 0 ? 'schema' : _getWithDefault$drive;
        var lowerSaturationBound = Ember.get(this, 'lowerSaturationBound') || minimumSaturation;
        var upperSaturationBound = Ember.get(this, 'upperSaturationBound') || 100;

        // Get the metrics from the taxonomy that are traits
        var traitMetrics = driver === 'schema' ? Ember.get(this, 'report.metricDefinitions').filterBy('referencingModules.length').filterBy('traitWeight') : Ember.get(this, 'report.metricDefinitions').filterBy('traitWeight');

        // console.log('pass 1', traitMetrics);

        // Loop through each trait metric from the taxonomy
        // This is ment to apply records to the metric and create possible traits object
        traitMetrics.forEach(function (traitMetric) {
          // Get the type of metrics this is (i.e hashtags, bioKeywords etc..)
          var label = Ember.get(traitMetric, 'label');
          // Set up an array that will store objects for each metric type entity with its label, value, and records
          var metric = [];
          // Loop through the records map enties for this tpe of metric
          /* eslint-disable */
          // js map
          if (recordsMap.get(label)) {
            recordsMap.get(label).forEach(function (entity, key) {
              /* eslint-enable */
              // Grab the value out of this entities records map
              var entityValue = entity.find(function (n) {
                return n.cluster === suffix;
              });
              // Set the entity value it present
              if (entityValue) {
                entityValue = entityValue.value;
              }
              // Push this object to the metrics array
              metric.pushObject({
                label: key,
                records: entity,
                value: entityValue
              });
            });
          }
          // Map over the top 50 entities of this metric type and create an object containing trait info
          // console.log('ahm', get(traitMetric, 'label'), get(traitMetric, 'id'));
          var actualMetric = Ember.get(Ember.get(_this, 'report.clusterAggregation'), Ember.get(traitMetric, 'label'));
          var metricHasSaturations = false;
          if (!Ember.isEmpty(metric)) {
            metricHasSaturations = Ember.get(actualMetric, 'response') && Ember.isArray(Ember.get(actualMetric, 'response')) && Ember.get(actualMetric, 'response').any(function (instance) {
              return Ember.get(instance, 'saturations') && Ember.get(instance, 'saturations').findBy('filters.paired');
            });
          }
          // Doing these lookups with .find or .findBy is SLOW, changing this traits calc from ~30ms to ~400ms.
          // Let's try doing it with a JS immutable Map() instead!
          // console.log('can we map this more easily?', actualMetric);
          var mapOfBaselineValues = new Map();
          if (metricHasSaturations) {
            Ember.get(actualMetric, 'response').forEach(function (instance) {
              /* eslint-disable */
              // jsmap
              mapOfBaselineValues.set(Ember.get(instance, 'networkID') || Ember.get(instance, 'label'), Ember.get(Ember.get(instance, 'saturations').findBy('filters.paired'), 'value'));
              /* eslint-enable */
            });
          }
          var metricTraits = metric.slice(0, 1000).map(function (item) {
            // Get the reocrds for this entity without the current cluster
            var recordsWithOutThisCluster = item.records.reject(function (recordItem) {
              return recordItem.cluster === suffix;
            });
            // Get the totaly value of this entity excluding this clusters value
            var otherClusterValue = (0, _affinio.sumKey)(recordsWithOutThisCluster, 'value');
            // Get the TML for this entity
            var _item$records$findBy = item.records.findBy('cluster', suffix),
              timesMoreLikely = _item$records$findBy.timesMoreLikely;
            // Calculate the saturation for this entity
            var saturation;
            if (label === 'interests') {
              saturation = item.value;
              // console.log(timesMoreLikely, get(this, 'interests.response').findBy('networkID', item.label), item);
            } else {
              saturation = (item.value / clusterSize * 100).toFixed(2);
            }

            // Baselining: we want to both figure out the "times more likely than baseline" score per trait, but also to use this in an aggregateable way
            // Because we're trying to create an ensemble number here, and non-existence in a baseline would mean a num/0, and that would be a problem
            // So, default to null, reject by null when handling the ensemble (.compact()), and multiply by an inverse ratio of the % of traits that are null --
            // that is, if 19% of traits show up in a baseline, we multiply the average timesMoreLikelyThanBaseline x 100/19, or 5.26
            // Subject to future tweaks.
            // - PR
            var timesMoreLikelyThanBaseline = null;
            var baselineSaturation = 0;
            if (metricHasSaturations) {
              // let instanceInBaseline = get(actualMetric, 'response').findBy('label', get(item, 'label')); // ~200ms
              /* eslint-disable */
              // jsmap
              var instanceInBaseline = mapOfBaselineValues.get(Ember.get(item, 'label'));
              /* eslint-enable */
              if (instanceInBaseline) {
                baselineSaturation = instanceInBaseline;
                timesMoreLikelyThanBaseline = saturation / instanceInBaseline;
              }
            }
            // Set up a object containing the important trait data
            var traitProps = {
              instanceLookup: item.label,
              // used in trait-instance
              saturation: saturation,
              timesMoreLikely: timesMoreLikely,
              timesMoreLikelyThanBaseline: timesMoreLikelyThanBaseline,
              isInfinitelyMoreLikely: timesMoreLikely.isInfinite,
              // TODO JOEL remove where this is used and use timesMoreLikely obj instead
              metric: label,
              baselineSaturation: baselineSaturation,
              otherRatio: (otherClusterValue / otherClusterSize * 100).toFixed(2),
              totalValue: item.records ? (0, _affinio.sumKey)(item.records, 'value') : 0,
              traitScore: (timesMoreLikely.isInfinite ? 0 : timesMoreLikely.value

              // bias saturation by saturationMultiplier
              ) * (saturationMultiplier > 0 ? saturationMultiplier * saturation : 1)
              // bias depending on trait weight
              * Ember.get(traitMetric, 'traitWeight')
            };
            // Assign the traitProps to each entity
            return Object.assign(traitProps, item, {
              saturation: saturation
            });
          });

          // Note: the existence of this line below was causing the averageTraitBaselineScore to always be equal to the averageClusterBaselineScore
          // Keeping for posterity
          // -PR
          // if (metricHasSaturations) {
          //   metricTraits = metricTraits.filterBy('timesMoreLikelyThanBaseline');
          // }

          // Establish un-traits
          var metricUnTraits = metricTraits.filter(function (item) {
            // must be more than 1% of the cluster's members
            return item.saturation >= lowerSaturationBound
            // If the user puts a ceiling on saturations, obey it
            && item.saturation <= upperSaturationBound
            // must be less than 0.75x as likely as the other clusters
            && item.timesMoreLikely.value < 0.75 && item.traitScore > 0; // traitScore > 0, which it would be if metric's traitWeight were 0
          }); // Sorted by the traitScore Assigned
          potentialUnTraits.pushObjects(metricUnTraits);

          // everything.pushObjects(metricTraits.filter((item) => item.value >= 1));

          // filter out entities that don't match certain criteria to be a trait
          metricTraits = metricTraits.filter(function (item) {
            // must be more than 1% of the cluster's members
            return item.saturation >= lowerSaturationBound
            // If the user puts a ceiling on saturations, obey it
            && item.saturation <= upperSaturationBound
            // must be more than 1.5x as likely as the other clusters
            && (item.timesMoreLikely.isInfinite || item.timesMoreLikely.value > minimumTimesMoreLikely) && item.value >= minimumValue // must have happened 10 times at least
            && item.traitScore > minimumTraitScore; // traitScore > 0 threshold
          }); // Sorted by the traitScore Assigned
          // Push the trait into the potentialTraits array
          potentialTraits.pushObjects(metricTraits);
        }); // end of traitMetrics forEach

        // Handle Numeric traits! They don't have records in the recordsMap (it rejects by defaultValue=number)
        // so we do it in a different way.

        // TODO: if progressively-loaded reports (graphQL without metrics array) ever have numerics, we might have to revisit this.
        var skippedNumericTraits = ['averageAccountAge', 'averageLinkKarma', 'averageCommentKarma', 'averageNumberOfPosts', 'averageNumberOfComments', 'numberOfMembers', 'density', 'averagePostsPerMonth', 'lurkerPercentage'];
        var numericMetrics = traitMetrics.filterBy('defaultValue', 'number').reject(function (n) {
          return skippedNumericTraits.includes(Ember.get(n, 'label'));
        });

        // Holding array
        var numericTraits = [];
        numericMetrics.forEach(function (traitMetric) {
          var label = Ember.get(traitMetric, 'label');
          // Set up an array that will store objects for each metric type entity with its label, value, and records
          // let allClusters = get(this, 'report.clusters').mapBy(`${label}.response`);
          var clusterMetric = Ember.get(_this, "".concat(label, ".response"));
          var aggregateMetric = Ember.get(_this, "report.clusterAggregation.".concat(label, ".response"));

          // We make a fake metric from which to reference in trait-instance, etc.

          // Naive example, but if diff > 25%, run with it.
          var differenceValue = Ember.get(clusterMetric, 'value') - Ember.get(aggregateMetric, 'value');
          var differencePercent = Math.abs(differenceValue / Ember.get(aggregateMetric, 'value') * 100);
          if (differencePercent > 25) {
            // console.log('yep! big diff!', get(this, 'name'), label, get(clusterMetric, 'value'), get(aggregateMetric, 'value'));
            var relationshipDirection = differenceValue > 0 ? 'higher' : 'lower';
            var _Ember$get$peekRecord = Ember.get(_this, 'store').peekRecord('report-state', Ember.get(_this, 'report.id')),
              metricLabelMap = _Ember$get$peekRecord.metricLabelMap;
            var niceLabel = metricLabelMap.has(label) ? metricLabelMap.get(label) // eslint-disable-line ember/use-ember-get-and-set
            : (0, _titleize.titleize)([(0, _humanize.humanize)([label.dasherize()])]);
            var traitProps = {
              // instanceLookup: item.label, // used in trait-instance
              label: "".concat(niceLabel, " ").concat(relationshipDirection, " than average"),
              saturation: '50',
              timesMoreLikely: 5,
              isInfinitelyMoreLikely: false,
              metric: 'numericTraits',
              originalMetric: label,
              rawValue: Ember.get(clusterMetric, 'value'),
              audienceValue: Ember.get(aggregateMetric, 'value'),
              // otherRatio: (otherClusterValue / otherClusterSize * 100).toFixed(2),
              // totalValue: item.records ? sumKey(item.records, 'value') : 0,
              // traitScore:
              //   (
              //     timesMoreLikely.isInfinite
              //       ? 0
              //       : timesMoreLikely.value
              //   )
              //   // bias saturation by saturationMultiplier
              //   * (saturationMultiplier > 0 ? (saturationMultiplier * saturation) : 1)
              //   // bias depending on trait weight
              //   * get(traitMetric, 'traitWeight')
              traitScore: differencePercent
            };
            numericTraits.push(traitProps);
          }
        });
        Ember.set(this, 'numericTraits', {
          response: numericTraits
        }); // eslint-disable-line ember/no-side-effects

        // UnTraits first:
        var unData = potentialUnTraits.sortBy('traitScore').map(function (datum) {
          if (Ember.get(datum, 'metric') === 'interests') {
            var foundInterest = Ember.get(_this, 'interests.response').findBy('networkID', datum.label);
            if (foundInterest) {
              Ember.set(datum, 'label', Ember.get(foundInterest, 'screenName')); // eslint-disable-line ember/no-side-effects
            }
          }
          return datum;
        });
        Ember.set(this, 'unTraits', {
          // eslint-disable-line ember/no-side-effects
          data: unData,
          max: _.max(unData.mapBy('traitScore'))
          // unMeta
        });
        if (Ember.get(this, 'report.baselineReport')) {
          var averageTraitBaselineScore = potentialTraits.length && potentialTraits.mapBy('timesMoreLikelyThanBaseline').compact().length // if this cluster has potentialTraits with timesMoreLikelyThanBaseline among them
          ? potentialTraits.mapBy('timesMoreLikelyThanBaseline').compact().reduce(function (m, n) {
            return m + n;
          }) / potentialTraits.filterBy('timesMoreLikelyThanBaseline').length // What's the average amount of timesMoreLikelyThanBaseline among your traits?
          : 0; // if you have no traits, your average timesMoreLikelyThanBaseline for the cluster is 0
          // console.log('==================');
          // console.log('so what?', get(this, 'name'))
          // console.log(`- We have ${everything.length} total instances, of which ${potentialTraits.length} are traits.`)
          // console.log(`- Among all instances, ${everything.filter((item) => item.timesMoreLikelyThanBaseline !== 10).length / everything.length * 100}% are found within the baseline`)
          // console.log(`- Among ${potentialTraits.length} traits, ${potentialTraits.filterBy('timesMoreLikelyThanBaseline').length / potentialTraits.length * 100}% are found within the baseline`)
          // console.log(`- %c${soWhatPT} %cmore likely than baseline if among all co-located traits.`, 'font-weight: bold; color: #cc6600', '');
          // Inverse ratio of those items found in baseline; if few of the traits are found in the baseline, means it's pretty unique, so multiply by a bigger number.
          var percentOfTraitsFoundInBaseline = potentialTraits.filterBy('timesMoreLikelyThanBaseline').length / potentialTraits.length * 100;
          var soWhatPT;
          // soWhatPT = (averageTraitBaselineScore * 2) * (100 / percentOfTraitsFoundInBaseline); // 100 / percentOfTraitsFoundInBaseline: used to offset when a cluster is SO unique that nothing in it is represented in your baseline. -PR
          if (Ember.get(this, 'report.isCustom')) {
            soWhatPT = averageTraitBaselineScore * 5; // 100 / percentOfTraitsFoundInBaseline: used to offset when a cluster is SO unique that nothing in it is represented in your baseline. -PR
          } else {
            soWhatPT = averageTraitBaselineScore * 2 * (100 / percentOfTraitsFoundInBaseline); // 100 / percentOfTraitsFoundInBaseline: used to offset when a cluster is SO unique that nothing in it is represented in your baseline. -PR
          }
          Ember.run.scheduleOnce('afterRender', function () {
            Ember.set(_this, 'percentOfTraitsFoundInBaseline', percentOfTraitsFoundInBaseline); // eslint-disable-line ember/no-side-effects
            Ember.set(_this, 'averageTraitBaselineScore', averageTraitBaselineScore); // eslint-disable-line ember/no-side-effects
            Ember.set(_this, 'soWhat', isNaN(soWhatPT) ? 0 : soWhatPT); // eslint-disable-line ember/no-side-effects
          });
        }

        // console.log('traitChecking now', potentialTraits, numericTraits);
        // Data object containing the different traits for this cluster
        var data = [].concat(potentialTraits, numericTraits).sortBy('traitScore').reverse().map(function (datum) {
          if (Ember.get(datum, 'metric') === 'interests') {
            var foundInterest = Ember.get(_this, 'interests.response').findBy('networkID', datum.label);
            if (foundInterest) {
              //  because sometimes unData, above, sets label already and we dont need to do it here.
              Ember.set(datum, 'label', Ember.get(foundInterest, 'screenName')); // eslint-disable-line ember/no-side-effects
            }
          }
          return datum;
        });

        // Count of each type of triat
        var counts = _.countBy(data, 'metric');
        // Set up meta data object what will hold label value pairs for different trait type counts
        var meta = {};
        meta.data = [];
        for (var metric in counts) {
          meta.data.push({
            label: metric,
            value: counts[metric]
          });
        }
        // Sort meta data counts by value
        meta.data = meta.data.sortBy('value').reverse();
        // Total number of traits
        meta.total = _.reduce(_.values(counts), function (a, b) {
          return a + b;
        });
        // Get the highest value from all the traits
        var max = _.max(data.mapBy('traitScore'));
        // Return the trait data object
        var ret = {
          data: data,
          max: max,
          meta: meta
        };
        // console.log('done traits', ret);
        // console.timeEnd('traits');
        return ret;
      }
    }),
    // Cluster bar graph
    lurkers: Ember.computed('lurkerPercentage.loaded', function () {
      var description = 'These metrics show the % of an audience who actively post vs. those who \'lurk\' (i.e. seldomly post, or only consume content). We define lurkers as those who post fewer than 15 times per month.';
      if (Ember.get(this, 'lurkerPercentage.loaded')) {
        var lurkerPercentage = Ember.get(this, 'lurkerPercentage.response.value') * 100;
        return lurkerPercentage ? Ember.Object.create({
          description: description,
          'label': '% Lurkers',
          'value': lurkerPercentage,
          'category': 'Lurkers',
          'specificity': 2
        }) : Ember.Object.create({
          description: description,
          'label': '% Lurkers',
          'value': 100,
          'category': 'Lurkers',
          'specificity': 2
        });
      } else {
        return Ember.Object.create({
          description: description,
          'label': '% Lurkers',
          'value': 100,
          'category': 'Lurkers',
          'specificity': 2
        });
      }
    }),
    postsPerMonthCBG: Ember.computed('numberOfMembers.loaded', 'averagePostsPerMonth.loaded', 'report.postType', function () {
      var description = 'This shows the average number of posts each cluster member makes per month, including both original tweets and retweets. The Affinio Average lines indicate the 25th, 50th, and 75th percentiles to give you an idea of what normal activity looks like for this type of Affinio report.';
      var postTypePlural = "".concat(Ember.get(this, 'report.postType'), "s");
      var postsPerMonth = (0, _getWithDefault2.default)(this, 'averagePostsPerMonth.response.value', 0);
      return !isNaN(postsPerMonth) && Ember.Object.create({
        description: description,
        'label': "".concat(postTypePlural, "/user/month"),
        'value': postsPerMonth,
        specificity: 2
      });
    }),
    interconnectivityCBG: Ember.computed('density.loaded', function () {
      var description = 'This metric shows how friendly or well-acquainted a cluster is. The higher the density percentage, the greater the likelihood that people in this cluster know (and follow) each other.';
      var density = Ember.get(this, 'density.response.value');
      return Ember.Object.create({
        description: description,
        label: '% density',
        value: density || 0.0,
        specificity: 2
      });
    }),
    imageShareCBG: Ember.computed('report.imageCategories', function () {
      var description = 'This shows the percentage of images shared by each cluster relative to the total number of images shared by the audience. It does not represent the percentage of tweets that included images.';
      var images = Ember.get(this, 'report.imageCategories');
      if (images) {
        var flatImages = _.flatten(images.mapBy('images'));
        var filteredImages = _.uniq(flatImages, false, 'tweetID');
        var counts = _.countBy(filteredImages, 'sourceCluster');
        return Ember.Object.create({
          description: description,
          label: '% image share',
          value: counts[Ember.get(this, 'id').split('_').pop()] / flatImages.length * 100,
          specificity: 1
        });
      }
    }),
    hasLegacyEngagementFormat: Ember.computed('engagements', 'histogram', function () {
      return Ember.get(this, 'engagements.length') < 1 && Ember.get(this, 'histogram.length') > 1;
    }),
    hasNoEngagementData: Ember.computed('engagements.[]', 'histogram', function () {
      return Ember.get(this, 'engagements.length') < 1 && Ember.get(this, 'histogram.length') < 1;
    }),
    pluckedAggregateSettings: Ember.computed('report.metricDefinitions.@each.aggregate', function () {
      return Ember.get(this, 'report.metricDefinitions').mapBy('aggregate');
    }),
    aggregateMetricDefinitions: Ember.computed('pluckedAggregateSettings.@each.{checked,type,slice}', function () {
      Ember.get(this, 'pluckedAggregateSettings'); // necessary to fire the above dependency
      return Ember.get(this, 'report.metricDefinitions').filterBy('aggregate.checked');
    }),
    aggregateMetricInstances: Ember.computed('aggregateMetricDefinitions.[]', 'report.version', function () {
      var _this2 = this;
      return Ember.get(this, 'aggregateMetricDefinitions').map(function (metric) {
        return Ember.get(_this2, Ember.get(metric, 'label'));
      });
    }),
    aggregateMetrics: Ember.computed('aggregateMetricInstances.@each.response', 'pluckedAggregateSettings.@each.{checked,type,slice}', 'numberOfMembers.loaded', function () {
      var _this3 = this;
      var definitions = Ember.get(this, 'aggregateMetricDefinitions');
      var responses = Ember.get(this, 'aggregateMetricInstances');
      var numberOfMembers = Ember.get(this, 'numberOfMembers.response.value');

      // get(this, 'graph').loadMetrics(responses);
      return definitions.map(function (defn) {
        var response = Ember.get(responses.findBy('label', Ember.get(defn, 'label')), 'response');
        if (response && !Ember.isEmpty(response)) {
          var sliceSize = Ember.get(defn, 'aggregate.slice') || 20;
          var aggregateType = Ember.get(defn, 'aggregate.type') || 'average';
          var aggregateValue = 0;
          var aggregateTitle = Ember.get(defn, 'aggregate.title') || ''; // what's the thing called in the dropdown?
          var aggregateDescription = Ember.get(defn, 'aggregate.description') || ''; // what's the thing called in the dropdown?
          var aggregateInstanceTitle = "% ".concat(aggregateType, " of ").concat(Ember.get(defn, 'label')); // what shows up on the bars themselves?
          var isCustomNumeric = response.hasOwnProperty('structuredBins');
          switch (aggregateType) {
            case 'rawValue':
              aggregateValue = Ember.get(_this3, "".concat(Ember.get(defn, 'label'), ".response.value")); // TODO this may need backend to change singular numeric returns to match
              aggregateInstanceTitle = '';
              break;
            case 'sum':
              aggregateValue = response.slice(0, sliceSize).mapBy('value').reduce(function (m, n) {
                return m + n;
              }) / numberOfMembers * 100;
              break;
            case 'average':
              aggregateValue = isCustomNumeric ? response.value : response.slice(0, sliceSize).mapBy('value').reduce(function (m, n) {
                return m + n;
              }) / sliceSize / numberOfMembers * 100;
              break;
            case 'top':
              aggregateValue = Ember.get(response.objectAt(0), 'value') / numberOfMembers * 100;
              break;
            case 'instancePercentage':
              aggregateValue = response.findBy('label', Ember.get(defn, 'aggregate.instance')) ? Ember.get(response.findBy('label', Ember.get(defn, 'aggregate.instance')), 'value') / numberOfMembers * 100 : 0;
              aggregateInstanceTitle = "% ".concat(Ember.get(defn, 'aggregate.instance'));
              aggregateTitle = aggregateTitle || "".concat(Ember.get(defn, 'aggregate.instance'));
              break;
            default:
              break;
          }
          Ember.set(_this3, "_".concat(Ember.get(defn, 'label'), "-").concat(aggregateType), {
            // eslint-disable-line ember/no-side-effects
            description: aggregateDescription,
            label: aggregateInstanceTitle,
            value: aggregateValue,
            specificity: Ember.get(defn, 'label') === 'numberOfMembers' ? 0 : 2
          });
          return {
            value: "_".concat(Ember.get(defn, 'label'), "-").concat(aggregateType),
            label: aggregateTitle || "".concat((0, _humanize.humanize)([aggregateType.dasherize()]), " of ").concat(Ember.get(defn, 'label').capitalize()),
            sliceSize: sliceSize
          };
        }
      }).compact();
    })
  });
});