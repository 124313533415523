define("affinio/helpers/abbreviate-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.abbreviateNumber = abbreviateNumber;
  _exports.default = void 0;
  function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
  // also preforms cull-number
  function abbreviateNumber(_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
      n = _ref2[0],
      _ref2$ = _ref2[1],
      cull = _ref2$ === void 0 ? 0 : _ref2$;
    var magnitude = Math.abs(n);
    if (magnitude < 1e3) {
      if (typeof cull !== 'undefined') {
        cull = cull.toString();
      }
      return parseFloat(n).toFixed(cull ? cull : 2);
    }
    if (magnitude >= 1e3 && n < 1e6) {
      return "".concat(+(n / 1e3).toFixed(1), "K");
    }
    if (magnitude >= 1e6 && n < 1e9) {
      return "".concat(+(n / 1e6).toFixed(1), "M");
    }
    if (magnitude >= 1e9 && n < 1e12) {
      return "".concat(+(n / 1e9).toFixed(1), "B");
    }
    if (magnitude >= 1e12) {
      return "".concat(+(n / 1e12).toFixed(1), "T");
    }
  }
  var _default = _exports.default = Ember.Helper.helper(abbreviateNumber);
});