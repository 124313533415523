define("affinio/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "aVIg718U",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"id\",\"universe-container\"],[12,\"class\",[28,[[27,\"camelize\",[[23,[\"path\",\"currentPathClass\"]]],null],\" \",[27,\"if\",[[23,[\"modal\"]],\"modal-open\"],null]]]],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"id\",\"world-container\"],[12,\"class\",[27,\"camelize\",[[23,[\"preferences\",\"categoricalColors\"]]],null]],[9],[0,\"\\n\\n    \"],[1,[21,\"outlet\"],false],[0,\"\\n\\n  \"],[10],[0,\"\\n  \"],[1,[21,\"modal-display\"],false],[0,\"\\n  \"],[1,[21,\"auth-notify\"],false],[0,\"\\n  \"],[1,[27,\"ember-notify\",null,[[\"closeAfter\"],[5000]]],false],[0,\"\\n  \"],[1,[27,\"support-button\",null,[[\"worldly\",\"text\"],[true,\"Support\"]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/application.hbs"
    }
  });
});