define("affinio/components/modules/engagement-timeline", ["exports", "affinio/mixins/affinio-module"], function (_exports, _affinioModule) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_affinioModule.default, {
    // Element
    classNames: ['engagement-timeline', 'card', 'module'],
    preferences: Ember.inject.service()
  });
});