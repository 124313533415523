define("affinio/components/metric-icon", ["exports", "affinio/utils/constants/metrics"], function (_exports, _metrics) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Element
    classNames: ['metric-icon'],
    tagName: 'div',
    // Properties
    metrics: _metrics.default
  });
});