define("affinio/routes/wildcard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    app: Ember.inject.service(),
    redirect: function redirect(model) {
      var badRoute = model[''];
      // If we have some semblance of a valid section, move them there
      // else default to sign-in (will redirect to reports if authed)
      if (badRoute.indexOf('admin') > -1) {
        this.transitionTo('admin');
      } else if (badRoute.indexOf('setting') > -1) {
        this.transitionTo('settings');
      } else if (badRoute.indexOf('aton') > -1 && Ember.get(this, 'app.ENV.features.custom')) {
        this.transitionTo('aton');
      } else {
        this.transitionTo('sign-in');
      }
    }
  });
});