define("affinio/models/taxonomies/metric-definition", ["exports", "ember-data", "affinio/utils/data/array-attr"], function (_exports, _emberData, _arrayAttr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var Model = _emberData.default.Model;
  var _default = _exports.default = Model.extend({
    isStandard: attr(),
    label: attr('string'),
    title: attr('string', {
      defaultValue: function defaultValue(metric) {
        return Ember.get(metric, 'label');
      }
    }),
    description: attr('string'),
    icon: attr('string', {
      defaultValue: 'asterisk'
    }),
    defaultValue: attr('string', {
      defaultValue: 'array'
    }),
    // okay lol // yeah wat? lol
    graphQLType: attr('string'),
    customDataType: attr('string'),
    properties: _arrayAttr.default,
    level: attr('string', {
      defaultValue: 'cluster'
    }),
    exportable: attr('boolean', {
      defaultValue: true
    }),
    comparable: attr('boolean', {
      defaultValue: false
    }),
    exportType: attr('string', {
      defaultValue: 'standard'
    }),
    aggregation: attr('string', {
      defaultValue: 'top'
    }),
    aggregate: attr({
      defaultValue: function defaultValue() {
        return {
          checked: false,
          type: 'sum',
          slice: 20
        };
      }
    }),
    trait: attr('boolean', {
      defaultValue: false
    }),
    traitWeight: attr('number', {
      defaultValue: 1
    }),
    taxonomy: belongsTo('taxonomy', {
      inverse: 'metricDefinitions'
    }),
    referencingModules: Ember.computed('taxonomy', 'referencingModulesTrigger', function () {
      var _this = this;
      var modules = [];
      Ember.get(this, 'taxonomy.schema').forEach(function (cat) {
        Ember.get(cat, 'subcategories').forEach(function (subcat) {
          Ember.get(subcat, 'modules').forEach(function (module) {
            modules.pushObject(module);
          });
        });
      });
      return modules.filter(function (module) {
        return Ember.get(module, 'metrics').any(function (metric) {
          var vals = Object.values(metric);
          return vals.includes(Ember.get(_this, 'label')) || vals.includes(Ember.get(_this, 'modelAlias'));
        });
      });
    }),
    modelAlias: attr('string', {
      defaultValue: function defaultValue(metric) {
        return Ember.get(metric, 'label');
      }
    })
  });
});