define("affinio/components/report-builder/benchmark-groups", ["exports", "affinio/utils/auto-suggest"], function (_exports, _autoSuggest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var assetLimit = 800;
  var _default = _exports.default = Ember.Component.extend({
    // Element
    classNames: ['benchmark-groups'],
    tagName: 'div',
    twEndpoint: (0, _autoSuggest.twitterHandlesEndpoint)({
      inputKey: 'autoSuggestInput'
    }),
    // Services
    user: Ember.inject.service(),
    graph: Ember.inject.service(),
    tracker: Ember.inject.service(),
    modalManager: Ember.inject.service(),
    actions: {
      addAssetToReportSummary: function addAssetToReportSummary(asset) {
        var existingBenchmarkGroups = Ember.get(this, 'model.filters.benchmarkGroups');
        if (!existingBenchmarkGroups) {
          Ember.set(this, 'model.filters.benchmarkGroups', Ember.A());
        }
        var totalAssets = existingBenchmarkGroups ? existingBenchmarkGroups.map(function (n) {
          return Ember.get(n, 'members.length');
        }).reduce(function (a, b) {
          return a + b;
        }, 0) : 0;
        if (totalAssets < assetLimit) {
          var benchmarkGroupName = Ember.get(this, 'benchmarkGroupName');
          var existingBenchmarkGroup = Ember.get(this, 'model.filters.benchmarkGroups') ? Ember.get(this, 'model.filters.benchmarkGroups').findBy('name', benchmarkGroupName) : null;
          var handle = Ember.get(asset, 'label').replace('@', '');
          if (existingBenchmarkGroup) {
            // console.log('benchgroup of name', benchmarkGroupName, 'found', existingBenchmarkGroup);
            Ember.get(existingBenchmarkGroup, 'members').pushObject(handle);
            Ember.set(existingBenchmarkGroup, 'members', Ember.get(existingBenchmarkGroup, 'members').uniq());
          } else {
            // console.log('no benchmark group found with name ');
            var newGroup = {
              name: benchmarkGroupName,
              members: [handle]
            };
            Ember.get(this, 'model.filters.benchmarkGroups').pushObject(newGroup);
          }
          this.toggleProperty('model.filterChange');
        } else {
          Ember.get(this, 'modalManager').openModal('modals/modal-error', {
            title: 'Tracked Interests Limit Reached',
            reason: 'The maximum number of tracked interests per report is 800. Please make sure the sum of all tracked interests groups does not exceed the limit and try again'
          });
        }
      },
      addGroupToReportSummary: function addGroupToReportSummary(assetGroup) {
        // console.log('addGroupToReportSummary', assetGroup);
        // if group has more than one member
        if (Ember.get(assetGroup, 'assets.length') > 0) {
          var filterBenchmarkGroups = Ember.get(this, 'model.filters.benchmarkGroups');
          if (!filterBenchmarkGroups) {
            filterBenchmarkGroups = Ember.A();
          }
          var combinedNumberOfAssets = Ember.get(assetGroup, 'assets.length') + (filterBenchmarkGroups.length > 0 ? filterBenchmarkGroups.map(function (n) {
            return Ember.get(n, 'members.length');
          }).reduce(function (a, b) {
            return a + b;
          }) : 0);
          if (combinedNumberOfAssets <= assetLimit) {
            var groupFilterObject = {
              name: Ember.get(assetGroup, 'name'),
              members: Ember.get(assetGroup, 'assets').mapBy('name')
            };
            var existingGroup = filterBenchmarkGroups.findBy('name', Ember.get(assetGroup, 'name'));
            if (existingGroup && Ember.get(existingGroup, 'members.length') !== Ember.get(assetGroup, 'assets.length')) {
              // overwrite members with passed in ones
              var diff = _.difference(Ember.get(assetGroup, 'assets').mapBy('name'), Ember.get(existingGroup, 'members'));
              if (Ember.get(diff, 'length') > 0) {
                // push the diff to existing filter group
                Ember.get(existingGroup, 'members').pushObjects(diff);
              }
            } else {
              filterBenchmarkGroups.pushObject(groupFilterObject);
              filterBenchmarkGroups = filterBenchmarkGroups.uniqBy('name');
              Ember.set(Ember.get(this, 'model'), 'filters.benchmarkGroups', filterBenchmarkGroups);
            }
            this.toggleProperty('model.filterChange');
          } else {
            Ember.get(this, 'modalManager').openModal('modals/modal-error', {
              title: 'Tracked Interests Limit Reached',
              reason: 'The maximum number of tracked interests per report is 800. Please make sure the sum of all tracked interests groups does not exceed the limit and try again'
            });
          }
        } else {
          // modal please add members
          Ember.get(this, 'modalManager').openModal('modals/modal-error', {
            title: 'Tracked Interests Group Requires Members',
            reason: "Please add handles to your tracked interests group ".concat(Ember.get(assetGroup, 'name') ? Ember.get(assetGroup, 'name') : 'unnamed group')
          });
        }
      }
    }
  });
});