define("affinio/components/multi-slider", ["exports", "ember-cli-nouislider/components/range-slider"], function (_exports, _rangeSlider) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _rangeSlider.default.extend({
    // Element
    classNames: ['multi-slider'],
    tagName: 'div',
    preferences: Ember.inject.service(),
    // Lifecycle
    init: function init() {
      this._super.apply(this, arguments);
      // We do this so the slider doesn't show floating point madness
      var decimals = Ember.getWithDefault(this, 'decimalPoints', 0);
      if (Ember.isArray(Ember.get(this, 'start'))) {
        Ember.set(this, 'tooltips', [{
          to: function to(x) {
            return x.toFixed(decimals);
          }
        }, {
          to: function to(x) {
            return x.toFixed(decimals);
          }
        }]);
      } else {
        Ember.set(this, 'tooltips', [{
          to: function to(x) {
            return x.toFixed(decimals);
          }
        }]);
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (Ember.get(this, 'globallyApplied')) {
        Ember.set(this, 'start', [Ember.get(this, 'globalLowerBound'), Ember.get(this, 'globalUpperBound')]);
      }
    }
  });
});