define("affinio/components/hover-span", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: 'span',
    attributeBindings: ['text:title'],
    text: ''
  });
});