define("affinio/utils/taxonomies", ["exports", "affinio/utils/taxonomies/twitter", "affinio/utils/taxonomies/pinterest", "affinio/utils/taxonomies/instagram", "affinio/utils/taxonomies/sales", "affinio/utils/taxonomies/odc-cpg"], function (_exports, _twitter, _pinterest, _instagram, _sales, _odcCpg) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    Twitter: _twitter.taxonomy,
    Pinterest: _pinterest.taxonomy,
    Instagram: _instagram.taxonomy,
    Sales: _sales.taxonomy,
    'ODC-CPG': _odcCpg.taxonomy
  };
});