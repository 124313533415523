define("affinio/utils/queries/companies", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.company = _exports.companiesLite = _exports.companies = _exports.allPermissions = void 0;
  var companies = _exports.companies = {
    queryString: "query {\n    allCompanies {\n        id\n        name\n        isActive\n        seatLimit\n        viewerSeatLimit\n        numberOfAccounts\n    }\n  }",
    queryMap: {
      allCompanies: {
        array: true,
        modelName: 'company',
        responseKey: 'allCompanies'
      }
    }
  };
  var companiesLite = _exports.companiesLite = {
    cacheKey: 'companiesLite',
    queryString: "query {\n    allCompanies {\n        id\n        name\n    }\n  }",
    queryMap: {
      companiesLite: {
        array: true,
        modelName: 'company',
        responseKey: 'allCompanies'
      }
    }
  };
  var company = _exports.company = function company(companyId) {
    return {
      queryString: "query {\n      company(id:\"".concat(companyId, "\") {\n        id\n        name\n        credits\n        isActive\n        seatLimit\n        viewerSeatLimit\n        sfid\n        notes\n        businessModelType\n        verticalType\n        trialInfo {\n          endDate\n          startDate\n          isActive\n        }\n        accounts {\n          id\n          email\n          lastName\n          firstName\n          isActive\n          permissions\n        }\n        groups{\n          id\n          name\n          numberOfAccounts\n        }\n      }\n    }"),
      queryMap: {
        company: {
          array: false,
          modelName: 'company',
          responseKey: 'company'
        }
      }
    };
  };
  var allPermissions = _exports.allPermissions = {
    cacheKey: 'allPermissions',
    queryString: "query {\n    allPermissions\n  }",
    queryMap: {}
  };
  var _default = _exports.default = {
    companies: companies,
    companiesLite: companiesLite,
    company: company,
    allPermissions: allPermissions
  };
});