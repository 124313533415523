define("affinio/services/network-graph", ["exports", "affinio/objects/object-promise-proxy"], function (_exports, _objectPromiseProxy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    // Services
    ajax: Ember.inject.service(),
    tracker: Ember.inject.service(),
    localStorage: Ember.inject.service(),
    app: Ember.inject.service(),
    // Properties
    cache: null,
    // JS map
    init: function init() {
      this._super.apply(this, arguments);
      Ember.set(this, 'cache', new Map());
    },
    // Helpers
    getCached: function getCached(key, clusters) {
      var _this = this;
      // console.log('clusters from getCached', clusters);
      var cache = Ember.get(this, 'cache');
      if (cache.has(key)) {
        /* eslint-disable */
        // jsmap
        var cachedSvg = cache.get(key).cloneNode(true);
        /* eslint-enable */
        return _objectPromiseProxy.default.create({
          promise: new Ember.RSVP.Promise(function (resolve) {
            return resolve(cachedSvg);
          })
        });
      } else {
        if (typeof key === 'string') {
          var requestProperties = {
            dataType: 'xml'
          };
          if (Ember.get(this, 'app.ENV.context.container')) {
            Ember.set(requestProperties, 'headers', {
              Authorization: Ember.get(this, 'localStorage.affinioAuth')
            });
          }
          return _objectPromiseProxy.default.create({
            promise: Ember.get(this, 'ajax').request(key, requestProperties).then(function (response) {
              var svg = response.documentElement;
              /* eslint-disable */
              // jsmap
              cache.set(key, _this.setupSVG(key, clusters, svg));
              return cache.get(key);
              /* eslint-enable */
            })
          });
        } else {
          Ember.get(this, 'tracker').track('tried to load svg without key');
          return _objectPromiseProxy.default.create({
            promise: new Ember.RSVP.Promise(function (resolve) {
              return resolve('');
            })
          });
        }
      }
    },
    setupSVG: function setupSVG(key, clusters, _svg) {
      // console.log('clusters from setupSVG', clusters);
      var svg = _svg.cloneNode(true);
      svg.setAttribute('width', '100%');
      svg.setAttribute('height', '100%');
      svg.setAttribute('viewBox', '0 0 500 500');
      svg.setAttribute('class', 'network-graph-svg');
      var svgD3 = d3.select(svg);
      svgD3.append('circle').attr('class', 'frame').attr('r', 250).attr('cy', 250).attr('cx', 250).style('opacity', '0');
      clusters.forEach(function (cluster) {
        var box = svgD3.append('g').attr('data-cluster', cluster.id).append('svg').attr('height', 20);
        box.append('rect').attr('class', 'label-rect').attr('height', 20).style('opacity', '1');
        box.append('rect').attr('class', 'accent').attr('height', 16);
        box.append('text').attr('class', 'text');
      });
      return svg;
    }
  });
});