define("affinio/controllers/aton/taxonomy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    queryParams: ['taxonomyID', 'duplicate', 'dataSourceID'],
    user: Ember.inject.service()
  });
});