define("affinio/templates/components/affinio-table-cell/date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "9g8Coeqp",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"moment-format\",[[23,[\"value\"]],[27,\"or\",[[23,[\"column\",\"dateFormat\"]],\"MMM Do, YYYY\"],null]],null],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/affinio-table-cell/date.hbs"
    }
  });
});