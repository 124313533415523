define("affinio/templates/components/old/report-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "yug3G/+k",
    "block": "{\"symbols\":[],\"statements\":[[7,\"label\"],[9],[0,\"\\n  \"],[7,\"h4\"],[9],[1,[21,\"title\"],false],[0,\":\"],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"currentTerm\"]]],null,{\"statements\":[[0,\"    \"],[7,\"button\"],[11,\"class\",\"inline tiny-add-button\"],[9],[0,\"Add\"],[3,\"action\",[[22,0,[]],\"addActionWrapper\",[23,[\"currentTerm\"]]]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[1,[27,\"input\",null,[[\"type\",\"placeholder\",\"value\"],[\"text\",[23,[\"placeholder\"]],[23,[\"currentTerm\"]]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"description\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"html-safe\",[[23,[\"description\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/old/report-input.hbs"
    }
  });
});