define("affinio/templates/components/modals/modal-toolbox-asset-groups", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "kZVYW1+4",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"form\"],[11,\"class\",\"modal modal-toolbox-asset-groups\"],[9],[0,\"\\n  \"],[7,\"section\"],[11,\"class\",\"page-title\"],[9],[0,\"\\n    \"],[7,\"h2\"],[9],[1,[27,\"if\",[[23,[\"isCompany\"]],\"Company\",\"User\"],null],false],[0,\" ID:\"],[1,[21,\"id\"],false],[0,\" Tracked Interests Groups\"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"asset-grid-container clearfix\"],[9],[0,\"\\n    \"],[7,\"section\"],[11,\"class\",\"admin-prefs\"],[9],[0,\"\\n      \"],[7,\"h3\"],[9],[0,\"Twitter Tracked Interests\"],[10],[0,\"\\n      \"],[1,[27,\"asset-group-list\",null,[[\"groups\",\"socialNetwork\",\"isToolbox\",\"toolboxID\",\"isCompany\"],[[22,0,[\"assetGroups\"]],\"Twitter\",true,[22,0,[\"id\"]],[23,[\"isCompany\"]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"backdrop\"],[11,\"role\",\"button\"],[9],[3,\"action\",[[22,0,[]],\"close\"]],[10],[0,\"\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/modals/modal-toolbox-asset-groups.hbs"
    }
  });
});