define("affinio/components/report-builder/min-max-input", ["exports", "affinio/mixins/input-module"], function (_exports, _inputModule) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_inputModule.default, {
    classNames: ['min-max-input']
  });
});