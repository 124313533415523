define("affinio/mixins/models/lurker-percentage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    // this is used for getting all the metric models on clusters
    // We use this intermediate cp incase we want a cp to fire when any of the metrics change state
    // TODO: Make this a generic computed as I suspect we will want this in multiple places
    everyClusterLurkerPercentage: Ember.computed.mapBy('clusters', 'lurkerPercentage'),
    everyClusterLurkerPercentageResponse: Ember.computed.mapBy('everyClusterLurkerPercentage', 'response'),
    hasLurkers: Ember.computed('everyClusterLurkerPercentageResponse.@each.value', function () {
      return Ember.get(this, 'everyClusterLurkerPercentageResponse').some(function (n) {
        return Ember.get(n, 'value');
      });
    })
  });
});