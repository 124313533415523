define("affinio/components/folder-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['folder-list'],
    tagName: 'ul',
    modalManager: Ember.inject.service(),
    store: Ember.inject.service(),
    user: Ember.inject.service(),
    sortedFolders: Ember.computed('folders.@each.name', function () {
      return Ember.get(this, 'folders').sortBy('name');
    }),
    openEdit: function openEdit(folder) {
      Ember.get(this, 'modalManager').openModal('modals/modal-edit-folder', {
        folder: folder,
        okAction: Ember.get(this, 'saveFolder'),
        clearFolderCP: Ember.get(this, 'clearFolderCP')
      });
    }
  });
});