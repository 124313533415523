define("affinio/templates/components/pagination-pager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "EujADplX",
    "block": "{\"symbols\":[\"p\",\"i\"],\"statements\":[[7,\"a\"],[12,\"class\",[27,\"unless\",[[23,[\"prevPageNeeded\"]],\"disabled\"],null]],[11,\"role\",\"button\"],[9],[0,\"\\n  < Previous\\n\"],[3,\"action\",[[22,0,[]],[27,\"if\",[[23,[\"prevPageNeeded\"]],[27,\"action\",[[22,0,[]],\"changePage\",[27,\"dec\",[[23,[\"page\"]]],null]],null],[27,\"optional\",null,null]],null]]],[10],[0,\"\\n\\n\"],[7,\"span\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"pages\"]]],null,{\"statements\":[[0,\"    \"],[7,\"a\"],[12,\"class\",[27,\"if\",[[22,1,[\"active\"]],\"active\"],null]],[11,\"role\",\"button\"],[9],[1,[22,1,[\"first\"]],false],[0,\"-\"],[1,[22,1,[\"last\"]],false],[3,\"action\",[[22,0,[]],\"changePage\",[22,2,[]]]],[10],[0,\"\\n\"]],\"parameters\":[1,2]},null],[10],[0,\"\\n\\n\"],[7,\"a\"],[12,\"class\",[27,\"unless\",[[23,[\"nextPageNeeded\"]],\"disabled\"],null]],[11,\"role\",\"button\"],[9],[0,\"\\n  Next >\\n\"],[3,\"action\",[[22,0,[]],[27,\"if\",[[23,[\"nextPageNeeded\"]],[27,\"action\",[[22,0,[]],\"changePage\",[27,\"inc\",[[23,[\"page\"]]],null]],null],[27,\"optional\",null,null]],null]]],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/pagination-pager.hbs"
    }
  });
});