define("affinio/services/asset-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    enabled: false,
    map: null,
    prepend: '/',
    resolve: function resolve(name) {
      var map = Ember.get(this, 'map');
      var enabled = Ember.get(this, 'enabled');
      if (enabled && map && map.hasOwnProperty(name)) {
        return "".concat(Ember.get(this, 'prepend')).concat(map[name]);
      } else {
        return name;
      }
    }
  });
});