define("affinio/templates/components/auth-notify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "LvZG5ZkW",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"auth-notify-wrapper \",[27,\"if\",[[22,0,[\"visible\"]],\"visible\"],null]]]],[9],[10],[0,\"\\n\"],[7,\"div\"],[12,\"class\",[28,[\"auth-notify \",[27,\"if\",[[22,0,[\"extending\"]],\"extending\"],null],\" \",[27,\"if\",[[22,0,[\"visible\"]],\"visible\"],null]]]],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"extending\"]]],null,{\"statements\":[[0,\"    Extending...\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"p\"],[9],[0,\"Your session will expire soon if you don’t extend it by another hour.\"],[10],[0,\"\\n    \"],[7,\"a\"],[11,\"class\",\"button\"],[11,\"role\",\"button\"],[9],[0,\"Extend session\"],[3,\"action\",[[22,0,[]],\"onClick\"]],[10],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/auth-notify.hbs"
    }
  });
});