define("affinio/utils/constants/date-formats", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unixTimestamp = _exports.shortYearDotSeparated = _exports.longYearDashSeparatedInverse = _exports.longYearDashSeparated = _exports.fullShortMonth = _exports.fullMonthWithTime = _exports.fullMonth = _exports.fullDayMonth = _exports.default = void 0;
  /**
   * moment.js format 'DD.MM.YY' ex: 29.01.18
   * @property shortYearDotSeparated
   * @type String
   */
  var shortYearDotSeparated = _exports.shortYearDotSeparated = 'DD.MM.YY';

  /**
   * moment.js format 'YYYY-MM-DD' ex: 2018-01-29
   * @property longYearDashSeparated
   * @type String
   */
  var longYearDashSeparated = _exports.longYearDashSeparated = 'YYYY-MM-DD';

  /**
   * moment.js format 'DD-MMM-YYYY' ex: 08-Nov-2018
   * @property longYearDashSeparatedInverse
   * @type String
   */
  var longYearDashSeparatedInverse = _exports.longYearDashSeparatedInverse = 'DD-MMM-YYYY';

  /**
   * moment.js format 'MMMM Do, YYYY' ex: January 29th, 2018
   * @property fullMonth
   * @type String
   */
  var fullMonth = _exports.fullMonth = 'MMMM Do, YYYY';

  /**
   * moment.js format 'MMM. D, YYYY' ex: Jan. 29, 2018 or 'MMM D, YYYY' ex: May 29, 2018 for months with only 3 letters
   * @property fullShortMonth
   * @type String
   */

  var fullShortMonth = _exports.fullShortMonth = function fullShortMonth(date) {
    if ((0, _moment.default)(date).format('MMMM') === 'May') {
      return (0, _moment.default)(date).format('MMM D, YYYY');
    } else {
      return (0, _moment.default)(date).format('MMM. D, YYYY');
    }
  };

  /**
   * moment.js format 'MMMM Do, YYYY, h:mma' ex: January 29th, 2018, 12:17pm
   * @property fullMonthWithTime
   * @type String
   */
  var fullMonthWithTime = _exports.fullMonthWithTime = 'MMMM Do, YYYY, h:mma';

  /**
   * moment.js format 'X' ex: 1517847513
   * @property unixTimestamp
   * @type String
   */
  var unixTimestamp = _exports.unixTimestamp = 'X';

  /**
   * moment.js format 'dddd, MMMM Do' ex: Monday, January 29th
   * @property fullDayMonth
   * @type String
   */
  var fullDayMonth = _exports.fullDayMonth = 'dddd, MMMM Do';

  /**
   * @module Utils
   * @submodule Constants
   * @class DateFormats
   */
  var _default = _exports.default = {
    shortYearDotSeparated: shortYearDotSeparated,
    longYearDashSeparated: longYearDashSeparated,
    longYearDashSeparatedInverse: longYearDashSeparatedInverse,
    fullMonth: fullMonth,
    fullShortMonth: fullShortMonth,
    fullMonthWithTime: fullMonthWithTime,
    unixTimestamp: unixTimestamp,
    fullDayMonth: fullDayMonth
  };
});