define("affinio/models/metric", ["exports", "ember-data", "affinio/utils/get-with-default"], function (_exports, _emberData, _getWithDefault) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var Model = _emberData.default.Model;
  var _default = _exports.default = Model.extend({
    // metric id's are of the form <parentId>___<version>___<keyname>. We use this instead of manually setting these properties
    idSplit: Ember.computed('id', function () {
      return Ember.get(this, 'id').split('___');
    }),
    parentId: Ember.computed.alias('idSplit.0'),
    version: Ember.computed.alias('idSplit.1'),
    key: Ember.computed.alias('idSplit.2'),
    reportId: Ember.computed('parentId', function () {
      return Ember.get(this, 'parentId').split('_').objectAt(0);
    }),
    report: Ember.computed('reportID', function () {
      var store = Ember.get(this, 'store');
      return store.peekRecord("reports/".concat(Ember.get(this, 'network')), Ember.get(this, 'reportId'));
    }),
    cluster: Ember.computed('idSplit', function () {
      var store = Ember.get(this, 'store');
      // This is needed in the runPairs function below
      return store.peekRecord("clusters/".concat(Ember.get(this, 'network')), Ember.get(this, 'parentId'));
    }),
    metricDefinition: attr({
      defaultValue: function defaultValue() {
        return {};
      }
    }),
    graphQLType: Ember.computed.alias('metricDefinition.graphQLType'),
    _properties: attr('string', {
      defaultValue: ''
    }),
    properties: Ember.computed.or('_properties', 'metricDefinition.properties'),
    label: Ember.computed.alias('metricDefinition.label'),
    title: Ember.computed.alias('metricDefinition.label'),
    description: Ember.computed.alias('metricDefinition.description'),
    metricLevel: Ember.computed.alias('metricDefinition.level'),
    defaultValue: Ember.computed.alias('metricDefinition.defaultValue'),
    initialVersionOnly: Ember.computed.alias('metricDefinition.initialVersionOnly'),
    modelAlias: Ember.computed.alias('metricDefinition.modelAlias'),
    referencingModules: Ember.computed.alias('metricDefinition.referencingModules'),
    referencingModuleMetrics: Ember.computed('referencingModules.@each.metrics', function () {
      return Ember.get(this, 'referencingModules').mapBy('metrics');
    }),
    defaultSliceSize: 20,
    sliceSize: Ember.computed.or('metricDefinition.sliceSize', 'defaultSliceSize'),
    loading: false,
    // to avoid fetching in flight metrics
    // by default attr's will be undefined. We have possibility for a response to be null (and represent that it has been loaded)
    loaded: Ember.computed('_response', function () {
      return Ember.get(this, '_response') === undefined ? false : true;
    }),
    checkIfCached: function checkIfCached() {
      var _this = this;
      return Ember.get(this, 'cached').then(function (isCached) {
        return Ember.set(_this, 'isCached', isCached);
      });
    },
    cached: Ember.computed(function () {
      var _this2 = this;
      var cacheName = "".concat(Ember.get(this, 'reportId'), "_").concat(Ember.get(this, 'version'));
      var url = "/".concat(Ember.get(this, 'parentId'), "_").concat(Ember.get(this, 'key'));
      if (window.caches) {
        return caches.keys().then(function (keys) {
          return keys.includes(cacheName);
        }, function () {
          // console.log('error on getting cache keys', err);
        }).then(function (reportIsCached) {
          if (reportIsCached) {
            return caches.open(cacheName).then(function (cache) {
              // console.log('hey I have this report cached!', cache);
              return cache.match(url).then(function (item) {
                // console.log('is the item cached tho', item);
                return item ? item.json() // readableStream in a promise; .json() inherent method to extract in foundCache below
                : false;
              }).then(function (foundCache) {
                if (foundCache) {
                  // console.log('found the metric itself', foundCache);
                  Ember.set(_this2, 'loaded', true); // eslint-disable-line ember/no-side-effects
                  Ember.set(_this2, '_response', foundCache); // eslint-disable-line ember/no-side-effects
                  return true;
                }
              });
            });
          } else {
            return false;
          }
        });
      } else {
        return new Promise(function (resolve) {
          return resolve(false);
        });
      }
    }),
    _response: attr(),
    response: Ember.computed('_response', 'loaded', 'defaultValue', 'metricDefinition', 'metricDefinition.pairedMetrics.[]', function () {
      if (Ember.get(this, 'metricDefinition')) {
        if (Ember.get(this, 'loaded')) {
          if (Ember.get(this, 'metricDefinition.pairedMetrics.length')) {
            if (Ember.get(this, '_response.length')) {
              this.runPairs();
            }
          }
          return Ember.get(this, '_response');
        } else {
          switch (Ember.get(this, 'defaultValue')) {
            case 'array':
              return [];
            case 'object':
              return {};
            case 'number':
              return {
                value: 0
              };
            case 'string':
              return '';
            default:
              return Ember.get(this, '_response');
          }
        }
      }
    }),
    runPairs: function runPairs() {
      var _this3 = this;
      // console.time(`runPairs ${get(this, 'id')}`)

      var response = Ember.get(this, '_response');
      var store = Ember.get(this, 'store');
      var isCustom = Ember.get(this, 'report.isCustom');
      var cluster = store.peekRecord("clusters/".concat(Ember.get(this, 'network')), Ember.get(this, 'parentId'));
      if (cluster) {
        // response.setEach('saturations', []);

        response.forEach(function (instance) {
          var numberOfMembers = Ember.get(cluster, 'numberOfMembers.response.value');
          // console.log('k value for', get(instance, 'label'), get(instance, 'value'), numberOfMembers, get(cluster, 'numberOfMembers.response'), cluster);
          var value = Ember.get(instance, 'relevance') ? isCustom ? Ember.get(instance, 'value') / numberOfMembers * 100 : Ember.get(instance, 'value') : Ember.get(instance, 'value') / numberOfMembers * 100;
          Ember.set(instance, 'saturations', [{
            filters: {
              type: 'audience',
              value: 'audience'
            },
            value: value
          }]);
        });
        Ember.get(this, 'metricDefinition.pairedMetrics').forEach(function (pm) {
          if (!Ember.get(pm, 'source')) {
            Ember.set(pm, 'source', 'cluster');
          }
          var pairedMetric;
          if (Ember.get(pm, 'source') === 'cluster') {
            pairedMetric = Ember.get(Ember.get(_this3, 'cluster'), Ember.get(pm, 'paired'));
          } else if (Ember.get(pm, 'source') === 'external') {
            pairedMetric = store.peekRecord('metric', Ember.get(pm, 'paired'));
          }
          response.forEach(function (instance) {
            // console.log('gonna uh, set value to uh,', get(get(pairedMetric, '_response').findBy('label', get(instance, 'label')) || {}, 'value'))
            Ember.get(instance, 'saturations').pushObject({
              filters: {
                type: 'global',
                value: Ember.get(pm, 'pairedType') || 'saturation',
                paired: Ember.get(pm, 'paired'),
                title: Ember.get(pm, 'title')
              },
              value: Ember.get(Ember.get(pairedMetric, '_response').findBy('label', Ember.get(instance, 'label')) || {}, 'saturation')
            });
            // console.log('posthoc', get(instance, 'label'), get(get(pairedMetric, '_response').findBy('label', get(instance, 'label')) || {}, 'value'));
          });
        });

        // Final pass; we establish the instance-level properties like diffFromBaseline, etc.
        response.forEach(function (instance) {
          var saturations = Ember.get(instance, 'saturations');
          var audienceSaturation = (0, _getWithDefault.default)(saturations.findBy('filters.type', 'audience'), 'value', 0);
          var baselineSaturation = (0, _getWithDefault.default)(saturations.findBy('filters.type', 'global'), 'value', 0); // assume baseline is the first global saturation
          Ember.set(instance, 'baselineSaturation', baselineSaturation);
          Ember.set(instance, 'audienceSaturation', audienceSaturation);
          Ember.set(instance, 'diffFromBaseline', baselineSaturation ? audienceSaturation - baselineSaturation : Infinity);
        });
        Ember.set(this, 'baselineEstablished', true);
      }

      // console.timeEnd(`runPairs ${get(this, 'id')}`)
    }
  });
});