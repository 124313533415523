define("affinio/templates/components/modules/page-title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "cQbBjA2q",
    "block": "{\"symbols\":[\"description\"],\"statements\":[[7,\"h2\"],[9],[0,\"\\n  \"],[1,[27,\"if\",[[23,[\"moduleRequest\",\"label\"]],[23,[\"moduleRequest\",\"label\"]],[23,[\"model\",\"subcategory\",\"label\"]]],null],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"each\",[[23,[\"model\",\"subcategory\",\"descriptions\"]]],null,{\"statements\":[[0,\"  \"],[7,\"p\"],[9],[0,\"\\n    \"],[1,[22,1,[]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/modules/page-title.hbs"
    }
  });
});