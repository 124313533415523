define("affinio/helpers/selection-checker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.selectionChecker = selectionChecker;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  function selectionChecker(params /* , hash*/) {
    if ((params.length === 3 || params.length === 4 // 4 is for boolean to force update
    ) && _typeof(params[0]) === 'object' // obj
    && typeof params[1] === 'string' // key name of array of checkboxes
    && typeof params[2] === 'string') {
      // array's checked key name
      return Ember.get(params[0], params[1]).any(function (n) {
        return n.checked;
      });
    } else {
      throw new Error('Incorrect helper usage:\nFirst param: object that contains data\nSecond param: keyname of array\nThird param: keyname of value in array');
    }
  }
  var _default = _exports.default = Ember.Helper.helper(selectionChecker);
});