define("affinio/utils/queries/toolbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reportSearchByNameOrId = _exports.monitoredReportSearchByNameOrId = _exports.getAssetGroupsByID = _exports.folderSearchByNameOrId = _exports.default = _exports.companySearchByNameOrId = _exports.accountSearchByNameOrId = void 0;
  var reportSearchByNameOrId = _exports.reportSearchByNameOrId = function reportSearchByNameOrId(query) {
    return {
      networkName: "reportSearchByNameOrId-".concat(query),
      queryString: "query {\n      toolboxSearchReportsByNameOrId(query: \"".concat(query, "\"){\n        id\n        name\n        creator{\n          email\n        }\n      }\n    }"),
      queryMap: {}
    };
  };
  var monitoredReportSearchByNameOrId = _exports.monitoredReportSearchByNameOrId = function monitoredReportSearchByNameOrId(query) {
    return {
      networkName: "monitoredReportSearchByNameOrId-".concat(query),
      queryString: "query {\n      toolboxSearchMonitoredReportsByNameOrId(query: \"".concat(query, "\"){\n        id\n        name\n        creator{\n          email\n        }\n      }\n    }"),
      queryMap: {}
    };
  };
  var accountSearchByNameOrId = _exports.accountSearchByNameOrId = function accountSearchByNameOrId(query) {
    return {
      networkName: "accountSearchByNameOrId-".concat(query),
      queryString: "query {\n      toolboxSearchAccountsByNameOrEmailOrId(query: \"".concat(query, "\"){\n        id\n        firstName\n        lastName\n        email\n      }\n    }"),
      queryMap: {}
    };
  };
  var companySearchByNameOrId = _exports.companySearchByNameOrId = function companySearchByNameOrId(query) {
    return {
      networkName: "companySearchByName-".concat(query),
      queryString: "query {\n      toolboxSearchCompaniesByNameOrId(query: \"".concat(query, "\"){\n        id\n        name\n      }\n    }"),
      queryMap: {}
    };
  };
  var folderSearchByNameOrId = _exports.folderSearchByNameOrId = function folderSearchByNameOrId(query) {
    return {
      networkName: "folderSearchByNameOrId-".concat(query),
      queryString: "query {\n      toolboxSearchFoldersByNameOrId(query: \"".concat(query, "\"){\n        id\n        name\n        owner{\n          email\n        }\n      }\n    }"),
      queryMap: {}
    };
  };
  var getAssetGroupsByID = _exports.getAssetGroupsByID = function getAssetGroupsByID(id) {
    return {
      networkName: "assetGroupsById-".concat(id),
      queryString: "query {\n      toolboxGetAssetGroupByOwnerID(id: \"".concat(id, "\"){\n        id\n        assetType\n        name\n        assets {\n          name\n          id\n        }\n        socialNetwork\n        dateCreated\n        dateUpdated\n      }\n    }"),
      queryMap: {}
    };
  };
  var _default = _exports.default = {
    reportSearchByNameOrId: reportSearchByNameOrId,
    monitoredReportSearchByNameOrId: monitoredReportSearchByNameOrId,
    accountSearchByNameOrId: accountSearchByNameOrId,
    companySearchByNameOrId: companySearchByNameOrId,
    folderSearchByNameOrId: folderSearchByNameOrId,
    getAssetGroupsByID: getAssetGroupsByID
  };
});