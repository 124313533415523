define("affinio/templates/components/modals/modal-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "wWrmqT4Z",
    "block": "{\"symbols\":[],\"statements\":[[7,\"form\"],[11,\"class\",\"modal\"],[11,\"tabindex\",\"0\"],[9],[0,\"\\n  \"],[7,\"h2\"],[9],[0,\"Please wait\"],[10],[0,\"\\n  \"],[1,[21,\"loading-spinner\"],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"backdrop\"],[11,\"role\",\"button\"],[9],[3,\"action\",[[22,0,[]],\"close\"]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/modals/modal-loading.hbs"
    }
  });
});