define("affinio/routes/admin/users/company/user", ["exports", "affinio/utils/queries", "affinio/utils/mutations", "affinio/mixins/auth-check", "affinio/mixins/document-title"], function (_exports, _queries, _mutations, _authCheck, _documentTitle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
  var _default = _exports.default = Ember.Route.extend(_authCheck.default, _documentTitle.default, {
    admin: Ember.inject.service(),
    graph: Ember.inject.service(),
    store: Ember.inject.service(),
    user: Ember.inject.service(),
    notify: Ember.inject.service('notify'),
    tracker: Ember.inject.service(),
    api: Ember.inject.service(),
    authOnly: true,
    documentTitle: 'User - Admin - Affinio',
    showAccounts: false,
    model: function model(params) {
      var store = Ember.get(this, 'store');
      var companyID = this.modelFor('admin.users.company');
      var userId = params.user_id;
      var graph = Ember.get(this, 'graph');
      var admin = Ember.get(this, 'admin');
      var schema = Ember.get(this, 'user.account.isAffinio') ? 'private' : 'public';
      return userId === 'new' ? Ember.Object.create({
        company: store.peekRecord('company', companyID),
        account: {
          role: 'Regular'
        }
      }) : Ember.RSVP.all([schema === 'private' ? graph.query(_queries.default.allPermissions, true) : Ember.RSVP.Promise.resolve(), graph.query(_queries.default.user(userId, companyID, schema)), admin.loadPaginatedReports(userId, null, 'account', 0, 100)]).then(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
          permissionsRaw = _ref2[0],
          responseObject = _ref2[1];
        var r = {
          company: responseObject.company,
          account: responseObject.account,
          accountReports: admin.getReportsForEntity(userId, 'account')
        };
        if (schema === 'private') {
          r.permissions = permissionsRaw.data.allPermissions;
        }
        return r;
      });
    },
    actions: {
      addToGroup: function addToGroup(entitie) {
        var _this = this;
        var notify = Ember.get(this, 'notify');
        var tracker = Ember.get(this, 'tracker');
        var graph = Ember.get(this, 'graph');
        var accountID = Ember.get(this.controller, 'model.account.id');
        var groupID = Ember.get(entitie, 'id');
        graph.mutate(_mutations.default.shareGroupToAccount(accountID, groupID)).then(function () {
          tracker.track('Added User to Group');
          notify.success("".concat(Ember.get(_this.controller, 'model.account.firstName'), " ").concat(Ember.get(_this.controller, 'model.account.lastName'), " added to ").concat(Ember.get(entitie, 'name')));
          Ember.get(_this.controller, 'model.account.groups').pushObject(entitie);
        }).catch(function (err) {
          Ember.get(_this, 'notify').error(err.payload.errors[0].message);
        });
      },
      removeFromGroup: function removeFromGroup(entitie) {
        var _this2 = this;
        var notify = Ember.get(this, 'notify');
        var tracker = Ember.get(this, 'tracker');
        var graph = Ember.get(this, 'graph');
        var accountID = Ember.get(this.controller, 'model.account.id');
        var groupID = Ember.get(entitie, 'id');
        graph.mutate(_mutations.default.unshareGroupFromAccount(accountID, groupID)).then(function () {
          tracker.track('Removed User from Group');
          notify.success("".concat(Ember.get(_this2.controller, 'model.account.firstName'), " ").concat(Ember.get(_this2.controller, 'model.account.lastName'), " removed from ").concat(Ember.get(entitie, 'name')));
          Ember.get(_this2.controller, 'model.account.groups').removeObject(entitie);
        }).catch(function (err) {
          Ember.get(_this2, 'notify').error(err.payload.errors[0].message);
        });
      },
      addPermissionToAccount: function addPermissionToAccount(permission) {
        var _this3 = this;
        var notify = Ember.get(this, 'notify');
        var tracker = Ember.get(this, 'tracker');
        var graph = Ember.get(this, 'graph');
        var accountId = Ember.get(this.controller, 'model.account.id');
        graph.mutate(_mutations.default.addPermissionToAccount(accountId, permission)).then(function () {
          tracker.track('Added Permission to Account');
          notify.success("".concat(permission, " added to ").concat(Ember.get(_this3.controller, 'model.account.name')));
        }).catch(function (err) {
          Ember.get(_this3, 'notify').error(err.payload.errors[0].message);
        });
      },
      removePermissionFromAccount: function removePermissionFromAccount(permission) {
        var _this4 = this;
        var notify = Ember.get(this, 'notify');
        var tracker = Ember.get(this, 'tracker');
        var graph = Ember.get(this, 'graph');
        var accountId = Ember.get(this.controller, 'model.account.id');
        graph.mutate(_mutations.default.removePermissionFromAccount(accountId, permission)).then(function () {
          tracker.track('Removed Permission from Account');
          notify.success("".concat(permission, " removed from ").concat(Ember.get(_this4.controller, 'model.account.name')));
        }).catch(function (err) {
          Ember.get(_this4, 'notify').error(err.payload.errors[0].message);
        });
      },
      directShareReportToAccount: function directShareReportToAccount(reportID, shareOption, cb) {
        var _this5 = this;
        var notify = Ember.get(this, 'notify');
        var tracker = Ember.get(this, 'tracker');
        var graph = Ember.get(this, 'graph');
        var accountID = Ember.get(this.controller, 'model.account.id');
        var permission = shareOption === 'Edit' ? true : false;
        graph.mutate(_mutations.default.directShareReportToAccount(reportID, accountID, permission)).then(function (response) {
          var reportResponse = response.data.shareReportToAccount;
          // TODO update to new format
          var report = graph.recordFromGraphData('single', null, reportResponse);
          tracker.track('Added Report to Account');
          notify.success("".concat(Ember.get(report, 'name'), " added to ").concat(Ember.get(_this5.controller, 'model.account.name')));
          Ember.get(_this5.controller, 'model.account.reports').pushObject(report);
        }).then(function () {
          if (cb && typeof cb === 'function') {
            cb();
          }
        }).catch(function (err) {
          Ember.get(_this5, 'notify').error(err.payload.errors[0].message);
        });
      },
      resetPassword: function resetPassword() {
        var api = Ember.get(this, 'api');
        var notify = Ember.get(this, 'notify');
        var tracker = Ember.get(this, 'tracker');
        var email = Ember.get(this.controller, 'model.account.email');
        api.request("requestPasswordResetEmail/".concat(email), {
          dataType: 'text'
        }).then(function () {
          tracker.track('Admin Requested Account Password Reset');
          notify.success('Password reset email sent.');
        }).catch(function (err) {
          tracker.track('Admin Requested Account Password Reset Error', {
            error: JSON.stringify(err)
          });
          notify.error(err);
        });
      }
    }
  });
});