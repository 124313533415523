define("affinio/components/delta-card", ["exports", "affinio/utils/get-with-default", "affinio/utils/constants/metrics"], function (_exports, _getWithDefault, _metrics) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Element
    classNames: ['delta-card'],
    tagName: 'article',
    // Services
    path: Ember.inject.service(),
    // Properties
    metrics: _metrics.default,
    // used in the hbs to show plus/minus and apply classes
    rankDirection: Ember.computed('delta', function () {
      var rankDelta = Ember.get(this, 'delta.origin.delta.rankDelta');
      return rankDelta === 0 ? null : rankDelta > 0 ? 'decrease' : 'increase';
    }),
    rankValue: Ember.computed('delta', function () {
      return (0, _getWithDefault.default)(this, 'delta.origin.delta.absoluteRankDelta', 0).toFixed(0);
    }),
    valueDirection: Ember.computed('delta', function () {
      var valueDelta = Ember.get(this, 'delta.origin.delta.valueDelta');
      return valueDelta === 0 ? null : valueDelta > 0 ? 'increase' : 'decrease';
    })
  });
});