define("affinio/templates/components/cluster-occurrence-comparison", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "SzWca5On",
    "block": "{\"symbols\":[\"occurrence\"],\"statements\":[[4,\"each\",[[23,[\"clusterOccurrences\"]]],null,{\"statements\":[[0,\"  \"],[7,\"li\"],[12,\"class\",[27,\"if\",[[27,\"is-equal\",[[22,1,[\"clusterSuffix\"]],[23,[\"cluster\",\"suffix\"]]],null],\"active\"],null]],[12,\"title\",[27,\"if\",[[23,[\"showValue\"]],[22,1,[\"value\"]]],null]],[9],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"name\"],[9],[1,[22,1,[\"name\"]],false],[10],[0,\"\\n    \"],[7,\"img\"],[11,\"class\",\"icon\"],[12,\"src\",[27,\"html-safe\",[[23,[\"instanceIcon\"]]],null]],[12,\"alt\",[21,\"metricTitle\"]],[12,\"title\",[21,\"metricTitle\"]],[9],[10],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"occurrence-bar\"],[12,\"style\",[27,\"html-safe\",[[22,1,[\"style\"]]],null]],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/cluster-occurrence-comparison.hbs"
    }
  });
});