define("affinio/templates/components/folder-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "6GlImW2B",
    "block": "{\"symbols\":[\"reports\"],\"statements\":[[4,\"link-to\",[[23,[\"route\"]],[27,\"query-params\",null,[[\"folderParam\"],[[23,[\"folderParam\"]]]]]],[[\"class\"],[\"button\"]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"folder-description\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"model\",\"accountAccess\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"font-awesome\",[\"folder-o\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[27,\"font-awesome\",[\"share-alt share-icon\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n    \"],[1,[27,\"hover-span\",null,[[\"text\"],[[23,[\"model\",\"name\"]]]]],false],[0,\"\\n\"],[4,\"let\",[[27,\"filter\",[[27,\"action\",[[22,0,[]],\"filterReports\"],null],[23,[\"model\",\"reports\"]]],null]],null,{\"statements\":[[0,\"      (\"],[1,[22,1,[\"length\"]],false],[0,\")\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"nav\"],[9],[0,\"\\n  \"],[7,\"button\"],[11,\"title\",\"Edit\"],[9],[0,\"\\n    \"],[1,[27,\"font-awesome\",[\"pencil\"],null],false],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],[23,[\"openEdit\"]],[23,[\"model\"]],\"folder\"]],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/folder-list-item.hbs"
    }
  });
});