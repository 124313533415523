define("affinio/components/cluster-sub-nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.LinkComponent.extend({
    // Element
    classNames: ['cluster-sub-nav'],
    classNameBindings: ['all', 'isHeld:held', 'semanticClusterClass', 'orderedClusterClass', 'cluster.compareSelected:held', 'cluster.isHidden:isHidden', 'cluster.isClusterRemoved:isClusterRemoved'],
    // tagName: 'a',

    // Services
    user: Ember.inject.service(),
    tracker: Ember.inject.service(),
    path: Ember.inject.service(),
    preferences: Ember.inject.service(),
    // TODO: note - I tried adding cluster ids as a css class for easy click driving with selenium, e.g. 1234_0
    // numeric CSS selectors have proven to crash selenium. this format is: 'cluster-all', 'cluster-0', ...
    semanticClusterClass: Ember.computed('cluster', function () {
      return Ember.get(this, 'cluster.id') ? "cluster-".concat(Ember.get(this, 'cluster.id').split('_').pop()) : null;
    }),
    orderedClusterClass: Ember.computed('cluster.{staticMemberRank,memberRank.response.value}', 'model.report.socialNetwork', function () {
      var staticMemberRank = Ember.get(this, 'cluster.staticMemberRank');
      var memberRank = Ember.get(this, 'model.report.socialNetwork') === 'Twitter' ? Ember.get(this, 'cluster.memberRank.response.value') : Ember.get(this, 'cluster.memberRank');
      return Ember.isPresent(staticMemberRank) ? "cluster-order-".concat(staticMemberRank) : Ember.isPresent(memberRank) ? "cluster-order-".concat(memberRank) : "cluster-order-".concat(Ember.get(this, 'cluster.index') + 1);
    }),
    isHeld: Ember.computed('preferences.heldCluster', function () {
      return Ember.get(this, 'preferences.heldCluster') === Ember.get(this, 'suffix');
    }),
    hasNotesToggle: Ember.computed.or('canEdit', 'cluster.notes.text.length'),
    // Computed Properties
    account: Ember.computed.alias('user.account'),
    click: function click(evt) {
      if (!Ember.get(this, 'isReaggregating') && !Ember.$(evt.target).is('input[type="checkbox"]')) {
        if (Ember.get(this, 'isCompare')) {
          if (!Ember.get(this, 'twoClusters') || Ember.get(this, 'cluster.compareSelected')) {
            this.toggleProperty('cluster.compareSelected');
          }
        }
        if (Ember.get(this, 'isEditReport')) {
          this.toggleProperty('cluster.selected');
        }
      }
    },
    mouseEnter: function mouseEnter() {
      var cluster = Ember.get(this, 'cluster');
      if (cluster && !Ember.get(cluster, 'isAggregate')) {
        Ember.set(this, 'model.report.hoveredCluster', Ember.get(cluster, 'id'));
      }
    },
    mouseLeave: function mouseLeave() {
      var model = Ember.get(this, 'model');
      if (model) {
        Ember.set(model, 'report.hoveredCluster', null);
      }
    }
  });
});