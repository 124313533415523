define("affinio/templates/components/modules/screenshots-exporter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "6IslCTnm",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"model\",\"report\",\"isReaggregating\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\"],[11,\"class\",\"info-notification\"],[9],[0,\"\\n    \"],[7,\"span\"],[9],[0,\"This action is not available while your report is being updated. Please check back later.\"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"model\",\"requestsFulfilled\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"screenshot-modules\",null,[[\"model\",\"report\",\"reportMap\"],[[23,[\"model\"]],[23,[\"model\",\"report\"]],[23,[\"model\",\"reportMapPojo\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[21,\"loading-spinner\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/modules/screenshots-exporter.hbs"
    }
  });
});