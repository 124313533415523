define("affinio/utils/queries/folders", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.updateFoldersReports = _exports.default = void 0;
  var updateFoldersReports = _exports.updateFoldersReports = function updateFoldersReports(folders) {
    var queries = Ember.A();
    var queryMap = {};
    folders.forEach(function (folder, i) {
      var alias = "folder".concat(i);
      queries.pushObject("\n      ".concat(alias, ":folder(id: \"").concat(Ember.get(folder, 'id'), "\"){\n        id\n        reports {\n          id\n          socialNetwork\n          folders {\n            id\n            shareInfo {\n              canEdit\n            }\n          }\n        }\n      }\n    "));
      queryMap[alias] = {
        array: false,
        modelName: 'folder',
        responseKey: alias
      };
    });
    return {
      networkName: 'updateFoldersReports',
      queryString: "query {\n      ".concat(queries, "\n    }"),
      queryMap: queryMap
    };
  };
  var _default = _exports.default = {
    updateFoldersReports: updateFoldersReports
  };
});