define("affinio/routes/reports/report/version/cluster", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    graph: Ember.inject.service(),
    path: Ember.inject.service(),
    model: function model(params) {
      var report = this.modelFor('reports.report');
      var cluster = params.cluster_id === Ember.get(report, 'clusterAggregation.id') ? Ember.get(report, 'clusterAggregation') : Ember.get(report, '_clusters').findBy('id', params.cluster_id);

      // Update path
      Ember.set(this, 'path.currentClusterId', params.cluster_id);
      Ember.set(this, 'path.currentClusterSuffix', Ember.get(cluster, 'suffix'));
      Ember.set(this, 'path.currentClusterIndex', Ember.get(report, '_clusters').indexOf(cluster));
      return cluster;
    },
    afterModel: function afterModel(model, transition) {
      var report = this.modelFor('reports.report');
      var version = this.modelFor('reports.report.version');
      var cluster = model;
      var validCats = Ember.get(report, 'taxonomy.schema').filter(function (cat) {
        return cat.dependencyCheck({
          report: report,
          cluster: cluster
        });
      });
      var cat = validCats.objectAt(0);
      var validSubcats = Ember.get(cat, 'subcategories').filter(function (subcat) {
        return subcat.dependencyCheck({
          report: report,
          cluster: cluster
        });
      });

      // for hidden, removed or merged, clusters, redirect to report level
      if (Ember.get(cluster, 'isClusterRemoved') || Ember.get(cluster, 'mergedInto') || Ember.get(cluster, 'isHidden')) {
        this.transitionTo('reports.report.version.cluster.category.subcategory', Ember.get(report, 'id'), version, Ember.get(report, 'clusterAggregation.id'), Ember.get(cat, 'value'), Ember.get(validSubcats, '0.value'));
      }

      // If the user didn't enter any ids past cluster, still get them into the report
      if (transition.targetName === 'reports.report.version.cluster.index') {
        if (validCats.length) {
          if (validSubcats.length) {
            this.transitionTo('reports.report.version.cluster.category.subcategory', Ember.get(report, 'id'), version, Ember.get(cluster, 'id'), Ember.get(cat, 'value'), Ember.get(validSubcats, '0.value'));
          }
        }

        // Didn't transition to valid cat/subcat, lets 404 (unlikely)
        this.transitionTo('reports.report.version.cluster.category.subcategory', '404', '404');
      }
    }
  });
});