define("affinio/routes/admin/companies/company", ["exports", "affinio/utils/queries", "affinio/utils/mutations", "affinio/mixins/auth-check", "affinio/mixins/document-title", "moment"], function (_exports, _queries, _mutations, _authCheck, _documentTitle, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
  var _default = _exports.default = Ember.Route.extend(_authCheck.default, _documentTitle.default, {
    admin: Ember.inject.service(),
    graph: Ember.inject.service(),
    store: Ember.inject.service(),
    notify: Ember.inject.service('notify'),
    tracker: Ember.inject.service(),
    authOnly: true,
    documentTitle: 'Company - Admin - Affinio',
    showAccounts: false,
    model: function model(params) {
      var companyId = params.id;
      var graph = Ember.get(this, 'graph');
      var admin = Ember.get(this, 'admin');
      return companyId === 'new' ? Ember.Object.create({
        company: {}
      }) : Ember.RSVP.all([graph.query(_queries.default.allPermissions, true), graph.query(_queries.default.company(companyId)), admin.loadPaginatedReports(companyId, null, 'company', 0, 100)]).then(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
          permissionsRaw = _ref2[0],
          responseObject = _ref2[1];
        return {
          company: responseObject.company,
          permissions: permissionsRaw.data.allPermissions,
          companyReports: admin.getReportsForEntity(companyId, 'company')
        };
      });
    },
    actions: {
      addPermissionToAccount: function addPermissionToAccount(entity, permission) {
        var _this = this;
        var notify = Ember.get(this, 'notify');
        var tracker = Ember.get(this, 'tracker');
        var graph = Ember.get(this, 'graph');
        var accountId = Ember.get(entity, 'id');
        graph.mutate(_mutations.default.addPermissionToAccount(accountId, permission)).then(function () {
          tracker.track('Added Permission to Account');
          notify.success("".concat(permission, " added to ").concat(Ember.get(entity, 'name')));
          // get(this.controller, 'model.company.features').pushObject(entity);
        }).catch(function (err) {
          Ember.get(_this, 'notify').error(err.payload.errors[0].message);
        });
      },
      removePermissionFromAccount: function removePermissionFromAccount(entity, permission) {
        var _this2 = this;
        var notify = Ember.get(this, 'notify');
        var tracker = Ember.get(this, 'tracker');
        var graph = Ember.get(this, 'graph');
        var accountId = Ember.get(entity, 'id');
        graph.mutate(_mutations.default.removePermissionFromAccount(accountId, permission)).then(function () {
          tracker.track('Removed Permission from Account');
          notify.success("".concat(permission, " Removed from ").concat(Ember.get(entity, 'name')));
          // get(this.controller, 'model.company.features').pushObject(entity);
        }).catch(function (err) {
          Ember.get(_this2, 'notify').error(err.payload.errors[0].message);
        });
      },
      addPermissionToAllAccountsInCompany: function addPermissionToAllAccountsInCompany(permission) {
        var _this3 = this;
        var notify = Ember.get(this, 'notify');
        var tracker = Ember.get(this, 'tracker');
        var graph = Ember.get(this, 'graph');
        var companyId = Ember.get(this.controller, 'model.company.id');
        graph.mutate(_mutations.default.addPermissionToAllAccountsInCompany(companyId, permission)).then(function (response) {
          tracker.track('Added Permission to All Accounts in Company');
          notify.success("".concat(permission, " added to all accounts in ").concat(Ember.get(_this3.controller, 'model.company.name')));
          response.data.addPermissionToAllAccountsInCompany.forEach(function (n) {
            var storeRecord = Ember.get(_this3, 'store').peekRecord('account', Ember.get(n, 'id'));
            if (storeRecord) {
              Ember.set(storeRecord, 'permissions', Ember.get(n, 'permissions'));
            }
          });
        }).catch(function (err) {
          Ember.get(_this3, 'notify').error(err.payload.errors[0].message);
        });
      },
      removePermissionFromAllAccountsInCompany: function removePermissionFromAllAccountsInCompany(permission) {
        var _this4 = this;
        var notify = Ember.get(this, 'notify');
        var tracker = Ember.get(this, 'tracker');
        var graph = Ember.get(this, 'graph');
        var companyId = Ember.get(this.controller, 'model.company.id');
        graph.mutate(_mutations.default.removePermissionFromAllAccountsInCompany(companyId, permission)).then(function (response) {
          tracker.track('Removed Permission from All Accounts in Company');
          notify.success("".concat(permission, " removed from all accounts in ").concat(Ember.get(_this4.controller, 'model.company.name')));
          response.data.removePermissionFromAllAccountsInCompany.forEach(function (n) {
            var storeRecord = Ember.get(_this4, 'store').peekRecord('account', Ember.get(n, 'id'));
            if (storeRecord) {
              Ember.set(storeRecord, 'permissions', Ember.get(n, 'permissions'));
            }
          });
        }).catch(function (err) {
          Ember.get(_this4, 'notify').error(err.payload.errors[0].message);
        });
      },
      turnTrialOn: function turnTrialOn() {
        Ember.set(this.controller, 'model.company.trialInfo', Ember.Object.create({
          isActive: true,
          startDate: new Date((0, _moment.default)()),
          endDate: new Date((0, _moment.default)().add(7, 'days'))
        }));
      },
      turnTrialOff: function turnTrialOff() {
        Ember.set(this.controller, 'model.company.trialInfo', null);
      },
      directShareReportToCompany: function directShareReportToCompany(reportID, shareOption, cb) {
        var _this5 = this;
        var notify = Ember.get(this, 'notify');
        var tracker = Ember.get(this, 'tracker');
        var graph = Ember.get(this, 'graph');
        var companyId = Ember.get(this.controller, 'model.company.id');
        var permission = shareOption === 'Edit' ? true : false;
        graph.mutate(_mutations.default.directShareReportToCompany(reportID, companyId, permission)).then(function (response) {
          var reportResponse = response.data.shareReportToCompany;
          var report = graph.recordFromGraphData(false, "reports/".concat(reportResponse.socialNetwork.toLowerCase()), reportResponse);
          tracker.track('Added Report to Company');
          notify.success("".concat(Ember.get(report, 'name'), " added to ").concat(Ember.get(_this5.controller, 'model.company.name')));
          Ember.get(_this5.controller, 'model.company.reports').pushObject(report);
        }).then(function () {
          if (cb && typeof cb === 'function') {
            cb();
          }
        }).catch(function (err) {
          Ember.get(_this5, 'notify').error(err.payload.errors[0].message);
        });
      }
    }
  });
});