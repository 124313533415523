define("affinio/templates/components/api-key-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "1jHCDf5E",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"icon\"],[9],[0,\"\\n  \"],[7,\"img\"],[12,\"src\",[21,\"sourceIcon\"]],[12,\"alt\",[28,[[21,\"source\"],\" Logo\"]]],[12,\"title\",[21,\"source\"]],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"input\"],[9],[0,\"\\n\"],[4,\"if\",[[27,\"and\",[[23,[\"not\"]],[23,[\"key\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"p\"],[9],[0,\"User has not entered a key\"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"key\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"input\",null,[[\"type\",\"class\",\"value\",\"disabled\"],[\"text\",\"api-key-input\",[23,[\"key\"]],true]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[27,\"input\",null,[[\"type\",\"class\",\"value\",\"placeholder\",\"enter\"],[\"text\",\"api-key-input\",[23,[\"keyToAdd\"]],\"API Key\",[27,\"action\",[[22,0,[]],\"submit\",true],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[10],[0,\"\\n\\n\"],[7,\"div\"],[12,\"class\",[27,\"if\",[[23,[\"key\"]],\"clear\",\"save\"],null]],[11,\"role\",\"button\"],[9],[0,\"\\n  \"],[1,[27,\"font-awesome\",[[27,\"if\",[[23,[\"key\"]],\"trash\",\"floppy-o\"],null]],null],false],[0,\"\\n\"],[3,\"action\",[[22,0,[]],\"submit\",[27,\"if\",[[23,[\"key\"]],false,true],null]]],[10],[0,\"\\n\"],[4,\"hover-tip\",null,null,{\"statements\":[[0,\"  \"],[7,\"a\"],[11,\"class\",\"hovertip-question\"],[9],[0,\"?\"],[10],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"format-description\"],[9],[0,\"\\n    \"],[1,[27,\"html-safe\",[[23,[\"description\"]]],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/api-key-input.hbs"
    }
  });
});