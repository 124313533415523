define("affinio/components/bar-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Element
    classNames: ['bar-chart'],
    classNameBindings: ['type'],
    tagName: 'div',
    colorScale: Ember.computed('source', function () {
      // console.log('SHIT',get(this, 'source.max'), get(this, 'source.min'), get(this, 'source.max') - ((get(this, 'source.max') - get(this, 'source.min'))/2));
      return d3.scale.linear().domain([Ember.get(this, 'source.min'), Ember.get(this, 'source.max') - (Ember.get(this, 'source.max') - Ember.get(this, 'source.min')) / 2, Ember.get(this, 'source.max')]).range(['#c00', '#ffffff', '#4DB930']);
    }),
    delayScale: Ember.computed('activeRange', 'source', function () {
      return d3.scale.linear().domain([0, Ember.get(this, 'source.max') / 2, Ember.get(this, 'source.max')]).range([150, 850, 150]);
    }),
    // TODO: pass down
    opacityScale: Ember.computed('activeRange', 'source', function () {
      if (Ember.get(this, 'activeRange') === 'max') {
        return d3.scale.linear().domain([Ember.get(this, 'source.min'), Ember.get(this, 'source.max')]).range([0.1, 1]);
      } else if (Ember.get(this, 'activeRange') === 'min') {
        return d3.scale.linear().domain([Ember.get(this, 'source.min'), Ember.get(this, 'source.max')]).range([1, 0.1]);
      } else {
        return d3.scale.linear().domain([0, 1]).range([1, 1]);
      }
    }),
    processedRecords: Ember.computed('activeRange', 'source', function () {
      var _this = this;
      // console.log('max', get(this, 'source.max'),'records', get(this, 'source.records'));

      var type = Ember.get(this, 'type');
      var axisStyleProperty;
      if (type === 'horizontal') {
        axisStyleProperty = 'width';
      }
      if (type === 'vertical') {
        axisStyleProperty = 'height';
      }
      return Ember.get(this, 'source.records').map(function (rec) {
        // console.log('rec', rec, 'source', get(this, 'source'));

        var style = Ember.String.htmlSafe("\n        ".concat(axisStyleProperty, ":").concat(rec.value / Ember.get(_this, 'source.max') * 100, "%;\n        background-color:").concat(Ember.get(_this, 'colorScale')(rec.value), ";\n        opacity: ").concat(Ember.get(_this, 'opacityScale')(rec.value), ";\n        transition-delay: ").concat(Ember.get(_this, 'delayScale')(rec.value), "ms;\n      "));
        Ember.set(rec, 'style', style); // eslint-disable-line ember/no-side-effects
        return rec;
      });
    }),
    actions: {
      processStyle: function processStyle(rec) {
        var type = Ember.get(this, 'type');
        var axisStyleProperty;
        if (type === 'horizontal') {
          axisStyleProperty = 'width';
        }
        if (type === 'vertical') {
          axisStyleProperty = 'height';
        }
        var style = Ember.String.htmlSafe("\n        ".concat(axisStyleProperty, ":").concat(rec.value / Ember.get(this, 'source.max') * 100, "%;\n        background-color:").concat(Ember.get(this, 'colorScale')(rec.value), ";\n        opacity: ").concat(Ember.get(this, 'opacityScale')(rec.value), ";\n        transition-delay: ").concat(Ember.get(this, 'delayScale')(rec.value), "ms;\n      "));
        return style;
      }
    }
  });
});