define("affinio/components/macro-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  // TODO: handle w/ Piano in application.js
  function escapePress(event) {
    if (event.which === 27) {
      event.data.send('closeMacroMenu');
    }
  }
  var _default = _exports.default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['macro-menu'],
    classNameBindings: ['active:active'],
    active: false,
    loading: true,
    store: Ember.inject.service(),
    user: Ember.inject.service(),
    path: Ember.inject.service(),
    recentlyLoadedReports: Ember.computed('user.reports.@each.sessionLoadedTime', function () {
      return Ember.get(this, 'user.reports').filterBy('sessionLoadedTime').sortBy('sessionLoadedTime').reverse().slice(0, 20);
    }),
    reports: Ember.computed('user.{reports.@each.sessionLoadedTime,company.reports.[]}', function () {
      // Error on logout if we try to toArray things that don't exist
      try {
        var reports = _toConsumableArray(Ember.get(this, 'user.reports'));
        return reports.filter(function (n) {
          return Ember.get(n, 'state') === 'finished';
        }).uniq().rejectBy('sessionLoadedTime').sortBy('monitoredDateUpdated').reverse().slice(0, 20);
      } catch (err) {
        return [];
      }
    }),
    // reports

    // TODO make sure this still works - JK May 2017
    // TODO this doesn't seem to work right
    closeOnPathChange: Ember.observer('path.currentPath', 'path.currentReportId', function () {
      Ember.set(this, 'active', false);
    }),
    // closeOnPathChange
    // TODO: handle w/ Piano in application.js
    didInsertElement: function didInsertElement() {
      Ember.$(document).on('keyup', this, escapePress);
    },
    willDestroyElement: function willDestroyElement() {
      Ember.$(document).off('keyup', escapePress);
    },
    actions: {
      closeMacroMenu: function closeMacroMenu() {
        if (Ember.get(this, 'active')) {
          Ember.set(this, 'active', false);
        }
      },
      backdropped: function backdropped() {
        if (Ember.get(this, 'active')) {
          Ember.set(this, 'active', false);
        }
      },
      // backdropped
      blurInput: function blurInput() {
        Ember.$('input').blur();
      }
    } // actions
  });
});