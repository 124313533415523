define("affinio/components/profile-cover", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Element
    classNames: ['profile-cover'],
    classNameBindings: ['lengthClassName'],
    attributeBindings: ['title'],
    lengthClassName: Ember.computed('slicedProfiles', function () {
      return "length-".concat(Ember.get(this, 'slicedProfiles.length'));
    }),
    tagName: 'article',
    slicedProfiles: Ember.computed('profiles', function () {
      var shuffle = Ember.get(this, 'shuffle');
      var profiles = Ember.get(this, 'profiles');
      var sliceSize = Ember.get(this, 'sliceSize');
      if (shuffle) {
        profiles = _.shuffle(profiles);
      }
      if (sliceSize === 5 && profiles && profiles.length === 6) {
        sliceSize = 6;
      }
      profiles = profiles ? profiles.slice(0, sliceSize ? sliceSize : 4) : null;
      return profiles;
    }),
    emptyImages: Ember.computed('profiles.length', 'sliceSize', function () {
      var sliceSize = Ember.get(this, 'sliceSize');
      var profilesLength = Ember.get(this, 'profiles.length');
      if (profilesLength < sliceSize) {
        return Array(sliceSize - (profilesLength - 1)).fill(0);
      } else {
        return [];
      }
    }),
    howManyMore: Ember.computed('profiles.length', 'sliceSize', function () {
      var length = Ember.get(this, 'profiles.length');
      if (length > 6) {
        return length - Ember.get(this, 'sliceSize');
      } else {
        return 0;
      }
    })
  });
});