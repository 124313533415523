define("affinio/templates/components/metric-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "GpvaA+rr",
    "block": "{\"symbols\":[],\"statements\":[[4,\"link-to\",[\"reports.report.version.cluster.category.subcategory\",[27,\"get\",[[27,\"get\",[[23,[\"metrics\",\"links\"]],[23,[\"type\"]]],null],\"category\"],null],[27,\"get\",[[27,\"get\",[[23,[\"metrics\",\"links\"]],[23,[\"type\"]]],null],\"subcategory\"],null]],null,{\"statements\":[[0,\"  \"],[1,[27,\"font-awesome\",[[27,\"get\",[[23,[\"metrics\",\"icons\"]],[23,[\"type\"]]],null]],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/metric-icon.hbs"
    }
  });
});