define("affinio/templates/components/support-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Ro7mPxpb",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"text\"]]],null,{\"statements\":[[0,\"  \"],[1,[21,\"text\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  Support\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/support-button.hbs"
    }
  });
});