define("affinio/components/modals/modal-version-picker", ["exports", "affinio/components/modals/modal-base"], function (_exports, _modalBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _modalBase.default.extend({
    path: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.$('.close-modal').focus();
    },
    cleanDates: Ember.computed('report.versions', 'path.currentVersion', function () {
      var dates = Ember.get(this, 'report.versions');
      var currentVersion = Ember.get(this, 'path.currentVersion').toString();
      return dates.map(function (n) {
        return {
          version: n.version,
          date: n.dateFinished || n.dateUpdated,
          active: n.version.toString() === currentVersion
        };
      });
    })
  });
});