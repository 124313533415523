define("affinio/utils/queries/auto-suggest", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.entitySearch = void 0;
  var entitySearch = _exports.entitySearch = function entitySearch(name, id, filterName, filters) {
    var dateFilter = '';
    if (filters && filters.dateRange) {
      dateFilter = "dateFilters: \"".concat(JSON.stringify(filters.dateRange), "\"");
    }
    return {
      networkName: 'entitySearch',
      queryString: "query {\n      entitySearch(name: \"".concat(name, "\" id: \"").concat(id, "\" filterName: \"").concat(filterName, "\" ").concat(dateFilter, ") {\n        properties {\n          label\n          value\n        }\n      }\n    }"),
      queryMap: {}
    };
  };
});