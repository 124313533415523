define("affinio/utils/mutations/account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.updateAccountPreferences = _exports.updateAccountPassword = _exports.storeTwitterAccessTokens = _exports.default = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  var keys = window.Object.keys;

  // TODO use variables
  var updateAccountPreferences = _exports.updateAccountPreferences = function updateAccountPreferences(id, preferences) {
    var preferencesObject = '{';
    keys(preferences).forEach(function (key, i, a) {
      var comma = i === a.length - 1 ? '' : ',';
      switch (_typeof(preferences[key])) {
        case 'string':
          preferencesObject += "".concat(key, ":\"").concat(preferences[key], "\"").concat(comma);
          break;
        case 'boolean':
          preferencesObject += "".concat(key, ":").concat(preferences[key]).concat(comma);
          break;
      }
    });
    preferencesObject += '}';
    return {
      networkName: 'updateAccountPreferences',
      queryString: "mutation {\n      updateAccount(id: \"".concat(id, "\", preferences:").concat(preferencesObject, "){\n        id\n        preferences {\n          categoricalColors\n          shouldNameClusters\n        }\n      }\n    }"),
      update: function update(response, graph) {
        return graph.recordFromGraphData(false, 'account', Ember.get(response, 'data.updateAccount'));
      }
    };
  };
  var updateAccountPassword = _exports.updateAccountPassword = function updateAccountPassword(id, password, currentPassword) {
    return {
      networkName: 'updateAccountPassword',
      queryString: "mutation {\n      updateAccountPassword(id: \"".concat(id, "\", password:\"").concat(password, "\", currentPassword:\"").concat(currentPassword, "\"){\n        id\n      }\n    }")
    };
  };
  var storeTwitterAccessTokens = _exports.storeTwitterAccessTokens = function storeTwitterAccessTokens(oAuthToken, oAuthVerifier) {
    return {
      networkName: 'storeTwitterAccessTokens',
      queryString: "mutation {\n      storeTwitterAccessTokens(\n        oAuthToken: \"".concat(oAuthToken, "\",\n        oAuthVerifier:\"").concat(oAuthVerifier, "\"\n      ) {\n        userID\n        screenName\n      }\n    }")
    };
  };
  var _default = _exports.default = {
    updateAccountPreferences: updateAccountPreferences,
    updateAccountPassword: updateAccountPassword,
    storeTwitterAccessTokens: storeTwitterAccessTokens
  };
});