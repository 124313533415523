define("affinio/templates/components/tags-suggestion-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Mr6ckSPJ",
    "block": "{\"symbols\":[\"suggestion\"],\"statements\":[[7,\"ul\"],[11,\"class\",\"tabular-list\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"entities\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\"],[11,\"class\",\"\"],[9],[0,\"\\n\"],[0,\"      \"],[7,\"span\"],[11,\"class\",\"mr\"],[9],[1,[22,1,[\"handleId\"]],false],[10],[0,\"\\n      \"],[7,\"span\"],[9],[0,\" \"],[1,[22,1,[\"submitterEmail\"]],false],[10],[0,\"\\n\"],[0,\"      \"],[7,\"span\"],[11,\"class\",\"action-wrapper\"],[9],[0,\"\\n        \"],[7,\"input\"],[12,\"value\",[22,1,[\"suggestedTag\"]]],[12,\"oninput\",[27,\"action\",[[22,0,[]],[27,\"mut\",[[22,1,[\"suggestedTag\"]]],null]],[[\"value\"],[\"target.value\"]]]],[11,\"type\",\"text\"],[9],[10],[0,\"\\n        \"],[7,\"span\"],[12,\"class\",[28,[\"button\\n            \",[27,\"if\",[[27,\"eq\",[[22,1,[\"status\"]],\"approved\"],null],\"primary\",\"remove\"],null]]]],[11,\"role\",\"button\"],[9],[0,\"\\n\"],[4,\"if\",[[27,\"eq\",[[22,1,[\"status\"]],\"approved\"],null]],null,{\"statements\":[[0,\"            \"],[1,[27,\"font-awesome\",[\"toggle-on\"],null],false],[0,\"\\n            \"],[7,\"small\"],[9],[0,\"Accepted\"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[1,[27,\"font-awesome\",[\"toggle-off\"],null],false],[0,\"\\n            \"],[7,\"small\"],[9],[0,\"Rejected\"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"        \"],[3,\"action\",[[22,0,[]],\"toggleStatus\",[22,1,[]]]],[10],[0,\"\\n      \"],[10],[0,\"\\n\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"],[4,\"if\",[[27,\"not\",[[23,[\"entities\",\"length\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"span\"],[11,\"class\",\"none\"],[9],[0,\"No Interest Category Suggestion Available\"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/tags-suggestion-list.hbs"
    }
  });
});