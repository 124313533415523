define("affinio/templates/components/ridge-lines", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ky+7Asfx",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"data\"]]],null,{\"statements\":[[0,\"  \"],[1,[21,\"chart\"],false],[0,\"\\n  \"],[1,[21,\"axis\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/ridge-lines.hbs"
    }
  });
});