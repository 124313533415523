define("affinio/mixins/affinio-module", ["exports", "ember-copy", "affinio/utils/affinio-module"], function (_exports, _emberCopy, _affinioModule) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
  var _default = _exports.default = Ember.Mixin.create({
    classNames: ['clearfix'],
    classNameBindings: ['moduleRequest.card:card', 'moduleSchema.card:card'],
    path: Ember.inject.service(),
    modules: Ember.inject.service(),
    preferences: Ember.inject.service(),
    height: null,
    heightCache: null,
    didInsertElement: function didInsertElement() {
      // console.log('%caffinio-module didInsertElement', 'color: #aec7e8; font-weight: bold;', get(this, 'moduleRequest.value'));
    },
    didRender: function didRender() {
      var _this = this;
      // console.log('%caffinio-module didRender', 'color: #3369E6; font-weight: bold;', get(this, 'moduleRequest.value'));
      Ember.run.scheduleOnce('afterRender', this, function () {
        var lastHeight = Ember.get(_this, 'heightCache');
        var currentHeight = _this.$().height();
        if (lastHeight && currentHeight !== lastHeight) {
          // console.log(`height difference of: ${currentHeight - lastHeight} -  ${get(this, 'moduleRequest.value')}`);
        }
        Ember.set(_this, 'heightCache', currentHeight);
      });
    },
    metric: Ember.computed('model.{version,cluster.id}', 'moduleRequest', 'moduleRequest.{primaryMetric,metrics.@each.everyCluster}', function () {
      var level;
      var metricKey = Ember.get(this, 'moduleRequest.primaryMetric') ? Ember.get(this, 'moduleRequest.primaryMetric') : Ember.get(this, 'moduleRequest.metrics.length') ? Ember.get(this, 'moduleRequest.metrics')[0][Object.keys(Ember.get(this, 'moduleRequest.metrics')[0])] : null; // TODO Implications?
      if (Ember.get(this, 'moduleRequest.metrics.length')) {
        var _Object$keys = Object.keys(Ember.get(this, 'moduleRequest.metrics')[0]);
        var _Object$keys2 = _slicedToArray(_Object$keys, 1);
        level = _Object$keys2[0];
      }
      if (level !== 'report') {
        level = 'cluster';
      }
      return metricKey ? Ember.get(this, "model.".concat(level, ".").concat(metricKey)) : null; // TODO Implications?
    }),
    init: function init() {
      this._super.apply(this, arguments);
      var dynamicKey = Ember.get(this, 'moduleSchema.dynamicDependency');
      if (dynamicKey) {
        Ember.defineProperty(this, 'dynamicOptions', Ember.computed("model.".concat(dynamicKey, ".[]"), function () {
          return Ember.get(this, "model.".concat(Ember.get(this, 'moduleSchema.dynamicDependency'))).uniqBy('value');
        }));
      }
    },
    // a computed property available in each Affinio Module (if it uses this mixin), which is the
    // moduleSchema data filtered to remove items that dont have their dependencies met.
    processedModuleSchema: Ember.computed('moduleSchema', 'preferences.hiddenOverlaps.[]', 'model.{requestsFulfilled,allMetricsToLoad.@each.loaded,report.baselineReport}', 'dynamicOptions.[]', 'moduleRequest.metrics.length', function () {
      var _this2 = this;
      // to keep schema a POJO (may not be neccessary, will test) - js
      var moduleSchemaClone = (0, _emberCopy.copy)(Ember.get(this, 'moduleSchema'), true);
      if (Ember.get(this, 'moduleRequest.preferences.length')) {
        var preferences = (0, _emberCopy.copy)(Ember.get(this, 'moduleRequest.preferences'), true);
        // go through each preference, then go over the options of each, return only those that have their dependencies met.
        var processedPreferences = preferences.map(function (preference) {
          // check preference object for deps, then go to options.

          if (preference.dependencies) {
            var prefDependenciesMet = (0, _affinioModule.affinioModuleDependencyCheck)(preference.dependencies, _this2, preference);
            if (!prefDependenciesMet) {
              return null;
            }
          }
          var options = preference.options,
            dynamicOptionsKey = preference.dynamicOptionsKey;

          // TODO this was added to the moduleSchema instance, but should have been added to preference instance to not apply to all preferences
          // TODO this should be option and not dependency?
          var moduleDynamicOptions = [];
          if (Ember.get(moduleSchemaClone, 'dynamicDependency')) {
            moduleDynamicOptions = Ember.get(_this2, "model.".concat(Ember.get(moduleSchemaClone, 'dynamicDependency'))).uniqBy('value');
            // TODO the uniqBy should happen where we get it from?
            // TODO we shouldn't assume model here, this limits us where we could just put model in the dynamicDependency
          }
          var dynamicOptions = [];
          if (dynamicOptionsKey) {
            dynamicOptions = Ember.get(_this2, dynamicOptionsKey);
          }
          options = [].concat(_toConsumableArray(options), _toConsumableArray(moduleDynamicOptions), _toConsumableArray(dynamicOptions));
          var processedOptions;
          if (options) {
            processedOptions = options.filter(function (option) {
              // check option dependencies against their requested context
              if (option.dependencies) {
                return (0, _affinioModule.affinioModuleDependencyCheck)(option.dependencies, _this2, option);
              } // if option.dependencies
              else {
                return true;
              } // else option.dependencies
            }); // processedOptions map
            // overwrite options for this preference
          } // if options
          Ember.set(preference, 'options', processedOptions); // eslint-disable-line ember/no-side-effects
          return preference;
        }); // preferences map
        // console.log('processedPreferences', processedPreferences);
        // overwrite preferences for this moduleSchema
        moduleSchemaClone.preferences = processedPreferences.compact();
      } // if preferences
      return moduleSchemaClone;
    }) // processedModuleSchema
  });
});