define("affinio/templates/components/preferences/select-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "utO5xZVf",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"input\",null,[[\"type\",\"checked\"],[\"checkbox\",[27,\"get\",[[23,[\"controller\"]],[23,[\"preference\",\"valueKey\"]]],null]]]],false],[0,\"\\n\"],[7,\"span\"],[9],[1,[23,[\"preference\",\"label\"]],false],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/preferences/select-checkbox.hbs"
    }
  });
});