define("affinio/components/modal-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Element

    tagName: '',
    // Services
    preferences: Ember.inject.service(),
    modalManager: Ember.inject.service(),
    modal: Ember.computed.alias('modalManager.modal'),
    modalProperties: Ember.computed.alias('modalManager.modalProperties')
  });
});