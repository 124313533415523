define("affinio/helpers/intersect", ["exports", "ember-composable-helpers/helpers/intersect"], function (_exports, _intersect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _intersect.default;
    }
  });
  Object.defineProperty(_exports, "intersect", {
    enumerable: true,
    get: function get() {
      return _intersect.intersect;
    }
  });
});