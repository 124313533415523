define("affinio/components/dropdown-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Element
    tagName: '',
    // Properties
    open: false,
    // Computed Properties
    _options: Ember.computed('options', function () {
      return Ember.get(this, 'options') || [];
    }),
    actions: {
      closeDropdown: function closeDropdown() {
        Ember.set(this, 'open', false);
      },
      toggleDropdown: function toggleDropdown() {
        Ember.set(this, 'open', !Ember.get(this, 'open'));
      }
    }
  });
});