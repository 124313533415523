define("affinio/templates/components/comparison-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Fuby+6S5",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"label\"],[9],[0,\"\\n  \"],[1,[23,[\"data\",\"label\"]],false],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"active-label\"],[12,\"style\",[21,\"activeColorStyle\"]],[9],[1,[27,\"if\",[[23,[\"skeletonState\"]],\"--\",[23,[\"activePercent\"]]],null],false],[10],[0,\"\\n\"],[4,\"unless\",[[23,[\"noComparison\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"comparison-label\"],[9],[0,\"(\"],[1,[27,\"if\",[[23,[\"skeletonState\"]],\"--\",[23,[\"comparisonPercent\"]]],null],false],[0,\")\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"unless\",[[23,[\"noBaseline\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"baseline-label\"],[9],[0,\"(\"],[1,[27,\"if\",[[23,[\"skeletonState\"]],\"--\",[23,[\"baselinePercent\"]]],null],false],[0,\")\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\\n\"],[7,\"div\"],[12,\"class\",[28,[\"active-bar \",[27,\"if\",[[23,[\"skeletonState\"]],\"skeleton\"],null]]]],[12,\"style\",[27,\"html-safe\",[[23,[\"activeStyle\"]]],null]],[12,\"title\",[23,[\"data\",\"active\",\"label\"]]],[9],[10],[0,\"\\n\"],[7,\"div\"],[12,\"class\",[28,[\"comparison \",[27,\"if\",[[23,[\"noComparison\"]],\"no-comparsion\"],null]]]],[12,\"style\",[21,\"comparisonStyle\"]],[12,\"title\",[23,[\"data\",\"comparison\",\"label\"]]],[9],[10],[0,\"\\n\"],[7,\"div\"],[12,\"class\",[28,[\"baseline \",[27,\"if\",[[23,[\"noBaseline\"]],\"no-baseline\"],null]]]],[12,\"style\",[21,\"baselineStyle\"]],[12,\"title\",[23,[\"data\",\"baseline\",\"label\"]]],[9],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/comparison-bar.hbs"
    }
  });
});