define("affinio/templates/components/modals/modal-data-source-selection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "OTXCH1/P",
    "block": "{\"symbols\":[],\"statements\":[[7,\"form\"],[11,\"class\",\"modal modal-data-source-selection\"],[11,\"tabindex\",\"0\"],[9],[0,\"\\n  \"],[7,\"a\"],[11,\"class\",\"close-modal\"],[11,\"href\",\"#\"],[9],[0,\"×\"],[3,\"action\",[[22,0,[]],\"close\"]],[10],[0,\"\\n  \"],[7,\"h3\"],[11,\"class\",\"modal-sub-nav-header\"],[9],[0,\"Select Data Source\"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"report-search\"],[9],[0,\"\\n      \"],[1,[27,\"font-awesome\",[\"search\"],null],false],[0,\"\\n      \"],[1,[27,\"input\",null,[[\"type\",\"input\",\"value\"],[\"text\",[27,\"perform\",[[23,[\"textSearch\"]]],null],[23,[\"textQuery\"]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[1,[27,\"affinio-table\",null,[[\"columns\",\"rows\",\"onSelect\"],[[23,[\"columns\"]],[23,[\"rows\"]],[27,\"action\",[[22,0,[]],\"selectRow\"],null]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"okAction\"]]],null,{\"statements\":[[0,\"    \"],[7,\"footer\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"okAction\"]]],null,{\"statements\":[[0,\"        \"],[7,\"button\"],[11,\"class\",\"button primary\"],[11,\"type\",\"submit\"],[9],[0,\"Add\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"cancelAction\"]]],null,{\"statements\":[[0,\"        \"],[7,\"a\"],[11,\"class\",\"button\"],[11,\"role\",\"button\"],[9],[0,\"Cancel\"],[3,\"action\",[[22,0,[]],\"cancel\"]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n  \"]],\"parameters\":[]},null],[0,\"\\n\"],[3,\"action\",[[22,0,[]],\"ok\"],[[\"on\"],[\"submit\"]]],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"backdrop\"],[11,\"role\",\"button\"],[9],[3,\"action\",[[22,0,[]],\"close\"]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/modals/modal-data-source-selection.hbs"
    }
  });
});