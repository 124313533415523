define("affinio/templates/components/affinio-table-cell/data-source-crud-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Rc8gaUVU",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"row\",\"canEdit\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\"],[11,\"role\",\"button\"],[9],[0,\"\\n    \"],[1,[27,\"font-awesome\",[\"pencil\"],null],false],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],[23,[\"editDataSource\"]],[23,[\"row\"]]]],[10],[0,\"\\n  \"],[7,\"span\"],[11,\"role\",\"button\"],[9],[0,\"\\n    \"],[1,[27,\"font-awesome\",[\"trash\"],null],false],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],[23,[\"showDeleteSourceModal\"]],[23,[\"row\"]]]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"customFeatures\"]]],null,{\"statements\":[[4,\"link-to\",[\"aton.taxonomy\",[27,\"query-params\",null,[[\"dataSourceID\"],[[23,[\"row\",\"id\"]]]]]],[[\"title\"],[\"Create Taxonomy with this Data Source\"]],{\"statements\":[[0,\"    \"],[1,[27,\"font-awesome\",[\"plus\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/affinio-table-cell/data-source-crud-actions.hbs"
    }
  });
});