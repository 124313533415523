define("affinio/utils/custom-data/auto-suggest", ["exports", "affinio/utils/auto-suggest"], function (_exports, _autoSuggest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validAutoSuggest = _exports.resolveAutoSuggestEndpoint = _exports.resolveAutoSuggestAddAction = _exports.resolveAddAction = _exports.default = void 0;
  var validAutoSuggest = _exports.validAutoSuggest = function validAutoSuggest(autsuggest) {
    var r = false;
    switch (autsuggest) {
      case 'imdbid':
        r = true;
        break;
      case 'publications':
        r = true;
    }
    return r;
  };
  var resolveAutoSuggestEndpoint = _exports.resolveAutoSuggestEndpoint = function resolveAutoSuggestEndpoint(autosuggest, inputKey, taxoId, field, ds) {
    switch (autosuggest) {
      case 'imdbid':
        return (0, _autoSuggest.entitySearchEndpoint)({
          inputKey: inputKey,
          taxoId: taxoId,
          placeholder: ds ? Ember.get(ds, 'customPlaceholder') : 'Chronicles of Narnia',
          filterName: 'movieName',
          field: field
        });
    }
  };
  var resolveAutoSuggestAddAction = _exports.resolveAutoSuggestAddAction = function resolveAutoSuggestAddAction(autosuggest) {
    switch (autosuggest) {
      case 'imdbid':
        return function (inputController, model, result) {
          var key = Ember.get(inputController, 'moduleSchema.valueKey');
          var filtersKey = "filters.".concat(key);
          var existing = Ember.get(model, filtersKey);
          if (!existing) {
            existing = Ember.set(model, filtersKey, []);
          }

          // Add input to model
          existing.addObject(result);

          // Clear input
          Ember.set(inputController, key, null);
        };
      case 'publications':
        return function (inputController, model, result) {
          var key = Ember.get(inputController, 'moduleSchema.label');
          var filtersKey = "filters.".concat(key);
          var existing = Ember.get(model, "filters.".concat(key));
          if (!existing) {
            existing = Ember.set(model, filtersKey, []);
          }
          var filter = {
            field: Ember.get(inputController, 'moduleSchema.label'),
            condition: 'equal',
            value: result.label,
            datasourceid: Ember.get(inputController, 'moduleSchema.datasourceid')
          };
          var thisTypeExisting = existing.filterBy('field', filter.field);
          var alreadyExists = thisTypeExisting.some(function (n) {
            return _.isEqual(n, filter);
          });
          if (!alreadyExists) {
            // Add input to model
            existing.addObject(filter);
          }

          // Clear input
          Ember.set(inputController, key, null);
          model.toggleProperty('filterChange');
        };
    }
  };
  var resolveAddAction = _exports.resolveAddAction = function resolveAddAction(field) {
    switch (field) {
      case 'publications':
        return function (inputController, model) {
          var existingFilter = Ember.get(model, "filters.".concat(Ember.get(inputController, 'moduleSchema.label').camelize()));
          if (!existingFilter) {
            Ember.set(model, "filters.".concat(Ember.get(inputController, 'moduleSchema.label').camelize()), []);
          }
          var filterObject = {};
          var labels = [];
          var splitLabels = Ember.get(inputController, 'filter').split(',');
          splitLabels.forEach(function (l) {
            l = l.trim();
            if (l.length > 0) {
              labels.push(l);
            }
          });
          var existing = Ember.get(model, "filters.".concat(Ember.get(inputController, 'moduleSchema.label').camelize()));
          var thisTypeExisting = existing.filterBy('field', Ember.get(inputController, 'moduleSchema.label'));
          labels.forEach(function (l, i) {
            filterObject = {
              field: Ember.get(inputController, 'moduleSchema.label'),
              condition: Ember.get(inputController, 'condition'),
              value: labels[i],
              datasourceid: Ember.get(inputController, 'moduleSchema.datasourceid')
            };
            Ember.set(inputController, 'filter', null);
            var alreadyExists = thisTypeExisting.some(function (n) {
              return _.isEqual(n, filterObject);
            });
            if (!alreadyExists) {
              Ember.get(model, "filters.".concat(Ember.get(inputController, 'moduleSchema.label').camelize())).pushObject(filterObject);
            }
          });
          model.toggleProperty('filterChange');
        };
    }
  };
  var _default = _exports.default = {
    validAutoSuggest: validAutoSuggest,
    resolveAutoSuggestEndpoint: resolveAutoSuggestEndpoint,
    resolveAutoSuggestAddAction: resolveAutoSuggestAddAction
  };
});