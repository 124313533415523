define("affinio/components/old/votable-word", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    notify: Ember.inject.service('notify'),
    tagName: 'span',
    classNames: ['votable-word'],
    classNameBindings: ['votable', 'hovered', 'input', 'custom_term:has-custom-term', 'isHidden:hidden', 'isHighlighted:active'],
    attributeBindings: ['draggable'],
    wordSource: '',
    setWordToInput: Ember.observer('custom_term', function () {
      var ret = Ember.Object.create();
      ret.label = Ember.get(this, 'custom_term');
      ret.value = 1;
      Ember.set(this, 'word', ret);
    }),
    votable: Ember.computed('word', function () {
      return true; // TODO: make more robust.
    }),
    label: Ember.computed('word', function () {
      return Ember.get(this, 'word.label');
    }),
    isHidden: Ember.computed('threshold', function () {
      if (Ember.get(this, 'threshold')) {
        if (Ember.get(this, 'threshold') / 100 > Ember.get(this, 'word.value')) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }),
    isHighlighted: Ember.computed('highlighted', function () {
      // console.log('isHighlighted', get(this, 'word'));
      return Ember.get(this, 'highlighted') ? true : false;
      // return true;
    }),
    mouseEnter: function mouseEnter(ev) {
      var _this = this;
      if (Ember.get(this, 'hoverable')) {
        Ember.run.scheduleOnce('afterRender', function () {
          return Ember.set(_this, 'hovered', true);
        });
        // console.log('okay whatdo we have here', get(this, 'word'));
        var hoverWord = Ember.get(this, 'hoverWord');
        if (hoverWord && typeof hoverWord === 'function') {
          hoverWord(Ember.get(this, 'word'), ev);
        }
      }
    },
    mouseLeave: function mouseLeave() {
      if (Ember.get(this, 'hoverable') || Ember.get(this, 'clickable')) {
        Ember.set(this, 'hovered', false);
        // this.sendAction('hoverWord', null,null)
      }
    } /* ev */,
    upvotePercentage: Ember.computed('word.{upvotes,downvotes}', function () {
      // console.log(get(this, 'word.upvotes'), get(this, 'word.downvotes'));
      var upvotes = Ember.get(this, 'word.upvotes') || 0;
      var downvotes = Ember.get(this, 'word.downvotes') || 0;
      // console.log(upvotes, downvotes);

      return upvotes > 0 ? Math.round(upvotes / (upvotes + downvotes) * 100) : 0;
    }),
    downvotePercentage: Ember.computed('word.{upvotes,downvotes}', function () {
      var upvotes = Ember.get(this, 'word.upvotes') || 0;
      var downvotes = Ember.get(this, 'word.downvotes') || 0;
      // console.log(upvotes, downvotes);
      return downvotes > 0 ? Math.round(downvotes / (upvotes + downvotes) * 100) : 0;
    }),
    upvoteStyle: Ember.computed('upvotePercentage', function () {
      return Ember.String.htmlSafe("height:".concat(Ember.get(this, 'upvotePercentage'), "%"));
    }),
    downvoteStyle: Ember.computed('downvotePercentage', function () {
      return Ember.String.htmlSafe("height:".concat(Ember.get(this, 'downvotePercentage'), "%"));
    }),
    actions: {
      upvote: function upvote(word, wordSource) {
        var upvote = Ember.get(this, 'upvote');
        if (upvote && typeof upvote === 'function') {
          upvote(word, wordSource);
        }
        if (Ember.get(this, 'custom_term')) {
          Ember.set(this, 'custom_term', '');
        }
      },
      downvote: function downvote(word, wordSource) {
        var downvote = Ember.get(this, 'downvote');
        if (downvote && typeof downvote === 'function') {
          downvote(word, wordSource);
        }
        if (Ember.get(this, 'custom_term')) {
          Ember.set(this, 'custom_term', '');
        }
      },
      remove: function remove(word, from) {
        var removeWord = Ember.get(this, 'removeWord');
        if (removeWord && typeof removeWord === 'function') {
          removeWord(word, from);
        }
      },
      // TODO: this is the hackathonniest code ever, lol.
      dontprop: function dontprop() {
        // console.log('phew');
      }
    }
  });
});