define("affinio/components/report-builder/translation-input", ["exports", "affinio/utils/constants/countries"], function (_exports, _countries) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['translation-input'],
    tagName: 'div',
    expandedLanguages: _countries.expandedLanguages,
    actions: {
      setTranslationCode: function setTranslationCode(translationCode) {
        Ember.set(this, 'model.filters.translationCode', translationCode);
      }
    }
  });
});