define("affinio/components/time-series", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    preferences: Ember.inject.service(),
    path: Ember.inject.service(),
    classNames: ['time-series'],
    height: 500,
    format: Ember.computed.alias('preferences.timeSeriesFormat'),
    draw: Ember.observer('data', function () {
      var chart = Ember.get(this, 'chart');
      if (chart) {
        chart.unload();
      }
      var keys = Object.keys(Ember.get(this, 'data'));
      var theColumns = Ember.get(this, "data.".concat(keys[0]));
      var theGrouping = theColumns.slice(2).map(function (it) {
        return it[0];
      });
      Ember.set(this, 'grouping', theGrouping);
      var color;
      if (Ember.get(this, 'preferences.categoricalColors') && Ember.get(this, 'preferences.categoricalColors') !== 'Standard Categorical') {
        color = d3.scale.ordinal().domain(d3.range(20)).range(Ember.get(this, 'preferences.categoricalSets').findBy('name', Ember.get(this, 'preferences.categoricalColors')).colors).range();
      } else {
        color = d3.scale.category20().domain(d3.range(20)).range();
      }
      color = ['#222222'].concat(color);
      chart = c3.generate({
        bindto: '#timeseries',
        size: {
          height: Ember.get(this, 'height')
        },
        data: {
          x: 'x',
          columns: theColumns,
          names: Ember.get(this, 'idNameHash'),
          groups: [theGrouping],
          type: 'area',
          types: {
            all: 'line'
          },
          order: null
        },
        color: {
          pattern: color
        },
        axis: {
          x: {
            type: 'timeseries',
            tick: {
              format: '%Y-%m-%d'
            },
            label: {
              text: 'Date',
              position: 'outer-center'
            }
          },
          y: {
            label: {
              text: 'Daily Growth/Loss',
              position: 'outer-middle'
            }
          }
        }
      });
      Ember.set(this, 'chart', chart);
    }),
    didInsertElement: function didInsertElement() {
      var hiddenClustersHash = {};
      var hiddenClusters;
      if (Ember.get(this, 'path.inCluster')) {
        // console.log('didInsertElement time-series', get(this, 'idNameHash'), get(this, 'highlightedId'));
        hiddenClustersHash = Object.assign(hiddenClustersHash, Ember.get(this, 'idNameHash'));
        delete hiddenClustersHash[Ember.get(this, 'highlightedId')];
        hiddenClusters = Object.keys(hiddenClustersHash);
        hiddenClusters.push('all');
      }
      if (Ember.get(this, 'chart')) {
        Ember.get(this, 'chart').unload();
        this.draw();
        if (Ember.get(this, 'path.inCluster')) {
          Ember.get(this, 'chart').hide(hiddenClusters);
        }
      } else {
        this.draw();
        if (Ember.get(this, 'path.inCluster')) {
          Ember.get(this, 'chart').hide(hiddenClusters);
        }
      } // if chart exists
    },
    // didInsertElement
    didUpdateAttrs: function didUpdateAttrs() {
      // console.log('didUpdateAttrs time-series');
      var hiddenClustersHash = {};
      var hiddenClusters;
      if (Ember.get(this, 'path.inCluster')) {
        hiddenClustersHash = Object.assign(hiddenClustersHash, Ember.get(this, 'idNameHash'));
        delete hiddenClustersHash[Ember.get(this, 'highlightedId')];
        hiddenClusters = Object.keys(hiddenClustersHash);
        hiddenClusters.push('all');
      }
      if (Ember.get(this, 'chart')) {
        Ember.get(this, 'chart').unload();
        this.draw();
        if (Ember.get(this, 'path.inCluster')) {
          Ember.get(this, 'chart').hide(hiddenClusters);
        }
      } else {
        this.draw();
        if (Ember.get(this, 'path.inCluster')) {
          Ember.get(this, 'chart').hide(hiddenClusters);
        }
      } // if chart exists
    },
    // didUpdateAttrs
    didRender: function didRender() {
      this.changeFormat();
    },
    changeFormat: Ember.observer('format', function () {
      var chart = Ember.get(this, 'chart');
      if (Ember.get(this, 'format') === 'dailyGrowth') {
        chart.axis.labels({
          'y': 'Daily Growth/Loss'
        });
        chart.load({
          columns: Ember.get(this, 'data.dailyGrowth'),
          type: 'area',
          types: {
            all: 'line'
          }
        });
        chart.groups([Ember.get(this, 'grouping')]);

        // chart.show(['all']);
      } else if (Ember.get(this, 'format') === 'percentChange') {
        chart.axis.labels({
          'y': 'Percent Change'
        });
        // console.log('time series data:', this.get('data.percentChange'));
        chart.load({
          columns: Ember.get(this, 'data.percentChange'),
          type: 'line'
        });
        chart.groups([]);
        // chart.hide(['all']);
      } else {
        chart.axis.labels({
          'y': 'Cluster Size'
        });
        chart.load({
          columns: Ember.get(this, 'data.cumulativeSize'),
          type: 'area',
          types: {
            all: 'line'
          }
        });
        chart.groups([Ember.get(this, 'grouping')]);

        // this.get('chart').show(['all']);
      }
    }) // changeFormat
  });
});