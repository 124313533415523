define("affinio/components/modules/engagement-dashboard", ["exports", "affinio/mixins/affinio-module", "moment", "affinio/utils/get-with-default"], function (_exports, _affinioModule, _moment, _getWithDefault) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_affinioModule.default, {
    // Element
    classNames: ['engagement-dashboard'],
    preferences: Ember.inject.service(),
    sortedEngagements: Ember.computed('model.cluster.engagements.loaded', 'preferences.timezone', function () {
      var _this = this;
      // console.log('log', get(this, 'model'))
      return (0, _getWithDefault.default)(this, 'model.cluster.engagements.response', []).map(function (time) {
        if (!time.dateTime && time.label) {
          time.dateTime = time.label;
        }
        var tz = _moment.default.tz(time.dateTime, Ember.get(_this, 'preferences.timezone') ? Ember.get(_this, 'preferences.timezone') : _moment.default.tz.guess());
        var newObj = {
          date: tz.format('YYYY-MM-DD'),
          time: tz.format('HH:mm:ss'),
          dateTime: tz,
          value: time.value
        };
        return newObj;
      }).sort(function (a, b) {
        return a.dateTime - b.dateTime;
      });
    }),
    // engagement objects that are highlighted
    highlightedEngagementTimes: Ember.computed('preferences.{timezone,highlightedTime}', 'sortedEngagements', function () {
      var _this2 = this;
      // console.log(get(this, 'sortedEngagements'));
      var highlightedEngagementTimes = Ember.get(this, 'sortedEngagements').filter(function (rec) {
        return rec.dateTime.format('ddd') === Ember.get(_this2, 'preferences.highlightedTime.day') && rec.time === Ember.get(_this2, 'preferences.highlightedTime.value');
      });
      return highlightedEngagementTimes;
    }),
    maxEngagementTimes: Ember.computed('postsTimeSeries', 'preferences.timezone', 'sortedEngagements', function () {
      var engagements = Ember.get(this, 'sortedEngagements');
      var max = Ember.get(this, 'postsTimeSeries.hours.max');
      return engagements.filter(function (rec) {
        return rec.dateTime.format('ddd') === max.day && rec.time === max.value;
      });
    }),
    minEngagementTimes: Ember.computed('preferences.timezone', 'sortedEngagements', function () {
      var engagements = Ember.get(this, 'sortedEngagements');
      var min = Ember.get(this, 'postsTimeSeries.hours.min');
      return engagements.filter(function (rec) {
        return rec.dateTime.format('ddd') === min.day && rec.time === min.value;
      });
    }),
    postsTimeSeries: Ember.computed('model.cluster.engagements', 'preferences.timezone', 'sortedEngagements', 'report.id', function () {
      // console.time(`postsTimeSeries_${get(this, 'id')}`);
      if (Ember.get(this, 'model.cluster.engagements.response') && Ember.get(this, 'model.cluster.engagements.response.length') > 0) {
        var engagements = Ember.get(this, 'sortedEngagements');
        var days = _.groupBy(engagements, 'date');
        days = _.groupBy(Object.keys(days).map(function (k) {
          var ret = {};
          // ret.label = moment().day(k).format('ddd');
          ret.day = (0, _moment.default)(k).day();
          ret.hours = days[k];
          return ret;
        }), 'day'); // days

        // fill day gaps
        if (days.length < 7) {
          var _days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
          var foundDays = days.mapBy('day');
          _days.map(function (day) {
            if (!foundDays.contains(day)) {
              days.push({
                day: day
              });
            }
          });
        } // if days.length < 7

        days = Object.keys(days).map(function (dayKey) {
          var ret = {};
          ret.label = (0, _moment.default)().day(dayKey).format('ddd');
          ret.day = dayKey;
          var hours = _.groupBy(_.flatten(days[dayKey].map(function (day) {
            return day.hours;
          })), 'time');

          // if not every hour found in backend data
          if (Object.keys(hours).length < 24) {
            // console.log('hours has less than 24');
            var emptyHourObj = {};
            for (var i = 0; i < 24; i++) {
              var m = (0, _moment.default)();
              /* eslint-disable */
              // moment has its own set method
              var time = m.set({
                hour: i,
                minute: 0,
                second: 0,
                millisecond: 0
              }).format('HH:mm:ss');
              /* eslint-enable */
              emptyHourObj[time] = [Ember.Object.create({
                value: 0,
                time: time,
                date: dayKey,
                dateTime: (0, _moment.default)("".concat(dayKey, "T").concat(time, "Z"))
              })];
            } // for loop add 24 hours to emptyHourObj
            // assign found hours onto emptyHourObj
            // console.log(hours, emptyHourObj);
            hours = Object.assign(emptyHourObj, hours);
          } // if hours keys length < 24
          var hoursMap = Object.keys(hours).map(function (hourKey) {
            return {
              label: (0, _moment.default)(hourKey, ['h:mm:ss']) /* .add(1, 'hours')*/.format('h:mm A'),
              value: hourKey,
              count: _.pluck(hours[hourKey], 'value').reduce(function (m, n) {
                return m + n;
              }),
              average: _.pluck(hours[hourKey], 'value').reduce(function (m, n) {
                return m + n;
              }) / hours[hourKey].length || 0,
              day: ret.label,
              dayLabel: (0, _moment.default)().day(dayKey).format('dddd'),
              fullLabel: "".concat((0, _moment.default)().day(dayKey).format('dddd'), "s at ").concat((0, _moment.default)(hourKey, ['h:mm:ss']) /* .add(1, 'hours')*/.format('h:mm A'))
            };
          }).sortBy('value'); // hours
          ret.hours = hoursMap;
          ret.value = _.pluck(ret.hours, 'count').reduce(function (m, n) {
            return m + n;
          });
          return ret;
        }); // days
        days.total = engagements.reduce(function (m, n) {
          return m + n.value;
        }, 0);
        days.averageByHour = days.total / (30 * 24);
        days.averageByDay = (days.total / 30).toFixed();
        days.hours = {};
        days.hours.max = _.max(_.flatten(_.pluck(days, 'hours')), function (hour) {
          return hour.average;
        });
        days.hours.min = _.min(_.flatten(_.pluck(days, 'hours')), function (hour) {
          return hour.average;
        });
        days.hours.max.percentOfTotal = days.hours.max.count / days.total * 100;
        days.hours.min.percentOfTotal = days.hours.min.count / days.total * 100;

        // if (get(this, 'id') !== `${get(this, 'report.id')}_all`) {
        //   days.ratio = ((days.total / get(this, 'report.clusterAggregate.postsTimeSeries.total')) * 100);
        // }

        days.averageColorScale = d3.scale.linear().domain([days.hours.min.average, days.hours.max.average - (days.hours.max.average - days.hours.min.average) / 2, days.hours.max.average]).range(['#c00', '#ffffff', '#4DB930']);
        var dayRecords = days.map(function (day) {
          return {
            label: day.label,
            value: day.value
          };
        });
        days.daySummary = {
          max: _.max(dayRecords, function (rec) {
            return rec.value;
          }).value,
          min: _.min(dayRecords, function (rec) {
            return rec.value;
          }).value,
          records: dayRecords
        };
        var hourRecords = [];
        _.mapObject(_.groupBy(_.flatten(days.mapBy('hours')), 'value'), function (vals, key) {
          hourRecords.push(Ember.Object.create({
            label: (0, _moment.default)(key, ['h:mm A']).format('h:mm A'),
            value: _.reduce(vals, function (m, n) {
              return m + n.count;
            }, 0)
          }));
        });
        days.hourSummary = {
          max: _.max(hourRecords, function (rec) {
            return rec.value;
          }).value,
          min: _.min(hourRecords, function (rec) {
            return rec.value;
          }).value,
          records: hourRecords
        };
        // console.log(days);
        // console.timeEnd(`postsTimeSeries_${get(this, 'id')}`);
        return days;
      } else {
        return null;
      }
    })
  });
});