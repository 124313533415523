define("affinio/routes/aton/about", ["exports", "affinio/mixins/document-title"], function (_exports, _documentTitle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_documentTitle.default, {
    documentTitle: 'About - ATON - Affinio'
  });
});