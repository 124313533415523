define("affinio/utils/taxonomies/toolbox", ["exports", "affinio/utils/auto-suggest", "affinio/utils/mutations"], function (_exports, _autoSuggest, _mutations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.toolboxSchema = _exports.default = void 0;
  // Each object in this schema represents a drawer within the toolbox.
  // Think of it like an actual toolbox in a garage
  // -JC

  var toolboxSchema = _exports.toolboxSchema = Ember.A([{
    label: 'Reports',
    // Label of the drawer
    tools: [{
      label: 'Delete Report',
      // Label of the tool
      mutation: _mutations.default.toolboxDeleteReport,
      btnIcon: 'trash',
      successMessage: 'Successfully Deleted Report',
      confirm: 'This will delete this report from your records, you will not get back the credit.',
      inputs: [
      // List of needed inputs for mutation
      {
        label: 'Report Name',
        // label for input
        type: 'Report',
        endpoint: _autoSuggest.toolboxReportSearchByNameOrIdEndpoint // query to handle autocomplete in concurrency task
      }]
    }, {
      label: 'Transfer Ownership of a Report',
      mutation: _mutations.default.toolboxTransferReport,
      successMessage: 'Successfully Transfered Ownership',
      shareAction: true,
      inputs: [{
        label: 'Report Name',
        // label for input
        type: 'Report',
        endpoint: _autoSuggest.toolboxReportSearchByNameOrIdEndpoint // query to handle autocomplete in concurrency task
      }, {
        label: 'Account Name',
        type: 'Account',
        endpoint: _autoSuggest.toolboxAccountSearchByNameOrIdEndpoint
      }]
    }, {
      label: 'Share Account Reports to Company',
      mutation: _mutations.default.toolboxShareAccountReportsToCompany,
      successMessage: 'Successfully Transfered Account Reports to Company',
      shareAction: true,
      inputs: [{
        label: 'Account Name',
        type: 'Account',
        endpoint: _autoSuggest.toolboxAccountSearchByNameOrIdEndpoint
      }]
    }, {
      label: 'Share Account Reports to Folder',
      mutation: _mutations.default.toolboxShareAccountReportsToFolder,
      successMessage: 'Successfully Transfered Account Reports to Folder',
      shareAction: true,
      inputs: [{
        label: 'Account Name',
        type: 'Account',
        endpoint: _autoSuggest.toolboxAccountSearchByNameOrIdEndpoint
      }, {
        label: 'Folder Name',
        type: 'Folder',
        endpoint: _autoSuggest.toolboxFolderSearchByNameOrIdEndpoint
      }]
    }, {
      label: 'Share Account Reports to Account',
      mutation: _mutations.default.toolboxShareAccountReportsToAccount,
      successMessage: 'Successfully Transfered Account Reports to Account',
      shareAction: true,
      inputs: [{
        label: 'Sharer Account Name',
        type: 'Account',
        endpoint: _autoSuggest.toolboxAccountSearchByNameOrIdEndpoint
      }, {
        label: 'Recipient Account Name',
        type: 'Account',
        endpoint: _autoSuggest.toolboxAccountSearchByNameOrIdEndpoint
      }]
    }, {
      label: 'Rerun report',
      mutation: _mutations.default.toolboxReRunReport,
      successMessage: 'Report running',
      successProperty: 'data.toolboxReRunReport.id',
      trackerInfo: {
        label: 'Toolbox Rerun Report',
        key: 'reportID',
        value: 'report.id'
      },
      inputs: [{
        label: 'Original Report ID',
        type: 'Report',
        endpoint: _autoSuggest.toolboxReportSearchByNameOrIdEndpoint
      }]
    }, {
      label: 'Reaggregate report',
      mutation: _mutations.default.toolboxReaggregateReport,
      successMessage: 'Reaggregating report',
      successProperty: 'data.updateClusters.0.id',
      inputs: [{
        label: 'Original Report ID',
        type: 'Report',
        endpoint: _autoSuggest.toolboxReportSearchByNameOrIdEndpoint
      }]
    }, {
      label: 'Rerun Graph Gen',
      mutation: _mutations.default.toolboxReRunGraphGen,
      successMessage: 'Rerunning Graph Gen',
      successProperty: 'data.toolboxReRunGraphGen.id',
      inputs: [{
        label: 'Original Report ID',
        type: 'Report',
        endpoint: _autoSuggest.toolboxReportSearchByNameOrIdEndpoint
      }]
    }, {
      label: 'Rerun Records Gen',
      mutation: _mutations.default.toolboxReRunRecordsGen,
      successMessage: 'Rerunning Records Gen',
      successProperty: 'data.toolboxReRunRecordsGen.id',
      inputs: [{
        label: 'Original Report ID',
        type: 'Report',
        endpoint: _autoSuggest.toolboxReportSearchByNameOrIdEndpoint
      }]
    }, {
      label: 'Convert to Baseline',
      description: 'Change a report to be viewable to all companies from the Baseline modal, but not be openable from the menu. It will be labelled as a Baseline and show a generic creator.',
      mutation: _mutations.default.toolboxConvertToBaseline,
      successMessage: 'Report being converted to baseline report',
      successProperty: 'data.toolboxUpdateReportBaseline.id',
      inputs: [{
        label: 'Original Report ID',
        type: 'Report',
        endpoint: _autoSuggest.toolboxReportSearchByNameOrIdEndpoint
      }, {
        label: 'Availability',
        type: 'Availability',
        endpoint: _autoSuggest.toolboxAvailabilityEndpoint,
        minInputLength: 0
      }]
    }, {
      label: 'Revert Baseline',
      description: 'Reverse the Baseline status of a report so that it becomes openable in the menu, and both its title and creator go back to their original state.',
      mutation: _mutations.default.toolboxRevertBaseline,
      successMessage: 'Report baseline cleared',
      successProperty: 'data.toolboxUpdateReportBaseline.id',
      inputs: [{
        label: 'Original Report ID',
        type: 'Report',
        endpoint: _autoSuggest.toolboxReportSearchByNameOrIdEndpoint
      }, {
        label: 'Availability',
        type: 'Availability',
        endpoint: _autoSuggest.toolboxAvailabilityEndpoint,
        minInputLength: 0
      }]
    }, {
      label: 'Distribute Report',
      description: 'Broaden the distribution of a regular report to all companies, so they can access it from their menu. Report will reflect the creator name who made it.',
      mutation: _mutations.default.toolboxUpdateReportAvailability,
      successMessage: 'Report availability updated',
      successProperty: 'data.toolboxUpdateReportAvailability.id',
      inputs: [{
        label: 'Original Report ID',
        type: 'Report',
        endpoint: _autoSuggest.toolboxReportSearchByNameOrIdEndpoint
      }, {
        label: 'Availability',
        type: 'Availability',
        endpoint: _autoSuggest.toolboxAvailabilityEndpoint,
        minInputLength: 0
      }]
    }, {
      label: 'Disable Monitoring on Report',
      mutation: _mutations.default.toolboxDisableMonitoredReport,
      successMessage: 'Successfully Disabled Monitoring',
      confirm: 'This will disable monitoring for a Report',
      inputs: [{
        label: 'Monitored Report Name',
        type: 'Report',
        endpoint: _autoSuggest.toolboxSearchMonitoredReportsByNameOrId
      }]
    }]
  }, {
    label: 'Accounts',
    tools: [{
      label: 'Transfer Account',
      mutation: _mutations.default.toolboxTransferAccount,
      successMessage: 'Successfully Transfered Account to Company',
      inputs: [{
        label: 'Account Name',
        type: 'Account',
        endpoint: _autoSuggest.toolboxAccountSearchByNameOrIdEndpoint
      }, {
        label: 'Company Name',
        type: 'Company',
        endpoint: _autoSuggest.toolboxCompanySearchByNameOrIdEndpoint
      }]
    }, {
      label: 'Set Account Password',
      mutation: _mutations.default.toolboxSetPassword,
      successMessage: 'Successfully Set Password',
      errorMessage: 'Unable to set password',
      hasPasswordInput: true,
      inputs: [{
        label: 'Account Name',
        type: 'Account',
        endpoint: _autoSuggest.toolboxAccountSearchByNameOrIdEndpoint
      }, {
        isPassword: true,
        // for password type input
        label: 'Password',
        type: 'Password'
      }]
    }, {
      label: 'Clear Account Lockout',
      mutation: _mutations.default.toolboxClearAccountLockout,
      successMessage: 'Successfully Cleared Account Lockout',
      inputs: [{
        label: 'Account Name',
        type: 'Account',
        endpoint: _autoSuggest.toolboxAccountSearchByNameOrIdEndpoint
      }]
    }, {
      label: 'Resend Welcome Email',
      mutation: _mutations.default.toolboxResendWelcome,
      successMessage: 'Successfully sent welcome email',
      errorMessage: 'Unable to send welcome email',
      inputs: [{
        label: 'Account Name',
        type: 'Account',
        endpoint: _autoSuggest.toolboxAccountSearchByNameOrIdEndpoint
      }]
    }]
  }, {
    label: 'Company',
    tools: [{
      label: 'Add container',
      mutation: _mutations.default.toolboxAddCompanyContainer,
      successMessage: 'Successfully Added Container',
      inputs: [{
        label: 'Company Name',
        type: 'Company',
        endpoint: _autoSuggest.toolboxCompanySearchByNameOrIdEndpoint
      }, {
        label: 'Container Name',
        type: 'Arbitrary',
        allowCustomValue: true,
        validRegex: /^[a-zA-Z0-9+\-_]*$/g,
        endpoint: _autoSuggest.toolboxRawInputEndpoint
      }, {
        label: 'Container Type',
        type: 'ContainerType',
        endpoint: _autoSuggest.toolboxContainerTypeEndpoint,
        minInputLength: 0
      }, {
        label: 'Database Connection String',
        type: 'Arbitrary',
        allowCustomValue: true,
        endpoint: _autoSuggest.toolboxRawInputEndpoint
      }]
    }]
  }, {
    label: 'System',
    tools: [{
      label: 'Add handle to cache',
      // Label of the tool
      mutation: _mutations.default.toolboxAddTwitterHandleToInfluencers,
      btnIcon: 'plus',
      successMessage: 'Successfully Added Handle',
      inputs: [
      // List of needed inputs for mutation
      {
        label: 'Twitter Handle',
        // label for input
        type: 'TwitterHandle',
        endpoint: _autoSuggest.toolboxTwitterHandlesEndpoint,
        // query to handle autocomplete in concurrency task
        allowCustomValue: true
      }]
    }, {
      label: 'Add/Update handle interest category',
      // Label of the tool
      mutation: _mutations.default.toolboxAddToTags,
      btnIcon: 'plus',
      successMessage: 'Successfully Added Handle Tag',
      errorMessage: 'Unable to Add Handle Tag',
      hasFreeForm: true,
      inputs: [
      // List of needed inputs for mutation
      {
        label: 'Map a handle to its child tag using a comma (e.g. moatstv, tv channels & shows)',
        // label for input
        type: 'InfluencerTag',
        isTags: true
      }]
    }]
  }, {
    label: 'Tracked Interests Groups',
    tools: [{
      label: 'Edit Account Tracked Interests Groups',
      // Label of the tool
      mutation: 'benchmark',
      successMessage: 'Successfully Added Handle',
      inputs: [
      // List of needed inputs for mutation
      {
        label: 'Account Name',
        type: 'Account',
        endpoint: _autoSuggest.toolboxAccountSearchByNameOrIdEndpoint
      }]
    }, {
      label: 'Edit Company Tracked Interests Groups',
      // Label of the tool
      mutation: 'benchmark',
      successMessage: 'Successfully Added Handle',
      inputs: [
      // List of needed inputs for mutation
      {
        label: 'Company Name',
        type: 'Company',
        endpoint: _autoSuggest.toolboxCompanySearchByNameOrIdEndpoint
      }]
    }]
  }]);
  var _default = _exports.default = {
    toolboxSchema: toolboxSchema
  };
});