define("affinio/utils/queries", ["exports", "affinio/utils/queries/general", "affinio/utils/queries/toolbox", "affinio/utils/queries/superUser", "affinio/utils/queries/reports", "affinio/utils/queries/taxonomies", "affinio/utils/queries/companies", "affinio/utils/queries/users", "affinio/utils/queries/groups", "affinio/utils/queries/folders", "affinio/utils/queries/auto-suggest", "affinio/utils/queries/estimation", "affinio/utils/queries/integrations"], function (_exports, _general, _toolbox, _superUser, _reports, _taxonomies, _companies, _users, _groups, _folders, _autoSuggest, _estimation, _integrations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    me: _general.me,
    myNotifications: _general.myNotifications,
    myBenchmarks: _general.myBenchmarks,
    myPreferences: _general.myPreferences,
    outageInfo: _general.outageInfo,
    dataSets: _general.dataSets,
    myCombinedReports: _reports.myCombinedReports,
    myCombinedReportsForClusters: _reports.myCombinedReportsForClusters,
    combinedReports: _reports.combinedReports,
    updateReportPostShare: _reports.updateReportPostShare,
    folderReports: _reports.folderReports,
    findReportType: _reports.findReportType,
    twitterReport: _reports.twitterReport,
    instagramReport: _reports.instagramReport,
    pinterestReport: _reports.pinterestReport,
    twitterBasicClusters: _reports.twitterBasicClusters,
    customReport: _reports.customReport,
    customClusterMetrics: _reports.customClusterMetrics,
    realtimeQuery: _general.realtimeQuery,
    getFileDownloadSignature: _general.getFileDownloadSignature,
    getDataSetSamples: _general.getDataSetSamples,
    getCrowdSourcedTags: _general.getCrowdSourcedTags,
    companies: _companies.companies,
    companiesLite: _companies.companiesLite,
    company: _companies.company,
    allPermissions: _companies.allPermissions,
    userPermissions: _users.userPermissions,
    allUsers: _users.allUsers,
    user: _users.user,
    users: _users.users,
    twitterAdsAccounts: _users.twitterAdsAccounts,
    generateTwitterOauthRequestToken: _users.generateTwitterOauthRequestToken,
    getTwitterCredentials: _users.getTwitterCredentials,
    groups: _groups.groups,
    group: _groups.group,
    updateGroupsReports: _groups.updateGroupsReports,
    updateGroupsFolders: _groups.updateGroupsFolders,
    reportSearch: _reports.reportSearch,
    affinioReportSearch: _reports.affinioReportSearch,
    updateFoldersReports: _folders.updateFoldersReports,
    twitterUserSearch: _general.twitterUserSearch,
    pinterestUserSearch: _general.pinterestUserSearch,
    recordsMap: _reports.recordsMap,
    getFilters: _reports.getFilters,
    getReportState: _reports.getReportState,
    estimateTwitterFilter: _general.estimateTwitterFilter,
    estimatePinterestFilter: _general.estimatePinterestFilter,
    estimateRedditFilter: _general.estimateRedditFilter,
    instagramImages: _general.instagramImages,
    wordSimilarity: _general.wordSimilarity,
    accountFromEmail: _general.accountFromEmail,
    getDynamicsHandles: _general.getDynamicsHandles,
    reportSearchByNameOrId: _toolbox.reportSearchByNameOrId,
    monitoredReportSearchByNameOrId: _toolbox.monitoredReportSearchByNameOrId,
    accountSearchByNameOrId: _toolbox.accountSearchByNameOrId,
    companySearchByNameOrId: _toolbox.companySearchByNameOrId,
    folderSearchByNameOrId: _toolbox.folderSearchByNameOrId,
    getAssetGroupsByID: _toolbox.getAssetGroupsByID,
    accountSearchByNameOrEmailAdmin: _superUser.accountSearchByNameOrEmailAdmin,
    companySearchByNameAdmin: _superUser.companySearchByNameAdmin,
    entitySearch: _autoSuggest.entitySearch,
    estimateReport: _estimation.estimateReport,
    estimatePublisherReport: _estimation.estimatePublisherReport,
    getAllTaxonomies: _taxonomies.getAllTaxonomies,
    getAllTaxonomyModels: _taxonomies.getAllTaxonomyModels,
    getTaxonomies: _taxonomies.getTaxonomies,
    getAllDataSources: _taxonomies.getAllDataSources,
    getDataSources: _taxonomies.getDataSources,
    getContainers: _taxonomies.getContainers,
    checkTwitterCache: _estimation.checkTwitterCache,
    dataSetTypes: _general.dataSetTypes,
    getTalkWalkerProjects: _integrations.getTalkWalkerProjects,
    getTalkWalkerSourcePanels: _integrations.getTalkWalkerSourcePanels,
    getTalkWalkerSourcePanelAuthors: _integrations.getTalkWalkerSourcePanelAuthors
  };
});