define("affinio/models/group", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;
  var Model = _emberData.default.Model;
  var _default = _exports.default = Model.extend({
    name: attr('string'),
    company: belongsTo('company'),
    reports: attr(),
    // reports needs to be attr, to avoid overwritting share info
    accounts: hasMany('account'),
    numberOfAccounts: attr(),
    folders: hasMany('folder')
  });
});