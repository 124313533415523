define("affinio/models/taxonomies/subcategory", ["exports", "ember-data", "affinio/mixins/dependency-check", "affinio/mixins/changed-relationship"], function (_exports, _emberData, _dependencyCheck, _changedRelationship) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var hasMany = _emberData.default.hasMany;
  var belongsTo = _emberData.default.belongsTo;
  var _default = _exports.default = Model.extend(_dependencyCheck.default, _changedRelationship.default, {
    path: Ember.inject.service(),
    label: attr(),
    value: attr(),
    uneditable: attr(),
    modules: hasMany('taxonomies/module', {
      inverse: 'subcategory'
    }),
    category: belongsTo('taxonomies/category', {
      inverse: 'subcategories'
    })
  });
});