define("affinio/templates/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "nDm8D1BA",
    "block": "{\"symbols\":[],\"statements\":[[1,[21,\"top-level-nav\"],false],[0,\"\\n\"],[7,\"nav\"],[11,\"class\",\"sub-nav\"],[9],[0,\"\\n  \"],[4,\"link-to\",[\"settings.preferences\"],null,{\"statements\":[[0,\"Preferences\"]],\"parameters\":[]},null],[0,\"\\n  \"],[4,\"link-to\",[\"settings.security\"],null,{\"statements\":[[0,\"Security\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"model\",\"twitterFeatures\"]]],null,{\"statements\":[[0,\"    \"],[4,\"link-to\",[\"settings.benchmarks\"],null,{\"statements\":[[0,\"Tracked Interests\"]],\"parameters\":[]},null],[0,\"\\n    \"],[4,\"link-to\",[\"settings.integrations\"],null,{\"statements\":[[0,\"Integrations\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\\n\"],[7,\"section\"],[11,\"id\",\"overview\"],[12,\"class\",[28,[\"clearfix has-sub-nav \",[23,[\"preferences\",\"normalized\"]]]]],[9],[0,\"\\n  \"],[1,[21,\"outlet\"],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/settings.hbs"
    }
  });
});