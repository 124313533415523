define("affinio/services/modules", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.schema = _exports.default = void 0;
  var standardRefinementOptions = ['title', 'description'];
  var schema = _exports.schema = {
    activationDashboard: {
      card: true,
      fullWidth: true
    },
    pageTitle: {
      fullWidth: true
    },
    pageMessage: {
      fullWidth: true
    },
    audienceHeader: {
      screenshotable: true,
      card: true,
      fullWidth: true
    },
    audienceDetails: {
      screenshotable: true
    },
    audienceSource: {
      screenshotable: true
    },
    audienceVisualization: {
      screenshotable: true,
      card: true,
      stretch: true,
      preferences: [{
        label: 'Visualization Type',
        value: 'select-inline',
        valueKey: 'visualizationType',
        options: [{
          value: 'network-graph',
          label: 'Network Graph',
          dependencies: [{
            report: 'clusterSVGURL'
          }]
        }, {
          value: 'audience-size',
          label: 'Audience Size'
        }]
      }, {
        label: 'Show Labels',
        value: 'select-dropdown',
        valueKey: 'preferences.audienceVizBg',
        dependencyForHide: 'hideNetworkDropDown',
        options: [{
          label: 'Labels on Black Background',
          value: 'black'
        }, {
          label: 'Labels on White Background',
          value: 'white'
        }, {
          label: "Don't Show Labels",
          value: 'none'
        }]
      }, {
        label: 'Show Labels',
        value: 'select-checkbox',
        valueKey: 'preferences.showVisualizationLabels',
        dependencyForHide: 'showLabelPreference',
        options: [{
          label: 'Show Labels',
          value: true
        }, {
          label: 'No Labels',
          value: false
        }]
      }
      // ,
      // {
      //   label: 'Download',
      //   value: 'download-button',
      //   hrefKey: 'svgURI',
      //   downloadKey: 'downloadName'
      // }
      ]
    },
    audienceSummary: {
      screenshotable: true,
      card: true,
      dynamicDependency: 'report.aggregateMetrics',
      preferences: [{
        label: 'Type of Metric',
        value: 'select-dropdown',
        valueKey: 'metric',
        options: [{
          value: 'lurkers',
          label: 'Lurker Percentage',
          description: 'These metrics show the % of an audience who actively post vs. those who "lurk" (i.e. seldomly post, or only consume content). We define lurkers as those who post fewer than 15 times per month.',
          dependencies: [{
            report: 'hasLurkers'
          }]
        }, {
          value: 'interestAffinity',
          label: 'Interest Relevance',
          description: '',
          dependencies: [{
            cluster: 'interestAffinity.value'
          }]
        }, {
          value: 'postsPerMonthCBG',
          label: 'Posts',
          description: '',
          dependencies: [{
            cluster: 'averagePostsPerMonth.response.value'
          }]
        }, {
          value: 'likesCBG',
          label: 'Likes',
          description: '',
          dependencies: [{
            cluster: 'numberOfLikes'
          }]
        }, {
          value: 'sharesCBG',
          label: 'Shares',
          description: '',
          dependencies: [{
            cluster: 'sharesCBG'
          }]
        }, {
          value: 'commentsCBG',
          label: 'Comments',
          description: '',
          dependencies: [{
            cluster: 'numberOfComments'
          }]
        }, {
          value: 'avgLinkKarmaCBG',
          label: 'Average Link Karma',
          description: '',
          dependencies: [{
            cluster: 'averageLinkKarma'
          }]
        }, {
          value: 'avgCommentKarmaCBG',
          label: 'Average Comment Karma',
          description: '',
          dependencies: [{
            cluster: 'averageCommentKarma'
          }]
        }, {
          value: 'avgAccountAgeCBG',
          label: 'Average Account Age',
          description: '',
          dependencies: [{
            cluster: 'averageAccountAge'
          }]
        }, {
          value: 'avgNumPostsCBG',
          label: 'Average Number of Posts',
          description: '',
          dependencies: [{
            cluster: 'averageNumberOfPosts'
          }]
        }, {
          value: 'avgNumCommentsCBG',
          label: 'Average Number of Comments',
          description: '',
          dependencies: [{
            cluster: 'averageNumberOfComments'
          }]
        }, {
          value: 'interconnectivityCBG',
          label: 'Interconnectivity',
          description: '',
          dependencies: [{
            report: 'hasInterconnectivity'
          }]
        }, {
          value: 'uniqueInterestsPercentage',
          label: 'Unique Interests',
          description: '',
          dependencies: [{
            cluster: 'uniqueInterests'
          }]
        }, {
          value: 'verifiedMembersPercentageCBG',
          label: 'Verified',
          description: '',
          dependencies: [{
            report: 'hasVerifiedPercentages'
          }]
        }]
      }]
    },
    clusterSummaries: {
      screenshotable: true,
      list: true,
      fullWidth: true,
      listItemSelector: '.cluster-summary',
      clusterLevelList: true,
      preferences: [{
        label: 'Traits Preferences',
        value: 'traits-preferences',
        valueKey: 'traitsPreferences',
        options: [] // TODO this isn't used by this component
      }],
      refinementOptions: ['networkTraitScores']
    },
    engagementDashboard: {
      fullWidth: true,
      screenshotable: true,
      subModules: [{
        label: 'Engagement Timeline',
        value: '.engagement-timeline'
      }, {
        label: 'Hour & Day Heatmap',
        value: '.engagement-grid'
      }, {
        label: 'Average Engagement by Hour and Day',
        value: '.engagement-highlight'
      }, {
        label: 'Peak Average Engagement by Time',
        value: '.engagement-card.max'
      }, {
        label: 'Lowest Average Engagement by Time',
        value: '.engagement-card.min'
      }]
    },
    topInterests: {
      screenshotable: true,
      card: true,
      preferences: [{
        label: 'Unique Interests',
        value: 'select-checkbox',
        valueKey: 'showUnique',
        dependencies: ['cluster.uniqueInterests.length', {
          not: 'moduleRequest.moduleProperties.showUnique'
        }],
        options: [{
          label: 'Show Uniques',
          value: true
        }, {
          label: 'Show All',
          value: false
        }]
      }]
    },
    numericVisualization: {
      card: true,
      refinementOptions: [].concat(standardRefinementOptions, ['compareBy']),
      preferences: [{
        label: 'Visualization Type',
        value: 'select-dropdown',
        valueKey: 'moduleRequest.moduleProperties.style',
        dependencies: [{
          is: 'validValueBins'
        }],
        options: [{
          value: 'ridgeline',
          label: 'Ridgeline'
        }, {
          value: 'histogram',
          label: 'Histogram'
        }]
      }]
    },
    metricVisualization: {
      screenshotable: true,
      card: true,
      preferences: [{
        label: 'Visualization Type',
        value: 'select-dropdown',
        valueKey: 'normalized',
        options: [{
          value: 'cluster-bias',
          label: 'Normalized',
          dependencies: [{
            not: 'module.id'
          }, {
            minClusters: 2
          }],
          keyNav: 'Shift + 1'
        }, {
          value: 'term-relative',
          label: 'Bar Chart',
          keyNav: 'Shift + 2'
        }, {
          value: 'niche-ranked',
          label: 'Niche Ranked',
          dependencies: [{
            cluster: 'isCluster'
          }, {
            not: 'module.id'
          }, {
            minClusters: 2
          }],
          keyNav: 'Shift + 3',
          caveat: 'only when in a cluster'
        }, {
          value: 'word-cloud',
          label: 'Word Cloud',
          keyNav: 'Shift + 4'
        }, {
          value: 'timeline',
          label: 'Timeline',
          dependencies: [{
            metric: 'times'
          }],
          keyNav: 'Shift + 5',
          caveat: 'only for longitudinal data'
        }, {
          value: 'global-comparison',
          label: 'Baseline',
          preventScreenshot: true,
          dependencies: [{
            report: 'baselineReport'
          }],
          keyNav: 'Shift + 6',
          caveat: 'only for compared data'
        }, {
          value: 'metric-normalized',
          label: 'Metric Normalized',
          dependencies: [{
            not: 'report.taxonomy.meta.loadRecordsMap'
          }],
          keyNav: 'Shift + 7',
          caveat: 'only for custom data'
        }]
      }, {
        label: 'Search',
        value: 'instance-search',
        valueKey: 'instanceSearchInput',
        options: [] // TODO this isn't used by this component
      }],
      refinementOptions: [].concat(standardRefinementOptions, ['numberOfBars', 'defaultBarView', 'sizeWordCloudBy'])
    },
    traitsDashboard: {
      screenshotable: true,
      card: true
    },
    traitsSummary: {
      card: true,
      screenshotable: true,
      preferences: [{
        label: null,
        value: 'select-dropdown',
        valueKey: 'metric',
        options: [{
          value: 'lurkers',
          label: 'Lurker Percentage',
          dependencies: [{
            report: 'hasLurkers'
          }]
        }, {
          value: 'interestAffinity',
          label: 'Interest Affinity',
          dependencies: [{
            'cluster': 'interestAffinity'
          }]
        }, {
          value: 'postsPerMonthCBG',
          label: 'Posts',
          description: '',
          dependencies: [{
            cluster: 'averagePostsPerMonth'
          }]
        }, {
          value: 'likesCBG',
          label: 'Likes',
          description: '',
          dependencies: [{
            cluster: 'numberOfLikes'
          }]
        }, {
          value: 'sharesCBG',
          label: 'Shares',
          description: '',
          dependencies: [{
            cluster: 'numberOfShares'
          }]
        }, {
          value: 'commentsCBG',
          label: 'Comments',
          description: '',
          dependencies: [{
            cluster: 'numberOfComments'
          }]
        }, {
          value: 'avgLinkKarmaCBG',
          label: 'Average Link Karma',
          description: '',
          dependencies: [{
            cluster: 'averageLinkKarma'
          }]
        }, {
          value: 'avgCommentKarmaCBG',
          label: 'Average Comment Karma',
          description: '',
          dependencies: [{
            cluster: 'averageCommentKarma'
          }]
        }, {
          value: 'avgAccountAgeCBG',
          label: 'Average Account Age',
          description: '',
          dependencies: [{
            cluster: 'averageAccountAge'
          }]
        }, {
          value: 'avgNumPostsCBG',
          label: 'Average Number of Posts',
          description: '',
          dependencies: [{
            cluster: 'averageNumberOfPosts'
          }]
        }, {
          value: 'avgNumCommentsCBG',
          label: 'Average Number of Comments',
          description: '',
          dependencies: [{
            cluster: 'averageNumberOfComments'
          }]
        }, {
          value: 'interconnectivityCBG',
          label: 'Interconnectivity',
          description: '',
          dependencies: [{
            cluster: 'density'
          }]
        }, {
          value: 'uniqueInterestsPercentage',
          label: 'Unique Interests',
          description: '',
          dependencies: [{
            cluster: 'uniqueInterests'
          }]
        }, {
          value: 'imageShareCBG',
          label: 'Share of Images',
          description: '',
          dependencies: [{
            report: 'imageAnalysis.response.length'
          }]
        }, {
          value: 'shareOfWallet',
          label: 'Share of Wallet',
          description: 'Share of Wallet (ODC)',
          dependencies: [{
            cluster: 'shareOfWallet'
          }]
        }]
      }]
    },
    traitsDemographics: {
      screenshotable: true,
      card: true
    },
    traitsGallery: {
      screenshotable: true,
      fullWidth: true
      // list: true,
      // listItemSelector: '.trait-card'
    },
    audienceGrowth: {
      screenshotable: true,
      card: true,
      preferences: [{
        label: 'Growth Format',
        value: 'select-inline',
        valueKey: 'preferences.timeSeriesFormat',
        options: [{
          value: 'dailyGrowth',
          label: 'Member Shift'
        }, {
          value: 'percentChange',
          label: 'Percent Change'
        }, {
          value: 'cumulativeSize',
          label: 'Cumulative Size'
        }]
      }]
    },
    deltaCardGallery: {},
    deltaTimelineGallery: {},
    membersProfiles: {
      screenshotable: true,
      card: true,
      fullWidth: true
    },
    ageBreakdown: {
      screenshotable: true,
      card: true
    },
    genderBreakdown: {
      screenshotable: true,
      card: true
    },
    memberLocations: {
      screenshotable: true,
      fullWidth: true
    },
    engagementTimeline: {
      card: true,
      screenshotable: true,
      fullWidth: true
    },
    engagementGrid: {
      card: true,
      screenshotable: true
    },
    engagementHighlight: {
      screenshotable: true,
      card: true
    },
    engagementCard: {
      card: true,
      screenshotable: true
    },
    engagementTimezone: {
      card: true
    },
    engagementLegacy: {
      card: true,
      screenshotable: true
    },
    mediaGallery: {
      screenshotable: true,
      list: true,
      listItemSelector: '.media-frame',
      fullWidth: true
    },
    interestsSearch: {},
    interestsVis: {
      screenshotable: true,
      card: true,
      fullWidth: true,
      contentSelector: '.interests.chart',
      listItemSelector: '.profile-card',
      isCustom: true,
      refinementOptions: [].concat(standardRefinementOptions),
      preferences: [{
        label: 'Visualization Type',
        value: 'select-dropdown',
        valueKey: 'normalized',
        options: [{
          value: 'cluster-bias',
          label: 'Normalized',
          dependencies: [{
            not: 'module.id'
          }, {
            minClusters: 2
          }],
          keyNav: 'Shift + 1'
        }, {
          value: 'term-relative',
          label: 'Bar Chart',
          keyNav: 'Shift + 2'
        }, {
          value: 'niche-ranked',
          label: 'Niche Ranked',
          dependencies: [{
            cluster: 'isCluster'
          }, {
            not: 'module.id'
          }, {
            minClusters: 2
          }],
          keyNav: 'Shift + 3',
          caveat: 'only when in a cluster'
        }, {
          value: 'word-cloud',
          label: 'Word Cloud',
          keyNav: 'Shift + 4'
        }, {
          value: 'timeline',
          label: 'Timeline',
          dependencies: [{
            metric: 'times'
          }],
          keyNav: 'Shift + 5',
          caveat: 'only for longitudinal data'
        }, {
          value: 'global-comparison',
          label: 'Baseline',
          preventScreenshot: true,
          dependencies: [{
            report: 'baselineReport'
          }],
          keyNav: 'Shift + 6',
          caveat: 'only for compared data'
        }, {
          value: 'metric-normalized',
          label: 'Metric Normalized',
          dependencies: [{
            not: 'report.taxonomy.meta.loadRecordsMap'
          }],
          keyNav: 'Shift + 7',
          caveat: 'only for custom data'
        }]
      }, {
        label: 'Search Interests',
        value: 'interests-search-bar',
        preventScreenshot: true,
        valueKey: '_queryText',
        exclusiveKey: '',
        options: [],
        dependencies: []
      }, {
        label: 'Filter By Clusters',
        value: 'cluster-selector',
        preventScreenshot: true,
        valueKey: 'selectedClusters',
        exclusiveKey: 'selectedClustersExclusive',
        options: [],
        dependencies: [{
          subcategory: 'index, location'
        }]
      }, {
        label: 'Filter By Interest Categories',
        value: 'tag-filter',
        preventScreenshot: true,
        valueKey: 'selectedTag',
        exclusiveKey: '',
        options: [],
        dependencies: [{
          not: 'report.isCustom'
        }]
      }, {
        label: 'Group',
        showLabel: true,
        value: 'select-dropdown',
        valueKey: 'selectedBenchmarkGroupId',
        preventScreenshot: true,
        dependencies: [{
          subcategory: 'benchmarks'
        }],
        dynamicOptionsKey: 'benchmarkGroupDynamicOptions',
        options: [{
          label: 'All Groups',
          value: 'all-groups'
        }, {
          label: 'All Tracked Interests',
          value: 'all-members'
        }]
      }, {
        label: 'Sort By',
        showLabel: true,
        value: 'select-dropdown',
        valueKey: 'currentSortBy',
        options: [{
          label: 'Baseline',
          value: 'diffFromBaseline',
          preventScreenshot: true,
          dependencies: [{
            report: 'baselineReport'
          }, {
            metric: 'diffFromBaseline'
          }]
        }, {
          label: 'Global Relevance',
          value: 'relevance',
          dependencies: [{
            metric: 'relevance'
          }]
        }, {
          label: 'Global Affinity',
          value: 'affinity.value',
          dependencies: [{
            metric: 'affinity'
          }]
        }, {
          label: 'Local Relevance',
          value: 'locationRelevance',
          dependencies: [{
            metric: 'locationRelevance'
          }]
        }, {
          label: 'Local Affinity',
          value: 'locationAffinity.value',
          dependencies: [{
            metric: 'locationAffinity'
          }]
        }, {
          label: 'Followers',
          value: 'followersCount',
          dependencies: [{
            subcategory: 'index, page-likes, location'
          }, {
            not: 'report.isCustom'
          }]
        }, {
          label: 'Change',
          value: 'rankChange',
          dependencies: [{
            metric: 'rankChange'
          }]
        }, {
          label: 'Value',
          value: 'value',
          dependencies: [{
            metric: 'value'
          }, {
            report: 'isCustom'
          }]
        }]
      }, {
        label: 'Shade By',
        showLabel: true,
        value: 'select-dropdown',
        valueKey: 'interestsShadeBy',
        preventScreenshot: true,
        dependencies: [{
          subcategory: 'benchmarks'
        }, {
          cluster: 'isAggregate'
        }],
        options: [{
          label: 'Tracked Interests',
          value: 'column'
        }, {
          label: 'Cluster',
          value: 'row'
        }, {
          label: 'None',
          value: 'none'
        }]
      }, {
        label: 'View',
        showLabel: true,
        value: 'select-inline',
        valueKey: 'view',
        options: [{
          label: 'Grid',
          value: 'grid'
        }, {
          label: 'List',
          value: 'list',
          list: true,
          combine: true
        }, {
          label: 'Heatmap',
          value: 'heatmap',
          dependencies: [{
            cluster: 'isAggregate'
          }, {
            subcategory: 'benchmarks'
          }]
          // cancelCompose: true
        }]
      }, {
        label: 'Interest Categories Chart Type',
        showLabel: true,
        value: 'select-dropdown',
        valueKey: 'normalized',
        preventScreenshot: true,
        dependencies: [{
          subcategory: 'tags'
        }],
        options: [{
          value: 'cluster-bias',
          label: 'Normalized'
        }, {
          value: 'term-relative',
          label: 'Bar Chart'
        }, {
          value: 'niche-ranked',
          label: 'Niche Ranked',
          dependencies: [{
            cluster: 'isCluster'
          }, {
            not: 'module.id'
          }, {
            minClusters: 2
          }],
          caveat: 'only when in a cluster'
        }, {
          value: 'word-cloud',
          label: 'Word Cloud'
        }]
      }, {
        label: 'Search',
        value: 'instance-search',
        valueKey: 'instanceSearchInput',
        options: [] // TODO this isn't used by this component
      }]
    },
    imageExplorer: {
      fullWidth: true
    },
    topicExplorer: {
      fullWidth: true
    },
    compareDisplay: {
      fullWidth: true
    },
    csvExporter: {
      card: true,
      fullWidth: true
    },
    powerpointExport: {
      card: true,
      fullWidth: true
    },
    screenshotsExporter: {
      card: true,
      fullWidth: true
    },
    networkGraphExport: {
      card: true,
      fullWidth: true,
      preferences: [{
        label: 'Show Labels',
        value: 'select-dropdown',
        valueKey: 'preferences.networkRectBg',
        options: [{
          label: 'Labels on Black Background',
          value: 'black'
        }, {
          label: 'Labels on White Background',
          value: 'white'
        }, {
          label: "Don't Show Labels",
          value: 'none'
        }]
      }]
    },
    removeCluster: {
      card: true,
      fullWidth: true
    },
    reAddCluster: {
      card: true,
      fullWidth: true
    },
    advertiseDashboard: {
      card: true,
      fullWidth: true
    },
    segmentDashboard: {
      card: true,
      fullWidth: true
    },
    monitorDashboard: {
      card: true,
      fullWidth: true
    },
    translateDashboard: {
      card: true,
      fullWidth: true
    },
    newModule: {
      card: true
    },
    audienceShare: {
      card: true,
      screenshotable: true,
      preferences: [{
        label: 'Audience Share',
        value: 'select-dropdown',
        valueKey: 'preferences.normalized',
        options: [{
          value: 'cluster-bias',
          label: 'Normalized'
        }, {
          value: 'term-relative',
          label: 'Standard'
        }]
      }]
    },
    audienceOverlap: {
      card: true,
      screenshotable: true,
      preferences: [{
        label: 'Audience Share',
        value: 'select-dropdown',
        valueKey: 'preferences.overlapSelected',
        options: [{
          value: '1',
          label: 'Compare followers individually'
        }, {
          value: '2',
          label: 'Compare followers of two',
          dependencies: [{
            lte: 'report.filters.followersOf',
            minus: 'preferences.hiddenOverlaps.length'
          }]
        }, {
          value: '3',
          label: 'Compare followers of three',
          dependencies: [{
            lte: 'report.filters.followersOf',
            minus: 'preferences.hiddenOverlaps.length'
          }]
        }, {
          value: '4',
          label: 'Compare followers of four',
          dependencies: [{
            lte: 'report.filters.followersOf',
            minus: 'preferences.hiddenOverlaps.length'
          }]
        }, {
          value: '5',
          label: 'Compare followers of five',
          dependencies: [{
            lte: 'report.filters.followersOf',
            minus: 'preferences.hiddenOverlaps.length'
          }]
        }, {
          value: '6',
          label: 'Compare followers of six',
          dependencies: [{
            lte: 'report.filters.followersOf',
            minus: 'preferences.hiddenOverlaps.length'
          }]
        }, {
          value: '7',
          label: 'Compare followers of seven',
          dependencies: [{
            lte: 'report.filters.followersOf',
            minus: 'preferences.hiddenOverlaps.length'
          }]
        }, {
          value: '8',
          label: 'Compare followers of eight',
          dependencies: [{
            lte: 'report.filters.followersOf',
            minus: 'preferences.hiddenOverlaps.length'
          }]
        }, {
          value: '9',
          label: 'Compare followers of nine',
          dependencies: [{
            lte: 'report.filters.followersOf',
            minus: 'preferences.hiddenOverlaps.length'
          }]
        }, {
          value: '10',
          label: 'Compare followers of ten',
          dependencies: [{
            lte: 'report.filters.followersOf',
            minus: 'preferences.hiddenOverlaps.length'
          }]
        }, {
          value: '11',
          label: 'Compare followers of eleven',
          dependencies: [{
            lte: 'report.filters.followersOf',
            minus: 'preferences.hiddenOverlaps.length'
          }]
        }, {
          value: '12',
          label: 'Compare followers of twelve',
          dependencies: [{
            lte: 'report.filters.followersOf',
            minus: 'preferences.hiddenOverlaps.length'
          }]
        }]
      }]
    },
    baselineSummary: {
      card: true,
      fullWidth: true
    },
    clusterOverlap: {
      card: true,
      fullWidth: true
    },
    clusterModifier: {
      fullWidth: true
    }
  };

  // TODO: moduleSchema should be inferred from the report's taxonomy, not imported from this modules file
  var _default = _exports.default = Ember.Service.extend({
    preferences: Ember.inject.service(),
    // use (get modules.schema (camelize module.value)) to get these props when needed
    schema: schema
  });
});