define("affinio/routes/settings/preferences", ["exports", "affinio/utils/queries", "affinio/mixins/auth-check", "affinio/mixins/document-title"], function (_exports, _queries, _authCheck, _documentTitle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_authCheck.default, _documentTitle.default, {
    documentTitle: 'Preferences - Settings - Affinio',
    // Services
    graph: Ember.inject.service(),
    model: function model() {
      // Its not an expensive fetch, and will keep people up to date on preferences -JK Jun 2017
      return Ember.get(this, 'graph').query(_queries.default.myPreferences, false);
    }
  });
});