define("affinio/components/modals/modal-base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function escapePress(event) {
    if (event.which === 27) {
      // console.log('escapePress');
      event.data.send('close');
    }
  }
  var _default = _exports.default = Ember.Component.extend({
    tagName: 'section',
    classNames: ['modal-wrapper', 'clearfix'],
    modalManager: Ember.inject.service(),
    properties: Ember.computed({
      get: function get() {
        return null;
      },
      set: function set(key, value) {
        return Ember.setProperties(this, value);
      }
    }),
    closeAfter: null,
    /**
     * adding a closeAfter: 100 into the modalProperties will close
     * the modal after the given time in milliseconds, here we are
     * registering the event on the modal and when the timer has finished
     * or another modal is opened the willDestroyElement() will cancel the event
    */
    willInsertElement: function willInsertElement() {
      var _this = this;
      this._super.apply(this, arguments);
      var closeAfter = Ember.get(this, 'closeAfter');
      if (closeAfter) {
        var closeAfterEvent = Ember.run.later(this, function () {
          return _this.send('close');
        }, closeAfter);
        Ember.set(this, 'closeAfterEvent', closeAfterEvent);
      }
    },
    didInsertElement: function didInsertElement() {
      // console.log('insert');
      Ember.run.scheduleOnce('afterRender', function () {
        return Ember.$('.modal-container').scrollTop(0);
      });
      Ember.$(document).on('keyup', this, escapePress);
      // Focus the form if applicable
      var form = this.$('form.modal');
      if (form.length) {
        /* eslint-disable */
        // jquery .get
        form.get(0).focus();
        /* eslint-enable */
      }
    },
    willDestroyElement: function willDestroyElement() {
      Ember.$(document).off('keyup', escapePress);

      // Some modals will auto close themselves, but allow the user to close too
      // cancel the event incase they close it and then open another modal
      var closeAfterEvent = Ember.get(this, 'closeAfterEvent');
      if (closeAfterEvent) {
        Ember.run.cancel(closeAfterEvent);
      }
    },
    closeAction: function closeAction() {
      Ember.setProperties(this, {
        'modalManager.modal': null,
        'modalManager.modalProperties': null
      });
    },
    actions: {
      close: function close() {
        if (!Ember.get(this, 'noClose')) {
          var beforeCloseAction = Ember.get(this, 'beforeCloseAction');
          if (typeof beforeCloseAction === 'function') {
            beforeCloseAction();
          }
          this.closeAction();
        }
      },
      reset: function reset() {
        var resetAction = Ember.get(this, 'resetAction');
        if (typeof resetAction === 'function') {
          resetAction();
        }
        this.closeAction();
      },
      cancel: function cancel() {
        if (!Ember.get(this, 'noCancel')) {
          var cancelAction = Ember.get(this, 'cancelAction');
          if (typeof cancelAction === 'function') {
            cancelAction();
          }
          this.closeAction();
        }
      },
      ok: function ok() {
        var okAction = Ember.get(this, 'okAction');
        if (typeof okAction === 'function') {
          okAction();
        }
        this.closeAction();
      }
    }
  });
});