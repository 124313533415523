define("affinio/components/modals/modal-signin-failed", ["exports", "affinio/components/modals/modal-text"], function (_exports, _modalText) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _modalText.default.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.$('.close-modal').focus();
    }
  });
});