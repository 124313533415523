define("affinio/utils/taxonomies/custom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.taxonomy = _exports.default = void 0;
  /* eslint-disable */
  var taxonomy = _exports.taxonomy = {
    id: 'Custom',
    meta: {
      title: 'PeerLogix',
      // icon: '/assets/images/source-icons/odc-cpg.png',
      icon: '/assets/images/source-icons/instagram.png',
      colors: {
        primary: '#00ccff',
        secondary: '#ff00cc'
      },
      alwaysLoadMetrics: {
        blocking: true,
        report: ['numberOfMembers'],
        cluster: ['numberOfMembers']
      },
      traits: {
        minimumSaturation: 0.001,
        minimumTimesMoreLikely: 1.2
      },
      globalLabel: 'Baseline'
    },
    schema: [{
      label: 'Overview',
      value: 'overview',
      subcategories: [{
        label: 'Summary',
        value: 'summary',
        modules: [{
          label: 'Audience Details and Source',
          value: 'audience-header',
          dependencies: ['cluster.isAggregate'],
          metrics: [{
            clusterAggregation: 'interests'
          }]
        }, {
          label: 'Audience Visualization',
          value: 'audience-visualization',
          dependencies: ['cluster.isAggregate'],
          metrics: [{
            report: 'clusterSVGURL'
          }],
          description: 'This diagram represents the different clusters within this audience. The lines connecting clusters indicate their shared interests. More lines = more in common.'
        }, {
          label: 'Audience Summary',
          value: 'audience-summary',
          dependencies: ['cluster.isAggregate'],
          metrics: [{
            everyCluster: 'interests'
          }]
        },
        // {
        //   label: 'Audience Summary',
        //   value: 'audience-summary',
        //   dependencies: ['cluster.isAggregate'],
        //   // metrics: [
        //   //   { everyCluster: 'shareOfWallet' }
        //   // ]
        // },
        {
          label: 'Cluster Summaries',
          value: 'cluster-summaries',
          dependencies: ['cluster.isAggregate'],
          description: 'This section provides a consolidated overview of key over-indexing attributes by cluster. Click-through to see more.'
        }, {
          label: 'Top Traits',
          value: 'traits-gallery',
          dependencies: ['cluster.isCluster']
        }, {
          label: 'Top Interests',
          value: 'top-interests',
          metrics: [{
            cluster: '_interests'
          }],
          primaryMetric: 'interests',
          dependencies: ['cluster.isCluster'],
          description: 'These represent this cluster\'s most contextually relevant interests as measured by the Relevance score. Highlight Uniques to see which interests are only relevant to this cluster.'
        }]
      }]
    }, {
      label: 'Interests',
      value: 'interests',
      subcategories: [{
        label: 'Interests',
        value: 'interests',
        modules: [{
          label: 'Interests',
          value: 'interests-vis',
          type: 'full',
          metrics: [{
            cluster: 'interests'
          }],
          description: 'Top downloaded things'
        }]
      }]
    }, {
      label: 'Media',
      value: 'media',
      subcategories: [{
        label: 'Media Data',
        value: 'imdb',
        modules: [{
          label: 'Number of Members',
          value: 'numeric-visualization',
          metrics: [{
            cluster: 'numberOfMembers'
          }],
          description: 'Number of Members',
          moduleProperties: {
            compareBy: 'average',
            visualizationProperties: [{
              label: 'cull',
              value: 0
            }]
          }
        }, {
          label: 'Average IMDB Rating',
          value: 'numeric-visualization',
          metrics: [{
            cluster: 'avgImdbRating'
          }],
          description: 'The average rating across all IMDB media downloaded by this cluster',
          moduleProperties: {
            visualizationProperties: [{
              label: 'cull',
              value: 2
            }]
          }
        }, {
          label: 'Top Genres',
          value: 'metric-visualization',
          metrics: [{
            cluster: 'topGenres'
          }],
          description: 'Top downloaded things'
        }, {
          label: 'Top Directors',
          value: 'metric-visualization',
          metrics: [{
            cluster: 'topDirectors'
          }],
          description: 'Top downloaded things'
        }, {
          label: 'Top Directors Names',
          value: 'metric-visualization',
          metrics: [{
            cluster: 'topDirectorsNameLengths'
          }],
          description: 'Top downloaded things'
        }]
      }]
    }, {
      label: 'Compare',
      value: 'compare',
      dependencies: ['report.hasCompare'],
      subcategories: [{
        label: 'Compare Metrics',
        value: 'index',
        dependencies: ['report.hasManyClusters'],
        modules: [{
          label: 'Compare Display',
          value: 'compare-display'
        }]
      }]
    }, {
      label: 'Actions',
      value: 'actions',
      subcategories: [{
        label: 'Export',
        value: 'export',
        modules: [{
          label: 'Audience Export',
          value: 'page-title'
        }, {
          label: 'Screenshots Export',
          value: 'screenshots-exporter'
        }, {
          label: 'Data Downloads (.csv)',
          value: 'csv-exporter',
          metrics: [{
            everyCluster: 'interests'
          }]
        }]
      }]
    }],
    metricDefinitions: [{
      label: 'clusterSVGURL',
      title: 'Network Graph',
      properties: [],
      defaultValue: 'string',
      level: 'report'
    }, {
      label: 'numberOfMembers',
      level: 'cluster',
      properties: [],
      defaultValue: 'number'
    }, {
      label: 'numberOfMembers',
      level: 'report',
      properties: [],
      defaultValue: 'number'
    }, {
      label: 'topGenres',
      comparable: true
      // aggregate: { checked: true, type: 'instancePercentage', instance: 'Premium', title: '% Premium Affluence' }
    }, {
      label: 'topDirectors',
      comparable: true
    }, {
      label: 'topDirectorsNameLengths',
      comparable: true
    }, {
      label: 'interests',
      comparable: true
      // aggregate: { checked: true, type: 'instancePercentage', instance: 'Premium', title: '% Premium Affluence' }
    }, {
      label: 'avgImdbRating',
      comparable: true,
      defaultValue: 'number'
    }],
    exportSchema: [{
      metrics: ['topGenres']
    }, {
      metrics: ['topDirectors']
    }, {
      metrics: ['topDirectorsNameLengths']
    }, {
      metrics: ['interests']
    }, {
      metrics: ['avgImdbRating']
    }]
  };
  /* eslint-enable */
  var _default = _exports.default = {
    taxonomy: taxonomy
  };
});