define("affinio/components/interest-tag", ["exports", "affinio/utils/interests", "lodash", "affinio/utils/mutations"], function (_exports, _interests, _lodash, _mutations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Element
    classNames: ['tag-filter'],
    tagName: 'section',
    classNameBindings: ['open'],
    notify: Ember.inject.service(),
    modalManager: Ember.inject.service(),
    graph: Ember.inject.service(),
    /* ------------------------------ toggle switch ----------------------------- */
    open: false,
    showSubCategory: true,
    /* ------------------------------- properties ------------------------------- */
    metricKey: undefined,
    // current tab's metric key
    tags: undefined,
    // Array of ['tagName']
    selectedTag: undefined,
    // tag name
    searchText: undefined,
    // string - search text
    /* ----------------------------- end properties ----------------------------- */
    init: function init() {
      this._super.apply(this, arguments);
      var tags = Ember.get(this, 'controller.tags');
      var metricKey = Ember.get(this, 'controller.metric.key');
      Ember.set(this, 'tags', tags);
      Ember.set(this, 'open', false);
      Ember.set(this, 'searchText', '');
      Ember.set(this, 'metricKey', metricKey);
    },
    // convert metric.response.tags to lowercase tags - ['tag1', 'tag2', 'tag1']
    lowercaseTags: Ember.computed('tags', function () {
      var flattenTags = Ember.get(this, 'tags').flatten().compact();
      var lowercaseTags = flattenTags.map(function (item) {
        return item.toLowerCase();
      });
      return lowercaseTags;
    }),
    // generate counts for each tags: e.g.: {'brand' : 765, 'media': 322}
    tagCounts: Ember.computed('lowercaseTags', function () {
      var tagCounts = _lodash.default.countBy(this.lowercaseTags);
      return tagCounts;
    }),
    // find brands count at tagCounts: {brands: 411}
    brandsTagCount: Ember.computed('tagCounts.[]', function () {
      var _this = this;
      if (this.tagCounts) {
        var count = 0;
        _interests.brands.children.forEach(function (child) {
          var tagName = child.value; // lowercase tag value - 'alcohol'
          if (_this.tagCounts[tagName]) {
            count += _this.tagCounts[tagName];
          }
        });
        return count;
      } else {
        return 0;
      }
    }),
    // find uniq lowercase tags
    uniqTags: Ember.computed('lowercaseTags', function () {
      var uniqTags = _lodash.default.uniq(this.lowercaseTags);
      return uniqTags;
    }),
    items: Ember.computed('uniqTags', 'selectedTag', function () {
      var _this2 = this;
      var result = {};
      _interests.tagHierarchyMap.forEach(function (parent) {
        if (parent.children) {
          var childItems = parent.children.reduce(function (accumulator, child) {
            if (!accumulator.some(function (obj) {
              return obj.label === child.label;
            })) {
              accumulator.push({
                label: child.label,
                value: child.value,
                active: _this2.selectedTag === child.value
              });
            }
            return accumulator;
          }, []);
          result[parent.label] = childItems;
        }
      });
      return result;
    }),
    flattenItems: Ember.computed('items', function () {
      var flattenItems = [];
      for (var item in this.items) {
        this.items[item].forEach(function (item) {
          flattenItems.push(item);
        });
      }
      return flattenItems;
    }),
    searchItems: Ember.computed('flattenItems', 'searchText', function () {
      var searchText = this.searchText.toLowerCase();
      var resultItems = this.flattenItems.filter(function (item) {
        if (item.value.includes(searchText)) {
          return item;
        }
      });
      return resultItems;
    }),
    hierarchyMapCount: function hierarchyMapCount(rawHierarchy, tagCounts, uniqTags) {
      var _this3 = this;
      var rawTag = rawHierarchy.value;
      if (uniqTags.includes(rawTag)) {
        var item = {
          value: rawHierarchy.value,
          label: rawHierarchy.label,
          count: tagCounts[rawTag],
          // count number of a hierarchy e.g.:{count: 765}
          active: this.selectedTag === rawTag,
          isParent: true
        };

        // if the hierachy has children, keep attaching children with counts to the parent
        if (rawHierarchy.children) {
          item.children = rawHierarchy.children.map(function (hierarchy) {
            // if the tag is available in uniqTags
            if (uniqTags.includes(hierarchy.value)) {
              var childItem = {
                value: hierarchy.value,
                label: hierarchy.label,
                count: tagCounts[hierarchy.value],
                active: _this3.selectedTag === hierarchy.value,
                parent: item
              };
              return childItem;
            }
          }).compact();
        }
        return item;
      } else {
        return null;
      }
    },
    actions: {
      toggleOpen: function toggleOpen() {
        this.toggleProperty('open');
      },
      toggleOpenAtBackground: function toggleOpenAtBackground(event) {
        var modal = document.querySelector('.tag-filter .cluster-modal');
        var target = event.target;
        if (modal === target) {
          this.toggleProperty('open');
        }
      },
      toggleSubCategory: function toggleSubCategory() {
        this.toggleProperty('showSubCategory');
      },
      selectChildTag: function selectChildTag(childItem) {
        if (childItem.active) {
          Ember.set(this, 'selectedTag', undefined);
          Ember.setProperties(childItem, {
            active: false
          });
        } else {
          Ember.set(this, 'selectedTag', childItem.label);
          Ember.setProperties(childItem, {
            active: true
          });
        }
      },
      submitTags: function submitTags() {
        var _this4 = this;
        Ember.set(this, 'loading', true);
        this.graph.mutate(_mutations.default.createCrowdSourcingTag(this.activeInterest.screenName, this.selectedTag)).then(function () {
          _this4.notify.success('Thank you for making our product better! Your suggestion will be reviewed and applied soon to future reports.');
          Ember.set(_this4, 'showInterestTagsPicker', false);
        }).catch(function (error) {
          var errorMessage = Ember.get(error, 'payload.errors.0.message');
          _this4.notify.error("Error suggesting tag: ".concat(errorMessage));
        }).finally(function () {
          Ember.set(_this4, 'loading', false);
        });
      },
      onTyping: function onTyping(text) {
        Ember.set(this, 'searchText', text);
      }
    }
  });
});