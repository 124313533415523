define("affinio/utils/constants/social-networks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.A([{
    label: 'twitter',
    queryTypes: Ember.A(['topic', 'location', 'follow']),
    supportedFilters: Ember.A(['bioLocations', 'contentQuery', 'followersOf', 'bioKeywords'])
  }, {
    label: 'pinterest',
    queryTypes: Ember.A(['follow']),
    supportedFilters: Ember.A(['followersOf'])
  }]);
});