define("affinio/components/affinio-table-cell/datasource-column-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['affinio-table-cell-datasource-column-preview'],
    modalManager: Ember.inject.service(),
    cleanedDataSet: Ember.computed('row.{dataSet,multiple,multipleDelimeter}', function () {
      var dataSet = Ember.get(this, 'row.dataSet');
      var multiple = Ember.get(this, 'row.multiple');
      var multipleDelimeter = Ember.get(this, 'row.multipleDelimeter');
      if (multiple && multipleDelimeter) {
        return dataSet.map(function (n) {
          return n.split(multipleDelimeter);
        }).flatten();
      } else {
        return dataSet;
      }
    }),
    example: Ember.computed('cleanedDataSet', 'row.{multiple,multipleDelimeter}', function () {
      return _.sample(Ember.get(this, 'cleanedDataSet'), 1);
    }),
    actions: {
      openDataSourcePreview: function openDataSourcePreview(row) {
        this.modalManager.openModal('modals/modal-datasource-column-preview', {
          samples: _.sample(Ember.get(this, 'cleanedDataSet'), 10),
          row: row
        });
      }
    }
  });
});