define("affinio/components/twitter-frame", ["exports", "affinio/utils/affinio"], function (_exports, _affinio) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Element
    classNames: ['twitter-frame', 'media-frame'],
    classNameBindings: ['isDeleted:hidden'],
    tagName: 'div',
    // Services
    tracker: Ember.inject.service(),
    deletedAction: function deletedAction() {},
    loadedAction: function loadedAction() {},
    rejectedAction: function rejectedAction() {},
    didInsertElement: function didInsertElement() {
      var _this = this;
      var id;
      var loadedAction = Ember.get(this, 'loadedAction');
      var rejectedAction = Ember.get(this, 'rejectedAction');
      var deletedAction = Ember.get(this, 'deletedAction');
      if (Ember.get(this, 'instance').id) {
        id = Ember.get(this, 'instance').id;
      } else {
        id = Ember.get(this, 'instance');
      } // if instance has an id. multiple ways of this being handled in the app.
      Ember.run(function () {
        return twttr.widgets.createTweet(id, _this.$()[0], {
          cards: 'visible'
        }).then(function (res) {
          if (res === undefined) {
            // error (deleted tweets still return promise success, so check for undefined)
            // console.log('error', get(this, 'instance'));
            (0, _affinio.maybeRunLater)(_this, function () {
              return Ember.set(_this, 'isDeleted', true);
            }, 0);
            deletedAction(id, _this.$()[0]);
          } else {
            // success
            //  console.log('success', get(this, 'instance'));
            if (Ember.get(_this, 'loadedAction')) {
              // console.log('loadedAction firing!');
              loadedAction(id, _this.$() && _this.$()[0]);
            }
          }
        },
        // rejected
        function () {
          if (Ember.typeOf(rejectedAction) === 'function') {
            rejectedAction(id);
          }
        });
      });
    }
  });
});