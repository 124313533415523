define("affinio/templates/search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "/0GnDp8b",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"top-level-nav\",null,[[\"trackHelp\"],[[27,\"route-action\",[\"trackHelp\"],null]]]],false],[0,\"\\n\\n\"],[7,\"h2\"],[9],[0,\"Internal Report Search\"],[10],[0,\"\\n\"],[1,[27,\"admin-list\",null,[[\"isReports\",\"isInternal\",\"showReports\",\"globalSearch\",\"noDirectShare\",\"sectionSearch\",\"editReports\",\"trackHelp\"],[true,true,true,true,true,\"reports-search\",[27,\"route-action\",[\"editReports\"],null],[27,\"route-action\",[\"trackHelp\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/search.hbs"
    }
  });
});