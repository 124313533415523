define("affinio/components/modules/translate-dashboard", ["exports", "affinio/mixins/affinio-module", "affinio/utils/constants/countries", "affinio/utils/mutations", "ember-copy"], function (_exports, _affinioModule, _countries, _mutations, _emberCopy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  var _default = _exports.default = Ember.Component.extend(_affinioModule.default, {
    // Element
    classNames: ['translate-dashboard'],
    tagName: 'section',
    user: Ember.inject.service(),
    tracker: Ember.inject.service(),
    graph: Ember.inject.service(),
    modalManager: Ember.inject.service(),
    expandedLanguages: _countries.expandedLanguages,
    // imported from utils/constants/countries

    actions: {
      translateReport: function translateReport(language) {
        var _this = this;
        var report = Ember.get(this, 'model.report');
        var filtersClone = (0, _emberCopy.copy)(Ember.get(this, 'model.report.filters'), true);
        Ember.set(filtersClone, 'translationCode', language);
        var newFilters = {};
        Object.entries(filtersClone).forEach(function (item) {
          if (item[1] && item[0] !== 'competitiveBenchmarks' && item[0] !== 'ownedBenchmarks') {
            Ember.set(newFilters, item[0], Ember.isArray(item[1]) // The isArray check is because filters in input need to be comma separated strings, but on output can be arrays proper.
            ? _typeof(item[1].objectAt(0)) === 'object' ? item[1] : item[1].join() : item[1]);
          }
        });

        // Per above, we cut out competitiveBenchmarks and ownedBenchmarks, the old way of doing report input filters;
        // however, this is still maintained as report.filters in output
        // luckily we have storedBenchmarkGroups to pass into new report input filters in a future-friendly way!
        if (Ember.get(this, 'model.report.storedBenchmarkGroups')) {
          Ember.set(newFilters, 'benchmarkGroups', Ember.get(this, 'model.report.storedBenchmarkGroups'));
        }
        var model = {};
        Ember.set(model, 'filters', newFilters);
        Ember.set(model, 'creator', Ember.get(this, 'user.account'));
        Ember.set(model, 'reportType', 'network_graph');
        Ember.set(model, 'numberOfClusters', Ember.get(report, 'numberOfClusters'));
        Ember.set(model, 'socialNetwork', 'Twitter');
        Ember.set(model, 'name', "".concat(Ember.get(report, 'name'), " - Translation"));
        Ember.set(this, 'saving', true);
        Ember.get(this, 'graph').mutate(_mutations.default.launchReport(model)).then(function (response) {
          var trackObject = {
            idOfCampaign: response.id,
            numClusters: Ember.get(model, 'numberOfClusters'),
            type: Ember.get(model, 'reportType'),
            source: Ember.get(model, 'socialNetwork'),
            user: Ember.get(_this, 'user.email'),
            company: Ember.get(_this, 'user.company.name'),
            translation: Ember.get(filtersClone, 'translationCode'),
            runFromTranslation: true
          };
          Ember.get(_this, 'tracker').track('New Campaign Created', trackObject);
          Ember.set(_this, 'user.company.credits', Ember.get(_this, 'user.company.credits') - 1);
          Ember.get(_this, 'modalManager').openModal('modals/modal-success', {
            title: 'Report Generating',
            message: 'Great! Your new report is now being generated. You\'ll receive an email upon completion.',
            closeAfter: 3500
          }); // modal-success
          Ember.set(_this, 'saving', false);
        }).catch(function (err) {
          var error = err.payload.errors[0].message;
          var trackObject = {
            error: error,
            translation: true,
            runFromTranslationTab: true
          };
          Ember.get(_this, 'tracker').track('New Campaign - Error saving new report', trackObject);
          Ember.get(_this, 'modalManager').openModal('modals/modal-error', {
            title: 'Report Error',
            reason: error,
            fix: 'There was an error translating this report -- please contact support@affinio.com for help'
          });
        });
        Ember.set(this, 'saving', false);
      }
    }
  });
});