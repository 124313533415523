define("affinio/utils/interests", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.tagHierarchyMap = _exports.default = _exports.brands = _exports.allInterestTags = void 0;
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  var tagHierarchyMap = _exports.tagHierarchyMap = [{
    value: 'organized groups',
    label: 'Organized Groups',
    children: [{
      value: 'associations',
      label: 'Associations'
    }, {
      value: 'causes & charities',
      label: 'Causes & Charities'
    }, {
      value: 'conference',
      label: 'Conference'
    }, {
      value: 'corporate',
      label: 'Corporate'
    }, {
      value: 'professional association',
      label: 'Associations'
    }, {
      value: 'csr',
      label: 'Causes & Charities'
    }, {
      value: 'political',
      label: 'Causes & Charities'
    }, {
      value: 'ngo',
      label: 'Causes & Charities'
    }, {
      value: 'conglomerate',
      label: 'Corporate'
    }, {
      value: 'industrial',
      label: 'Corporate'
    }, {
      value: 'fmcg corporate',
      label: 'Corporate'
    }, {
      value: 'business',
      label: 'Corporate'
    }]
  }, {
    value: 'cpg',
    label: 'CPG',
    children: [{
      value: 'alcohol',
      label: 'Alcohol'
    }, {
      value: 'beverages',
      label: 'Beverages'
    }, {
      value: 'food',
      label: 'Food'
    }, {
      value: 'personal care',
      label: 'Personal Care'
    }, {
      value: 'beer',
      label: 'Alcohol'
    }, {
      value: 'spirits',
      label: 'Alcohol'
    }, {
      value: 'wine',
      label: 'Alcohol'
    }, {
      value: 'soft drink',
      label: 'Beverages'
    }, {
      value: 'water',
      label: 'Beverages'
    }, {
      value: 'coffee & tea',
      label: 'Beverages'
    }, {
      value: 'fmcg food',
      label: 'Food'
    }, {
      value: 'baby food',
      label: 'Food'
    }, {
      value: 'confectionery',
      label: 'Food'
    }, {
      value: 'dairy',
      label: 'Food'
    }, {
      value: 'hygiene',
      label: 'Personal Care'
    }, {
      value: 'beauty',
      label: 'Personal Care'
    }]
  }, {
    value: 'culture',
    label: 'Culture',
    children: [{
      value: 'arts & literature',
      label: 'Arts & Literature'
    }, {
      value: 'communities',
      label: 'Communities'
    }, {
      value: 'education',
      label: 'Education'
    }, {
      value: 'governmental',
      label: 'Governmental'
    }, {
      value: 'institutions',
      label: 'Institutions'
    }, {
      value: 'location',
      label: 'Location'
    }, {
      value: 'religion',
      label: 'Religion'
    }, {
      value: 'wikipedia',
      label: 'Arts & Literature'
    }, {
      value: 'art',
      label: 'Arts & Literature'
    }, {
      value: 'shopping center',
      label: 'Communities'
    }, {
      value: 'restaurant & cafe',
      label: 'Communities'
    }, {
      value: 'night life',
      label: 'Communities'
    }, {
      value: 'park',
      label: 'Communities'
    }, {
      value: 'cultural center',
      label: 'Institutions'
    }, {
      value: 'museum',
      label: 'Institutions'
    }, {
      value: 'science',
      label: 'Institutions'
    }, {
      value: 'airport',
      label: 'Location'
    }, {
      value: 'city',
      label: 'Location'
    }, {
      value: 'country',
      label: 'Location'
    }]
  }, {
    value: 'healthcare',
    label: 'Healthcare',
    children: [{
      value: 'health organizations',
      label: 'Health Organizations'
    }, {
      value: 'hospitals & clinics',
      label: 'Hospitals & Clinics'
    }, {
      value: 'pharma',
      label: 'Pharma'
    }, {
      value: 'medical center',
      label: 'Hospitals & Clinics'
    }]
  }, {
    value: 'influencers',
    label: 'Influencers',
    children: [{
      value: 'activist',
      label: 'Activist'
    }, {
      value: 'advisor',
      label: 'Advisor'
    }, {
      value: 'astronaut',
      label: 'Advisor'
    }, {
      value: 'doctor',
      label: 'Advisor'
    }, {
      value: 'entrepreneur',
      label: 'Advisor'
    }, {
      value: 'legal',
      label: 'Advisor'
    }, {
      value: 'business advisor',
      label: 'Advisor'
    }, {
      value: 'marketer',
      label: 'Advisor'
    }, {
      value: 'athlete',
      label: 'Athlete'
    }, {
      value: 'sport star',
      label: 'Athlete'
    }, {
      value: 'entertainer',
      label: 'Athlete'
    }, {
      value: 'author',
      label: 'Author'
    }, {
      value: 'writer',
      label: 'Author'
    }, {
      value: 'blogger',
      label: 'Blogger'
    }, {
      value: 'character',
      label: 'Character'
    }, {
      value: 'fictional character',
      label: 'Character'
    }, {
      value: 'creative',
      label: 'Creative'
    }, {
      value: 'artist',
      label: 'Creative'
    }, {
      value: 'fashionista',
      label: 'Fashionista'
    }, {
      value: 'fashion star',
      label: 'Fashionista'
    }, {
      value: 'gamer',
      label: 'Gamer'
    }, {
      value: 'pro gamer',
      label: 'Gamer'
    }, {
      value: 'esports',
      label: 'Gamer'
    }, {
      value: 'host',
      label: 'Host'
    }, {
      value: 'podcast host',
      label: 'Host'
    }, {
      value: 'tv host',
      label: 'Host'
    }, {
      value: 'radio host',
      label: 'Host'
    }, {
      value: 'journalist',
      label: 'Host'
    }, {
      value: 'tv personality',
      label: 'Host'
    }, {
      value: 'mogul',
      label: 'Mogul'
    }, {
      value: 'investor',
      label: 'Mogul'
    }, {
      value: 'ceo',
      label: 'Mogul'
    }, {
      value: 'musician',
      label: 'Musician'
    }, {
      value: 'singer',
      label: 'Musician'
    }, {
      value: 'disc jockey',
      label: 'Musician'
    }, {
      value: 'band',
      label: 'Musician'
    }, {
      value: 'rapper',
      label: 'Musician'
    }, {
      value: 'people other',
      label: 'People Other'
    }, {
      value: 'performer',
      label: 'Performer'
    }, {
      value: 'actor',
      label: 'Performer'
    }, {
      value: 'broadcast star',
      label: 'Performer'
    }, {
      value: 'comedian',
      label: 'Performer'
    }, {
      value: 'politician',
      label: 'Politician'
    }, {
      value: 'politics',
      label: 'Politician'
    }, {
      value: 'social influencer',
      label: 'Social Influencer'
    }, {
      value: 'online show',
      label: 'Social Influencer'
    }, {
      value: 'beauty vlogger',
      label: 'Social Influencer'
    }, {
      value: 'vlogger',
      label: 'Social Influencer'
    }, {
      value: 'socialite',
      label: 'Social Influencer'
    }]
  }, {
    value: 'lifestyle',
    label: 'Lifestyle',
    children: [{
      value: 'auto',
      label: 'Auto'
    }, {
      value: 'auto interest',
      label: 'Auto'
    }, {
      value: 'children',
      label: 'Children'
    }, {
      value: 'toys & games',
      label: 'Children'
    }, {
      value: 'dining',
      label: 'Dining'
    }, {
      value: 'retail food',
      label: 'Dining'
    }, {
      value: 'household',
      label: 'Household'
    }, {
      value: 'home maintenance',
      label: 'Household'
    }, {
      value: 'utensils',
      label: 'Household'
    }, {
      value: 'furniture',
      label: 'Household'
    }, {
      value: 'appliance',
      label: 'Household'
    }, {
      value: 'chemicals',
      label: 'Household'
    }, {
      value: 'stationery',
      label: 'Household'
    }, {
      value: 'home & living',
      label: 'Household'
    }, {
      value: 'household goods',
      label: 'Household'
    }, {
      value: 'leisure',
      label: 'Leisure'
    }, {
      value: 'life style',
      label: 'Leisure'
    }, {
      value: 'pets',
      label: 'Pets'
    }, {
      value: 'wellness',
      label: 'Wellness'
    }]
  }, {
    value: 'media & entertainment',
    label: 'Media & Entertainment',
    children: [{
      value: 'channels',
      label: 'Channels'
    }, {
      value: 'tv channels',
      label: 'Channels'
    }, {
      value: 'tv channels & shows',
      label: 'Channels'
    }, {
      value: 'emerging',
      label: 'Emerging'
    }, {
      value: 'entertainment industry',
      label: 'Entertainment Industry'
    }, {
      value: 'music industry',
      label: 'Entertainment Industry'
    }, {
      value: 'film industry',
      label: 'Entertainment Industry'
    }, {
      value: 'film',
      label: 'Entertainment Industry'
    }, {
      value: 'music',
      label: 'Entertainment Industry'
    }, {
      value: 'events',
      label: 'Events'
    }, {
      value: 'event',
      label: 'Events'
    }, {
      value: 'gaming',
      label: 'Gaming Industry'
    }, {
      value: 'game developer',
      label: 'Gaming Industry'
    }, {
      value: 'gaming console',
      label: 'Gaming Industry'
    }, {
      value: 'video game',
      label: 'Games'
    }, {
      value: 'gambling',
      label: 'Gaming Industry'
    }, {
      value: 'computer game',
      label: 'Games'
    }, {
      value: 'games',
      label: 'Games'
    }, {
      value: 'gaming industry',
      label: 'Gaming Industry'
    }, {
      value: 'movies',
      label: 'Movies'
    }, {
      value: 'podcast',
      label: 'Podcast'
    }, {
      value: 'radio',
      label: 'Radio'
    }, {
      value: 'radio media',
      label: 'Radio'
    }, {
      value: 'tv shows',
      label: 'Shows'
    }, {
      value: 'broadcast show',
      label: 'Shows'
    }, {
      value: 'shows',
      label: 'Shows'
    }, {
      value: 'streaming music',
      label: 'Streaming Music'
    }, {
      value: 'streaming video',
      label: 'Streaming Video'
    }, {
      value: 'social networks',
      label: 'Social Networks'
    }, {
      value: 'memes & fun',
      label: 'Memes & Fun'
    }, {
      value: 'social',
      label: 'Memes & Fun'
    }, {
      value: 'social media',
      label: 'Social Networks'
    }]
  }, {
    value: 'publishing',
    label: 'Publishing',
    children: [{
      value: 'books',
      label: 'Books'
    }, {
      value: 'digital',
      label: 'Digital'
    }, {
      value: 'online media',
      label: 'Digital'
    }, {
      value: 'printed media',
      label: 'Digital'
    }, {
      value: 'multimedia',
      label: 'Multimedia'
    }, {
      value: 'media house',
      label: 'Multimedia'
    }, {
      value: 'news',
      label: 'News'
    }, {
      value: 'sites',
      label: 'Sites'
    }, {
      value: 'blog',
      label: 'Sites'
    }, {
      value: 'erotic',
      label: 'Sites'
    }, {
      value: 'fun',
      label: 'Sites'
    }, {
      value: 'hobbies',
      label: 'Sites'
    }, {
      value: 'meme',
      label: 'Sites'
    }]
  }, {
    value: 'services',
    label: 'Services',
    children: [{
      value: 'agency',
      label: 'Agency'
    }, {
      value: 'financial',
      label: 'Financial'
    }, {
      value: 'finance',
      label: 'Financial'
    }, {
      value: 'bank',
      label: 'Financial'
    }, {
      value: 'insurance',
      label: 'Financial'
    }, {
      value: 'payment',
      label: 'Financial'
    }, {
      value: 'providers',
      label: 'Providers'
    }, {
      value: 'housing',
      label: 'Providers'
    }, {
      value: 'mail & shipping',
      label: 'Providers'
    }, {
      value: 'transportation',
      label: 'Providers'
    }]
  }, {
    value: 'shopping',
    label: 'Shopping',
    children: [{
      value: 'ecommerce',
      label: 'Ecommerce'
    }, {
      value: 'e shop',
      label: 'Ecommerce'
    }, {
      value: 'fashion',
      label: 'Fashion'
    }, {
      value: 'accessories',
      label: 'Fashion'
    }, {
      value: 'clothing',
      label: 'Fashion'
    }, {
      value: 'jewelry',
      label: 'Fashion'
    }, {
      value: 'retail',
      label: 'Retail'
    }, {
      value: 'sporting goods',
      label: 'Sporting Goods'
    }]
  }, {
    value: 'sports',
    label: 'Sports',
    children: [{
      value: 'competitions',
      label: 'Competitions'
    }, {
      value: 'sport event',
      label: 'Competitions'
    }, {
      value: 'esport',
      label: 'Esport'
    }, {
      value: 'league',
      label: 'League'
    }, {
      value: 'sport organization',
      label: 'League'
    }, {
      value: 'sport interest',
      label: 'Sports Fandom'
    }, {
      value: 'sports fandom',
      label: 'Sports Fandom'
    }, {
      value: 'team',
      label: 'Team'
    }, {
      value: 'sport club',
      label: 'Team'
    }, {
      value: 'sport team',
      label: 'Team'
    }]
  }, {
    value: 'technology',
    label: 'Technology',
    children: [{
      value: 'electronics',
      label: 'Electronics'
    }, {
      value: 'audio',
      label: 'Electronics'
    }, {
      value: 'camera',
      label: 'Electronics'
    }, {
      value: 'computer',
      label: 'Electronics'
    }, {
      value: 'software & apps',
      label: 'Software & Apps'
    }, {
      value: 'apps',
      label: 'Software & Apps'
    }, {
      value: 'web portal',
      label: 'Software & Apps'
    }, {
      value: 'software',
      label: 'Software & Apps'
    }, {
      value: 'telecom',
      label: 'Telecom'
    }, {
      value: 'phone',
      label: 'Telecom'
    }, {
      value: 'blockchain & web3',
      label: 'Blockchain & Web3'
    }]
  }, {
    value: 'travel',
    label: 'Travel',
    children: [{
      value: 'airlines',
      label: 'Airlines'
    }, {
      value: 'hotels',
      label: 'Hotels'
    }, {
      value: 'accommodation',
      label: 'Hotels'
    }, {
      value: 'vacation',
      label: 'Vacation'
    }, {
      value: 'travel booking',
      label: 'Vacation'
    }]
  }, {
    label: 'Uncategorized',
    value: 'uncategorized'
  }];

  // special brand tags that allows `Select All Brands` button to work
  // code logic can be found in tag-filter.js
  var brands = _exports.brands = {
    value: 'brands',
    label: 'Brands',
    children: [{
      value: 'alcohol',
      label: 'Alcohol'
    }, {
      value: 'beverages',
      label: 'Beverages'
    }, {
      value: 'food',
      label: 'Food'
    }, {
      value: 'personal care',
      label: 'Personal Care'
    }, {
      value: 'pharma',
      label: 'Pharma'
    }, {
      value: 'auto',
      label: 'Auto'
    }, {
      value: 'children',
      label: 'Children'
    }, {
      value: 'dining',
      label: 'Dining'
    }, {
      value: 'household',
      label: 'Household'
    }, {
      value: 'pets',
      label: 'Pets'
    }, {
      value: 'ecommerce',
      label: 'Ecommerce'
    }, {
      value: 'fashion',
      label: 'Fashion'
    }, {
      value: 'retail',
      label: 'Retail'
    }, {
      value: 'sporting goods',
      label: 'Sporting Goods'
    }, {
      value: 'electronics',
      label: 'Electronics'
    }, {
      value: 'software & apps',
      label: 'Software & Apps'
    }, {
      value: 'telecom',
      label: 'Telecom'
    },
    // new brand tags as requested from https://app.clubhouse.io/affinio/story/71205/brand-interest-filter
    {
      value: 'games',
      label: 'Gaming Industry'
    }, {
      value: 'gaming',
      label: 'Gaming Industry'
    }, {
      value: 'streaming music',
      label: 'Streaming Music'
    }, {
      value: 'streaming video',
      label: 'Streaming Video'
    }, {
      value: 'social',
      label: 'Memes & Fun'
    }, {
      value: 'airlines',
      label: 'Airlines'
    }, {
      value: 'hotels',
      label: 'Hotels'
    }]
  };
  function flattenObj(_ref) {
    var label = _ref.label,
      value = _ref.value,
      _ref$children = _ref.children,
      children = _ref$children === void 0 ? [] : _ref$children;
    return [{
      label: label,
      value: value
    }].concat(_toConsumableArray(children.flatMap(flattenObj) || []));
  }

  // All tags, regardless of hierarchy, used for generating the
  // pptx export by searching for tag label
  var allInterestTags = _exports.allInterestTags = tagHierarchyMap.flatMap(flattenObj);
  var _default = _exports.default = {
    tagHierarchyMap: tagHierarchyMap,
    brands: brands,
    allInterestTags: allInterestTags
  };
});