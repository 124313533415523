define("affinio/components/modules/traits-gallery", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  var _default = _exports.default = Ember.Component.extend({
    // Element
    classNames: ['traits-gallery', 'clearfix'],
    tagName: 'div',
    path: Ember.inject.service(),
    cluster: Ember.computed.alias('model.cluster'),
    report: Ember.computed.alias('model.report'),
    summaryViews: Ember.computed(function () {
      return [{
        label: 'most',
        type: 'traits',
        data: 'processedTraits'
      }, {
        label: 'least',
        type: 'traits',
        data: 'processedUnTraits'
      }];
    }),
    traitTypes: Ember.computed('model.report.clusters.@each.traits', function () {
      var _this = this;
      var allClusterTraits = Ember.get(this, 'model.report.clusters').mapBy('traits');
      var traits = ['traits'].concat(_toConsumableArray(allClusterTraits.compact().mapBy('meta').map(function (c) {
        return Ember.get(c, 'data').mapBy('label');
      }).flatten().uniq())).map(function (trait) {
        var label;
        var metricDef = Ember.get(_this, 'model.report.taxonomy.metricDefinitions').findBy('label', trait);
        if (metricDef) {
          var referencingModules = Ember.get(metricDef, 'referencingModules');
          if (referencingModules.length && Ember.get(_this, 'model.report.socialNetwork') === 'Custom') {
            label = Ember.get(referencingModules.objectAt(0), 'label');
          } else {
            label = Ember.get(metricDef, 'title');
          }
        } else {
          label = trait === 'traits' ? 'Attributes' : Ember.String.dasherize(trait).replace(/-/g, ' ');
        }
        return {
          label: label,
          value: trait
        };
      });
      traits = traits.sortBy('label');
      var traitSpot = traits.findBy('value', 'traits');
      traits.splice(traits.indexOf(traitSpot), 1);
      traits.unshift(traitSpot);
      return traits;
    }),
    selectedTraitType: Ember.computed('traitTypes', function () {
      return Ember.get(Ember.get(this, 'traitTypes').objectAt(0), 'value');
    }),
    traits: Ember.computed('cluster.traits', 'selectedTraitType', 'report.recordsMap.size', function () {
      var data = Ember.get(this, 'cluster.traits.data');
      if (data && Ember.get(this, 'selectedTraitType') !== 'traits') {
        data = data.filterBy('metric', Ember.get(this, 'selectedTraitType'));
      }
      return data;
    }),
    unTraits: Ember.computed('cluster.unTraits', 'selectedTraitType', 'report.recordsMap.size', function () {
      var data = Ember.get(this, 'cluster.unTraits.data');
      if (data && Ember.get(this, 'selectedTraitType') !== 'traits') {
        data = data.filterBy('metric', Ember.get(this, 'selectedTraitType'));
      }
      return data;
    })
  });
});