define("affinio/components/modules/numeric-visualization", ["exports", "affinio/mixins/affinio-module", "affinio/utils/get-with-default", "affinio/utils/custom-data/validate-value-bins"], function (_exports, _affinioModule, _getWithDefault, _validateValueBins) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  var _default = _exports.default = Ember.Component.extend(_affinioModule.default, {
    // Element
    classNames: ['numeric-visualization'],
    tagName: 'section',
    // Services
    user: Ember.inject.service(),
    tracker: Ember.inject.service(),
    path: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      if (!Ember.get(this, 'moduleRequest.moduleProperties.compareBy')) {
        Ember.set(this, 'moduleRequest.moduleProperties.compareBy', 'average');
      }
      if (!Ember.get(this, 'moduleRequest.moduleProperties.style')) {
        Ember.set(this, 'moduleRequest.moduleProperties.style', Ember.get(this, 'validValueBins') ? 'histogram' : 'ridgeline');
      }
      if (!Ember.get(this, 'moduleRequest.moduleProperties.visualizationProperties')) {
        Ember.set(this, 'moduleRequest.moduleProperties.visualizationProperties', [{
          label: 'cull',
          value: 0
        }]);
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (Ember.get(this, 'moduleRequest.moduleProperties.style') === 'histogram' && !Ember.get(this, 'validValueBins')) {
        Ember.set(this, 'moduleRequest.moduleProperties.style', 'ridgeline');
      }
    },
    validValueBins: Ember.computed('histogramLabels', function () {
      return Ember.get(this, 'histogramLabels').length !== 0;
    }),
    histogramLabels: Ember.computed('metric.loaded', 'model.{cluster.id,report.compareData}', 'moduleRequest.nestedCompare', function () {
      var metricName = Ember.get(this, 'metric.label');
      if (Ember.get(this, 'moduleRequest.nestedCompare')) {
        var clusters = Ember.get(this, 'model.report.compareData');
        var allValueBins = clusters.map(function (cluster) {
          return Ember.get(cluster, "".concat(metricName, ".response.valueBins"));
        }).compact();
        // check is done this way because if any of the clusters are missing valuebins they shouldn't be used
        // even if some of the clusters do have valid ones
        if (allValueBins.length === clusters.length) {
          return (0, _validateValueBins.default)(allValueBins);
        } else {
          return [];
        }
      } else {
        var cluster = Ember.get(this, 'model.cluster');
        var valueBins = Ember.get(cluster, "".concat(metricName, ".response.valueBins"));
        if (valueBins) {
          return (0, _validateValueBins.default)([valueBins]);
        } else {
          return [];
        }
      }
    }),
    histogram: Ember.computed('moduleRequest.moduleProperties.style', 'model.{cluster.id,report.compareData}', function () {
      return Ember.get(this, 'validValueBins') && Ember.get(this, 'moduleRequest.moduleProperties.style') === 'histogram';
    }),
    histogramData: Ember.computed('metric.loaded', 'model.{cluster.id,report.compareData}', 'moduleRequest.nestedCompare', function () {
      var metricName = Ember.get(this, 'metric.label');
      if (Ember.get(this, 'moduleRequest.nestedCompare')) {
        return Ember.get(this, 'model.report.compareData').map(function (cluster) {
          var clusterID = Ember.get(cluster, 'id');
          return {
            clusterID: clusterID,
            color: (0, _getWithDefault.default)(cluster, 'color', '#777777'),
            valueBins: (0, _getWithDefault.default)(Ember.get(cluster, metricName), 'response.valueBins', [])
          };
        });
      } else {
        var clusterID = Ember.get(this, 'model.cluster.id');
        var cluster = Ember.get(this, 'model.report.allClusters').findBy('id', clusterID);
        return [{
          clusterID: clusterID,
          color: (0, _getWithDefault.default)(cluster, 'color', '#777777'),
          valueBins: (0, _getWithDefault.default)(Ember.get(cluster, metricName), 'response.valueBins', [])
        }];
      }
    }),
    comparableBins: Ember.computed('metric.loaded', 'model.{cluster.id,report.compareData}', 'moduleRequest.nestedCompare', function () {
      var metricName = Ember.get(this, 'metric.label');
      var clusters = Ember.get(this, 'moduleRequest.nestedCompare') ? Ember.get(this, 'model.report.compareData') : Ember.get(this, 'model.report.clusters');
      var comparables = clusters.map(function (cluster) {
        return {
          cluster: Ember.get(cluster, 'id'),
          color: Ember.get(cluster, 'color') || '#444444',
          bins: (0, _getWithDefault.default)(Ember.get(cluster, metricName), 'response.structuredBins', [])
        };
      });
      return comparables;
    }),
    // Properties
    // value: alias('metric.response.value'),
    value: Ember.computed('metric.response', 'moduleRequest.moduleProperties.compareBy', function () {
      // we should handle both single digits and arrays of digits.
      var method = Ember.get(this, 'moduleRequest.moduleProperties.compareBy') || 'average';
      var value;
      var response = Ember.get(this, 'metric.response');
      if (response && !Ember.isEmpty(response)) {
        // First, let's determine if we have a number or an array of numbers
        if (Ember.isArray(response)) {
          response = response.mapBy('value');
          if (method === 'sum') {
            value = response.reduce(function (m, n) {
              return m + n;
            });
          } else if (method === 'average') {
            value = response.reduce(function (m, n) {
              return m + n;
            }) / response.length;
          } else if (method === 'top') {
            value = Math.max.apply(Math, _toConsumableArray(response));
          }
          return value;
        } else {
          return Ember.get(this, 'metric.response.value');
        }
      }
    }),
    compareValues: Ember.computed('metric.response', 'moduleRequest.{moduleProperties.compareBy,nestedCompare}', function () {
      // we should handle both single digits and arrays of digits.
      if (!Ember.get(this, 'moduleRequest.nestedCompare')) {
        return {};
      }
      var method = Ember.get(this, 'moduleRequest.moduleProperties.compareBy') || 'average';
      var clusters = Ember.get(this, 'model.report.compareData');
      var metricName = Ember.get(this, 'metric.label');
      return clusters.map(function (cluster) {
        var response = Ember.get(Ember.get(cluster, metricName), 'response');
        var value;
        var name = Ember.get(cluster, 'name');
        if (response && !Ember.isEmpty(response)) {
          // First, let's determine if we have a number or an array of numbers
          if (Ember.isArray(response)) {
            response = response.mapBy('value');
            if (method === 'sum') {
              value = response.reduce(function (m, n) {
                return m + n;
              });
            } else if (method === 'average') {
              value = response.reduce(function (m, n) {
                return m + n;
              }) / response.length;
            } else if (method === 'top') {
              value = Math.max.apply(Math, _toConsumableArray(response));
            }
          } else {
            value = Ember.get(Ember.get(cluster, metricName), 'response.value');
          }
        }
        return {
          name: name,
          value: value
        };
      });
    }),
    comparables: Ember.computed('model.report.clusters', function () {
      var metricName = Ember.get(this, 'metric.label');
      var clusters = Ember.get(this, 'model.report.clusters');
      if (metricName) {
        var comparables = clusters.mapBy(metricName);
        var comparableType = Ember.get(comparables.objectAt(0), 'defaultValue'); // check to see if number or array
        if (comparableType === 'number') {
          comparables = comparables.map(function (x) {
            return Ember.get(x, 'response.value');
          });
        } else if (comparableType === 'array') {
          comparables = comparables.mapBy('response').map(function (metric) {
            return metric.mapBy('value');
          });
        }
        return comparables;
      }
    }),
    comparison: Ember.computed('comparisonMethod', 'comparables', 'moduleRequest.moduleProperties.compareBy', 'metric.loaded', function () {
      if (Ember.get(this, 'metric.loaded')) {
        var comparables = Ember.get(this, 'comparables');
        var method = (0, _getWithDefault.default)(this, 'moduleRequest.moduleProperties.compareBy', 'average');
        var clusters = Ember.get(this, 'model.report.clusters');
        var value;
        if (comparables.every(function (comp) {
          return (!isNaN(comp) || Ember.isArray(comp)) && !Ember.isEmpty(comp);
        })) {
          // First, let's determine if each cluster has a number or an array of numbers
          if (Ember.isArray(comparables.objectAt(0))) {
            if (method === 'sum') {
              value = comparables.map(function (comparable) {
                return comparable.reduce(function (m, n) {
                  return m + n;
                });
              }).reduce(function (m, n) {
                return m + n;
              });
            } else if (method === 'average') {
              value = comparables.map(function (comparable) {
                return comparable.reduce(function (m, n) {
                  return m + n;
                }) / comparable.length;
              }).reduce(function (m, n) {
                return m + n;
              }) / comparables.length;
            } else if (method === 'top') {
              var _ref;
              value = Math.max.apply(Math, _toConsumableArray((_ref = []).concat.apply(_ref, _toConsumableArray(comparables)))); // supa dupa flat
            }
          } else {
            if (method === 'sum') {
              value = comparables.reduce(function (m, n) {
                return m + n;
              });
            } else if (method === 'average') {
              value = comparables.map(function (num, iter) {
                return num * Ember.get(clusters.objectAt(iter), 'numberOfMembers.response.value');
              }).reduce(function (m, n) {
                return m + n;
              });
              value = value / clusters.mapBy('numberOfMembers.response.value').reduce(function (m, n) {
                return m + n;
              });
            } else if (method === 'top') {
              value = Math.max.apply(Math, _toConsumableArray(comparables));
            }
          }
          return {
            method: method,
            value: value
          };
        }
      }
    }),
    cull: Ember.computed('moduleRequest', 'moduleRequest.moduleProperties.visualizationProperties.@each.value', function () {
      if (Ember.get(this, 'moduleRequest.moduleProperties.visualizationProperties') && Ember.get(this, 'moduleRequest.moduleProperties.visualizationProperties').findBy('label', 'cull')) {
        return Ember.get(Ember.get(this, 'moduleRequest.moduleProperties.visualizationProperties').findBy('label', 'cull'), 'value');
      } else {
        return 0;
      }
    })
  });
});