define("affinio/templates/components/preferences/select-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "7A2hqvAw",
    "block": "{\"symbols\":[\"o\",\"o\"],\"statements\":[[4,\"if\",[[23,[\"noDefault\"]]],null,{\"statements\":[[0,\"  \"],[7,\"option\"],[11,\"value\",\"\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"keys\"]]],null,{\"statements\":[[4,\"each\",[[23,[\"options\"]]],null,{\"statements\":[[0,\"    \"],[7,\"option\"],[12,\"value\",[27,\"get\",[[22,2,[]],[23,[\"keys\",\"1\"]]],null]],[9],[1,[27,\"get\",[[22,2,[]],[23,[\"keys\",\"0\"]]],null],false],[10],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[]},{\"statements\":[[4,\"each\",[[23,[\"options\"]]],null,{\"statements\":[[4,\"if\",[[22,1,[\"label\"]]],null,{\"statements\":[[0,\"      \"],[7,\"option\"],[12,\"value\",[22,1,[\"value\"]]],[9],[1,[22,1,[\"label\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"option\"],[12,\"value\",[22,1,[]]],[9],[1,[22,1,[]],false],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/preferences/select-dropdown.hbs"
    }
  });
});