define("affinio/components/pie-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    preferences: Ember.inject.service(),
    path: Ember.inject.service(),
    user: Ember.inject.service(),
    tracker: Ember.inject.service(),
    router: Ember.inject.service(),
    classNames: ['pie-chart'],
    competitive: false,
    pattern: Ember.computed('records', 'preferences.categoricalColors', 'model.report._clusters.@each.isClusterRemoved', function () {
      var color;
      if (Ember.get(this, 'competitive') === true) {
        if (Ember.get(this, 'preferences.categoricalColors') && Ember.get(this, 'preferences.categoricalColors') === 'Standard Categorical') {
          color = d3.scale.ordinal().domain(d3.range(20)).range(Ember.get(this, 'preferences.categoricalSets').findBy('name', 'Tritanopia').colors).range();
        } else if (Ember.get(this, 'preferences.categoricalColors') && Ember.get(this, 'preferences.categoricalColors') !== 'Standard Categorical') {
          color = d3.scale.ordinal().domain(d3.range(20)).range(Ember.get(this, 'preferences.categoricalSets').findBy('name', 'Standard Categorical').colors).range();
        } else {
          color = d3.scale.category20().domain(d3.range(20)).range();
        }
      } else {
        if (Ember.get(this, 'preferences.categoricalColors') && Ember.get(this, 'preferences.categoricalColors') !== 'Standard Categorical') {
          color = d3.scale.ordinal().domain(d3.range(20)).range(Ember.get(this, 'preferences.categoricalSets').findBy('name', Ember.get(this, 'preferences.categoricalColors')).colors).range();
        } else {
          color = d3.scale.category20().domain(d3.range(20)).range();
        }
      }
      // This map excludes colors for removed clusters to retain original color for remaining clusters.
      color = Ember.get(this, 'model.report.clusters').mapBy('color');
      return color;
    }),
    changeColors: Ember.observer('records', 'current', 'pattern', function () {
      var chart = Ember.get(this, 'chart');
      if (chart) {
        var current = Ember.get(this, 'current');
        var pattern = Ember.get(this, 'pattern');
        var records = Ember.get(this, 'records');
        var colorSet = {};
        records.map(function (item, iter) {
          colorSet[Ember.get(item, 'id')] = Ember.get(item, 'id') === current || Ember.isNone(current) ? pattern.objectAt(iter) : '#ddd';
        });
        chart.data.colors(colorSet);
      }
    }),
    changeData: Ember.observer('records', function () {
      var chart = Ember.get(this, 'chart');
      if (chart) {
        var records = Ember.get(this, 'records');
        var json = {};
        var names = {};
        records.forEach(function (item) {
          json[Ember.get(item, 'id')] = Ember.get(item, 'value');
          names[Ember.get(item, 'id')] = Ember.get(item, 'label');
        });
        // NOTE:
        // unfortunatley this does not overwrite names, so the charts name data
        // will just keep growing and unfortunatley there doesn't seem to be a way
        // to clean it
        chart.data.names(names);
        chart.load({
          json: [json],
          5: {
            value: records.map(function (item) {
              return Ember.get(item, 'id');
            })
          },
          unload: true
        });
      }
    }),
    didRender: function didRender() {
      var _this = this;
      var self = this; // used in d3 :/
      var current = Ember.get(this, 'current');
      var isCompetitive = Ember.get(this, 'type') === 'competitive';
      var records = Ember.get(this, 'records');
      var pattern = Ember.get(this, 'pattern');
      var colorSet = current ? records.map(function (item, iter) {
        return Ember.get(item, 'id') === current ? pattern.objectAt(iter) : '#ddd';
      }) : pattern;
      var json = {};
      var names = {};
      records.forEach(function (item) {
        json[Ember.get(item, 'id')] = Ember.get(item, 'value');
        names[Ember.get(item, 'id')] = Ember.get(item, 'label');
      });
      Ember.run.scheduleOnce('afterRender', this, function () {
        var total = 0;
        records.forEach(function (record) {
          total += parseInt(Ember.get(record, 'value'));
        });
        var chart = c3.generate({
          size: {
            height: Ember.get(_this, 'height') || 500,
            width: Ember.get(_this, 'width') || 500
          },
          pie: {
            label: {
              format: function format(value /* , ratio, id*/) {
                return Ember.get(_this, 'showLabels') ? "".concat((value / total * 100).toFixed(1), "%") : null;
              }
            }
          },
          data: {
            bindto: '#donutbox',
            type: 'pie',
            json: [json],
            keys: {
              value: records.map(function (item) {
                return Ember.get(item, 'id');
              })
            },
            names: names,
            onclick: function onclick(d /* , element*/) {
              if (!isCompetitive) {
                Ember.get(self, 'router').transitionTo('reports.report.version.cluster.category.subcategory', Ember.get(self, 'path.currentReportId'), Ember.get(self, 'path.currentVersion'), d.id, 'overview', 'summary');
              }
            }
          },
          // data
          color: {
            pattern: colorSet
          },
          legend: isCompetitive ? {
            show: false
          } : {
            // position: 'right',
            item: {
              onclick: function onclick(id) {
                Ember.get(self, 'router').transitionTo('reports.report.version.cluster.category.subcategory', Ember.get(self, 'path.currentReportId'), Ember.get(self, 'path.currentVersion'), id, 'overview', 'summary');
              }
            }
          },
          tooltip: {
            format: {
              value: function value(x, ratio) {
                return isCompetitive && Ember.get(_this, 'preferences.normalized') === 'cluster-bias' ? "".concat((ratio * 100).toFixed(1), "%") : "".concat((x / total * 100).toFixed(1), "%");
              }
            } // format
          } // tooltip
        }); // compintelchart

        // TODO fix this
        // Define legend
        if (isCompetitive) {
          var accountObjs = records.map(function (record) {
            return {
              id: Ember.get(record, 'id'),
              score: (Ember.get(record, 'value') / total * 100).toFixed(1)
            };
          });

          // Clear the old legend, or going from tribe to tribe breaks
          _this.$('.legend').empty();
          _this.$('.legend > span').each(function () {
            var theAccount = this.$().attr('data-id');
            var target = accountObjs.findBy('id', theAccount);
            this.$().find('em').html("".concat(target.score, "% @").concat(theAccount));
          });

          // TODO: I'm appending HTML with javascript and this isn't react.
          d3.select(_this.$('.legend')).remove();
          var legend = d3.select(_this.$()[0]).insert('div', '#chart').attr('class', 'legend');
          legend.append('h6').html('Accounts:');
          Ember.get(_this, 'competitiveOrder').forEach(function (record) {
            legend.append('span').attr('data-id', record.id).html("<em>@".concat(record.label, "</em><strong></strong>"));
          }); // forEach

          // TODO use ember for legend not jquery - js
          d3.selectAll(_this.$('.legend span')).each(function () {
            var skip = false;
            var id = d3.select(this).attr('data-id');
            if (id.substr(id.length - 3) === '...') {
              // TODO
              // id = _.find(App.TheCampaign.get('cluster_ids').content.content, function(obj){
              //   return obj.get('name').indexOf(id.substr(0, id.length -3)) > -1;
              // });
              // The skip fixes for when you have a tribe name that is truncated, then rename it - JK Aug 2015
              if (id === undefined) {
                skip = true;
              }
              if (!skip) {
                id = Ember.get(id, 'name');
              }
            }
            // .selectAll('div > ul');
            if (!skip) {
              d3.select(this).select('img').style('border-color', chart.color(id));
              d3.select(this).select('em').style('border-color', chart.color(id)).style('color', chart.color(id));
            }

            // hide handles that are hidden in controller/overlap
            if (Ember.get(self, 'hidden').includes(d3.select(this)[0][0].textContent)) {
              Ember.$(this).toggleClass('toggled');
              chart.toggle(d3.select(this).attr('data-id'));
            }
          }).on('mouseover', function () {
            var id = d3.select(this).attr('data-id');
            if (id.substr(id.length - 3) === '...') {
              // TODO
              // id = _.find(App.TheCampaign.get('cluster_ids').content.content, function(obj){
              //   return obj.get('name').indexOf(id.substr(0, id.length -3)) > -1;
              // }).get('name');
            }
            Ember.$(this).addClass('hovered');
            chart.focus(id);
          }).on('mouseout', function () {
            var id = d3.select(this).attr('data-id');
            if (id.substr(id.length - 3) === '...') {
              // TODO
              // id = _.find(App.TheCampaign.get('cluster_ids').content.content, function(obj){
              //   return obj.get('name').indexOf(id.substr(0, id.length -3)) > -1;
              // }).get('name');
            }
            Ember.$(this).removeClass('hovered');
            chart.revert();
          }).on('click', function () {
            var id = d3.select(this).attr('data-id');
            var handle = d3.select(this)[0][0].textContent;
            var hideAction = Ember.get(self, 'hideAction');
            if (id.substr(id.length - 3) === '...') {
              // TODO fixme
              // id = _.find(App.TheCampaign.get('cluster_ids').content.content, function(obj){
              //   return obj.get('name').indexOf(id.substr(0, id.length -3)) > -1;
              // }).get('name');
            }
            Ember.$(this).toggleClass('toggled');
            if (hideAction) {
              hideAction(handle);
            }
            chart.toggle(id);

            // TODO fixme
            // Record use with Woopra
            var inAudience = Ember.get(self, 'path.currentPath').indexOf('audiences.audience') > -1;
            var theClusterName = Ember.get(self, 'path.inTribe') ? 'TODO tribename' : 'all';
            var theCampaign = inAudience ? 'TODO audiencename' : 'none';
            var theCampaignID = inAudience ? Ember.get(self, 'path.audienceID') : 'none';
            var onoroff = Ember.$(this).hasClass('toggled') ? 'off' : 'on';
            Ember.get(self, 'tracker').track('CompIntel Legend Toggled', {
              user: Ember.get(self, 'user.email'),
              toggledHandle: id,
              toggledTo: onoroff,
              currentCampaign: theCampaign,
              currentCampaignId: theCampaignID,
              currentCluster: theClusterName,
              currentTab: Ember.get(self, 'path.currentTab'),
              currentPath: Ember.get(self, 'path.currentPath')
            });
          });
          var width = Ember.get(_this, 'width');
          if (width && !Ember.get(_this, 'skipWidthStyle')) {
            _this.$().css('width', "".concat(width + 200, "px"));
          }
        }

        // set chart as component property
        Ember.set(_this, 'chart', chart);
      });
    }
  });
});