define("affinio/mixins/auth-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    loggedInOnly: null,
    allowedAccountTypes: null,
    redirectRoute: null,
    auth: Ember.inject.service(),
    user: Ember.inject.service(),
    localStorage: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      var _this = this;
      var auth = Ember.get(this, 'auth');
      if (window.location.search.indexOf('?JWT=') > -1) {
        Ember.get(this, 'localStorage').clearAuth();
      }
      return auth && auth.checkAuthStatus().then(function (isLoggedIn) {
        if (!isLoggedIn && Ember.get(_this, 'loggedInOnly')) {
          if (!Ember.get(_this, 'auth.loginTransition')) {
            Ember.set(_this, 'auth.loginTransition', transition);
          }
          _this.transitionTo('sign-in');
        } else if (isLoggedIn && Ember.get(_this, 'loggedOutOnly')) {
          _this.transitionTo('reports');
          // transition.abort();
        } else if (Ember.get(_this, 'redirectRoute')) {
          _this.transitionTo(Ember.get(_this, 'redirectRoute'));
        }
      }, function /* reason*/
      () {
        if (Ember.get(_this, 'loggedInOnly')) {
          if (!Ember.get(_this, 'auth.loginTransition')) {
            Ember.set(_this, 'auth.loginTransition', transition);
          }
          _this.transitionTo('sign-in');
        } else if (Ember.get(_this, 'redirectRoute')) {
          _this.transitionTo(Ember.get(_this, 'redirectRoute'));
        }
        // throw reason;
      });
    }
  });
});