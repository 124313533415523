function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
define("affinio/components/modules/interests-categories", ["exports", "affinio/utils/interests", "lodash", "affinio/utils/get-with-default", "affinio/utils/affinio"], function (_exports, _interests, _lodash, _getWithDefault, _affinio) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
  function removeFullWidthClass() {
    var _document$getElements = document.getElementsByClassName('affinio-module-wrapper'),
      _document$getElements2 = _slicedToArray(_document$getElements, 1),
      moduleGrid = _document$getElements2[0];
    if (moduleGrid !== undefined) {
      moduleGrid.classList.remove('full-width');
    }
  }
  var _default = _exports.default = Ember.Component.extend({
    // Element
    classNames: ['interests-categories'],
    tagName: 'div',
    firstClusterRender: true,
    lastCluster: null,
    preferences: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
    },
    init: function init() {
      this._super.apply(this, arguments);
      Ember.set(this, 'isInterestsTags', true);
      removeFullWidthClass();
    },
    // convert metric.response.tags to lowercase tags - ["tag1", "tag2", "tag1"]
    lowercaseTags: Ember.computed('tags', 'controller.instanceSearchInput', function () {
      var tags = Ember.get(this, 'tags');
      if (tags !== undefined) {
        var flattenTags = tags.flatten().compact();
        var lowercaseTags = flattenTags.map(function (item) {
          return item.toLowerCase();
        });
        return lowercaseTags;
      }
    }),
    // generate counts for each tags: e.g.: {'brand' : 765, 'media': 322}
    tagCounts: Ember.computed('lowercaseTags', function () {
      var tagCounts = _lodash.default.countBy(this.lowercaseTags);
      return tagCounts;
    }),
    uniqTags: Ember.computed('lowercaseTags', function () {
      var uniqTags = _lodash.default.uniq(this.lowercaseTags);
      return uniqTags;
    }),
    items: Ember.computed('uniqTags', 'controller.instanceSearchInput', function () {
      var _this = this;
      var uniqTags = Ember.get(this, 'uniqTags');
      var tagCounts = Ember.get(this, 'tagCounts');
      var filter = Ember.get(this, 'controller.instanceSearchInput');
      var items = _interests.tagHierarchyMap.map(function (hierarchy) {
        return _this.hierarchyMapCount(hierarchy, tagCounts, uniqTags, filter);
      }).compact();
      return items;
    }),
    sortedItemsForWordCloud: Ember.computed('items', function () {
      var items = Ember.get(this, 'items');
      var arr = [];
      items.forEach(function (item) {
        if (item.value !== 'uncategorized') {
          // exclude "uncategorized"
          item.children.forEach(function (child) {
            var newObj = {};
            var label = child.label,
              count = child.count;
            newObj.label = label;
            newObj.count = count;
            arr.push(newObj);
          });
        }
      });
      var sortedItems = arr.sort(function (a, b) {
        return b.count - a.count;
      });
      return sortedItems;
    }),
    sortedItems: Ember.computed('items', function () {
      var items = Ember.get(this, 'items');
      var arr = [];
      items.forEach(function (item) {
        if (item.value !== 'uncategorized') {
          item.children.forEach(function (child) {
            if (!arr.find(function (_ref) {
              var value = _ref.value,
                label = _ref.label;
              return child.value === value || child.label === label;
            })) {
              arr.push(child);
            }
          });
        }
      });
      return arr.sort(function (a, b) {
        return b.count - a.count;
      }).slice(0, 20);
    }),
    max: Ember.computed('sortedItems', function () {
      return Ember.get(this, 'sortedItems')[0].count;
    }),
    childTagCountsByCluster: Ember.computed('model.allMetricsToLoad', 'sortedItems', function () {
      var arrayToReturn = [];
      var sortedItems = Ember.get(this, 'sortedItems');
      var allItems = Ember.get(this, 'model.allMetricsToLoad');
      if (allItems !== undefined) {
        allItems.forEach(function (item) {
          var anotherTempArray = [];
          sortedItems.forEach(function (tagItem) {
            var tempArray = [];
            var tag = tagItem.value.toLowerCase();
            item.response.filter(function (interest) {
              var tags = (0, _getWithDefault.default)(interest, 'tags', []).map(function (m) {
                return m.toLowerCase();
              });
              var intersectedTags = _lodash.default.intersection(tags, [tag]);
              if (intersectedTags.length > 0) {
                tempArray.push(interest);
                return interest;
              }
            });
            anotherTempArray.push(_defineProperty({}, tagItem.value, tempArray.length));
          });
          arrayToReturn.push(_defineProperty({}, item.cluster.suffix, anotherTempArray));
        });
        arrayToReturn.shift();
        return arrayToReturn;
      }
    }),
    totalCountsAdded: Ember.computed('sortedItems', 'childTagCountsByCluster', function () {
      var objToReturn = {};
      var sortedItems = Ember.get(this, 'sortedItems');
      var childTagCountsByCluster = Ember.get(this, 'childTagCountsByCluster');
      if (childTagCountsByCluster !== undefined) {
        sortedItems.forEach(function (tag) {
          var count = 0;
          childTagCountsByCluster.forEach(function (obj) {
            var _Object$values$0$filt = Object.values(obj)[0].filter(function (item) {
                return item[tag.value];
              }),
              _Object$values$0$filt2 = _slicedToArray(_Object$values$0$filt, 1),
              tagValue = _Object$values$0$filt2[0];
            var tagCount = tagValue ? tagValue[tag.value] : 0;
            count += tagCount;
          });
          objToReturn[tag.value] = count;
        });
        return objToReturn;
      }
    }),
    tagCountsObject: Ember.computed('childTagCountsByCluster', 'sortedItems', 'totalCountsAdded', function () {
      var _this2 = this;
      var childTagCountsByCluster = Ember.get(this, 'childTagCountsByCluster');
      var sortedItems = Ember.get(this, 'sortedItems');
      var totalCountsAdded = Ember.get(this, 'totalCountsAdded');
      var tagObjectsAll = {};
      if (childTagCountsByCluster !== undefined && sortedItems !== undefined && totalCountsAdded !== undefined) {
        sortedItems.forEach(function (tagObj, index) {
          var array = [];
          var tagToAdd = tagObj.value.toLowerCase();
          childTagCountsByCluster.forEach(function (item, clusterIndex) {
            var clusterNumberOfMembersArray = Ember.get(_this2, 'metric.report.clusterNumberOfMembers').map(function (item) {
              return item.response.value;
            });
            var totalNumberOfMembers = clusterNumberOfMembersArray.reduce(function (a, c) {
              return a + c;
            }, 0);
            var thisValue = Object.values(Object.values(item)[0][index])[0] !== undefined ? Object.values(Object.values(item)[0][index])[0] : 0;
            var thisTimesMoreLikely = _this2.calculateTimesMoreLikely(tagToAdd, clusterIndex, thisValue, clusterNumberOfMembersArray, totalNumberOfMembers, totalCountsAdded);
            thisTimesMoreLikely = !Number.isFinite(thisTimesMoreLikely) ? 'Infinity' : thisTimesMoreLikely;
            var isInfinite = thisTimesMoreLikely === 'Infinity';
            var object = {
              cluster: Object.keys(item)[0],
              value: thisValue,
              normalizedScore: thisValue,
              timesMoreLikely: {
                value: thisTimesMoreLikely,
                isInfinite: isInfinite
              }
            };
            array.push(object);
          });
          tagObjectsAll[tagToAdd] = array;
        });
        return tagObjectsAll;
      }
    }),
    normalized: Ember.computed('controller.normalized', function () {
      var normalized = Ember.get(this, 'controller.normalized');
      if (normalized !== undefined) {
        return Ember.get(this, 'controller.normalized');
      }
    }),
    normalizedWatcher: Ember.observer('controller.normalized', 'metric.cluster.isCluster', function () {
      var _normalized = 'cluster-bias';
      var normalized = Ember.get(this, 'controller.normalized');
      if (!Ember.get(this, 'metric.cluster.isCluster') && normalized === 'niche-ranked') {
        Ember.set(this, 'controller.normalized', _normalized);
      }
    }),
    // ------- niche-ranked
    currentClusterSuffix: Ember.computed.alias('metric.cluster.suffix'),
    handleNicheRanked: Ember.observer('controller.normalized', function () {
      var _this3 = this;
      (0, _affinio.maybeRunLater)(this, function () {
        var heldCluster = Ember.get(_this3, 'preferences.heldCluster');
        var heldDelta = Ember.get(_this3, 'preferences.heldDelta');
        if (Ember.get(_this3, 'lastCluster') !== Ember.get(_this3, 'currentClusterSuffix')) {
          Ember.set(_this3, 'firstClusterRender', true);
        }
        if (Ember.get(_this3, 'controller.normalized') === 'niche-ranked') {
          if (!heldCluster) {
            Ember.set(_this3, 'nicheRankSorted', true);
            _this3.send('nicheRank', Ember.get(_this3, 'metric.cluster.suffix'));
          }
        } else if (heldCluster !== null) {
          _this3.send('nicheRank', heldCluster);
        } else if (heldDelta) {
          _this3.send('nicheRank', 'delta');
        } else {
          if (!heldCluster) {
            _this3.send('unNiche');
          }
          if (Ember.get(_this3, 'firstClusterRender') && Ember.get(_this3, 'controller.normalized')) {
            Ember.set(_this3, 'controller.normalized', 'niche-ranked');
            Ember.set(_this3, 'firstClusterRender', false);
          }
        } // if niche-ranked

        Ember.set(_this3, 'lastCluster', Ember.get(_this3, 'currentClusterSuffix'));
      });
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      var _this4 = this;
      Ember.run.scheduleOnce('afterRender', function () {
        return _this4.handleNicheRanked();
      });
    },
    nicheLoadObserver: Ember.observer('instances.[]', 'tagCountsObject', function () {
      var _this5 = this;
      if (Ember.get(this, 'instances.length') && Ember.get(this, 'tagCountsObject')) {
        Ember.run.scheduleOnce('afterRender', function () {
          return _this5.handleNicheRanked();
        });
      }
    }),
    actions: {
      nicheRank: function nicheRank(cluster) {
        var _this6 = this;
        if (Ember.get(this, '_state') !== 'inDOM') {
          return;
        }
        var offsetProperty = 'data-normalized-offset';

        // Move the row of records-blocks leftwards so that the cluster is flush left
        this.$('.cluster-stats').each(function (iter, clusterStats) {
          clusterStats = Ember.$(clusterStats);
          var nicheRecord = clusterStats.children(".record-block[data-cluster=\"".concat(cluster, "\"]"));
          var offset = nicheRecord.attr(offsetProperty);
          clusterStats.children('.record-block').css('opacity', 0);
          nicheRecord.css('opacity', 1);
          clusterStats.css({
            'transform': "translateX(-".concat(offset, "%)")
          });
        });
        var barHeight = 29; // height 28 + bottom margin 1

        this.$().map(function (_, chart) {
          var sorted = _this6.$(chart).find(".record-block[data-cluster=\"".concat(cluster, "\"]")).toArray().sort(function (a, b) {
            var aSize = parseFloat(_this6.$(a).attr('data-normalized-percentage'));
            var bSize = parseFloat(_this6.$(b).attr('data-normalized-percentage'));
            return aSize > bSize ? -1 : aSize < bSize ? 1 : 0;
          });
          var rest = [];

          // Move the bars
          [].concat(_toConsumableArray(sorted), rest).forEach(function (obj, iter) {
            var graphedBar = _this6.$(obj).hasClass('metric-instance') ? _this6.$(obj) : _this6.$(obj).parents('.metric-instance');
            var originalSpot = _this6.$(chart).find('.metric-instance').index(graphedBar);
            var topPosition = barHeight * iter - barHeight * originalSpot;
            graphedBar.css({
              'transform': "translateY(".concat(topPosition, "px)")
            }).attr('which', iter);
          }); // sorted forEach
        }); // map charts
      },
      // nicheRank
      unNiche: function unNiche() {
        if (this.$('.cluster-stats')) {
          this.$('.cluster-stats').css({
            'transform': 'translateX(0%)'
          }, 200);
          this.$('.cluster-stats').children('span').css({
            'opacity': 1
          });
          this.$('.metric-instance').css({
            'transform': 'translateY(0px)'
          });
          Ember.set(this, 'nicheRankSorted', false);
        }
      } // unNiche
    },
    calculateTimesMoreLikely: function calculateTimesMoreLikely(tag, clusterIndex, thisValue, clusterNumberOfMembersArray, totalNumberOfMembers, totalCountsAdded) {
      var thisClusterNumberOfMembers = clusterNumberOfMembersArray[clusterIndex];
      var thisLikely = thisValue / thisClusterNumberOfMembers;
      var otherLikely = (totalCountsAdded[tag] - thisValue) / (totalNumberOfMembers - thisClusterNumberOfMembers);
      var timesMoreLikely = thisLikely / otherLikely;
      return timesMoreLikely;
    },
    hierarchyMapCount: function hierarchyMapCount(rawHierarchy, tagCounts, uniqTags, filter) {
      var rawTag = rawHierarchy.value;
      if (uniqTags.includes(rawTag)) {
        var item = {
          value: rawHierarchy.value,
          label: rawHierarchy.label,
          count: tagCounts[rawTag],
          // count number of a hierarchy e.g.:{count: 765}
          isParent: true
        };
        // if the hierachy has children, keep attaching children with counts to the parent
        if (rawHierarchy.children) {
          item.children = rawHierarchy.children.map(function (hierarchy) {
            // if the tag is available in uniqTags
            if (uniqTags.includes(hierarchy.value) && (!filter || hierarchy.value.toLowerCase().includes(filter.toLowerCase()))) {
              var childItem = {
                value: hierarchy.value,
                label: hierarchy.label,
                count: tagCounts[hierarchy.value],
                parent: item
              };
              return childItem;
            }
          }).compact();
        }
        return item;
      } else {
        return null;
      }
    }
  });
});