define("affinio/components/histo-gram", ["exports", "d3v5", "affinio/utils/get-with-default"], function (_exports, _d3v, _getWithDefault) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
  var height = 100;
  var _default = _exports.default = Ember.Component.extend({
    // Element
    classNames: ['histo-gram'],
    tagName: 'div',
    yScale: Ember.computed('data', function () {
      var clusters = Ember.get(this, 'data');

      // stacked bar heights
      var barOffsets = {};
      clusters.forEach(function (cluster) {
        var bins = Ember.get(cluster, 'valueBins');
        bins.forEach(function (bin) {
          bin.offset = (0, _getWithDefault.default)(barOffsets, bin.label, 0);
          barOffsets[bin.label] = bin.offset > 0 ? bin.value + barOffsets[bin.label] : bin.value;
        });
      });
      var yDomain = 0;
      clusters.forEach(function (cluster) {
        var bins = Ember.get(cluster, 'valueBins');
        var max = _d3v.default.max(bins, function (d) {
          return d.value + d.offset;
        });
        yDomain = max > yDomain ? max : yDomain;
      });
      return _d3v.default.scaleLinear().domain([0, yDomain]).range([height, 0]);
    }),
    xScale: Ember.computed('labels', function () {
      var labels = Ember.get(this, 'labels');
      var margin = {
        top: 0,
        right: 3,
        bottom: 0,
        left: 3
      };
      if (labels.length <= 1) {
        margin.left = 40;
        margin.right = 40;
      } else if (labels.length <= 6) {
        margin.left = 35 - labels.length * 5;
        margin.right = 35 - labels.length * 5;
      }
      return _d3v.default.scaleBand().domain(labels).range([margin.left, 100 - margin.right]).padding(.2);
    }),
    axis: Ember.computed('xScale', function () {
      var labels = Ember.get(this, 'labels');
      var x = Ember.get(this, 'xScale');
      var xAxis = function xAxis(g) {
        return g.call(_d3v.default.axisBottom(x).tickSizeInner(2).tickValues(labels));
      };
      var svg = _d3v.default.select(this.element).append('svg:svg').attr('preserveAspectRatio', 'none').attr('viewBox', '0 0 100 40').classed('axis', true);
      var axis = svg.append('g').attr('class', 'x-axis').call(xAxis);
      axis.selectAll('.tick')._groups[0].forEach(function (t) {
        var tick = _d3v.default.select(t);
        var format = _d3v.default.format(',.0f');
        var value = format(tick.data());
        tick.insert('title').html(value);
        if (value.length > 5) {
          var truncatedValue = "".concat(value.substring(0, 4), "...");
          tick.select('text').html(truncatedValue);
        }
      });
      return svg.node();
    }),
    graph: Ember.computed('data', function () {
      var format = _d3v.default.format(',.0f');
      var clusters = Ember.get(this, 'data');
      var x = Ember.get(this, 'xScale');
      var y = Ember.get(this, 'yScale');
      var svg = _d3v.default.select(this.element).append('svg:svg').attr('height', height * 2).attr('preserveAspectRatio', 'none').attr('viewBox', '0 0 100 200').attr('class', 'chart');
      clusters.forEach(function (cluster) {
        var clusterID = Ember.get(cluster, 'clusterID');
        var color = Ember.get(cluster, 'color');
        var data = Ember.get(cluster, 'valueBins');
        svg.selectAll("rect-".concat(clusterID)).data(data).enter().append('rect').attr('class', "rect-".concat(clusterID)).attr('fill', color).attr('transform', function (d) {
          return "translate(".concat(x(d.label), ",").concat(height + y(d.value + d.offset), ")");
        }).attr('height', function (d) {
          return height - y(d.value);
        }).attr('width', function () {
          return x.bandwidth();
        }).insert('title').html(function (d) {
          return format(d.value);
        });
      });
      return svg.node();
    }),
    animateGraph: function animateGraph(reset) {
      var clusters = Ember.get(this, 'data');
      if (clusters.length !== 2) {
        return;
      }
      var _clusters = _slicedToArray(clusters, 2),
        cluster1 = _clusters[0],
        cluster2 = _clusters[1];
      var x = Ember.get(this, 'xScale');
      var y = Ember.get(this, 'yScale');
      var svg = _d3v.default.select(this.element).select('svg');
      var bins1 = Ember.get(cluster1, 'valueBins');
      var clusterID1 = Ember.get(cluster1, 'clusterID');
      var bins2 = Ember.get(cluster2, 'valueBins');
      var clusterID2 = Ember.get(cluster2, 'clusterID');
      if (reset) {
        svg.selectAll(".rect-".concat(clusterID1)).data(bins1).transition('ease').duration(750).attr('transform', function (d) {
          return "translate(".concat(x(d.label), ",").concat(height + y(d.value + d.offset), ")");
        });
        svg.selectAll(".rect-".concat(clusterID2)).data(bins2).transition('ease').duration(750).attr('transform', function (d) {
          return "translate(".concat(x(d.label), ",").concat(height + y(d.value + d.offset), ")");
        });
      } else {
        var tempHeight = _d3v.default.max(Ember.get(cluster1, 'valueBins'), function (d) {
          return d.value;
        });
        svg.selectAll(".rect-".concat(clusterID1)).data(bins1).transition('ease').duration(750).attr('transform', function (d) {
          return "translate(".concat(x(d.label), ",").concat(height + y(tempHeight), ")");
        });
        svg.selectAll(".rect-".concat(clusterID2)).data(bins2).transition('ease').duration(750).attr('transform', function (d) {
          return "translate(".concat(x(d.label), ",").concat(height + y(d.value + tempHeight), ")");
        });
      }
    },
    // Lifecycle
    mouseDown: function mouseDown() {
      this.animateGraph(false);
    },
    mouseUp: function mouseUp() {
      this.animateGraph(true);
    }
  });
});