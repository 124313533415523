define("affinio/components/old/filter-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Element
    // classNames: ['filter-table'],
    tagName: 'table',
    // Services
    tracker: Ember.inject.service(),
    // Properties
    folded: true,
    // Computed
    isAutoSuggest: Ember.computed.equal('model.filterTableType', 'autosuggest'),
    isFreeForm: Ember.computed.equal('model.filterTableType', 'freeform'),
    isStandard: Ember.computed.equal('model.filterTableType', 'standard'),
    isSingle: Ember.computed.alias('model.single'),
    canSearch: Ember.computed.or('isAutoSuggest', 'model.searchable', 'isFreeForm'),
    filteredOptions: Ember.computed('model.input', function () {
      var filter = Ember.get(this, 'model.input');
      var isFreeForm = Ember.get(this, 'isFreeForm');
      var dontFilterOptions = Ember.get(this, 'model.dontFilterOptions');
      var model = Ember.get(this, 'model');
      if (filter && typeof filter === 'string') {
        filter = filter.toLowerCase();
      }
      return filter && !isFreeForm && !dontFilterOptions ? model.options.filter(function (n) {
        return n.label.toLowerCase().indexOf(filter) > -1;
      }) : model.options;
    }),
    updatedEndpoint: Ember.computed('model.{input,endpoint}', function () {
      return Ember.get(this, 'model.endpoint')(Ember.get(this, 'model.input'));
    }),
    // Actions passed in
    // toggleAllMetricOptions: () => {
    //   console.log('toggleAllMetricOptions action wasnt passed in to filter-table');
    // },
    // toggleDemographicInclude: () => {
    //   console.log('toggleDemographicInclude action wasnt passed in to filter-table');
    // },

    actions: {
      fold: function fold() {
        this.toggleProperty('folded');
        Ember.get(this, 'tracker').track('section folded', {
          section: Ember.get(this, 'model.key')
        });
      },
      clearInput: function clearInput() {
        Ember.set(this, 'model.input', '');
      },
      submitAddAction: function submitAddAction(item) {
        Ember.get(this, 'model.addAction')(item, Ember.get(this, 'model.key'));
      }
    }
  });
});