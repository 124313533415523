define("affinio/templates/components/modals/modal-edit-report-nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "IqlbslVG",
    "block": "{\"symbols\":[],\"statements\":[[7,\"form\"],[11,\"class\",\"modal modal-edit-report-nav\"],[9],[0,\"\\n  \"],[7,\"a\"],[11,\"class\",\"close-modal\"],[11,\"href\",\"#\"],[9],[0,\"×\"],[3,\"action\",[[22,0,[]],\"close\"]],[10],[0,\"\\n  \"],[7,\"h3\"],[11,\"class\",\"modal-sub-nav-header\"],[9],[0,\"\\n    Report Navigation Editor\\n  \"],[10],[0,\"\\n  \"],[1,[27,\"report-nav-editor\",null,[[\"model\"],[[23,[\"model\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"backdrop\"],[9],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/modals/modal-edit-report-nav.hbs"
    }
  });
});