define("affinio/components/site-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Element
    classNames: ['embedded-media', 'media-frame'],
    classNameBindings: ['hovered'],
    // attributeBindings: ['style'],
    // modifiedTitle: computed('record', function(){
    //   let title = this.get('record.parsed_media.title') ? this.get('record.parsed_media.title') : this.get('record.parsed_media.source_url');
    //   return title && title.length > 70 ? title.slice(0,67)+"..." : title;
    // }), //modifiedTitle

    url: Ember.computed.alias('instance.label'),
    title: Ember.computed.or('instance.title', 'instance.parsedMedia.title', 'instance.label'),
    image: Ember.computed.or('instance.image', 'instance.parsedMedia.image'),
    description: Ember.computed.or('instance.description', 'instance.parsedMedia.description')
  });
});