define("affinio/models/company", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  var attr = _emberData.default.attr;
  var hasMany = _emberData.default.hasMany;
  var Model = _emberData.default.Model;
  var _default = _exports.default = Model.extend({
    sfid: attr('string'),
    name: attr('string'),
    notes: attr('string'),
    groups: hasMany('group'),
    twitterReports: hasMany('reports/twitter'),
    pinterestReports: hasMany('reports/pinterest'),
    instagramReports: hasMany('reports/instagram'),
    customReports: hasMany('reports/custom'),
    reports: Ember.computed('twitterReports.[]', 'pinterestReports.[]', 'instagramReports.[]', 'customReports.[]', function () {
      return [].concat(_toConsumableArray(Ember.get(this, 'twitterReports').toArray()), _toConsumableArray(Ember.get(this, 'pinterestReports').toArray()), _toConsumableArray(Ember.get(this, 'instagramReports').toArray()), _toConsumableArray(Ember.get(this, 'customReports').toArray())).filter(function (r) {
        return Ember.get(r, 'state') !== 'failed';
      });
    }),
    accounts: hasMany('account'),
    features: attr(),
    isActive: attr('boolean'),
    seatLimit: attr('number'),
    viewerSeatLimit: attr('number'),
    trialInfo: attr(),
    numberOfAccounts: attr('number'),
    folders: hasMany('folder'),
    assetGroups: hasMany('asset-group'),
    credits: attr('number'),
    businessModelType: attr('string'),
    verticalType: attr('string'),
    accountsWithCreateReport: Ember.computed.filterBy('accounts', 'hasCreateReport', true),
    accountsWithCreateDuplicateReport: Ember.computed.filterBy('accounts', 'hasCreateDuplicateReport', true),
    accountsWithCreateMonitoredReport: Ember.computed.filterBy('accounts', 'hasCreateMonitoredReport', true),
    accountsWithTwitterAdvertising: Ember.computed.filterBy('accounts', 'hasTwitterAdvertising', true),
    accountsWithAffinioAPIAccess: Ember.computed.filterBy('accounts', 'hasAffinioAPIAccess', true),
    accountsWithTranslation: Ember.computed.filterBy('accounts', 'hasTranslation', true),
    hasTwitterAdvertising: Ember.computed('account.@each.permissions', 'accounts.[]', 'accountsWithTwitterAdvertising', function () {
      return Ember.get(this, 'accountsWithTwitterAdvertising.length') >= 1;
    }),
    TRIAL_EXPIRED_MESSAGE: 'This account is inactive because of an expired trial. Remove or update the trial and save if you want to reactivate.',
    /**
    * the logic is on the backend to disallow users with expired trials to login
    * here we are returning false when the trial date is past due
    * this is also done for each user under the account
    * by the this.send('updateCompanyWithAccounts', true); action
    *
    * early return is in place if there is no trial
    */
    trialActive: Ember.computed('trialInfo.endDate', function () {
      if (!Ember.get(this, 'trialInfo')) {
        return true;
      }
      var endDate = (0, _moment.default)(Ember.get(this, 'trialInfo.endDate'));
      return (0, _moment.default)(endDate).diff((0, _moment.default)()) > 0;
    })
  });
});