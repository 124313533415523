define("affinio/templates/components/sort-triangle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "KPEMYwag",
    "block": "{\"symbols\":[\"@ascending\"],\"statements\":[[1,[27,\"font-awesome\",[[27,\"if\",[[22,1,[]],\"caret-up\",\"caret-down\"],null]],null],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/sort-triangle.hbs"
    }
  });
});