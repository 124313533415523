define("affinio/templates/components/modules/cluster-overlap", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "zI0VvZ+0",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1],[0,\"\\n\\n\"],[5,\"correlation-matrix\",[],[[\"@axis\",\"@data\",\"@average\",\"@max\",\"@selectedAxisIndex\",\"@shouldHideLastColumn\"],[[27,\"map-by\",[\"name\",[23,[\"model\",\"report\",\"clusters\"]]],null],[23,[\"model\",\"report\",\"clusterOverlap\",\"data\"]],[23,[\"model\",\"report\",\"clusterOverlap\",\"average\"]],[23,[\"model\",\"report\",\"clusterOverlap\",\"max\"]],[27,\"if\",[[23,[\"model\",\"cluster\",\"isCluster\"]],[23,[\"model\",\"cluster\",\"index\"]]],null],false]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/modules/cluster-overlap.hbs"
    }
  });
});