define("affinio/templates/components/monitor/new-audience-summary-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "En261I5g",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\"],[11,\"class\",\"remove\"],[11,\"role\",\"button\"],[9],[0,\"×\"],[3,\"action\",[[22,0,[]],[23,[\"removeAction\"]],[23,[\"title\"]]]],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"img\"]]],null,{\"statements\":[[0,\"  \"],[7,\"img\"],[11,\"class\",\"avatar\"],[11,\"alt\",\"image\"],[12,\"src\",[21,\"img\"]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"isMini\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\"],[9],[1,[21,\"title\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"span\"],[9],[1,[21,\"title\"],false],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[23,[\"subtitle\"]]],null,{\"statements\":[[0,\"  \"],[7,\"p\"],[9],[1,[21,\"subtitle\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/monitor/new-audience-summary-card.hbs"
    }
  });
});