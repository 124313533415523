define("affinio/utils/auto-suggest", ["exports", "moment", "affinio/utils/queries", "affinio/utils/get-with-default"], function (_exports, _moment, _queries, _getWithDefault) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.entitySearchEndpoint = entitySearchEndpoint;
  _exports.timezoneEndpoint = timezoneEndpoint;
  _exports.toolboxAccountSearchByNameOrIdEndpoint = toolboxAccountSearchByNameOrIdEndpoint;
  _exports.toolboxAvailabilityEndpoint = toolboxAvailabilityEndpoint;
  _exports.toolboxCompanySearchByNameOrIdEndpoint = toolboxCompanySearchByNameOrIdEndpoint;
  _exports.toolboxContainerTypeEndpoint = toolboxContainerTypeEndpoint;
  _exports.toolboxFolderSearchByNameOrIdEndpoint = toolboxFolderSearchByNameOrIdEndpoint;
  _exports.toolboxRawInputEndpoint = toolboxRawInputEndpoint;
  _exports.toolboxReportSearchByNameOrIdEndpoint = toolboxReportSearchByNameOrIdEndpoint;
  _exports.toolboxSearchMonitoredReportsByNameOrId = toolboxSearchMonitoredReportsByNameOrId;
  _exports.toolboxTwitterHandlesEndpoint = toolboxTwitterHandlesEndpoint;
  _exports.twitterHandlesEndpoint = twitterHandlesEndpoint;
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function timezoneEndpoint(_ref) {
    var inputKey = _ref.inputKey;
    return Ember.computed(function () {
      var _this = this;
      return {
        placeholder: 'America/Los Angeles, Japan ...',
        request: function request() {
          return new Ember.RSVP.Promise(function (resolve) {
            return resolve(_moment.default.tz.names().filter(function (tz) {
              return tz.match(new RegExp(Ember.get(_this, inputKey).replace(' ', '_'), 'gi'));
            }).map(function (tz) {
              return {
                label: tz,
                buttonNomenclature: 'Select'
              };
            }));
          });
        }
      };
    });
  }

  // Requirements: graph service
  function twitterHandlesEndpoint(_ref2) {
    var inputKey = _ref2.inputKey;
    return Ember.computed(function () {
      var _this2 = this;
      return {
        placeholder: '@Nike',
        request: function request() {
          return new Ember.RSVP.Promise(function (resolve) {
            return Ember.get(_this2, 'graph').query(_queries.default.twitterUserSearch((0, _getWithDefault.default)(_this2, inputKey, '').replace('@', '')), true).then(function (response) {
              var returnValue;
              // Check for a direct match
              var match = response.data.twitterUserSearch.filter(function (n) {
                return n.screenName.toLowerCase() === (0, _getWithDefault.default)(_this2, inputKey, '').replace('@', '').toLowerCase();
              });
              if (match.length) {
                returnValue = [{
                  image: Ember.get(match[0], 'profileImage'),
                  label: Ember.get(match[0], 'screenName'),
                  value: Ember.get(match[0], 'followersCount'),
                  valueNomenclature: 'followers'
                }].concat(_toConsumableArray(response.data.twitterUserSearch.rejectBy('screenName', match[0].screenName).map(function (n) {
                  return {
                    image: Ember.get(n, 'profileImage'),
                    label: Ember.get(n, 'screenName'),
                    value: Ember.get(n, 'followersCount'),
                    valueNomenclature: 'followers'
                  };
                }).sortBy('value').reverse()));
              } else {
                returnValue = response.data.twitterUserSearch.map(function (n) {
                  return {
                    image: Ember.get(n, 'profileImage'),
                    label: Ember.get(n, 'screenName'),
                    value: Ember.get(n, 'followersCount'),
                    valueNomenclature: 'followers'
                  };
                }).sortBy('value').reverse();
              }
              resolve(returnValue);
            }).catch(function () {
              return resolve([]);
            });
          });
        }
      };
    });
  }

  // Requirements: graph service
  function toolboxReportSearchByNameOrIdEndpoint(_ref3) {
    var inputKey = _ref3.inputKey;
    return Ember.computed(function () {
      var _this3 = this;
      return {
        placeholder: 'Search Reports by Name or Id',
        request: function request() {
          return new Ember.RSVP.Promise(function (resolve) {
            return Ember.get(_this3, 'graph').query(_queries.default.reportSearchByNameOrId((0, _getWithDefault.default)(_this3, inputKey, '')), true).then(function (response) {
              var returnValue;
              returnValue = response.data.toolboxSearchReportsByNameOrId.map(function (n) {
                return {
                  label: Ember.get(n, 'name'),
                  value: Ember.get(n, 'id'),
                  owner: Ember.get(n, 'creator.email')
                };
              });
              resolve(returnValue);
            }).catch(function () {
              return resolve([]);
            });
          });
        }
      };
    });
  }

  // Requirements: graph service
  function toolboxSearchMonitoredReportsByNameOrId(_ref4) {
    var inputKey = _ref4.inputKey;
    return Ember.computed(function () {
      var _this4 = this;
      return {
        placeholder: 'Search Monitored Reports by Name or Id',
        request: function request() {
          return new Ember.RSVP.Promise(function (resolve) {
            return Ember.get(_this4, 'graph').query(_queries.default.monitoredReportSearchByNameOrId((0, _getWithDefault.default)(_this4, inputKey, '')), true).then(function (response) {
              var returnValue;
              returnValue = response.data.toolboxSearchMonitoredReportsByNameOrId.map(function (n) {
                return {
                  label: Ember.get(n, 'name'),
                  value: Ember.get(n, 'id'),
                  owner: Ember.get(n, 'creator.email')
                };
              });
              resolve(returnValue);
            }).catch(function () {
              return resolve([]);
            });
          });
        }
      };
    });
  }
  function toolboxAccountSearchByNameOrIdEndpoint(_ref5) {
    var inputKey = _ref5.inputKey;
    return Ember.computed(function () {
      var _this5 = this;
      return {
        placeholder: 'Search Accounts by Name, Email, or Id',
        request: function request() {
          return new Ember.RSVP.Promise(function (resolve) {
            return Ember.get(_this5, 'graph').query(_queries.default.accountSearchByNameOrId((0, _getWithDefault.default)(_this5, inputKey, '')), true).then(function (response) {
              var returnValue;
              returnValue = response.data.toolboxSearchAccountsByNameOrEmailOrId.map(function (n) {
                return {
                  label: "".concat(Ember.get(n, 'firstName'), " ").concat(Ember.get(n, 'lastName'), " - ").concat(Ember.get(n, 'email')),
                  value: Ember.get(n, 'id')
                };
              });
              resolve(returnValue);
            }).catch(function () {
              return resolve([]);
            });
          });
        }
      };
    });
  }
  function toolboxCompanySearchByNameOrIdEndpoint(_ref6) {
    var inputKey = _ref6.inputKey;
    return Ember.computed(function () {
      var _this6 = this;
      return {
        placeholder: 'Search Company By Name or Id',
        request: function request() {
          return new Ember.RSVP.Promise(function (resolve) {
            return Ember.get(_this6, 'graph').query(_queries.default.companySearchByNameOrId((0, _getWithDefault.default)(_this6, inputKey, '')), true).then(function (response) {
              var returnValue;
              returnValue = response.data.toolboxSearchCompaniesByNameOrId.map(function (n) {
                return {
                  label: Ember.get(n, 'name'),
                  value: Ember.get(n, 'id')
                };
              });
              resolve(returnValue);
            }).catch(function () {
              return resolve([]);
            });
          });
        }
      };
    });
  }
  function toolboxFolderSearchByNameOrIdEndpoint(_ref7) {
    var inputKey = _ref7.inputKey;
    return Ember.computed(function () {
      var _this7 = this;
      return {
        placeholder: 'Search Folder by Name or Id',
        request: function request() {
          return new Ember.RSVP.Promise(function (resolve) {
            return Ember.get(_this7, 'graph').query(_queries.default.folderSearchByNameOrId((0, _getWithDefault.default)(_this7, inputKey, '')), true).then(function (response) {
              var returnValue;
              returnValue = response.data.toolboxSearchFoldersByNameOrId.map(function (n) {
                return {
                  label: Ember.get(n, 'name'),
                  value: Ember.get(n, 'id'),
                  owner: Ember.get(n, 'owner.email')
                };
              });
              resolve(returnValue);
            }).catch(function () {
              return resolve([]);
            });
          });
        }
      };
    });
  }
  function toolboxAvailabilityEndpoint() {
    return Ember.computed(function () {
      return {
        placeholder: 'Report Availability',
        request: function request() {
          return Ember.RSVP.Promise.resolve([{
            value: 'Default',
            label: 'Standard edit/view privileges'
          }, {
            value: 'General',
            label: 'Cross-company access for everyone'
          }]);
        }
      };
    });
  }

  // basically the same except it uses screenName as value
  function toolboxTwitterHandlesEndpoint(_ref8) {
    var inputKey = _ref8.inputKey;
    return Ember.computed(function () {
      var _this8 = this;
      return {
        placeholder: '@Nike',
        request: function request() {
          return new Ember.RSVP.Promise(function (resolve) {
            return Ember.get(_this8, 'graph').query(_queries.default.twitterUserSearch((0, _getWithDefault.default)(_this8, inputKey, '').replace('@', '')), true).then(function (response) {
              var returnValue;
              // Check for a direct match
              var match = response.data.twitterUserSearch.filter(function (n) {
                return n.screenName.toLowerCase() === (0, _getWithDefault.default)(_this8, inputKey, '').replace('@', '').toLowerCase();
              });
              if (match.length) {
                returnValue = [{
                  value: Ember.get(match[0], 'screenName')
                }].concat(_toConsumableArray(response.data.twitterUserSearch.rejectBy('screenName', match[0].screenName).map(function (n) {
                  return {
                    value: Ember.get(n, 'screenName')
                  };
                }).sortBy('value').reverse()));
              } else {
                returnValue = response.data.twitterUserSearch.map(function (n) {
                  return {
                    value: Ember.get(n, 'screenName')
                  };
                }).sortBy('value').reverse();
              }
              resolve(returnValue);
            }).catch(function () {
              return resolve([]);
            });
          });
        }
      };
    });
  }
  function toolboxContainerTypeEndpoint() {
    return Ember.computed(function () {
      return {
        placeholder: 'Container Type',
        request: function request() {
          return Ember.RSVP.Promise.resolve([{
            value: 'Snowflake',
            label: ''
          }]);
        }
      };
    });
  }

  // hack until we bring in raw support to toolbox
  function toolboxRawInputEndpoint() {
    return Ember.computed(function () {
      return {
        placeholder: '',
        request: function request() {
          return Ember.RSVP.Promise.resolve([]);
        }
      };
    });
  }
  function entitySearchEndpoint(_ref9) {
    var inputKey = _ref9.inputKey,
      taxoId = _ref9.taxoId,
      placeholder = _ref9.placeholder,
      filterName = _ref9.filterName,
      field = _ref9.field;
    return Ember.computed('model.filters', function () {
      var _this9 = this;
      return {
        placeholder: placeholder,
        request: function request() {
          return new Ember.RSVP.Promise(function (resolve) {
            return Ember.get(_this9, 'graph').query(_queries.default.entitySearch((0, _getWithDefault.default)(_this9, inputKey, ''), taxoId, filterName, Ember.get(_this9, 'model.filters')), true).then(function (response) {
              resolve(response.data.entitySearch.map(function (n) {
                return {
                  value: n.properties.findBy('label', 'imdbID').value,
                  // TODO switch to id
                  label: n.properties.findBy('label', 'title').value,
                  count: n.properties.findBy('label', 'count').value,
                  inputKey: inputKey,
                  field: field,
                  condition: 'matches'
                };
              }));
            });
          });
        }
      };
    });
  }
  var _default = _exports.default = {
    twitterHandlesEndpoint: twitterHandlesEndpoint,
    toolboxReportSearchByNameOrIdEndpoint: toolboxReportSearchByNameOrIdEndpoint,
    toolboxSearchMonitoredReportsByNameOrId: toolboxSearchMonitoredReportsByNameOrId,
    toolboxAccountSearchByNameOrIdEndpoint: toolboxAccountSearchByNameOrIdEndpoint,
    toolboxCompanySearchByNameOrIdEndpoint: toolboxCompanySearchByNameOrIdEndpoint,
    toolboxFolderSearchByNameOrIdEndpoint: toolboxFolderSearchByNameOrIdEndpoint,
    entitySearchEndpoint: entitySearchEndpoint,
    toolboxAvailabilityEndpoint: toolboxAvailabilityEndpoint,
    toolboxContainerTypeEndpoint: toolboxContainerTypeEndpoint,
    toolboxRawInputEndpoint: toolboxRawInputEndpoint
  };
});