define("affinio/components/modules/audience-overlap", ["exports", "affinio/mixins/affinio-module"], function (_exports, _affinioModule) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // http://emberjs.com/api/classes/Ember.Component.html
  var _default = _exports.default = Ember.Component.extend(_affinioModule.default, {
    // Element
    classNames: ['audience-overlap'],
    tagName: 'div',
    // Services
    preferences: Ember.inject.service(),
    currentCluster: Ember.computed.alias('model.cluster'),
    overlaps: Ember.computed('model.{clusters,report.version}', 'preferences.{overlapSelected,hiddenOverlaps.[]}', 'currentCluster', function () {
      var _this = this;
      var hidden = Ember.get(this, 'preferences.hiddenOverlaps');
      var overlap = Ember.get(this, 'currentCluster.overlaps.response');
      if (overlap) {
        overlap = overlap.filter(function (n) {
          // filter by dropdown
          switch (Ember.get(_this, 'preferences.overlapSelected')) {
            case '1':
              return n.label.split('AND').length - 1 === 0;
            case '2':
              return n.label.split('AND').length - 1 === 1;
            case '3':
              return n.label.split('AND').length - 1 === 2;
            case '4':
              return n.label.split('AND').length - 1 === 3;
            case '5':
              return n.label.split('AND').length - 1 === 4;
            case '6':
              return n.label.split('AND').length - 1 === 5;
            case '7':
              return n.label.split('AND').length - 1 === 6;
            case '8':
              return n.label.split('AND').length - 1 === 7;
            case '9':
              return n.label.split('AND').length - 1 === 8;
            case '10':
              return n.label.split('AND').length - 1 === 9;
            case '11':
              return n.label.split('AND').length - 1 === 10;
            case '12':
              return n.label.split('AND').length - 1 === 11;
            default:
              return n;
          }
        }).filter(function (n) {
          // filter away hiddens
          return !n.label.split(' AND ').any(function (e) {
            return hidden.includes(e);
          });
        }).filter(function (n) {
          // filter away empties
          return n.value > 0;
        }).sortBy('value').reverse();
      } else {
        overlap = [];
      }
      return {
        records: overlap,
        max: _.max(overlap, function (obj) {
          return obj.value;
        }).value
      };
    }),
    noOverlap: Ember.computed.empty('overlaps.records')
  });
});