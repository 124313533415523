define("affinio/components/modules/baseline-summary", ["exports", "affinio/utils/affinio"], function (_exports, _affinio) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  var _default = _exports.default = Ember.Component.extend({
    // Element
    classNames: ['baseline-summary', 'card'],
    classNameBindings: ['model.report.baselineReport:has-baseline:no-baseline', 'model.report.recordsCollection.loaded::hidden'],
    tagName: 'section',
    // Services
    modalManager: Ember.inject.service(),
    store: Ember.inject.service(),
    preferences: Ember.inject.service(),
    tracker: Ember.inject.service(),
    summaryViews: Ember.computed(function () {
      return [{
        label: 'most',
        type: 'traits',
        data: 'processedTraits'
      }, {
        label: 'least',
        type: 'traits',
        data: 'processedUnTraits'
      }];
    }),
    traitTypes: Ember.computed('model.report.clusters.@each.traits', function () {
      var allClusterTraits = Ember.get(this, 'model.report.clusters').mapBy('traits');
      var traits = ['traits'].concat(_toConsumableArray(allClusterTraits.compact().mapBy('meta').map(function (c) {
        return Ember.get(c, 'data').mapBy('label');
      }).flatten().uniq())).map(function (trait) {
        return {
          label: trait === 'traits' ? 'attributes' : Ember.String.dasherize(trait).replace(/-/g, ' '),
          value: trait
        };
      }).sortBy('label');
      var traitSpot = traits.findBy('value', 'traits');
      traits.splice(traits.indexOf(traitSpot), 1);
      traits.unshift(traitSpot);
      return traits;
    }),
    selectedSummaryView: Ember.computed('summaryViews', function () {
      return Ember.get(Ember.get(this, 'summaryViews').objectAt(0), 'label');
    }),
    selectedTraitType: Ember.computed('traitTypes', function () {
      return Ember.get(Ember.get(this, 'traitTypes').objectAt(0), 'value');
    }),
    processedTraits: Ember.computed('model.report.{baselineReport,numberOfMembers.response.value}', function () {
      var report = Ember.get(this, 'model.report');
      if (Ember.get(report, 'baselineReport') && Ember.get(report, 'numberOfMembers.response.value')) {
        var baselineDiffs = [];
        Ember.get(report, 'metricDefinitions').filter(function (metric) {
          return Ember.get(metric, 'referencingModules').findBy('value', 'metric-visualization') || Ember.get(metric, 'label') === 'interests';
        }).map(function (metric) {
          return Ember.get(report, "clusterAggregation.".concat(Ember.get(metric, 'label')));
        }).forEach(function (metric) {
          Ember.get(metric, 'response').forEach(function (instance) {
            var thisValue = Ember.get(Ember.get(instance, 'saturations').findBy('filters.type', 'audience'), 'value');
            var otherValue = Ember.get(Ember.get(instance, 'saturations').findBy('filters.type', 'global'), 'value');
            var scoreDiff = thisValue / otherValue;
            var ret = {
              label: Ember.get(instance, 'label'),
              instanceLookup: Ember.get(instance, 'networkID'),
              metric: Ember.get(metric, 'label'),
              timesMoreLikely: {
                value: scoreDiff,
                isInfinite: !isNaN(scoreDiff)
              },
              traitScore: scoreDiff,
              value: Ember.get(instance, 'value'),
              saturation: Ember.get(instance, 'relevance') ? Ember.get(instance, 'value') : Ember.get(instance, 'value') / Ember.get(report, 'numberOfMembers.response.value') * 100,
              timesMoreLikelyThanBaseline: scoreDiff,
              records: Ember.get(report, 'recordsMap').get(Ember.get(metric, 'label')) // eslint-disable-line ember/use-ember-get-and-set
              .get(Ember.get(instance, 'networkID') || Ember.get(instance, 'label')) // eslint-disable-line ember/use-ember-get-and-set
            };
            baselineDiffs.pushObject(ret);
          });
        });
        baselineDiffs = baselineDiffs.filterBy('timesMoreLikely.value').filter(function (x) {
          return x.saturation > 1;
        }).sortBy('timesMoreLikely.value').reverse();
        Ember.set(Ember.get(this, 'model.cluster'), 'baselinedTraits', baselineDiffs); // eslint-disable-line ember/no-side-effects

        return baselineDiffs;
      }
    }),
    processedUnTraits: Ember.computed('processedTraits', function () {
      return Ember.get(this, 'processedTraits').sortBy('timesMoreLikely.value');
    }),
    selectedSummaryData: Ember.computed('processedTraits', 'selectedSummaryView', 'selectedTraitType', function () {
      var data = Ember.get(this, Ember.get(Ember.get(this, 'summaryViews').findBy('label', Ember.get(this, 'selectedSummaryView')), 'data'));
      if (Ember.get(this, 'selectedTraitType') !== 'traits') {
        data = data.filterBy('metric', Ember.get(this, 'selectedTraitType'));
      }
      return data;
    }),
    demographics: Ember.computed('model.cluster.demographics.loaded', function () {
      return Ember.get(this, 'model.cluster.demographics.response');
    }),
    sizeComparison: Ember.computed('model.report.{numberOfMembers.response.value,baselineReport.numberOfMembers.response.value}', function () {
      return (Ember.get(this, 'model.report.numberOfMembers.response.value') / Ember.get(this, 'model.report.baselineReport.numberOfMembers.response.value')).toFixed(2);
    }),
    genderSummary: Ember.computed('demographics', 'model.report.baselineReport', function () {
      var demographics = Ember.get(this, 'demographics');
      var baselineReport = Ember.get(this, 'model.report.baselineReport');
      var baselineDemographics = Ember.get(this, 'store').peekRecord('metric', "".concat(Ember.get(baselineReport, 'id'), "_all___0___demographics"));
      if (baselineDemographics) {
        baselineDemographics = Ember.get(baselineDemographics, 'response');
      }
      if (!demographics || !baselineDemographics) {
        return null;
      }
      var principalGender = Ember.get(demographics, 'maleTotal') > Ember.get(demographics, 'femaleTotal') ? 'male' : 'female';
      var diff = Math.abs(Ember.get(baselineDemographics, "".concat(principalGender, "Total")) - Ember.get(demographics, "".concat(principalGender, "Total")));
      var moreOrLess = Ember.get(demographics, "".concat(principalGender, "Total")) > Ember.get(baselineDemographics, "".concat(principalGender, "Total")) ? 'more' : 'less';
      return {
        principalGender: principalGender,
        diff: diff,
        moreOrLess: moreOrLess,
        clusterPercentGender: Ember.get(demographics, "".concat(principalGender, "Total")),
        audiencePercentGender: Ember.get(baselineDemographics, "".concat(principalGender, "Total"))
      };
    }),
    ageSummary: Ember.computed('demographics', 'model.report.baselineReport', function () {
      var demographics = Ember.get(this, 'demographics');
      var baselineReport = Ember.get(this, 'model.report.baselineReport');
      var baselineDemographics = Ember.get(this, 'store').peekRecord('metric', "".concat(Ember.get(baselineReport, 'id'), "_all___0___demographics"));
      if (baselineDemographics) {
        baselineDemographics = Ember.get(baselineDemographics, 'response');
      }
      if (!demographics || !baselineDemographics) {
        return null;
      }

      // TODO: should make this a const; also used in cluster-summary.js
      var ageGroups = [{
        label: 'total1824',
        pseudoAge: 21
      }, {
        label: 'total2534',
        pseudoAge: 30
      }, {
        label: 'total3544',
        pseudoAge: 40
      }, {
        label: 'total4554',
        pseudoAge: 50
      }, {
        label: 'total5564',
        pseudoAge: 60
      }, {
        label: 'total65',
        pseudoAge: 65
      }];
      var ageTotals = ageGroups.map(function (ageGroup) {
        return {
          label: Ember.get(ageGroup, 'label'),
          value: demographics[Ember.get(ageGroup, 'label')],
          pseudoAge: Ember.get(ageGroup, 'pseudoAge')
        };
      });
      var audienceAgeTotals = ageGroups.map(function (ageGroup) {
        return {
          label: Ember.get(ageGroup, 'label'),
          value: baselineDemographics[Ember.get(ageGroup, 'label')],
          pseudoAge: Ember.get(ageGroup, 'pseudoAge')
        };
      });
      var perceivedAge = ageTotals.map(function (group) {
        return group.value * group.pseudoAge;
      }).reduce(function (m, n) {
        return m + n;
      }) / ageTotals.mapBy('value').reduce(function (m, n) {
        return m + n;
      });
      var audiencePerceivedAge = audienceAgeTotals.map(function (group) {
        return group.value * group.pseudoAge;
      }).reduce(function (m, n) {
        return m + n;
      }) / ageTotals.mapBy('value').reduce(function (m, n) {
        return m + n;
      });
      return {
        perceivedAge: perceivedAge,
        audiencePerceivedAge: audiencePerceivedAge
      };
    }),
    averageBaselineScore: Ember.computed('model.report.{clusters.@each.traits,baseline}', function () {
      var allTraits = Ember.get(this, 'model.report.clusters').mapBy('traits.data').flatten();
      var baselinedTraits = allTraits.filterBy('timesMoreLikelyThanBaseline');
      var oneCluster = Ember.get(this, 'model.report.clusters.length') === 1;
      var report = Ember.get(this, 'model.report');
      if (baselinedTraits.length) {
        var percentThatAreFoundInBaseline = oneCluster ? 1 : baselinedTraits.length / allTraits.length;
        var averageBaselinedTraitsDelta = oneCluster ? 1 : baselinedTraits.mapBy('timesMoreLikelyThanBaseline').reduce(function (m, n) {
          return m + n;
        }) / baselinedTraits.length;

        // The below is the crus of the algorithm to determine "so what" score:
        // ensemble of what the average diff between trait and baseline is, and inverse of the % shared between em.
        // Average diff matters twice as much (gut)
        // -PR
        Ember.set(report, 'averageClusterBaselineScore', averageBaselinedTraitsDelta * 2 * (1 / percentThatAreFoundInBaseline)); // eslint-disable-line ember/no-side-effects
        Ember.set(report, 'averageTraitBaselineScore', averageBaselinedTraitsDelta); // eslint-disable-line ember/no-side-effects
        Ember.set(report, 'percentOfTraitsFoundInBaseline', percentThatAreFoundInBaseline * 100); // eslint-disable-line ember/no-side-effects
      } else {
        Ember.set(report, 'averageClusterBaselineScore', 0); // eslint-disable-line ember/no-side-effects
        Ember.set(report, 'averageTraitBaselineScore', 0); // eslint-disable-line ember/no-side-effects
        Ember.set(report, 'percentOfTraitsFoundInBaseline', 0); // eslint-disable-line ember/no-side-effects
      }
      return Ember.get(report, 'averageClusterBaselineScore');
    }),
    distinctivenessFromBaseline: Ember.computed('averageBaselineScore', function () {
      var soWhat = Ember.get(this, 'averageBaselineScore');
      var distinctionLevel = Ember.get(_affinio.baselineAudienceDistinctionRanges.find(function (range) {
        return soWhat >= Ember.get(range, 'minSoWhat');
      }), 'level');
      Ember.get(this, 'tracker').track('Baselining: distinction established', {
        network: Ember.get(this, 'model.report.taxonomy.id'),
        currentReport: Ember.get(this, 'model.report.id'),
        baselineReport: Ember.get(this, 'model.report.baselineReport.id'),
        baselineIsCanned: Ember.get(this, 'model.report.baselineReport.isBaseline') ? true : false,
        distinctionLevel: distinctionLevel,
        percentTraitOverlap: Ember.get(this, 'model.report.percentOfTraitsFoundInBaseline').toFixed(2),
        averageTraitBaselineScore: Ember.get(this, 'model.report.averageTraitBaselineScore').toFixed(2),
        averageClusterBaselineScore: Ember.get(this, 'model.report.averageClusterBaselineScore').toFixed(2)
      });
      return distinctionLevel;
    }),
    baselineDistinctivenessColor: Ember.computed('distinctivenessFromBaseline', function () {
      // Doing a little extra work here to allow for baselineAudienceDistinctionRanges to grow in length/complexity.
      var audienceLevel = _affinio.baselineAudienceDistinctionRanges.indexOf(_affinio.baselineAudienceDistinctionRanges.findBy('level', Ember.get(this, 'distinctivenessFromBaseline')));
      var colorScale = d3.scale.linear().domain([0, _affinio.baselineAudienceDistinctionRanges.length * 0.5, _affinio.baselineAudienceDistinctionRanges.length]).range(['rgba(157,238,205,1)', 'rgba(220,220,220,1)', 'rgba(247, 167, 186,1)']);
      return colorScale(audienceLevel);
    }),
    actions: {
      removeBaseline: function removeBaseline(model) {
        var report = Ember.get(model, 'report');

        // Remove the baselined report itself from being bound to this report.
        Ember.set(report, 'baselineReport', null);

        // Remove the score above Cluster Summaries
        Ember.get(report, 'clusters').setEach('soWhat', null);

        // Remove all the double-lines from trait charts
        Ember.get(report, 'clusters').forEach(function (cluster) {
          Ember.get(cluster, 'traits.data').forEach(function (trait) {
            Ember.set(trait, 'timesMoreLikelyThanBaseline', null);
          });
        });

        // reset all the barcharts to be Normalized (not Compare dot view)
        Ember.set(this, 'preferences.normalizedMap', Ember.get(this, 'preferences.normalizedMap').rejectBy('value', 'global-comparison'));
      },
      openBaselineModal: function openBaselineModal(model) {
        Ember.get(this, 'modalManager').openModal('modals/modal-baseline', {
          report: Ember.get(model, 'report'),
          model: model
        });
      }
    }
  });
});