define("affinio/components/trait-card", ["exports", "affinio/utils/constants/countries", "affinio/utils/constants/metrics"], function (_exports, _countries, _metrics) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Element
    classNames: ['trait-card'],
    classNameBindings: ['isMedium:medium', 'isSmall:small', 'isLarge:large', 'active:active'],
    attributeBindings: ['style'],
    path: Ember.inject.service(),
    // Properties
    metrics: _metrics.default,
    size: 'large',
    // Computed Properties
    isSmall: Ember.computed.equal('size', 'small'),
    isMedium: Ember.computed.equal('size', 'medium'),
    isLarge: Ember.computed.equal('size', 'large'),
    isLargeOrMedium: Ember.computed.or('isLarge', 'isMedium'),
    active: Ember.computed.and('inClusterSummary', 'trait'),
    style: Ember.computed('trait', function () {
      return Ember.get(this, 'isLarge') ? Ember.String.htmlSafe("border-left: 5px solid ".concat(Ember.get(this, 'clusterColor'), ";")) : null;
    }),
    colorStyle: Ember.computed('clusterColor', function () {
      return Ember.String.htmlSafe("background-color: ".concat(Ember.get(this, 'clusterColor')));
    }),
    hoverStyle: Ember.computed('clusterColor', function () {
      return Ember.String.htmlSafe("background-color: ".concat(Ember.get(this, 'clusterColor'), "; box-shadow: 0 0 ").concat(Ember.get(this, 'isMedium') ? '1px 1px' : '0 5px', " ").concat(Ember.get(this, 'clusterColor')));
    }),
    processedTrait: Ember.computed('trait', function () {
      var _this = this;
      var _trait;
      if (Ember.get(this, 'trait')) {
        _trait = Ember.Object.create(Ember.get(this, 'trait'));
        var max = Ember.get(this, 'max');
        // score
        Ember.set(_trait, 'score', (Ember.get(_trait, 'traitScore') / max * 100).toFixed(0) === 0 ? 1 : (Ember.get(_trait, 'traitScore') / max * 100).toFixed(0)); // eslint-disable-line ember/no-side-effects

        var recordsTemp = Ember.get(_trait, 'records').find(function (rec) {
          return rec.cluster === Ember.get(_this, 'clusterSuffix');
        });
        Ember.set(_trait, 'tribeValue', recordsTemp ? recordsTemp.value : null); // eslint-disable-line ember/no-side-effects

        // bar width css
        Ember.set(_trait, 'style', Ember.String.htmlSafe("width: ".concat(Ember.get(_trait, 'score'), "%;"))); // eslint-disable-line ember/no-side-effects
        if (parseFloat(Ember.get(_trait, 'other_ratio')) === 0) {
          Ember.set(_trait, 'otherClustersEmpty', true); // eslint-disable-line ember/no-side-effects
        }

        // if two letter interest category metric name, run against countryName computed filter.
        if (Ember.get(_trait, 'metric') === 'interestCategories' && Ember.get(_trait, 'label.length') === 2) {
          // console.log('cc found:');
          var country = _countries.countryBindings.findBy('value', Ember.get(_trait, 'label').toUpperCase());
          Ember.set(_trait, 'label',
          // eslint-disable-line ember/no-side-effects
          country ? country.label : Ember.get(_trait, 'label'));
        }
      } else {
        _trait = Ember.Object.create(); // With ember 3.x if you try to create object with null (as trait could be at top of this computed) it throws an exception
      }
      return _trait;
    }),
    actions: {
      metricHover: function metricHover(enter) {
        Ember.set(this, 'metricHover', enter ? true : false);
      }
    }
  });
});