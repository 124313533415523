define("affinio/components/radar-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['radar-chart'],
    didRender: function didRender() {
      this.drawChart();
    },
    drawChart: function drawChart() {
      var clusters = Ember.get(this, 'clusters');
      var socialNetwork = Ember.get(this, 'clusters') ? Ember.get(this, 'clusters.0.report.socialNetwork') : null;
      if (clusters.length === 2) {
        var data = [];
        if (socialNetwork === 'Twitter') {
          var _iterator = _createForOfIteratorHelper(clusters),
            _step;
          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var cluster = _step.value;
              var ret = [];
              if (Ember.get(cluster, 'interestAffinity')) {
                ret.push({
                  axis: 'Interest Relevance',
                  value: Ember.get(cluster, 'interestAffinity.value') / _.max(clusters.mapBy('interestAffinity.value'))
                });
              }
              if (Ember.get(cluster, 'postsPerMonthCBG')) {
                ret.push({
                  axis: 'Tweets per user',
                  value: Ember.get(cluster, 'postsPerMonthCBG.value') / _.max(clusters.mapBy('postsPerMonthCBG.value'))
                });
              }
              if (Ember.get(cluster, 'interconnectivityCBG')) {
                ret.push({
                  axis: 'Density',
                  value: Ember.get(cluster, 'interconnectivityCBG.value') / _.max(clusters.mapBy('interconnectivityCBG.value'))
                });
              }
              if (Ember.get(cluster, 'numberOfMembers')) {
                ret.push({
                  axis: 'Size',
                  value: Ember.get(cluster, 'numberOfMembers.response.value') / _.max(clusters.mapBy('numberOfMembers.response.value'))
                });
              }
              if (Ember.get(cluster, 'uniqueInterestsPercentage')) {
                ret.push({
                  axis: 'Unique Interests',
                  value: Ember.get(cluster, 'uniqueInterestsPercentage.value') / _.max(clusters.mapBy('uniqueInterestsPercentage.value'))
                });
              }
              if (Ember.get(cluster, 'lurkers')) {
                ret.push({
                  axis: 'Lurkers %',
                  value: Ember.get(cluster, 'lurkers.value') / _.max(clusters.mapBy('lurkers.value'))
                });
              }
              data.push(ret);
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }
        } else if (socialNetwork === 'Reddit') {
          var _iterator2 = _createForOfIteratorHelper(clusters),
            _step2;
          try {
            for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
              var _cluster = _step2.value;
              var _ret = [];
              if (Ember.get(_cluster, 'interestAffinity')) {
                _ret.push({
                  axis: 'Influencer Affinity',
                  value: Ember.get(_cluster, 'interestAffinity.value') / _.max(clusters.mapBy('interestAffinity.value'))
                });
              }
              if (Ember.get(_cluster, 'avgAccountAgeCBG')) {
                _ret.push({
                  axis: 'Reddit Account Age',
                  value: Ember.get(_cluster, 'avgAccountAgeCBG.value') / _.max(clusters.mapBy('avgAccountAgeCBG.value'))
                });
              }
              if (Ember.get(_cluster, 'avgLinkKarmaCBG')) {
                _ret.push({
                  axis: 'Average Link Karma',
                  value: Ember.get(_cluster, 'avgLinkKarmaCBG.value') / _.max(clusters.mapBy('avgLinkKarmaCBG.value'))
                });
              }
              if (Ember.get(_cluster, 'avgCommentKarmaCBG')) {
                _ret.push({
                  axis: 'Average Comment Karma',
                  value: Ember.get(_cluster, 'avgCommentKarmaCBG.value') / _.max(clusters.mapBy('avgCommentKarmaCBG.value'))
                });
              }
              if (Ember.get(_cluster, 'numberOfMembers')) {
                _ret.push({
                  axis: 'Size',
                  value: Ember.get(_cluster, 'numberOfMembers.response.value') / _.max(clusters.mapBy('numberOfMembers.response.value'))
                });
              }
              if (Ember.get(_cluster, 'uniqueInterestsPercentage')) {
                _ret.push({
                  axis: 'Unique Interests',
                  value: Ember.get(_cluster, 'uniqueInterestsPercentage.value') / _.max(clusters.mapBy('uniqueInterestsPercentage.value'))
                });
              }
              data.push(_ret);
            }
          } catch (err) {
            _iterator2.e(err);
          } finally {
            _iterator2.f();
          }
        } else if (socialNetwork === 'Custom') {
          var _iterator3 = _createForOfIteratorHelper(clusters),
            _step3;
          try {
            var _loop = function _loop() {
              var cluster = _step3.value;
              var ret = [];
              Ember.get(cluster, 'aggregateMetrics').slice(0, 6).forEach(function (metric) {
                var key = "".concat(metric.value, ".value");
                ret.push({
                  axis: metric.label.length > 30 ? "".concat(metric.label.slice(0, 30), "...") : metric.label,
                  value: Ember.get(cluster, key) / _.max(clusters.mapBy(key))
                });
              });
              data.push(ret);
            };
            for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
              _loop();
            }
          } catch (err) {
            _iterator3.e(err);
          } finally {
            _iterator3.f();
          }
        } else {
          var _iterator4 = _createForOfIteratorHelper(clusters),
            _step4;
          try {
            for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
              var _cluster2 = _step4.value;
              var _ret2 = [];
              if (Ember.get(_cluster2, 'interestAffinity')) {
                _ret2.push({
                  axis: 'Influencer Affinity',
                  value: Ember.get(_cluster2, 'interestAffinity.value') / _.max(clusters.mapBy('interestAffinity.value'))
                });
              }
              if (Ember.get(_cluster2, 'numberOfMembers')) {
                _ret2.push({
                  axis: 'Size',
                  value: Ember.get(_cluster2, 'numberOfMembers.response.value') / _.max(clusters.mapBy('numberOfMembers.response.value'))
                });
              }
              if (Ember.get(_cluster2, 'uniqueInterestsPercentage')) {
                _ret2.push({
                  axis: 'Unique Interests',
                  value: Ember.get(_cluster2, 'uniqueInterestsPercentage.value') / _.max(clusters.mapBy('uniqueInterestsPercentage.value'))
                });
              }
              data.push(_ret2);
            }
          } catch (err) {
            _iterator4.e(err);
          } finally {
            _iterator4.f();
          }
        }

        // Handle NaNs and stuff
        data.forEach(function (ret) {
          ret.forEach(function (c) {
            if (isNaN(c.value)) {
              c.value = 0;
            }
          });
        });

        // ////////////////////// Set-Up //////////////////////////////

        var margins = {
          top: 60,
          right: 60,
          bottom: 60,
          left: 60
        };
        var width = 200;
        var height = 200;

        // ////////////////// Draw the Chart //////////////////////////
        var colors = d3.scale.ordinal().range([Ember.get(this, 'model.report.id') === Ember.get(clusters.objectAt(0), 'report.id') ? Ember.get(clusters.objectAt(0), 'color') : '#424242', Ember.get(this, 'model.report.id') === Ember.get(clusters.objectAt(1), 'report.id') ? Ember.get(clusters.objectAt(1), 'color') : '#424242']);
        var radarChartOptions = {
          w: width,
          h: height,
          margin: margins,
          maxValue: 1,
          levels: 5,
          roundStrokes: true,
          color: colors
        };
        // Call function to draw the Radar chart
        // console.log('data', data);
        new RadarChart(this.$()[0], data, radarChartOptions);
      } // if clusters
    } // drawChart
  });
});