define("affinio/utils/taxonomies/pinterest", ["exports", "affinio/utils/taxonomies/standard-metric-definitions"], function (_exports, _standardMetricDefinitions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.taxonomy = _exports.default = void 0;
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  var taxonomy = _exports.taxonomy = {
    id: 'Pinterest',
    meta: {
      public: true,
      loadRecordsMap: true,
      alwaysLoadMetrics: {
        blocking: false,
        report: ['numberOfMembers', 'percentTotal'],
        cluster: ['numberOfMembers', 'density', 'averagePostsPerMonth', '_interests' // needed for interest relevance on the left side nav. TODO: can we make that a backend prop? would allow for pages to load without interests
        ]
      },
      traits: {
        minimumValue: 4,
        minimumTimesMoreLikely: 1.2,
        minimumSaturation: 0.1
      }
    },
    schema: [{
      label: 'Overview',
      value: 'overview',
      subcategories: [{
        label: 'Summary',
        value: 'summary',
        modules: [{
          label: 'Audience Details and Source',
          value: 'audience-header',
          dependencies: ['cluster.isAggregate'],
          metrics: [{
            clusterAggregation: '_interests'
          }]
        }, {
          label: 'Audience Visualization',
          value: 'audience-visualization',
          dependencies: ['cluster.isAggregate'],
          metrics: [{
            report: 'clusterSVGURL'
          }]
        }, {
          label: 'Audience Summary',
          value: 'audience-summary',
          dependencies: ['cluster.isAggregate'],
          metrics: [{
            everyCluster: 'lurkerPercentage'
          }, {
            everyCluster: 'density'
          }, {
            everyCluster: '_interests'
          }, {
            report: 'imageAnalysis'
          }, {
            everyCluster: 'averagePostsPerMonth'
          }]
        }, {
          label: 'Cluster Summaries',
          value: 'cluster-summaries',
          dependencies: ['cluster.isAggregate'],
          description: 'This section provides a consolidated overview of key over-indexing attributes by cluster. Click-through to see more.'
        }, {
          label: 'Bio Keywords',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'bioKeywords'
          }],
          primaryMetric: 'bioKeywords',
          description: 'These are the top used terms members of this cluster use to describe themselves. The colored bars represent that terms usage in each of the other clusters.',
          dependencies: ['cluster.isCluster']
        }, {
          label: 'Top Interests',
          value: 'top-interests',
          description: 'These represent this cluster\'s most contextually relevant interests as measured by the Relevance score. Highlight Uniques to see which interests are only relevant to this cluster.',
          dependencies: ['cluster.isCluster'],
          metrics: [{
            everyCluster: '_interests'
          }],
          primaryMetric: 'interests'
        }, {
          label: 'Top Locations',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'locations'
          }],
          primaryMetric: 'locations',
          description: 'We analyze the profiles of every cluster member and plot their locations using a heat map. Darker/highly saturated areas indicate zones where a lot of cluster members live.',
          dependencies: ['cluster.isCluster']
        }, {
          label: 'Top Shared Links',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'links'
          }],
          primaryMetric: 'links',
          description: 'These are links to the articles with the highest number of engagements by members of this cluster.',
          dependencies: ['cluster.isCluster']
        }]
      }, {
        label: 'Attributes',
        value: 'traits',
        dependencies: ['cluster.isCluster'],
        descriptions: ['These lists show the most and least distinct attributes among all metrics of this cluster.', 'Toggle the attribute type below to view a specific metric type, and click any attribute to dig deeper.'],
        modules: [{
          label: null,
          value: 'page-title'
        }, {
          label: 'Top Attributes',
          value: 'traits-gallery'
        }]
      }]
    }, {
      label: 'Members',
      value: 'members',
      subcategories: [{
        label: 'Profiles',
        value: 'profiles',
        modules: [{
          label: 'Members\' Profiles',
          value: 'members-profiles',
          metrics: [{
            everySubCluster: 'followerProfiles'
          }],
          description: 'We provide images for the top 100 high affinity members to help you get an idea of how these users visually represent themselves.'
        }, {
          label: 'Bio Keywords',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'bioKeywords'
          }],
          primaryMetric: 'bioKeywords',
          description: 'These are the top used terms members use to describe themselves.'
        }]
      }, {
        label: 'Locations',
        value: 'locations',
        modules: [{
          label: 'Locations',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'locations'
          }],
          primaryMetric: 'locations',
          description: 'Top Locations of the members'
        }]
      }, {
        label: 'Engagement',
        value: 'engagement',
        modules: [{
          label: 'Time of Day',
          value: 'engagement-legacy',
          metrics: [{
            everyCluster: 'histogram'
          }]
        }]
      }]
    }, {
      label: 'Interests',
      value: 'interests',
      subcategories: [{
        label: 'Interests',
        value: 'index',
        modules: [{
          label: 'Interests',
          value: 'interests-vis',
          type: 'full',
          recordsKey: 'interests',
          recordsInstanceKey: 'networkID',
          metrics: [{
            everyCluster: '_interests'
          }],
          primaryMetric: 'interests'
        }]
      }]
    }, {
      label: 'Content',
      value: 'content',
      subcategories: [{
        label: 'Topics',
        value: 'topics',
        modules: [{
          label: 'Comment Keywords',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'comments'
          }],
          primaryMetric: 'comments',
          description: 'Most-used words in comments from this audience.'
        }]
      }, {
        label: 'Links',
        value: 'links',
        modules: [{
          noHeader: true,
          label: 'Links',
          value: 'media-gallery',
          type: 'links',
          dependencies: ['cluster.links'],
          metrics: [{
            everyCluster: 'links'
          }]
        }]
      }, {
        label: 'Domains',
        value: 'domains',
        modules: [{
          label: 'Domains',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'domains'
          }],
          primaryMetric: 'domains',
          description: 'These are the domains where members of this audience source and share content, extracted from their tweets.'
        }]
      }]
    }, {
      label: 'Compare',
      value: 'compare',
      subcategories: [{
        label: 'Compare Metrics',
        value: 'index',
        modules: [{
          label: 'Compare Display',
          value: 'compare-display',
          metrics: [{
            cluster: '_interests'
          }]
        }]
      }, {
        label: 'Competitive',
        value: 'competitive-analysis',
        dependencies: ['report.hasCompetitive'],
        modules: [{
          label: 'Audience Share',
          value: 'audience-share',
          metrics: [{
            everyCluster: '_breakdown'
          }]
        }, {
          label: 'Audience Overlap',
          value: 'audience-overlap',
          metrics: [{
            everyCluster: 'overlaps'
          }]
        }]
      }]
    }, {
      label: 'Actions',
      value: 'actions',
      subcategories: [{
        label: 'Export',
        value: 'export',
        modules: [{
          label: 'Data Downloads (.csv)',
          value: 'csv-exporter',
          metrics: [{
            everyCluster: '_interests'
          }, {
            everyCluster: 'topRankedMetricDelta'
          }, {
            everyCluster: 'activity'
          }, {
            report: 'recordsCollection'
          }]
        }, {
          label: 'Screenshots Export',
          value: 'screenshots-exporter'
        }, {
          label: 'Network Graph Vector (.svg)',
          value: 'network-graph-export',
          metrics: [{
            report: 'clusterSVGURL'
          }]
        }]
      }]
    }],
    exportSchema: [{
      metrics: ['comments']
    }, {
      metrics: ['domains']
    }, {
      metrics: ['bioKeywords']
    }, {
      metrics: ['locations']
    }, {
      metrics: ['links']
    }, {
      exportType: 'interest',
      metrics: ['interests']
    }, {
      exportType: 'members',
      metrics: ['followerProfiles'],
      clusterOnly: true
    }, {
      exportType: 'breakdown',
      metrics: ['_breakdown']
    }, {
      metrics: ['overlaps']
    }],
    metricDefinitions: [{
      label: 'comments',
      title: 'Comment Keywords',
      description: 'Most-used words in comments from this audience.',
      graphQLType: 'LabelValue',
      properties: ['label', 'value'],
      defaultValue: 'array',
      icon: 'commenting',
      traitWeight: 1,
      deltaWeight: 0.5,
      level: 'cluster'
    }, {
      label: 'domains',
      title: 'Domains',
      description: 'These are the domains where members of this audience source and share content, extracted from their tweets',
      graphQLType: 'LabelValue',
      properties: ['label', 'value'],
      defaultValue: 'array',
      icon: 'globe',
      comparable: true,
      sliceSize: 50,
      traitWeight: 0,
      deltaWeight: 1,
      normalizationOptions: Ember.A([
      // TODO is this still used from here? :thinking:
      {
        value: 'cluster-bias',
        label: 'Normalized'
      }, {
        value: 'term-relative',
        label: 'Bar Chart'
      }, {
        value: 'niche-ranked',
        label: 'Niche Ranked'
      }, {
        value: 'word-cloud',
        label: 'Word Cloud'
      }]),
      level: 'cluster'
    }, {
      label: 'bioKeywords',
      title: 'Bio Keywords',
      description: 'These are the top used terms members of this audience use to describe themselves.',
      graphQLType: 'LabelValue',
      icon: 'user',
      properties: ['label', 'value'],
      defaultValue: 'array',
      traitWeight: 1,
      comparable: true,
      deltaWeight: 1,
      normalizationOptions: Ember.A([{
        value: 'cluster-bias',
        label: 'Normalized'
      }, {
        value: 'term-relative',
        label: 'Bar Chart'
      }, {
        value: 'niche-ranked',
        label: 'Niche Ranked'
      }, {
        value: 'word-cloud',
        label: 'Word Cloud'
      }]),
      level: 'cluster'
    }, {
      label: 'locations',
      title: 'Locations',
      description: 'Top Locations of the members of this audience',
      graphQLType: 'LabelValue',
      properties: ['label', 'value'],
      defaultValue: 'array',
      icon: 'map-marker',
      traitWeight: 1,
      comparable: true,
      deltaWeight: 1,
      normalizationOptions: Ember.A([{
        value: 'cluster-bias',
        label: 'Normalized'
      }, {
        value: 'term-relative',
        label: 'Bar Chart'
      }, {
        value: 'niche-ranked',
        label: 'Niche Ranked'
      }, {
        value: 'word-cloud',
        label: 'Word Cloud'
      }]),
      level: 'cluster'
    }, {
      label: 'links',
      title: 'Top Links',
      description: 'Top links pinned by members of this audience',
      graphQLType: 'Link',
      properties: ['label', 'value', 'mediaType', 'embedType', 'title', 'description', 'type', 'mediaURL', 'image'],
      defaultValue: 'array',
      icon: 'star',
      traitWeight: 0,
      deltaWeight: 1,
      sliceSize: 20,
      level: 'cluster'
    }, {
      label: 'interests',
      modelAlias: '_interests',
      title: 'Interests',
      description: 'Categories of Top Influencers from the Interests section',
      graphQLType: 'Interest',
      properties: ['affinity { value isInfinite }', 'description', 'followersCount', 'friendsCount', 'headerImage', 'iconImage', 'screenName', 'label:name', 'lastPost { id dateCreated }', 'name', 'networkID', 'rank', 'relevance', 'tags', 'score'],
      defaultValue: 'array',
      icon: 'star',
      comparable: true,
      traitWeight: 1,
      deltaWeight: 1,
      level: 'cluster'
    }, {
      label: 'followerProfiles',
      title: 'Follower Profiles',
      description: 'profiles of the members of this cluster',
      graphQLType: 'PinterestInstagramFollowerProfile',
      properties: ['id', 'friendsCount', 'description', 'name', 'screenName', 'statusesCount', 'profileImageURL', 'followersCount'],
      defaultValue: 'array',
      icon: 'user',
      deltaWeight: 1,
      level: 'cluster'
    }, {
      label: 'lurkerPercentage',
      properties: [],
      defaultValue: 'number',
      level: 'cluster'
    }, {
      label: 'histogram',
      graphQLType: 'HourCount',
      properties: ['hour', 'count'],
      defaultValue: 'array',
      level: 'cluster'
    }, {
      label: 'breakdown',
      modelAlias: '_breakdown',
      graphQLType: 'Breakdown',
      title: 'Cluster Breakdown',
      properties: ['size', 'users { id screenName followerCount}'],
      defaultValue: 'array',
      level: 'cluster'
    }, {
      label: 'overlaps',
      title: 'Audience Overlap',
      graphQLType: 'LabelIntValue',
      properties: ['label', 'value'],
      defaultValue: 'array',
      traitWeight: 0,
      level: 'cluster'
    }, {
      label: 'percentTotal',
      properties: [],
      defaultValue: 'number',
      level: 'report'
    }, {
      label: 'activity',
      graphQLType: 'Activity',
      properties: ['mean', 'sum'],
      defaultValue: 'object',
      level: 'cluster'
    }].concat(_toConsumableArray(_standardMetricDefinitions.default))
  };
  var _default = _exports.default = {
    taxonomy: taxonomy
  };
});