define("affinio/templates/components/report-builder/min-max-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "XnT7tz0Z",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[22,0,[]]]],[0,\"\\n\"],[7,\"section\"],[9],[0,\"\\n  \"],[1,[27,\"input\",null,[[\"type\",\"min\",\"placeholder\",\"value\",\"change\"],[\"number\",\"0\",\"Min\",[23,[\"model\",\"filters\",\"minInfluencerFollowers\"]],[27,\"action\",[[22,0,[]],[27,\"toggle\",[\"filterChange\",[23,[\"model\"]]],null]],null]]]],false],[0,\"\\n  \"],[1,[27,\"input\",null,[[\"type\",\"min\",\"placeholder\",\"value\",\"change\"],[\"number\",[23,[\"model\",\"filters\",\"minInfluencerFollowers\"]],\"Max\",[23,[\"model\",\"filters\",\"maxInfluencerFollowers\"]],[27,\"action\",[[22,0,[]],[27,\"toggle\",[\"filterChange\",[23,[\"model\"]]],null]],null]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/report-builder/min-max-input.hbs"
    }
  });
});