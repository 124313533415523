define("affinio/components/report-builder/csv-upload", ["exports", "affinio/mixins/input-module", "affinio/utils/get-with-default", "ember-animated/transitions/fade"], function (_exports, _inputModule, _getWithDefault, _fade) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_inputModule.default, {
    classNames: ['csv-upload'],
    tagName: 'div',
    tracker: Ember.inject.service(),
    api: Ember.inject.service(),
    graph: Ember.inject.service(),
    modalManager: Ember.inject.service(),
    user: Ember.inject.service(),
    fileUpload: Ember.inject.service(),
    uploadingFile: false,
    showUploadFileInput: false,
    file: Ember.computed.alias('model.filters.fileURL'),
    selectedInputType: 'csv',
    transition: _fade.default,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (!Ember.get(this, 'controller.sourceReport')) {
        Ember.set(this, 'model.filters.followersOfCSV', false);
        Ember.set(this, 'model.filters.isHandles', true);
      }
    },
    actions: {
      addFile: function addFile(_ref) {
        var _this = this;
        var fileInfo = _ref.fileInfo;
        var tracker = Ember.get(this, 'tracker');
        Ember.set(this, 'uploadingFile', true);
        if (navigator.platform.toLowerCase().indexOf('win') > -1 || fileInfo.type === 'text/csv' || fileInfo.type === 'application/vnd.ms-excel') {
          var formData = new FormData();
          var name = fileInfo.name;
          formData.append('name', name);
          formData.append('file', fileInfo, name);
          // Save
          this.fileUpload.upload(fileInfo, name, 'Twitter CSV').then(function (response) {
            Ember.get(_this, 'tracker').track('CSV Uploaded');
            Ember.set(_this, 'model.filters.fileID', response.id);
            Ember.set(_this, 'model.filters.fileURL', response.id);
            _this.toggleProperty('model.filterChange');
          }).catch(function (e) {
            // TODO handle error message from endpoint
            var message = (0, _getWithDefault.default)(e, 'payload.errors.0.message', '');
            if (message.length) {
              message = "<br><br>".concat(message, "<br><br>");
            }
            Ember.get(_this, 'tracker').track('Error on CSV Upload');
            Ember.get(_this, 'modalManager').openModal('modals/modal-error', {
              title: 'Upload Error',
              reason: "Something went wrong during file upload.".concat(message),
              fix: 'Please try again'
            });
          }).then(function () {
            return Ember.set(_this, 'uploadingFile', false);
          });
        } else {
          Ember.get(this, 'modalManager').openModal('modals/modal-error', {
            title: 'Please select a CSV file',
            reason: 'This file doesn\'t appear to be a proper CSV',
            fix: 'Try uploading a proper CSV file.'
          });
          tracker.track('assets CSV upload invalid file type');
          Ember.set(this, 'addingAsset', false);
        }
      },
      clearFile: function clearFile() {
        Ember.get(this, 'tracker').track('New Campaign - clear CSV file', {
          fileURL: Ember.get(this, 'model.filters.fileURL')
        });
        Ember.set(this, 'model.filters.fileURL', null);
        Ember.set(this, 'uploadingFile', false);
        this.toggleProperty('model.filterChange');
      }
    }
  });
});