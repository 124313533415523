define("affinio/components/modules/monitor-dashboard", ["exports", "ember-copy", "moment", "affinio/utils/constants/date-formats", "affinio/utils/mutations", "affinio/mixins/affinio-module", "affinio/utils/auto-suggest", "affinio/utils/queries"], function (_exports, _emberCopy, _moment, _dateFormats, _mutations, _affinioModule, _autoSuggest, _queries) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var assetLimit = 800;
  var niceFrequency = function niceFrequency(f) {
    var r = '';
    switch (f) {
      case 'one-time':
        r = 'once';
        break;
      case 'daily':
        r = 'every day';
        break;
      case 'weekly':
        r = 'every week';
        break;
      case 'semi-monthly':
        r = 'every 14 days';
        break;
      case 'monthly':
        r = 'every month';
        break;
    }
    return r;
  };
  var _default = _exports.default = Ember.Component.extend(_affinioModule.default, {
    // Element
    classNames: ['monitor-dashboard', 'updateForm'],
    tagName: 'section',
    user: Ember.inject.service(),
    tracker: Ember.inject.service(),
    graph: Ember.inject.service(),
    modalManager: Ember.inject.service(),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      Ember.get(this, 'graph').query(_queries.default.myBenchmarks);
    },
    savedStart: null,
    showBenchmarksValue: 'no',
    showBenchmarks: Ember.computed.equal('showBenchmarksValue', 'yes'),
    twEndpoint: (0, _autoSuggest.twitterHandlesEndpoint)({
      inputKey: 'autoSuggestInput'
    }),
    active: Ember.computed.alias('model.report.monitoredInformation.isActive'),
    iCreatedReport: Ember.computed.alias('model.report.accountOwned'),
    monitoringNotAllowed: Ember.computed('model.report.canEdit', function () {
      return !Ember.get(this, 'model.report.canEdit') || Ember.get(this, 'user.account.isViewerSeat');
    }),
    hasOwnedAssets: Ember.computed('user.{account.assetGroups.[],company.assetGroups.[]}', function () {
      var personalGroups = Ember.get(this, 'user.account.assetGroups');
      var companyGroups = Ember.get(this, 'user.company.assetGroups');
      return personalGroups.filter(function (n) {
        return Ember.get(n, 'assets').length && Ember.get(n, 'assetType') === 'owned';
      }).length || companyGroups.filter(function (n) {
        return Ember.get(n, 'assets').length && Ember.get(n, 'assetType') === 'owned';
      }).length;
    }),
    hasTargetedAssets: Ember.computed('user.{account.assetGroups.[],company.assetGroups.[]}', function () {
      var personalGroups = Ember.get(this, 'user.account.assetGroups');
      var companyGroups = Ember.get(this, 'user.company.assetGroups');
      return personalGroups.filter(function (n) {
        return Ember.get(n, 'assets').length && Ember.get(n, 'assetType') === 'targeted';
      }).length || companyGroups.filter(function (n) {
        return Ember.get(n, 'assets').length && Ember.get(n, 'assetType') === 'targeted';
      }).length;
    }),
    initialFrequency: Ember.computed('active', function () {
      return niceFrequency(Ember.get(this, 'model.report.monitoredInformation.frequency'));
    }),
    readableFrequency: Ember.computed('frequency', function () {
      return niceFrequency(Ember.get(this, 'frequency'));
    }),
    nextUpdate: Ember.computed('model.report.monitoredInformation.nextUpdate', function () {
      return (0, _moment.default)(_moment.default.utc(Ember.get(this, 'model.report.monitoredInformation.nextUpdate')).local());
    }),
    _frequency: 'weekly',
    frequency: Ember.computed('active', 'model.report.{id,monitoredInformation.frequency}', '_frequency', {
      get: function get() {
        if (Ember.get(this, 'active')) {
          return Ember.get(this, 'model.report.monitoredInformation.frequency');
        } else {
          return Ember.get(this, '_frequency');
        }
      },
      set: function set(key, value) {
        Ember.set(this, '_frequency', value);
        return value;
      }
    }),
    firstUpdate: Ember.computed('frequency', function () {
      var firstUpdate = 'one-time';
      switch (Ember.get(this, 'frequency')) {
        case 'daily':
          firstUpdate = (0, _moment.default)().add(1, 'days').format(_dateFormats.default.fullDayMonth);
          break;
        case 'weekly':
          firstUpdate = (0, _moment.default)().add(7, 'days').format(_dateFormats.default.fullDayMonth);
          break;
        case 'semi-monthly':
          firstUpdate = (0, _moment.default)().add(14, 'days').format(_dateFormats.default.fullDayMonth);
          break;
        case 'monthly':
          firstUpdate = (0, _moment.default)().add(1, 'month').format(_dateFormats.default.fullDayMonth);
          break;
      }
      return firstUpdate;
    }),
    isOneTime: Ember.computed.equal('firstUpdate', 'one-time'),
    willRepeat: Ember.computed.not('isOneTime'),
    updatedWithinWeek: Ember.computed('model.report.monitoredInformation.lastUpdate', function () {
      var lastUpdate = Ember.get(this, 'model.report.monitoredInformation.lastUpdate');
      if (lastUpdate) {
        var lastUpdateDate = (0, _moment.default)(lastUpdate);
        var now = (0, _moment.default)();
        if (lastUpdateDate.isBefore(now)) {
          if (lastUpdateDate.diff(now, 'days') >= -7) {
            lastUpdateDate.add(7, 'days');
            return lastUpdateDate.format(_dateFormats.default.fullMonth);
          }
        }
      }
      return false;
    }),
    start: Ember.computed('firstUpdate', {
      get: function get() {
        return Ember.get(this, 'savedStart') !== 'one-time' ? Ember.get(this, 'firstUpdate') : 'one-time';
      },
      set: function set(key, value) {
        Ember.set(this, 'savedStart', value);
        return value;
      }
    }),
    newReport: Ember.computed('model.report.dateCreated', function () {
      var dateCreated = Ember.get(this, 'model.report.dateCreated');
      var now = (0, _moment.default)(new Date());
      var delta = now.diff(dateCreated, 'days');
      return delta <= 7;
    }),
    canMonitorDate: Ember.computed('model.report.dateCreated', function () {
      return (0, _moment.default)(Ember.get(this, '')).add(7, 'days').format(_dateFormats.default.shortYearDotSeparated);
    }),
    actions: {
      updateCampaign: function updateCampaign() {
        var _this = this;
        var frequency = Ember.get(this, 'frequency');
        var reportId = Ember.get(this, 'model.report.id');
        var nextUpdateDate = (0, _moment.default)(Ember.get(this, 'nextUpdate')).format(_dateFormats.default.fullDayMonth);
        var isImmediate = Ember.get(this, 'start') === 'one-time' ? true : false;
        Ember.set(this, 'saving', true);
        Ember.get(this, 'tracker').track('Campaign Update Requested', {
          id: reportId
        });
        Ember.get(this, 'graph').mutate(_mutations.default.addTwitterMonitoredInformation(Ember.get(this, 'model.report'), frequency, true, isImmediate)).then(function () {
          Ember.get(_this, 'tracker').track('Campaign Updated', {
            id: reportId
          });
          nextUpdateDate = (0, _moment.default)(Ember.get(_this, 'nextUpdate')).format(_dateFormats.default.fullDayMonth);
          Ember.get(_this, 'modalManager').openModal('modals/modal-success', {
            title: 'Report Monitoring Updated',
            message: Ember.get(_this, 'willRepeat') ? "".concat(Ember.get(_this, 'model.report.name'), " is now updating <em>").concat(niceFrequency(frequency), "</em> starting <em>").concat(nextUpdateDate, "</em>. This report will continue to update <em>").concat(niceFrequency(frequency), "</em>, and you'll receive an email at <em>").concat(Ember.get(_this, 'model.report.creator.email'), "</em> when the update is ready to view.") : "You'll receive an email to <em>".concat(Ember.get(_this, 'model.report.creator.email'), "</em> detailing changes to your audience, plus this dashboard will update to show trending time-based data. This will only happen once - you can choose to update the data again at a later date."),
            okAction: true
          });
        }).catch(function () {
          Ember.get(_this, 'tracker').track('Error on Campaign Update Save', {
            id: reportId
          });
          Ember.get(_this, 'modalManager').openModal('modals/modal-error', {
            title: 'Report Monitoring Update Error',
            reason: 'This is a known issue when updating your audience monitoring settings. It may currently be taking a snapshot and need some time to finish before monitoring settings can be changed',
            fix: 'try reupdating the settings in an hour'
          });
        }).then(function () {
          return Ember.set(_this, 'saving', false);
        });
      },
      stopUpdatingCampaign: function stopUpdatingCampaign() {
        var _this2 = this;
        var frequency = Ember.get(this, 'frequency');
        var reportId = Ember.get(this, 'model.report.id');
        Ember.set(this, 'stopping', true);
        Ember.get(this, 'tracker').track('Campaign Update Stop Requested', {
          id: reportId
        });
        Ember.get(this, 'graph').mutate(_mutations.default.addTwitterMonitoredInformation(Ember.get(this, 'model.report'), frequency, false, false)).then(function () {
          Ember.get(_this2, 'tracker').track('Campaign Update Stop Saved', {
            id: reportId
          });
          Ember.get(_this2, 'modalManager').openModal('modals/modal-success', {
            title: 'Report Monitoring Updated',
            message: "".concat(Ember.get(_this2, 'model.report.name'), "'s update policy has been changed"),
            okAction: true
          });
        }).catch(function () {
          Ember.get(_this2, 'tracker').track('Error on Campaign Update Stop Save', {
            id: reportId
          });
          Ember.get(_this2, 'modalManager').openModal('modals/modal-error', {
            title: 'Report Monitoring Update Error',
            reason: 'This is a known issue when updating your audience monitoring settings. It may currently be taking a snapshot and need some time to finish before monitoring settings can be changed',
            fix: 'try reupdating the settings in an hour'
          });
        }).then(function () {
          return Ember.set(_this2, 'stopping', false);
        });
      },
      // TODO {{toggle }}
      toggleProp: function toggleProp(prop) {
        this.toggleProperty(prop);
      },
      addAssetToAudienceStaging: function addAssetToAudienceStaging(asset) {
        var _this3 = this;
        // console.log('addAssetToAudienceStaging', asset);
        var storedBenchmarkGroups = (0, _emberCopy.copy)(Ember.get(this, 'model.report.storedBenchmarkGroups.response'), true);
        var benchmarkGroupName = Ember.get(this, 'benchmarkGroupName');
        var existingBenchmarkGroup = storedBenchmarkGroups ? storedBenchmarkGroups.findBy('name', benchmarkGroupName) : null;
        var handle = Ember.get(asset, 'label').replace('@', '');
        var totalAssets = storedBenchmarkGroups && storedBenchmarkGroups.length ? storedBenchmarkGroups.mapBy('members.length').reduce(function (a, b) {
          return a + b;
        }, 0) : 0;
        if (totalAssets < assetLimit) {
          if (existingBenchmarkGroup) {
            var existingMembers = Ember.get(existingBenchmarkGroup, 'members');
            if (!existingMembers.includes(handle)) {
              // console.log('asset not found in benchmarkGroup', get(existingBenchmarkGroup, 'name'));
              Ember.get(existingBenchmarkGroup, 'members').pushObject(handle);
              storedBenchmarkGroups.forEach(function (group) {
                delete group.hidden;
              });
              Ember.get(this, 'graph').mutate(_mutations.default.updateTwitterReportBenchmarkGroups(Ember.get(this, 'model.report'), storedBenchmarkGroups)).then(function () {
                return _this3.toggleProperty('internalChange');
              });
            } else {
              // console.log('asset is already in that group');
            }
          } else {
            // console.log('no benchmark group found with name ');
            var newGroup = {
              name: benchmarkGroupName,
              members: [handle]
            };
            storedBenchmarkGroups.pushObject(newGroup);
            storedBenchmarkGroups.forEach(function (group) {
              delete group.hidden;
            });
            Ember.get(this, 'graph').mutate(_mutations.default.updateTwitterReportBenchmarkGroups(Ember.get(this, 'model.report'), storedBenchmarkGroups)).then(function () {
              return _this3.toggleProperty('internalChange');
            });
          }
        } else {
          // Past limit, can't add
          Ember.get(this, 'modalManager').openModal('modals/modal-error', {
            title: 'Tracked Interests Limit Reached',
            reason: 'The maximum number of tracked interests per report is 800. Please make sure the sum of all tracked interests groups does not exceed the limit and try again'
          });
        }
      },
      addGroupToAudienceStaging: function addGroupToAudienceStaging(assetGroup) {
        var _this4 = this;
        // console.log('addGroupToAudienceStaging', assetGroup);
        var assetGroupLength = Ember.get(assetGroup, 'assets.length');
        if (assetGroupLength > 0) {
          var diff, newGroup;
          var storedBenchmarkGroups = (0, _emberCopy.copy)(Ember.get(this, 'model.report.storedBenchmarkGroups.response'), true);
          var totalAssets = assetGroupLength + (storedBenchmarkGroups && storedBenchmarkGroups.length ? storedBenchmarkGroups.mapBy('members.length').reduce(function (a, b) {
            return a + b;
          }, 0) : 0);
          if (totalAssets <= assetLimit) {
            var existingGroup = storedBenchmarkGroups.findBy('name', Ember.get(assetGroup, 'name'));
            var existingMembers = existingGroup ? Ember.get(existingGroup, 'members') : null;
            var passedInMembers = Ember.get(assetGroup, 'assets') ? Ember.get(assetGroup, 'assets').mapBy('name') : null;
            diff = _.difference(passedInMembers, existingMembers);
            // console.log('diff', diff);
            if (existingGroup && Ember.get(diff, 'length') > 0) {
              existingMembers.pushObjects(diff);
            } else if (!existingGroup) {
              // add new group
              newGroup = {
                name: Ember.get(assetGroup, 'name'),
                members: Ember.get(assetGroup, 'assets').mapBy('name')
              };
              storedBenchmarkGroups.pushObject(newGroup);
            }
            if (Ember.get(diff, 'length') > 0 || newGroup) {
              // make mutation
              storedBenchmarkGroups.forEach(function (group) {
                delete group.hidden;
              });
              Ember.get(this, 'graph').mutate(_mutations.default.updateTwitterReportBenchmarkGroups(Ember.get(this, 'model.report'), storedBenchmarkGroups)).then(function () {
                return _this4.toggleProperty('internalChange');
              });
            } else {
              // console.log('no mutation needed');
            }
          } else {
            Ember.get(this, 'modalManager').openModal('modals/modal-error', {
              title: 'Tracked Interests Limit Reached',
              reason: 'The maximum number of tracked interests per report is 800. Please make sure the sum of all tracked interests groups does not exceed the limit and try again'
            });
          }
        } else {
          Ember.get(this, 'modalManager').openModal('modals/modal-error', {
            title: "".concat(Ember.get(assetGroup, 'name') ? Ember.get(assetGroup, 'name') : 'untitled Tracked Interests Group', " is missing members"),
            reason: 'Please add handles to the tracked interests group to track it in your report'
          });
        }
      },
      removeBenchmarkGroup: function removeBenchmarkGroup(benchmarkIndex) {
        var _this5 = this;
        var storedBenchmarkGroups = (0, _emberCopy.copy)(Ember.get(this, 'model.report.storedBenchmarkGroups.response'), true);
        storedBenchmarkGroups = storedBenchmarkGroups.reject(function (group, index) {
          return index === benchmarkIndex;
        });
        storedBenchmarkGroups.forEach(function (group) {
          return delete group.hidden;
        });
        Ember.get(this, 'graph').mutate(_mutations.default.updateTwitterReportBenchmarkGroups(Ember.get(this, 'model.report'), storedBenchmarkGroups)).then(function () {
          return _this5.toggleProperty('internalChange');
        });
      },
      removeAssetFromBenchmarkGroup: function removeAssetFromBenchmarkGroup(benchmarkIndex, removedHandle) {
        var _this6 = this;
        var storedBenchmarkGroups = (0, _emberCopy.copy)(Ember.get(this, 'model.report.storedBenchmarkGroups.response'), true);
        var benchmarkGroup = storedBenchmarkGroups.objectAt(benchmarkIndex);
        var filteredMembers = Ember.get(benchmarkGroup, 'members').reject(function (handle) {
          return handle === removedHandle.replace('@', '');
        });
        if (Ember.get(filteredMembers, 'length') > 0) {
          Ember.set(storedBenchmarkGroups.objectAt(benchmarkIndex), 'members', filteredMembers);
        } else {
          // remove benchmark group from filters if no members left
          storedBenchmarkGroups = storedBenchmarkGroups.reject(function (group, i) {
            return i === benchmarkIndex;
          });
        }
        storedBenchmarkGroups.forEach(function (group) {
          delete group.hidden;
        });
        Ember.get(this, 'graph').mutate(_mutations.default.updateTwitterReportBenchmarkGroups(Ember.get(this, 'model.report'), storedBenchmarkGroups)).then(function () {
          return _this6.toggleProperty('internalChange');
        });
      }
    }
  });
});