define("affinio/components/modals/modal-screenshot", ["exports", "affinio/components/modals/modal-base"], function (_exports, _modalBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _modalBase.default.extend({
    classNames: ['modal-screenshot'],
    tracker: Ember.inject.service(),
    path: Ember.inject.service(),
    user: Ember.inject.service(),
    preferences: Ember.inject.service(),
    // affinioApi: service(),
    routing: Ember.inject.service('-routing'),
    notify: Ember.inject.service(),
    modules: Ember.inject.service(),
    modalManager: Ember.inject.service(),
    ajax: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.$('input').focus(function () {
        this.select();
      });
      this.$('input').focus();
      // console.log('modal-screenshot reportMap', get(this, 'model.reportMap'));
    },
    clusterName: Ember.computed('path.currentCategory', 'report.clustersLite', function () {
      var _this = this;
      return Ember.get(this, 'report.clustersLite').find(function (cluster) {
        return Ember.get(cluster, 'id') === Ember.get(_this, 'path.currentClusterId');
      }).name;
    })
  });
});