define("affinio/templates/admin/report-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Pv/TuJkB",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h2\"],[9],[0,\"Global Report Search\"],[10],[0,\"\\n\"],[1,[27,\"admin-list\",null,[[\"isReports\",\"showReports\",\"globalSearch\",\"noDirectShare\",\"sectionSearch\",\"editReports\"],[true,true,true,true,\"reports-search\",[27,\"route-action\",[\"editReports\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/admin/report-search.hbs"
    }
  });
});