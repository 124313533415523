define("affinio/components/preferences/select-inline", ["exports", "affinio/mixins/module-preference"], function (_exports, _modulePreference) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_modulePreference.default, {
    // Element
    tagName: 'div',
    actions: {
      // sets the property defined in `preference.valueKey` as the value passed in.
      setControllerValue: function setControllerValue(value) {
        Ember.set(Ember.get(this, 'controller'), Ember.get(this, 'preference.valueKey'), value);
      }
    }
  });
});