define("affinio/templates/components/modules/audience-share", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "6xlPaNPi",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[22,0,[]]]],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"content\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"model\",\"requestsFulfilled\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"pie-chart\",null,[[\"model\",\"records\",\"type\",\"competitive\",\"hidden\",\"hideAction\",\"width\",\"showLabels\",\"competitiveOrder\",\"skipWidthStyle\"],[[23,[\"model\"]],[23,[\"records\"]],\"competitive\",true,[23,[\"preferences\",\"hiddenOverlaps\"]],[27,\"action\",[[22,0,[]],\"hideHandle\"],null],300,true,[23,[\"model\",\"report\",\"competitiveOrder\"]],true]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/modules/audience-share.hbs"
    }
  });
});