define("affinio/routes/reports/report/version", ["exports", "affinio/utils/affinio"], function (_exports, _affinio) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    path: Ember.inject.service(),
    graph: Ember.inject.service(),
    dataCacher: Ember.inject.service(),
    fetchAlwaysLoadMetrics: function fetchAlwaysLoadMetrics(alwaysLoadMetrics, report, clusters) {
      // console.log(`${alwaysLoadMetrics.blocking ? '' : 'non-'}blocking fetchAlwaysLoadMetrics`, report.id, alwaysLoadMetrics);
      var alwaysLoadMetricInstances = [];
      if (alwaysLoadMetrics.aggregate && alwaysLoadMetrics.aggregate.length) {
        alwaysLoadMetrics.aggregate.forEach(function (metric) {
          return alwaysLoadMetricInstances.pushObject(Ember.get(report, "clusterAggregation.".concat(metric)));
        });
      }
      if (alwaysLoadMetrics.cluster && alwaysLoadMetrics.cluster.length) {
        alwaysLoadMetrics.cluster.forEach(function (metric) {
          return alwaysLoadMetricInstances.pushObjects(clusters.mapBy(metric));
        });
      }
      if (alwaysLoadMetrics.report && alwaysLoadMetrics.report.length) {
        alwaysLoadMetrics.report.forEach(function (metric) {
          return alwaysLoadMetricInstances.pushObject(Ember.get(report, metric));
        });
      }
      Ember.set(report, 'alwaysLoadMetricsLoading', true);
      return Ember.get(this, 'graph').loadMetrics(alwaysLoadMetricInstances).then(function () {
        var sortKey = 'numberOfMembers.response.value';
        if (report.socialNetwork === 'Twitter') {
          if (report._clusters.any(function (n) {
            return n.memberRank.response && n.memberRank.response.value;
          })) {
            sortKey = 'memberRank.response.value';
          }
        } else {
          if (report._clusters.any(function (n) {
            return n.memberRank;
          })) {
            sortKey = 'memberRank';
          }
        }

        // TODO update member rank check to be metric aware
        var sortedClusters = report._clusters.sortBy(sortKey);
        if (sortKey === 'numberOfMembers.response.value') {
          sortedClusters = sortedClusters.reverse();
        }
        Ember.set(report, '_clusters', sortedClusters);
        return Ember.set(report, 'alwaysLoadMetricsLoading', false);
      });
    },
    model: function model(params) {
      var report = this.modelFor('reports.report');
      Ember.set(report, 'currentVersion', params.version_id);
      Ember.set(this, 'path.currentVersion', params.version_id);
      (0, _affinio.setupReportMetricInstances)(this.store, report, params.version_id);
      var alwaysLoadMetrics = Ember.get(report, 'taxonomy.meta.alwaysLoadMetrics');
      if (alwaysLoadMetrics && alwaysLoadMetrics.blocking) {
        return this.fetchAlwaysLoadMetrics(alwaysLoadMetrics, report, Ember.get(report, 'clusters')).then(function () {
          return params.version_id;
        });
      } else {
        return params.version_id;
      }
    },
    afterModel: function afterModel(model, transition) {
      // Load standard report level metrics that are needed at initial load, and could change with versions
      // console.log('loading standard report metrics', this.modelFor('reports.report'));
      var report = this.modelFor('reports.report');
      var clusters = Ember.get(report, 'clusters');
      var alwaysLoadMetrics = Ember.get(report, 'taxonomy.meta.alwaysLoadMetrics');
      if (alwaysLoadMetrics && !alwaysLoadMetrics.blocking) {
        this.fetchAlwaysLoadMetrics(alwaysLoadMetrics, report, clusters);
      }

      // If the taxonomy says you should loadRecordsMap (report is not custom)....
      if (Ember.get(report, 'taxonomy.meta.loadRecordsMap')) {
        Ember.get(this, 'graph').loadMetrics([Ember.get(report, 'recordsCollection') // This is heavy, hence why we do it seperately from everything else
        ]);
      } else {
        // console.log("I dont have loadrecordsMap in my meta and I am gonna get it manually");
        Ember.get(report, 'recordsCollection');
      }

      // If the user didn't enter any ids past version, still get them into the report
      if (transition.targetName === 'reports.report.version.index') {
        // We aren't far enough to do a dep check, so we take first cat/subcat and carry on
        var cat = Ember.get(report, 'taxonomy.schema').objectAt(0);
        var subcat = Ember.get(cat, 'subcategories').objectAt(0);
        this.transitionTo('reports.report.version.cluster.category.subcategory', Ember.get(report, 'id'), model, Ember.get(report, 'clusterAggregation.id'), Ember.get(cat, 'value'), Ember.get(subcat, 'value'));
      }
    }
  });
});