define("affinio/controllers/sign-in", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    queryParams: ['startPasswordReset'],
    startPasswordReset: null,
    email: null,
    auth: Ember.inject.service(),
    startPasswordResetFlow: Ember.observer('startPasswordReset', function () {
      if (Ember.get(this, 'startPasswordReset')) {
        Ember.set(this, 'forgotPassword', true);
        Ember.set(this, 'startPasswordReset', null);
      }
    }),
    actions: {
      submitForgotPassword: function submitForgotPassword() {
        var _this = this;
        var complete = function complete() {
          var forgotPasswordComplete = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
          return Ember.setProperties(_this, {
            forgotPasswordComplete: forgotPasswordComplete,
            working: false
          });
        };
        Ember.set(this, 'working', true);
        Ember.get(this, 'auth').affinioResetPassword(Ember.get(this, 'email')).then(complete).catch(function () {
          return complete(false);
        });
      }
    }
  });
});