define("affinio/components/profile-card", ["exports", "affinio/utils/mutations", "affinio/utils/regex", "affinio/utils/get-with-default"], function (_exports, _mutations, _regex, _getWithDefault) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  var _default = _exports.default = Ember.Component.extend({
    // Element
    // TODO remove tweeter class
    classNames: ['clearfix', 'profile-card'],
    classNameBindings: ['isUnique', 'immune', 'covered', 'isList:list', 'cover:cover', 'initials', 'isHightlited:is-hightlited', 'isCategorized:is-categorized'],
    tagName: 'a',
    attributeBindings: ['customHref:href', 'theTarget:target', 'profileTitle:title', 'style'],
    // Services
    user: Ember.inject.service(),
    preferences: Ember.inject.service(),
    store: Ember.inject.service(),
    path: Ember.inject.service(),
    tracker: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      Ember.set(this, 'instance', {}); // TODO ? needed for metric-instance in hbs
    },
    // Properties
    showScores: true,
    theTarget: '_blank',
    profile: null,
    initials: Ember.computed.not('profile.iconImage'),
    profileTitle: Ember.computed('network', 'profile', 'hoverTitle', function () {
      // if we want the title attribute
      if (!Ember.get(this, 'showTitle')) {
        return null;
      }
      var title;
      switch (Ember.get(this, 'network')) {
        case 'Twitter':
        case 'Instagram':
        case 'Pinterest':
          title = Ember.get(this, 'profile.screenName');
          break;
        case 'Reddit':
          title = Ember.get(this, 'profile.name');
          break;
        case 'Custom':
          title = Ember.get(this, 'profile.label');
      }
      return title;
    }),
    // Computed Properties
    isInstagram: Ember.computed.equal('network', 'Instagram'),
    isPinterest: Ember.computed.equal('network', 'Pinterest'),
    isReddit: Ember.computed.equal('network', 'Reddit'),
    isTwitter: Ember.computed.equal('network', 'Twitter'),
    isList: Ember.computed.equal('view', 'list'),
    isGrid: Ember.computed.equal('view', 'grid'),
    isDelta: Ember.computed.equal('view', 'delta'),
    parsedDescription: Ember.computed('profile.description', function () {
      var description = Ember.get(this, 'profile.description');
      if (description) {
        return description.replace(_regex.xmlTagRegex, '').replace(_regex.lineBreakRegex, '');
      } else {
        return null;
      }
    }),
    isCategorized: Ember.computed('profile.tags.@each', function () {
      return !this.profile.tags || !this.profile.tags.includes('uncategorized');
    }),
    uncategorized: Ember.computed('profile.tags.length', function () {
      var tags = Ember.get(this, 'profile.tags');
      return tags ? tags.includes('uncategorized') : false;
    }),
    isHightlited: Ember.computed('activeInterest', 'activeInterest.screenName', function () {
      return !this.activeInterest || !this.activeInterest.screenName || this.activeInterest.screenName === this.profile.screenName;
    }),
    relevanceStyle: Ember.computed('profile.relevance', function () {
      return Ember.String.htmlSafe("width:".concat(Ember.get(this, 'profile.relevance'), "%"));
    }),
    locationRelevanceStyle: Ember.computed('profile.locationRelevance', function () {
      return Ember.String.htmlSafe("width:".concat(Ember.get(this, 'profile.locationRelevance'), "%"));
    }),
    covered: Ember.computed('profile.bannerImage', function () {
      return Ember.get(this, 'profile.bannerImage') ? true : false;
    }),
    isUnique: Ember.computed('uniqueArray', 'profile', function () {
      if (Ember.get(this, 'uniqueArray')) {
        var nameProxy = 'screenName';
        switch (Ember.get(this, 'network')) {
          case 'Reddit':
            nameProxy = 'networkID';
            break;
          case 'Custom':
            nameProxy = 'networkID';
            break;
        }
        return Ember.get(this, 'uniqueArray').mapBy(nameProxy).includes(Ember.get(this, 'profile')[nameProxy]);
      } else {
        return true;
      }
    }),
    bannerStyle: Ember.computed('network', 'activeInfluencer', function () {
      return Ember.String.htmlSafe("background-color: ".concat('none'));
    }),
    image: Ember.computed.alias('profile.iconImage'),
    standInImage: Ember.computed('profile.iconImage', function () {
      var imageUrl;
      switch (Ember.get(this, 'network')) {
        case 'Twitter':
          imageUrl = '/assets/images/twitter-user.png';
          break;
        case 'Custom':
          imageUrl = '/assets/images/twitter-user.png';
          break;
        default:
      }
      return imageUrl;
    }),
    followHref: Ember.computed('profile.screenName', function () {
      return "https://twitter.com/intent/follow?screen_name=".concat(Ember.get(this, 'profile.screenName'));
    }),
    computedDeltaAffinity: Ember.computed('profile.previousSnapshot', function () {
      var isInfinite = Ember.get(this, 'profile.previousSnapshot.previousAffinity.isInfinite');
      if (isInfinite) {
        return 0;
      } else {
        var delta = Ember.get(this, 'profile.previousSnapshot.previousAffinity.value');
        var value = Ember.get(this, 'profile.affinity.value');
        var diff = value.toFixed(0) - delta.toFixed(0);
        return Math.abs(diff) < 1 ? 0 : diff.toFixed(0);
      }
    }),
    computedDeltaLocalAffinity: Ember.computed('profile.previousSnapshot', function () {
      var isInfinite = Ember.get(this, 'profile.previousSnapshot.previousLocationAffinity.isInfinite');
      var data = Ember.get(this, 'profile.previousSnapshot.previousLocationAffinity');
      if (isInfinite || !data) {
        return 0;
      } else {
        var delta = Ember.get(this, 'profile.previousSnapshot.previousLocationAffinity.value');
        var value = Ember.get(this, 'profile.locationAffinity.value');
        var diff = value.toFixed(0) - delta.toFixed(0);
        return Math.abs(diff) < 1 ? 0 : diff.toFixed(0);
      }
    }),
    computedDeltaRelevance: Ember.computed('profile.previousSnapshot', function () {
      var delta = Ember.get(this, 'profile.previousSnapshot.previousRelevance');
      var value = Ember.get(this, 'profile.relevance');
      var diff = value.toFixed(1) - delta.toFixed(1);
      return diff.toFixed(1);
    }),
    computedDeltaLocationRelevance: Ember.computed('profile.previousSnapshot', function () {
      var delta = Ember.get(this, 'profile.previousSnapshot.previousLocationRelevance');
      if (!delta) {
        return 0;
      }
      var value = Ember.get(this, 'profile.locationRelevance');
      var diff = value.toFixed(1) - delta.toFixed(1);
      return diff.toFixed(1);
    }),
    computedDeltaRank: Ember.computed('profile.previousSnapshot', function () {
      var delta = Ember.get(this, 'profile.previousSnapshot.previousRank');
      var value = Ember.get(this, 'profile.rank');
      var diff = delta - value; // why does this need to be backwards
      // v ----------------------- ^
      // because when relevance# goes up it's good but when rank# goes up it's bad
      // also, to keep us on our toes :) -PR
      return Math.abs(diff) < 1 ? 0 : diff;
    }),
    flattenItems: Ember.computed('items', function () {
      var flattenItems = [];
      this.items.forEach(function (item) {
        flattenItems.push(item);
        if (item.children) {
          item.children.forEach(function (childItem) {
            flattenItems.push(childItem);
          });
        }
      });
      return flattenItems;
    }),
    searchItems: Ember.computed('flattenItems', 'searchText', function () {
      var searchText = this.searchText.toLowerCase();
      var resultItems = this.flattenItems.filter(function (item) {
        if (item.value.includes(searchText)) {
          return item;
        }
      });
      return resultItems;
    }),
    customHref: Ember.computed('network', 'cover', 'profile', function () {
      var network = Ember.get(this, 'network');
      var href;
      if (!Ember.get(this, 'cover')) {
        switch (network) {
          case 'Twitter':
            href = "https://twitter.com/".concat(Ember.get(this, 'profile.screenName'));
            break;
          case 'Instagram':
            href = "https://instagram.com/".concat(Ember.get(this, 'profile.screenName'));
            break;
          case 'Pinterest':
            href = "https://pinterest.com/".concat(Ember.get(this, 'profile.screenName'));
            break;
          default:
            href = Ember.get(this, 'profile.url');
            break;
        }
        return href;
      } else {
        return null;
      }
    }),
    // when color is passed in (for example, in cluster-summary.hbs), assign a border color
    style: Ember.computed('color', function () {
      return Ember.String.htmlSafe("border-color:".concat(Ember.get(this, 'color')));
    }),
    mouseEnter: function mouseEnter(event) {
      Ember.set(this, 'immune', true);
      // The skeleton state has profile being a number (index)
      // O.create() doesn't like this
      var profile = Ember.get(this, 'profile');
      if (_typeof(profile) !== 'object') {
        return;
      }
      var influencer = Ember.Object.create(profile);
      var mousePositionY = event.pageY;
      var whereAmI = mousePositionY - Ember.$(document).scrollTop();
      Ember.set(influencer, 'topSpot', this.$().position().top + this.$().height());
      Ember.set(influencer, 'leftSpot', this.$().position().left);
      if (whereAmI >= Ember.$(window).height() / 2) {
        Ember.set(influencer, 'bottomSpot', Ember.$("".concat((0, _getWithDefault.default)(this, 'parentSelector', ''), " .influencers.chart")).height() - this.$().position().top);
      }
      Ember.set(this, 'activeInterest', influencer);
      Ember.set(this, 'recentInterest', influencer);
    },
    mouseLeave: function mouseLeave(event) {
      var _this = this;
      Ember.set(this, 'interest', null);
      setTimeout(function () {
        if (event.toElement && !event.toElement.closest('.user-box') && _this.activeInterest && _this.activeInterest.screenName === _this.profile.screenName) {
          Ember.set(_this, 'activeInterest', null);
        }
      }, 10);
    },
    actions: {
      forceInitals: function forceInitals() {
        if (Ember.get(this, 'model.requestsFulfilled')) {
          Ember.set(this, 'initials', true);
        }
      },
      selectChildTag: function selectChildTag(childItem) {
        if (childItem.active) {
          Ember.set(this, 'selectedTag', undefined);
          Ember.setProperties(childItem, {
            active: false
          });
        } else {
          // add childItem tag
          Ember.set(this, 'selectedTag', childItem.label);
          Ember.setProperties(childItem, {
            active: true
          });
        }
      },
      submitTags: function submitTags() {
        var _this2 = this;
        Ember.set(this, 'loading', true);
        this.graph.mutate(_mutations.default.createCrowdSourcingTag(this.activeInterest.screenName, this.selectedTag)).then(function () {
          _this2.notify.success('Thank you for making our product better! Your suggestion will be reviewed and applied soon to future reports.');
          Ember.set(_this2, 'showInterestTagsPicker', false);
        }).catch(function (error) {
          var errorMessage = Ember.get(error, 'payload.errors.0.message');
          _this2.notify.error("Error suggesting tag: ".concat(errorMessage));
        }).finally(function () {
          Ember.set(_this2, 'loading', false);
        });
      },
      onTyping: function onTyping(text) {
        Ember.set(this, 'searchText', text);
      }
    }
  });
});