define("affinio/templates/components/affinio-table-cell/affinio-table-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "8Ira5acz",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[27,\"concat\",[\"header\",[27,\"if\",[[23,[\"sorts\",\"0\",\"isAscending\"]],\" ascending\"],null],[27,\"if\",[[27,\"is-equal\",[[23,[\"sorts\",\"0\",\"valuePath\"]],[23,[\"column\",\"valuePath\"]]],null],\" active\"],null]],null]],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"column\",\"canToggleAll\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"custom-checkbox\",null,[[\"checked\",\"checkAction\"],[[23,[\"column\",\"toggledAll\"]],[27,\"action\",[[22,0,[]],[23,[\"toggleAll\"]],[23,[\"column\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[1,[23,[\"column\",\"name\"]],false],[0,\"\\n\"],[4,\"if\",[[23,[\"column\",\"description\"]]],null,{\"statements\":[[4,\"hover-tip\",null,null,{\"statements\":[[0,\"      \"],[7,\"a\"],[12,\"title\",[23,[\"column\",\"description\"]]],[9],[0,\"?\"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[4,\"if\",[[27,\"is-equal\",[[23,[\"sorts\",\"0\",\"valuePath\"]],[23,[\"column\",\"valuePath\"]]],null]],null,{\"statements\":[[4,\"if\",[[23,[\"sorts\",\"0\",\"isAscending\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"font-awesome\",[\"caret-up\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[27,\"font-awesome\",[\"caret-down\"],null],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/affinio-table-cell/affinio-table-header.hbs"
    }
  });
});