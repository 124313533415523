define("affinio/utils/affinio-module", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.affinioModuleDependencyCheck = affinioModuleDependencyCheck;
  function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
  function affinioModuleDependencyCheck(dependencies, context, parent) {
    return dependencies.map(function (dep) {
      if (typeof dep === 'string') {
        return Ember.isPresent(Ember.get(context, "model.".concat(dep)));
      } else {
        var _Object$keys = Object.keys(dep),
          _Object$keys2 = _slicedToArray(_Object$keys, 1),
          contextName = _Object$keys2[0];
        var contextValue = dep[contextName];
        var dependencyMet = false;
        switch (contextName) {
          case 'category':
            {
              var currentCategory = Ember.get(context, 'model.category.value');
              var pathArray = contextValue.includes(',') ? contextValue.replace(/ /g, '').split(',') : [contextValue];
              dependencyMet = pathArray.includes(currentCategory);
              break;
            }
          case 'subcategory':
            {
              var currentSubcategory = Ember.get(context, 'model.subcategory.value');
              var _pathArray = contextValue.includes(',') ? contextValue.replace(/ /g, '').split(',') : [contextValue];
              dependencyMet = _pathArray.includes(currentSubcategory);
              break;
            }
          case 'metric':
            {
              var metric = Ember.get(context, 'metric');
              if (metric) {
                var clusterMetric = Ember.get(context, "model.cluster.".concat(Ember.get(metric, 'label'), ".response"));
                if (clusterMetric) {
                  // TODO this assumes array, not quite scalable yet
                  if (Ember.get(metric, 'defaultValue') === 'array') {
                    dependencyMet = clusterMetric.any(function (item) {
                      // TODO lets make this scalable
                      // benchmarkGroups aggregates data, so we must go deeper and use any
                      if (Ember.get(metric, 'label') === 'benchmarkGroups') {
                        return item.members.any(function (n) {
                          return Ember.isPresent(Ember.get(n, contextValue));
                        });
                      } else {
                        return Ember.isPresent(Ember.get(item, contextValue));
                      }
                    });
                  } else {
                    dependencyMet = Ember.isPresent(clusterMetric);
                  }
                }
              } else {
                dependencyMet = false;
              }
              break;
            }
          case 'not':
            dependencyMet = !Ember.get(context, "model.".concat(contextValue));
            break;
          case 'is':
            dependencyMet = Ember.get(context, "".concat(contextValue));
            break;
          case 'notTaxonomy':
            dependencyMet = Ember.get(context, 'model.report._taxonomy.id') !== contextValue;
            break;
          case 'taxonomy':
            dependencyMet = Ember.get(context, 'model.report._taxonomy.id') === contextValue;
            break;
          case 'lte':
            // TODO come up with a way to make this work for any module not just overlap
            dependencyMet = parent.value <= Ember.get(context, "model.".concat(contextValue)).length - Ember.get(context, dep.minus);
            break;
          case 'minClusters':
            dependencyMet = Ember.get(context, 'model.report.clusters.length') >= contextValue;
            break;
          default:
            // TODO this doesn't seem super useful
            dependencyMet = !!Ember.get(context, "model.".concat(contextName, ".").concat(contextValue));
            break;
        }
        return dependencyMet;
      }
    }).every(Boolean);
  }
});