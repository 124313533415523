define("affinio/models/taxonomies/category", ["exports", "ember-data", "affinio/mixins/dependency-check"], function (_exports, _emberData, _dependencyCheck) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var hasMany = _emberData.default.hasMany;
  var belongsTo = _emberData.default.belongsTo;
  var _default = _exports.default = Model.extend(_dependencyCheck.default, {
    label: attr(),
    value: attr(),
    uneditable: attr(),
    subcategories: hasMany('taxonomies/subcategory', {
      inverse: 'category'
    }),
    taxonomy: belongsTo('taxonomy', {
      inverse: 'schema'
    })
  });
});