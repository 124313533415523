define("affinio/components/user-nav", ["exports", "affinio/utils/mutations"], function (_exports, _mutations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['options', 'user-settings-nav', 'clearfix'],
    classNameBindings: ['open'],
    auth: Ember.inject.service(),
    user: Ember.inject.service(),
    tracker: Ember.inject.service(),
    store: Ember.inject.service(),
    graph: Ember.inject.service(),
    notifications: Ember.computed(function () {
      return Ember.get(this, 'store').peekAll('notification');
    }),
    unreadNotifications: Ember.computed.filterBy('notifications', 'acknowledged', false),
    filteredNotifications: Ember.computed('notifications.@each.dateCreated', function () {
      var sevenDays = new Date();
      sevenDays.setDate(sevenDays.getDate() - 7);
      return Ember.get(this, 'notifications').filter(function (n) {
        if (!Ember.get(n, 'acknowledged')) {
          return true;
        } // unread, display
        else {
          return Ember.get(n, 'dateCreated') >= sevenDays;
        } // read, display if newer than 7 days
      }).sortBy('dateCreated').reverse();
    }),
    isLoggedIn: Ember.computed.alias('auth.loggedIn'),
    hideMe: Ember.observer('isLoggedIn', function () {
      if (!Ember.get(this, 'isLoggedIn')) {
        Ember.set(this, 'isVisible', false);
      }
    }),
    actions: {
      toggleOpen: function toggleOpen() {
        this.toggleProperty('open');
      },
      markAllAsRead: function markAllAsRead() {
        var notifications = Ember.get(this, 'notifications');
        var initialUnread = notifications.filterBy('acknowledged', false).length;
        var tracker = Ember.get(this, 'tracker');
        Ember.get(this, 'graph').mutate(_mutations.default.acknowledgeAllNotifications()).then(function () {
          return notifications.setEach('acknowledged', true);
        }).then(function () {
          return tracker.track('All notifications marked as read', {
            totalMarked: initialUnread
          });
        }).catch(function (e) {
          return tracker.track('All notifications failed to mark as read', {
            error: JSON.stringify(e)
          });
        });
      }
    }
  });
});