define("affinio/templates/components/affinio-table-cell/checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "IKgjbgEx",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"custom-checkbox\",null,[[\"disabled\",\"checked\",\"checkAction\",\"showSuggested\"],[[23,[\"disabled\"]],[27,\"get\",[[23,[\"row\"]],[23,[\"column\",\"valuePath\"]]],null],[27,\"action\",[[22,0,[]],[23,[\"toggleProperty\"]],[23,[\"row\"]],[23,[\"column\",\"valuePath\"]]],null],[23,[\"isUnique\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/affinio-table-cell/checkbox.hbs"
    }
  });
});