define("affinio/controllers/reports/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    queryParams: ['network', 'reportType', 'sourceReport', 'stage', 'taxonomyID'],
    stage: 1,
    app: Ember.inject.service(),
    user: Ember.inject.service()
  });
});