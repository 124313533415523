define("affinio/templates/components/toolbox/toolbox-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "qqF8KZ7c",
    "block": "{\"symbols\":[\"drawer\"],\"statements\":[[4,\"each\",[[23,[\"drawers\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"toolbox/toolbox-drawer\",null,[[\"label\",\"tools\",\"currentlyOpenDrawer\",\"setCurrentlyOpenDrawer\"],[[22,1,[\"label\"]],[22,1,[\"tools\"]],[23,[\"currentlyOpenDrawer\"]],[27,\"action\",[[22,0,[]],\"setCurrentlyOpenDrawer\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/toolbox/toolbox-display.hbs"
    }
  });
});