define("affinio/components/async-button", ["exports", "affinio/utils/get-with-default"], function (_exports, _getWithDefault) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Element
    classNames: ['async-button'],
    tagName: 'button',
    classNameBindings: ['textState'],
    attributeBindings: ['disabled', 'type', 'tabindex'],
    type: 'submit',
    // Display text
    default: 'Save',
    pending: 'Saving...',
    fulfilled: '✔',
    rejected: 'Error',
    // State
    reset: true,
    resetAfter: 1000,
    textState: 'default',
    // Async Action
    action: null,
    // Computed properties
    isDefault: Ember.computed.equal('textState', 'default'),
    disabled: Ember.computed.not('isDefault'),
    text: Ember.computed('textState', 'default', 'pending', 'fulfilled', 'rejected', function () {
      return (0, _getWithDefault.default)(this, Ember.get(this, 'textState'), Ember.get(this, 'default'));
    }),
    resetToDefault: function resetToDefault() {
      if (!this.isDestroyed) {
        Ember.set(this, 'textState', 'default');
      }
    },
    // Lifecycle
    click: function click() {
      var _this = this;
      var action = Ember.get(this, 'action');
      var reset = Ember.get(this, 'reset');
      var resetAfter = Ember.get(this, 'resetAfter');
      if (action && typeof action === 'function') {
        Ember.set(this, 'textState', 'pending');
        action().then(function () {
          if (!_this.isDestroyed) {
            Ember.set(_this, 'textState', 'fulfilled');
            if (reset) {
              Ember.run.later(function () {
                return _this.resetToDefault();
              }, resetAfter);
            }
          }
        }).catch(function () {
          if (!_this.isDestroyed) {
            Ember.set(_this, 'textState', 'rejected');
            if (reset) {
              Ember.run.later(function () {
                return _this.resetToDefault();
              }, resetAfter);
            }
          }
        });
      } else {
        throw new Error('missing passed in action to async-button');
      }
    }
  });
});