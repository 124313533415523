define("affinio/components/cluster-panel", ["exports", "affinio/utils/mutations"], function (_exports, _mutations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['cluster-panel'],
    attributeBindings: ['style'],
    path: Ember.inject.service(),
    graph: Ember.inject.service(),
    notify: Ember.inject.service('notify'),
    tracker: Ember.inject.service(),
    isInput: Ember.computed('network', 'clusterName', function () {
      var inputNetworks = ['Twitter', 'Pinterest', 'Instagram', 'Reddit'];
      return inputNetworks.includes(Ember.get(this, 'network')) ? true : null;
    }),
    placeholderSafe: Ember.computed('placeholder', function () {
      return Ember.String.htmlSafe(Ember.get(this, 'placeholder'));
    }),
    style: Ember.computed('color', function () {
      return Ember.String.htmlSafe("background-color: ".concat(Ember.get(this, 'color'), ";"));
    }),
    // TODO: hrm thought the comp prop would enough :/ -js //wait wtf is this -future js
    nameObserver: Ember.observer('clusterName', function () {
      Ember.set(this, 'inputValue', Ember.String.htmlSafe(Ember.get(this, 'clusterName')));
    }),
    inputValue: Ember.computed('clusterName', function () {
      return Ember.String.htmlSafe(Ember.get(this, 'clusterName'));
    }),
    actions: {
      changeName: function changeName() {
        var _this = this;
        var notify = Ember.get(this, 'notify');
        var graph = Ember.get(this, 'graph');
        var clusterID = Ember.get(this, 'model.cluster.id');
        graph.mutate(_mutations.default.updateClusterName(clusterID, Ember.get(this, 'inputValue'), Ember.get(this, 'network').toLowerCase())).then(function () {
          notify.success("You named this cluster ".concat(Ember.get(_this, 'model.cluster.name')));
          Ember.get(_this, 'tracker').track('cluster name changed', {
            newName: Ember.get(_this, 'inputValue'),
            cluster: clusterID
          });
        }).catch(function (err) {
          notify.error(err.payload.errors[0].message);
        });
      }
    }
  });
});