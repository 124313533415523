define("affinio/routes/admin/toolbox", ["exports", "affinio/mixins/auth-check", "affinio/mixins/document-title", "affinio/utils/taxonomies/toolbox"], function (_exports, _authCheck, _documentTitle, _toolbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_authCheck.default, _documentTitle.default, {
    authOnly: true,
    graph: Ember.inject.service(),
    notify: Ember.inject.service(),
    documentTitle: 'Toolbox - Admin - Affinio',
    model: function model() {
      return _toolbox.default;
    }
  });
});