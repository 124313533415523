define("affinio/templates/components/modules/age-breakdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "wQJewWsL",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[22,0,[]]]],[0,\"\\n\\n\"],[1,[27,\"member-demographics\",null,[[\"type\",\"clusterData\",\"allData\",\"baselineData\",\"tribe\",\"reportName\",\"tribeName\",\"baselineName\",\"tribeCount\",\"skeletonState\",\"cluster\"],[\"age\",[27,\"if\",[[23,[\"metric\",\"loaded\"]],[23,[\"metric\",\"response\"]],[23,[\"mockData\"]]],null],[27,\"if\",[[23,[\"model\",\"report\",\"clusterAggregation\",\"demographics\",\"loaded\"]],[23,[\"model\",\"report\",\"clusterAggregation\",\"demographics\",\"response\"]],[23,[\"mockData\"]]],null],[23,[\"baselineData\"]],[23,[\"model\",\"cluster\",\"suffix\"]],[23,[\"model\",\"report\",\"name\"]],[27,\"if\",[[23,[\"path\",\"inCluster\"]],[23,[\"model\",\"cluster\",\"name\"]],[23,[\"model\",\"report\",\"name\"]]],null],[23,[\"model\",\"report\",\"baselineReport\",\"name\"]],[23,[\"model\",\"report\",\"clusters\",\"length\"]],[27,\"not\",[[23,[\"metric\",\"loaded\"]]],null],[23,[\"model\",\"cluster\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/modules/age-breakdown.hbs"
    }
  });
});