define("affinio/templates/components/heatmap-grid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Y21XlGZs",
    "block": "{\"symbols\":[\"day\",\"hour\"],\"statements\":[[4,\"each\",[[23,[\"records\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"class\",[28,[\"day-of-week \",[22,1,[\"label\"]]]]],[9],[0,\"\\n\"],[4,\"each\",[[22,1,[\"hours\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"heat-block\",null,[[\"record\",\"colorScale\",\"opacityScale\",\"delayScale\",\"class\",\"mouseEnter\",\"mouseLeave\",\"click\"],[[22,2,[]],[23,[\"colorScale\"]],[23,[\"opacityScale\"]],[23,[\"delayScale\"]],[22,2,[\"label\"]],[27,\"action\",[[22,0,[]],\"setHighlightedTime\",[22,2,[]]],null],[27,\"action\",[[22,0,[]],\"clearHighlightedTime\",[22,2,[]]],null],[27,\"action\",[[22,0,[]],\"stickyHighlightedTime\",[22,2,[]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/heatmap-grid.hbs"
    }
  });
});