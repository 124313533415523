define("affinio/utils/custom-data/validate-value-bins", ["exports", "affinio/utils/get-with-default"], function (_exports, _getWithDefault) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateValueBins;
  function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
  /**
  * This is a helper function for the histogram visualization.
  * returns valid labels if bins are valid and false otherwise
  * @param {Array} allValueBins array of value bins
  * @param {Integer} maximumValueBins optional default set to 20
  * @return {Array} empty if invalid bins, otherwise array of labels including gap fill
  */
  function validateValueBins(allValueBins) {
    var maximumValueBins = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 20;
    if (!allValueBins) {
      return [];
    }
    var labels = allValueBins.flatMap(function (valueBins) {
      return valueBins.mapBy('label');
    }).uniq();
    for (var i = 0; i < maximumValueBins; i++) {
      if (labels.length > maximumValueBins) {
        return [];
      }
      labels.sort(function (a, b) {
        return a - b;
      });
      var distances = {};
      var _labels = _slicedToArray(labels, 1),
        previousLabel = _labels[0];
      for (var _i = 1; _i < labels.length; _i++) {
        var label = labels[_i];
        var rawDistance = label - previousLabel;
        if (rawDistance <= 0.01) {
          // numbers are too precises just use a ridgeline
          return [];
        }
        var distance = rawDistance.toFixed(2);
        var count = (0, _getWithDefault.default)(distances, distance, 0);
        distances[distance] = count + 1;
        previousLabel = label;
      }
      // minDistance for gap fill
      var distanceKeys = Object.keys(distances).map(function (n) {
        return parseFloat(n);
      });
      var minDistance = _.min(distanceKeys);
      // equidistant check for labels
      if (distanceKeys.length > 1) {
        // possibility check for gap filling within means
        if ((_.max(labels) - _.min(labels)) / minDistance > maximumValueBins) {
          return [];
        } else {
          // try to fill gaps then re-loop to check again
          var max = _.max(labels);
          var gap = parseFloat(minDistance);
          var _loop = function _loop(_i2) {
            if (labels.find(function (x) {
              return x.toFixed(2) === _i2.toFixed(2);
            }) === undefined) {
              labels.push(_i2);
            }
          };
          for (var _i2 = _.min(labels) + gap; _i2 < max; _i2 = _i2 + gap) {
            _loop(_i2);
          }
        }
      } else {
        return labels;
      }
    }
    return [];
  }
});