define("affinio/utils/queries/taxonomies", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getTaxonomies = _exports.getDataSources = _exports.getContainers = _exports.getAllTaxonomyModels = _exports.getAllTaxonomies = _exports.getAllDataSources = _exports.default = void 0;
  var getAllTaxonomies = _exports.getAllTaxonomies = function getAllTaxonomies() {
    return {
      networkName: 'taxonomiesMeta',
      queryString: "query {\n      taxonomies {\n        creator {\n          id\n        }\n        id\n        label\n        icon\n        runnable\n        canEdit\n        dataSources {\n          label\n          id\n        }\n      }\n    }",
      queryMap: {}
    };
  };
  var getAllTaxonomyModels = _exports.getAllTaxonomyModels = function getAllTaxonomyModels() {
    return {
      networkName: 'allTaxonomies',
      queryString: "query {\n      availableTaxonomies{\n        id\n        label\n        icon\n        accountId\n        dateCreated\n        runnable\n        canEdit\n      }\n    }",
      queryMap: {}
    };
  };
  var getTaxonomies = _exports.getTaxonomies = function getTaxonomies(ids) {
    return {
      networkName: 'someTaxonomies',
      queryString: "query {\n      ".concat(ids.map(function (id, i) {
        return "taxo".concat(i, ":taxonomy (taxonomyID: \"").concat(id, "\") {\n        id\n        label\n        icon\n        runnable\n        canEdit\n        json\n      }");
      }), "\n    }"),
      queryMap: {}
    };
  };
  var getAllDataSources = _exports.getAllDataSources = function getAllDataSources() {
    return {
      networkName: 'dataSourcesAll',
      queryString: "query {\n      availableDataSources{\n        id\n        label\n        dataSetName:dataSet_name\n        columnsCount\n        isActive\n        accountId\n      }\n    }",
      queryMap: {}
    };
  };
  var getDataSources = _exports.getDataSources = function getDataSources(ids) {
    return {
      networkName: 'dataSourcesSpecific',
      queryString: "query {\n      ".concat(ids.map(function (id, i) {
        return "ds".concat(i, ":dataSource (dataSourceID: \"").concat(id, "\") {id label dataSet { id } json taxonomies {id canEdit}}");
      }), "\n    }"),
      queryMap: {}
    };
  };
  var getContainers = _exports.getContainers = function getContainers() {
    return {
      networkName: 'containers',
      queryString: "query {\n      containers {\n        id\n        company {\n          id\n        }\n        containerName\n        containerType\n        items\n        isPrimary\n      }\n    }",
      queryMap: {}
    };
  };
  var _default = _exports.default = {
    getAllTaxonomies: getAllTaxonomies,
    getAllTaxonomyModels: getAllTaxonomyModels,
    getTaxonomies: getTaxonomies,
    getAllDataSources: getAllDataSources,
    getDataSources: getDataSources,
    getContainers: getContainers
  };
});