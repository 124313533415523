define("affinio/utils/taxonomies/twitter", ["exports", "affinio/utils/taxonomies/standard-metric-definitions", "affinio/utils/report-builder/builder-utils", "affinio/utils/report-builder/advanced-modules"], function (_exports, _standardMetricDefinitions, _builderUtils, _advancedModules) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.taxonomy = _exports.default = void 0;
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  var delta = 'delta { label rankDelta valueDelta absoluteRankDelta absoluteValueDelta computedDeltaScore }';
  var previousSnapshotScore = 'previousSnapshot { previousRank previousScore previousRelevance previousAffinity { value isInfinite } isNew previousLocationRelevance previousLocationAffinity { value isInfinite } previousLocationAffinityIsNew }';
  var previousSnapshotValue = 'previousSnapshot { previousRank previousValue previousNormalizedValue isNew }';
  var interestsVisDescription = 'Interests are represented by the handles that these members follow.<br><br><strong>Relevance Score</strong>: a custom calculation for how contextually influential (or relevant) an interest is to a particular cluster (or audience). Relevance takes more factors into consideration than Affinity such as the interest\'s audience size and the number of cluster members who are interested.<br><br><strong>Affinity Score</strong>: a custom calculation that measures how many times more likely members are to have an interest as compared to a broader potential universe. For Twitter, it\'s comparing to the entire social network (Global Affinity) or all other users who live in a specified location (Local Affinity). Affinity can help identify longtail influences.<br><br><a target="_blank" href="https://affinio.zendesk.com/hc/en-us/articles/115001018103-Interest-Scores">See Interest Score Definitions for more information.<i class="fa fa-external-link fa-fw"></i></a>';
  var taxonomy = _exports.taxonomy = {
    id: 'Twitter',
    meta: {
      title: 'Twitter',
      public: true,
      nonPersistable: true,
      colors: {
        primary: '#222',
        secondary: '#f8f9fa'
      },
      loadRecordsMap: true,
      alwaysLoadMetrics: {
        blocking: true,
        report: ['numberOfMembers', 'storedBenchmarkGroups'],
        cluster: ['memberRank', 'numberOfMembers', 'density', 'averagePostsPerMonth', '_interests' // needed for interest relevance on the left side nav. TODO: can we make that a backend prop? would allow for pages to load without interests
        ],
        aggregate: ['numberOfMembers', '_interests' // TODO I don't think this is needed, just for dev
        ]
      }
    },
    builder: {
      label: 'Twitter',
      value: 'twitter',
      color: '#222222',
      description: 'We subdivide <strong>Twitter audiences</strong> into interest-based clusters based on the handles people follow. Twitter reports typically process within <strong>2 hours</strong>. We’ll email you when it’s available.',
      types: [_builderUtils.twitterNetworkGraph, _builderUtils.twitterTweetContent, _builderUtils.twitterUrlShare, _builderUtils.twitterCsvUpload, _builderUtils.talkwalkerIntegration],
      maxNumberOfClusters: 20,
      minNumberOfInstances: _advancedModules.metricDepth.min,
      maxNumberOfInstances: _advancedModules.metricDepth.max,
      runnable: true,
      clusterEstimateMap: [{
        min: 150,
        count: 6
      }, {
        min: 500,
        count: 8
      }, {
        min: 50000,
        count: 10
      }, {
        min: 100000,
        count: 14
      }, {
        min: 500000,
        count: 16
      }, {
        min: 1000000,
        count: 20
      }]
    },
    schema: [{
      label: 'Overview',
      value: 'overview',
      subcategories: [{
        label: 'Summary',
        value: 'summary',
        annotation: {
          description: 'Audience Overview',
          articles: [{
            label: 'A summary of your unique audience',
            appliesTo: ['cmo'],
            description: 'As CMO, I am tasked with expanding our brand into new markets. Understanding the local culture of new prospective markets is key - even when I don’t speak the language. Through Affinio I’m able to understand the interest-based clusters discussing any topic to provide me with an intimate understanding of who they are as people.'
          }, {
            label: 'A Multi-Lingual Approach',
            appliesTo: ['cmo'],
            description: 'As Affinio can run reports on people talking about topics in multiple languages, I want to see the results using Spanish words descriptive of our products as we prepare to expand into LATAM. Through its translation feature, Affinio has returned all the original data to me in English, so I can easily digest the unique interest-based segments discussing products like ours.'
          }, {
            label: 'A summary of your unique audience',
            appliesTo: ['vp insights'],
            description: 'As VP Insights, it is crucial to have a good understanding of the trends impacting my company. Affinio provides an in-depth look into key audiences and crucial information that can be distributed across my organization. Looking at the Overview page gives a true understanding of interest audiences that exist within my brand. These clusters naturally emerge in less than two hours after specifying an audience of interest.'
          }, {
            label: 'A summary of your unique audience',
            appliesTo: ['data scientist'],
            description: 'As a Data Scientist, I am constantly tasked with finding actionable insights within and across our various data sets. By ingesting our loyalty program user lists from Microsoft Dynamics, I immediately notice the unique interest segments formed among our program participants. This insight provides me with a greater understanding of the varied personas behind our brand buyers.'
          }, {
            label: 'A summary of your unique audience',
            appliesTo: ['brand manager'],
            description: 'As a Brand Manager, understanding the unique segments in my audience is a critical step when creating campaigns. Affinio provides an in-depth lens into all the interest-based segments that exist in my audience. This Overview page gives me a high-level snapshot of my audience. Affinio has analyzed the interests of the 157,000 people in my target audience. Affinio’s machine learning naturally clustered my audience based on their interests to provide me with an intimate understanding of who they are as people.'
          }, {
            label: 'A summary of your unique audience',
            appliesTo: ['brand manager'],
            description: 'Clicking into any cluster allows me to start understanding my audience on a much deeper level. From this overview page, I can quickly see everything from their Top Interests and Top Hashtags to Top Bio Keywords of self-description.'
          }]
        },
        modules: [{
          label: 'Audience Details and Source',
          value: 'audience-header',
          dependencies: ['cluster.isAggregate'],
          metrics: [{
            clusterAggregation: '_interests'
          }]
        }, {
          label: 'Audience Visualization',
          value: 'audience-visualization',
          dependencies: ['cluster.isAggregate'],
          metrics: [{
            report: 'clusterSVGURL'
          }]
        }, {
          label: 'Audience Summary',
          value: 'audience-summary',
          dependencies: ['cluster.isAggregate'],
          metrics: [{
            everyCluster: 'lurkerPercentage'
          }, {
            everyCluster: 'density'
          }, {
            everyCluster: '_interests'
          }, {
            report: 'imageAnalysis'
          }, {
            everyCluster: 'averagePostsPerMonth'
          }]
        }, {
          label: 'Baseline Summary',
          value: 'baseline-summary',
          dependencies: ['cluster.isAggregate', 'report.notMonitoredReport']
          // metrics: [{ clusterAggregation: '_interests' }]
        }, {
          label: 'Cluster Summaries',
          value: 'cluster-summaries',
          dependencies: ['cluster.isAggregate'],
          metrics: [{
            everyCluster: 'demographics'
          }],
          description: 'This section provides a consolidated overview of key over-indexing attributes by cluster. Click-through to see more.'
        }, {
          label: 'Top Interests',
          value: 'top-interests',
          metrics: [{
            cluster: '_interests'
          }],
          primaryMetric: 'interests',
          dependencies: ['cluster.isCluster'],
          sliceSize: 100,
          recordsInstanceKey: 'networkID',
          description: 'These represent this cluster\'s most contextually relevant interests as measured by the Relevance score. Highlight Uniques to see which interests are only relevant to this cluster.'
        }, {
          label: 'Hashtags',
          value: 'metric-visualization',
          dependencies: ['cluster.isCluster'],
          metrics: [{
            everyCluster: 'hashtags'
          }],
          primaryMetric: 'hashtags',
          description: 'Most-used hashtags by members of this audience.',
          moduleProperties: {
            defaultBarView: 'niche-ranked'
          }
        }, {
          label: 'Bio Keywords',
          value: 'metric-visualization',
          dependencies: ['cluster.isCluster'],
          metrics: [{
            everyCluster: 'bioKeywords'
          }],
          primaryMetric: 'bioKeywords',
          description: 'These are the top used terms members of this cluster use to describe themselves. The colored bars represent that terms usage in each of the other clusters.',
          moduleProperties: {
            defaultBarView: 'niche-ranked'
          }
        }, {
          label: 'Top Locations',
          value: 'metric-visualization',
          dependencies: ['cluster.isCluster'],
          metrics: [{
            everyCluster: 'locations'
          }],
          primaryMetric: 'locations',
          description: 'We analyze the profiles of every cluster member and plot their locations using a heat map. Darker/highly saturated areas indicate zones where a lot of cluster members live.',
          moduleProperties: {
            defaultBarView: 'niche-ranked'
          }
        }, {
          label: 'Top Shared Links',
          value: 'metric-visualization',
          dependencies: ['cluster.isCluster'],
          metrics: [{
            everyCluster: 'links'
          }],
          primaryMetric: 'links',
          moduleProperties: {
            defaultBarView: 'niche-ranked'
          }
        }]
      }, {
        label: 'Attributes',
        value: 'traits',
        dependencies: ['cluster.isCluster'],
        descriptions: ['These lists show the most and least distinct attributes among all metrics of this cluster.', 'Toggle the attribute type below to view a specific metric type, and click any attribute to dig deeper.'],
        modules: [{
          label: null,
          value: 'page-title'
        }, {
          label: 'Top Attributes',
          value: 'traits-gallery'
        }]
      }, {
        label: 'Deltas',
        value: 'deltas',
        dependencies: ['report.gains', 'report.isNotFirstVersion'],
        modules: [{
          label: 'Audience Change',
          value: 'audience-growth',
          dependencies: ['report.hasAudienceGrowth'],
          metrics: [{
            everyCluster: 'topRankedMetricDelta'
          }]
        }, {
          label: 'Metric Deltas',
          value: 'delta-card-gallery',
          metrics: [{
            everyCluster: 'topRankedMetricDelta'
          }]
        }, {
          label: 'Metric Instances in this Report Period',
          value: 'delta-timeline-gallery',
          metrics: [{
            everyCluster: 'topRankedMetricDelta'
          }]
        }]
      }]
    }, {
      label: 'Members',
      value: 'members',
      subcategories: [{
        label: 'Profiles',
        value: 'profiles',
        modules: [{
          label: 'Members\' Profiles',
          value: 'members-profiles',
          metrics: [{
            everySubCluster: 'followerProfiles'
          }],
          description: 'We provide images for the top 100 high affinity members to help you get an idea of how these users visually represent themselves.'
        }, {
          label: 'Age Breakdown',
          value: 'age-breakdown',
          metricsRequiredForDependency: true,
          metrics: [{
            everyCluster: 'demographics'
          }],
          dependencies: ['report.hasDemographics'],
          description: 'This breakout is determined by applying facial recognition technology to members\' profile images. Users <18 years are excluded. Compare cluster results to the line for the overall audience average.'
        }, {
          label: 'Gender Breakdown',
          value: 'gender-breakdown',
          metricsRequiredForDependency: true,
          metrics: [{
            everyCluster: 'demographics'
          }],
          dependencies: ['report.hasDemographics'],
          description: 'This is identified by applying facial recognition technology to members\' profile images. Compare cluster results to the line for the overall audience average.'
        }, {
          label: 'Bio Keywords',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'bioKeywords'
          }],
          primaryMetric: 'bioKeywords',
          description: 'These are the top used terms members use to describe themselves.',
          moduleProperties: {
            defaultBarView: 'niche-ranked'
          }
        }
        // Twitter API for this info is currently broken; uncomment if they eventually fix this
        // {
        //   label: 'Tweeted From',
        //   value: 'metric-visualization',
        //   metrics: [{ everyCluster: 'apps' }],
        //   primaryMetric: 'apps',
        //   description: 'These are the devices & apps people within this audience use to access Twitter.',
        //   moduleProperties: { defaultBarView: 'niche-ranked' }
        // }
        ],
        annotation: {
          description: 'Members\' Profiles',
          articles: [{
            appliesTo: ['data scientist'],
            description: 'Clicking on Members, I start to understand more about our audiences. Here, I find everything from demographic data such as age and gender - which I understand comes from facial recognition - through to geographic details.'
          }]
        }
      }, {
        label: 'Locations',
        value: 'locations',
        modules: [{
          label: 'Location Heatmap',
          value: 'member-locations',
          metrics: [{
            everyCluster: 'locations'
          }]
        }, {
          label: 'Locations',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'locations'
          }],
          primaryMetric: 'locations',
          description: 'Top Locations of the members',
          moduleProperties: {
            defaultBarView: 'niche-ranked'
          }
        }]
      }, {
        label: 'Engagement',
        value: 'engagement',
        descriptions: ['This graph shows the days and times when members post.'],
        modules: [{
          label: null,
          value: 'page-title'
        }, {
          label: 'Engagement Dashboard',
          value: 'engagement-dashboard',
          metrics: [{
            everyCluster: 'engagements'
          }],
          metricsRequiredForDependency: true,
          dependencies: ['cluster.engagements.response.length']
        }]
      }, {
        label: 'Most Liked',
        value: 'most-liked',
        descriptions: ['These are original tweets of audience or cluster members with the highest favorite count. The top liked tweet for each member is considered.'],
        modules: [{
          label: null,
          value: 'page-title'
        }, {
          noHeader: true,
          label: 'Most Liked Tweets',
          value: 'media-gallery',
          type: 'favorites',
          metrics: [{
            everyCluster: 'favorites'
          }]
        }]
      }, {
        label: 'Most Retweeted',
        value: 'most-retweeted',
        descriptions: ['These are original tweets of audience or cluster members with the highest retweet count. The top retweeted tweet for each member is considered.'],
        modules: [{
          label: null,
          value: 'page-title'
        }, {
          noHeader: true,
          label: 'Most Retweeted Tweets',
          value: 'media-gallery',
          type: 'retweets',
          metrics: [{
            cluster: 'retweets'
          }]
        }]
      }, {
        label: 'Stream',
        value: 'stream',
        descriptions: ['This stream is a random compilation of tweets pulled from the top interests to give you an idea of what a member’s Twitter feed may look like.'],
        modules: [{
          label: null,
          value: 'page-title'
        }, {
          noHeader: true,
          label: 'Interest Tweet Stream',
          value: 'media-gallery',
          type: 'interestTweets',
          metrics: [{
            cluster: '_interests'
          }]
        }]
      }]
    }, {
      label: 'Interests',
      value: 'interests',
      subcategories: [{
        label: 'Interests',
        value: 'index',
        modules: [{
          label: 'Interests',
          value: 'interests-vis',
          type: 'snapshot',
          recordsKey: 'interests',
          recordsInstanceKey: 'networkID',
          metrics: [{
            everyCluster: '_interests'
          }],
          description: interestsVisDescription
        }],
        annotation: {
          description: 'Interests',
          articles: [{
            appliesTo: ['data scientist'],
            description: 'Clicking on Interests gives an overview of the top 1000 things that are of most interest to this audience. I can easily scan through the sub-categories that interest me using the established taxonomy or via search.'
          }, {
            appliesTo: ['vp insights'],
            description: 'Clicking on Interests gives an overview of the top 1000 things that are of most interest to this audience. Here, I can see everything from the brands they love, to celebrities and influencers the follow, to their media choices.'
          }, {
            appliesTo: ['brand manager'],
            description: 'Looking at the Interests tab provides me with an overview of the top 1000 people or things that resonate with this group. Clicking on the Celebrities sub-category allows me to quickly see which influencers matter most to this audience, both mainstream and niche influencers.'
          }, {
            appliesTo: ['brand manager'],
            description: 'The Media sub-category gives great direction when deciding how best to reach this audience by providing me with a shortlist of the outlets that matter most to this audience. This information can be used for advertising (whether directly or as input for my DSP) or as a way to help focus my PR and branded content activities.'
          }]
        }
      }, {
        label: 'Location',
        value: 'location',
        dependencies: ['report.hasLocationInterests', 'report.noRemovedClusters', 'report.noMergedClusters'],
        modules: [{
          label: 'Location Interests',
          value: 'interests-vis',
          type: 'location',
          recordsKey: 'locationInterests',
          recordsInstanceKey: 'networkID',
          description: interestsVisDescription,
          metrics: [{
            everyCluster: '_locationInterests'
          }]
        }],
        annotation: {
          description: 'Location Interests',
          articles: [{
            appliesTo: ['cmo'],
            description: 'Clicking on the Interests allows me to gain a deeper understanding of this audience\'s passions from multiple angles. By selecting the Location option, I can actually sort top affinities unique to this specific region.'
          }]
        }
      }, {
        label: 'Tracked Interests',
        value: 'benchmarks',
        dependencies: ['report.storedBenchmarkGroups.response.length'],
        modules: [{
          label: 'Tracked Interests Groups',
          value: 'interests-vis',
          type: 'custom',
          dependencies: ['report.storedBenchmarkGroups.response.length', 'cluster.benchmarkHasData'],
          description: interestsVisDescription,
          metrics: [{
            everyCluster: '_benchmarkGroups'
          }],
          metricsRequiredForDependency: true,
          recordsKey: 'interests',
          // todo this be wrong I think
          recordsInstanceKey: 'networkID'
        }],
        annotation: {
          description: 'Tracked Interests Groups',
          articles: [{
            appliesTo: ['cmo'],
            description: 'Knowing competition is already in-market, I included competitive tracked interests groups against this audience. Tracked interests groups will let me see in which segments my specified competitors are strong or not.'
          }, {
            appliesTo: ['vp insights'],
            description: 'Tracked interests groups allow me to quickly cross-reference a specified list of accounts whose popularity I want to assess within my audience. In this case, I’ve uploaded a list of key influencers used for our brand to see which clusters they resonate with the most.'
          }]
        }
      }, {
        label: 'Interest Categories',
        value: 'tags',
        modules: [{
          label: 'Interest Categories',
          value: 'interests-vis',
          type: 'tags',
          metrics: [{
            everyCluster: 'interests'
          }],
          primaryMetric: 'interests',
          description: 'These are the top sub-categories with the highest number of tagged interests.',
          moduleProperties: {
            defaultBarView: 'niche-ranked'
          }
        }]
      }]
    }, {
      label: 'Content',
      value: 'content',
      subcategories: [{
        label: 'Topics',
        value: 'topics',
        modules: [{
          label: 'Hashtags',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'hashtags'
          }],
          primaryMetric: 'hashtags',
          description: 'Most-used hashtags by members.',
          moduleProperties: {
            defaultBarView: 'niche-ranked'
          }
        }, {
          label: '@Mentions',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'mentions'
          }],
          primaryMetric: 'mentions',
          description: 'Most-mentioned users by members.',
          moduleProperties: {
            defaultBarView: 'niche-ranked'
          }
        }, {
          label: 'Tracked Hashtags',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'hashtags'
          }],
          primaryMetric: 'trackedHashtags',
          description: 'Top tracked hashtags specified in report creation.',
          dependencies: ['cluster.trackedHashtags.response.length'],
          moduleProperties: {
            defaultBarView: 'niche-ranked'
          }
        }, {
          label: 'Tracked @Mentions',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'mentions'
          }],
          primaryMetric: 'trackedMentions',
          description: 'Top tracked mentions specified in report creation.',
          dependencies: ['cluster.trackedMentions.response.length'],
          moduleProperties: {
            defaultBarView: 'niche-ranked'
          }
        }, {
          label: 'Tweet Keywords',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'keywords'
          }],
          primaryMetric: 'keywords',
          description: 'Most-used words in tweets by members.',
          moduleProperties: {
            defaultBarView: 'niche-ranked'
          }
        }, {
          label: 'Top Emoji',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'emojis'
          }],
          primaryMetric: 'emojis',
          description: 'Most-used emojis by members.',
          moduleProperties: {
            defaultBarView: 'niche-ranked'
          }
        }, {
          label: 'Tracked Keywords',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'keywords'
          }],
          primaryMetric: 'trackedKeywords',
          description: 'Top tracked keywords specified in report creation.',
          dependencies: ['cluster.trackedKeywords.response.length'],
          moduleProperties: {
            defaultBarView: 'niche-ranked'
          }
        }],
        annotation: {
          description: 'Topics',
          articles: [{
            appliesTo: ['cmo'],
            description: 'Navigating to the Content tab lets me see an overview of the content this audience publishes most. Affinio’s automatic translations have converted into English so I can get a better understanding of this foreign market. Clicking on any of the specific content results allows me to pull up the original posts in their native language for more context.'
          }, {
            appliesTo: ['cmo'],
            description: 'After reviewing this report, I now have a better understanding of the new market and the presence of our top competitors there.'
          }, {
            appliesTo: ['brand manager'],
            description: 'The Content tab gives me an overview of all the content this cluster publishes the most. Clicking on any of the items such as a hashtag also provides me with contextual samples of how this content is being used for deeper insight.'
          }, {
            appliesTo: ['brand manager'],
            description: 'Now armed with all the insights and understanding about this Fashionista cluster, I have all the data and confidence to run my new campaigns in a meaningful way to my audience.'
          }]
        }
      }, {
        label: 'Links',
        value: 'links',
        dependencies: ['report.isNotURLShare'],
        modules: [{
          noHeader: true,
          label: 'Links',
          value: 'media-gallery',
          type: 'links',
          metrics: [{
            everyCluster: 'links'
          }]
        }]
      }, {
        label: 'Internal Pages',
        value: 'internal-pages',
        dependencies: ['report.isURLShare'],
        descriptions: ['These are other links, within the same domain as the studied URL(s), which are also shared by the audience or cluster.'],
        modules: [{
          label: null,
          value: 'page-title'
        }, {
          noHeader: true,
          label: 'Internal Pages',
          value: 'media-gallery',
          type: 'links',
          metrics: [{
            cluster: 'links'
          }]
        }]
      }, {
        label: 'External Links',
        value: 'external-links',
        dependencies: ['report.isURLShare'],
        descriptions: ['These are links from domains outside of the studied URL(s) which are shared by the audience or cluster.'],
        modules: [{
          label: null,
          value: 'page-title'
        }, {
          noHeader: true,
          label: 'External Links',
          value: 'media-gallery',
          type: 'links',
          metrics: [{
            cluster: 'links'
          }]
        }]
      }, {
        label: 'Video',
        value: 'videos',
        modules: [{
          noHeader: true,
          label: 'Videos Tweeted',
          value: 'media-gallery',
          type: 'links',
          metrics: [{
            everyCluster: 'links'
          }]
        }]
      }, {
        label: 'Domains',
        value: 'domains',
        modules: [{
          label: 'Domains',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'domains'
          }],
          primaryMetric: 'domains',
          description: 'These are the domains where members of this audience source and share content, extracted from their tweets.',
          moduleProperties: {
            defaultBarView: 'niche-ranked'
          }
        }]
      }]
    }, {
      label: 'Compare',
      value: 'compare',
      subcategories: [{
        label: 'Cluster Compare',
        value: 'index',
        modules: [{
          label: 'Compare Display',
          value: 'compare-display',
          metrics: [{
            cluster: '_interests'
          }]
        }],
        annotation: {
          description: 'Compare',
          articles: [{
            appliesTo: ['vp insights'],
            description: 'Looking at the Compare tab allows me to quickly assess two different clusters for similar and different attributes, teasing out where unique nuances lie.'
          }, {
            appliesTo: ['vp insights'],
            description: 'After looking at all this data, I now understand the unique interests of my target audience and have defensible data to show which influencers resonate most with key segments.'
          }]
        }
      }, {
        label: 'Competitive',
        value: 'competitive-analysis',
        dependencies: ['report.hasCompetitive'],
        modules: [{
          label: 'Audience Share',
          value: 'audience-share',
          metrics: [{
            everyCluster: '_breakdown'
          }]
        }, {
          label: 'Audience Overlap',
          value: 'audience-overlap',
          metrics: [{
            everyCluster: 'overlaps'
          }]
        }]
      }, {
        label: 'Cluster Overlap',
        value: 'cluster-overlap',
        modules: [{
          label: 'Cluster Overlap',
          value: 'cluster-overlap',
          metrics: [{
            report: 'recordsCollection'
          }],
          description: 'This represents how a cluster’s attributes overlap with the attributes of all other clusters in the report. Scores are out of 100%. A relatively high overlap indicates clusters share similar attributes, and a relatively low overlap indicates clusters share few to no similar attributes.'
        }]
      }]
    }, {
      label: 'Actions',
      value: 'actions',
      subcategories: [{
        label: 'Export',
        value: 'export',
        modules: [{
          label: 'Slides Export (.pptx)',
          value: 'powerpoint-export'
          // TODO I suspect not needed
          // metrics: [
          //   { report: 'numberOfClusters' }
          // ]
        }, {
          label: 'Data Downloads (.csv)',
          value: 'csv-exporter',
          metrics: [{
            everyCluster: '_interests'
          }, {
            everyCluster: 'topRankedMetricDelta'
          }, {
            everyCluster: 'activity'
          }, {
            report: 'recordsCollection'
          }]
        }, {
          label: 'Screenshots Export',
          value: 'screenshots-exporter'
        }, {
          label: 'Network Graph Vector (.svg)',
          value: 'network-graph-export',
          description: 'This image has a transparent background and maintains its resolution when resized. In the event your presentation software does not support it, try making it into a PNG file using an online converter.',
          metrics: [{
            report: 'clusterSVGURL'
          }]
        }]
      }, {
        label: 'Advertise',
        value: 'advertise',
        // TODO lets make page level dependencies be anything rather than report scope
        // when you fix this, remove user service from twitter report model - JK
        // TODO still need to do this taxo
        dependencies: ['report.user.company.hasTwitterAdvertising'],
        modules: [{
          noHeader: true,
          label: null,
          value: 'activation-dashboard',
          metrics: [{
            report: 'clusterSVGURL'
          }]
        }]
      }, {
        label: 'Segment a Cluster',
        value: 'segment',
        modules: [{
          noHeader: true,
          label: 'Segment Dashboard',
          value: 'segment-dashboard',
          metrics: [{
            report: 'clusterSVGURL'
          }]
        }, {
          label: null,
          value: 'segment-audience-builder'
        }]
      }, {
        label: 'Monitor this Audience',
        value: 'monitor',
        dependencies: ['report.notHasEditedDate', 'report.notHasExclusionFilters', 'report.noRemovedClusters', 'report.noMergedClusters', 'report.notNewFollowersOnly', 'report.notQueryBased'],
        modules: [{
          label: 'Monitor This Audience',
          value: 'monitor-dashboard',
          metrics: [{
            report: 'storedBenchmarkGroups'
          }]
        }]
      }, {
        label: 'Modify Report',
        value: 'modify-report',
        dependencies: ['report.notMonitoredReport', 'report.hasMoreThanSingleCluster'],
        modules: [{
          label: null,
          value: 'cluster-modifier'
        } // ,
        // {
        //   label: 'Remove Cluster',
        //   value: 'remove-cluster'
        // },
        // {
        //   label: 'Re-Add Cluster',
        //   value: 're-add-cluster',
        //   dependencies: ['report.hasRemovedClusters']
        // }
        ]
      }, {
        label: 'Integrate',
        value: 'integrate',
        dependencies: ['report.notMonitoredReport'],
        modules: [{
          label: null,
          value: 'talkwalker-integration'
        }]
      }, {
        label: 'Translate',
        value: 'translate',
        dependencies: ['report.user.account.canTranslate'],
        modules: [{
          label: 'Report Translation',
          value: 'translate-dashboard',
          metrics: [{
            report: 'clusterSVGURL'
          }] // TODO: arbitrary but unnecessary thing to get it to not spin on refresh
        }]
      }]
    }],
    exportSchema: [{
      exportType: 'members',
      metrics: ['followerProfiles'],
      clusterOnly: true
    }, {
      exportType: 'interest',
      metrics: ['_interests'],
      intersectionable: true
    }, {
      exportType: 'interest',
      metrics: ['_locationInterests']
    }, {
      exportType: 'benchmarks-all',
      metrics: ['_benchmarkGroups'],
      label: 'All Tracked Interests'
    }, {
      exportType: 'benchmarks-all-group',
      metrics: ['_benchmarkGroups'],
      label: 'All Tracked Interests Groups'
    }, {
      exportType: 'tags',
      metrics: ['_interests'],
      label: 'Interest Categories'
    }, {
      exportType: 'clusterOverview',
      unaggregateable: true,
      aggregateOnly: true,
      metrics: ['density'],
      // TODO this needs more
      label: 'Audience Summary'
    }, {
      metrics: ['bioKeywords']
    }, {
      metrics: ['hashtags']
    }, {
      metrics: ['locations']
    }, {
      metrics: ['domains']
    }, {
      metrics: ['mentions']
    }, {
      metrics: ['keywords']
    },
    // Twitter API for this info is currently broken; uncomment if they eventually fix this
    // {
    //   metrics: ['apps']
    // },
    {
      metrics: ['emojis']
    }, {
      metrics: ['links']
    }, {
      exportType: 'breakdown',
      metrics: ['_breakdown']
    }, {
      exportType: 'rankedDelta',
      metrics: ['topRankedMetricDelta'],
      unaggregateable: true
    }, {
      metrics: ['overlaps'],
      exportLabelAlias: 'handles',
      exportValueAlias: 'followers'
    }, {
      metrics: ['trackedHashtags'],
      label: 'Tracked Hashtags'
    }, {
      metrics: ['trackedMentions'],
      label: 'Tracked @Mentions'
    }, {
      metrics: ['trackedKeywords'],
      label: 'Tracked Keywords'
    }, {
      exportType: 'topicExplorer',
      metrics: ['topicExplorer'],
      unaggregateable: true,
      aggregateOnly: true
    }, {
      exportType: 'clusterOverlap',
      metrics: [],
      label: 'Cluster Overlap',
      unaggregateable: true,
      aggregateOnly: true
    }],
    metricDefinitions: [].concat(_toConsumableArray(_standardMetricDefinitions.default), [{
      label: 'memberRank',
      level: 'cluster',
      properties: [],
      defaultValue: 'number'
    }, {
      title: 'Members\' Profiles',
      label: 'followerProfiles',
      graphQLType: 'TwitterFollowerProfile',
      description: 'profiles of the members of this cluster',
      properties: ['id', 'friendsCount', 'description', 'name', 'screenName', 'statusesCount', 'profileImageURL', 'followersCount', 'location', 'locationExact', 'profileImageURLHTTPS', 'profileImage', 'url', 'timeZone', 'lastPost { id dateCreated }', 'isVerified', 'isMostLiked', 'isMostTweeted', 'clusterID'],
      defaultValue: 'array',
      level: 'cluster',
      icon: 'user'
    }, {
      label: 'interests',
      modelAlias: '_interests',
      graphQLType: 'Interest',
      title: 'Interests',
      description: 'Categories of Top Influencers from the Interests section',
      properties: ['affinity { value isInfinite }', 'description', 'followersCount', 'friendsCount', 'headerImage', 'iconImage', 'screenName', 'lastPost { id dateCreated }', 'posts { id }', 'name', 'label:name', 'networkID', 'rank', 'relevance', 'score', 'tags', previousSnapshotScore],
      defaultValue: 'array',
      traitWeight: 0.1,
      level: 'cluster',
      comparable: true,
      icon: 'star',
      metricIcon: '/assets/images/metric-icons/emoji.png'
    }, {
      label: 'locationInterests',
      title: 'Location Interests',
      modelAlias: '_locationInterests',
      graphQLType: 'LocationInterests',
      // NOTE this isn't the actual type in graphql schema, just wanted this to be fetched differently than interests to get the location_ aliases
      properties: ['locationAffinity:affinity { value isInfinite }', 'description', 'followersCount', 'friendsCount', 'headerImage', 'iconImage', 'screenName', 'lastPost { id dateCreated }', 'name', 'label:name', 'networkID', 'rank', 'locationRelevance:relevance', 'score', 'tags', previousSnapshotScore],
      defaultValue: 'array',
      level: 'cluster',
      comparable: true,
      icon: 'star',
      traitWeight: 0,
      metricIcon: '/assets/images/metric-icons/emoji.png'
    }, {
      label: 'benchmarkGroups',
      modelAlias: '_benchmarkGroups',
      title: 'All Tracked Interests Groups',
      graphQLType: 'BenchmarkGroup',
      properties: ['name', 'id', 'members { name screenName label:name rank relevance affinity { value isInfinite } networkID description followersCount friendsCount iconImage headerImage bannerImage color tags lastPost { id } score verified previousSnapshot { previousRank previousScore previousRelevance isNew previousAffinity { value isInfinite } } locationRelevance locationAffinity { value isInfinite } }'],
      level: 'cluster',
      defaultValue: 'array'
    }, {
      label: 'storedBenchmarkGroups',
      graphQLType: 'storedBenchmarkGroup',
      properties: ['name', 'members'],
      defaultValue: 'array',
      level: 'report'
    }, {
      title: 'Bio Keywords',
      label: 'bioKeywords',
      graphQLType: 'LabelValueSaturationsDelta',
      description: 'These are the top used terms members of this audience use to describe themselves.',
      properties: ['label', 'value', delta, previousSnapshotValue],
      defaultValue: 'array',
      level: 'cluster',
      comparable: true,
      icon: 'user',
      metricIcon: '/assets/images/metric-icons/bio-keywords.png'
    }, {
      label: 'hashtags',
      title: 'Hashtags',
      graphQLType: 'LabelValueTimesIDsSaturations',
      description: 'Most-used hashtags by members of this audience',
      properties: ['label', 'value', 'times{date value}', 'ids', delta, previousSnapshotValue],
      defaultValue: 'array',
      traitWeight: 0.5,
      icon: 'hashtag',
      level: 'cluster',
      comparable: true,
      metricIcon: '/assets/images/metric-icons/hashtag.png'
    }, {
      label: 'locations',
      title: 'Locations',
      graphQLType: 'Location',
      description: 'Top Locations of the members of this audience',
      properties: ['label', 'value', 'coordinates{latitude longitude}', delta, previousSnapshotValue],
      level: 'cluster',
      defaultValue: 'array',
      comparable: true,
      icon: 'map-marker',
      metricIcon: '/assets/images/metric-icons/locations.png'
    }, {
      label: 'domains',
      title: 'Domains',
      graphQLType: 'LabelValueSaturationsDelta',
      properties: ['label', 'value', delta, previousSnapshotValue],
      description: 'These are the domains where members of this audience source and share content, extracted from their tweets',
      defaultValue: 'array',
      traitWeight: 1,
      level: 'cluster',
      comparable: true,
      icon: 'globe',
      metricIcon: '/assets/images/metric-icons/links.png'
    }, {
      label: 'mentions',
      title: '@Mentions',
      graphQLType: 'LabelValueTimesIDsSaturations',
      properties: ['label', 'value', 'times{date value}', 'ids', delta, previousSnapshotValue],
      description: 'Most-mentioned users by members of this audience',
      defaultValue: 'array',
      traitWeight: 0.5,
      level: 'cluster',
      comparable: true,
      icon: 'at',
      metricIcon: '/assets/images/metric-icons/at.png'
    }, {
      label: 'keywords',
      title: 'Tweet Keywords',
      graphQLType: 'LabelValueTimesIDsSaturations',
      description: 'Most-used words in tweets by members of this audience',
      properties: ['label', 'value', 'times{date value}', 'ids', delta, previousSnapshotValue],
      defaultValue: 'array',
      traitWeight: 0.5,
      level: 'cluster',
      icon: 'commenting',
      metricIcon: '/assets/images/metric-icons/keywords.png'
    },
    // Twitter API for this info is currently broken; uncomment if they eventually fix this
    // {
    //   title: 'Tweeted From',
    //   label: 'apps',
    //   graphQLType: 'LabelValueSaturationsDelta',
    //   description: 'These are the devices & apps people within this audience use to access Twitter',
    //   properties: ['label', 'value', delta, previousSnapshotValue],
    //   defaultValue: 'array',
    //   traitWeight: 0,
    //   level: 'cluster',
    //   icon: 'commenting'
    // },
    {
      label: 'emojis',
      title: 'Top Emojis',
      graphQLType: 'LabelValueTimesIDsSaturations',
      properties: ['label', 'value', 'times{date value}', 'ids', delta, previousSnapshotValue],
      description: 'Most-used emojis by members of this audience',
      defaultValue: 'array',
      traitWeight: 1,
      level: 'cluster',
      comparable: true,
      icon: 'smile-o',
      metricIcon: '/assets/images/metric-icons/emoji.png'
    }, {
      label: 'links',
      title: 'Top Links',
      graphQLType: 'Link',
      properties: ['label', 'value', 'mediaURL', 'mediaType', 'embedType', 'title', 'description', 'type', 'image'],
      description: 'Most popular links for this cluster.',
      defaultValue: 'array',
      traitWeight: 0.5,
      level: 'cluster',
      icon: 'globe',
      metricIcon: '/assets/images/metric-icons/links.png'
    }, {
      label: 'favorites',
      title: 'Top Favorites',
      properties: ['id', 'value', 'rank'],
      description: 'Top Favorited tweets by members of this audience',
      icon: 'star',
      sliceSize: 50,
      graphQLType: 'IDValueRank',
      defaultValue: 'array',
      level: 'cluster',
      traitWeight: 0
    }, {
      label: 'retweets',
      title: 'Top Retweets',
      properties: ['id', 'value', 'rank'],
      icon: 'star',
      sliceSize: 50,
      graphQLType: 'IDValueRank',
      defaultValue: 'array',
      level: 'cluster',
      traitWeight: 0,
      description: 'Top Retweeted tweets by members of this audience'
    }, {
      title: 'Demographics',
      label: 'demographics',
      graphQLType: 'Demographics',
      properties: ['female1824', 'female2534', 'female3544', 'female4554', 'female5564', 'female65', 'femaleTotal', 'male1824', 'male2534', 'male3544', 'male4554', 'male5564', 'male65', 'maleTotal', 'total1824', 'total2534', 'total3544', 'total4554', 'total5564', 'total65', 'genderMax', 'ageMax', 'totalFaces'],
      defaultValue: 'object',
      level: 'cluster',
      icon: 'star',
      initialVersionOnly: true
    }, {
      label: 'breakdown',
      modelAlias: '_breakdown',
      title: 'Audience Share',
      graphQLType: 'Breakdown',
      properties: ['size', 'users { id screenName followerCount}'],
      defaultValue: 'array',
      level: 'cluster'
    }, {
      title: 'Engagements',
      label: 'engagements',
      icon: 'newspaper-o',
      graphQLType: 'Engagement',
      traitWeight: 0,
      properties: ['dateTime', 'value'],
      defaultValue: 'array',
      level: 'cluster'
    }, {
      label: 'topRankedMetricDelta',
      graphQLType: 'TopRankedMetricDelta',
      title: 'Top Metric Deltas',
      description: 'Metrics that go uppy downy.',
      properties: ['label', 'type', 'origin {label value ids rank times { date value } previousSnapshot { previousRank previousValue previousNormalizedValue isNew} delta { label rankDelta valueDelta absoluteRankDelta absoluteValueDelta computedDeltaScore}}'],
      level: 'cluster',
      defaultValue: 'array'
    }, {
      label: 'lurkerPercentage',
      properties: [],
      defaultValue: 'number',
      level: 'cluster'
    }, {
      label: 'activity',
      graphQLType: 'Activity',
      properties: ['mean', 'sum'],
      defaultValue: 'object',
      level: 'cluster'
    }, {
      label: 'overlaps',
      title: 'Audience Overlap',
      graphQLType: 'LabelIntValue',
      properties: ['label', 'value'],
      traitWeight: 0,
      defaultValue: 'array',
      level: 'cluster'
    }])
  };
  var _default = _exports.default = {
    taxonomy: taxonomy
  };
});