define("affinio/templates/components/top-level-nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "OO8ImeYt",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\"],[11,\"id\",\"campaign-upper\"],[9],[0,\"\\n  \"],[7,\"header\"],[11,\"class\",\"campaign-header\"],[9],[0,\"\\n    \"],[1,[21,\"macro-menu\"],false],[0,\"\\n    \"],[7,\"nav\"],[11,\"class\",\"tabs\"],[9],[0,\"\\n      \"],[4,\"link-to\",[\"reports\"],null,{\"statements\":[[0,\"Reports\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"app\",\"ENV\",\"features\",\"custom\"]]],null,{\"statements\":[[0,\"        \"],[4,\"link-to\",[\"aton\"],null,{\"statements\":[[0,\"Configure\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[4,\"link-to\",[\"settings\"],null,{\"statements\":[[0,\"Settings\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"user\",\"account\",\"isAdmin\"]]],null,{\"statements\":[[0,\"        \"],[4,\"link-to\",[\"admin\"],[[\"class\"],[\"admin\"]],{\"statements\":[[0,\"Admin\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"user\",\"account\",\"isInAffinioCompany\"]]],null,{\"statements\":[[0,\"        \"],[4,\"link-to\",[\"search\"],null,{\"statements\":[[0,\"Search\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[4,\"zendesk-link\",null,[[\"path\"],[\"/\"]],{\"statements\":[[0,\"Help\"]],\"parameters\":[]},null],[0,\"\\n    \"],[10],[0,\"\\n    \"],[1,[27,\"user-nav\",null,[[\"open\"],[[23,[\"userNavOpen\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/top-level-nav.hbs"
    }
  });
});