define("affinio/components/modules/audience-visualization", ["exports", "affinio/mixins/affinio-module"], function (_exports, _affinioModule) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_affinioModule.default, {
    init: function init() {
      this._super.apply(this, arguments);
      Ember.set(this, 'preferences.audienceVizBg', 'black');
    },
    // Element
    classNames: ['audience-visualization'],
    tagName: 'div',
    // Services
    user: Ember.inject.service(),
    tracker: Ember.inject.service(),
    preferences: Ember.inject.service(),
    // Properties
    svgURI: '',
    showLabels: Ember.computed.alias('preferences.showVisualizationLabels'),
    downloadName: Ember.computed('model.report.name', function () {
      return "".concat(Ember.get(this, 'model.report.name').dasherize(), "-network-graph.svg");
    }),
    visualizationType: Ember.computed('processedModuleSchema', function () {
      return Ember.get(this, 'processedModuleSchema.preferences').findBy('value', 'select-inline').options[0].value;
    }),
    description: Ember.computed('descriptions', 'visualizationType', function () {
      return Ember.get(this, "descriptions.".concat(Ember.get(this, 'visualizationType')));
    }),
    hideNetworkDropDown: Ember.computed('visualizationType', function () {
      return Ember.get(this, 'visualizationType') !== 'network-graph';
    }),
    showLabelPreference: Ember.computed('hideNetworkDropDown', function () {
      return !Ember.get(this, 'hideNetworkDropDown');
    }),
    descriptions: Ember.computed('model.report.socialNetwork', function () {
      return {
        'network-graph': 'This diagram represents the different clusters within this audience. The lines connecting clusters indicate their shared interests. More lines = more in common.',
        'audience-size': 'This chart shows the size of each cluster within the total audience. Hovering on a slice of the pie will show you the percentage of people within each cluster.'
      };
    }),
    actions: {
      updateSvgURI: function updateSvgURI(uri) {
        this.toggleProperty('forceUpdate');
        Ember.set(this, 'svgURI', uri);
      }
    }
  });
});