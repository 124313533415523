define("affinio/templates/components/icon-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "caO10riN",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[27,\"file-upload\",null,[[\"accept\",\"successAction\",\"parseData\"],[\"image/*\",[27,\"action\",[[22,0,[]],\"imageLoaded\"],null],false]]],false],[0,\"\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/icon-upload.hbs"
    }
  });
});