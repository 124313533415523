define("affinio/components/loading-animation", ["exports", "affinio/utils/affinio"], function (_exports, _affinio) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    preferences: Ember.inject.service(),
    tagName: 'svg',
    width: 800,
    height: 650,
    smallWindowHeightCutoff: 830,
    smallWindowHeight: 500,
    didInsertElement: function didInsertElement() {
      var _this = this;
      // console.log('initializing loader');

      var smallWindow = Ember.$(window).height() < Ember.get(this, 'smallWindowHeightCutoff');
      if (smallWindow) {
        Ember.set(this, 'height', Ember.get(this, 'smallWindowHeight'));
      }
      var width = Ember.get(this, 'width');
      var height = Ember.get(this, 'height');
      var color;
      if (Ember.get(this, 'preferences.categoricalColors') && Ember.get(this, 'preferences.categoricalColors') !== 'Standard Categorical') {
        color = d3.scale.ordinal().domain(d3.range(20)).range(Ember.get(this, 'preferences.categoricalSets').findBy('name', Ember.get(this, 'preferences.categoricalColors')).colors);
      } else {
        color = d3.scale.category20().domain(d3.range(20));
      }
      var nodes = d3.range(5000).map(function (i) {
        return {
          index: i,
          x: (Math.random() - 0.5) * 400 + width / 2,
          y: -10
        }; // map
      });
      var svg = d3.select(this.$()[0]).attr('width', width).attr('viewBox', "0 0 ".concat(width, " ").concat(height));

      // If the user is in the stone age and has a small browser window, we will move the filter
      // up a little so they can experience our loading screen magic
      // -JK June 2016
      if (smallWindow) {
        var filtration = svg.select('.loader-filter');
        filtration.attr('transform', "".concat(filtration.attr('transform'), " translate(0, -300)"));
      }

      // set(this, 'numClusters', App.TheCampaign.get('cluster_ids').get('length'))
      var numClusters = Math.floor(Math.random() * 6) + 3;
      var nodeGroup = svg.select('.loader-nodes');
      var node = nodeGroup.selectAll('.node').data(nodes).enter().append('circle').attr('class', 'node').attr('r', function () {
        return parseInt(Math.random() * 4 + 2);
      }).attr('opacity', 0.5).attr('cx', function (obj) {
        return obj.x;
      }).attr('cy', function (obj) {
        return obj.y;
      }).style('fill', function (d) {
        return color(d.index % numClusters);
      });

      // Yep, appending it twice.
      // Just the first: layer too low. Nodes show up above it.
      // Just the second: layer takes too long. Nodes run before it loads.
      // Both: juuuuuuuuuust right.
      // -PR, March 2015. During a blizzard.

      // To solve the funnel loading after nodes we can just use the svg as a data URI.
      // Initially I tried b64 encoding but that increased the size significantly.
      // Luckily svg doesn't have any crazy characters, neat eh - JK, July 2015. During some fog.

      // Its raining today and I was cleaning up the code in this file so I figured I should comment
      // -JK, Feb 2016

      // Filter is now inline in the component rendered ontop of the loader-nodes group,
      // which is where d3 now renders the nodes.
      // Also added viewBox attr so if we want to we can make this baby scale
      // with the browser window.
      // -RS, May 2016. During the wee hours
      (0, _affinio.maybeRunLater)(this, function () {
        Ember.set(_this, 'svg', svg);
        Ember.set(_this, 'numClusters', numClusters);
        Ember.set(_this, 'nodes', node);
      });
      (0, _affinio.maybeRunLater)(this, function () {
        _this.loaderStep1();
      }, 500);
    },
    // didInsertElement
    loaderStep1: function loaderStep1() {
      var _this2 = this;
      var smallWindow = Ember.$(window).height() < Ember.get(this, 'smallWindowHeightCutoff');
      if (!Ember.get(this, 'errorState')) {
        // console.log('loaderStep');
        Ember.get(this, 'nodes').transition().delay(function (obj, iter) {
          return iter * 5;
        }).duration(500).attr('cy', function () {
          return smallWindow ? 305 : 400;
        }) // magic numbers, deal with it
        .attr('cx', function () {
          return Ember.get(_this2, 'width') / 2;
        }).transition().delay(function (obj, iter) {
          return iter * 5 + 500;
        }).duration(1500).attr('cy', function (obj, iter) {
          return Ember.get(_this2, 'height') - 10 + (Math.random() - 0.5) * 10 - iter * 0.02;
        }).attr('cx', function (obj, iter) {
          return iter % Ember.get(_this2, 'numClusters') * (700 / Ember.get(_this2, 'numClusters')) + (Ember.get(_this2, 'width') / 2 - 350 / Ember.get(_this2, 'numClusters') * (Ember.get(_this2, 'numClusters') - 1)) + (Math.random() - 0.5) * 600 / Ember.get(_this2, 'numClusters');
        });
        // // Optional: Continuance
        // .transition()
        // .delay(function(obj,iter){ return iter * 5 + 5000 })
        // .attr('cy', 1200)
      } // if no errorState
    },
    // loaderStep1
    becomeDepressed: Ember.observer('errorState', function () {
      var _this3 = this;
      // console.log('becoming depressed :(');
      // console.log(get(this, 'errorState'));

      var radius = 250;
      Ember.get(this, 'nodes').transition().delay(function (obj) {
        obj.angle = Math.random() * Math.PI * 2;
        return Math.random() * Ember.get(_this3, 'nodes')[0].length;
      }).duration(1500).attr('cy', function (obj, iter) {
        if (iter % 4 === 2) {
          return 440 - Math.sin(obj.angle) * radius * 0.2 + Math.random() * 10;
        } else if (iter % 4 === 3) {
          return 350 - Math.sin(obj.angle) * radius * 0.65 + Math.random() * 10;
        } else if (iter % 4 === 1) {
          return 320 + (Math.sin(obj.angle) - 0.5) * (Math.random() * radius) * 0.05;
        } else if (iter % 4 === 0) {
          return 320 + (Math.sin(obj.angle) - 0.5) * (Math.random() * radius) * 0.05;
        }
      }).attr('opacity', function (obj, iter) {
        return iter % 4 === 2 && (obj.angle < 0.3 || obj.angle > 2.8) ? 0 : Math.random();
      }).attr('cx', function (obj, iter) {
        // console.log(self.get('width')/2);
        if (iter % 4 === 2) {
          return Ember.get(_this3, 'width') / 2 + Math.cos(obj.angle) * radius * 0.3 + Math.random() * 10;
        } else if (iter % 4 === 3) {
          return Ember.get(_this3, 'width') / 2 + Math.cos(obj.angle) * radius * 0.65 + (Math.random() * 10 - 5);
        } else if (iter % 4 === 1) {
          return Ember.get(_this3, 'width') / 2 - 60 + (Math.cos(obj.angle) - 0.5) * (Math.random() * radius) * 0.05;
        } else if (iter % 4 === 0) {
          return Ember.get(_this3, 'width') / 2 + 60 + (Math.cos(obj.angle) - 0.5) * (Math.random() * radius) * 0.05;
        }
        // return 150;
        // return (iter%self.get('numClusters') * (700/self.get('numClusters'))) + (self.get('width')/2-((350/self.get('numClusters'))*(self.get('numClusters')-1))) + (Math.random() - .5) * 600/self.get('numClusters')
      });
      Ember.$('.loader-filter').hide(300);
    })
  });
});