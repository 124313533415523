define("affinio/components/interest-cluster-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    preferences: Ember.inject.service(),
    attributeBindings: ['style'],
    classNames: ['influencer-cluster-bar'],
    style: Ember.computed('gradient', function () {
      return Ember.String.htmlSafe("background: linear-gradient(to right, ".concat(Ember.get(this, 'gradient'), ")"));
    }),
    // style
    didReceiveAttrs: function didReceiveAttrs() {
      this.initializeStats();
    },
    // didReceiveAttrs
    initializeStats: function initializeStats() {
      var check = Ember.get(this, 'check');
      var color;
      if (Ember.get(this, 'preferences.categoricalColors') && Ember.get(this, 'preferences.categoricalColors') !== 'Standard Categorical') {
        color = d3.scale.ordinal().domain(d3.range(20)).range(Ember.get(this, 'preferences.categoricalSets').findBy('name', Ember.get(this, 'preferences.categoricalColors')).colors);
      } else {
        color = d3.scale.category20().domain(d3.range(20));
      }
      var values = check;
      var total = _.reduce(_.pluck(values, 'normalizedScore'), function (memo, num) {
        return parseFloat(memo) + parseFloat(num);
      }); // reduce
      var values2 = _.map(values, function (obj, i) {
        return {
          'value': obj.normalizedScore,
          'width': obj.normalizedScore / total * 100,
          'cluster': obj.cluster,
          'spot': i,
          'color': color(i)
        };
      }); // map

      var str = '';
      var pct = 0;
      values2.forEach(function (obj) {
        str += "".concat(obj.color, " ").concat(pct, "%,");
        pct += obj.width;
        str += "".concat(obj.color, " ").concat(pct, "%,");
      });
      Ember.set(this, 'gradient', str.slice(0, -1));
    } // initializeStats
  });
});