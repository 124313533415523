define("affinio/templates/components/modals/modal-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "xRp58jiS",
    "block": "{\"symbols\":[],\"statements\":[[7,\"form\"],[11,\"class\",\"modal modal-text\"],[11,\"tabindex\",\"0\"],[9],[0,\"\\n\"],[4,\"unless\",[[23,[\"noClose\"]]],null,{\"statements\":[[0,\"    \"],[7,\"a\"],[11,\"class\",\"close-modal\"],[11,\"href\",\"#\"],[9],[0,\"×\"],[3,\"action\",[[22,0,[]],\"close\"]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"title\"]]],null,{\"statements\":[[0,\"    \"],[7,\"h3\"],[11,\"class\",\"modal-sub-nav-header\"],[9],[1,[21,\"title\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[1,[27,\"html-safe\",[[23,[\"message\"]]],null],false],[0,\"\\n\"],[4,\"if\",[[23,[\"okAction\"]]],null,{\"statements\":[[0,\"    \"],[7,\"footer\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"okAction\"]]],null,{\"statements\":[[0,\"        \"],[7,\"button\"],[11,\"class\",\"button primary\"],[11,\"type\",\"submit\"],[9],[0,\"\\n          \"],[1,[27,\"if\",[[23,[\"okText\"]],[23,[\"okText\"]],\"Ok\"],null],false],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"cancelAction\"]]],null,{\"statements\":[[0,\"        \"],[7,\"a\"],[11,\"class\",\"button\"],[11,\"role\",\"button\"],[9],[0,\"\\n          \"],[1,[27,\"if\",[[23,[\"cancelText\"]],[23,[\"cancelText\"]],\"Cancel\"],null],false],[0,\"\\n        \"],[3,\"action\",[[22,0,[]],\"cancel\"]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n  \"]],\"parameters\":[]},null],[0,\"\\n\"],[3,\"action\",[[22,0,[]],[23,[\"submitAction\"]]],[[\"on\"],[\"submit\"]]],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"backdrop\"],[11,\"role\",\"button\"],[9],[3,\"action\",[[22,0,[]],\"close\"]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/modals/modal-text.hbs"
    }
  });
});