define("affinio/templates/admin/companies/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "vWTt5bDx",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h2\"],[9],[0,\"Companies\"],[10],[0,\"\\n\"],[4,\"link-to\",[\"admin.companies.company\",\"new\"],[[\"class\"],[\"create-new-button\"]],{\"statements\":[[0,\"Create Company \"],[1,[27,\"font-awesome\",[\"plus\"],null],false]],\"parameters\":[]},null],[0,\"\\n\\n\"],[1,[27,\"admin-list\",null,[[\"isCompanies\",\"globalSearch\",\"sectionSearch\",\"toggleCompanyActive\"],[true,true,\"companies-search\",[27,\"route-action\",[\"showToggleCompanyActiveModal\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/admin/companies/index.hbs"
    }
  });
});