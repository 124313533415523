define("affinio/templates/components/score-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "JltYTNVy",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"isLocationTimesMoreLikely\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\"],[11,\"class\",\"primary\"],[9],[1,[27,\"commafy-number\",[[23,[\"tribe\",\"locationtmlscore\"]]],null],false],[0,\"x\"],[10],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"secondary\"],[9],[1,[23,[\"tribe\",\"locationscore\"]],false],[7,\"small\"],[11,\"class\",\"of-100\"],[9],[0,\"/100\"],[10],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"isLocationRelevance\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\"],[11,\"class\",\"primary\"],[9],[1,[23,[\"tribe\",\"locationscore\"]],false],[7,\"small\"],[11,\"class\",\"of-100\"],[9],[0,\"/100\"],[10],[10],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"secondary\"],[9],[1,[27,\"commafy-number\",[[23,[\"tribe\",\"locationtmlscore\"]]],null],false],[0,\"x\"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"isTML\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\"],[11,\"class\",\"primary\"],[9],[1,[27,\"commafy-number\",[[23,[\"tribe\",\"tmlscore\"]]],null],false],[0,\"x\"],[10],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"secondary\"],[9],[1,[23,[\"tribe\",\"score\"]],false],[7,\"small\"],[11,\"class\",\"of-100\"],[9],[0,\"/100\"],[10],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"span\"],[11,\"class\",\"primary\"],[9],[1,[23,[\"tribe\",\"score\"]],false],[7,\"small\"],[11,\"class\",\"of-100\"],[9],[0,\"/100\"],[10],[10],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"secondary\"],[9],[1,[27,\"commafy-number\",[[23,[\"tribe\",\"tmlscore\"]]],null],false],[0,\"x\"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/score-cell.hbs"
    }
  });
});