define("affinio/templates/components/graph-cluster-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "eL/pfH1H",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"bar\"],[12,\"style\",[21,\"style\"]],[12,\"data-color-order\",[23,[\"record\",\"index\"]]],[9],[0,\"\\n  \"],[7,\"label\"],[9],[0,\"\\n    \"],[7,\"strong\"],[9],[1,[21,\"term\"],false],[10],[0,\"\\n\"],[4,\"unless\",[[23,[\"recordMetric\",\"value\"]]],null,{\"statements\":[[0,\"      \"],[7,\"em\"],[9],[1,[27,\"commafy-number\",[[23,[\"value\"]],[23,[\"recordMetric\",\"specificity\"]]],null],false],[0,\" (--)\"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"em\"],[9],[1,[27,\"commafy-number\",[[23,[\"value\"]],[23,[\"recordMetric\",\"specificity\"]]],null],false],[0,\" \"],[1,[23,[\"recordMetric\",\"label\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/graph-cluster-bar.hbs"
    }
  });
});