define("affinio/routes/search", ["exports", "affinio/mixins/auth-check", "affinio/mixins/document-title"], function (_exports, _authCheck, _documentTitle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_authCheck.default, _documentTitle.default, {
    loggedInOnly: true,
    documentTitle: 'Search Reports - Affinio',
    user: Ember.inject.service(),
    redirect: function redirect() {
      if (!Ember.get(this, 'user.account.isInAffinioCompany')) {
        this.transitionTo('reports');
      }
    }
  });
});