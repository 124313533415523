define("affinio/templates/settings/integrations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "U45p2xPS",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\"],[11,\"class\",\"content-proper\"],[9],[0,\"\\n  \"],[7,\"h3\"],[9],[0,\"Authorized Accounts\"],[10],[0,\"\\n  \"],[7,\"p\"],[9],[0,\"Authenticate with these accounts to push audiences\"],[10],[0,\"\\n  \"],[1,[27,\"oauth-button\",null,[[\"network\",\"account\"],[\"twitter\",[23,[\"model\"]]]]],false],[0,\"\\n\"],[0,\"\\n  \"],[1,[27,\"api-key-list\",null,[[\"source\",\"account\"],[\"TalkWalkerAPIToken\",[23,[\"model\"]]]]],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/settings/integrations.hbs"
    }
  });
});