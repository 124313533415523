define("affinio/components/modals/modal-boolean", ["exports", "affinio/components/modals/modal-text"], function (_exports, _modalText) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _modalText.default.extend({
    classNames: ['modal-boolean']
  });
});