define("affinio/templates/components/permissions-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "TDgQoP/F",
    "block": "{\"symbols\":[\"permission\"],\"statements\":[[7,\"span\"],[11,\"class\",\"section-header\"],[11,\"role\",\"button\"],[9],[0,\"\\n  Features\\n\"],[4,\"if\",[[23,[\"show\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"font-awesome\",[\"caret-up\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[27,\"font-awesome\",[\"caret-down\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[3,\"action\",[[22,0,[]],[27,\"toggle\",[\"show\",[22,0,[]]],null]]],[10],[0,\"\\n\"],[4,\"unless\",[[23,[\"show\"]]],null,{\"statements\":[[0,\"  \"],[7,\"hr\"],[11,\"class\",\"admin-hr\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"show\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"permissions-holder\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"accountPermissions\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"admin-selector\",null,[[\"allEntities\",\"selectedEntities\",\"model\",\"header\",\"permission\",\"addAction\",\"removeAction\",\"addAllAction\",\"removeAllAction\",\"noHR\",\"canSwitchAll\",\"isPermissions\"],[[23,[\"model\",\"company\",\"accounts\"]],[27,\"get\",[[23,[\"model\",\"company\"]],[27,\"concat\",[\"accountsWith\",[27,\"classify\",[[22,1,[]]],null]],null]],null],[23,[\"model\"]],[22,1,[]],[22,1,[]],[23,[\"addAction\"]],[23,[\"removeAction\"]],[23,[\"addAllAction\"]],[23,[\"removeAllAction\"]],true,true,true]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n  \"],[7,\"hr\"],[11,\"class\",\"admin-hr-bottom\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/permissions-view.hbs"
    }
  });
});