define("affinio/components/hover-tip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['hovertip', 'clearfix'],
    tagName: 'div',
    hovered: false,
    classNameBindings: ['hovered', 'float'],
    mouseEnter: function mouseEnter() {
      Ember.set(this, 'hovered', true);
    },
    // hover
    mouseLeave: function mouseLeave() {
      Ember.set(this, 'hovered', false);
    } // mouseLeave
  });
});