define("affinio/components/modules/compare-display", ["exports", "affinio/mixins/affinio-module", "ember-cli-string-helpers/helpers/humanize", "ember-cli-string-helpers/helpers/titleize"], function (_exports, _affinioModule, _humanize, _titleize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
  function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
  var _default = _exports.default = Ember.Component.extend(_affinioModule.default, {
    // Element
    classNames: ['compare-display'],
    classNameBindings: ['showRadar::no-radar'],
    tagName: 'div',
    // Services
    assetMap: Ember.inject.service(),
    user: Ember.inject.service(),
    tracker: Ember.inject.service(),
    graph: Ember.inject.service(),
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    modules: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      Ember.get(this, 'user.loadReportClusters').perform();
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;
      this._super.apply(this, arguments);
      if (Ember.get(this, 'model.cluster.isCluster')) {
        Ember.run.scheduleOnce('afterRender', function () {
          Ember.get(_this, 'router').transitionTo('reports.report.version.cluster.category.subcategory', Ember.get(_this, 'model.report.clusterAggregation.id'), Ember.get(_this, 'model.category.value'), Ember.get(_this, 'model.subcategory.value'));
        });
      }
    },
    // Properties
    isCustom: Ember.computed.equal('model.report.socialNetwork', 'Custom'),
    // don't allow cross-report compares for custom reports

    socialNetwork: Ember.computed.alias('model.report.socialNetwork'),
    metrics: Ember.computed.alias('model.report.metricDefinitions'),
    // TODO rename
    records: Ember.computed.alias('model.report.recordsMap'),
    clusters: Ember.computed.alias('model.report.compareData'),
    hasCrossReportCluster: Ember.computed('model.report.compareData.[]', function () {
      return Ember.get(this, 'model.report.compareData.0.report.id') !== Ember.get(this, 'model.report.compareData.1.report.id');
    }),
    crossReportCluster: Ember.computed('model.report.compareData.[]', function () {
      var _this2 = this;
      if (Ember.get(this, 'hasCrossReportCluster')) {
        return Ember.get(this, 'model.report.compareData').find(function (cluster) {
          return Ember.get(cluster, 'report.id') !== Ember.get(_this2, 'model.report.id');
        });
      }
    }),
    showDisplay: Ember.computed('model.report.compareData.[]', function () {
      var _this3 = this;
      if (Ember.get(this, 'model.report.compareData')) {
        var metricRecords = [];
        Ember.get(this, 'model.report.metricDefinitions').filterBy('comparable').forEach(function (metric) {
          metricRecords.push(Ember.get(Ember.get(_this3, 'model.report.compareData.0'), Ember.get(metric, 'modelAlias') || Ember.get(metric, 'label')));
          metricRecords.push(Ember.get(Ember.get(_this3, 'model.report.compareData.1'), Ember.get(metric, 'modelAlias') || Ember.get(metric, 'label')));
        });
        return metricRecords.every(function (metric) {
          return Ember.get(metric, 'loaded');
        });
      }
    }),
    loadingDisplay: Ember.computed('model.report.compareData.[]', function () {
      var _this4 = this;
      if (Ember.get(this, 'model.report.compareData')) {
        if (Ember.get(this, 'isCustom')) {
          return true;
        } else {
          var metricRecords = [];
          Ember.get(this, 'model.report.metricDefinitions').filterBy('comparable').forEach(function (metric) {
            metricRecords.push(Ember.get(Ember.get(_this4, 'model.report.compareData.0'), Ember.get(metric, 'modelAlias') || Ember.get(metric, 'label')));
            metricRecords.push(Ember.get(Ember.get(_this4, 'model.report.compareData.1'), Ember.get(metric, 'modelAlias') || Ember.get(metric, 'label')));
          });
          return metricRecords.some(function (metric) {
            return Ember.get(metric, 'loading') || Ember.get(metric, 'cached');
          });
        }
      }
    }),
    interestsName: Ember.computed('socialNetwork', function () {
      'interests';
    }),
    hasThreeAggregate: Ember.computed.gt('model.report.clusterAggregation.aggregateMetrics.length', 2),
    showRadar: Ember.computed('isCustom', 'hasThreeAggregate', function () {
      if (Ember.get(this, 'isCustom')) {
        return Ember.get(this, 'hasThreeAggregate');
      } else {
        return true;
      }
    }),
    recordsMapLoaded: Ember.computed.alias('model.report.recordsCollection.loaded'),
    isReddit: Ember.computed.equal('socialNetwork', 'Reddit'),
    noAtSymbol: Ember.computed.alias('isReddit'),
    // Computed Properties
    comparison: Ember.computed('clusters', function () {
      var _this5 = this;
      var _Ember$get = Ember.get(this, 'clusters'),
        _Ember$get2 = _slicedToArray(_Ember$get, 2),
        cluster1 = _Ember$get2[0],
        cluster2 = _Ember$get2[1];
      var metrics = Ember.get(this, 'metrics').filterBy('comparable').filterBy('defaultValue', 'array').filterBy('referencingModules.length');
      var flatmaps = Ember.Object.create();
      var comparison = Ember.A();
      var _Ember$get$peekRecord = Ember.get(this, 'store').peekRecord('report-state', Ember.get(this, 'model.report.id')),
        metricLabelMap = _Ember$get$peekRecord.metricLabelMap;
      var _iterator = _createForOfIteratorHelper(metrics),
        _step;
      try {
        var _loop = function _loop() {
          var metric = _step.value;
          var metricLabel = Ember.get(metric, 'label');
          var metricIcon = Ember.get(metric, 'icon');
          var metricIconCustom = Ember.get(metric, 'metricIcon');
          var metricIconProperty = Ember.get(metric, 'iconProperty');
          var metricTitle = Ember.get(metric, 'title');
          if (metricIconCustom) {
            metricIconCustom = Ember.get(_this5, 'assetMap').resolve(metricIconCustom);
          }
          if (Ember.get(cluster1, "".concat(metricLabel, ".response")) && Ember.get(cluster2, "".concat(metricLabel, ".response"))) {
            var uniqMetrics = _.uniq(_.flatten([Ember.get(cluster1, "".concat(metricLabel, ".response")).mapBy('label'), Ember.get(cluster2, "".concat(metricLabel, ".response")).mapBy('label')])); // flat list of uniq labels to compare against
            Ember.set(flatmaps, metricLabel, uniqMetrics); // eslint-disable-line ember/no-side-effects
            var _iterator2 = _createForOfIteratorHelper(uniqMetrics),
              _step2;
            try {
              var _loop2 = function _loop2() {
                var label = _step2.value;
                // We should not disallow those metrics that are both 100%/0% AND very highly saturated in the 100% cluster.
                var consideredBecauseSaturation = [cluster1, cluster2].some(function (cluster) {
                  if (Ember.get(cluster, "".concat(metricLabel, ".response")).findBy('label', label)) {
                    if (Ember.get(cluster, "".concat(metricLabel, ".response")).findBy('label', label).value > Ember.get(cluster, 'numberOfMembers.response.value') * 0.05) {
                      // allow if saturation is above 5%
                      return true;
                    }
                  }
                });
                var cluster1Response = Ember.get(cluster1, "".concat(metricLabel, ".response")).findBy('label', label);
                var cluster2Response = Ember.get(cluster2, "".concat(metricLabel, ".response")).findBy('label', label);
                var foundIn1 = cluster1Response && cluster1Response.value;
                var foundIn2 = cluster2Response && cluster2Response.value;
                if (foundIn1 && foundIn2 || consideredBecauseSaturation) {
                  var niceLabel = metricLabel;
                  var niceTitle = metricTitle;
                  if (Ember.get(_this5, 'isCustom')) {
                    if (metricLabelMap.has(metricLabel)) {
                      niceLabel = niceTitle = metricLabelMap.get(metricLabel); // eslint-disable-line ember/use-ember-get-and-set
                    } else {
                      niceLabel = niceTitle = (0, _titleize.titleize)([(0, _humanize.humanize)([metricLabel.dasherize()])]);
                    }
                  }
                  var instanceMetricIconProperty = metricIconProperty ? cluster1Response && cluster1Response[metricIconProperty] : null;
                  var _ret = Ember.Object.create({
                    label: label,
                    'metric': niceLabel,
                    'icon': metricIcon,
                    customIcon: metricIconCustom,
                    iconProperty: instanceMetricIconProperty,
                    'title': niceTitle
                  }); // ret

                  var likelihoods = Ember.A();
                  likelihoods.pushObjects([Ember.Object.create({
                    'cluster': Ember.get(cluster1, 'id'),
                    'likelihood': cluster1Response ? cluster1Response.value / (metricLabel === 'interestCategories' ? 5000 : Ember.get(cluster1, 'numberOfMembers.response.value')) * 100 : 0
                  }),
                  // create
                  Ember.Object.create({
                    'cluster': Ember.get(cluster2, 'id'),
                    'likelihood': cluster2Response ? cluster2Response.value / (metricLabel === 'interestCategories' ? 5000 : Ember.get(cluster2, 'numberOfMembers.response.value')) * 100 : 0
                  })]); // pushObject

                  var likelihoodTotal = Ember.get(likelihoods.objectAt(0), 'likelihood') + Ember.get(likelihoods.objectAt(1), 'likelihood');
                  Ember.setProperties(likelihoods.objectAt(0), {
                    'relativeLikelihood': Ember.get(likelihoods.objectAt(0), 'likelihood') / likelihoodTotal * 100,
                    'relativeStyle': Ember.String.htmlSafe("width: ".concat(Ember.get(likelihoods.objectAt(0), 'likelihood') / likelihoodTotal * 100, "%; background-color: ").concat(Ember.get(_this5, 'model.report.id') === Ember.get(cluster1, 'report.id') ? Ember.get(cluster1, 'color') : '#424242', ";"))
                  });
                  Ember.setProperties(likelihoods.objectAt(1), {
                    'relativeLikelihood': Ember.get(likelihoods.objectAt(1), 'likelihood') / likelihoodTotal * 100,
                    'relativeStyle': Ember.String.htmlSafe("width: ".concat(Ember.get(likelihoods.objectAt(1), 'likelihood') / likelihoodTotal * 100, "%; background-color: ").concat(Ember.get(_this5, 'model.report.id') === Ember.get(cluster2, 'report.id') ? Ember.get(cluster2, 'color') : '#424242', ";"))
                  });
                  Ember.set(_ret, 'likelihoods', likelihoods); // eslint-disable-line ember/no-side-effects

                  comparison.pushObject(_ret);
                } // if both have
              };
              for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
                _loop2();
              } // for label of uniqMetrics
            } catch (err) {
              _iterator2.e(err);
            } finally {
              _iterator2.f();
            }
          } // if they both have the metric in question, compare em
        };
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          _loop();
        } // for each metric
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      comparison = _.sortBy(comparison, function (obj) {
        return Ember.get(obj.likelihoods.objectAt(0), 'relativeLikelihood');
      }).reverse();
      var left, right, middle;
      left = _.chain(comparison).filter(function (obj) {
        return Ember.get(obj.likelihoods.objectAt(0), 'relativeLikelihood') >= 75;
      }).sortBy(function (obj) {
        return Ember.get(obj.likelihoods.objectAt(0), 'likelihood') + Ember.get(obj.likelihoods.objectAt(1), 'likelihood');
      }).reverse().slice(0, 10).sortBy(function (obj) {
        return Ember.get(obj.likelihoods.objectAt(0), 'relativeLikelihood');
      }).reverse().value();
      middle = _.chain(comparison).filter(function (obj) {
        return Ember.get(obj.likelihoods.objectAt(0), 'relativeLikelihood') < 75 && Ember.get(obj.likelihoods.objectAt(0), 'relativeLikelihood') > 25;
      }).sortBy(function (obj) {
        return Ember.get(obj.likelihoods.objectAt(0), 'likelihood') + Ember.get(obj.likelihoods.objectAt(1), 'likelihood');
      }).reverse().slice(0, 10).sortBy(function (obj) {
        return Ember.get(obj.likelihoods.objectAt(0), 'relativeLikelihood');
      }).reverse().value();
      right = _.chain(comparison).filter(function (obj) {
        return Ember.get(obj.likelihoods.objectAt(0), 'relativeLikelihood') <= 25;
      }).sortBy(function (obj) {
        return Ember.get(obj.likelihoods.objectAt(0), 'likelihood') + Ember.get(obj.likelihoods.objectAt(1), 'likelihood');
      }).reverse().slice(0, 10).sortBy(function (obj) {
        return Ember.get(obj.likelihoods.objectAt(1), 'relativeLikelihood');
      }).reverse().value();
      var ret = {
        left: left,
        middle: middle,
        right: right
      };
      Ember.get(this, 'tracker').track('Trait Comparison Generated', {
        reportId: Ember.get(this, 'model.id'),
        cluster1Id: Ember.get(this, 'clusters.0.id'),
        cluster2Id: Ember.get(this, 'clusters.1.id'),
        crossReportComparison: Ember.get(this, 'clusters.0.report.id') !== Ember.get(this, 'clusters.1.report.id'),
        user: Ember.get(this, 'user.email'),
        leftTraits: ret.left.length,
        middleTriats: ret.middle.length,
        rightTraits: ret.right.length,
        metricBreakdown: JSON.stringify(_.countBy([].concat(_toConsumableArray(ret.left), _toConsumableArray(ret.middle), _toConsumableArray(ret.right)), 'metric'))
      });
      return ret;
    }),
    interestComparison: Ember.computed('clusters', 'recordsMapLoaded', function () {
      if (!Ember.get(this, 'isCustom')) {
        var _Ember$get3 = Ember.get(this, 'clusters'),
          _Ember$get4 = _slicedToArray(_Ember$get3, 2),
          cluster1 = _Ember$get4[0],
          cluster2 = _Ember$get4[1];

        // Cluster 1 Uniq Interests
        var uniqInterestsForCluster1 = _.difference(Ember.get(cluster1, 'interests.response').mapBy('label'), Ember.get(cluster2, 'interests.response').mapBy('label'));
        var left = [];
        var _iterator3 = _createForOfIteratorHelper(uniqInterestsForCluster1),
          _step3;
        try {
          for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
            var label = _step3.value;
            left.pushObject(Ember.get(cluster1, 'interests.response').findBy('label', label));
          }
        } catch (err) {
          _iterator3.e(err);
        } finally {
          _iterator3.f();
        }
        left = _.chain(left).sortBy('relevance').reverse().slice(0, 10).value();

        // Cluster 2 Uniq Interests
        var uniqInterestsForCluster2 = _.difference(Ember.get(cluster2, 'interests.response').mapBy('label'), Ember.get(cluster1, 'interests.response').mapBy('label'));
        var right = [];
        var _iterator4 = _createForOfIteratorHelper(uniqInterestsForCluster2),
          _step4;
        try {
          for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
            var _label = _step4.value;
            right.pushObject(Ember.get(cluster2, 'interests.response').findBy('label', _label));
          }
        } catch (err) {
          _iterator4.e(err);
        } finally {
          _iterator4.f();
        }
        right = _.chain(right).sortBy('relevance').reverse().slice(0, 10).value();

        // Intersection of Interests
        var intersectionInterests = _.intersection(Ember.get(cluster1, 'interests.response').mapBy('label'), Ember.get(cluster2, 'interests.response').mapBy('label'));
        var middle = [];
        var _iterator5 = _createForOfIteratorHelper(intersectionInterests),
          _step5;
        try {
          for (_iterator5.s(); !(_step5 = _iterator5.n()).done;) {
            var _label2 = _step5.value;
            var i1 = Ember.get(cluster1, 'interests.response').findBy('label', _label2);
            var i2 = Ember.get(cluster2, 'interests.response').findBy('label', _label2);
            var totalRelevance = i1.relevance + i2.relevance;
            var ret = Ember.get(cluster1, 'interests.response').findBy('label', _label2);
            Ember.set(ret, 'totalRelevance', totalRelevance); // eslint-disable-line ember/no-side-effects
            Ember.set(ret, 'likelihoods', []); // eslint-disable-line ember/no-side-effects
            Ember.get(ret, 'likelihoods').pushObject({
              relevancePercent: i1.relevance / totalRelevance * 100,
              relevanceStyle: Ember.String.htmlSafe("width: ".concat(i1.relevance / totalRelevance * 100, "%; background-color: ").concat(Ember.get(this, 'model.report.id') === Ember.get(cluster1, 'report.id') ? Ember.get(cluster1, 'color') : '#424242', ";"))
            });
            Ember.get(ret, 'likelihoods').pushObject({
              relevancePercent: i2.relevance / totalRelevance * 100,
              relevanceStyle: Ember.String.htmlSafe("width: ".concat(i2.relevance / totalRelevance * 100, "%; background-color: ").concat(Ember.get(this, 'model.report.id') === Ember.get(cluster2, 'report.id') ? Ember.get(cluster2, 'color') : '#424242', ";"))
            });
            middle.pushObject(ret);
          }
        } catch (err) {
          _iterator5.e(err);
        } finally {
          _iterator5.f();
        }
        middle = _.chain(middle).sort(function (a, b) {
          return Math.abs(50 - Ember.get(a, 'likelihoods.0.relevancePercent')) - Math.abs(50 - Ember.get(b, 'likelihoods.0.relevancePercent'));
        }).slice(0, 10).sort(function (a, b) {
          return Ember.get(a, 'likelihoods.0.relevancePercent') - Ember.get(b, 'likelihoods.0.relevancePercent');
        }).reverse().value();
        return {
          left: left,
          right: right,
          middle: middle
        };
      }
    }),
    hasNumericMetrics: Ember.computed('metrics', function () {
      var metrics = Ember.get(this, 'metrics').filterBy('comparable').filterBy('defaultValue', 'number').filterBy('referencingModules.length');
      return metrics.length > 0;
    }),
    // we are showing the 20 metrics with the greatest % difference in average value
    // between clusters excluding any metrics with less than 2 ticks/values
    numericModules: Ember.computed('clusters', 'metrics', function () {
      var _this6 = this;
      var metrics = Ember.get(this, 'metrics').filterBy('comparable').filterBy('defaultValue', 'number').filterBy('referencingModules.length');
      var _Ember$get5 = Ember.get(this, 'clusters'),
        _Ember$get6 = _slicedToArray(_Ember$get5, 2),
        cluster1 = _Ember$get6[0],
        cluster2 = _Ember$get6[1];
      var modules = [];
      var reportID = Ember.get(this, 'model.report.id');
      var _Ember$get$peekRecord2 = Ember.get(this, 'store').peekRecord('report-state', reportID),
        metricLabelMap = _Ember$get$peekRecord2.metricLabelMap;
      metrics.forEach(function (metric) {
        var metric1 = Ember.get(cluster1, "".concat(metric.label, ".response"));
        var metric2 = Ember.get(cluster2, "".concat(metric.label, ".response"));
        var showMetric = true;
        if (metric1.valueBins && metric2.valueBins) {
          var labels = metric1.valueBins.mapBy('label').concat(metric2.valueBins.mapBy('label')).uniq();
          if (labels.length <= 2) {
            showMetric = false;
          }
        }
        // In subcategory route proper, we send subcategory.modules in to the module-grid
        // these are proper models with computeds to fetch many things (like preferences)
        // rather than make new models here, we just hack things we need
        if (showMetric) {
          var moduleTitle = metricLabelMap.has(metric.label) ? metricLabelMap.get(metric.label) // eslint-disable-line ember/use-ember-get-and-set
          : metric.label;
          var difference = Math.abs(metric1.value - metric2.value) / Math.max(metric1.value, metric2.value);
          modules.push({
            label: moduleTitle,
            value: 'numeric-visualization',
            metrics: [{
              cluster: metric.label
            }],
            description: metric.description,
            preferences: Ember.get(Ember.get(_this6, 'modules.schema'), 'numericVisualization.preferences'),
            moduleProperties: {
              visualizationProperties: [{
                label: 'cull',
                value: 0
              }]
            },
            difference: difference,
            nestedCompare: true
          });
        }
      });
      modules.sort(function (a, b) {
        return b.difference - a.difference;
      });
      return modules.slice(0, 20);
    })
  });
});