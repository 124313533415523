define("affinio/templates/components/download-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "r1KZ4PrR",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"href\"]]],null,{\"statements\":[[0,\"  \"],[7,\"a\"],[12,\"href\",[21,\"href\"]],[11,\"class\",\"download-button primary button\"],[12,\"download\",[21,\"download\"]],[9],[1,[21,\"label\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/download-button.hbs"
    }
  });
});