define("affinio/components/active-tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this.$().hide().slideDown(300);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;
      Ember.run.scheduleOnce('afterRender', this, function () {
        Ember.$('.highlit').removeClass('highlit');
        Ember.$(".active-tags span:contains(\"".concat(Ember.get(_this, 'param'), "\")")).addClass('highlit');
      });
    },
    willDestroyElement: function willDestroyElement() {
      var clone = this.$().clone();
      this.$().parent().prepend(clone);
      clone.slideUp(300).next(function () {
        clone.remove();
      });
    },
    actions: {
      filterInterests: function filterInterests(param, parent) {
        Ember.get(this, 'changeCurrentCategory')(param, parent);
      }
    }
  });
});