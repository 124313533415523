define("affinio/components/report-list-item", ["exports", "affinio/utils/mutations", "affinio/utils/constants/countries", "affinio/utils/affinio", "moment", "affinio/utils/constants/date-formats"], function (_exports, _mutations, _countries, _affinio, _moment, _dateFormats) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    user: Ember.inject.service(),
    graph: Ember.inject.service(),
    tracker: Ember.inject.service(),
    modalManager: Ember.inject.service(),
    router: Ember.inject.service(),
    tagName: 'li',
    classNameBindings: ['modelmonitoredInformation.active:monitoring', 'pending', 'failed', 'model.checked:checked'],
    classNames: ['clearfix', 'report-list-item'],
    pending: Ember.computed.equal('model.state', 'pending'),
    failed: Ember.computed.equal('model.state', 'failed'),
    expandedLanguages: _countries.expandedLanguages,
    // imported from utils/constants/countries

    // isActive: computed('model.monitoredInformation.active', function() {
    //   let model = get(this, 'model');
    //   return model.get('monitoredInformation.active');
    // }),
    ownerId: Ember.computed.alias('model.creator.id'),
    isOwner: Ember.computed('ownerId', function () {
      var userId = Ember.get(this, 'user.account.id');
      return userId && userId === Ember.get(this, 'ownerId');
    }),
    followersOfExpanded: false,
    explicitWordsExpanded: false,
    isEditor: Ember.computed('user.account.hasCreateMonitoredReport', 'model.canEdit', function () {
      return Ember.get(this, 'model.canEdit') && Ember.get(this, 'user.account.hasCreateMonitoredReport');
    }),
    isCustomNewFormat: Ember.computed('model.{filters,socialNetwork}', function () {
      return Ember.get(this, 'model.socialNetwork') === 'Custom' && (!Object.keys(Ember.get(this, 'model.filters')).length || Ember.get(this, 'model.filters.data_sources') && !Ember.get(this, 'model.filters.data_sources.length') || (0, _affinio.isNewFormatCustomFilters)(Ember.get(this, 'model.filters')));
    }),
    actions: {
      sourceReportType: function sourceReportType(model) {
        var reportType = (0, _affinio.reportTypeFromFilters)(Ember.get(model, 'socialNetwork').toLowerCase(), Ember.get(model, 'filters'));
        return reportType.type;
      },
      clickAction: function clickAction() {
        Ember.get(this, 'clickClosure')(this, Ember.get(this, 'model'));
      },
      defaultClickAction: function defaultClickAction() {
        Ember.get(this, 'router').transitionTo('reports.report', Ember.get(this, 'model.id'));
      },
      stopUpdatingCampaign: function stopUpdatingCampaign() {
        var _this = this;
        var modalManager = Ember.get(this, 'modalManager');
        var frequency = Ember.get(this, 'model.monitoredInformation.frequency');
        var reportId = Ember.get(this, 'model.id');
        var reportName = Ember.get(this, 'model.name');
        modalManager.openModal('modals/modal-boolean', {
          question: 'Cancel Report Updates',
          message: "Please confirm if you would like to stop monitoring for ".concat(reportName, ". You can also update the current monitoring settings, including frequency and tracked interests, from the Actions section."),
          okText: 'Stop Monitoring',
          okAction: function okAction() {
            Ember.get(_this, 'tracker').track('Campaign Update Stop Requested', {
              id: reportId
            });
            Ember.get(_this, 'graph').mutate(_mutations.default.addTwitterMonitoredInformation(Ember.get(_this, 'model'), frequency, false, false)).then(function () {
              Ember.get(_this, 'tracker').track('Campaign Update Stop Saved', {
                id: reportId
              });
              modalManager.openModal('modals/modal-success', {
                title: 'Report Monitoring Updated',
                message: "".concat(Ember.get(_this, 'model.name'), "'s update policy has been changed"),
                okAction: true
              });
            }).catch(function () {
              Ember.get(_this, 'tracker').track('Error on Campaign Update Stop Save', {
                id: reportId
              });
              modalManager.openModal('modals/modal-error', {
                title: 'Report Monitoring Update Error',
                reason: 'This is a known issue when updating your audience monitoring settings. It may currently be taking a snapshot and need some time to finish before monitoring settings can be changed',
                fix: 'try reupdating the settings in an hour'
              });
            });
          },
          secondaryActionText: 'Go to Actions',
          secondaryAction: function secondaryAction() {
            Ember.get(_this, 'router').transitionTo('reports.report.version.cluster.category.subcategory', reportId, Ember.get(_this, 'model.version'), "".concat(reportId, "_all"), 'actions', 'monitor');
            modalManager.closeModal();
          }
        });
      },
      recycleQuery: function recycleQuery(model) {
        Ember.get(this, 'recycleQueryClosureAction')(model);
      },
      toggle: function toggle(element) {
        this.toggleProperty(element);
      },
      combineCustomFilters: function combineCustomFilters(filters) {
        var combined = {};
        var isNewFormat = (0, _affinio.isNewFormatCustomFilters)(filters);
        if (isNewFormat) {
          var isSql = (0, _affinio.isCustomSQLFilters)(filters);
          if (isSql) {
            combined.SQL = [];
            filters.data_sources.forEach(function (ds) {
              combined.SQL.pushObjects(ds.filters.leaves.map(function (n) {
                return n.value;
              }));
            });
          } else {
            var isAnd = false;
            (filters.data_sources || []).forEach(function (ds) {
              if (ds.filters.op === 'AND') {
                isAnd = true;
              }
              (ds.filters.branches || []).forEach(function (grouping) {
                if (grouping.op === 'AND') {
                  isAnd = true;
                }
                (grouping.leaves || []).forEach(function (filter) {
                  if (!combined.hasOwnProperty(filter.source)) {
                    combined[filter.source] = [];
                  }
                  combined[filter.source].push("".concat(filter.condition, ": ").concat(filter.value));
                });
              });
            });
            if (isAnd && Object.keys(combined).length) {
              combined.advancedBoolean = true;
            }
          }
        } else {
          (filters.data_sources || []).forEach(function (ds) {
            (ds.filters || []).forEach(function (filter) {
              if (!combined.hasOwnProperty(filter.field)) {
                combined[filter.field] = [];
              }
              combined[filter.field].push("".concat(filter.condition, ": ").concat(filter.value));
            });
          });
        }
        return combined;
      },
      fixFilters: function fixFilters(filters) {
        var newFilters = Object.assign({}, filters);
        if (filters && filters.followersStartDate && filters.followersEndDate) {
          newFilters.newFollowersOnly = "".concat((0, _moment.default)(filters.followersStartDate).format(_dateFormats.fullMonth), " - ").concat((0, _moment.default)(filters.followersEndDate).format(_dateFormats.fullMonth));
          delete newFilters.followersStartDate;
          delete newFilters.followersEndDate;
          delete newFilters.followersStartWeekYear;
          delete newFilters.followersEndWeekYear;
        }
        if (filters && filters.queryID) {
          delete newFilters.queryID;
          newFilters.source = 'Realtime App Query';
        }
        return newFilters;
      }
    }
  });
});