define("affinio/components/member-demographics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Element
    classNames: ['member-demographics'],
    // Services
    path: Ember.inject.service(),
    // Properties
    isSmall: false,
    smallCutoff: 400,
    // theAction: 'setIsSmall', // TODO pass this in as a closure instead of expecting parent context to have it

    // Computed Properties
    isGender: Ember.computed.equal('type', 'gender'),
    // clusterData: computed.alias('model.report.cluster.demographics'),

    demographics: Ember.computed('reportName', 'tribeName', 'clusterData', 'allData', 'tribe', 'type', 'baselineData', 'path.{currentTribeID,currentTribeIndex}', function () {
      var _this = this;
      var tribeCount = Ember.get(this, 'tribeCount');
      var reportName = Ember.get(this, 'reportName');
      var tribeName = Ember.get(this, 'tribeName');
      var tribe = Ember.get(this, 'tribe');
      var limit = Ember.get(this, 'smallCutoff');
      var clusterData = Ember.get(this, 'clusterData');
      var allData = Ember.get(this, 'allData');
      var type = Ember.get(this, 'type');
      var baselineData = Ember.get(this, 'baselineData');
      var baselineName = Ember.get(this, 'baselineName');
      var ret = [];
      var max;
      if (tribe) {
        var clusterDemos = clusterData;
        var allDemos = allData;
        Ember.run.scheduleOnce('afterRender', function () {
          return Ember.set(_this, 'isSmall', clusterDemos.totalFaces < limit ? true : false);
        }); // eslint-disable-line ember/no-side-effects

        switch (type) {
          case 'age':
            max = _.max([clusterDemos.ageMax, allDemos.ageMax, baselineData ? baselineData.ageMax : 0]);
            ret.push({
              max: max,
              label: '18-24',
              active: {
                label: tribeName,
                percent: clusterDemos.female1824 + clusterDemos.male1824
              },
              comparison: {
                label: reportName,
                percent: allDemos.female1824 + allDemos.male1824
              },
              baseline: baselineData ? {
                label: baselineName,
                percent: baselineData.female1824 + baselineData.male1824
              } : null
            });
            ret.push({
              max: max,
              label: '25-34',
              active: {
                label: tribeName,
                percent: clusterDemos.female2534 + clusterDemos.male2534
              },
              comparison: {
                label: reportName,
                percent: allDemos.female2534 + allDemos.male2534
              },
              baseline: baselineData ? {
                label: baselineName,
                percent: baselineData.female2534 + baselineData.male2534
              } : null
            });
            ret.push({
              max: max,
              label: '35-44',
              active: {
                label: tribeName,
                percent: clusterDemos.female3544 + clusterDemos.male3544
              },
              comparison: {
                label: reportName,
                percent: allDemos.female3544 + allDemos.male3544
              },
              baseline: baselineData ? {
                label: baselineName,
                percent: baselineData.female3544 + baselineData.male3544
              } : null
            });
            ret.push({
              max: max,
              label: '45-54',
              active: {
                label: tribeName,
                percent: clusterDemos.female4554 + clusterDemos.male4554
              },
              comparison: {
                label: reportName,
                percent: allDemos.female4554 + allDemos.male4554
              },
              baseline: baselineData ? {
                label: baselineName,
                percent: baselineData.female4554 + baselineData.male4554
              } : null
            });
            ret.push({
              max: max,
              label: '55-64',
              active: {
                label: tribeName,
                percent: clusterDemos.female5564 + clusterDemos.male5564
              },
              comparison: {
                label: reportName,
                percent: allDemos.female5564 + allDemos.male5564
              },
              baseline: baselineData ? {
                label: baselineName,
                percent: baselineData.female5564 + baselineData.male5564
              } : null
            });
            ret.push({
              max: max,
              label: '65+',
              active: {
                label: tribeName,
                percent: clusterDemos.female65 + clusterDemos.male65
              },
              comparison: {
                label: reportName,
                percent: allDemos.female65 + allDemos.male65
              },
              baseline: baselineData ? {
                label: baselineName,
                percent: baselineData.female65 + baselineData.male65
              } : null
            });
            break;
          case 'gender':
            max = _.max([clusterDemos.genderMax, allDemos.genderMax, baselineData ? baselineData.genderMax : 0]);
            ret.push({
              label: 'Male',
              max: max,
              active: {
                label: tribeName,
                percent: clusterDemos.maleTotal
              },
              comparison: {
                label: reportName,
                percent: allDemos.maleTotal
              },
              baseline: baselineData ? {
                label: reportName,
                percent: baselineData.maleTotal
              } : null
            });
            ret.push({
              label: 'Female',
              max: max,
              active: {
                label: tribeName,
                percent: clusterDemos.femaleTotal
              },
              comparison: {
                label: reportName,
                percent: allDemos.femaleTotal
              },
              baseline: baselineData ? {
                label: reportName,
                percent: baselineData.femaleTotal
              } : null
            });
            break;
        }
      } else {
        // not in tribe
        var _clusterDemos = clusterData;
        Ember.run.scheduleOnce('afterRender', function () {
          return Ember.set(_this, 'isSmall', _clusterDemos.total < limit * tribeCount ? true : false);
        }); // eslint-disable-line ember/no-side-effects
        switch (type) {
          case 'age':
            max = _.max([_clusterDemos.ageMax, baselineData ? baselineData.ageMax : 0]);
            ret.push({
              max: max,
              label: '18-24',
              active: {
                label: reportName,
                percent: _clusterDemos.female1824 + _clusterDemos.male1824
              },
              baseline: baselineData ? {
                label: baselineName,
                percent: baselineData.female1824 + baselineData.male1824
              } : null
            });
            ret.push({
              max: max,
              label: '25-34',
              active: {
                label: reportName,
                percent: _clusterDemos.female2534 + _clusterDemos.male2534
              },
              baseline: baselineData ? {
                label: baselineName,
                percent: baselineData.female2534 + baselineData.male2534
              } : null
            });
            ret.push({
              max: max,
              label: '35-44',
              active: {
                label: reportName,
                percent: _clusterDemos.female3544 + _clusterDemos.male3544
              },
              baseline: baselineData ? {
                label: baselineName,
                percent: baselineData.female3544 + baselineData.male3544
              } : null
            });
            ret.push({
              max: max,
              label: '45-54',
              active: {
                label: reportName,
                percent: _clusterDemos.female4554 + _clusterDemos.male4554
              },
              baseline: baselineData ? {
                label: baselineName,
                percent: baselineData.female4554 + baselineData.male4554
              } : null
            });
            ret.push({
              max: max,
              label: '55-64',
              active: {
                label: reportName,
                percent: _clusterDemos.female5564 + _clusterDemos.male5564
              },
              baseline: baselineData ? {
                label: baselineName,
                percent: baselineData.female5564 + baselineData.male5564
              } : null
            });
            ret.push({
              max: max,
              label: '65+',
              active: {
                label: reportName,
                percent: _clusterDemos.female65 + _clusterDemos.male65
              },
              baseline: baselineData ? {
                label: baselineName,
                percent: baselineData.female65 + baselineData.male65
              } : null
            });
            break;
          case 'gender':
            max = _.max([_clusterDemos.genderMax, baselineData ? baselineData.genderMax : 0]);
            ret.push({
              label: 'Male',
              max: max,
              active: {
                label: reportName,
                percent: _clusterDemos.maleTotal
              },
              baseline: baselineData ? {
                label: reportName,
                percent: baselineData.maleTotal
              } : null
            });
            ret.push({
              label: 'Female',
              max: max,
              active: {
                label: reportName,
                percent: _clusterDemos.femaleTotal
              },
              baseline: baselineData ? {
                label: reportName,
                percent: baselineData.femaleTotal
              } : null
            });
            break;
        }
      } // if tribe

      if (Ember.get(this, 'top')) {
        ret = [_.max(ret, function (demo) {
          return demo.active.percent;
        })];
      }
      return ret;
    }),
    primaryStyle: Ember.computed('path.inCluster', function () {
      var color = '#777777';
      if (Ember.get(this, 'path.inCluster')) {
        color = Ember.get(this, 'cluster.color');
      }
      return Ember.String.htmlSafe("background-color: ".concat(color, ";"));
    })
  });
});