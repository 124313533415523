define("affinio/templates/components/modules/interests-categories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "U39wBkRB",
    "block": "{\"symbols\":[\"item\",\"&default\"],\"statements\":[[14,2,[[22,0,[]]]],[0,\"\\n\"],[7,\"div\"],[9],[0,\"\\n\"],[4,\"if\",[[27,\"eq\",[[23,[\"normalized\"]],\"word-cloud\"],null]],null,{\"statements\":[[0,\"    \"],[1,[27,\"word-cloud\",null,[[\"source\",\"metric\",\"normalized\",\"recordsMap\",\"sizeWordCloudBy\",\"width\",\"height\"],[[23,[\"sortedItemsForWordCloud\"]],[23,[\"metric\"]],[22,0,[\"controller\",\"normalized\"]],[23,[\"sortedItemsForWordCloud\"]],\"count\",1200,900]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\"],[12,\"class\",[28,[\"interests-charts \",[21,\"normalized\"]]]],[9],[0,\"\\n\\n\"],[4,\"unless\",[[27,\"or\",[[23,[\"sortedItems\",\"length\"]],[27,\"not\",[[23,[\"controller\",\"instanceSearchInput\"]]],null]],null]],null,{\"statements\":[[0,\"        \"],[7,\"p\"],[9],[0,\"No tags found matching '\"],[1,[23,[\"controller\",\"instanceSearchInput\"]],false],[0,\"'\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"each\",[[23,[\"sortedItems\"]]],null,{\"statements\":[[0,\"        \"],[1,[27,\"metric-instance\",null,[[\"instance\",\"model\",\"max\",\"records\",\"instances\",\"normalized\"],[[27,\"hash\",null,[[\"label\",\"value\"],[[22,1,[\"label\"]],[22,1,[\"count\"]]]]],[23,[\"model\"]],[23,[\"max\"]],[27,\"get\",[[23,[\"tagCountsObject\"]],[27,\"lowercase\",[[22,1,[\"value\"]]],null]],null],[23,[\"sortedItems\"]],[23,[\"normalized\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/modules/interests-categories.hbs"
    }
  });
});