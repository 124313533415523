define("affinio/components/toolbox/toolbox-drawer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['toolbox-drawer'],
    classNameBindings: ['hasExpandedTool'],
    tagName: 'div',
    hasExpandedTool: false,
    currentlyOpenTool: null,
    isExpanded: Ember.computed('currentlyOpenDrawer', 'label', function () {
      return Ember.get(this, 'currentlyOpenDrawer') === Ember.get(this, 'label');
    }),
    isExpandedObserver: Ember.observer('isExpanded', function () {
      if (!Ember.get(this, 'isExpanded')) {
        Ember.set(this, 'currentlyOpenTool', null);
        Ember.set(this, 'hasExpandedTool', false);
      }
    }),
    actions: {
      toggle: function toggle() {
        if (Ember.get(this, 'isExpanded')) {
          Ember.get(this, 'setCurrentlyOpenDrawer')(null);
        } else {
          Ember.get(this, 'setCurrentlyOpenDrawer')(Ember.get(this, 'label'));
        }
      },
      setCurrentlyOpenTool: function setCurrentlyOpenTool(tool) {
        Ember.set(this, 'currentlyOpenTool', tool);
        if (tool !== null) {
          Ember.set(this, 'hasExpandedTool', true);
        } else {
          Ember.set(this, 'hasExpandedTool', false);
        }
      }
    }
  });
});