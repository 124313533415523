define("affinio/app", ["exports", "affinio/resolver", "ember-load-initializers", "affinio/config/environment", "flatten-polyfill"], function (_exports, _resolver, _emberLoadInitializers, _environment, _flattenPolyfill) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // TODO: Remove when flat/flatMap reaches stage 4 and added back into babel
  // https://github.com/babel/ember-cli-babel/issues/247
  // https://github.com/tc39/proposal-flatMap

  var App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default,
    /* eslint-disable */
    customEvents: {
      mousemove: null,
      touchmove: null,
      touchstart: null,
      touchend: null,
      touchcancel: null,
      contextmenu: null,
      dblclick: null
    }
    /* eslint-enable */
  });
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
  var _default = _exports.default = App;
});