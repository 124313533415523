define("affinio/services/tracker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var assign = Object.assign,
    keys = Object.keys;
  var _default = _exports.default = Ember.Service.extend({
    app: Ember.inject.service(),
    user: Ember.inject.service(),
    path: Ember.inject.service(),
    api: Ember.inject.service(),
    propsToAlwaysSend: function propsToAlwaysSend() {
      var user = Ember.get(this, 'user');
      var path = Ember.get(this, 'path');
      return {
        user: Ember.get(user, 'email'),
        company: Ember.get(user, 'company.id'),
        companyName: Ember.get(user, 'company.name'),
        sfid: Ember.get(user, 'company.sfid'),
        currentTab: Ember.get(path, 'currentTab'),
        tabid: Ember.get(this, 'api.tabid'),
        version: Ember.get(this, 'app.ENV.version'),
        host: Ember.get(this, 'app.ENV.hosts.api'),
        namespace: Ember.get(this, 'app.ENV.name'),
        environment: Ember.get(this, 'app.environment'),
        href: window.location.href
      };
    },
    track: function track(title) {
      var properties = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var callback = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      var always = this.propsToAlwaysSend();
      var camelized = assign(always, properties);
      var decamelized = {};
      keys(camelized).forEach(function (key) {
        decamelized[Ember.String.decamelize(key)] = camelized[key];
      });
      woopra.track(title, decamelized, callback);
    },
    identify: function identify(properties) {
      woopra.identify(properties);
    }
  });
});