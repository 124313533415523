define("affinio/templates/components/affinio-table-cell/datasource-column-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "YWv2+BDZ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\"],[11,\"role\",\"button\"],[12,\"title\",[27,\"concat\",[\"Click for more: \",[23,[\"example\"]]],null]],[9],[0,\"\\n  \"],[1,[21,\"example\"],false],[0,\"\\n\"],[3,\"action\",[[22,0,[]],\"openDataSourcePreview\",[23,[\"row\"]]]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/affinio-table-cell/datasource-column-preview.hbs"
    }
  });
});