define("affinio/components/modules/audience-summary", ["exports", "affinio/mixins/affinio-module"], function (_exports, _affinioModule) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_affinioModule.default, {
    // Element
    classNames: ['audience-summary'],
    tagName: 'div',
    // Services
    preferences: Ember.inject.service(),
    tracker: Ember.inject.service(),
    metric: Ember.computed('processedModuleSchema', 'processedModuleSchema.{preferences,preferences.0.options.@each.sliceSize}', 'model.requestsFulfilled', function () {
      var dropdownOptions = Ember.get(this, 'processedModuleSchema.preferences').findBy('value', 'select-dropdown').options;
      if (dropdownOptions.length) {
        return dropdownOptions[0].value;
      } else {
        return null;
      }
    }),
    description: Ember.computed('descriptions', 'metric', function () {
      return Ember.get(this, "descriptions.".concat(Ember.get(this, 'metric')));
    }),
    descriptions: Ember.computed('model.report.socialNetwork', function () {
      return {
        lurkers: 'These metrics show the % of an audience who actively post vs. those who \'lurk\' (i.e. seldomly post, or only consume content). We define lurkers as those who post fewer than 15 times per month.',
        imageShareCBG: 'This shows the percentage of images shared by each cluster relative to the total number of images shared by the audience. It does not represent the percentage of tweets that included images.',
        postsPerMonthCBG: Ember.get(this, 'model.report.socialNetwork') === 'Reddit' ? 'This shows the average number of posts each cluster member makes per month, including text and link posts.' : 'This shows the average number of posts each cluster member makes per month, including both original tweets and retweets. The Affinio Average lines indicate the 25th, 50th, and 75th percentiles to give you an idea of what normal activity looks like for this type of Affinio report.',
        likesCBG: 'This shows the average number of posts matching the report query each cluster member \'likes\' per month.',
        commentsCBG: 'This shows the average number of posts matching the report query each cluster member comments on per month.',
        sharesCBG: 'This shows the average number of posts matching the report query, by others, each cluster member shares per month.',
        interestAffinity: 'This shows the average Relevance score among a cluster’s top 100 interests. Relevance is a custom measurement of an interest’s significance. It’s likely that clusters with greater Interest Relevance have more common interests.',
        uniqueInterestsPercentage: 'This shows the percentage of a cluster\'s interests that no other cluster in this audience is interested in.',
        interconnectivityCBG: 'This metric shows how friendly or well-acquainted a cluster is. The higher the density percentage, the greater the likelihood that people in this cluster know (and follow) each other.',
        avgAccountAgeCBG: 'This shows the number of days the average user has been on Reddit per cluster',
        avgCommentKarmaCBG: 'This shows the average number of total upvotes that comments posted by cluster members have received',
        avgLinkKarmaCBG: 'This shows the average number of total upvotes that links posted by cluster members have received',
        verifiedMembersPercentageCBG: 'This metric represents the percentage of members within each cluster who hold verified/badged status, as a way to measure authenticity and influence.'
      };
    })
  });
});