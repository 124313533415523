define("affinio/templates/aton/about", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "lTrWbleC",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\"],[11,\"class\",\"content-proper full-width\"],[9],[0,\"\\n  \"],[7,\"h2\"],[9],[0,\"About ATON\"],[10],[0,\"\\n\\n  \"],[7,\"p\"],[9],[0,\"ATON is an AI-driven graph segmentation and visualization technology that identifies common patterns and behaviors across any data set. ATON requires two components to create an Audience Source: a Data Source and a Taxonomy.\"],[10],[0,\"\\n\\n  \"],[7,\"section\"],[11,\"class\",\"aton-about-list\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"aton.sources\"],[[\"class\",\"disabled\"],[\"card\",[27,\"eq\",[[23,[\"model\",\"dataSets\",\"length\"]],0],null]]],{\"statements\":[[0,\"      \"],[7,\"h3\"],[9],[0,\"Data Source\"],[10],[0,\"\\n      \"],[7,\"p\"],[9],[0,\"The formatting of your Data Set for use as an ongoing audience source.\"],[10],[0,\"\\n      \"],[7,\"br\"],[9],[10],[0,\"\\n\"],[4,\"link-to\",[\"aton.sources\",[27,\"query-params\",null,[[\"forceCreation\"],[true]]]],[[\"disabled\"],[[27,\"eq\",[[23,[\"model\",\"dataSets\",\"length\"]],0],null]]],{\"statements\":[[0,\"        Upload Data\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"link-to\",[\"aton.taxonomy\"],[[\"class\",\"disabled\"],[\"card\",[27,\"eq\",[[23,[\"model\",\"dataSources\",\"length\"]],0],null]]],{\"statements\":[[0,\"      \"],[7,\"h3\"],[9],[0,\"Taxonomy\"],[10],[0,\"\\n      \"],[7,\"p\"],[9],[0,\"The specific criteria you want to apply to your Data Source when building reports.\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"img\"],[11,\"src\",\"/assets/images/aton-boat.png\"],[11,\"alt\",\"ATON Visualization\"],[11,\"class\",\"aton-about-img\"],[9],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/aton/about.hbs"
    }
  });
});