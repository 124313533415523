define("affinio/components/social-network-estimate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['social-network-estimate'],
    classNameBindings: ['network.network'],
    tagName: 'section',
    attributeBindings: ['width', 'height'],
    width: '100%',
    height: '100%',
    draw: function draw() {
      var elem = d3.select(this.$()[0]);
      var width = Ember.$(elem[0]).width();
      var height = Ember.$(elem[0]).height();
      var svg = elem.append('svg').append('svg').attr('width', width).attr('height', height);
      for (var i = 0; i < 300; i++) {
        svg.append('circle').attr('cx', Math.random() * width).attr('cy', Math.random() * height).attr('opacity', 0.2).attr('r', 0).transition().attr('r', 2);
      }
    },
    didInsertElement: function didInsertElement() {
      this.draw();
    }
  });
});