define("affinio/components/modules/network-graph-export", ["exports", "affinio/mixins/affinio-module"], function (_exports, _affinioModule) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_affinioModule.default, {
    init: function init() {
      this._super.apply(this, arguments);
      Ember.set(this, 'preferences.networkRectBg', 'black');
    },
    // Element
    classNames: ['network-graph-export'],
    tagName: 'div',
    // Services
    tracker: Ember.inject.service(),
    report: Ember.computed.alias('model.report'),
    // Properties
    downloadKey: 'downloadName',
    hrefKey: 'svgURI',
    label: 'Download SVG',
    svgURI: '',
    downloadName: Ember.computed('model.{report.name,cluster.isCluster,cluster.name}', function () {
      if (Ember.get(this, 'model.cluster.isCluster')) {
        return "".concat(Ember.get(this, 'model.report.name').dasherize(), "-").concat(Ember.get(this, 'model.cluster.name').dasherize(), "-network-graph.svg");
      } else {
        return "".concat(Ember.get(this, 'model.report.name').dasherize(), "-network-graph.svg");
      }
    }),
    actions: {
      updateSvgURI: function updateSvgURI(uri) {
        this.toggleProperty('forceUpdate');
        Ember.set(this, 'svgURI', uri);
      }
    }
  });
});