define("affinio/components/correlation-matrix", ["exports", "affinio/utils/get-with-default"], function (_exports, _getWithDefault) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Element
    classNames: ['correlation-matrix'],
    tagName: 'article',
    // Properties
    triangle: true,
    showMax: false,
    showMin: true,
    hideCellValues: false,
    selectedAxisIndex: null,
    showPercent: true,
    min: 0,
    max: 1,
    // Computed Properties
    notShowMax: Ember.computed.not('showMax'),
    isSingleColumn: Ember.computed.notEmpty('selectedAxisIndex'),
    // 0 is falsy but valid
    notIsSingleColumn: Ember.computed.not('isSingleColumn'),
    shouldHideLastColumn: Ember.computed.and('triangle', 'notIsSingleColumn', 'notShowMax'),
    style: Ember.computed('axis.length', 'isSingleColumn', 'cellWidth', 'shouldHideLastColumn', function () {
      var length = Ember.get(this, 'isSingleColumn') ? 2 : Ember.get(this, 'axis.length') + (Ember.get(this, 'shouldHideLastColumn') ? 0 : 1);
      return Ember.String.htmlSafe("grid-template-columns: repeat(".concat(length, ", 1fr); width: ").concat((length - 1) * 6 + 8, "rem;"));
    }),
    d3ColorScale: Ember.computed('average', 'max', function () {
      return d3.scale.linear().domain([0, (0, _getWithDefault.default)(this, 'average', 0.5), (0, _getWithDefault.default)(this, 'max', 1)]).range(['#FFF', '#FFF', '#b9f6ca']);
    }),
    actions: {
      showCell: function showCell(cell, showMin, showMax) {
        if (showMin && showMax) {
          return true;
        } else {
          var min = Ember.get(this, 'min');
          // :thinking: rename something? we pass in max for a nice color scale
          //            showMax is used for hiding the 100% case.
          //            Should min also be 0? not currently passed in, default is 0
          var max = 1;
          if (!showMin && cell <= min || !showMax && cell >= max) {
            return false;
          } else {
            return true;
          }
        }
      },
      cellStyle: function cellStyle(cell, triangleHidden, triangleHeader) {
        return Ember.String.htmlSafe(!triangleHidden && !triangleHeader ? "background-color: ".concat(Ember.get(this, 'd3ColorScale')(cell), ";") : '');
      },
      cellClass: function cellClass(triangleHidden, triangleHeader, showCell) {
        return Ember.String.htmlSafe([triangleHidden ? 'triangle-hidden' : null, triangleHeader ? 'triangle-header' : null, showCell ? null : 'hide-cell'].compact().join(' '));
      }
    }
  });
});