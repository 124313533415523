define("affinio/components/affinio-table-cell/multi-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['multi-select'],
    tagName: 'div',
    columns: Ember.computed('rows', function () {
      var _this = this;
      return Ember.get(this, 'rows').mapBy('label').filter(function (r) {
        return r !== Ember.get(_this, 'row.label');
      });
    })
  });
});