define("affinio/templates/components/selected-source", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "weOatPSY",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"source-title\",[[23,[\"option\",\"name\"]]],null],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/selected-source.hbs"
    }
  });
});