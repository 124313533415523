define("affinio/components/embedded-media", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['embedded-media', 'media-frame'],
    classNameBindings: ['isIframe'],
    attributeBindings: ['style'],
    modifiedURL: Ember.computed('instance', function () {
      return Ember.get(this, 'instance.mediaURL') ? Ember.get(this, 'instance.mediaURL').replace('http://', 'https://') : '';
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this.determineRatio();
    },
    style: Ember.computed('width', 'height', function () {
      return Ember.String.htmlSafe("width: ".concat(Ember.get(this, 'width'), "; height: ").concat(Ember.get(this, 'height'), ";"));
    }),
    determineRatio: function determineRatio() {
      if (Ember.get(this, 'instance.label')) {
        if (Ember.get(this, 'instance.embedType') !== 'iframe') {
          Ember.set(this, 'width', 'auto');
          Ember.set(this, 'height', 'auto');
        } else if (Ember.get(this, 'instance.label').indexOf('youtube') > 0 || Ember.get(this, 'instance.label').indexOf('youtu.be') > 0) {
          Ember.set(this, 'width', '400px');
          Ember.set(this, 'height', '246px');
        } else if (Ember.get(this, 'instance.label').indexOf('vine') > 0) {
          Ember.set(this, 'width', '400px');
          Ember.set(this, 'height', '424px');
        } else if (Ember.get(this, 'instance.label').indexOf('vimeo') > 0) {
          Ember.set(this, 'width', '400px');
          Ember.set(this, 'height', '246px');
        } else if (Ember.get(this, 'instance.label').indexOf('instagram') > 0) {
          Ember.set(this, 'width', '400px');
          Ember.set(this, 'height', '500px');
        } else {
          Ember.set(this, 'width', 'auto');
          Ember.set(this, 'height', 'auto');
        }
      }
    },
    isIframe: Ember.computed('instance', function () {
      if (Ember.get(this, 'instance.embedType') === 'iframe') {
        return true;
      } else {
        return false;
      }
    })
  });
});