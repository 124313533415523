define("affinio/components/modals/modal-focus", ["exports", "affinio/components/modals/modal-base", "affinio/utils/constants/metric-icons", "affinio/utils/get-with-default", "ember-cli-string-helpers/helpers/humanize", "ember-cli-string-helpers/helpers/titleize"], function (_exports, _modalBase, _metricIcons, _getWithDefault, _humanize, _titleize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Tracked terms are not proper metrics. Use the metric the tracked term is computed from.
  var metricKeyCleanser = function metricKeyCleanser(key) {
    var m = {
      'trackedKeywords': 'keywords',
      'trackedMentions': 'mentions',
      'trackedHashtags': 'hashtags',
      'default': key
    };
    return m[key] || m.default;
  };
  var _default = _exports.default = _modalBase.default.extend({
    classNames: ['modal-focus-container'],
    graph: Ember.inject.service(),
    tracker: Ember.inject.service(),
    path: Ember.inject.service(),
    store: Ember.inject.service(),
    assetMap: Ember.inject.service(),
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;
      this._super.apply(this, arguments);
      Ember.get(this, 'tracker').track('Diving into a term', {
        label: Ember.get(this, 'label'),
        metric: Ember.get(this, 'metric'),
        tab: "".concat(Ember.get(this, 'path.currentCategory'), "/").concat(Ember.get(this, 'path.currentSubcategory'))
      });
      var realMetric = Ember.get(this, 'isNumeric') ? Ember.get(this, 'instance.originalMetric') : Ember.get(this, 'metric');
      Ember.get(this, 'graph').loadMetrics([Ember.get(this, "cluster.".concat(metricKeyCleanser(realMetric)))]).then(function () {
        if (Ember.get(_this, 'defaultView') && Ember.get(_this, 'views').includes(Ember.get(_this, 'defaultView'))) {
          Ember.set(_this, 'activeView', Ember.get(_this, 'defaultView'));
        } else {
          Ember.set(_this, 'activeView', 'overview');
        }
      }, 100);
    },
    report: Ember.computed.alias('cluster.report'),
    aggregateClustered: Ember.computed.alias('cluster.isAggregate'),
    isNumeric: Ember.computed.equal('metric', 'numericTraits'),
    labelTitle: Ember.computed('instance.clusterInstance', 'label', function () {
      var label = Ember.get(this, 'label');
      var clusterInstance = Ember.get(this, 'instance.clusterInstance');
      if (clusterInstance) {
        if (Ember.get(clusterInstance, 'name')) {
          label = Ember.get(clusterInstance, 'name');
        } else if (Ember.get(clusterInstance, 'title')) {
          label = Ember.get(clusterInstance, 'title');
        }
      }
      return label;
    }),
    metricTitle: Ember.computed('metric', function () {
      var definitions = Ember.get(this, 'report.metricDefinitions');
      var metric = metricKeyCleanser(Ember.get(this, 'metric'));
      return definitions.findBy('label', metric) ? Ember.get(definitions.findBy('label', metric), 'title') : metric;
    }),
    niceMetricName: Ember.computed('metricTitle', 'instance.originalMetric', function () {
      var reportID = Ember.get(this, 'report.id');
      var _Ember$get$peekRecord = Ember.get(this, 'store').peekRecord('report-state', reportID),
        metricLabelMap = _Ember$get$peekRecord.metricLabelMap;
      var metricTitle = Ember.get(this, 'metricTitle');
      var label = metricTitle === 'numericTraits' ? Ember.get(this, 'instance.originalMetric') : metricTitle;
      if (metricLabelMap.has(label)) {
        return metricLabelMap.get(label); // eslint-disable-line ember/use-ember-get-and-set
      } else {
        return (0, _titleize.titleize)([(0, _humanize.humanize)([label.dasherize()])]);
      }
    }),
    // For Links and Interests, for example, their label and their unique identifier are different things.
    instanceRecords: Ember.computed('recordsLookup', 'metric', 'label', 'report.recordsMap', function () {
      var recordsMap = Ember.get(this, 'report.recordsMap');
      var metric = metricKeyCleanser(Ember.get(this, 'metric'));
      var lookup = Ember.get(this, 'recordsLookup');
      if (!lookup) {
        lookup = metric === 'interests' ? Ember.get(this, 'networkID') : Ember.get(this, 'label');
      }
      /* eslint-disable */
      // jsmap
      var map = recordsMap.get(metric);
      var foundRecords = map ? map.get(lookup)
      /* eslint-enable */ : [];
      return foundRecords;
    }),
    exampleTweets: Ember.computed('instance.clusterInstance', function () {
      var exampleIDs = [];
      var posts = Ember.get(this, 'instance.clusterInstance.ids') || Ember.get(this, 'instance.clusterInstance.posts');
      if (posts) {
        exampleIDs.pushObjects(posts);
      }
      return exampleIDs;
    }),
    // activeView: 'new',

    views: Ember.computed('instance', 'exampleTweets', function () {
      var views = ['overview'];
      var metricDefinitions = Ember.get(this, 'report.metricDefinitions');
      if (metricDefinitions.findBy('label', Ember.get(this, 'metric')) && Ember.get(metricDefinitions.findBy('label', Ember.get(this, 'metric')), 'traitWeight') > 0) {
        views.push('related');
      }
      if (Ember.get(this, 'exampleTweets.length')) {
        views.push('examples');
      }
      // views.push('new');
      return views;
    }),
    clusterMetric: Ember.computed('cluster', 'metric', function () {
      return Ember.get(Ember.get(this, 'cluster'), metricKeyCleanser(Ember.get(this, 'metric')));
    }),
    instanceIcon: Ember.computed('clusterMetric', 'label', function () {
      var clusterMetric = Ember.get(this, 'clusterMetric');
      var metricDefinitions = Ember.get(this, 'cluster.report.taxonomy.metricDefinitions');
      var metricIconTaxo = '';
      var metricDefinitionSchema = metricDefinitions.findBy('label', Ember.get(this, 'metric'));
      if (metricDefinitions && metricDefinitionSchema && metricDefinitionSchema.metricIcon) {
        metricIconTaxo = metricDefinitionSchema.metricIcon;
      }
      var metricResponse = Ember.get(clusterMetric, 'response');
      var findByKey = Ember.get(this, 'report.isCustom') ? 'label' : 'screenName';
      var metricInstance = metricResponse && Ember.get(metricResponse, 'length') ? metricResponse.findBy(findByKey, Ember.get(this, 'label')) : null;
      var icon;
      if (metricInstance) {
        icon = Ember.get(metricInstance, 'iconImage');
      }
      icon = Ember.get(this, 'assetMap').resolve(icon ? icon : metricIconTaxo);
      if (!icon) {
        var metricIconObject = _metricIcons.metricIcons.findBy('label', metricKeyCleanser(Ember.get(this, 'metric')));
        if (metricIconObject) {
          icon = Ember.get(metricIconObject, 'value');
        } else {
          // If no icon, defualt to emoji
          icon = Ember.get(this, 'assetMap').resolve('/assets/images/metric-icons/trait.png');
        }
      }
      return icon;
    }),
    saturation: Ember.computed('instance', function () {
      var instance = Ember.get(this, 'instance');
      var saturation = null;
      if (instance) {
        if (Ember.get(this, 'metric') === 'interests') {
          if (!instance.hasOwnProperty('relevance')) {
            instance = Ember.get(this, 'instance.clusterInstance');
          }
          saturation = Ember.get(instance, 'relevance'); // Interests already have their relevance as roughly equivalent to a percentage of the audience.
        } else {
          if (!instance.hasOwnProperty('value')) {
            instance = Ember.get(this, 'instance.clusterInstance');
          }
          saturation = Ember.get(this, 'aggregateClustered') ? Ember.get(instance, 'value') / Ember.get(this, 'report.numberOfMembers.response.value') * 100 : Ember.get(instance, 'value') / Ember.get(this, 'cluster.numberOfMembers.response.value') * 100;
        }
      }
      return saturation;
    }),
    timesMoreLikely: Ember.computed('instance.records', function () {
      var instance = Ember.get(this, 'instance');
      var timesMoreLikely = Ember.get(this, 'aggregateClustered') ? null : Ember.get(Ember.get(instance, 'records').findBy('cluster', Ember.get(this, 'cluster.suffix')), 'timesMoreLikely.value');
      return timesMoreLikely;
    }),
    // TODO: 1. rename to something like informedInstance or something, 2. set its child properties as top-level (Object.assign?)
    instance: Ember.computed('clusterMetric.loaded', 'label', 'cluster', 'instanceRecords', function () {
      var instance = {
        records: Ember.get(this, 'instanceRecords'),
        icon: Ember.get(this, 'instanceIcon')
      };
      if (Ember.get(this, 'clusterMetric.loaded')) {
        if (Ember.get(this, 'clusterMetric.label') === 'interests') {
          Ember.set(instance, 'clusterInstance', Ember.get(this, 'clusterMetric.response').findBy('screenName', Ember.get(this, 'label'))); // eslint-disable-line ember/no-side-effects
        } else {
          Ember.set(instance, 'clusterInstance', Ember.get(this, 'clusterMetric.response').findBy('label', Ember.get(this, 'label'))); // eslint-disable-line ember/no-side-effects
        }
      }
      return instance;
    }),
    externalLink: Ember.computed('instance', 'label', function () {
      var url = '';
      var instance = Ember.get(this, 'instance.clusterInstance');
      if (Ember.get(this, 'clusterMetric.loaded') && instance) {
        if (Ember.get(instance, 'mediaURL')) {
          url = Ember.get(instance, 'mediaURL');
        }
        if (Ember.get(this, 'metric') === 'interests') {
          switch (Ember.get(this, 'report.socialNetwork')) {
            case 'Twitter':
              url = "https://twitter.com/".concat(Ember.get(instance, 'screenName'));
              break;
            case 'Instagram':
              url = "https://instagram.com/".concat(Ember.get(this, 'screenName'));
              break;
            case 'Pinterest':
              url = "https://pinterest.com/".concat(Ember.get(this, 'screenName'));
              break;
            default:
              url = '';
              break;
          }
        }
        if (Ember.get(this, 'metric') === 'links') {
          url = Ember.get(instance, 'label');
        }
        if (Ember.get(this, 'metric') === 'posts') {
          url = Ember.get(instance, 'label');
        }
        if (Ember.get(this, 'metric') === 'domains') {
          url = "http://".concat(Ember.get(instance, 'label'));
        }
      }
      return url;
    }),
    clusterOccurrences: Ember.computed('instance.{records.[],originalMetric}', 'isNumeric', function () {
      var _this2 = this;
      var clusters = Ember.get(this, 'report.clusters');
      if (Ember.get(this, 'isNumeric')) {
        var visibleClusters = clusters.rejectBy('isHidden');
        var key = "".concat(Ember.get(this, 'instance.originalMetric'), ".response.value");
        var maxValue = _.max(visibleClusters.mapBy(key));
        return visibleClusters.map(function (cluster) {
          var value = Ember.get(cluster, key);
          return {
            name: Ember.get(cluster, 'name'),
            clusterSuffix: Ember.get(cluster, 'suffix'),
            style: "width: ".concat(value / maxValue * 100, "%; background-color: ").concat(Ember.get(cluster, 'color')),
            value: value.toFixed(1)
          };
        });
      } else {
        var hiddenClusters = clusters.filterBy('isHidden');
        var filteredRecords = Ember.get(this, 'instance.records').filter(function (record) {
          return !hiddenClusters.some(function (cluster) {
            return cluster.suffix === record.cluster;
          });
        }); // filter out hidden clusters

        return filteredRecords.map(function (record) {
          return {
            name: Ember.get(clusters.findBy('suffix', Ember.get(record, 'cluster')), 'name'),
            normalizedScore: Ember.get(record, 'normalizedScore'),
            clusterSuffix: Ember.get(record, 'cluster'),
            style: "\n            width: ".concat(Ember.get(record, 'normalizedScore') / _.max(Ember.get(_this2, 'instance.records').mapBy('normalizedScore')) * 100, "%;\n            background-color: ").concat(Ember.get(clusters.findBy('suffix', Ember.get(record, 'cluster')), 'color'), "\n          ") // TODO: don't lodash
          };
        });
      }
    }),
    carouselOffset: Ember.computed('activeView', 'views', function () {
      return "position-".concat(Ember.get(this, 'views').indexOf(Ember.get(this, 'activeView')));
    }),
    comparableBins: Ember.computed(function () {
      var metricName = Ember.get(this, 'instance.originalMetric');
      var otherBins = Ember.get(this, 'report.clusters').without(Ember.get(this, 'cluster')).mapBy("".concat(metricName, ".response.structuredBins"));
      var bins = (0, _getWithDefault.default)(this, "cluster.".concat(metricName, ".response.structuredBins"), []);
      var comparables = [{
        // this cluster
        cluster: Ember.get(this, 'cluster.id'),
        color: (0, _getWithDefault.default)(this, 'cluster.color', '#444444'),
        bins: bins
      }, {
        // aggregate other clusters
        cluster: 'all',
        color: '#444444',
        bins: bins.map(function (n) {
          return {
            lower: n.lower,
            upper: n.upper,
            value: otherBins.reduce(function (prev, curr) {
              var bin = curr.find(function (m) {
                return m.lower === n.lower && m.upper === n.upper;
              });
              return prev + (bin ? bin.value : 0);
            }, 0)
          };
        })
      }];
      return comparables;
    }),
    actions: {
      setActiveView: function setActiveView(view) {
        Ember.get(this, 'tracker').track('Switched focus modal screens', {
          view: view,
          label: Ember.get(this, 'label'),
          metric: Ember.get(this, 'metric'),
          tab: "".concat(Ember.get(this, 'path.currentCategory'), "/").concat(Ember.get(this, 'path.currentSubcategory'))
        });
        Ember.set(this, 'activeView', view);
      }
    }
  });
});