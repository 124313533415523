define("affinio/components/affinio-table-cell/checkbox", ["exports", "affinio/mixins/affinio-table-disabled"], function (_exports, _affinioTableDisabled) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_affinioTableDisabled.default, {
    classNames: ['affinio-table-cell-checkbox']
  });
});