define("affinio/components/sign-in", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Element
    classNames: ['sign-in'],
    api: Ember.inject.service(),
    auth: Ember.inject.service(),
    tracker: Ember.inject.service(),
    modalManager: Ember.inject.service(),
    router: Ember.inject.service(),
    localStorage: Ember.inject.service(),
    type: 'new',
    isNew: Ember.computed.equal('type', 'new'),
    email: null,
    password: null,
    emptyFields: Ember.computed('email', 'password', function () {
      return !Ember.get(this, 'email') || !Ember.get(this, 'password');
    }),
    loggingIn: Ember.computed.or('_loggingIn', 'auth.otherTabLoginHappening'),
    disabled: Ember.computed.or('loggingIn', 'emptyFields'),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (Ember.get(this, 'ms')) {
        this.send('login', 'microsoft');
      } else {
        this.$('form input[name="email"]').focus();
      }
    },
    handleTransition: function handleTransition(sameUser) {
      // Update localStorage for getting other tabs signedIn
      Ember.set(this, 'localStorage.tabAuthInfo', {
        newUser: !sameUser,
        tabId: Ember.get(this, 'api.tabid')
      });
      Ember.get(this, 'auth').clearTabAuthInfoDelayed();

      // If new login, attempt their saved transition, fallback to reports
      if (Ember.get(this, 'isNew')) {
        var auth = Ember.get(this, 'auth');
        var transition = Ember.get(auth, 'loginTransition');
        if (transition) {
          transition.retry().then(function () {
            return Ember.set(auth, 'loginTransition', null);
          });
        } else {
          Ember.get(this, 'router').transitionTo('reports');
        }
      }

      // If existing login, check if they are the same user. If not redirct to root
      else {
        if (!sameUser) {
          Ember.get(this, 'router').transitionTo('/');
        }
        Ember.get(this, 'modalManager').closeModal();
      }
    },
    actions: {
      login: function login(authType, email, password) {
        var _this = this;
        var isNew = Ember.get(this, 'isNew');

        // Check screen width, block small screen attempts
        if (screen.width && screen.width < 960) {
          Ember.get(this, 'tracker').track('Attempted login from small screen', {
            user: email
          });
          Ember.get(this, 'modalManager').openModal('modals/modal-error', {
            noClose: true,
            title: 'Login Failed',
            reason: 'Affinio doesn\'t support tablets and small screens.',
            fix: 'Try logging in from a desktop or laptop computer'
          });
          return;
        }
        var method, resetPromise;

        // Map authType to auth service method
        switch (authType) {
          case 'affinio':
            method = 'affinioLogin';
            break;
          case 'microsoft':
            method = 'msLogin';
            break;
        }

        // If we are a new login, clear things out. Wrap else in a promise for thenability
        if (isNew) {
          resetPromise = Ember.get(this, 'auth').affinioLogout(false, false, false);
        } else {
          resetPromise = Ember.RSVP.resolve();
        }
        resetPromise.then(function () {
          // Call the auth service method
          if (method) {
            Ember.set(_this, '_loggingIn', true);
            Ember.get(_this, 'auth')[method](email, password, isNew).then(_this.handleTransition.bind(_this)).catch(function /* e */ () {}).then(function () {
              Ember.setProperties(_this, {
                password: null,
                _loggingIn: false
              });
            });
          }
        });
      }
    }
  });
});