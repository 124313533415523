define("affinio/templates/admin/user-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ZldN5dxx",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h2\"],[9],[0,\"Global User Search\"],[10],[0,\"\\n\"],[1,[27,\"admin-list\",null,[[\"isAccounts\",\"showAccounts\",\"globalSearch\",\"sectionSearch\",\"toggleAccountActive\"],[true,true,true,\"accounts-search\",[27,\"route-action\",[\"showToggleAccountActiveModal\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/admin/user-search.hbs"
    }
  });
});