define("affinio/components/delta-metric", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function toggleHoldOff(event) {
    Ember.set(event.data, 'isMouseDown', false);
    Ember.$(document).off('mouseup', toggleHoldOff);
    if (Ember.get(event.data, 'preferences.normalized') !== 'niche-ranked') {
      Ember.set(event.data, 'preferences.heldDelta', false);
    } // if not niche-ranked
  }
  var _default = _exports.default = Ember.Component.extend({
    // Services
    preferences: Ember.inject.service(),
    // Element
    tagName: 'label',
    classNames: ['delta-metric'],
    classNameBindings: ['positive', 'neutral', 'negative', 'new'],
    positive: Ember.computed.gt('diff', 0),
    neutral: Ember.computed.equal('diff', 0),
    negative: Ember.computed.lt('diff', 0),
    new: Ember.computed('delta', 'isLocation', function () {
      var key = Ember.get(this, 'isLocation') ? 'delta.previousLocationAffinityIsNew' : 'delta.isNew';
      return !!Ember.get(this, key);
    }),
    icon: Ember.computed('diff', function () {
      var diff = Ember.get(this, 'diff');
      if (parseFloat(diff) === 0 || Ember.get(this, 'new')) {
        return null;
      } else if (diff > 0) {
        return 'plus';
      } else {
        return 'minus';
      }
    }),
    description: Ember.computed('diff', function () {
      var diff = Ember.get(this, 'diff');
      if (parseFloat(diff) === 0) {
        return 'Same as last update';
      } else {
        return "".concat(Math.abs(diff), " from last update");
      }
    }),
    mouseDown: function mouseDown(evt) {
      var _this = this;
      evt.preventDefault();
      if (Ember.get(this, 'normalized') !== 'niche-ranked') {
        Ember.set(this, 'isMouseDown', true);
        Ember.run.later(function () {
          Ember.set(_this, 'preferences.heldDelta', true);
        }, 100);
        Ember.$(document).on('mouseup', this, toggleHoldOff);
      }
    } // mouseDown
  });
});