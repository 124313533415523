define("affinio/utils/report-builder/twitter/talkwalker-integration", ["exports", "affinio/utils/constants/string", "affinio/utils/auto-suggest", "affinio/utils/report-builder/twitter/bio-add-action", "affinio/utils/report-builder/advanced-modules"], function (_exports, _string, _autoSuggest, _bioAddAction, _advancedModules) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    label: 'Integration',
    naturalLanguageLabel: 'Your Talkwalker audience',
    value: 'talkwalker-integration',
    stages: [{
      label: 'Source Panel',
      heading: 'Select a Source Panel',
      description: 'You can select authors directly from a Talkwalker Project for clustering.',
      modules: [{
        value: 'talkwalker-query',
        modelFilterKey: 'sourcePanel',
        requiredFilters: ['fileID'],
        addAction: function addAction(inputController, model) {
          var talkwalker = Ember.get(model, 'talkwalker');
          if (!talkwalker) {
            Ember.set(model, 'talkwalker', {
              project: null,
              sourcePanels: []
            });
          }
          // if (!get(model, 'filters.followersOf').includes(resultScreenName.toLowerCase())) {
          //   get(model, 'filters.followersOf').pushObject(resultScreenName);
          // }
          Ember.set(inputController, 'talkwalker-query', '');
        }
      }]
    }, {
      label: 'Attributes',
      heading: 'Apply additional audience attributes:',
      description: 'These attributes are optional for this report type. Attributes are only recommended on very large file sizes so we can ensure that the minimum audience size requirement is met.',
      modules: [{
        label: 'Handle(s):',
        description: 'Filter an audience down to those who follow a given handle(s). If the handle does not appear as a recommendation, it can still be added by entering the full handle into the field and clicking Add. Input one handle at a time. Toggle AND/OR operators between multiple handles.',
        value: 'auto-suggest-input',
        valueKey: 'requestedFollowersOf',
        endpoint: _autoSuggest.twitterHandlesEndpoint,
        placeholder: '@nike',
        addAction: function addAction(inputController, model, result) {
          var existingFollowersOf = Ember.get(model, 'filters.followersOf');
          var existingNotFollowersOf = Ember.get(model, 'filters.excludedFollowersOf');
          var resultScreenName = Ember.get(result, 'label').replace(/\s|@/g, '');
          if (!existingFollowersOf) {
            Ember.set(model, 'filters.followersOf', []);
          }
          if (!existingNotFollowersOf) {
            Ember.set(model, 'filters.excludedFollowersOf', []);
          }
          /**
           * below is the logic to add or remove inclusions or exclusions
           * if model.excludeFilters.requestedFollowersOf is truthy
           *   if excludedFollowersOf DOES NOT contain this screen name AND followersOf DOES NOT contain this screen name
           *     add to excludedFollowersOf
           * else
           *  if followers of DOES NOT contain this screen name && excludedFollowersOf DOES NOT contain this screen name
           *     add to followersOf
           *   else if followers of DOES NOT contain this screen name
           * if excluded followers of contains screen name
           *     remove it from excluded
           *     add to followers of
           */
          if (Ember.get(inputController, "model.excludeFilters.".concat(Ember.get(inputController.moduleSchema, 'valueKey')))) {
            if (!Ember.get(model, 'filters.excludedFollowersOf').some(function (filter) {
              return filter.toLowerCase() === resultScreenName.toLowerCase();
            }) && !Ember.get(model, 'filters.followersOf').some(function (filter) {
              return filter.toLowerCase() === resultScreenName.toLowerCase();
            })) {
              Ember.get(model, 'filters.excludedFollowersOf').pushObject(resultScreenName);
            }
          } else {
            if (!Ember.get(model, 'filters.followersOf').some(function (filter) {
              return filter.toLowerCase() === resultScreenName.toLowerCase();
            }) && !Ember.get(model, 'filters.excludedFollowersOf').some(function (filter) {
              return filter.toLowerCase() === resultScreenName.toLowerCase();
            })) {
              Ember.get(model, 'filters.followersOf').pushObject(resultScreenName);
            } else if (!Ember.get(model, 'filters.followersOf').some(function (filter) {
              return filter.toLowerCase() === resultScreenName.toLowerCase();
            })) {
              var index = Ember.get(model, 'filters.excludedFollowersOf').findIndex(function (i) {
                return i.toLowerCase() === resultScreenName.toLowerCase();
              });
              if (index !== -1) {
                Ember.get(model, 'filters.excludedFollowersOf').removeObject(Ember.get(model, 'filters.excludedFollowersOf')[index]);
              }
              Ember.get(model, 'filters.followersOf').pushObject(resultScreenName);
            }
          }
          Ember.set(inputController, 'requestedFollowersOf', '');
        }
      }, {
        label: 'Bio Keyword(s):',
        description: 'Filter an audience based on a word(s) used in their bios. Input phrases in quotes (e.g. "loyal fan"). Comma separate between multiple terms. AND/OR operators may be used. Format combinations in parentheses. E.g. (fan AND baseball) OR (fan AND hockey). E.g. (VP OR Director OR Manager) AND ("customer experience" OR "customer service").',
        value: 'text-input',
        valueKey: 'requestedBioKeyword',
        placeholder: 'Fashion, blogger, travel',
        modelFilterKey: 'bioKeywords',
        addAction: _bioAddAction.default
      }, {
        label: 'Location(s):',
        description: 'Filter an audience based on the location specified in their bio. Comma separate between multiple places. Use quotations when your entry contains spaces to capture the entire location name (i.e. "San Francisco" or "Bangor, Maine"). If you enter multiple locations and use the OR operator, a user will be included if they specify any of them.',
        value: 'text-input',
        valueKey: 'requestedBioLocation',
        placeholder: '"San Francisco", Belgium',
        modelFilterKey: 'bioLocations',
        addAction: _bioAddAction.default
      }]
    }, {
      label: 'Options',
      heading: 'Adjust advanced report features:',
      description: 'Changing these options will not impact the makeup of your audience but can be used to fine tune the desired output of your report.',
      modules: [{
        label: 'Number of Clusters:',
        description: 'We auto-recommend a number based on estimated audience size, but you can adjust between 1-20 clusters. Select fewer clusters to break out high-level segments or more clusters to uncover niche segments. Small audiences are capped at 6 clusters (size 150-499).',
        value: 'number-input',
        min: 1,
        max: 20,
        valueKey: 'numberOfClusters',
        placeholder: 'Number of Clusters'
      }, {
        label: 'Track these Terms:',
        description: 'Track hashtags, mentions, and keywords that may fall outside of the top 20 terms we display by default. Only single terms without quotes are supported, and not phrases. Comma-separate between multiple terms.',
        value: 'text-input',
        valueKey: 'requestedTrackedTerm',
        placeholder: '#trip, @Nike',
        addAction: function addAction(inputController, model) {
          var existingTrackedTerms = Ember.get(model, 'filters.explicitWords');
          var trackedTermInputValue = Ember.get(inputController, 'requestedTrackedTerm');
          if (!existingTrackedTerms) {
            Ember.set(model, 'filters.explicitWords', []);
          }
          var terms = trackedTermInputValue.split(',').map(function (t) {
            while (t.indexOf(' ') === 0) {
              t = t.substr(1);
            }
            return t;
          });
          var cleanTerms = terms.filter(function (t) {
            return !(t.indexOf(' ') > 0);
          });
          if (cleanTerms.length) {
            cleanTerms.forEach(function (t) {
              var type;
              if (t.lastIndexOf('#', 0) === 0) {
                type = 'hashtag';
              } else if (t.lastIndexOf('@', 0) === 0) {
                type = 'mention';
              } else if (t.lastIndexOf('https:', 0) === 0 || t.lastIndexOf('http:', 0) === 0 || t.lastIndexOf('www.', 0) === 0 || t.lastIndexOf('bit.ly', 0) === 0 || t.lastIndexOf('t.co', 0) === 0 || t.indexOf('.com') > 0 || t.indexOf('.net') > 0 || t.indexOf('.org') > 0) {
                type = 'link';
              } else {
                type = 'keyword';
              }
              if (!Ember.get(model, 'filters.explicitWords').findBy('term', t)) {
                Ember.get(model, 'filters.explicitWords').pushObject({
                  term: t,
                  type: type
                });
              }
            });
            Ember.set(inputController, 'requestedTrackedTerm', '');
          } else {
            Ember.set(inputController, 'requestedTrackedTerm', '');
          }
        }
      }, {
        label: 'Interests\' Follower Range:',
        description: 'Only identify interests with a follower count that falls within a set range. This feature can help you find relevant micro-influencers. Setting a range will not impact the assignment of users to clusters.',
        value: 'min-max-input'
      }, _advancedModules.audienceSample, _advancedModules.metricDepth]
    }, {
      label: 'Set tracked interests',
      heading: 'Set tracked interests:',
      description: _string.default.reportBuilder.setBenchmarks,
      modules: [{
        label: null,
        value: 'benchmark-groups',
        placeholder: 'Tracked interests'
      }]
    }, {
      label: 'Confirm and Launch',
      heading: 'Confirm and launch:',
      description: 'Review summary and name report to launch.',
      modules: [{
        label: null,
        value: 'launch-input',
        placeholder: 'Report Name'
      }]
    }]
  };
});