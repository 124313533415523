define("affinio/components/modals/modal-keynav", ["exports", "affinio/components/modals/modal-base"], function (_exports, _modalBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _modalBase.default.extend({
    modules: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      var viewOptions = Ember.get(Ember.get(this, 'modules.schema.metricVisualization.preferences').findBy('valueKey', 'normalized'), 'options').map(function (view) {
        if (!Ember.$('.select-dropdown').children("option[value=\"".concat(Ember.get(view, 'value'), "\"]")).length) {
          Ember.set(view, 'inactive', true);
        } else {
          Ember.set(view, 'inactive', false);
        }
        return view;
      });
      Ember.set(this, 'viewOptions', viewOptions);
    }
  });
});