define("affinio/templates/components/oauth-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "DcpJa2cF",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"icon \",[21,\"icon\"]]]],[9],[1,[27,\"font-awesome\",[[23,[\"icon\"]]],null],false],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"text\"],[9],[0,\"\\n  \"],[1,[27,\"if\",[[23,[\"isAdmin\"]],[27,\"if\",[[23,[\"authorized\"]],[23,[\"authorizedAccount\"]],\"Not Authenticated\"],null],[27,\"if\",[[23,[\"authorized\"]],[23,[\"authorizedAccount\"]],\"Sign in\"],null]],null],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"authorized\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"icon clear\"],[11,\"role\",\"button\"],[9],[0,\"\\n    \"],[1,[27,\"font-awesome\",[\"trash\"],null],false],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],\"clear\"],[[\"bubbles\"],[false]]],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/oauth-button.hbs"
    }
  });
});