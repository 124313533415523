define("affinio/components/modules/media-gallery", ["exports", "affinio/mixins/affinio-module"], function (_exports, _affinioModule) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_affinioModule.default, {
    // Element
    classNames: ['media-gallery'],
    tagName: 'div',
    path: Ember.inject.service(),
    mediaGallerySchema: Ember.computed('model.{subcategory,cluster}', function () {
      return {
        stream: {
          emptyMessageLabel: 'tweets',
          handle: 'twitter-frame',
          metric: 'interestTweets',
          masonry: true
        },
        mostLiked: {
          emptyMessageLabel: "liked ".concat(Ember.get(this, 'model.report.socialNetwork').toLowerCase() === 'twitter' ? 'tweets' : 'media'),
          handle: 'twitter-frame',
          metric: 'favorites.response',
          masonry: true
        },
        mostRetweeted: {
          emptyMessageLabel: 'retweets',
          handle: 'twitter-frame',
          metric: 'retweets.response',
          masonry: true
        },
        links: {
          emptyMessageLabel: 'links',
          handle: 'site-link',
          metric: 'links.response'
        },
        internalPages: {
          emptyMessageLabel: 'links',
          handle: 'site-link',
          metric: 'internalLinks'
        },
        externalLinks: {
          emptyMessageLabel: 'links',
          handle: 'site-link',
          metric: 'externalLinks'
        },
        videos: {
          emptyMessageLabel: 'videos',
          handle: 'embedded-media',
          metric: 'videos'
        },
        posts: {
          emptyMessageLabel: 'posts',
          handle: "".concat(Ember.get(this, 'model.report.socialNetwork').toLowerCase(), "-post"),
          // TODO probably best to just have a general `post` component eventually
          metric: Ember.get(this, 'model.report.socialNetwork').toLowerCase() === 'instagram' ? 'topMedia.response' : 'posts.response',
          isReport: false,
          masonry: true
        }
      };
    }),
    hasGroupedMedia: Ember.computed('media', 'primaryMetric', function () {
      return Ember.get(this, 'media.records') ? Ember.get(this, 'media.records').map(function (mediaObj) {
        return mediaObj.hasOwnProperty('urls');
      }).every(function (result) {
        return result;
      }) : false;
    }),
    activeGallerySchema: Ember.computed('mediaGallerySchema', 'path.currentClusterId', function () {
      return Ember.get(this, "mediaGallerySchema.".concat(Ember.get(this, 'model.subcategory.value').camelize())) || {
        handle: 'site-link',
        metric: "".concat(Object.values(Ember.get(this, 'moduleRequest.metrics').objectAt(0)).objectAt(0), ".response")
      } || Ember.get(this, 'mediaGallerySchema.links');
    }),
    primaryMetric: Ember.computed('moduleRequest', 'model.subcategory.value', 'activeGallerySchema', function () {
      return Ember.get(this, 'activeGallerySchema.metric');
    }),
    media: Ember.computed('model.{cluster.id,requestsFulfilled}', 'path.currentClusterId', function () {
      if (Ember.get(this, 'model.requestsFulfilled')) {
        Ember.get(this, 'mediaGrid') ? Ember.get(this, 'mediaGrid').packery('remove', this.$('.appended')).packery('layout') : '';
        var key = Ember.get(this, 'activeGallerySchema.isReport') ? "model.report.".concat(Ember.get(this, 'primaryMetric')) : "model.cluster.".concat(Ember.get(this, 'primaryMetric'));
        var records = Ember.get(this, key);
        var max = _.max(records, function (record) {
          return record.value;
        });
        return {
          max: max,
          records: records
        };
      }
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;
      // console.log('didInsertElement');

      // TODO reinstate masonry on pages that require it (life cycles change in performance branch, no longer didInsertElement every page visit, the module persists and the grid needs to handle accordingly.)
      if (Ember.get(this, 'activeGallerySchema.masonry') && !Ember.get(this, 'mediaGrid')) {
        Ember.run.scheduleOnce('afterRender', function () {
          // passing in a selector when the records are loaded causing offsetting issues
          var loadedSelector = Ember.get(_this, 'media.records') ? {} : {
            itemSelector: ".".concat(Ember.get(_this, 'activeGallerySchema.handle'))
          };
          var mediaGrid = Ember.$('.media-gallery > .inner').packery(loadedSelector);
          Ember.set(_this, 'mediaGrid', mediaGrid);
        });
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      // console.log('didReceiveAttrs');
      if (Ember.get(this, 'activeGallerySchema.masonry') && Ember.get(this, 'mediaGrid')) {
        Ember.get(this, 'mediaGrid').packery('remove', this.$('.appended')).packery('layout');
      }
    },
    willDestroyElement: function willDestroyElement() {
      // console.log('willDestroyElement');
      if (Ember.get(this, 'mediaGrid')) {
        Ember.get(this, 'mediaGrid').packery('destroy');
      }
    },
    repackLayout: function repackLayout() {
      Ember.get(this, 'mediaGrid').packery();
    },
    actions: {
      // closure action passed down and to be used by async loaded media components once they are done loading.
      // Pass the item element to be appended to packery.
      appendLayout: function appendLayout(id, el) {
        var _this2 = this;
        Ember.run.scheduleOnce('afterRender', function () {
          Ember.get(_this2, 'mediaGrid').packery('appended', el).packery('reloadItems');
          Ember.$(el).addClass('show appended');
        });
        Ember.run.debounce(this, 'repackLayout', 3500);
      },
      deleteElement: function deleteElement(id, el) {
        Ember.$(el).remove();
      },
      repackLayout: function repackLayout() {
        this.repackLayout();
      }
    }
  });
});