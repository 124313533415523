define("affinio/templates/aton/taxonomy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "6uqEVAks",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\"],[11,\"class\",\"content-proper full-width taxonomy\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"user\",\"account\",\"isViewerSeat\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"info-notification\"],[9],[0,\"\\n      \"],[7,\"p\"],[9],[0,\"\\n        Viewer users cannot modify or create taxonomies.\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[27,\"taxonomy-creator\",null,[[\"duplicate\",\"taxonomyID\",\"dataSourceID\"],[[23,[\"duplicate\"]],[23,[\"taxonomyID\"]],[23,[\"dataSourceID\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/aton/taxonomy.hbs"
    }
  });
});