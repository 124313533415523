define("affinio/utils/constants/countries", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.expandedLanguages = _exports.default = _exports.countryBindings = void 0;
  /**
   * @module Utils
   * @submodule Constants
   */

  /**
   * An array mapping country names to their codes
   * @property countryBindings
   * @type Array of label/value objects
   * @example
   * ```
   * [
   *   {label: 'Canada', value: 'CA' }
   * ]
   * ```
   */
  var countryBindings = _exports.countryBindings = [{
    'label': 'Afghanistan',
    'value': 'AF'
  }, {
    'label': 'American Samoa',
    'value': 'AS'
  }, {
    'label': 'Argentina',
    'value': 'AR'
  }, {
    'label': 'Australia',
    'value': 'AU'
  }, {
    'label': 'Austria',
    'value': 'AT'
  }, {
    'label': 'Bahrain',
    'value': 'BH'
  }, {
    'label': 'Bangladesh',
    'value': 'BD'
  }, {
    'label': 'Belgium',
    'value': 'BE'
  }, {
    'label': 'Bhutan',
    'value': 'BT'
  }, {
    'label': 'Bolivia, Plurinational State of',
    'value': 'BO'
  }, {
    'label': 'Brazil',
    'value': 'BR'
  }, {
    'label': 'Brunei Darussalam',
    'value': 'BN'
  }, {
    'label': 'Bulgaria',
    'value': 'BG'
  }, {
    'label': 'Cambodia',
    'value': 'KH'
  }, {
    'label': 'Canada',
    'value': 'CA'
  }, {
    'label': 'Chile',
    'value': 'CL'
  }, {
    'label': 'China',
    'value': 'CN'
  }, {
    'label': 'Christmas Island',
    'value': 'CX'
  }, {
    'label': 'Colombia',
    'value': 'CO'
  }, {
    'label': 'Cook Islands',
    'value': 'CK'
  }, {
    'label': 'Costa Rica',
    'value': 'CR'
  }, {
    'label': 'Croatia',
    'value': 'HR'
  }, {
    'label': 'Cuba',
    'value': 'CU'
  }, {
    'label': 'Cyprus',
    'value': 'CY'
  }, {
    'label': 'Czech Republic',
    'value': 'CZ'
  }, {
    'label': 'Denmark',
    'value': 'DK'
  }, {
    'label': 'Dominican Republic',
    'value': 'DO'
  }, {
    'label': 'East Timor',
    'value': 'TL'
  }, {
    'label': 'Ecuador',
    'value': 'EC'
  }, {
    'label': 'Egypt',
    'value': 'EG'
  }, {
    'label': 'El Salvador',
    'value': 'SV'
  }, {
    'label': 'Estonia',
    'value': 'EE'
  }, {
    'label': 'Fiji',
    'value': 'FJ'
  }, {
    'label': 'Finland',
    'value': 'FI'
  }, {
    'label': 'France',
    'value': 'FR'
  }, {
    'label': 'French Guiana',
    'value': 'GF'
  }, {
    'label': 'French Polynesia',
    'value': 'PF'
  }, {
    'label': 'Germany',
    'value': 'DE'
  }, {
    'label': 'Ghana',
    'value': 'GH'
  }, {
    'label': 'Greece',
    'value': 'GR'
  }, {
    'label': 'Guadeloupe',
    'value': 'GP'
  }, {
    'label': 'Guam',
    'value': 'GU'
  }, {
    'label': 'Guatemala',
    'value': 'GT'
  }, {
    'label': 'Haiti',
    'value': 'HT'
  }, {
    'label': 'Honduras',
    'value': 'HN'
  }, {
    'label': 'Hong Kong',
    'value': 'HK'
  }, {
    'label': 'Hungary',
    'value': 'HU'
  }, {
    'label': 'Iceland',
    'value': 'IS'
  }, {
    'label': 'India',
    'value': 'IN'
  }, {
    'label': 'Indonesia',
    'value': 'ID'
  }, {
    'label': 'Iraq',
    'value': 'IQ'
  }, {
    'label': 'Ireland',
    'value': 'IE'
  }, {
    'label': 'Israel',
    'value': 'IL'
  }, {
    'label': 'Italy',
    'value': 'IT'
  }, {
    'label': 'Jamaica',
    'value': 'JM'
  }, {
    'label': 'Japan',
    'value': 'JP'
  }, {
    'label': 'Jordan',
    'value': 'JO'
  }, {
    'label': 'Kazakhstan',
    'value': 'KZ'
  }, {
    'label': 'Kenya',
    'value': 'KE'
  }, {
    'label': 'Kiribati',
    'value': 'KI'
  }, {
    'label': 'Korea, Republic of',
    'value': 'KR'
  }, {
    'label': 'Kuwait',
    'value': 'KW'
  }, {
    'label': 'Kyrgyzstan',
    'value': 'KG'
  }, {
    'label': 'Lao People\'s Democratic Republic',
    'value': 'LA'
  }, {
    'label': 'Latvia',
    'value': 'LV'
  }, {
    'label': 'Lebanon',
    'value': 'LB'
  }, {
    'label': 'Lithuania',
    'value': 'LT'
  }, {
    'label': 'Luxembourg',
    'value': 'LU'
  }, {
    'label': 'Macao',
    'value': 'MO'
  }, {
    'label': 'Macedonia, the former Yugoslav Republic of',
    'value': 'MK'
  }, {
    'label': 'Malaysia',
    'value': 'MY'
  }, {
    'label': 'Maldives',
    'value': 'MV'
  }, {
    'label': 'Malta',
    'value': 'MT'
  }, {
    'label': 'Marshall Islands',
    'value': 'MH'
  }, {
    'label': 'Martinique',
    'value': 'MQ'
  }, {
    'label': 'Mauritius',
    'value': 'MU'
  }, {
    'label': 'Mexico',
    'value': 'MX'
  }, {
    'label': 'Micronesia, Federated States of',
    'value': 'FM'
  }, {
    'label': 'Mongolia',
    'value': 'MN'
  }, {
    'label': 'Morocco',
    'value': 'MA'
  }, {
    'label': 'Myanmar',
    'value': 'MM'
  }, {
    'label': 'Nauru',
    'value': 'NR'
  }, {
    'label': 'Nepal',
    'value': 'NP'
  }, {
    'label': 'Netherlands',
    'value': 'NL'
  }, {
    'label': 'New Caledonia',
    'value': 'NC'
  }, {
    'label': 'New Zealand',
    'value': 'NZ'
  }, {
    'label': 'Nicaragua',
    'value': 'NI'
  }, {
    'label': 'Nigeria',
    'value': 'NG'
  }, {
    'label': 'Niue',
    'value': 'NU'
  }, {
    'label': 'Norfolk Island',
    'value': 'NF'
  }, {
    'label': 'Northern Mariana Islands',
    'value': 'MP'
  }, {
    'label': 'Norway',
    'value': 'NO'
  }, {
    'label': 'Oman',
    'value': 'OM'
  }, {
    'label': 'Pakistan',
    'value': 'PK'
  }, {
    'label': 'Palau',
    'value': 'PW'
  }, {
    'label': 'Palestinian Territories',
    'value': 'PS'
  }, {
    'label': 'Panama',
    'value': 'PA'
  }, {
    'label': 'Papua New Guinea',
    'value': 'PG'
  }, {
    'label': 'Paraguay',
    'value': 'PY'
  }, {
    'label': 'Peru',
    'value': 'PE'
  }, {
    'label': 'Philippines',
    'value': 'PH'
  }, {
    'label': 'Pitcairn Islands',
    'value': 'PN'
  }, {
    'label': 'Poland',
    'value': 'PL'
  }, {
    'label': 'Portugal',
    'value': 'PT'
  }, {
    'label': 'Puerto Rico',
    'value': 'PR'
  }, {
    'label': 'Qatar',
    'value': 'QA'
  }, {
    'label': 'Romania',
    'value': 'RO'
  }, {
    'label': 'Russia',
    'value': 'RU'
  }, {
    'label': 'Saint Barthelemy',
    'value': 'BL'
  }, {
    'label': 'Saint Martin',
    'value': 'MF'
  }, {
    'label': 'Samoa',
    'value': 'WS'
  }, {
    'label': 'Saudi Arabia',
    'value': 'SA'
  }, {
    'label': 'Serbia',
    'value': 'RS'
  }, {
    'label': 'Singapore',
    'value': 'SG'
  }, {
    'label': 'Slovakia',
    'value': 'SK'
  }, {
    'label': 'Slovenia',
    'value': 'SI'
  }, {
    'label': 'Solomon Islands',
    'value': 'SB'
  }, {
    'label': 'South Africa',
    'value': 'ZA'
  }, {
    'label': 'Spain',
    'value': 'ES'
  }, {
    'label': 'Sri Lanka',
    'value': 'LK'
  }, {
    'label': 'Sweden',
    'value': 'SE'
  }, {
    'label': 'Switzerland',
    'value': 'CH'
  }, {
    'label': 'Taiwan, Province of China',
    'value': 'TW'
  }, {
    'label': 'Tajikistan',
    'value': 'TJ'
  }, {
    'label': 'Thailand',
    'value': 'TH'
  }, {
    'label': 'Tokelau',
    'value': 'TK'
  }, {
    'label': 'Tonga',
    'value': 'TO'
  }, {
    'label': 'Tunisia',
    'value': 'TN'
  }, {
    'label': 'Turkey',
    'value': 'TR'
  }, {
    'label': 'Turkmenistan',
    'value': 'TM'
  }, {
    'label': 'Tuvalu',
    'value': 'TV'
  }, {
    'label': 'Ukraine',
    'value': 'UA'
  }, {
    'label': 'United Arab Emirates',
    'value': 'AE'
  }, {
    'label': 'United Kingdom',
    'value': 'GB'
  }, {
    'label': 'United States Minor Outlying Islands',
    'value': 'UM'
  }, {
    'label': 'United States',
    'value': 'US'
  }, {
    'label': 'Uruguay',
    'value': 'UY'
  }, {
    'label': 'Uzbekistan',
    'value': 'UZ'
  }, {
    'label': 'Vanuatu',
    'value': 'VU'
  }, {
    'label': 'Venezuela, Bolivarian Republic of',
    'value': 'VE'
  }, {
    'label': 'Viet Nam',
    'value': 'VN'
  }, {
    'label': 'Wallis and Futuna',
    'value': 'WF'
  }];
  var expandedLanguages = _exports.expandedLanguages = [{
    label: 'en',
    expanded: 'English',
    icon: '🇬🇧'
  }, {
    label: 'zh-Hans',
    expanded: 'Chinese Simplified',
    icon: '🇨🇳'
  }, {
    label: 'nl',
    expanded: 'Dutch',
    icon: '🇳🇱'
  }, {
    label: 'fr',
    expanded: 'French',
    icon: '🇫🇷'
  }, {
    label: 'de',
    expanded: 'German',
    icon: '🇩🇪'
  }, {
    label: 'hi',
    expanded: 'Hindi',
    icon: '🇮🇳'
  }, {
    label: 'it',
    expanded: 'Italian',
    icon: '🇮🇹'
  }, {
    label: 'pl',
    expanded: 'Polish',
    icon: '🇵🇱'
  }, {
    label: 'es',
    expanded: 'Spanish',
    icon: '🇪🇸'
  }, {
    label: 'tr',
    expanded: 'Turkish',
    icon: '🇹🇷'
  }];

  /**
   * @module Utils
   * @submodule Constants
   * @class Countries
   */
  var _default = _exports.default = {
    countryBindings: countryBindings,
    expandedLanguages: expandedLanguages
  };
});