define("affinio/templates/reports/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "gZiOUKi8",
    "block": "{\"symbols\":[],\"statements\":[[1,[21,\"top-level-nav\"],false],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"app\",\"outageInfo\",\"onReportList\"]]],null,{\"statements\":[[0,\"  \"],[7,\"p\"],[11,\"class\",\"warning\"],[9],[0,\"\\n    \"],[1,[23,[\"app\",\"outageInfo\",\"message\"]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[27,\"report-list\",null,[[\"search\",\"_reports\",\"folder\",\"isArchive\",\"entity\",\"entityType\",\"networkFilter\",\"editReports\",\"saveFolder\",\"createFolderModal\",\"clearFolderCP\"],[[23,[\"search\"]],[23,[\"user\",\"reports\"]],[23,[\"folderParam\"]],[23,[\"isArchive\"]],[23,[\"selectedEntity\"]],[23,[\"selectedEntityType\"]],[23,[\"network\"]],[27,\"route-action\",[\"editReports\"],null],[27,\"route-action\",[\"saveFolder\"],null],[27,\"route-action\",[\"createFolderModal\"],null],[27,\"action\",[[22,0,[]],\"clearFolderQP\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/reports/index.hbs"
    }
  });
});