define("affinio/components/affinio-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Element
    classNames: ['affinio-table'],
    classNameBindings: ['noMaxHeight'],
    // Properties
    columns: null,
    // Pass this in
    rows: null,
    // Pass this in

    actions: {
      toggleProperty: function toggleProperty(row, property) {
        var toggleProperty = Ember.get(this, 'togglePropertyAction');
        if (toggleProperty && typeof toggleProperty === 'function') {
          toggleProperty(row, property);
        } else {
          Ember.set(row, property, Ember.get(row, property));
        }
      },
      toggleAllProperty: function toggleAllProperty(column, e) {
        var toggleAllProperty = Ember.get(this, 'toggleAllPropertyAction');
        if (toggleAllProperty && typeof toggleAllProperty === 'function') {
          toggleAllProperty(column, e);
        } else {
          Ember.get(this, 'rows').setEach(column.valuePath, e);
        }
      },
      showUnique: function showUnique(valuePath, isUnique) {
        var acceptableFields = ['identifier', 'joiningColumn'];
        if (acceptableFields.includes(valuePath)) {
          return isUnique;
        } else {
          return false;
        }
      },
      rowSelect: function rowSelect(item) {
        var onSelect = Ember.get(this, 'onSelect');
        if (onSelect && typeof onSelect === 'function') {
          onSelect(item);
        }
      }
    }
  });
});