define("affinio/components/modals/modal-asset-group", ["exports", "affinio/components/modals/modal-base"], function (_exports, _modalBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _modalBase.default.extend({
    classNames: ['modal-asset-group'],
    tracker: Ember.inject.service(),
    user: Ember.inject.service(),
    // to get user.account.preferences.shouldNameClusters (template)
    notify: Ember.inject.service(),
    modalManager: Ember.inject.service(),
    path: Ember.inject.service(),
    graph: Ember.inject.service(),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      if (Ember.get(this, 'assetGroup.name') === 'untitled group') {
        Ember.run.scheduleOnce('afterRender', function () {
          Ember.$('.asset-group input.benchmark-title').select();
        });
      }
    },
    actions: {
      ok: function ok() {
        var unenteredValue = Ember.get(this, 'inputValue');
        var errorMessage = Ember.get(this, 'errorMessage');
        if (unenteredValue && !errorMessage) {
          Ember.set(this, 'errorMessage', true);
          return;
        }
        var okAction = Ember.get(this, 'okAction');
        if (typeof okAction === 'function') {
          okAction();
        }
        this.closeAction();
      }
    }
  });
});