define("affinio/templates/components/social-network-estimate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Ik+p594D",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"header\"],[9],[0,\"\\n  \"],[7,\"label\"],[11,\"class\",\"clearfix\"],[9],[0,\"\\n    \"],[1,[27,\"input\",null,[[\"type\",\"checked\"],[\"checkbox\",[23,[\"network\",\"selected\"]]]]],false],[0,\"\\n    \"],[7,\"h4\"],[9],[1,[23,[\"network\",\"title\"]],false],[10],[0,\"\\n    \"],[7,\"span\"],[9],[1,[23,[\"network\",\"populationString\"]],false],[0,\" Profiles\"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/social-network-estimate.hbs"
    }
  });
});