define("affinio/components/heatmap-grid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Element
    classNames: ['heatmap-grid'],
    tagName: 'div',
    preferences: Ember.inject.service(),
    colorScale: Ember.computed('records', function () {
      return d3.scale.linear().domain([Ember.get(this, 'records').hours.min.average, Ember.get(this, 'records').hours.max.average - (Ember.get(this, 'records').hours.max.average - Ember.get(this, 'records').hours.min.average) / 2, Ember.get(this, 'records').hours.max.average]).range(['#c00', '#ffffff', '#4DB930']);
    }),
    delayScale: Ember.computed('records', function () {
      return d3.scale.linear().domain([0, Ember.get(this, 'records.hours.max.average') / 2, Ember.get(this, 'records.hours.max.average')]).range([250, 850, 250]);
    }),
    opacityScale: Ember.computed('records', 'preferences.activeEngagement', function () {
      if (Ember.get(this, 'preferences.activeEngagement') === 'max') {
        // console.log('opacityScale is preferences.activeEngagement');
        return d3.scale.linear().domain([0, Ember.get(this, 'records.hours.max.average')]).range([0.1, 1]);
      } else if (Ember.get(this, 'preferences.activeEngagement') === 'min') {
        // console.log('opacityScale is preferences.activeEngagement');
        return d3.scale.linear().domain([0, Ember.get(this, 'records.hours.max.average')]).range([1, 0.1]);
      } else {
        // console.log('opacityScale is none');
        return d3.scale.linear().domain([0, 1]).range([1, 1]);
      }
    }),
    actions: {
      setHighlightedTime: function setHighlightedTime(time) {
        // console.log('setHighlightedTime', time /* , get(this, 'records').findBy('label', get(time, 'day')) */);
        if (!Ember.get(this, 'preferences.highlightStickied')) {
          Ember.set(time, 'highlight', true);
          Ember.set(time, 'stickied', true);
          Ember.set(Ember.get(this, 'records').findBy('label', Ember.get(time, 'day')), 'highlight', true);
          Ember.set(this, 'preferences.highlightedTime', time);
        }
      },
      clearHighlightedTime: function clearHighlightedTime(time) {
        if (!Ember.get(this, 'preferences.highlightStickied')) {
          // console.log('clearHighlightedTime', get(time, 'day'), get(this, 'records.hours.min.day'), get(time, 'label'), get(this, 'records.hours.min.label'));
          Ember.set(time, 'highlight', false);
          Ember.set(time, 'stickied', false);
          Ember.set(Ember.get(this, 'records').findBy('label', Ember.get(time, 'day')), 'highlight', false);
          Ember.set(this, 'preferences.highlightedTime', null);
        }
      },
      stickyHighlightedTime: function stickyHighlightedTime(time) {
        // console.log('clicked', time, get(this, 'preferences.highlightedTime'));
        if (Ember.get(this, 'preferences.highlightStickied') && Ember.get(this, 'preferences.highlightedTime.label') === Ember.get(time, 'label') && Ember.get(this, 'preferences.highlightedTime.day') === Ember.get(time, 'day')) {
          // console.log('stickyHighlight condition 1');
          Ember.set(time, 'highlight', false);
          Ember.set(time, 'stickied', false);
          // set(this, 'preferences.highlightedTime.stickied', false);
          Ember.set(Ember.get(this, 'records').findBy('label', Ember.get(time, 'day')), 'highlight', false);
          Ember.set(this, 'preferences.highlightStickied', false);
          Ember.set(this, 'preferences.highlightedTime', null);
        } else if (Ember.get(this, 'preferences.highlightStickied') && (Ember.get(this, 'preferences.highlightedTime.label') !== Ember.get(time, 'label') || Ember.get(this, 'preferences.highlightedTime.day') !== Ember.get(time, 'day'))) {
          // console.log('stickyHighlight condition 2');
          Ember.set(Ember.get(this, 'records').findBy('label', Ember.get(this, 'preferences.highlightedTime.day')), 'highlight', false);
          Ember.set(time, 'highlight', true);
          Ember.set(time, 'stickied', true);
          Ember.set(this, 'preferences.highlightedTime.stickied', false);
          Ember.set(Ember.get(this, 'records').findBy('label', Ember.get(time, 'day')), 'highlight', true);
          Ember.set(this, 'preferences.highlightStickied', true);
          Ember.set(this, 'preferences.highlightedTime', time);
        } else {
          // console.log('stickyHighlight condition 3');
          Ember.set(time, 'highlight', true);
          Ember.set(time, 'stickied', true);
          // set(this, 'preferences.highlightedTime.stickied', false);
          Ember.set(Ember.get(this, 'records').findBy('label', Ember.get(time, 'day')), 'highlight', true);
          Ember.set(this, 'preferences.highlightStickied', true);
          Ember.set(this, 'preferences.highlightedTime', time);
        }
      }
    }
  });
});