define("affinio/templates/components/modules/audience-visualization", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "J5eresD5",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[22,0,[]]]],[0,\"\\n\"],[4,\"if\",[[27,\"is-equal\",[[23,[\"visualizationType\"]],\"network-graph\"],null]],null,{\"statements\":[[0,\"  \"],[1,[27,\"network-graph\",null,[[\"model\",\"clusters\",\"showLabels\",\"labelBackground\",\"svg\",\"reportId\",\"updateSvgURI\",\"labelRoute\",\"baselined\"],[[23,[\"model\"]],[23,[\"model\",\"report\",\"clustersLite\"]],[23,[\"preferences\",\"showVisualizationLabels\"]],[23,[\"preferences\",\"audienceVizBg\"]],[23,[\"model\",\"report\",\"clusterSVGURL\",\"response\",\"value\"]],[23,[\"model\",\"report\",\"id\"]],[27,\"action\",[[22,0,[]],\"updateSvgURI\"],null],\"reports.report.version.cluster.category.subcategory\",[23,[\"model\",\"report\",\"baselineReport\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[27,\"is-equal\",[[23,[\"visualizationType\"]],\"audience-size\"],null]],null,{\"statements\":[[0,\"  \"],[1,[27,\"pie-chart\",null,[[\"model\",\"records\",\"height\",\"width\",\"showLabels\"],[[23,[\"model\"]],[23,[\"model\",\"report\",\"clusterMemberCounts\"]],500,450,[23,[\"preferences\",\"showVisualizationLabels\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/modules/audience-visualization.hbs"
    }
  });
});