define("affinio/templates/components/affinio-table-cell/radio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "sSY33PHH",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"affinio-table-cell-radio \",[27,\"if\",[[27,\"get\",[[23,[\"row\"]],[23,[\"column\",\"valuePath\"]]],null],\"checked\"],null]]]],[11,\"role\",\"button\"],[9],[0,\"\\n  \"],[7,\"label\"],[11,\"class\",\"report-check\"],[9],[10],[0,\"\\n\"],[3,\"action\",[[22,0,[]],\"click\"]],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/affinio-table-cell/radio.hbs"
    }
  });
});