define("affinio/templates/components/audience-size", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "oFoACUGc",
    "block": "{\"symbols\":[\"sizeOption\",\"&default\"],\"statements\":[[14,2],[0,\"\\n\"],[7,\"form\"],[11,\"class\",\"userForm advertisingForm clearfix\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"form-proper\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"title\"]]],null,{\"statements\":[[4,\"if\",[[23,[\"largeTitle\"]]],null,{\"statements\":[[0,\"        \"],[7,\"h3\"],[9],[1,[21,\"title\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"h4\"],[9],[1,[21,\"title\"],false],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[4,\"each\",[[23,[\"sizes\"]]],null,{\"statements\":[[0,\"      \"],[7,\"label\"],[9],[0,\"\\n        \"],[1,[27,\"radio-button\",null,[[\"name\",\"groupValue\",\"value\"],[\"audience_size\",[23,[\"size\"]],[22,1,[]]]]],false],[0,\"\\n        \"],[7,\"span\"],[9],[0,\"Up to \"],[1,[27,\"commafy-number\",[[22,1,[]]],null],false],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/audience-size.hbs"
    }
  });
});