function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
define("affinio/components/report-builder/launch-input", ["exports", "ember-copy", "affinio/utils/mutations", "affinio/utils/get-with-default", "affinio/utils/affinio", "affinio/utils/week-year-calculator"], function (_exports, _emberCopy, _mutations, _getWithDefault, _affinio, _weekYearCalculator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['launch-input'],
    tagName: 'div',
    graph: Ember.inject.service(),
    tracker: Ember.inject.service(),
    modalManager: Ember.inject.service(),
    user: Ember.inject.service(),
    localStorage: Ember.inject.service(),
    path: Ember.inject.service(),
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    taxos: Ember.inject.service(),
    launchButtonText: 'Launch',
    launching: false,
    canLaunch: Ember.computed('controller.{reportTypeRequirementsMet,controller.activeReportType}', 'model.{estimateTotal,name,filters.queryID,followersOfBoolean}', function () {
      var _this = this;
      if (Ember.get(this, 'controller.activeNetwork.isCustom')) {
        return Ember.get(this, 'model.name.length') > 0;
      } else {
        if (!Ember.get(this, 'model.name.length') || !Ember.get(this, 'controller.reportTypeRequirementsMet')) {
          return false;
        }
        if (Ember.get(this, 'controller.activeReportType.value') === 'csv-upload' || Ember.get(this, 'controller.activeReportType.value') === 'talkwalker-integration') {
          return true;
        }
        var estimate = Ember.get(this, 'model.estimateTotal');
        if (estimate && Ember.get(this, 'model.followersOfBoolean') === 'OR') {
          return estimate >= Ember.get(this, 'controller.minEstimate');
        }
        var cards = _toConsumableArray(document.querySelectorAll('.followers-of .summary-card'));
        var estimates = cards.map(function (card) {
          return Number(card.querySelector('.estimate') ? card.querySelector('.estimate').innerHTML.replace(',', '') || 0 : 0);
        });
        if (Ember.get(this, 'model.newFollowersOnly')) {
          // Grab cached estimate info from the summary-card localStorage setters
          var handles = cards.map(function (card) {
            return card.querySelector('span.value') ? card.querySelector('span.value').innerHTML : '';
          });
          estimates = handles.map(function (handle) {
            return Number(Ember.get(_this, "localStorage.".concat(handle.replaceAll('.', '___'))) || 0);
          });
        }
        if (Ember.get(this, 'model.followersOfBoolean') === 'OR') {
          estimate = estimates.reduce(function (acc, n) {
            return acc + n;
          }, 0);
        } else {
          estimate = Math.min.apply(Math, _toConsumableArray(estimates));
        }
        return !estimate || estimate >= Ember.get(this, 'controller.minEstimate');
      }
    }),
    actions: {
      launch: function launch() {
        var _this2 = this;
        Ember.set(this, 'launchButtonText', 'Launching...');
        Ember.set(this, 'launching', true);
        var customFilters = null;
        var taxoId = Ember.get(this, 'controller.activeNetwork.taxoID');
        var taxonomy = Ember.get(this, 'taxos.taxonomies').findBy('id', Ember.get(this, 'controller.activeNetwork.taxoID'));

        // Setup the structured filters object if we are a custom network
        if (Ember.get(this, 'controller.activeNetwork.isCustom')) {
          // TODO probably want to handle the case where findBy returns nothing better
          var flatModelFilters = _.flatten(Object.values(Ember.get(this, 'model.filters'))).map(function (n) {
            // delete auto suggest specific stuff
            delete n.count;
            delete n.inputKey;
            delete n.active;
            return n;
          });
          // If the user has both queries sqlFilters will show up as undefined in grouped. This is ok and we handle it by catching the undefined key explicitly
          var grouped = _.groupBy(flatModelFilters, 'datasourceid');
          var sqlMode = Ember.get(this, 'model.sqlMode');
          customFilters = Object.keys(sqlMode ? Ember.get(this, 'model.filters.sqlQueries') : grouped).map(function (dsid) {
            if (dsid !== 'undefined') {
              if (!sqlMode) {
                grouped[dsid] = _.groupBy(grouped[dsid], 'field');
              }
              return {
                id: dsid,
                filters: _defineProperty({
                  op: sqlMode ? 'OR' : Ember.get(_this2, "model.customBooleans.primary.".concat(dsid))
                }, sqlMode ? 'leaves' : 'branches', sqlMode ? [{
                  type: 'SQL',
                  value: Ember.get(_this2, "model.filters.sqlQueries.".concat(dsid))
                }] : Object.keys(grouped[dsid]).map(function (field) {
                  return {
                    op: Ember.get(_this2, "model.customBooleans.fields.".concat(field)),
                    leaves: grouped[dsid][field].map(function (n) {
                      return {
                        type: 'STRUCTURED',
                        value: n.value,
                        condition: n.condition,
                        source: n.field
                      };
                    })
                  };
                }))
              };
            }
          }).compact();
        }
        var filters = Ember.get(this, 'controller.activeNetwork.isCustom') ? JSON.stringify({
          data_sources: customFilters
        }) // eslint-disable-line camelcase
        : (0, _emberCopy.copy)(Ember.get(this, 'model.filters'), true);
        var report = {
          name: Ember.get(this, 'model.name'),
          socialNetwork: Ember.get(this, 'controller.network'),
          numberOfClusters: (0, _getWithDefault.default)(this, 'model.numberOfClusters', Ember.get(this, 'model.estimatedNumberOfClusters')),
          filters: filters,
          isCustomReport: Ember.get(this, 'controller.activeNetwork.isCustom'),
          taxoId: taxoId
        };
        // if you manually enter # of clusters, then remove it, the input box returns an empty string
        // empty string is valid in getWithDefault, so we have to manually set the estimateNumberOfClusters
        // JK July 2018
        if (report.numberOfClusters === '') {
          report.numberOfClusters = Ember.get(this, 'model.estimatedNumberOfClusters');
        }
        // Twitter filter edits
        if (Ember.get(this, 'controller.network') === 'Twitter') {
          // Set followersOfIntersection if !default boolean value
          Ember.set(report.filters, 'followersIntersection', Ember.get(this, 'model.followersOfBoolean') === 'AND' ? true : null);
          // Set primaryBoolean if !default boolean value
          if (Ember.get(this, 'model.primaryBoolean') === 'OR') {
            Ember.set(report.filters, 'union', true);
          }
          // Translate bioKeywords to a string with proper boolean value
          if (Ember.get(report.filters, 'bioKeywords') && Ember.get(report.filters, 'bioKeywords').length) {
            Ember.set(report.filters, 'bioKeywords', Ember.get(report.filters, 'bioKeywords').join(" ".concat(Ember.get(this, 'model.bioKeywordsBoolean'), " ")));
          }
          // Translate excludedBioKeywords to a string with proper boolean value
          if (Ember.get(report.filters, 'excludedBioKeywords') && Ember.get(report.filters, 'excludedBioKeywords').length) {
            Ember.set(report.filters, 'excludedBioKeywords', Ember.get(report.filters, 'excludedBioKeywords').join(' OR '));
          }
          // Translate contentQuery to a string with proper boolean value
          if (Ember.get(report.filters, 'contentQuery') && Ember.get(report.filters, 'contentQuery').length) {
            Ember.set(report.filters, 'contentQuery', Ember.get(report.filters, 'contentQuery').join(" ".concat(Ember.get(this, 'model.contentQueryBoolean'), " ")));
          }
          // Remove empty arrays from filters
          Object.entries(report.filters).forEach(function (_ref) {
            var _ref2 = _slicedToArray(_ref, 2),
              key = _ref2[0],
              filter = _ref2[1];
            if (Array.isArray(filter) && filter.length === 0) {
              delete report.filters[key];
            }
          });
          // New Twitter Followers
          if (Ember.get(this, 'model.newFollowersOnly')) {
            Ember.setProperties(report.filters, {
              followersStartWeekYear: (0, _weekYearCalculator.default)(Ember.get(this, 'model.filters.followersStartDate')),
              followersEndWeekYear: (0, _weekYearCalculator.default)(Ember.get(this, 'model.filters.followersEndDate'))
            });
          }

          // New CSV Format
          if (Ember.get(report.filters, 'fileID')) {
            delete report.filters.fileURL;
          }
          delete report.filters.fullMembersExport;
        }

        // Don't allow if contentQuery is too long
        if (Ember.get(report.filters, 'contentQuery') && Ember.get(report.filters, 'contentQuery.length') > 2048) {
          Ember.get(this, 'modalManager').openModal('modals/modal-text', {
            title: 'Query too large',
            message: 'Our system is optimized to support queries of 2048 characters or fewer. Please remove some keywords or operators',
            okText: 'Got it',
            okAction: true
          });
          return;
        }

        // Don't allow if user has 0 credits
        if (!Ember.get(this, 'user.company.credits')) {
          var trackObject = {
            numberOfClusters: Ember.get(report, 'numberOfClusters'),
            type: Ember.get(this, 'controller.reportType'),
            source: Ember.get(this, 'controller.activeNetwork.label'),
            user: Ember.get(this, 'user.email'),
            filters: JSON.stringify(Ember.get(report, 'filters'))
          };
          Ember.get(this, 'tracker').track('New Campaign - Error saving new report', trackObject);
          Ember.get(this, 'modalManager').openModal('modals/modal-error', {
            title: 'Error Launching Report',
            reason: 'You are out of report credits.',
            fix: 'To add more credits, please contact us',
            hideSupport: true
          });
          return;
        }

        // Launching
        Ember.get(this, 'graph').mutate(_mutations.default.launchReport(report)).then(function (response) {
          var trackObject = {
            idOfCampaign: response.id,
            numClusters: Ember.get(report, 'numberOfClusters'),
            type: Ember.get(_this2, 'controller.reportType'),
            hasExclusions: (0, _affinio.hasExclusions)(Ember.get(_this2, 'model.filters')),
            source: Ember.get(_this2, 'controller.activeNetwork.label'),
            user: Ember.get(_this2, 'user.email'),
            company: Ember.get(_this2, 'user.company.name'),
            translation: Ember.get(report, 'filters.translationCode'),
            newFollowers: Ember.get(_this2, 'model.newFollowersOnly'),
            hasBenchmarks: Ember.get(_this2, 'model.filters.benchmarkGroups.length') > 0,
            benchmarkGroups: Ember.get(_this2, 'model.filters.benchmarkGroups.length'),
            benchmarkMemberTotal: (Ember.get(_this2, 'model.filters.benchmarkGroups') || []).mapBy('members.length').reduce(function (n, m) {
              return n + m;
            }, 0)
          };
          if (Ember.get(_this2, 'controller.activeReportType.value') === 'csv-upload') {
            trackObject.followersOfCsv = Ember.get(_this2, 'model.filters.followersOfCSV');
            trackObject.isHandles = Ember.get(_this2, 'model.filters.isHandles');
          }
          Ember.get(_this2, 'tracker').track('New Campaign Created', trackObject);
          Ember.set(_this2, 'user.company.credits', Ember.get(_this2, 'user.company.credits') - 1);
          if (Ember.get(_this2, 'controller.activeNetwork.isCustom')) {
            Ember.set(taxonomy, 'noReports', false);
            Ember.set(taxonomy, 'canEdit', false);
            var dataSourceIds = [];
            dataSourceIds.push.apply(dataSourceIds, _toConsumableArray(Ember.get(taxonomy, 'dataSources').mapBy('id')));
            dataSourceIds.push.apply(dataSourceIds, _toConsumableArray(Ember.get(taxonomy, 'dataSources').mapBy('externalRelated').compact().map(function (n) {
              return n.mapBy('externalId').compact();
            }).flatten()));
            var store = Ember.get(_this2, 'store');
            dataSourceIds.forEach(function (id) {
              var record = store.peekRecord('taxonomies/data-source', id);
              if (record) {
                Ember.set(record, 'canEdit', false);
              }
            });
          }
          Ember.get(_this2, 'modalManager').openModal('modals/modal-success', {
            title: 'Report Generating',
            message: 'Great! Your new report is now being generated. You\'ll receive an email upon completion.',
            beforeCloseAction: Ember.get(_this2, 'actions.headToAudiences').bind(_this2),
            closeAfter: 3500
          }); // modal-success
          Ember.set(_this2, 'launching', false);
        }).catch(function (err) {
          var error = err.payload.errors[0].message;
          var isDupe = err.payload.errors[0].message.indexOf('Company contains a similar') > -1 || err.payload.errors[0].message.indexOf('Company contains similar') > -1;
          if (isDupe) {
            var store = Ember.get(_this2, 'store');
            var reportNames = Ember.A();
            var isMultipleReportDupes = err.payload.errors[0].message.indexOf('reports: ') > -1;
            // Get all the dupe report id's
            if (isMultipleReportDupes) {
              var _err$payload$errors$ = err.payload.errors[0].message.split('reports: '),
                _err$payload$errors$2 = _slicedToArray(_err$payload$errors$, 2),
                stringIds = _err$payload$errors$2[1];
              var reportIds = stringIds.split(', ');
              reportIds.forEach(function (r) {
                var report = store.peekRecord('reports/twitter', r) || store.peekRecord('reports/pinterest', r) || store.peekRecord('reports/instagram', r) || store.peekRecord('reports/custom', r);
                if (report) {
                  reportNames.push(Ember.get(report, 'name'));
                } else {
                  reportNames.push(r);
                }
              });
            } else {
              var _err$payload$errors$3 = err.payload.errors[0].message.split('report: '),
                _err$payload$errors$4 = _slicedToArray(_err$payload$errors$3, 2),
                reportId = _err$payload$errors$4[1];
              var _report = store.peekRecord('reports/twitter', reportId) || store.peekRecord('reports/pinterest', reportId) || store.peekRecord('reports/instagram', reportId) || store.peekRecord('reports/custom', reportId);
              if (_report) {
                reportNames.push(Ember.get(_report, 'name'));
              }
            }
            var _trackObject = {
              numClusters: Ember.get(report, 'numberOfClusters'),
              type: Ember.get(_this2, 'controller.reportType'),
              source: Ember.get(_this2, 'controller.activeNetwork.label'),
              user: Ember.get(_this2, 'user.email'),
              error: error
            };
            Ember.get(_this2, 'tracker').track('New Campaign - Error saving new report', _trackObject);
            Ember.get(_this2, 'modalManager').openModal('modals/modal-error', {
              title: 'Error Launching Report',
              reason: "We've detected the following duplicate report(s): ".concat(reportNames.toString().replace(',', ', '), ". ").concat((0, _getWithDefault.default)(err, 'payload.errors.0.detailedMessage', '')),
              fix: 'Please adjust the filters or number of clusters, and try again. If you have any questions, contact our support team',
              hideSupport: true
            });
          } else {
            var _trackObject2 = {
              user: Ember.get(_this2, 'user.email'),
              numberOfClusters: Ember.get(report, 'numberOfClusters'),
              source: Ember.get(_this2, 'controller.activeNetwork.label'),
              filters: JSON.stringify(Ember.get(report, 'filters'))
            };
            Ember.get(_this2, 'tracker').track('New Campaign - Error saving new report', _trackObject2);
            Ember.get(_this2, 'modalManager').openModal('modals/modal-error', {
              title: 'Error Launching Report',
              reason: 'Unfortunately, we were unable to launch this report.',
              fix: 'Please check all inputs and try again, or contact us for help',
              hideSupport: true
            });
          }
          Ember.set(_this2, 'launchButtonText', 'Launch');
          Ember.set(_this2, 'launching', false);
        });
      },
      headToAudiences: function headToAudiences() {
        // eslint-disable-next-line ember/closure-actions
        this.sendAction('resetRoute');
        Ember.set(this, 'controller.stage', 1);
        Ember.get(this, 'router').transitionTo('reports');
      }
    }
  });
});