define("affinio/components/report-builder/text-input", ["exports", "affinio/mixins/input-module", "affinio/utils/get-with-default"], function (_exports, _inputModule, _getWithDefault) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_inputModule.default, {
    classNames: ['text-input'],
    condition: 'includes',
    customStringConditions: Ember.A(['includes', 'does not include', 'matches']),
    showAdvancedFilters: Ember.computed('moduleSchema.advanced', function () {
      return (0, _getWithDefault.default)(this, 'moduleSchema.advanced', true);
    }),
    modalManager: Ember.inject.service(),
    // TODO this is hacky, but it allows us access to modalManager from within taxonomy add functions
    actions: {
      runAddActionClosure: function runAddActionClosure() {
        Ember.get(this, 'moduleSchema.addAction')(this, Ember.get(this, 'model'));
        this.toggleProperty('model.filterChange');
      },
      addCustomFilter: function addCustomFilter() {
        var filter = Ember.get(this, 'filter');
        var existingFilter = Ember.get(this, "model.filters.".concat(Ember.get(this, 'moduleSchema.label').camelize()));
        if (!existingFilter || !existingFilter.length) {
          Ember.set(this, "model.filters.".concat(Ember.get(this, 'moduleSchema.label').camelize()), []);
        }
        Ember.get(this, "model.filters.".concat(Ember.get(this, 'moduleSchema.label').camelize())).pushObject({
          field: Ember.get(this, 'moduleSchema.label'),
          condition: Ember.get(this, 'condition'),
          value: filter.replace(/'/g, "\\'"),
          datasourceid: Ember.get(this, 'moduleSchema.datasourceid')
        });
        // I hate toggling here, but its needed to recompute if primary boolean can show up for custom within a data source
        // :thinking: - JK Aug 2019
        this.toggleProperty('model.filterChange');
        Ember.set(this, 'filter', null);
      }
    }
  });
});