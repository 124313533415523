define("affinio/components/modules/metric-visualization", ["exports", "affinio/mixins/affinio-module", "affinio/utils/get-with-default", "affinio/utils/affinio", "affinio/utils/regex"], function (_exports, _affinioModule, _getWithDefault, _affinio, _regex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function toggleHoldOff(event) {
    Ember.set(event.data, 'isMouseDown', false);
    Ember.$(document).off('mouseup', toggleHoldOff);
    if (Ember.get(event.data, 'preferences.normalized') !== 'niche-ranked') {
      Ember.set(event.data, 'preferences.heldCluster', null);
      Ember.set(event.data, 'preferences.heldDelta', false);
    } // if not niche-ranked
  }
  var linkableMetrics = ['twitter-links', 'pinterest-links'];
  var _default = _exports.default = Ember.Component.extend(_affinioModule.default, {
    path: Ember.inject.service(),
    preferences: Ember.inject.service(),
    store: Ember.inject.service(),
    user: Ember.inject.service(),
    tracker: Ember.inject.service(),
    graph: Ember.inject.service(),
    modalManager: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      if (!Ember.get(this, 'moduleRequest.moduleProperties.relativizeCompareViewBy')) {
        Ember.set(this, 'moduleRequest.moduleProperties.relativizeCompareViewBy', 'all');
      }
    },
    mouseDown: function mouseDown(evt) {
      var _this = this;
      if (evt.target && evt.target.matches('.record-block')) {
        evt.preventDefault();
        if (Ember.get(this, 'normalized') !== 'niche-ranked') {
          Ember.set(this, 'isMouseDown', true);
          Ember.run.later(function () {
            if (Ember.get(_this, 'isMouseDown')) {
              var theCluster = Ember.$(evt.target).data('cluster');
              var isDelta = Ember.$(evt.target).is('em');
              if (isDelta) {
                Ember.set(_this, 'preferences.heldDelta', true);
              } else if (theCluster !== null) {
                Ember.set(_this, 'preferences.heldCluster', theCluster.toString());
              }
            }
          }, 100);
          Ember.$(document).on('mouseup', this, toggleHoldOff);
        }
      }
    },
    // mouseDown

    classNames: ['metric-visualization'],
    tagName: 'article',
    classNameBindings: ['metricClass', 'normalized', 'fulfilled:fulfilled:unfulfilled'],
    attributeBindings: ['metric.label:data-metric-label'],
    // TODO: move this to the module itself, rather than metric visualization

    forceDescription: Ember.computed('model.report.baselineReport', 'normalized', function () {
      return !!Ember.get(this, 'model.report.baselineReport') && Ember.get(this, 'normalized') === 'global-comparison';
    }),
    description: Ember.computed('model.report.baselineReport', function () {
      if (Ember.get(this, 'model.report.baselineReport')) {
        return 'Shows deltas in the percentage of members from each audience who exhibit a behavior.';
      }
      return '';
    }),
    _instances: Ember.computed.alias('metric.response'),
    socialNetwork: Ember.computed.alias('model.report.socialNetwork'),
    recordsMap: Ember.computed('metric.label', 'model.report.{recordsMap,recordsCollection.loaded}', function () {
      if (Ember.get(this, 'model.report.recordsCollection.loaded')) {
        /* eslint-disable */
        // js map
        switch (Ember.get(this, 'metric.label')) {
          case 'trackedHashtags':
            return Ember.get(this, 'model.report.recordsMap').get('hashtags');
          case 'trackedMentions':
            return Ember.get(this, 'model.report.recordsMap').get('mentions');
          case 'trackedKeywords':
            return Ember.get(this, 'model.report.recordsMap').get('keywords');
          default:
            return Ember.get(this, 'model.report.recordsMap').get(Ember.get(this, 'metric.label'));
        }
        /* eslint-enable */
      } else {
        return null;
      }
    }),
    metricClass: Ember.computed('metric.label', function () {
      return Ember.String.dasherize(Ember.get(this, 'metric.label').replace(_regex.nonWordNonWhitespaceRegex, '') || '');
    }),
    fulfilled: Ember.computed.alias('_instances.length'),
    lowerSaturationBound: 0,
    upperSaturationBound: 100,
    instances: Ember.computed('_instances', 'metric.{sliceSize,loaded,metricDefinition.preSorted}', 'moduleRequest.{sliceSize}', '_instanceSearchInput', 'lowerSaturationBound', 'upperSaturationBound', 'preferences.{lowerSaturationBound,upperSaturationBound}', 'normalized', 'model.{report.numberOfMembers.response,report.numberOfMembers.loaded,cluster.numberOfMembers.response,cluster.numberOfMembers.loaded}', function () {
      var instances = Ember.get(this, '_instances');
      var metric = Ember.get(this, 'metric');
      if (!metric || !instances) {
        return [];
      }
      var module = Ember.get(this, 'moduleRequest');
      var query = Ember.get(this, '_instanceSearchInput');
      // // v--- needs !isEmpty for lower to be happy with 0 or any number, but unhappy with null or undefined. -PR
      var lowerSaturationBound = !Ember.isEmpty(Ember.get(this, 'preferences.lowerSaturationBound')) ? Ember.get(this, 'preferences.lowerSaturationBound') : Ember.get(this, 'lowerSaturationBound');
      var upperSaturationBound = Ember.get(this, 'preferences.upperSaturationBound') || Ember.get(this, 'upperSaturationBound');
      var cluster = Ember.get(this, 'model.cluster');
      var report = Ember.get(this, 'model.report');
      var clusterNumberOfMembers = Ember.get(cluster, 'isAggregate') ? Ember.get(report, 'numberOfMembers.response.value') : Ember.get(cluster, 'numberOfMembers.response.value');
      var shouldMakeURL = linkableMetrics.includes(metric.id);

      // If number of members is 0 it likely isn't loaded yet,
      // Return the empty objects for skeleton state
      if (clusterNumberOfMembers === 0) {
        return _toConsumableArray(Array(Ember.get(metric, 'sliceSize'))).map(function () {
          return {};
        });
      }
      if (instances && Ember.get(instances, 'length')) {
        var preSorted = (0, _getWithDefault.default)(metric, 'preSorted', false) || (0, _getWithDefault.default)(metric, 'metricDefinition.preSorted', false);
        var sliceSize = Ember.get(module, 'sliceSize') || Ember.get(metric, 'sliceSize');
        if (instances && instances.length) {
          var slicedInstances = instances;

          // Show only the searched-for instances
          if (query) {
            var tempSlicedInstances = slicedInstances.filter(function (instance) {
              return Ember.get(instance, 'label').toLowerCase().includes(query.toLowerCase());
            }).sortBy('value').reverse().slice(0, sliceSize);

            // If your query had a straight single quote and didn't return any results
            // try the search again replacing them for smart quotes
            if (!tempSlicedInstances.length && query.indexOf('\'') > -1) {
              query = query.replace(/'/g, '’');
              slicedInstances = slicedInstances.filter(function (instance) {
                return Ember.get(instance, 'label').toLowerCase().includes(query.toLowerCase());
              }).sortBy('value').reverse().slice(0, sliceSize);
            } else {
              slicedInstances = tempSlicedInstances;
            }
          }

          // Should only show those that adhere to bounds
          if (lowerSaturationBound || upperSaturationBound) {
            slicedInstances = slicedInstances.filter(function (instance) {
              var instanceSaturation = Ember.get(instance, 'value') / clusterNumberOfMembers * 100;
              return instanceSaturation >= lowerSaturationBound && instanceSaturation <= upperSaturationBound;
            }).sortBy('value').reverse().slice(0, sliceSize);
          }
          // If presorted is set, keep order and slice appropriately.
          // Otherwise, sort descending by value and slice
          else if (preSorted) {
            slicedInstances = slicedInstances.slice(0, sliceSize);
          } else {
            slicedInstances = slicedInstances.sortBy('value').reverse().slice(0, sliceSize);
          }
          if (shouldMakeURL) {
            slicedInstances = slicedInstances.map(function (n) {
              Ember.set(n, 'url', n.label); // eslint-disable-line ember/no-side-effects
              return n;
            });
          }
          // if first instance is a spotify track then check and apply to rest
          if (slicedInstances.length && slicedInstances[0].label.startsWith('spotify:track:')) {
            slicedInstances = slicedInstances.map(function (n) {
              if (n.label.startsWith('spotify:track:')) {
                Ember.set(n, 'url', n.label); // eslint-disable-line ember/no-side-effects
              }
              return n;
            });
          }
          if (Ember.get(this, 'metric.baselineEstablished') && Ember.get(this, 'normalized') === 'global-comparison') {
            slicedInstances = slicedInstances.filter(function (instance) {
              return Ember.get(instance, 'baselineSaturation') && Ember.get(instance, 'audienceSaturation') && Ember.get(instance, 'diffFromBaseline') && isFinite(Ember.get(instance, 'diffFromBaseline'));
            });
            slicedInstances = slicedInstances.sortBy('diffFromBaseline').reverse();
          }

          // tracked terms are able to have 0 values (product ask) - js
          if (!['trackedKeywords', 'trackedHashtags', 'trackedMentions'].includes(Ember.get(this, 'metric.label')) // metric label whitelist to prevent filtering out 0 values
          ) {
            slicedInstances = slicedInstances.filter(function (instance) {
              return instance.value !== 0;
            });
          }
          return slicedInstances;
        } else {
          return null;
        }
      } else {
        if (Ember.get(metric, 'loaded')) {
          return [];
        } else {
          return _toConsumableArray(Array(Ember.get(metric, 'sliceSize'))).map(function () {
            return Ember.Object.create();
          });
        }
      }
    }),
    // Whenever the context of instances changes (say, you search or c)
    maxBaselineDiff: Ember.computed('instances', function () {
      var instances = Ember.get(this, 'instances');
      // Find the wides instance among all instances...
      var maxDiff = _.max(instances, function (instance) {
        return Math.abs(Ember.get(instance, 'audienceSaturation') - Ember.get(instance, 'baselineSaturation'));
      });
      // ... and then find its absolute difference:
      maxDiff = Math.abs(Ember.get(maxDiff, 'audienceSaturation') - Ember.get(maxDiff, 'baselineSaturation'));
      return maxDiff;
    }),
    saturationsLegend: Ember.computed('instances.@each.saturations', 'moduleRequest.{label,pairedMetrics.@each.title}', function () {
      var moduleRequest = Ember.get(this, 'moduleRequest');
      // This if block required to handle pre-custom-data style saturations, like what we handle for ODC.
      if (Ember.get(this, 'instances').any(function (instance) {
        return Ember.get(instance, 'saturations');
      }) && Ember.isEmpty(Ember.get(moduleRequest, 'pairedMetrics'))) {
        var saturatedInstance = Ember.get(this, 'instances').findBy('saturations');
        Ember.get(saturatedInstance, 'saturations').reject(function (sat) {
          // we get rid of audience saturation from the legend, as it's implied by the main bar in the UI.
          return Ember.get(sat, 'filterType') === 'audience' && Ember.get(sat, 'filterValue') === 'saturation' || Ember.get(sat, 'filters.type') === 'audience' && Ember.get(sat, 'filters.value') === 'audience' // old twitter convention to ignore;
          ;
        }).forEach(function (sat) {
          Ember.get(moduleRequest, 'pairedMetrics').pushObject({
            paired: Ember.get(sat, 'filters.value'),
            title: Ember.get(sat, 'filters.value')
          });
        });
      }
      if (Ember.get(this, 'moduleRequest.pairedMetrics') && !Ember.isEmpty(Ember.get(this, 'moduleRequest.pairedMetrics'))) {
        var colors = ['#555555', '#888888', '#222222', '#cccccc'];
        return [{
          label: Ember.get(this, 'model.report.name'),
          title: Ember.get(this, 'model.report.name'),
          color: '#2DDE98'
        }].concat(_toConsumableArray(Ember.get(this, 'moduleRequest.pairedMetrics').map(function (paired, iter) {
          return {
            label: Ember.get(paired, 'paired'),
            title: Ember.get(paired, 'title'),
            color: colors[iter]
          };
        })));
      } else {
        return [];
      }
    }),
    max: Ember.computed('instances.[]', function () {
      return Ember.get(_.max(Ember.get(this, 'instances'), 'value'), 'value');
    }),
    isCloud: Ember.computed.equal('normalized', 'word-cloud'),
    isSparkline: Ember.computed.equal('normalized', 'timeline'),
    _normalized: 'cluster-bias',
    // default
    normalizedMapMetric: Ember.computed('preferences.normalizedMap.[]', 'metric.label', function () {
      return Ember.get(this, 'preferences.normalizedMap').findBy('label', Ember.get(this, 'metric.label'));
    }),
    normalized: Ember.computed('_normalized', 'metric', 'currentClusterID', 'moduleRequest.moduleProperties.defaultBarView', 'normalizedMapMetric.value', 'model.report.clusters.[]', {
      get: function get() {
        // Either return the session-set normalization, or the local default
        /* eslint-disable */
        // jsmap
        // let normalizedMapMetric = get(this, 'preferences.normalizedMap').findBy('label', get(this, 'metric.label'));
        var normalizedMapMetric = Ember.get(this, 'normalizedMapMetric');
        var normalized = normalizedMapMetric ? Ember.get(normalizedMapMetric, 'value') : Ember.get(this, 'moduleRequest.moduleProperties.defaultBarView') || Ember.get(this, '_normalized');
        /* eslint-enable */
        if (!Ember.get(this, 'path.inCluster') && normalized === 'niche-ranked') {
          normalized = 'cluster-bias';
        }

        // Don't allow options that don't make sense in a single cluster report
        if (Ember.get(this, 'model.report.clusters.length') === 1 && ['niche-ranked', 'cluster-bias'].includes(normalized)) {
          normalized = 'term-relative';
        }
        return normalized;
      },
      set: function set(key, value) {
        if (value) {
          Ember.set(this, '_normalized', value);
          /* eslint-disable */
          // jsmap
          // get(this, 'preferences.normalizedMap').set(get(this, 'metric.label'), value);
          var normalizedMapMetric = Ember.get(this, 'preferences.normalizedMap').findBy('label', Ember.get(this, 'metric.label'));
          if (normalizedMapMetric) {
            Ember.set(normalizedMapMetric, 'value', value);
          } else {
            Ember.get(this, 'preferences.normalizedMap').pushObject({
              label: Ember.get(this, 'metric.label'),
              value: value
            });
          }
          /* eslint-enable */
          return Ember.get(this, '_normalized');
        } else {
          return Ember.get(this, '_normalized');
        }
      }
    }),
    currentClusterSuffix: Ember.computed.alias('path.currentClusterSuffix'),
    handleNicheRanked: Ember.observer('normalized', 'currentClusterID', 'preferences.heldCluster', 'preferences.heldDelta', 'preferences.lowerSaturationBound', 'preferences.upperSaturationBound', '_instances.[]', 'lowerSaturationBound', 'upperSaturationBound', '_instanceSearchInput', function () {
      var _this2 = this;
      (0, _affinio.maybeRunLater)(this, function () {
        var heldCluster = Ember.get(_this2, 'preferences.heldCluster');
        var heldDelta = Ember.get(_this2, 'preferences.heldDelta');
        if (Ember.get(_this2, 'normalized') === 'niche-ranked') {
          if (!heldCluster) {
            // don't fire on already-niche-ranked visualizations
            Ember.set(_this2, 'nicheRankSorted', true);
            _this2.send('nicheRank', Ember.get(_this2, 'currentClusterSuffix'), 800);
          }
        } else if (heldCluster !== null) {
          _this2.send('nicheRank', heldCluster);
        } else if (heldDelta) {
          _this2.send('nicheRank', 'delta');
        } else {
          if (!heldCluster) {
            // don't fire on already-niche-ranked visualizations
            _this2.send('unNiche');
          }
        } // if niche-ranked
      });
    }),
    // handleNicheRanked

    normalizedWatcher: Ember.observer('normalized', function () {
      Ember.get(this, 'tracker').track('Vis Normalization Toggled', {
        tab: Ember.get(this, 'path.currentTab'),
        type: Ember.get(this, 'normalized'),
        user: Ember.get(this, 'user.email')
      }); // woopra track
      // Heck, might as well be here.
      if (Ember.get(this, 'normalized') === 'global-comparison' && !Ember.get(this, 'model.report.baselineReport')) {
        Ember.get(this, 'modalManager').openModal('modals/modal-baseline', {
          report: Ember.get(this, 'report'),
          model: Ember.get(this, 'model')
        });
      }
    }),
    // normalizedWatcher
    didReceiveAttrs: function didReceiveAttrs() {
      var _this3 = this;
      Ember.run.scheduleOnce('afterRender', function () {
        return _this3.handleNicheRanked();
      });
    },
    // if moduleProperties.defaultBarView is niche-rank, and you load a cluster directly
    // the labels would show x more likely, but the bars wouldn't rearrange and you'd see colors for other clusters
    // this fixes that by ensuring recordsCollection is loaded before niching - JK Jun 2019
    nicheLoadObserver: Ember.observer('instances.[]', 'model.report.recordsCollection.loaded', function () {
      var _this4 = this;
      if (Ember.get(this, 'instances.length') && Ember.get(this, 'model.report.recordsCollection.loaded')) {
        Ember.run.scheduleOnce('afterRender', function () {
          return _this4.handleNicheRanked();
        });
      }
    }),
    sizeWordCloudBy: Ember.computed('moduleRequest.moduleProperties.sizeWordCloudBy', function () {
      return (0, _getWithDefault.default)(this, 'moduleRequest.moduleProperties.sizeWordCloudBy', 'value');
    }),
    relativizeCompareViewBy: Ember.computed('moduleRequest.moduleProperties.relativizeCompareViewBy', function () {
      return (0, _getWithDefault.default)(this, 'moduleRequest.moduleProperties.relativizeCompareViewBy', 'all');
    }),
    actions: {
      nicheRank: function nicheRank(cluster, nicheBy) {
        var _this5 = this;
        if (Ember.get(this, '_state') !== 'inDOM') {
          return;
        }
        if (cluster !== 'delta' && nicheBy !== 'saturation') {
          // record-block.js has two properties that measure how much % is taken up by blocks before them, left-to-right.
          // When normalized (normalized, niche-ranked), this value is different than when raw (term-relative, global-compare)
          var offsetProperty = Ember.get(this, 'normalized') === 'global-comparison' || Ember.get(this, 'normalized') === 'term-relative' ? 'data-raw-offset' : Ember.get(this, 'normalized') === 'metric-normalized' ? 'data-metric-normalized-offset' : 'data-normalized-offset';

          // Move the row of records-blocks leftwards so that the cluster is flush left
          this.$('.cluster-stats').each(function (iter, clusterStats) {
            clusterStats = Ember.$(clusterStats);
            var nicheRecord = clusterStats.children(".record-block[data-cluster=\"".concat(cluster, "\"]"));
            var offset = nicheRecord.attr(offsetProperty);
            clusterStats.children('.record-block').css('opacity', 0);
            nicheRecord.css('opacity', 1);
            clusterStats.css({
              'transform': "translateX(-".concat(offset, "%)")
            });
          });
        } // if cluster not delta

        var barHeight = Ember.get(this, 'normalized') === 'global-comparison' ? this.$('.metric-instance').height() : 29; // default bar height. Hardcoding 29 feels dirty, but can't depend on a non-globcomp one existing.

        this.$().map(function (iter, chart) {
          if (cluster === 'delta') {
            var sorted = _this5.$(chart).find('.bar').toArray().sort(function (a, b) {
              var aSize = _this5.$(a).data('diff');
              var bSize = _this5.$(b).data('diff');
              return aSize > bSize ? -1 : aSize < bSize ? 1 : 0;
            });
            sorted.forEach(function (obj, iter) {
              var graphedBar = _this5.$(obj).parents('.metric-instance');
              var topPosition = barHeight * iter - graphedBar.position().top + 5;
              graphedBar.css({
                'transform': "translateY(".concat(topPosition, "px)")
              }).attr('which', iter);
            }); // sorted forEach
          } else if (nicheBy === 'saturation') {
            var _sorted;
            if (Ember.get(_this5, 'moduleRequest.moduleProperties.relativizeCompareViewBy') === 'all') {
              _sorted = _this5.$(chart).find('.metric-instance').toArray().sort(function (a, b) {
                var aSize = _this5.$(a).find('.global-bar.hovered').data('relativeValue') || 0;
                var bSize = _this5.$(b).find('.global-bar.hovered').data('relativeValue') || 0;
                return aSize > bSize ? -1 : aSize < bSize ? 1 : 0;
              });
            } else {
              _sorted = _this5.$(chart).find('.metric-instance').toArray().sort(function (a, b) {
                var aSize = _this5.$(a).find('.global-bar.hovered').data('value') || 0;
                var bSize = _this5.$(b).find('.global-bar.hovered').data('value') || 0;
                return aSize > bSize ? -1 : aSize < bSize ? 1 : 0;
              });
            }
            _sorted.forEach(function (obj, iter) {
              var graphedBar = _this5.$(obj);
              var topPosition = (barHeight + 21) * iter - graphedBar.position().top;
              graphedBar.css({
                'transform': "translateY(".concat(topPosition, "px)")
              }).attr('which', iter);
            }); // sorted forEach
          } else {
            // Vertically sort metric-instance rows by the cluster in question's normalized-percentage
            var _sorted2 = _this5.$(chart).find(".record-block[data-cluster=\"".concat(cluster, "\"]")).toArray().sort(function (a, b) {
              var aSize = parseFloat(_this5.$(a).attr('data-normalized-percentage'));
              var bSize = parseFloat(_this5.$(b).attr('data-normalized-percentage'));
              return aSize > bSize ? -1 : aSize < bSize ? 1 : 0;
            });

            // tracked terms are a special case where they are piggyback on proper metrics.
            // This means if something doesn't score we have to handle it explicitly
            // as its not in the standard records map :( JK Sep 2019
            var tracked = ['trackedHashtags', 'trackedKeywords', 'trackedMentions'];
            var rest = [];
            if (tracked.includes(Ember.get(_this5, 'metric.label'))) {
              rest = _.difference(_this5.$().find('.metric-instance'), _sorted2.map(function (n) {
                return _this5.$(n).parents('.metric-instance')[0];
              }));
            }

            // Move the bars
            [].concat(_toConsumableArray(_sorted2), _toConsumableArray(rest)).forEach(function (obj, iter) {
              var graphedBar = _this5.$(obj).hasClass('metric-instance') ? _this5.$(obj) : _this5.$(obj).parents('.metric-instance');
              var originalSpot = _this5.$(chart).find('.metric-instance').index(graphedBar);
              var topPosition = barHeight * iter - barHeight * originalSpot;
              graphedBar.css({
                'transform': "translateY(".concat(topPosition, "px)")
              }).attr('which', iter);
            }); // sorted forEach
          } // if/else cluster==='delta'
        }); // map charts
      },
      // nicheRank
      unNiche: function unNiche() {
        if (this.$('.cluster-stats')) {
          this.$('.cluster-stats').css({
            'transform': 'translateX(0%)'
          }, 200);
          this.$('.cluster-stats').children('span').css({
            'opacity': 1
          });
          this.$('.metric-instance').css({
            'transform': 'translateY(0px)'
          });
          Ember.set(this, 'nicheRankSorted', false);
        }
      },
      // unNiche
      saturationLegendHover: function saturationLegendHover(sat) {
        if (sat) {
          Ember.set(this, 'hoveredSaturation', Ember.get(sat, 'label'));
        } else {
          Ember.set(this, 'hoveredSaturation', null);
        }
      }
    }
  });
});