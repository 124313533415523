define("affinio/components/google-map", ["exports", "affinio/utils/affinio"], function (_exports, _affinio) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
  var MAP_STYLES = [{
    'featureType': 'water',
    'stylers': [{
      'color': '#8ad7f6'
    }, {
      'visibility': 'on'
    }]
  }, {
    'featureType': 'landscape',
    'stylers': [{
      'color': '#f2f2f2'
    }]
  }, {
    'featureType': 'road',
    'stylers': [{
      'saturation': -100
    }, {
      'lightness': 45
    }]
  }, {
    'featureType': 'road.highway',
    'stylers': [{
      'visibility': 'simplified'
    }]
  }, {
    'featureType': 'road.arterial',
    'elementType': 'labels.icon',
    'stylers': [{
      'visibility': 'off'
    }]
  }, {
    'featureType': 'administrative',
    'elementType': 'labels.text.fill',
    'stylers': [{
      'color': '#444444'
    }]
  }, {
    'featureType': 'transit',
    'stylers': [{
      'visibility': 'off'
    }]
  }, {
    'featureType': 'poi',
    'stylers': [{
      'visibility': 'off'
    }]
  }];
  var CANADA_DISTRIBUTION = [{
    percentage: 0.21875,
    location: {
      lat: 43.653226,
      lng: -79.3831843
    }
  }, {
    percentage: 0.20713,
    location: {
      lat: 49.261226,
      lng: -123.1139268
    }
  }, {
    percentage: 0.13798,
    location: {
      lat: 45.5086699,
      lng: -73.5539925
    }
  }, {
    percentage: 0.09175,
    location: {
      lat: 51.0453246,
      lng: -114.0581012
    }
  }, {
    percentage: 0.07389,
    location: {
      lat: 45.4215296,
      lng: -75.6971931
    }
  }, {
    percentage: 0.06794,
    location: {
      lat: 53.544389,
      lng: -113.4909267
    }
  }, {
    percentage: 0.05968,
    location: {
      lat: 43.5890452,
      lng: -79.6441198
    }
  }, {
    percentage: 0.05551,
    location: {
      lat: 49.8997541,
      lng: -97.1374937
    }
  }, {
    percentage: 0.04382,
    location: {
      lat: 43.683333,
      lng: -79.766667
    }
  }, {
    percentage: 0.04349,
    location: {
      lat: 43.2500208,
      lng: -79.8660914
    }
  }];
  var USA_DISTRIBUTION = [{
    percentage: 0.33176,
    location: {
      lat: 40.7127837,
      lng: -74.0059413
    }
  }, {
    percentage: 0.15350,
    location: {
      lat: 34.0522342,
      lng: -118.2436849
    }
  }, {
    percentage: 0.10636,
    location: {
      lat: 41.8781136,
      lng: -87.6297982
    }
  }, {
    percentage: 0.08750,
    location: {
      lat: 29.7601927,
      lng: -95.3693896
    }
  }, {
    percentage: 0.06096,
    location: {
      lat: 39.952335,
      lng: -75.163789
    }
  }, {
    percentage: 0.06005,
    location: {
      lat: 33.4483771,
      lng: -112.0740373
    }
  }, {
    percentage: 0.05613,
    location: {
      lat: 29.4241219,
      lng: -98.4936282
    }
  }, {
    percentage: 0.05396,
    location: {
      lat: 32.715738,
      lng: -117.1610838
    }
  }, {
    percentage: 0.05005,
    location: {
      lat: 32.7801399,
      lng: -96.8004511
    }
  }, {
    percentage: 0.03968,
    location: {
      lat: 37.3393857,
      lng: -121.8949555
    }
  }];
  var AUSTRALIA_DISTRIBUTION = [{
    percentage: 0.28582,
    location: {
      lat: -33.8674869,
      lng: 151.2069902
    }
  }, {
    percentage: 0.26219,
    location: {
      lat: -37.814107,
      lng: 144.96328
    }
  }, {
    percentage: 0.13430,
    location: {
      lat: -27.4710107,
      lng: 153.0234489
    }
  }, {
    percentage: 0.11934,
    location: {
      lat: -31.9530044,
      lng: 115.8574693
    }
  }, {
    percentage: 0.07703,
    location: {
      lat: -34.9286212,
      lng: 138.5999594
    }
  }, {
    percentage: 0.03627,
    location: {
      lat: -28,
      lng: 153.4
    }
  }, {
    percentage: 0.02543,
    location: {
      lat: -32.9,
      lng: 151.75
    }
  }, {
    percentage: 0.02494,
    location: {
      lat: -35.2819998,
      lng: 149.1286843
    }
  }, {
    percentage: 0.01755,
    location: {
      lat: -26.6559759,
      lng: 153.0918365
    }
  }, {
    percentage: 0.01707,
    location: {
      lat: -34.4250728,
      lng: 150.8931494
    }
  }];
  var _default = _exports.default = Ember.Component.extend({
    // Element
    classNames: ['google-map'],
    classNameBindings: ['socialNetwork'],
    // Services
    path: Ember.inject.service(),
    store: Ember.inject.service(),
    tracker: Ember.inject.service(),
    user: Ember.inject.service(),
    // Computed Properties
    socialNetwork: Ember.computed.alias('model.report.socialNetwork'),
    // Observers
    // newDataObserver: observer('locations', function() {
    //   console.log('locations observer');
    //   // debugger;
    //   this.setHeatMap();
    // }),
    didUpdateAttrs: function didUpdateAttrs() {
      this.setHeatMap();
    },
    // Lifecycle
    didInsertElement: function didInsertElement() {
      // this._super(...arguments);
      this.initializeMap();
    },
    // Helpers
    initializeMap: function initializeMap() {
      var _this = this;
      var _this$$ = this.$('.map-holster'),
        _this$$2 = _slicedToArray(_this$$, 1),
        container = _this$$2[0];
      var mapOptions = {
        center: {
          lat: 0,
          lng: 0
        },
        zoom: 2,
        styles: MAP_STYLES
      };
      var map = new google.maps.Map(container, mapOptions);
      map.controls[google.maps.ControlPosition.RIGHT_TOP].push(this.$('.map-options')[0]);
      Ember.set(this, 'map', map);
      (0, _affinio.maybeRunLater)(this, function () {
        // console.log('init afterRender');
        _this.setHeatMap();
      }, 10);
    },
    setHeatMap: function setHeatMap() {
      var locations = Ember.get(this, 'locations');
      var map = Ember.get(this, 'map');
      var heatmap = Ember.get(this, 'heatmap');
      var heatmapData = [];
      locations.forEach(function (loc) {
        if (loc.coordinates) {
          var distributedCountry = null;
          switch (loc.label.toLowerCase()) {
            case 'canada':
              distributedCountry = CANADA_DISTRIBUTION;
              break;
            case 'usa':
              distributedCountry = USA_DISTRIBUTION;
              break;
            case 'australia':
              distributedCountry = AUSTRALIA_DISTRIBUTION;
              break;
          }

          // if in a distributed country
          if (distributedCountry !== null) {
            distributedCountry.forEach(function (obj) {
              heatmapData.push({
                location: new google.maps.LatLng(obj.location.lat, obj.location.lng),
                weight: obj.percentage * loc.value
              });
            });
          } else {
            heatmapData.push({
              location: new google.maps.LatLng(loc.coordinates.latitude, loc.coordinates.longitude),
              weight: loc.value
            });
          }
        }
      });
      Ember.set(this, 'heatmapData', heatmapData);
      if (heatmap) {
        heatmap.setData(heatmapData);
      } else {
        heatmap = new google.maps.visualization.HeatmapLayer({
          data: heatmapData,
          dissipating: false,
          id: 'all'
        });
        Ember.set(this, 'heatmap', heatmap);
        heatmap.setMap(map);
      }
    }
  });
});