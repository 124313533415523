define("affinio/utils/taxonomies/standard-metric-definitions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [
  // Report
  {
    label: 'numberOfMembers',
    level: 'report',
    properties: [],
    defaultValue: 'number'
  }, {
    label: 'clusterSVGURL',
    title: 'Network Graph',
    properties: [],
    defaultValue: 'string',
    level: 'report'
  }, {
    label: 'topicExplorer',
    title: 'Topic Explorer',
    description: 'topics',
    properties: ['nodes { url id entities { label value count } words { label value count } meta { favicon title url } }', 'links { weight source target }'],
    graphQLType: 'TopicExplorer',
    defaultValue: 'object',
    exportable: true,
    exportType: 'topicExplorer',
    unaggregateable: true,
    level: 'report',
    initialVersionOnly: true
  }, {
    label: 'recordsCollection',
    defaultValue: 'array',
    graphQLType: 'Metric',
    level: 'report',
    properties: ['label values { label values { cluster:clusterSuffix value timesMoreLikely { value isInfinite } normalizedScore } }']
  },
  // Cluster
  {
    label: 'numberOfMembers',
    level: 'cluster',
    properties: [],
    defaultValue: 'number'
  }, {
    label: 'density',
    level: 'cluster',
    properties: [],
    defaultValue: 'number',
    title: 'Cluster Overview',
    description: ''
  }, {
    label: 'averagePostsPerMonth',
    title: 'Average Posts Per Month',
    level: 'cluster',
    properties: [],
    defaultValue: 'number'
  }];
});