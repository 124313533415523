define("affinio/templates/components/active-tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "n9BvIhah",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[4,\"if\",[[23,[\"activeTags\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"active-tags\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"activeTags\"]]],null,{\"statements\":[[4,\"if\",[[22,1,[\"count\"]]],null,{\"statements\":[[0,\"        \"],[7,\"span\"],[12,\"class\",[22,1,[\"level\"]]],[11,\"role\",\"button\"],[9],[0,\"\\n          \"],[1,[22,1,[\"tag\"]],false],[0,\"\\n          \"],[7,\"small\"],[9],[0,\"(\"],[1,[22,1,[\"count\"]],false],[0,\")\"],[10],[0,\"\\n        \"],[3,\"action\",[[22,0,[]],\"filterInterests\",[22,1,[\"tag\"]],[22,1,[\"parent\"]]]],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/active-tags.hbs"
    }
  });
});