define("affinio/utils/constants/string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    reportBuilder: {
      setBenchmarks: 'Enter potential influencers, sponsorships, partnerships, or other handles as a point of reference. Tracked Interests are custom groups of handles that are actively scored for Relevance and Affinity regardless of whether they naturally surface as top interests.',
      invalidDateRange: 'Invalid Date Range',
      newFollowersSameWeekError: 'The Start and End Dates cannot be from the same week.'
    },
    taxonomyCreation: {
      stopWordsTooltip: 'Select the columns where you\'d like to remove stopwords from a keyword phrase to return the most relevant results. Stopwords are common words that carry less important meaning than keywords, examples include "the", "and", etc...'
    }
  };
});