define("affinio/initializers/json", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(/* application */
  ) {
    window.JSON.graphify = function () {
      return JSON.stringify.apply(this, arguments).replace(/"/g, '\\"');
    };
  }
  var _default = _exports.default = {
    name: 'json',
    initialize: initialize
  };
});