define("affinio/mixins/report-builder-account-requirements", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    user: Ember.inject.service(),
    meetsAccountRequirements: Ember.computed('moduleSchema.accountRequirements', function () {
      var _this = this;
      var accountRequirements = Ember.get(this, 'moduleSchema.accountRequirements');
      if (accountRequirements && accountRequirements.length) {
        return accountRequirements.every(function (n) {
          return Ember.get(_this, "user.account.".concat(n));
        });
      }
      return true;
    })
  });
});