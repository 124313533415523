define("affinio/helpers/is-defined", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isDefined = isDefined;
  function isDefined(params /* , hash*/) {
    return params[0] !== undefined && params[0] !== null;
  }
  var _default = _exports.default = Ember.Helper.helper(isDefined);
});