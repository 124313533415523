define("affinio/components/highlight-linechart", ["exports", "affinio/helpers/commafy-number", "moment", "affinio/utils/affinio"], function (_exports, _commafyNumber, _moment, _affinio) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Element
    classNames: ['highlight-linechart'],
    tagName: 'div',
    // Services
    preferences: Ember.inject.service(),
    // Computed Properties
    width: Ember.computed('preferences.highlightedTime', 'records', 'path.currentClusterId', function () {
      return this.$().width() - 60;
    }),
    height: Ember.computed('preferences.highlightedTime', 'records', 'path.currentClusterId', function () {
      return this.$().height() - 30;
    }),
    svg: Ember.computed('records', function () {
      return d3.select(this.$('> svg.line-chart')[0]);
    }),
    line: Ember.computed('records.[]', 'xScale', 'yScale', 'path.currentClusterId', function () {
      var _this = this;
      return d3.svg.line().x(function (d) {
        return Ember.get(_this, 'xScale')(d.dateTime);
      }).y(function (d) {
        return Ember.get(_this, 'yScale')(d.value);
      });
    }),
    yScale: Ember.computed('width', 'height', 'records', 'path.currentClusterId', function () {
      var height = Ember.get(this, 'height');
      // Creates the yScale
      var yScale = d3.scale.linear().range([height, 0]);
      yScale.domain([0, d3.max(Ember.get(this, 'records'), function (d) {
        return d.value;
      })]);
      return yScale;
    }),
    xScale: Ember.computed('width', 'height', 'records', 'path.currentClusterId', function () {
      var width = Ember.get(this, 'width');
      var xScale = d3.time.scale.utc().range([0, width]);
      xScale.domain(d3.extent(Ember.get(this, 'records'), function (d) {
        return d.dateTime;
      }));
      return xScale;
    }),
    xAxis: Ember.computed('xScale', 'width', 'records', function () {
      return d3.svg.axis().scale(Ember.get(this, 'xScale')).tickPadding(8).orient('bottom').tickSize(Ember.get(this, 'height')).ticks(5).tickFormat(d3.time.format('%d.%m.%Y'));
    }),
    yAxis: Ember.computed('yScale', 'width', 'records', function () {
      return d3.svg.axis().scale(Ember.get(this, 'yScale')).ticks(5).tickSize(-Ember.get(this, 'width')).tickPadding(5).orient('left');
    }),
    // TODO trim dependencies
    handleMouseOver: Ember.computed('xScale', 'yScale', 'records.[]', 'width', 'height', function () {
      var self = this;
      var focus = Ember.get(this, 'focus');
      var background = Ember.get(this, 'background');
      return function () {
        focus.style('display', 'block');
        background.transition().attr('fill', '#f5f5f5');
        Ember.set(self, 'isMouseOver', true); // eslint-disable-line ember/no-side-effects
      };
    }),
    // TODO trim dependencies
    handleMouseOut: Ember.computed('xScale', 'yScale', 'records.[]', 'width', 'height', function () {
      var self = this;
      var focus = Ember.get(this, 'focus');
      var background = Ember.get(this, 'background');
      return function () {
        focus.style('display', 'none');
        background.transition().attr('fill', '#fff');
        Ember.set(self, 'isMouseOver', false); // eslint-disable-line ember/no-side-effects
      };
    }),
    handleMouseMove: Ember.computed('xScale', 'yScale', 'records.[]', 'width', 'height', function () {
      var data = Ember.get(this, 'records');
      var xScale = Ember.get(this, 'xScale');
      var yScale = Ember.get(this, 'yScale');
      var focus = Ember.get(this, 'focus');
      var width = Ember.get(this, 'width');
      var height = Ember.get(this, 'height');
      var controller = this;
      var bisectDate = d3.bisector(function (d) {
        return d.dateTime;
      }).left;
      return function () {
        // console.log('handleMouseOver');
        var x0 = xScale.invert(d3.mouse(this)[0]);
        var i = bisectDate(data, x0, 1);
        var d0 = data[i - 1];
        var d1 = data[i];
        var d = x0 - d0.dateTime > d1.dateTime - x0 ? d1 : d0;
        focus.attr('transform', "translate(".concat(xScale(d.dateTime), ",").concat(yScale(d.value), " )"));
        focus.select('.date-label').text("".concat((0, _moment.default)(d.dateTime).format('dddd MMM DD'), " at \n ").concat((0, _moment.default)(d.dateTime).format('h:mm A')));
        focus.select('.date-value').text((0, _commafyNumber.commafyNumber)([d.value]));
        Ember.set(controller, 'mouseOverDate', (0, _moment.default)(d.dateTime).format('L')); // eslint-disable-line ember/no-side-effects
        if (width && height) {
          d3.select('.overlay').attr('width', width).attr('height', height);
        }
      };
    }),
    didInsertElement: function didInsertElement() {
      var _this2 = this;
      this._super.apply(this, arguments);
      // console.log('didInsertElement');
      this.send('initRenderLineChart');
      Ember.$(window).on('resize', function () {
        // console.log('resize event', get(this, 'width'), get(this, 'height'));
        (0, _affinio.maybeRunLater)(_this2, function () {
          if (Ember.get(_this2, 'defaultWidth')) {
            Ember.set(_this2, 'width', Ember.get(_this2, 'defaultWidth'));
          } else {
            Ember.set(_this2, 'width', _this2.$().width() - 60);
          }
          Ember.run.debounce(_this2, function () {
            // console.log('resize event', get(this, 'width'), this.$().width());
            _this2.send('renderLineChart');
            Ember.get(_this2, 'svg').select('.overlay').on('mousemove', Ember.get(_this2, 'handleMouseMove')).on('mouseover', Ember.get(_this2, 'handleMouseOver')).on('mouseout', Ember.get(_this2, 'handleMouseOut'));
          }, 250);
        }, 50);
      });
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      // console.log('didReceiveAttrs');
    },
    didUpdateAttrs: function didUpdateAttrs() {
      var _this3 = this;
      this._super.apply(this, arguments);
      (0, _affinio.maybeRunLater)(this, function () {
        Ember.run.debounce(_this3, function () {
          // console.log('didUpdateAttrs event', get(this, 'width'), get(this, 'height'));
          _this3.send('renderLineChart');
          Ember.get(_this3, 'svg').select('.overlay').on('mousemove', Ember.get(_this3, 'handleMouseMove')).on('mouseover', Ember.get(_this3, 'handleMouseOver')).on('mouseout', Ember.get(_this3, 'handleMouseOut'));
        }, 250);
      }, 100);
    },
    willDestroyElement: function willDestroyElement() {
      // console.log('willDestroyElement');
      this._super.apply(this, arguments);
      Ember.$(window).off('resize');
    },
    actions: {
      initRenderLineChart: function initRenderLineChart() {
        var yAxis = Ember.get(this, 'yAxis');
        var xAxis = Ember.get(this, 'xAxis');
        var line = Ember.get(this, 'line');
        var width = Ember.get(this, 'defaultWidth') ? Ember.get(this, 'defaultWidth') : Ember.get(this, 'width');
        // console.log('initRenderLineChart');
        // console.log('initRender width', width);
        Ember.get(this, 'svg').append('rect').attr('class', 'background').attr('width', width).attr('height', Ember.get(this, 'height')).attr('fill', '#FFF');
        Ember.set(this, 'background', d3.select('.background'));

        // Add the valueline path.
        Ember.get(this, 'svg').append('path').data([Ember.get(this, 'records')]).attr('class', 'line').transition().duration(400).attr('d', line);
        // Add the X Axis
        Ember.get(this, 'svg').append('g').attr('class', 'x-axis').attr('transform', "translate(0,".concat(0, ")")).call(xAxis);

        // Add the Y Axis
        Ember.get(this, 'svg').append('g').attr('class', 'y-axis').call(yAxis);
        if (Ember.get(this, 'preferences.highlightedTime')) {
          this.send('renderHighlightValues');
        }
        if (Ember.get(this, 'activeRange')) {
          this.send('renderActiveRangeValues');
        } // activeRange

        var focus = Ember.get(this, 'svg').append('g').attr('class', 'focus').style('display', 'none');
        focus.append('circle').attr('r', 4.5);
        // TODO setup focus object
        focus.append('rect').attr('width', 200).attr('height', 80).attr('y', -40).attr('x', 10).attr('stroke', '#333').attr('stroke-width', 2).attr('fill', '#fff');
        focus.append('text').attr('class', 'label-header').text('Date/Time:').style('font-size', '10px').attr('x', 20).attr('y', -20);
        focus.append('text').attr('class', 'date-label').style('font-size', '0.8em').style('font-weight', '500').attr('x', 20).attr('y', -5);
        focus.append('text').attr('class', 'value-header').style('font-size', '10px').text('Value:').attr('x', 20).attr('y', 15);
        focus.append('text').attr('class', 'date-value').style('font-size', '0.8em').style('font-weight', '500').attr('x', 20).attr('y', 30);

        //  set(this, 'xScale', xScale);
        //  set(this, 'yScale', yScale);
        //  set(this, 'line', line);
        Ember.set(this, 'focus', focus);
        Ember.get(this, 'svg').append('rect').attr('class', 'overlay').attr('width', width).attr('height', Ember.get(this, 'height')).on('mousemove', Ember.get(this, 'handleMouseMove')).on('mouseover', Ember.get(this, 'handleMouseOver')).on('mouseout', Ember.get(this, 'handleMouseOut'));
      },
      renderLineChart: function renderLineChart() {
        // console.log('renderLineChart', get(this, 'svg'));
        // console.log('renderLineChart width', get(this, 'width'));
        var yAxis = Ember.get(this, 'yAxis');
        var xAxis = Ember.get(this, 'xAxis');
        var line = Ember.get(this, 'line');
        Ember.get(this, 'svg').select('.line').transition().duration(400).attr('d', line(Ember.get(this, 'records')));
        Ember.get(this, 'svg').select('.x-axis').call(xAxis);
        Ember.get(this, 'svg').select('.y-axis').call(yAxis);
        Ember.get(this, 'svg').selectAll('.overlay, .background').attr('width', Ember.get(this, 'width')).attr('height', Ember.get(this, 'height'));
        d3.selectAll('.line-chart .highlight, .line-chart .highlight circle').remove();
        if (Ember.get(this, 'preferences.highlightedTime')) {
          this.send('renderHighlightValues');
        } // preferences.highlightedTime

        d3.selectAll('.line-chart .active, .line-chart .active circle').remove();
        if (Ember.get(this, 'activeRange')) {
          this.send('renderActiveRangeValues');
        } // activeRange
      },
      // renderLineChart
      renderHighlightValues: function renderHighlightValues() {
        var _this4 = this;
        // console.log('renderHighlightValues');
        var xScale = Ember.get(this, 'xScale');
        var yScale = Ember.get(this, 'yScale');
        Ember.get(this, 'highlightedValues').forEach(function (highTime) {
          var highlight = Ember.get(_this4, 'svg').append('g').attr('class', 'highlight').style('display', 'block');
          highlight.append('circle').attr('r', 8).attr('transform', "translate(".concat(xScale(Ember.get(highTime, 'dateTime')), ",").concat(yScale(Ember.get(highTime, 'value')), ")"));
        });
      },
      // renderHighlightValues
      renderActiveRangeValues: function renderActiveRangeValues() {
        var _this5 = this;
        // console.log('renderActiveRangeValues');
        var xScale = Ember.get(this, 'xScale');
        var yScale = Ember.get(this, 'yScale');
        var activeRange = Ember.get(this, 'activeRange');
        Ember.get(this, "".concat(activeRange, "Values")).forEach(function (value) {
          var point = Ember.get(_this5, 'svg').append('g').attr('class', "active ".concat(activeRange)).style('display', 'block');
          point.append('circle').attr('r', 8).attr('transform', "translate(".concat(xScale(Ember.get(value, 'dateTime')), ", ").concat(yScale(Ember.get(value, 'value')), ")"));
        });
      } // renderActiveRangeValues
    }
  });
});