define("affinio/components/notification-card", ["exports", "affinio/utils/mutations"], function (_exports, _mutations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    app: Ember.inject.service(),
    tracker: Ember.inject.service(),
    graph: Ember.inject.service(),
    didRender: function didRender() {
      var _this = this;
      this._super.apply(this, arguments);
      var links = this.$('a[href]');
      links.off('click');
      links.click(function (e) {
        Ember.get(_this, 'tracker').track('notification CTA clicked', {
          href: e.target.href,
          notificationId: Ember.get(_this, 'notification.id'),
          notificationTitle: Ember.get(_this, 'notification.title'),
          acknowledged: Ember.get(_this, 'notification.acknowledged')
        });
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.$('a[href]').off('click');
    },
    actions: {
      markAsRead: function markAsRead() {
        var _this2 = this;
        Ember.get(this, 'graph').mutate(_mutations.default.acknowledgeNotification(Ember.get(this, 'notification.id'))).then(function () {
          return Ember.get(_this2, 'tracker').track('Notification marked as read', {
            notification: Ember.get(_this2, 'notification.title'),
            notificationId: Ember.get(_this2, 'notification.id')
          });
        }).then(function () {
          // Not a fan of this at all, but embers new test format is a lot more of a stickler about using actual/mocked services
          // To avoid reimplementing the graph service in a stub, this is an easy fix - JK July 2018
          if (Ember.get(_this2, 'app.environment') === 'test') {
            Ember.set(_this2, 'notification.acknowledged', true);
          }
        });
      }
    }
  });
});