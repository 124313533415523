define("affinio/components/report-nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Element
    classNames: ['report-nav'],
    classNameBindings: ['model.editMode'],
    tagName: 'div',
    // Services
    path: Ember.inject.service(),
    modules: Ember.inject.service(),
    modalManager: Ember.inject.service(),
    store: Ember.inject.service(),
    user: Ember.inject.service(),
    schemaEditor: Ember.inject.service(),
    loadingType: 'bar',
    activeReportDate: Ember.computed('path.currentVersion', 'model.versions', function () {
      var version = Ember.get(this, 'path.currentVersion');
      var versions = Ember.get(this, 'model.report.versions');
      var date = versions.findBy('version', parseInt(version));
      return date.dateFinished || date.dateUpdated;
    }),
    actions: {
      isScreenshotable: function isScreenshotable(module) {
        var moduleSchema = Ember.get(this, "modules.schema.".concat(Ember.String.camelize(Ember.get(module, 'value'))));
        // console.log('isScreenshotable', moduleSchema);
        return moduleSchema ? Ember.get(moduleSchema, 'screenshotable') : null;
      },
      setHighlightedCategory: function setHighlightedCategory(sectionValue) {
        Ember.set(this, 'highlightedCategory', sectionValue);
      },
      openScreenshotModal: function openScreenshotModal(model) {
        Ember.get(this, 'modalManager').openModal('modals/modal-screenshot', {
          report: Ember.get(model, 'report'),
          model: model
        }); // openHashtagStream
      },
      openNavEditor: function openNavEditor() {
        Ember.get(this, 'modalManager').openModal('modals/modal-edit-report-nav', {
          model: Ember.get(this, 'model')
        });
      },
      updateTaxo: function updateTaxo() {
        Ember.get(this, 'schemaEditor').updateTaxo();
      },
      openBaselineModal: function openBaselineModal(model) {
        Ember.get(this, 'modalManager').openModal('modals/modal-baseline', {
          report: Ember.get(model, 'report'),
          model: model
        });
      },
      configureReport: function configureReport() {
        Ember.get(this, 'modalManager').openModal('modals/modal-edit-report-configuration', {
          model: Ember.get(this, 'model')
        });
      }
    } // actions
  });
});