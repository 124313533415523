define("affinio/components/oauth-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Element
    classNames: ['oauth-button'],
    tagName: 'button',
    // Services
    oauth: Ember.inject.service(),
    modalManager: Ember.inject.service(),
    // Events
    click: function click() {
      if (!Ember.get(this, 'isAdmin')) {
        if (!Ember.get(this, 'authorized')) {
          var preAuthAction = Ember.get(this, 'preAuthAction');
          if (preAuthAction && typeof preAuthAction === 'function') {
            preAuthAction();
          }
          Ember.get(this, 'oauth').authenticate(Ember.get(this, 'network'));
        } else {
          switch (Ember.get(this, 'network')) {
            case 'twitter':
              window.open('https://twitter.com/settings/sessions', '_blank');
              break;
          }
        }
      }
    },
    // Properties
    icon: Ember.computed('network', function () {
      switch (Ember.get(this, 'network')) {
        case 'twitter':
          return 'twitter';
        case 'facebook':
          return 'facebook';
        case 'google':
          return 'google';
        default:
          return 'pied-piper';
      }
    }),
    authorized: Ember.computed('network', 'account.twitterCredentials', function () {
      return Ember.get(this, "account.".concat(Ember.get(this, 'network'), "Credentials"));
    }),
    authorizedAccount: Ember.computed('network', 'account', function () {
      switch (Ember.get(this, 'network')) {
        case 'twitter':
          return "@".concat(Ember.get(this, 'account.twitterCredentials.screenName'));
        default:
          return 'Authorized';
      }
    }),
    actions: {
      clear: function clear() {
        var _this = this;
        Ember.get(this, 'modalManager').openModal('modals/modal-boolean', {
          question: "Are you sure you want to remove ".concat(Ember.get(this, 'isAdmin') ? "".concat(Ember.get(this, 'account.name'), "'s ") : 'your ').concat(Ember.get(this, 'network').capitalize(), " oauth credentials?"),
          okText: "Remove ".concat(Ember.get(this, 'network').capitalize(), " Credentials"),
          okAction: function okAction() {
            Ember.get(_this, 'oauth').clearAuthentication(Ember.get(_this, 'network'), Ember.get(_this, 'account.id'));
          }
        });
      }
    }
  });
});