define("affinio/utils/mutations/tags-suggestion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.toolboxUpdateCrowdSourceTag = _exports.default = _exports.createCrowdSourcingTag = void 0;
  var createCrowdSourcingTag = _exports.createCrowdSourcingTag = function createCrowdSourcingTag(handle, suggestedTag) {
    return {
      networkName: 'createCrowdSourcingTag',
      queryString: "mutation{createCrowdSourcingTag(handle:\"".concat(handle, "\", suggestedTag:\"").concat(suggestedTag, "\")}")
    };
  };

  /**
   * for accpting or regecting suggested tags
   * @param {*} suggestedTags [{id: string, status: string, tagName: string}]
   * @returns
   */
  var toolboxUpdateCrowdSourceTag = _exports.toolboxUpdateCrowdSourceTag = function toolboxUpdateCrowdSourceTag() {
    var suggestedTags = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    return {
      networkName: 'toolboxUpdateCrowdSourceTag',
      queryString: "mutation($crowdSourcingTagsUpdate:[updateCrowdSourceTagInputType!]! ) {\n      toolboxUpdateCrowdSourceTag(\n        crowdSourcingTagsUpdate: $crowdSourcingTagsUpdate\n      )\n     }",
      variables: {
        crowdSourcingTagsUpdate: suggestedTags
      }
    };
  };
  var _default = _exports.default = {
    createCrowdSourcingTag: createCrowdSourcingTag,
    toolboxUpdateCrowdSourceTag: toolboxUpdateCrowdSourceTag
  };
});