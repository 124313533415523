define("affinio/utils/mutations/folders", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.updateFolder = _exports.removeAccountFolderAccess = _exports.deleteFolder = _exports.default = _exports.createFolder = void 0;
  var deleteFolder = _exports.deleteFolder = function deleteFolder(id) {
    return {
      networkName: 'deleteFolder',
      queryString: "mutation {\n      deleteFolder(id: \"".concat(id, "\")\n    }")
    };
  };
  var updateFolder = _exports.updateFolder = function updateFolder(_ref) {
    var folder = _ref.folder,
      editedProperties = _ref.editedProperties,
      sendEmail = _ref.sendEmail;
    var mutations = Ember.A();
    var folderResponseCompanies = "\n    id\n    companies {\n      id\n      shareInfo {\n        canEdit\n      }\n    }\n  ";
    var folderResponseAccounts = "\n    id\n    accounts {\n      id\n      email\n      shareInfo {\n        canEdit\n      }\n    }\n  ";
    var folderResponseGroups = "\n    id\n    groups {\n      id\n      shareInfo {\n        canEdit\n      }\n    }\n  ";
    var modelNameToResponse = function modelNameToResponse(modelName) {
      switch (modelName) {
        case 'account':
          return folderResponseAccounts;
        case 'company':
          return folderResponseCompanies;
        case 'group':
          return folderResponseGroups;
      }
    };
    var sharedEntitiesIndex = 0;
    var unsharedEntitiesIndex = 0;
    var name = Ember.get(folder, 'name');
    if (Ember.get(editedProperties, 'sharedEntities')) {
      Ember.get(editedProperties, 'sharedEntities').forEach(function (entity) {
        var modelName = Ember.get(entity, 'modelName');
        mutations.pushObject("sharedEntities".concat(sharedEntitiesIndex, ": shareFolderTo").concat(modelName.capitalize(), "(folderID: \"").concat(Ember.get(folder, 'id'), "\", ").concat(modelName, "ID: \"").concat(Ember.get(entity, 'id'), "\", canEdit: ").concat(Ember.get(entity, 'canEdit'), ", shouldSendEmail: ").concat(sendEmail, ") {\n          ").concat(modelNameToResponse(modelName), "\n        }"));
        sharedEntitiesIndex++;
      });
    }
    if (Ember.get(editedProperties, 'unsharedEntities')) {
      Ember.get(editedProperties, 'unsharedEntities').forEach(function (entity) {
        var modelName = Ember.get(entity, 'modelName');
        mutations.pushObject("unsharedEntities".concat(unsharedEntitiesIndex, ": unshareFolderFrom").concat(modelName.capitalize(), "(folderID: \"").concat(Ember.get(folder, 'id'), "\", ").concat(modelName, "ID: \"").concat(Ember.get(entity, 'id'), "\") {\n          ").concat(modelNameToResponse(modelName), "\n        }"));
        unsharedEntitiesIndex++;
      });
    }
    if (Ember.get(editedProperties, 'name')) {
      mutations.pushObject("updateFolder(id: \"".concat(Ember.get(folder, 'id'), "\", name: $name) {\n          id\n          name\n      }"));
    }
    var mutationParams = Ember.get(editedProperties, 'name') ? '($name: String!)' : '';
    return {
      networkName: 'updateFolder',
      queryString: "mutation".concat(mutationParams, " {\n      ").concat(mutations, "\n    }"),
      variables: {
        name: name
      },
      update: function update(response, graph) {
        var sharedEntitiesIndex = 0;
        var unsharedEntitiesIndex = 0;
        if (Ember.get(editedProperties, 'sharedEntities')) {
          Ember.get(editedProperties, 'sharedEntities').forEach(function () {
            graph.recordFromGraphData(false, 'folder', Ember.get(response, "data.sharedEntities".concat(sharedEntitiesIndex)));
            sharedEntitiesIndex++;
          });
        }
        if (Ember.get(editedProperties, 'unsharedEntities')) {
          Ember.get(editedProperties, 'unsharedEntities').forEach(function () {
            graph.recordFromGraphData(false, 'folder', Ember.get(response, "data.unsharedEntities".concat(unsharedEntitiesIndex)));
            unsharedEntitiesIndex++;
          });
        }
        if (Ember.get(editedProperties, 'name')) {
          graph.recordFromGraphData(false, 'folder', response.data.updateReport);
        }
      }
    };
  };
  var removeAccountFolderAccess = _exports.removeAccountFolderAccess = function removeAccountFolderAccess(folderID, accountId) {
    return {
      networkName: 'removeAccountReportsAccess',
      queryString: "mutation {\n      unshareFolderFromAccount(folderID: \"".concat(folderID, "\", accountID: \"").concat(accountId, "\"){\n        id\n        accounts {\n          id\n          email\n          shareInfo {\n            canEdit\n          }\n        }\n      }\n    }")
    };
  };
  var createFolder = _exports.createFolder = function createFolder(name) {
    // We get accounts returned for permissions
    // We get reports returned so its an empty array for our item count
    return {
      networkName: 'createFolder',
      queryString: "mutation($name: String!) {\n      createFolder(name: $name){\n        id\n        name\n        owner {\n          id\n          email\n        }\n        accounts {\n          id\n          email\n          shareInfo {\n            canEdit\n          }\n        }\n        companies {\n          id\n          name\n          shareInfo {\n            canEdit\n          }\n        }\n        reports {\n          id\n          folders {\n            id\n            shareInfo {\n              canEdit\n            }\n          }\n        }\n        groups {\n          id\n          shareInfo {\n            canEdit\n          }\n        }\n      }\n    }",
      variables: {
        name: name
      },
      update: function update(response, graph) {
        return graph.recordFromGraphData(false, 'folder', response.data.createFolder);
      }
    };
  };
  var _default = _exports.default = {
    deleteFolder: deleteFolder,
    updateFolder: updateFolder,
    removeAccountFolderAccess: removeAccountFolderAccess,
    createFolder: createFolder
  };
});