define("affinio/templates/components/note-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "qFKqce49",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\"],[11,\"class\",\"section-header\"],[11,\"role\",\"button\"],[9],[0,\"\\n  \"],[1,[27,\"if\",[[23,[\"isShareNotes\"]],\"Message\",\"Notes\"],null],false],[0,\"\\n  \"],[1,[27,\"if\",[[23,[\"showNotes\"]],[27,\"font-awesome\",[\"caret-up\"],null],[27,\"font-awesome\",[\"caret-down\"],null]],null],false],[0,\"\\n\"],[3,\"action\",[[22,0,[]],\"toggle\",\"showNotes\"]],[10],[0,\"\\n\"],[4,\"unless\",[[23,[\"showNotes\"]]],null,{\"statements\":[[0,\"  \"],[7,\"hr\"],[11,\"class\",\"admin-hr\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"showNotes\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"textarea\",null,[[\"value\",\"placeholder\"],[[23,[\"note\"]],[27,\"if\",[[23,[\"placeholder\"]],[23,[\"placeholder\"]],\"\"],null]]]],false],[0,\"\\n  \"],[7,\"hr\"],[11,\"class\",\"admin-hr-bottom\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/note-field.hbs"
    }
  });
});