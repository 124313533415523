define("affinio/components/report-list", ["exports", "affinio/objects/object-promise-proxy", "affinio/utils/regex"], function (_exports, _objectPromiseProxy, _regex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
  var keys = window.Object.keys;
  var SOCIAL_NETWORKS = ['Twitter', 'Reddit', 'Pinterest', 'Instagram'];
  var _default = _exports.default = Ember.Component.extend({
    app: Ember.inject.service(),
    user: Ember.inject.service(),
    modalManager: Ember.inject.service(),
    store: Ember.inject.service(),
    tracker: Ember.inject.service(),
    screen: Ember.inject.service(),
    localStorage: Ember.inject.service(),
    tagName: 'section',
    classNames: ['report-list-container'],
    showFailed: false,
    showFolderOwned: true,
    showPending: true,
    showAccountOwned: Ember.computed.alias('localStorage.showAccountOwned'),
    showGroupOwned: Ember.computed.alias('localStorage.showGroupOwned'),
    showCompanyOwned: Ember.computed.alias('localStorage.showCompanyOwned'),
    showSearchTips: false,
    sorter: 'monitoredDateUpdated',
    // default sorting column for reports. Possible values: dateUpdated, creator.name, membersCount, numberOfClusters, name
    sortAscending: true,
    isAdmin: false,
    reports: Ember.computed('_reports.[]',
    // Initial set to be passed in
    'reportSearchInput',
    // Lets the user search in-component
    'sorter',
    // Sorting criteria ('dateUpdated', 'numberOfMembrs', 'creator.firstName', etc.)
    'sortAscending',
    // Sort direction
    'network',
    // Lets the user specify a network to view
    'showAccountOwned',
    // Lets the user show/hide reports they personally have access to
    'showGroupOwned',
    // Lets the user show/hide reports they have access to because of their group membership
    'showCompanyOwned',
    // Lets the user show/hide reports they have access to because of their company membership
    'showFolderOwned',
    // Show/hide reports if they're in folders
    'showPending',
    // Show/hide reports if they're in pending state
    'folder',
    // Let the user dig into a folder specifically
    function () {
      var _this = this;
      // Initial set, passed in from hbs call (for reports index, it's user.reports)
      var reports = Ember.get(this, '_reports').reject(function (n) {
        return Ember.get(n, 'isBaseline') && Ember.get(n, 'creator.id') !== Ember.get(_this, 'user.account.id');
      });
      // console.log('reports', reports);
      // Only show a specific network if the user has selected it
      var network = Ember.get(this, 'network');
      if (network !== 'All Networks') {
        if (SOCIAL_NETWORKS.includes(network)) {
          reports = reports.filterBy('socialNetwork', network);
        } else {
          reports = reports.filterBy('_taxonomy.label', network);
        }
      }
      // console.log('network', network);

      // Filter based on search criteria / filters key filtering
      var reportSearchInput = Ember.get(this, 'reportSearchInput');
      if (reportSearchInput) {
        // Using special filters from possibleFilterKeys
        if (reportSearchInput.indexOf(':') > -1) {
          var possibleFilterKeys = Ember.get(this, 'possibleFilterKeys');
          var filteredArray = reportSearchInput.split(':').map(function (n) {
            return n.trim();
          });
          var foundKey = possibleFilterKeys.findBy('label', filteredArray[0]);
          if (foundKey) {
            var _filteredArray = _slicedToArray(filteredArray, 2),
              searched = _filteredArray[1];
            searched = searched.toLowerCase();
            if (foundKey.customLogicNeeded) {
              reports = reports.filter(function (report) {
                var matched = false;
                switch (foundKey.label) {
                  case 'competitive':
                    {
                      if (report.filters && report.filters.followersOf && report.filters.followersOf.length >= 2 && report.filters.followersOf.length <= 12) {
                        if (searched) {
                          matched = report.filters.followersOf.some(function (n) {
                            return n.toLowerCase().indexOf(searched) > -1;
                          });
                        } else {
                          matched = true;
                        }
                      }
                      break;
                    }
                }
                return matched;
              });
            } else {
              reports = reports.filter(function (report) {
                var matched = false;
                Ember.get(foundKey, 'value').forEach(function (text) {
                  if (Ember.get(report, 'filters') && Ember.get(report, 'filters')[text] && typeof Ember.get(report, 'filters')[text] === 'string' && Ember.get(report, 'filters')[text].toLowerCase().indexOf(searched) > -1) {
                    // the plain ol' string case
                    matched = true;
                  } else if (
                  // the array of strings case
                  Ember.get(report, 'filters') && Ember.get(report, 'filters')[text] && Ember.isArray(Ember.get(report, 'filters')[text]) && typeof Ember.get(report, 'filters')[text][0] === 'string' && Ember.get(report, 'filters')[text].any(function (obj) {
                    return obj.toLowerCase().indexOf(searched) > -1;
                  })) {
                    matched = true;
                  } else if (
                  // the array of objects with "term" properties case
                  Ember.get(report, 'filters') && Ember.get(report, 'filters')[text] && Ember.isArray(Ember.get(report, 'filters')[text]) && Ember.get(report, 'filters')[text][0] && Ember.get(report, 'filters')[text][0].term && Ember.get(report, 'filters')[text].any(function (obj) {
                    return obj.term.toLowerCase().indexOf(searched) > -1;
                  })) {
                    matched = true;
                  }
                }); // forEach foundKey.value
                return matched;
              });
            }
          }
        } else {
          reportSearchInput = reportSearchInput.toLowerCase();
          reports = reports.filter(function (report) {
            return Ember.get(report, 'name').toLowerCase().indexOf(reportSearchInput) > -1;
          });
        }
      }

      // Filter out pending
      if (!Ember.get(this, 'showPending')) {
        reports = reports.rejectBy('state', 'pending');
      }

      // Filter out failed
      reports = reports.rejectBy('state', 'failed');

      // Show/hide reports based on view preferences
      var isAdmin = Ember.get(this, 'isAdmin');
      var isBuilder = Ember.get(this, 'isBuilder');
      if (!isAdmin && !isBuilder) {
        // console.log('hit here');
        reports = reports.filter(function (report) {
          if (Ember.get(_this, 'showAccountOwned')) {
            if (Ember.get(report, 'accountAccess')) {
              return true;
            }
          }
          if (Ember.get(_this, 'showCompanyOwned')) {
            if (Ember.get(report, 'companyAccess')) {
              return true;
            }
          }
          if (Ember.get(_this, 'showGroupOwned')) {
            if (Ember.get(report, 'groupAccess')) {
              return true;
            }
          }
          if (Ember.get(_this, 'folder')) {
            if (Ember.get(report, 'folderAccess')) {
              return true;
            }
          }
        });

        // Let the user dig into a specific folder
        if (Ember.get(this, 'folder')) {
          reports = reports.filter(function (report) {
            // console.log(get(report, 'folders'), get(this, 'folder'));
            return Ember.get(report, 'folders').mapBy('id').includes(Ember.get(_this, 'folder'));
          });
        }
      }
      // Sorting and sort order
      reports = reports.sortBy(Ember.get(this, 'sorter'));
      if (Ember.get(this, 'sortAscending')) {
        reports = reports.reverse();
      }
      return reports;
    }),
    selectedReports: Ember.computed.filterBy('reports', 'checked'),
    setNetwork: 'All Networks',
    // used as default for network comp prop below

    canShowReportSharing: Ember.computed(function () {
      // only show sharing if this is from you company or if not on admin
      return Ember.get(this, 'isExistingCompany') === Ember.get(this, 'user.account.company.id') || !Ember.get(this, 'isAdmin');
    }),
    // For the dropdown
    networks: Ember.computed('_reports.{[],@each._taxonomy.label}', function () {
      var reports = Ember.get(this, '_reports');
      // TODO base this off taxo
      // does it need to be objpromproxy?
      // console.log('reports from networks?', reports, reports instanceof ObjectPromiseProxy);
      if (reports instanceof _objectPromiseProxy.default) {
        // console.log('in', reports.isFulfilled);
        if (reports.isFulfilled) {
          // console.log('a');
          var social = Ember.get(reports, 'content').rejectBy('socialNetwork', 'Custom').mapBy('socialNetwork').uniq().sort();
          var taxo = Ember.get(reports, 'content').filterBy('socialNetwork', 'Custom').mapBy('_taxonomy.label').uniq().sort();
          return ['All Networks'].concat(_toConsumableArray(social), _toConsumableArray(taxo));
        } else {
          // console.log('b');
          return ['All Networks'];
        }
      } else {
        var _social = reports.rejectBy('socialNetwork', 'Custom').mapBy('socialNetwork').uniq().sort();
        var _taxo = reports.filterBy('socialNetwork', 'Custom').mapBy('_taxonomy.label').uniq().sort().compact();
        return ['All Networks'].concat(_toConsumableArray(_social), _toConsumableArray(_taxo));
      }
    }),
    network: Ember.computed('networks.[]', {
      get: function get() {
        return Ember.get(this, 'networks').indexOf(Ember.get(this, 'setNetwork')) > -1 ? Ember.get(this, 'setNetwork') : Ember.get(this, 'networks').objectAt(0);
      },
      set: function set(key, value) {
        Ember.set(this, 'setNetwork', value);
        return value;
      }
    }),
    currentFolderReportsFiltered: Ember.computed('currentFolder.reports', 'networkFilter', function () {
      var currentFolder = Ember.get(this, 'currentFolder');
      var network = Ember.get(this, 'networkFilter');
      if (network) {
        network = network.toLowerCase();
      }
      if (currentFolder) {
        if (network && network !== 'all networks') {
          return Ember.get(currentFolder, 'reports').filter(function (report) {
            var reportNetwork = Ember.get(report, 'socialNetwork');
            return network === (reportNetwork && reportNetwork.toLowerCase());
          });
        } else {
          return Ember.get(currentFolder, 'reports');
        }
      } else {
        return null;
      }
    }),
    changeFolder: function changeFolder(folder) {
      Ember.set(this, 'currentFolder', folder);
    },
    // handle absolute-positioned search tips box to push down report list an appropriate amount.
    setSearchTipsPadding: Ember.observer('screen.width', '_reports.[]', 'showSearchTips', function () {
      var _this2 = this;
      Ember.run.scheduleOnce('afterRender', this, function () {
        if (Ember.get(_this2, 'showSearchTips')) {
          Ember.set(_this2, 'searchTipsPadding', Ember.String.htmlSafe("padding-bottom: ".concat(Ember.$('.search-tips').height() + 40, "px")));
        } else {
          Ember.set(_this2, 'searchTipsPadding', Ember.String.htmlSafe('padding-bottom: 20px'));
        }
      }); // afterRender
    }),
    possibleFilterKeys: Ember.computed('_reports.[]', function () {
      var reports = Ember.get(this, '_reports');
      var possibleFilterKeys = Ember.A([{
        label: 'keywords',
        'value': ['bioKeywords']
      }, {
        label: 'location',
        'value': ['bioLocations']
      }, {
        label: 'followers of',
        'value': ['followersOf']
      }, {
        label: 'competitive',
        'value': [],
        'customLogicNeeded': true
      }, {
        label: 'content',
        'value': ['contentQuery']
      }, {
        label: 'tracked terms',
        'value': ['explicitWords']
      }, {
        label: 'tracked interests groups',
        'value': ['ownedBenchmarks', 'competitiveBenchmarks']
      }, {
        label: 'translated to',
        'value': ['translationCode']
      }]);
      possibleFilterKeys.setEach('found', false);

      // Get examples and set them to possibleFilterKeys
      reports.mapBy('filters').map(function (n) {
        // remove filter properties with empty arrays
        if (n) {
          keys(n).forEach(function (key) {
            if (Ember.isArray(n[key]) && !n[key].length) {
              delete n[key];
            }
          });
        }
        return n;
      }).forEach(function (n) {
        // find examples
        if (n) {
          keys(n).forEach(function (key) {
            var foundKey = possibleFilterKeys.find(function (o) {
              return o.value.indexOf(key) > -1;
            });
            if (foundKey && !foundKey.found) {
              var example;
              if (typeof n[key] === 'string') {
                example = n[key].replace(_regex.doubleQuoteRegex, '');
              } else if (Ember.isArray(n[key]) && n[key][0] && typeof n[key][0] === 'string') {
                example = n[key][0].replace(_regex.doubleQuoteRegex, '');
              } else if (Ember.isArray(n[key]) && n[key][0] && n[key][0].term) {
                example = n[key][0].term.replace(_regex.doubleQuoteRegex, '');
              }
              // Sometimes we store arrays in a [dash|comma]-divided string. Only show the first of these in an example.
              if (example && example.indexOf('-') > -1) {
                example = example.split('-')[0];
              }
              if (example && example.indexOf(',') > -1) {
                example = example.split(',')[0];
              }
              // For content reports, we store the filters in "(keyword OR keyword2 AND keyword3)" format;
              // Parse this to just give us an example of a single keyword
              if (example && Ember.get(foundKey, 'label') === 'content') {
                example = example.replace(/\(|\)/g, '').split('OR')[0].split('AND')[0].trim();
              }
              if (example) {
                Ember.set(foundKey, 'found', true); // eslint-disable-line ember/no-side-effects
                Ember.set(foundKey, 'example', example); // eslint-disable-line ember/no-side-effects
              }
            }
          });
        }
      });
      possibleFilterKeys.filterBy('customLogicNeeded').forEach(function (n) {
        switch (n.label) {
          case 'competitive':
            {
              var competitiveReports = reports.mapBy('filters').compact().filter(function (f) {
                return f.followersOf && f.followersOf.length >= 2 && f.followersOf.length <= 12;
              });
              if (competitiveReports.length) {
                Ember.set(n, 'found', true); // eslint-disable-line ember/no-side-effects
                Ember.set(n, 'example', competitiveReports[0].followersOf[0]); // eslint-disable-line ember/no-side-effects
              }
              break;
            }
        }
      });
      return possibleFilterKeys;
    }),
    // isEmpty: "I've loaded reports but there aren't any here"
    isEmpty: Ember.computed('shownReports.[]', 'user.reportsPromise0.isFulfilled', function () {
      if (Ember.get(this, 'user.reportsPromise0.isFulfilled') && !Ember.get(this, '_reports.length')) {
        return {
          state: true,
          type: 'empty'
        };
      } else if (Ember.get(this, 'user.reportsPromise0.isFulfilled') && Ember.get(this, '_reports.length')) {
        return {
          state: Ember.get(this, 'sortedReports.length') < 1,
          type: 'filters'
        };
      }
    }),
    sortedReports: Ember.computed('reports.[]', 'network', 'sorter', 'sortAscending', function () {
      var reports = Ember.get(this, 'reports');
      reports = reports.sortBy(Ember.get(this, 'sorter')).filter(function (n) {
        return Ember.get(n, 'state') !== 'failed';
      });
      // .filter((n) => {
      //   return n.get('state') === 'finished'
      //       || n.get('state') === 'pending'
      //       || n.get('state') === 'almost_finished';
      // });

      if (Ember.get(this, 'sortAscending')) {
        reports = reports.reverse();
      } // if ascending

      return reports;
    }),
    // TODO: Pagination, someday.
    shownReports: Ember.computed.alias('sortedReports'),
    allChecked: Ember.computed('shownReports.@each.checked', function () {
      return Ember.get(this, 'shownReports').every(function (r) {
        return Ember.get(r, 'checked');
      });
    }),
    actions: {
      toggleAllReportChecks: function toggleAllReportChecks() {
        Ember.get(this, 'reports').setEach('checked', Ember.get(this, 'allChecked') ? false : true);
      },
      sortBy: function sortBy(sorter) {
        Ember.get(this, 'tracker').track('Campaigns Sorted', {
          sortedBy: sorter
        });

        // TODO: toggle property
        // console.log(sorter, get(this, 'sorter'));

        if (sorter === Ember.get(this, 'sorter')) {
          // if you're sorting by the current sorter...
          if (Ember.get(this, 'sortAscending') === false) {
            // and it's descending,
            Ember.set(this, 'sortAscending', true); // set to ascending.
          } else {
            // or if it's ascending,
            Ember.set(this, 'sortAscending', false); // set to descending.
          }
        } else {
          // but if it's another sorter altogether
          Ember.set(this, 'sorter', sorter); // replace the global sorter.
          Ember.set(this, 'sortAscending', false);
        }
      },
      // sortBy
      addKeyToSearch: function addKeyToSearch(key) {
        Ember.set(this, 'reportSearchInput', "".concat(key, ":"));
        Ember.$('.suggestionInput').focus();
      }
    }
  });
});