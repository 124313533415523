define("affinio/components/trait-instance", ["exports", "affinio/utils/get-with-default", "ember-cli-string-helpers/helpers/humanize", "ember-cli-string-helpers/helpers/titleize"], function (_exports, _getWithDefault, _humanize, _titleize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Element
    tagName: 'a',
    classNames: ['trait-instance', 'clearfix'],
    classNameBindings: ['instance.timesMoreLikelyThanBaseline:has-baseline'],
    tracker: Ember.inject.service(),
    modalManager: Ember.inject.service(),
    graph: Ember.inject.service(),
    path: Ember.inject.service(),
    store: Ember.inject.service(),
    assetMap: Ember.inject.service(),
    // We inherit the majority of our calculations from a single 'instance' property in metric-visualiation.hbs
    // Alias them for efficiency
    label: Ember.computed.alias('instance.label'),
    value: Ember.computed.alias('instance.value'),
    traitScore: Ember.computed.alias('instance.traitScore'),
    timesMoreLikelyThanBaseline: Ember.computed.alias('instance.timesMoreLikelyThanBaseline'),
    saturation: Ember.computed.alias('instance.saturation'),
    baselineSaturation: Ember.computed.alias('instance.baselineSaturation'),
    metric: Ember.computed.alias('instance.metric'),
    originalMetric: Ember.computed.alias('instance.originalMetric'),
    // Determines the width of the bar

    max: Ember.computed('maxTraitScore', 'maxTimesMoreLikelyThanBaseline', 'selectedBaselineOption', function () {
      var baselinedReport = false;
      if (Ember.get(this, 'selectedBaselineOption') === 'baseline') {
        baselinedReport = true;
      }
      return baselinedReport ? Ember.get(this, 'maxTimesMoreLikelyThanBaseline') : Ember.get(this, 'maxTraitScore');
    }),
    percentage: Ember.computed('max', 'traitScore', 'timesMoreLikelyThanBaseline', 'selectedBaselineOption', function () {
      var baselinedReport = false;
      if (Ember.get(this, 'selectedBaselineOption') === 'baseline') {
        baselinedReport = true;
      }
      var propToMeasure = 'traitScore';
      if (baselinedReport) {
        propToMeasure = 'timesMoreLikelyThanBaseline';
      }
      var offset = 1;
      if (Ember.get(this, 'topBaselineOutweighsTopTrait')) {
        offset = Ember.get(this, 'maxTimesMoreLikelyThanBaseline');
      }
      return Ember.get(this, propToMeasure) / Ember.get(this, 'max') * 100 * offset;
    }),
    topBaselineOutweighsTopTrait: Ember.computed('maxTimesMoreLikelyThanBaseline', function () {
      return Ember.get(this, 'maxTimesMoreLikelyThanBaseline') && Ember.get(this, 'maxTimesMoreLikelyThanBaseline') <= 1;
    }),
    // baseline bar width should always be uniform when in "vs baseline" view, and should always be proportional to the greatest diff in cluster.
    baselinePercentage: Ember.computed('max', function () {
      if (Ember.get(this, 'topBaselineOutweighsTopTrait')) {
        return 100;
      } else {
        return 1 / Ember.get(this, 'max') * 100;
      }
    }),
    niceMetricName: Ember.computed('metric', 'originalMetric', function () {
      var reportID = Ember.get(this, 'path.currentReportId');
      var _Ember$get$peekRecord = Ember.get(this, 'store').peekRecord('report-state', reportID),
        metricLabelMap = _Ember$get$peekRecord.metricLabelMap;
      var label = Ember.get(this, 'metric') === 'numericTraits' ? Ember.get(this, 'originalMetric') : Ember.get(this, 'metric');
      if (metricLabelMap.has(label)) {
        return metricLabelMap.get(label); // eslint-disable-line ember/use-ember-get-and-set
      } else {
        return (0, _titleize.titleize)([(0, _humanize.humanize)([label.dasherize()])]);
      }
    }),
    actualInstance: Ember.computed('instance.instanceLookup', 'metric', function () {
      var metric = Ember.get(this, 'metric');
      var response = Ember.get(this, "cluster.".concat(metric, ".response"));
      if (response) {
        return metric === 'numericTraits' ? response.findBy('label', Ember.get(this, 'instance.label')) : response.findBy('label', Ember.get(this, 'instance.instanceLookup')) || response.findBy('networkID', Ember.get(this, 'instance.instanceLookup'));
      } else {
        return {};
      }
    }),
    icon: Ember.computed('metric', 'assetMap.map', function () {
      var icon = Ember.get(this, 'actualInstance.iconImage') || (Ember.get(this, 'cluster.report.taxonomy.metricDefinitions') && Ember.get(this, 'cluster.report.taxonomy.metricDefinitions').findBy('label', Ember.get(this, 'metric')) ? Ember.get(this, 'assetMap').resolve(Ember.get(Ember.get(this, 'cluster.report.taxonomy.metricDefinitions').findBy('label', Ember.get(this, 'metric')), 'metricIcon')) : null) || Ember.get(this, 'assetMap').resolve('/assets/images/metric-icons/trait.png');
      return icon;
    }),
    // Interests don't have obviously available cluster saturations; show relevance scores instead.
    // Second: records_generator.js is run at a time when cluster membersCounts aren't ever north of 50k. Therefore, using
    // instance.record[n].timesMoreLikely is artificially inflated in the recordsMap (by a factor of report.membersCount / 50k).
    // Thus, we get the actual interest, which API verifies has an accurate relevance score, and use that.
    // -PR, on a lovely night in Halifax, July 2018
    interestRelevance: Ember.computed('metric', 'label', function () {
      var metric = Ember.get(this, 'metric');
      if (metric === 'interests') {
        var metricResponse = Ember.get(Ember.get(Ember.get(this, 'cluster'), metric), 'response');
        var metricInstance = metricResponse && Ember.get(metricResponse, 'length') ? metricResponse.findBy('networkID', Ember.get(this, 'instance.instanceLookup')) : null;
        if (metricInstance) {
          return Ember.get(metricInstance, 'relevance');
        }
      } else {
        return null;
      }
    }),
    style: Ember.computed('percentage', function () {
      return Ember.String.htmlSafe("\n      width: calc(".concat(Ember.get(this, 'percentage'), "%);\n      background-color: ").concat(Ember.get(this, 'color'), ";\n    "));
    }),
    baselineStyle: Ember.computed('baselinePercentage', function () {
      return Ember.String.htmlSafe("\n      width: ".concat(Ember.get(this, 'baselinePercentage'), "%;\n      background-color: #333333;\n    "));
    }),
    click: function click() {
      Ember.get(this, 'modalManager').openModal('modals/modal-focus', {
        // This feels wrong but it makes it work on all network types
        instance: Object.assign({
          records: Ember.get(this, 'instance.records')
        }, (0, _getWithDefault.default)(this, 'actualInstance', Ember.get(this, 'instance'))),
        label: Ember.get(this, 'instance.label'),
        metric: Ember.get(this, 'instance.metric'),
        cluster: Ember.get(this, 'cluster'),
        recordsLookup: Ember.get(this, 'instance.instanceLookup')
      });
    }
  });
});