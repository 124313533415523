define("affinio/components/radio-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: 'input',
    type: 'radio',
    // Required:
    // value - the value for this radio button
    // groupValue - the currently selected value

    // Optional:
    // disabled - boolean
    // required - boolean
    // name - string

    attributeBindings: ['checked', 'disabled', 'name', 'required', 'type', 'value'],
    layout: null,
    // ie8 :(

    checked: Ember.computed('groupValue', 'value', function () {
      return Ember.get(this, 'groupValue') === Ember.get(this, 'value');
    }).readOnly(),
    change: function change() {
      var value = Ember.get(this, 'value');
      if (Ember.get(this, 'groupValue') !== value) {
        Ember.set(this, 'groupValue', value);
      }
    }
  });
});