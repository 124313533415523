define("affinio/templates/components/old/sample-tweet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "NGrxsf9k",
    "block": "{\"symbols\":[\"word\",\"&default\"],\"statements\":[[7,\"p\"],[11,\"class\",\"tweet-box\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"words\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"old/votable-word\",null,[[\"word\",\"hoverWord\",\"hoverable\"],[[22,1,[]],[23,[\"hoverWord\"]],true]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"],[14,2],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/old/sample-tweet.hbs"
    }
  });
});