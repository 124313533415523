define("affinio/components/behavior-graph", ["exports", "d3v5", "affinio/utils/regex", "affinio/utils/constants/metric-icons"], function (_exports, _d3v, _regex, _metricIcons) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['behavior-graph'],
    graph: Ember.inject.service(),
    tracker: Ember.inject.service(),
    path: Ember.inject.service(),
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;
      this._super.apply(this, arguments);
      Ember.get(this, 'graph').loadMetrics([Ember.get(Ember.get(this, 'cluster'), Ember.get(this, 'metric'))]).then(function () {
        Ember.run.scheduleOnce('render', _this, function () {
          // if (get(this, 'nodes.length')) {
          this.establishDefaults();
          // this.adjustCanvasSize();
          this.send('clickNode', Ember.get(this, 'instance'));
          this.drawCircles();
          // }
        });
      });
    },
    recordsArray: Ember.computed('report.recordsArray', function () {
      return {
        isFulfilled: true,
        content: Ember.get(this, 'report.recordsArray')
      };
    }),
    canvasSize: Ember.computed('screen.width', function () {
      return {
        width: 600,
        height: 800
      };
    }),
    lowestAcceptableCloseness: 0.2,
    params: Ember.computed('canvasSize.width', function () {
      return {
        minNodeRadius: 10,
        maxNodeRadius: 30,
        minLinkDistance: 100,
        maxLinkDistance: Ember.get(this, 'canvasSize.width') * .4,
        // maxLinkDistance: 100,
        linkStrength: 1,
        textBoxPadding: {
          x: 5,
          y: 3
        }
      };
    }),
    // Set initial nodes and edges, widths, etc.
    establishDefaults: function establishDefaults() {
      var svg = _d3v.default.select(this.$('.icon-box')[0]); // cant do this w multiples

      svg.selectAll('g').data([]).exit().remove(); // clear the old
      svg.selectAll('defs').data([]).exit().remove(); // clear the old

      // Establish force constraints: first, link closeness, second, centre of the canvas. Both established in drawCircles()
      var simulation = _d3v.default.forceSimulation().force('link', _d3v.default.forceLink()).force('center', _d3v.default.forceCenter());

      // set up patterns (icons)
      var defs = svg.append('svg:defs');
      Ember.set(this, 'defs', defs);
      var patterns = defs.selectAll('pattern').data(['hi']).enter().append('svg:pattern').attr('id', function (d, i) {
        return "pattern-".concat(i);
      }).attr('width', 1).attr('height', 1).attr('viewBox', '0 0 72 72').attr('preserveAspectRatio', 'none');
      patterns.append('svg:image').attr('image-rendering', 'optimizeQuality').attr('width', 72).attr('height', 72).attr('xlink:href', '/assets/images/odc-icons/affluence.png');
      Ember.set(this, 'simulation', simulation);
      Ember.set(this, 'svg', svg);
      Ember.set(this, 'patterns', patterns);

      // window.simulation = simulation;
      // window.D3 = d3;
    },
    drawCircles: function drawCircles() {
      var _this2 = this;
      var nodes = Ember.get(this, 'nodes');
      var edges = Ember.get(this, 'edges');
      var params = Ember.get(this, 'params');
      var canvasSize = Ember.get(this, 'canvasSize');
      var svg = Ember.get(this, 'svg');
      var everything = svg.append('g').attr('class', 'everything'); // for the zoomies
      var simulation = Ember.get(this, 'simulation');

      // Ideally this should be cut out at reportgen level
      // but alas here we are - JK Jul 2018
      nodes.forEach(function (n) {
        n.id = n.id.replace(_regex.inputOptimizerSpecialRegex, '').replace(_regex.lineBreakRegex, '').replace(_regex.whiteSpaceRegex, '');
      });
      edges.forEach(function (n) {
        n.target = n.target.replace(_regex.inputOptimizerSpecialRegex, '').replace(_regex.lineBreakRegex, '').replace(_regex.whiteSpaceRegex, '');
        n.source = n.source.replace(_regex.inputOptimizerSpecialRegex, '').replace(_regex.lineBreakRegex, '').replace(_regex.whiteSpaceRegex, '');
      });
      var patterns = Ember.get(this, 'defs').selectAll('patterns').data(nodes).enter().append('svg:pattern').attr('id', function (d) {
        return "pattern-".concat(Ember.get(d, 'id'));
      }).attr('width', 1).attr('height', 1).attr('viewBox', '0 0 72 72').attr('preserveAspectRatio', 'none');
      patterns.append('svg:image').attr('width', 72).attr('height', 72).attr('image-rendering', 'optimizeQuality').attr('xlink:href', function (d) {
        if (Ember.get(d, 'icon')) {
          return Ember.get(d, 'icon');
        } else {
          return _metricIcons.metricIcons.findBy('label', Ember.get(d, 'metric')) ? Ember.get(_metricIcons.metricIcons.findBy('label', Ember.get(d, 'metric')), 'value') : '/assets/images/metric-icons/trait.png';
        }
      });
      var nodeSizeScale = _d3v.default.scaleLinear().domain([_.min(nodes, 'value').value, _.max(nodes, 'value').value]).range([Ember.get(params, 'minNodeRadius'), Ember.get(params, 'maxNodeRadius')]);

      // Inverted scale
      var linkDistanceScale = _d3v.default.scaleLinear().domain([_.max(edges, 'value').value, _.min(edges, 'value').value]).range([Ember.get(params, 'minLinkDistance'), Ember.get(params, 'maxLinkDistance')]);
      var linkOpacityScale = _d3v.default.scaleLinear().domain([_.min(edges, 'value').value, _.max(edges, 'value').value]).range([0.2, 1]);
      var link;
      if (edges) {
        link = everything.append('g').attr('class', 'links').selectAll('line').data(edges).enter().append('line').style('stroke-opacity', function (link) {
          return linkOpacityScale(Ember.get(link, 'value'));
        });
      }
      var node = everything.append('g').attr('class', 'nodes')
      // .selectAll('circle')
      .selectAll('.node').data(nodes).enter().append('g').attr('class', 'node').attr('x', function (d, i) {
        var x = Math.cos(i / (nodes.length - 1) * 2 * Math.PI) * 200 + canvasSize.width / 2;
        Ember.set(d, 'x', x);
        if (i === 0) {
          Ember.set(d, 'x', canvasSize.width / 2);
          return Ember.get(d, 'x');
        }
        return Ember.get(d, 'x');
      }).attr('y', function (d, i) {
        var y = Math.sin(i / (nodes.length - 1) * 2 * Math.PI) * 200 + canvasSize.height / 2;
        // let y = Math.sin(i * 360 / (nodes.length - 1)) * 200 + canvasSize.height / 2;
        Ember.set(d, 'y', y);
        if (i === 0) {
          Ember.set(d, 'y', canvasSize.height / 2);
          return Ember.get(d, 'y');
        }
        return Ember.get(d, 'y');
      }).on('click', function (node) {
        _d3v.default.event.stopPropagation();
        _this2.send('refocus', node);
      }).on('contextmenu', function (node) {
        _d3v.default.event.preventDefault();
        _this2.send('doubleClickNode', node);
      });

      // window.node = node;
      // window.link = link;

      var clusters = Ember.get(this, 'report.clusters'); // used to establish proper arc colors

      node.append('circle').attr('fill', function (d) {
        return "url(#pattern-".concat(Ember.get(d, 'id'), ")");
      }).attr('r', function (n) {
        Ember.set(n, 'r', nodeSizeScale(Ember.get(n, 'value')));
        return nodeSizeScale(Ember.get(n, 'value'));
      });
      var arc = _d3v.default.arc().outerRadius(function (rec) {
        return rec.data.r + 8;
      }).innerRadius(function (rec) {
        return rec.data.r + 2;
      });
      var pie = _d3v.default.pie().value(function (record) {
        return record.value;
      });
      node.selectAll('path').data(function (d) {
        d.records.forEach(function (rec) {
          return Ember.set(rec, 'r', d.r);
        });
        return pie(d.records);
      }).enter().append('svg:path').attr('d', arc).attr('fill', function (d) {
        return Ember.get(clusters.findBy('suffix', d.data.cluster), 'color');
      });

      // node.append('title')
      //     .text(function(d) { return d.label; });

      node.append('g').attr('class', 'text-group');
      node.append('rect').attr('class', 'text-background').attr('rx', 6) // rounded corners
      .attr('ry', 6); // rounded corners

      node.append('text').attr('class', 'node-label').attr('text-anchor', 'middle').attr('dy', function (d) {
        return "".concat(d.r / Ember.get(params, 'maxNodeRadius') + 2.5, "em");
      }).text(function (d) {
        return d.label.slice(0, 30);
      });
      everything.selectAll('text.node-label').each(function (d) {
        Ember.set(d, 'boundingBox', this.getBBox());
      });
      everything.selectAll('rect.text-background').attr('width', function (d) {
        return d.boundingBox.width + Ember.get(params, 'textBoxPadding.x') * 2;
      }).attr('height', function (d) {
        return d.boundingBox.height + Ember.get(params, 'textBoxPadding.y') * 2;
      }).attr('x', function (d) {
        return d.boundingBox.x - Ember.get(params, 'textBoxPadding.x');
      }).attr('y', function (d) {
        return d.boundingBox.y - Ember.get(params, 'textBoxPadding.y');
      });
      simulation.nodes(nodes);
      simulation.force('center').x(Ember.get(this, 'canvasSize.width') * .5).y(Ember.get(this, 'canvasSize.height') * .5);

      // window.nodes = nodes;
      // window.edges = edges;

      simulation.force('link').id(function (d) {
        return d.id;
      }).distance(function (link) {
        return linkDistanceScale(Ember.get(link, 'value'));
      }).iterations(10).links(edges);

      // One-time run to establish positions, then css transitions handle the movement itself.
      Ember.run.later(function () {
        link.attr('x1', function (d) {
          return d.source.x;
        }).attr('y1', function (d) {
          return d.source.y;
        }).attr('x2', function (d) {
          return d.target.x;
        }).attr('y2', function (d) {
          return d.target.y;
        });
        node.attr('style', function (d) {
          return "transform: translate(".concat(d.x, "px,").concat(d.y, "px)");
        });
      }, 10);
    },
    actions: {
      // Clicking a node from within the graph refocuses things.
      refocus: function refocus(node) {
        Ember.set(this, 'metric', Ember.get(node, 'metric'));
        Ember.set(this, 'label', Ember.get(node, 'label'));
        if (Ember.get(node, 'networkID')) {
          Ember.set(this, 'recordsLookup', Ember.get(node, 'networkID'));
        } else {
          Ember.set(this, 'recordsLookup', null);
        }
        Ember.get(this, 'graph').loadMetrics([Ember.get(Ember.get(this, 'cluster'), Ember.get(this, 'metric'))]);
        // this.sendAction('setActiveView', 'overview');
        Ember.get(this, 'setActiveView')('overview');
        Ember.get(this, 'tracker').track('Diving into a term', {
          label: Ember.get(node, 'label'),
          metric: Ember.get(node, 'metric'),
          tab: "".concat(Ember.get(this, 'path.currentCategory'), "/").concat(Ember.get(this, 'path.currentSubcategory')),
          fromBehaviourGraph: true
        });
      },
      clickNode: function clickNode(instance) {
        var _this3 = this;
        var relatedNodes = [];
        var nodes = Ember.get(this, 'recordsArray.content');
        var node = instance;
        var found = [];

        // Filter out poor traitweighted metrics
        // TODO: maybe instead of filtering out traitWeight=0, we filter out report.schema.modules.doesn'tInclude(node)
        nodes = nodes.filter(function (node) {
          if (Ember.get(_this3, 'report.taxonomy.metricDefinitions').findBy('label', Ember.get(node, 'metric'))) {
            return Ember.get(Ember.get(_this3, 'report.taxonomy.metricDefinitions').findBy('label', Ember.get(node, 'metric')), 'traitWeight') > 0;
          }
        });
        nodes.forEach(function (othernode) {
          var closeness = othernode.records.map(function (record, iter) {
            return Math.abs(record.normalizedRatio - node.records[iter].normalizedRatio);
          }).reduce(function (m, n) {
            return m + n;
          });
          // console.log('closeness', closeness, node.label, othernode.label, node.records, othernode.records);
          // if (closeness < get(this, 'lowestAcceptableCloseness')) {
          Ember.set(othernode, 'closeness', 1 - closeness);
          if (Ember.get(othernode, 'label') === Ember.get(_this3, 'label') && Ember.get(othernode, 'metric') === Ember.get(_this3, 'metric')) {
            Ember.set(othernode, 'closeness', 1.001); // many nodes often have the same closeness if they're all 100% in a cluster. Make sure the one in the centre is the clicked one.
          }
          found.pushObject(othernode);
          // }
        });
        // relatedNodes.pushObjects(found.filter((f) => f.closeness !== 1 && f.closeness > get(this, 'lowestAcceptableCloseness')).sortBy('closeness').reverse().slice(0, 10));
        relatedNodes.pushObjects(found.filter(function (f) {
          return f.closeness > Ember.get(_this3, 'lowestAcceptableCloseness');
        }).sortBy('closeness').reverse().slice(0, 12));

        // Fix for inconsistent data (interests' labels arent their screennames):

        var allInterests;
        if (Ember.get(this, 'cluster.interests.response.length')) {
          allInterests = Ember.get(this, 'report.clusters').mapBy('interests.response').flatten().uniqBy('networkID');
        } else {
          allInterests = [];
        }
        relatedNodes.forEach(function (node) {
          if (Ember.get(node, 'metric') === 'interests') {
            // in the recordsArray interest nodes label is the metric instance networkID
            // this cleans it up so we can properly use it to get screen name/icon
            if (!Ember.get(node, 'networkID')) {
              Ember.set(node, 'networkID', Ember.get(node, 'label'));
            }
            Ember.set(node, 'label', Ember.get(allInterests.findBy('networkID', Ember.get(node, 'networkID')), 'screenName'));
            Ember.set(node, 'icon', Ember.get(allInterests.findBy('networkID', Ember.get(node, 'networkID')), 'iconImage'));
          }
        });
        relatedNodes = [relatedNodes[0]].concat(_toConsumableArray(_.shuffle(relatedNodes.slice(1))));
        var relatedEdges = relatedNodes.map(function (node) {
          return {
            source: Ember.get(relatedNodes.objectAt(0), 'id'),
            target: Ember.get(node, 'id'),
            value: Ember.get(node, 'closeness')
            // value: 1
          };
        });
        Ember.set(this, 'nodes', relatedNodes);
        Ember.set(this, 'edges', relatedEdges);
      } // // May use this again later
      // digIntoNode(instance) {
      //   if (get(instance, 'metric') === 'domains') {
      //     window.open(`https://${get(instance, 'label')}`, '_blank');
      //   }
      //   if (get(instance, 'metric') === 'links') {
      //     window.open(get(instance, 'label'), '_blank');
      //   }
      //   if (get(instance, 'metric') === 'interests') {
      //     window.open(`https://twitter.com/${get(instance, 'label')}`, '_blank');
      //   }
      //   return false;
      // }
    } // actions
  });
});