define("affinio/templates/components/asset-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "/7s9Hyyu",
    "block": "{\"symbols\":[\"tribe\",\"&default\"],\"statements\":[[4,\"each\",[[23,[\"scores\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"score-cell\",null,[[\"title\",\"isTML\",\"shadeBy\",\"tribe\",\"isHidden\",\"isLocationTimesMoreLikely\",\"isLocationRelevance\"],[[23,[\"asset\",\"screenName\"]],[23,[\"isTML\"]],[23,[\"shadeBy\"]],[22,1,[]],[22,1,[\"isHidden\"]],[23,[\"isLocationTimesMoreLikely\"]],[23,[\"isLocationRelevance\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[14,2],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/asset-row.hbs"
    }
  });
});