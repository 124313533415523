define("affinio/components/preferences/cluster-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Element
    classNames: ['cluster-selector'],
    classNameBindings: ['open'],
    tagName: 'section',
    open: false,
    //
    selectedClustersExclusive: false,
    selectedClusters: undefined,
    clusters: undefined,
    currentCluster: undefined,
    // selected cluster on laft nav bar
    init: function init() {
      this._super.apply(this, arguments);
      var selectedClusters = Ember.get(this, 'controller.selectedClusters');
      var selectedClustersExclusive = Ember.get(this, 'controller.selectedClustersExclusive');
      var clusters = Ember.get(this, 'model.report.clusters');
      var currentCluster = Ember.get(this, 'controller.metric.cluster');
      Ember.set(this, 'open', false);
      Ember.set(this, 'selectedClusters', selectedClusters);
      Ember.set(this, 'selectedClustersExclusive', selectedClustersExclusive);
      Ember.set(this, 'clusters', clusters);
      Ember.set(this, 'currentCluster', currentCluster);
    },
    didRender: function didRender() {
      // render all cluster button
      if (this.isAllClustersSelected()) {
        Ember.setProperties(this.item, {
          active: true
        });
      }
    },
    isAllClustersSelected: function isAllClustersSelected() {
      return this.item.children.every(function (childItem) {
        return childItem.active;
      });
    },
    /**
     * Generate a item for rendering the template, the item is:
     * ```ts
     * const item = {
     *    active: boolean // for render the active button
     *    children: [childItem] // related to cluster info
     * }
     * ```
     *
     * a childItem is:
     * ```ts
     * const childItem = {
     *    name: string, // cluster name
     *    cluster: Cluster, // the cluster class
     *    active: boolean, // for render the active button
     *    isCurrentCluster: boolean, // for render the disabled button
     * }
     * ```
     */
    item: Ember.computed('clusters', 'selectedClusters.[]', function () {
      var _this = this;
      var item = {
        active: false,
        children: []
      };
      var clusters = Ember.get(this, 'clusters');
      clusters.forEach(function (cluster) {
        var childItem = {
          name: cluster.name,
          cluster: cluster,
          active: _this.selectedClusters.includes(cluster),
          isCurrentCluster: cluster === _this.currentCluster
        };
        item.children.push(childItem);
      });
      return item;
    }),
    actions: {
      toggleOpen: function toggleOpen() {
        this.toggleProperty('open');
      },
      toggleOpenAtBackground: function toggleOpenAtBackground(event) {
        var modal = document.querySelector('.cluster-selector .cluster-modal');
        var target = event.target;
        if (modal === target) {
          this.toggleProperty('open');
        }
      },
      selectAllCluster: function selectAllCluster(item) {
        var selectedClusters = Ember.get(this, 'selectedClusters');
        if (item.active) {
          // if all cluster button is active,
          // remove all clusters and set item.active = false
          selectedClusters.clear();
          Ember.setProperties(item, {
            active: false
          });
          item.children.forEach(function (childItem) {
            Ember.setProperties(childItem, {
              active: false
            });
            selectedClusters.removeObject(childItem.cluster);
          });
        } else {
          // if all cluster button is not active
          // add all clusters and set item.active = true
          Ember.setProperties(item, {
            active: true
          });
          item.children.forEach(function (childItem) {
            Ember.setProperties(childItem, {
              active: true
            });
            selectedClusters.addObject(childItem.cluster);
          });
        }
      },
      selectCluster: function selectCluster(childItem, item) {
        var selectedClusters = Ember.get(this, 'selectedClusters');
        if (childItem.active) {
          // remove cluster from selectedClusters
          selectedClusters.removeObject(childItem.cluster);
          Ember.setProperties(childItem, {
            active: false
          });

          // set all cluster button to inactive
          Ember.setProperties(item, {
            active: false
          });
        } else {
          // if childItem is not active
          // set it to active, add it to selectedClusters

          selectedClusters.addObject(childItem.cluster);
          Ember.setProperties(childItem, {
            active: true
          });

          // if selected all clusters
          if (selectedClusters.length === this.clusters.length) {
            Ember.setProperties(item, {
              active: true
            });
          }
        }
      },
      toggleUniqueCheckbox: function toggleUniqueCheckbox() {
        this.toggleProperty('controller.selectedClustersExclusive');
      },
      selectClearAllFilters: function selectClearAllFilters(item) {
        this.selectedClusters.clear();
        this.selectedClustersExclusive = false;

        // clean active status
        Ember.setProperties(item, {
          active: false
        });
        item.children.forEach(function (childItem) {
          Ember.setProperties(childItem, {
            active: false
          });
        });
      }
    }
  });
});