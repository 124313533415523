define("affinio/utils/mutations/asset-group", ["exports", "affinio/utils/get-with-default"], function (_exports, _getWithDefault) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.updateAssetGroupName = _exports.deleteAssetsFromGroupByName = _exports.deleteAssetsFromGroupByID = _exports.deleteAssetGroup = _exports.default = _exports.createAssetGroup = _exports.addAssetsToAssetGroup = void 0;
  var createAssetGroup = _exports.createAssetGroup = function createAssetGroup(name, assetType, socialNetwork, entityId) {
    var isCompany = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
    var entity = isCompany ? 'Company' : 'Account';
    return {
      networkName: 'createAssetGroup',
      queryString: "mutation {\n      createAssetGroupFor".concat(entity, "(").concat(entity.toLowerCase(), "ID:\"").concat(entityId, "\", name:\"").concat(name, "\", assetType:\"").concat(assetType, "\", socialNetwork:\"").concat(socialNetwork, "\"){\n        id\n        assetType\n        name\n        socialNetwork\n        dateCreated\n        dateUpdated\n        assets {\n          id\n          name\n        }\n      }\n    }"),
      update: function update(response, graph) {
        // Transform dates into js Date's
        response.data["createAssetGroupFor".concat(entity)].dateCreated = new Date(response.data["createAssetGroupFor".concat(entity)].dateCreated);
        response.data["createAssetGroupFor".concat(entity)].dateUpdated = new Date(response.data["createAssetGroupFor".concat(entity)].dateUpdated);
        return graph.recordFromGraphData(false, 'asset-group', response.data["createAssetGroupFor".concat(entity)]);
      }
    };
  };
  var addAssetsToAssetGroup = _exports.addAssetsToAssetGroup = function addAssetsToAssetGroup(assetGroupID, assets) {
    var mutations = Ember.A();
    assets.forEach(function (asset, i) {
      mutations.pushObject("\n      addAssetToAssetGroup".concat(i, ":addAssetToAssetGroup(assetGroupID: \"").concat(assetGroupID, "\", assetID: \"").concat((0, _getWithDefault.default)(asset, 'assetID', ''), "\", assetName: \"").concat((0, _getWithDefault.default)(asset, 'assetName', ''), "\"){\n        id\n        assets {\n          id\n          name\n        }\n      }\n    "));
    });
    return {
      networkName: 'addAssetToGroup',
      queryString: "mutation {\n      ".concat(mutations, "\n    }"),
      update: function update(response, graph) {
        return graph.recordFromGraphData(false, 'asset-group', response.data["addAssetToAssetGroup".concat(assets.length - 1)]);
      }
    };
  };
  var deleteAssetGroup = _exports.deleteAssetGroup = function deleteAssetGroup(id) {
    return {
      networkName: 'deleteAssetGroup',
      queryString: "mutation {\n      deleteAssetGroup(id: \"".concat(id, "\")\n    }")
    };
  };
  var deleteAssetsFromGroupByName = _exports.deleteAssetsFromGroupByName = function deleteAssetsFromGroupByName(assetGroupID, assets) {
    var mutations = Ember.A();
    assets.forEach(function (asset, i) {
      mutations.pushObject("\n      deleteAssetFromAssetGroup".concat(i, ":deleteAssetFromAssetGroupByName(assetGroupID: \"").concat(assetGroupID, "\", assetName: \"").concat((0, _getWithDefault.default)(asset, 'assetName', ''), "\"){\n        id\n        assets {\n          id\n          name\n        }\n      }\n    "));
    });
    return {
      networkName: 'deleteAssetsFromGroup',
      queryString: "mutation {\n      ".concat(mutations, "\n    }"),
      update: function update(response, graph) {
        return graph.recordFromGraphData(false, 'asset-group', response.data["deleteAssetFromAssetGroup".concat(assets.length - 1)]);
      }
    };
  };
  var deleteAssetsFromGroupByID = _exports.deleteAssetsFromGroupByID = function deleteAssetsFromGroupByID(assetGroupID, assets) {
    var mutations = Ember.A();
    assets.forEach(function (asset, i) {
      mutations.pushObject("\n      deleteAssetFromAssetGroup".concat(i, ":deleteAssetFromAssetGroupByID(assetGroupID: \"").concat(assetGroupID, "\", assetID: \"").concat((0, _getWithDefault.default)(asset, 'assetID', ''), "\"){\n        id\n        assets {\n          id\n          name\n        }\n      }\n    "));
    });
    return {
      networkName: 'deleteAssetsFromGroup',
      queryString: "mutation {\n      ".concat(mutations, "\n    }"),
      update: function update(response, graph) {
        return graph.recordFromGraphData(false, 'asset-group', response.data["deleteAssetFromAssetGroup".concat(assets.length - 1)]);
      }
    };
  };
  var updateAssetGroupName = _exports.updateAssetGroupName = function updateAssetGroupName(id, name) {
    return {
      networkName: 'updateAssetGroupName',
      queryString: "mutation($name: String!) {\n      updateAssetGroupName(assetGroupID: \"".concat(id, "\", name: $name) {\n        id\n        name\n      }\n    }"),
      variables: {
        name: name
      }
    };
  };
  var _default = _exports.default = {
    createAssetGroup: createAssetGroup,
    addAssetsToAssetGroup: addAssetsToAssetGroup,
    deleteAssetGroup: deleteAssetGroup,
    deleteAssetsFromGroupByName: deleteAssetsFromGroupByName,
    deleteAssetsFromGroupByID: deleteAssetsFromGroupByID,
    updateAssetGroupName: updateAssetGroupName
  };
});