define("affinio/routes/admin/tags-suggestion", ["exports", "affinio/utils/queries", "affinio/mixins/auth-check", "affinio/mixins/document-title", "affinio/utils/mutations"], function (_exports, _queries, _authCheck, _documentTitle, _mutations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_authCheck.default, _documentTitle.default, {
    graph: Ember.inject.service(),
    store: Ember.inject.service(),
    user: Ember.inject.service(),
    notify: Ember.inject.service('notify'),
    tracker: Ember.inject.service(),
    authOnly: true,
    documentTitle: 'Users - Admin - Affinio',
    model: function model() {
      var graph = Ember.get(this, 'graph');
      return graph.query(_queries.default.getCrowdSourcedTags()).then(function (res) {
        return res.tagSuggestions;
      });
    },
    actions: {
      refreshModel: function refreshModel() {
        this.refresh();
      },
      undoChanges: function undoChanges() {
        Ember.get(this.controller, 'model').rollbackAttributes();
      },
      submitTags: function submitTags() {
        var _this = this;
        var controller = this.controller;
        var data = Ember.get(controller, 'model').map(function (s) {
          return {
            id: s.id,
            status: s.status || 'rejected',
            tagName: s.suggestedTag
          };
        });
        Ember.set(controller, 'currentlyLoading', true);
        this.graph.mutate(_mutations.default.toolboxUpdateCrowdSourceTag(data)).then(function () {
          _this.notify.success('The tag updates have been successfully applied and will come into effect in the subsequent report generation.');
          _this.refresh();
        }).catch(function (error) {
          var errorMessage = Ember.get(error, 'payload.errors.0.message');
          _this.notify.error("Error suggesting tag: ".concat(errorMessage));
        }).finally(function () {
          Ember.set(controller, 'currentlyLoading', false);
        });
      }
    }
  });
});