define("affinio/components/modules/audience-header", ["exports", "affinio/mixins/affinio-module", "affinio/utils/mutations"], function (_exports, _affinioModule, _mutations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_affinioModule.default, {
    // Element
    classNames: ['audience-header'],
    tagName: 'div',
    graph: Ember.inject.service(),
    tracker: Ember.inject.service(),
    notify: Ember.inject.service('notify'),
    actions: {
      changeCampaignName: function changeCampaignName() {
        var _this = this;
        var network = Ember.get(this, 'model.report.isCustom') ? 'custom' : Ember.get(this, 'model.report.socialNetwork');
        Ember.get(this, 'graph').mutate(_mutations.default.updateReportName(Ember.get(this, 'model.report.id'), Ember.get(this, 'model.report.name'), network)).then(function /* response */
        () {
          Ember.get(_this, 'tracker').track('Renamed Report', {
            name: Ember.get(_this, 'model.report.name')
          });
          Ember.get(_this, 'notify').success('Report name saved!');
        }).catch(function (err) {
          Ember.get(_this, 'tracker').track('Renamed Report Error', {
            err: JSON.stringify(err.payload.errors[0].message)
          });
          Ember.get(_this, 'notify').error(err.payload.errors[0].message);
        });
      }
    }
  });
});