define("affinio/templates/components/twitter-stream", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "2wXedF8p",
    "block": "{\"symbols\":[\"tweet\",\"&default\"],\"statements\":[[4,\"each\",[[23,[\"loadedTweets\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"twitter-frame\",null,[[\"instance\",\"loadedAction\",\"rejectedAction\"],[[22,1,[]],[27,\"action\",[[22,0,[]],\"tweetLoaded\"],null],[27,\"action\",[[22,0,[]],\"tweetLoaded\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"  \"],[7,\"span\"],[9],[0,\"No Tweets in Stream\"],[10],[0,\"\\n\"]],\"parameters\":[]}],[4,\"if\",[[23,[\"loading\"]]],null,{\"statements\":[[0,\"  \"],[1,[21,\"loading-spinner\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[14,2],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/twitter-stream.hbs"
    }
  });
});