define("affinio/utils/mutations/advertising", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.removeTwitterAccessTokens = _exports.default = void 0;
  var removeTwitterAccessTokens = _exports.removeTwitterAccessTokens = function removeTwitterAccessTokens(id) {
    return {
      networkName: 'removeTwitterAccessTokens',
      queryString: "mutation {\n      removeTwitterAccessTokens(accountID: \"".concat(id, "\") {\n        id\n        twitterCredentials { userID screenName }\n      }\n    }"),
      update: function update(response, graph) {
        return graph.recordFromGraphData(false, 'account', response.data.removeTwitterAccessTokens);
      }
    };
  };
  var _default = _exports.default = {
    removeTwitterAccessTokens: removeTwitterAccessTokens
  };
});