define("affinio/components/affinio-table-cell/select-apply-all", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['affinio-table-cell-select-apply-all'],
    actions: {
      applyToAll: function applyToAll() {
        var key = Ember.get(this, 'column.valuePath');
        var value = Ember.get(this, "row.".concat(key));
        Ember.get(this, 'rows').setEach(key, value);
      }
    }
  });
});