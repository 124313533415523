define("affinio/components/account-preferences", ["exports", "affinio/utils/mutations"], function (_exports, _mutations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Element
    classNames: ['account-preferences'],
    // Services
    app: Ember.inject.service(),
    preferences: Ember.inject.service(),
    path: Ember.inject.service(),
    user: Ember.inject.service(),
    graph: Ember.inject.service(),
    notify: Ember.inject.service(),
    tracker: Ember.inject.service(),
    show: false,
    isViewerSeat: Ember.computed(function () {
      return Ember.get(this, 'account.permissions').indexOf('Viewer Seat') !== -1 ? true : false;
    }),
    // Computed Properties
    autoNameClusters: Ember.computed.alias('account.preferences.shouldNameClusters'),
    preferenceSavingText: 'Save Preferences',
    actions: {
      savePreferences: function savePreferences() {
        var _this = this;
        return Ember.get(this, 'graph').mutate(_mutations.default.updateAccountPreferences(Ember.get(this, 'account.id'), Ember.get(this, 'account.preferences'))).then(function () {
          Ember.get(_this, 'notify').success('Updated user preferences');
          Ember.get(_this, 'tracker').track('Updated preferences', Ember.get(_this, 'account.preferences'));
        }).catch(function (err) {
          Ember.get(_this, 'tracker').track('failed to set user preferences', {
            error: JSON.stringify(err)
          });
        });
      }
    }
  });
});