define("affinio/templates/components/modules/audience-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "O+sZXzXQ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\"],[11,\"class\",\"report-name\"],[9],[0,\"\\n  \"],[7,\"h4\"],[9],[0,\"Report Name\"],[10],[0,\"\\n  \"],[7,\"form\"],[11,\"class\",\"name-change-form\"],[9],[0,\"\\n    \"],[1,[27,\"input\",null,[[\"type\",\"value\",\"class\",\"disabled\"],[\"text\",[23,[\"model\",\"report\",\"name\"]],\"name-change-input\",[27,\"if\",[[23,[\"model\",\"report\",\"canEdit\"]],false,true],null]]]],false],[0,\"\\n\"],[4,\"if\",[[23,[\"model\",\"report\",\"canEdit\"]]],null,{\"statements\":[[0,\"      \"],[7,\"input\"],[11,\"class\",\"name-change-button button inline\"],[11,\"value\",\"Change Name\"],[11,\"type\",\"submit\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[3,\"action\",[[22,0,[]],\"changeCampaignName\"],[[\"on\"],[\"submit\"]]],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"modules/audience-details\",null,[[\"model\",\"routeController\",\"moduleRequest\",\"moduleSchema\"],[[23,[\"model\"]],[23,[\"routeController\"]],[23,[\"moduleRequest\"]],[23,[\"moduleSchema\"]]]],{\"statements\":[[0,\"  \"],[7,\"h4\"],[9],[0,\"Audience Details\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"modules/audience-source\",null,[[\"model\",\"routeController\",\"moduleRequest\",\"moduleSchema\"],[[23,[\"model\"]],[23,[\"routeController\"]],[23,[\"moduleRequest\"]],[23,[\"moduleSchema\"]]]],{\"statements\":[[0,\"  \"],[7,\"h4\"],[9],[0,\"Audience Source\"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/modules/audience-header.hbs"
    }
  });
});