define("affinio/templates/components/modules/delta-card-gallery", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "1+8xNJfG",
    "block": "{\"symbols\":[\"delta\",\"&default\"],\"statements\":[[14,2],[0,\"\\n\\n\"],[4,\"each\",[[23,[\"model\",\"cluster\",\"topRankedMetricDelta\",\"response\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"delta-card\",null,[[\"delta\",\"date\",\"previousDate\",\"record\",\"click\",\"model\"],[[22,1,[]],[23,[\"model\",\"report\",\"currentReportPeriod\"]],[23,[\"model\",\"report\",\"previousReportPeriod\"]],[27,\"map-get\",[[27,\"map-get\",[[23,[\"model\",\"report\",\"recordsMap\"]],[22,1,[\"type\"]]],null],[22,1,[\"label\"]]],null],[27,\"if\",[[22,1,[\"origin\",\"ids\"]],[27,\"action\",[[22,0,[]],\"openFocusModal\",[22,1,[]]],null]],null],[23,[\"model\"]]]]],false],[0,\"\\n  \"],[7,\"hr\"],[9],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/modules/delta-card-gallery.hbs"
    }
  });
});