define("affinio/templates/components/modals/modal-signin-failed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "IQjlV6d2",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"modal\"],[9],[0,\"\\n\"],[4,\"unless\",[[23,[\"noClose\"]]],null,{\"statements\":[[0,\"    \"],[7,\"a\"],[11,\"class\",\"close-modal\"],[11,\"href\",\"#\"],[9],[0,\"×\"],[3,\"action\",[[22,0,[]],\"close\"]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"h3\"],[11,\"class\",\"modal-title\"],[9],[0,\"Login Failed\"],[10],[0,\"\\n  \"],[7,\"p\"],[9],[0,\"\\n    Your login attempt has failed. The email and/or password may be incorrect.\\n  \"],[10],[0,\"\\n  \"],[7,\"footer\"],[9],[0,\"\\n    \"],[7,\"a\"],[11,\"class\",\"button\"],[11,\"role\",\"button\"],[9],[0,\"\\n      Try Again\\n    \"],[3,\"action\",[[22,0,[]],\"cancel\"]],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"resetAction\"]]],null,{\"statements\":[[0,\"      \"],[7,\"a\"],[11,\"class\",\"button\"],[11,\"role\",\"button\"],[9],[1,[21,\"resetText\"],false],[3,\"action\",[[22,0,[]],\"reset\"]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"backdrop\"],[11,\"role\",\"button\"],[9],[3,\"action\",[[22,0,[]],\"close\"]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/modals/modal-signin-failed.hbs"
    }
  });
});