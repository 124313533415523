define("affinio/templates/components/old/vote-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "pm+18vZ3",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"span\"],[11,\"class\",\"upvote\"],[11,\"role\",\"button\"],[9],[4,\"if\",[[23,[\"up_term\"]]],null,{\"statements\":[[1,[21,\"up_term\"],false]],\"parameters\":[]},{\"statements\":[[0,\"+\"]],\"parameters\":[]}],[3,\"action\",[[22,0,[]],\"upvote\",[23,[\"word\"]],[23,[\"wordSource\"]]],[[\"on\"],[\"mouseDown\"]]],[10],[0,\"\\n\"],[7,\"span\"],[11,\"class\",\"downvote\"],[11,\"role\",\"button\"],[9],[4,\"if\",[[23,[\"down_term\"]]],null,{\"statements\":[[1,[21,\"down_term\"],false]],\"parameters\":[]},{\"statements\":[[0,\"-\"]],\"parameters\":[]}],[3,\"action\",[[22,0,[]],\"downvote\",[23,[\"word\"]],[23,[\"wordSource\"]]],[[\"on\"],[\"mouseDown\"]]],[10],[0,\"\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/old/vote-box.hbs"
    }
  });
});