define("affinio/templates/components/affinio-module", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "BNLbC/Jt",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[27,\"hash\",null,[[\"component\",\"model\"],[[27,\"component\",[[27,\"concat\",[\"modules/\",[23,[\"moduleRequest\",\"value\"]]],null]],[[\"model\",\"cluster\",\"routeController\",\"moduleRequest\",\"moduleSchema\",\"editNoteAction\"],[[23,[\"model\"]],[23,[\"model\",\"cluster\"]],[23,[\"routeController\"]],[23,[\"moduleRequest\"]],[23,[\"moduleSchema\"]],[23,[\"editNoteAction\"]]]]],[23,[\"model\"]]]]]]],[0,\"\\n\\n\"],[4,\"if\",[[27,\"and\",[[23,[\"model\",\"editMode\"]],[27,\"not-eq\",[[23,[\"moduleRequest\",\"value\"]],\"new-module\"],null],[27,\"not\",[[23,[\"model\",\"category\",\"uneditable\"]]],null],[27,\"not\",[[23,[\"model\",\"subcategory\",\"uneditable\"]]],null]],null]],null,{\"statements\":[[0,\"  \"],[1,[27,\"custom-metric-editor\",null,[[\"model\",\"moduleRequest\",\"moduleIndex\",\"activeEditorMetric\",\"activeMap\"],[[23,[\"model\"]],[23,[\"moduleRequest\"]],[23,[\"moduleIndex\"]],[23,[\"activeEditorMetric\"]],[23,[\"activeMap\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/affinio-module.hbs"
    }
  });
});