define("affinio/components/one-way-hidden", ["exports", "ember-one-way-controls/components/one-way-hidden"], function (_exports, _oneWayHidden) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _oneWayHidden.default;
    }
  });
});