define("affinio/routes/admin/groups/index", ["exports", "affinio/utils/queries", "affinio/mixins/auth-check", "affinio/mixins/document-title"], function (_exports, _queries, _authCheck, _documentTitle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_authCheck.default, _documentTitle.default, {
    graph: Ember.inject.service(),
    store: Ember.inject.service(),
    user: Ember.inject.service(),
    tracker: Ember.inject.service(),
    notify: Ember.inject.service('notify'),
    authOnly: true,
    documentTitle: 'Groups - Admin - Affinio',
    model: function model() {
      var companyId = Ember.get(this, 'user.company.id');
      var graph = Ember.get(this, 'graph');
      return graph.query(_queries.default.groups(companyId));
    }
  });
});