define("affinio/templates/admin/tags-suggestion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "FGyrMcmP",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h2\"],[9],[0,\"Tag Suggestions\"],[10],[0,\"\\n\"],[0,\"\\n\"],[1,[27,\"tags-suggestion-list\",null,[[\"entities\",\"isAccounts\",\"showAccounts\",\"sectionSearch\",\"toggleAccountActive\"],[[23,[\"model\"]],true,true,\"accounts-search\",[27,\"route-action\",[\"showToggleAccountActiveModal\"],null]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"model\",\"length\"]]],null,{\"statements\":[[0,\"  \"],[7,\"button\"],[12,\"disabled\",[21,\"currentlyLoading\"]],[11,\"class\",\"button primary\"],[11,\"style\",\"float: right; margin-top: 20px; margin-bottom: 100px;\"],[11,\"type\",\"button\"],[9],[0,\"\\n    \"],[1,[27,\"if\",[[23,[\"currentlyLoading\"]],\"Loading...\",\"Submit\"],null],false],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],\"submitTags\"]],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/admin/tags-suggestion.hbs"
    }
  });
});