define("affinio/controllers/admin/users/tags-suggestion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    canAddAccount: Ember.computed('viewerAccountsRemaining', 'fullAccountsRemaining', function () {
      return Ember.get(this, 'viewerAccountsRemaining') + Ember.get(this, 'fullAccountsRemaining') > 0;
    }),
    fullAccountsRemaining: Ember.computed('model.company.{accounts.@each.isActive,seatLimit}', function () {
      var model = Ember.get(this, 'model');
      var accounts = Ember.get(model, 'company.accounts');
      var active = accounts.filter(function (n) {
        return Ember.get(n, 'isActive');
      }, true);
      var fullAccounts = active.filter(function (n) {
        return !Ember.get(n, 'permissions').includes('Viewer Seat');
      });
      var seatLimit = Ember.get(this, 'model.company.seatLimit');
      var fullAccountsRemaining = seatLimit - fullAccounts.length;
      return fullAccountsRemaining;
    }),
    viewerAccountsRemaining: Ember.computed('model.company.{accounts.@each.isActive,viewerSeatLimit}', function () {
      var model = Ember.get(this, 'model');
      var accounts = Ember.get(model, 'company.accounts');
      var active = accounts.filter(function (n) {
        return Ember.get(n, 'isActive');
      }, true);
      var viewerAccounts = active.filter(function (n) {
        return Ember.get(n, 'permissions').includes('Viewer Seat');
      });
      var viewerSeatLimit = Ember.get(this, 'model.company.viewerSeatLimit');
      var viewerAccountsRemaining = viewerSeatLimit - viewerAccounts.length;
      return viewerAccountsRemaining;
    })
  });
});