define("affinio/templates/components/modals/modal-datasource-column-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "CG2fKoVa",
    "block": "{\"symbols\":[\"sample\"],\"statements\":[[7,\"form\"],[11,\"class\",\"modal modal-datasource-column-preview\"],[11,\"tabindex\",\"0\"],[9],[0,\"\\n  \"],[7,\"a\"],[11,\"class\",\"close-modal\"],[11,\"href\",\"#\"],[9],[0,\"×\"],[3,\"action\",[[22,0,[]],\"close\"]],[10],[0,\"\\n  \"],[7,\"h3\"],[11,\"class\",\"modal-sub-nav-header\"],[9],[0,\"Column Preview: \"],[1,[23,[\"row\",\"label\"]],false],[10],[0,\"\\n\\n  \"],[7,\"ul\"],[9],[0,\"\\n    \"],[7,\"li\"],[9],[1,[23,[\"samples\",\"length\"]],false],[0,\" sample records from the data set\"],[10],[0,\"\\n\"],[4,\"each\",[[23,[\"samples\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\"],[9],[1,[22,1,[]],false],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"backdrop\"],[11,\"role\",\"button\"],[9],[3,\"action\",[[22,0,[]],\"close\"]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/modals/modal-datasource-column-preview.hbs"
    }
  });
});