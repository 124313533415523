define("affinio/templates/components/affinio-table-cell/percent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "jaNtYAh5",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"percent\"],[9],[0,\"\\n  \"],[7,\"span\"],[12,\"style\",[27,\"html-safe\",[[27,\"concat\",[[27,\"data-source-creator-background-color\",[[23,[\"value\"]]],null],\" \",[27,\"data-source-creator-color\",[[23,[\"value\"]]],null]],null]],null]],[9],[0,\"\\n    \"],[1,[27,\"if\",[[23,[\"value\"]],[27,\"concat\",[[23,[\"value\"]],\"%\"],null],\"-\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/affinio-table-cell/percent.hbs"
    }
  });
});