define("affinio/components/input-module", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Element
    classNames: ['input-module'],
    tagName: '',
    // Services
    modules: Ember.inject.service(),
    tracker: Ember.inject.service()
  });
});