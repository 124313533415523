define("affinio/templates/components/dropdown-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "jlTqYXzi",
    "block": "{\"symbols\":[\"o\",\"@onClick\",\"@primaryLabel\"],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"dropdown-button \",[27,\"if\",[[22,0,[\"open\"]],\"open\"],null]]]],[9],[0,\"\\n  \"],[5,\"click-outside\",[],[[\"@onClickOutside\"],[[27,\"action\",[[22,0,[]],\"closeDropdown\"],null]]],{\"statements\":[[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"toggle\"],[11,\"role\",\"button\"],[9],[0,\"\\n      \"],[7,\"div\"],[9],[1,[22,3,[]],false],[10],[0,\"\\n      \"],[7,\"div\"],[9],[1,[27,\"font-awesome\",[[27,\"if\",[[22,0,[\"open\"]],\"chevron-up\",\"chevron-down\"],null]],null],false],[10],[0,\"\\n    \"],[3,\"action\",[[22,0,[]],\"toggleDropdown\"]],[10],[0,\"\\n    \"],[7,\"ul\"],[12,\"class\",[28,[\"buttons \",[27,\"if\",[[22,0,[\"open\"]],\"open\"],null]]]],[9],[0,\"\\n\"],[4,\"each\",[[22,0,[\"_options\"]]],null,{\"statements\":[[0,\"        \"],[7,\"li\"],[12,\"class\",[27,\"if\",[[22,1,[\"disabled\"]],\"disabled\"],null]],[11,\"role\",\"button\"],[9],[0,\"\\n          \"],[1,[22,1,[\"label\"]],false],[0,\"\\n        \"],[3,\"action\",[[22,0,[]],[27,\"if\",[[22,1,[\"disabled\"]],[27,\"optional\",[\"noop\"],null],[27,\"queue\",[[27,\"action\",[[22,0,[]],\"closeDropdown\"],null],[27,\"action\",[[22,0,[]],[22,2,[]],[22,1,[]]],null]],null]],null]]],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/dropdown-button.hbs"
    }
  });
});