define("affinio/components/asset-group", ["exports", "affinio/utils/mutations", "affinio/utils/affinio", "affinio/utils/auto-suggest", "affinio/utils/get-with-default"], function (_exports, _mutations, _affinio, _autoSuggest, _getWithDefault) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Element
    classNames: ['asset-heading', 'asset-group'],
    // TODO remove asset-heading
    classNameBindings: ['model.open'],
    tagName: 'div',
    // Services
    user: Ember.inject.service(),
    tracker: Ember.inject.service(),
    modalManager: Ember.inject.service(),
    graph: Ember.inject.service(),
    // Properties
    selectionWatch: false,
    addingAsset: false,
    addingAssetCSV: false,
    // Computed Properties
    isTwitter: Ember.computed.equal('model.socialNetwork', 'Twitter'),
    twEndpoint: (0, _autoSuggest.twitterHandlesEndpoint)({
      inputKey: 'inputValue'
    }),
    actions: {
      createGroup: function createGroup() {
        var _this = this;
        if (!Ember.get(this, 'creatingGroup')) {
          var name = Ember.get(this, 'newAssetGroup');
          if (name) {
            var isCompany = Ember.get(this, 'isCompany');
            var isToolbox = (0, _getWithDefault.default)(this, 'isToolbox', false);
            // TODO ok this sucks. current backend requires `type` to be `owned` even though the concept of `owned` doesnt exist anymore
            // TODO migrate away from `type`
            var assetType = 'owned';
            var socialNetwork = Ember.get(this, 'socialNetwork');
            var entityId = isToolbox ? Ember.get(this, 'toolboxID') : isCompany ? Ember.get(this, 'user.company.id') : Ember.get(this, 'user.account.id');
            Ember.get(this, 'graph').mutate(_mutations.default.createAssetGroup(name, assetType, socialNetwork, entityId, isCompany)).then(function (assetGroup) {
              Ember.get(_this, 'tracker').track('created benchmark group', {
                id: Ember.get(assetGroup, 'id')
              });
              Ember.get(_this, "user.".concat(isCompany ? 'company' : 'account', ".assetGroups")).pushObject(assetGroup);
              Ember.set(_this, 'creatingGroup', false);
              Ember.set(_this, 'newAssetGroup', null);
            }).catch(function (err) {
              return Ember.get(_this, 'tracker').track('failed to add asset group', {
                error: JSON.stringify(err)
              });
            });
          } else {
            Ember.get(this, 'tracker').track('tried to make asset group without name');
            Ember.set(this, 'creatingGroup', false);
          }
        }
      },
      deleteGroup: function deleteGroup() {
        var _this2 = this;
        var id = Ember.get(this, 'model.id');
        // get(this, 'tracker').track('deleting asset group', { id });
        Ember.get(this, 'graph').mutate(_mutations.default.deleteAssetGroup(id)).then(function () {
          return Ember.get(_this2, 'model').destroyRecord();
        }).catch(window.console.log);
      },
      // Selection
      selectAll: function selectAll(value) {
        var _this3 = this;
        var group = Ember.get(this, 'model');
        Ember.get(group, 'assets').forEach(function (n) {
          _this3.toggleProperty('selectionWatch');
          Ember.set(n, 'checked', value);
        });
        Ember.get(this, 'tracker').track('select all assets in asset group', {
          name: Ember.get(group, 'name'),
          group: Ember.get(group, 'id'),
          selected: value
        });
      },
      singleUpdate: function singleUpdate(handleObj, group, index, val) {
        Ember.set(handleObj, 'checked', val);
        if (!val) {
          Ember.$("#all".concat(Ember.get(group, 'name').dasherize()).concat(index)).prop('checked', false);
        }
        this.toggleProperty('selectionWatch');
        Ember.get(this, 'tracker').track('select single asset in asset group', {
          group: JSON.stringify(group),
          id: Ember.get(group, 'id'),
          asset: handleObj.handle,
          selected: val
        });
      },
      // Add
      addAssetAutoSuggest: function addAssetAutoSuggest(asset) {
        var _this4 = this;
        if (!Ember.get(this, 'addingAsset')) {
          var isTwitter = Ember.get(this, 'isTwitter');
          var assetName = Ember.get(asset, 'label');
          var group = Ember.get(this, 'model');
          Ember.set(this, 'addingAsset', true);
          if (isTwitter) {
            assetName = assetName.replace('@', '').replace(/ /gi, '');
          }
          var assetID = Ember.get(asset, 'id');
          var groupAlreadyHasAsset = isTwitter ? Ember.get(group, 'assets').mapBy('name').includes(assetName) : Ember.get(group, 'assets').mapBy('id').includes(assetID);
          if (groupAlreadyHasAsset) {
            // tracker.track('tried to add asset that already exists in group', {
            //   group: get(group, 'id')
            // });
            Ember.set(this, 'addingAsset', false);
          } else {
            Ember.get(this, 'graph').mutate(_mutations.default.addAssetsToAssetGroup(Ember.get(group, 'id'), [{
              assetID: assetID,
              assetName: assetName
            }])).then(function () {
              return Ember.set(_this4, 'addingAsset', false);
            }).catch(window.console.log);
          }
        }
      },
      addAssetsFromCSV: function addAssetsFromCSV(_ref) {
        var _this5 = this;
        var fileInfo = _ref.fileInfo,
          data = _ref.data;
        if (!Ember.get(this, 'addingAsset')) {
          var group = Ember.get(this, 'model');
          var tracker = Ember.get(this, 'tracker');
          Ember.set(this, 'addingAsset', true);
          if (navigator.platform.toLowerCase().indexOf('win') > -1 || fileInfo.type === 'text/csv' || fileInfo.type === 'application/vnd.ms-excel') {
            var handles = (0, _affinio.csvParse)(data, {
              headers: false
            }).map(function (row) {
              return row[0];
            }).compact();
            if (handles.length) {
              // Clean handles
              handles = handles.map(function (n) {
                return n.replace('@', '').replace(/ /gi, '').toLowerCase();
              }).compact().uniq();

              // Check for duplicates already in this asset group
              var intersection = _.intersection(Ember.get(group, 'assets').mapBy('name'), handles);
              if (intersection.length === handles.length) {
                tracker.track('tried to add assets via CSV but all existed');
                Ember.set(this, 'addingAsset', false);
                return;
              } else if (intersection.length && intersection.length < handles.length) {
                handles = _.difference(handles, intersection);
              }

              // Save
              Ember.get(this, 'graph').mutate(_mutations.default.addAssetsToAssetGroup(Ember.get(group, 'id'), handles.map(function (n) {
                return {
                  assetName: n
                };
              }))).then(function () {
                return Ember.set(_this5, 'addingAsset', false);
              }).catch(window.console.log);
            } else {
              Ember.get(this, 'modalManager').openModal('modals/modal-text', {
                title: 'Error loading CSV file',
                reason: 'Couldn\'t find or parse usernames in column "handle"',
                fix: 'Ensure usernames are correct and in a column "handle"'
              });
              tracker.track('Targeted assets CSV upload parse error');
              Ember.set(this, 'addingAsset', false);
            }
          } else {
            Ember.get(this, 'modalManager').openModal('modals/modal-error', {
              title: 'Please select a CSV file',
              reason: 'This file doesn\'t appear to be a proper CSV',
              fix: 'Try uploading a proper CSV file.'
            });
            tracker.track('assets CSV upload invalid file type');
            Ember.set(this, 'addingAsset', false);
          }
        }
      },
      // Remove
      removeSelectedAssets: function removeSelectedAssets() {
        var _this6 = this;
        var group = Ember.get(this, 'model');
        var id = Ember.get(group, 'id');
        var toRemove = Ember.get(group, 'assets').filter(function (n) {
          return n.checked;
        });
        var index = Ember.get(this, 'index');
        if (toRemove && toRemove.length) {
          Ember.get(this, 'graph').mutate(_mutations.default.deleteAssetsFromGroupByName(id, toRemove.map(function (n) {
            return {
              assetName: Ember.get(n, 'name'),
              assetID: Ember.get(n, 'id')
            };
          }))).then(function () {
            // Clear the select all checkbox
            _this6.$("#all".concat(Ember.get(group, 'name').dasherize()).concat(index)).prop('checked', false);
            _this6.toggleProperty('selectionWatch');
          }).catch(window.console.log);
        }
      },
      removeAsset: function removeAsset(asset) {
        var group = Ember.get(this, 'model');
        var id = Ember.get(group, 'id');
        var toRemove = [asset];
        // let index = get(this, 'index');
        if (toRemove && toRemove.length) {
          Ember.get(this, 'graph').mutate(_mutations.default.deleteAssetsFromGroupByName(id, toRemove.map(function (n) {
            return {
              assetName: Ember.get(n, 'name'),
              assetID: Ember.get(n, 'id')
            };
          }))).then(function () {
            // Clear the select all checkbox
            // this.$(`#all${get(group, 'name').dasherize()}${index}`).prop('checked', false);
            // this.toggleProperty('selectionWatch');
          }).catch(window.console.log);
        }
      },
      // Save
      saveNameEdit: function saveNameEdit() {
        var _this7 = this;
        var id = Ember.get(this, 'model.id');
        var name = Ember.get(this, 'model.name');
        Ember.get(this, 'tracker').track('updating asset group name', {
          id: id
        });
        Ember.get(this, 'graph').mutate(_mutations.default.updateAssetGroupName(id, name)).then(function () {
          return Ember.get(_this7, 'model').toggleProperty('edit');
        }).catch(window.console.log);
      }
    }
  });
});