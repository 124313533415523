define("affinio/utils/regex", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.xmlTagRegex = _exports.whiteSpaceRegex = _exports.singleQuoteRegex = _exports.semicolonRegex = _exports.quotedContent = _exports.nonWordNonWhitespaceRegex = _exports.lineBreakRegex = _exports.lastFourDigits = _exports.inputOptimizerSpecialRegex = _exports.hrefRegex = _exports.doubleQuoteRegex = _exports.domainRegex = _exports.default = _exports.curlySingleQuoteRegex = _exports.curlyDoubleQuoteRegex = _exports.commaRegex = _exports.bracketRegex = _exports.badAliasCharacters = void 0;
  var commaRegex = _exports.commaRegex = /,/g;
  var curlyDoubleQuoteRegex = _exports.curlyDoubleQuoteRegex = /[\u201C\u201D]/g;
  var curlySingleQuoteRegex = _exports.curlySingleQuoteRegex = /[\u2018\u2019]/g;
  var domainRegex = _exports.domainRegex = /^(?:https?:\/\/)?(?:[^@/\n]+@)?(?:www\.)?([^:/\n]+)/ig;
  var doubleQuoteRegex = _exports.doubleQuoteRegex = /"/g;
  var singleQuoteRegex = _exports.singleQuoteRegex = /'/g;
  var hrefRegex = _exports.hrefRegex = /href=["|'](.*?)["|']/;
  var inputOptimizerSpecialRegex = _exports.inputOptimizerSpecialRegex = /[!%^&*()+|~=`\\{}[\]:";<>?,]/g;
  var lineBreakRegex = _exports.lineBreakRegex = /(\r\n|\n|\r)/gm;
  var nonWordNonWhitespaceRegex = _exports.nonWordNonWhitespaceRegex = /[^\w\s]*/gi;
  var semicolonRegex = _exports.semicolonRegex = /;/g;
  var xmlTagRegex = _exports.xmlTagRegex = /(<([^>]+)>)/ig;
  var whiteSpaceRegex = _exports.whiteSpaceRegex = /\s/g;
  var quotedContent = _exports.quotedContent = /"(.*?)"/g;
  var lastFourDigits = _exports.lastFourDigits = /\d{4}$/g;
  var badAliasCharacters = _exports.badAliasCharacters = /[,;{}()=\s]/g;
  var bracketRegex = _exports.bracketRegex = /\(([^)]+)\)/;
  var _default = _exports.default = {
    curlyDoubleQuoteRegex: curlyDoubleQuoteRegex,
    curlySingleQuoteRegex: curlySingleQuoteRegex,
    commaRegex: commaRegex,
    domainRegex: domainRegex,
    doubleQuoteRegex: doubleQuoteRegex,
    singleQuoteRegex: singleQuoteRegex,
    hrefRegex: hrefRegex,
    inputOptimizerSpecialRegex: inputOptimizerSpecialRegex,
    lineBreakRegex: lineBreakRegex,
    nonWordNonWhitespaceRegex: nonWordNonWhitespaceRegex,
    semicolonRegex: semicolonRegex,
    xmlTagRegex: xmlTagRegex,
    whiteSpaceRegex: whiteSpaceRegex,
    quotedContent: quotedContent,
    lastFourDigits: lastFourDigits,
    badAliasCharacters: badAliasCharacters,
    bracketRegex: bracketRegex
  };
});