define("affinio/utils/report-builder/twitter/url-share", ["exports", "affinio/utils/constants/string", "affinio/utils/report-builder/advanced-modules"], function (_exports, _string, _advancedModules) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    label: 'URL Share',
    naturalLanguageLabel: 'What links they circulate',
    value: 'url-share',
    description: 'Find people based on links they have tweeted or retweeted within the past 30 days.',
    stages: [{
      label: 'Attributes',
      heading: 'Input audience attributes:',
      description: 'You can include anyone who has shared any links from a particular domain, subdomain, or filter based on a specific URL (e.g. <a href="affinio.com/blog/2017/06/06/putting-the-people-back-in-marketing">blog post</a>)',
      modules: [{
        label: 'URL:',
        description: 'Filter an audience based on specific URLs that have tweeted. Input one URL at a time. If you enter multiple URLs and use the OR operator, a user will be included if they tweeted any of them',
        value: 'text-input',
        valueKey: 'requestedURL',
        placeholder: 'http://www.theverge.com',
        requiredFilters: ['contentQuery'],
        addAction: function addAction(inputController, model) {
          var existingContentQuery = Ember.get(model, 'filters.contentQuery');
          var existingCount = 0;
          var urlInputValue = Ember.get(inputController, 'requestedURL').replace(/http(s)?:\/\/(www\.)?/gi, '');
          if (!existingContentQuery) {
            Ember.set(model, 'filters.contentQuery', []);
          } else {
            existingCount = existingContentQuery.mapBy('length').reduce(function (a, b) {
              return a + b;
            }, 0);
          }
          var splitUrl = urlInputValue.split(',');
          var urls = [];
          splitUrl.forEach(function (url) {
            while (url.indexOf(' ') === 0) {
              url = url.substr(1);
            }
            if (url.length > 0) {
              urls.push(url);
            }
          });
          var newlyAddedCount = urls.map(function (url) {
            return "url:".concat(url).length;
          }).reduce(function (a, b) {
            return a + b;
          }, 0);
          if (newlyAddedCount + existingCount > 2048) {
            // TODO this is hacky, and requires having the modalManager on text input
            // Until we move all add actions to the report-builder component proper this is likely
            // the best/easiest way to go - JK Sept 2018
            Ember.get(inputController, 'modalManager').openModal('modals/modal-text', {
              title: 'Query too large',
              message: 'Our system is optimized to support queries of 2048 characters or fewer. Please remove some URLs',
              okText: 'Got it',
              okAction: true
            });
          } else {
            urls.forEach(function (url) {
              if (!Ember.get(model, 'filters.contentQuery').includes("url:".concat(url))) {
                Ember.get(model, 'filters.contentQuery').pushObject("url:".concat(url));
              }
            });
            Ember.set(inputController, 'requestedURL', '');
          }
        }
      }]
    }, {
      label: 'Options',
      heading: 'Adjust advanced report features:',
      description: 'Changing these options will not impact the makeup of your audience but can be used to fine tune the desired output of your report.',
      modules: [{
        label: 'Number of Clusters:',
        description: 'We auto-recommend a number based on estimated audience size, but you can adjust between 1-20 clusters. Select fewer clusters to break out high-level segments or more clusters to uncover niche segments. Small audiences are capped at 6 clusters (size 150-499).',
        value: 'number-input',
        min: 1,
        max: 20,
        valueKey: 'numberOfClusters',
        placeholder: 'Number of Clusters'
      }, {
        label: 'Track these Terms:',
        description: 'Track hashtags, mentions, and keywords that may fall outside of the top 20 terms we display by default. Only single terms without quotes are supported, and not phrases. Comma-separate between multiple terms.',
        value: 'text-input',
        valueKey: 'requestedTrackedTerm',
        placeholder: '#trip, @Nike',
        addAction: function addAction(inputController, model) {
          var existingTrackedTerms = Ember.get(model, 'filters.explicitWords');
          var trackedTermInputValue = Ember.get(inputController, 'requestedTrackedTerm');
          if (!existingTrackedTerms) {
            Ember.set(model, 'filters.explicitWords', []);
          }
          var terms = trackedTermInputValue.split(',').map(function (t) {
            while (t.indexOf(' ') === 0) {
              t = t.substr(1);
            }
            return t;
          });
          var cleanTerms = terms.filter(function (t) {
            return !(t.indexOf(' ') > 0);
          });
          if (cleanTerms.length) {
            cleanTerms.forEach(function (t) {
              var type;
              if (t.lastIndexOf('#', 0) === 0) {
                type = 'hashtag';
              } else if (t.lastIndexOf('@', 0) === 0) {
                type = 'mention';
              } else if (t.lastIndexOf('https:', 0) === 0 || t.lastIndexOf('http:', 0) === 0 || t.lastIndexOf('www.', 0) === 0 || t.lastIndexOf('bit.ly', 0) === 0 || t.lastIndexOf('t.co', 0) === 0 || t.indexOf('.com') > 0 || t.indexOf('.net') > 0 || t.indexOf('.org') > 0) {
                type = 'link';
              } else {
                type = 'keyword';
              }
              if (!Ember.get(model, 'filters.explicitWords').findBy('term', t)) {
                Ember.get(model, 'filters.explicitWords').pushObject({
                  term: t,
                  type: type
                });
              }
            });
            Ember.set(inputController, 'requestedTrackedTerm', '');
          } else {
            Ember.set(inputController, 'requestedTrackedTerm', '');
          }
        }
      }, {
        label: 'Interests\' Follower Range:',
        description: 'Only identify interests with a follower count that falls within a set range. This feature can help you find relevant micro-influencers. Setting a range will not impact the assignment of users to clusters.',
        value: 'min-max-input'
      }, _advancedModules.audienceSample, _advancedModules.metricDepth]
    }, {
      label: 'Set tracked interests',
      heading: 'Set tracked interests:',
      description: _string.default.reportBuilder.setBenchmarks,
      modules: [{
        label: null,
        value: 'benchmark-groups',
        placeholder: 'Tracked interests'
      }]
    }, {
      label: 'Confirm and Launch',
      heading: 'Confirm and launch:',
      description: 'Review summary and name report to launch.',
      modules: [{
        label: null,
        value: 'launch-input',
        placeholder: 'Report Name'
      }]
    }]
  };
});