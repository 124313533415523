define("affinio/templates/components/modals/modal-stream", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Q6xFu1Gw",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"form\"],[11,\"class\",\"modal\"],[9],[0,\"\\n  \"],[7,\"a\"],[11,\"class\",\"close-modal\"],[11,\"href\",\"#\"],[9],[0,\"×\"],[3,\"action\",[[22,0,[]],\"close\"]],[10],[0,\"\\n  \"],[7,\"h3\"],[11,\"class\",\"modal-sub-nav-header\"],[9],[1,[21,\"term\"],false],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"examples\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"twitter-stream\",null,[[\"tweets\",\"lazyLoad\"],[[23,[\"examples\"]],false]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"igImages\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"instagram-stream\",null,[[\"images\",\"lazyLoad\",\"term\"],[[23,[\"igImages\"]],false,[23,[\"term\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"backdrop\"],[11,\"role\",\"button\"],[9],[3,\"action\",[[22,0,[]],\"close\"]],[10],[0,\"\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/modals/modal-stream.hbs"
    }
  });
});