define("affinio/components/modals/modal-text", ["exports", "affinio/components/modals/modal-base"], function (_exports, _modalBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _modalBase.default.extend({
    okText: 'Ok',
    cancelText: 'Cancel',
    submitAction: Ember.computed('okAction', function () {
      if (Ember.get(this, 'okAction')) {
        return 'ok';
      } else {
        return 'close';
      }
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (Ember.get(this, 'okAction')) {
        this.$('.button.primary').focus();
      } else if (!Ember.get(this, 'noClose')) {
        this.$('.close-modal').focus();
      }
    }
  });
});