define("affinio/services/auth", ["exports", "affinio/utils/queries", "affinio/utils/mutations", "affinio/utils/affinio", "moment"], function (_exports, _queries, _mutations, _affinio, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * The time in ms to debounce the setup of expiry notification
   * @property {Number} DEBOUNCE_TIME
   * @private
   */
  var DEBOUNCE_TIME = 10000;

  /**
   * The amount of minutes before token expiration to notify user to extend
   * @property {Number} MINUTES_BEFORE_NOTIFICATION
   * @private
   */
  var MINUTES_BEFORE_NOTIFICATION = 5;

  /**
   * The Auth Service. This is our main entry point to authenticate users
   * @module Services
   * @class Auth
   */
  var _default = _exports.default = Ember.Service.extend({
    /**
     * A reference to the app service
     * @property {Services.App} app
     * @private
     */
    app: Ember.inject.service(),
    /**
     * A reference to the api service. Used for its tabid property
     * @property {Services.Api} api
     * @private
     */
    api: Ember.inject.service(),
    /**
     * A reference to the graph service
     * @property {Services.Graph} graph
     * @private
     */
    graph: Ember.inject.service(),
    /**
     * A reference to the localStorage service
     * @property {Services.LocalStorage} localStorage
     * @private
     */
    localStorage: Ember.inject.service(),
    /**
     * A reference to the modalManager service
     * @property {Services.ModalManager} modalManager
     * @private
     */
    modalManager: Ember.inject.service(),
    /**
     * A reference to the tracker service
     * @property {Services.Tracker} tracker
     * @private
     */
    tracker: Ember.inject.service(),
    /**
     * A reference to the user service
     * @property {Services.User} user
     * @private
     */
    user: Ember.inject.service(),
    /**
     * A reference to the store service. Used to empty ember data store
     * @property {Services.Store} store
     * @private
     */
    store: Ember.inject.service(),
    /**
     * A reference to the ember-notify service for toasts
     * @property {Services.Notify} notify
     * @private
     */
    notify: Ember.inject.service(),
    /**
     * A reference to the ember router service
     * @property {Services.Router} router
     * @private
     */
    router: Ember.inject.service(),
    taxos: Ember.inject.service(),
    /**
     * A reference to the promise used to login or validate the auth
     * @property {Promise} loginPromise
     * @private
     */
    loginPromise: null,
    /**
     * An Ember Transition defining where to go after the user has logged in
     * @property {Ember.Transition} loginTransition
     */
    loginTransition: null,
    /**
     * The current users auth state
     * @property {Boolean} loggedIn
     */
    loggedIn: false,
    /**
     * A reference to the msal library for ms authentication
     * @property msAuth
     * @private
     */
    msAuth: null,
    /**
     * An ember runloop event for popping up the expiry notification at a specific time
     * @property expireNotificationEvent
     * @private
     */
    expireNotificationEvent: null,
    /**
     * An ember runloop event for opening session expired modal
     * @property expiredModalEvent
     * @private
     */
    expiredModalEvent: null,
    /**
     * State of the extension notification's visibility
     * @property {Boolean} notificationVisible
     */
    notificaitonVisible: false,
    /**
     * Initializes the service & sets up Msal library as msAuth property
     * @method init
     * @private
     */
    init: function init() {
      this._super.apply(this, arguments);
      Ember.set(this, 'msAuth', new Msal.UserAgentApplication('56bb8ea0-f6d9-4fb1-84dd-fd68388c0eb5', null, null, {
        redirectUri: "".concat(window.location.origin, "/sign-in"),
        postLogoutRedirectUri: "".concat(window.location.origin, "/sign-in")
      }));
    },
    /**
     * Ember observer to update our auth state if another tab logs in
     * @event otherTabLogin
     * @private
     */
    otherTabLogin: Ember.observer('localStorage.tabAuthInfo', function () {
      var _this = this;
      var tabAuthInfo = Ember.get(this, 'localStorage.tabAuthInfo');
      if (tabAuthInfo) {
        // if our tab caused this, skip
        if (tabAuthInfo.tabId === Ember.get(this, 'api.tabid')) {
          return;
        } else {
          var loggedIn = Ember.get(this, 'loggedIn');
          if (loggedIn && tabAuthInfo.newUser) {
            this.reset(false, false);
            Ember.get(this, 'router').transitionTo('/');
          } else if (loggedIn && !tabAuthInfo.newUser) {
            this.closeModalAndNotification();
            this.setupExpiredTokenHandling();
          } else if (!loggedIn) {
            Ember.set(this, 'otherTabLoginHappening', true);
            (0, _affinio.maybeRunLater)(this, function () {
              return Ember.set(_this, 'otherTabLoginHappening', false);
            }, 10000);
            this.reset(false, false);
            Ember.get(this, 'router').transitionTo('/');
          }
        }
      }
    }),
    /**
     * Ember observer to update our auth state if another tab logs out
     * @event otherTabLogout
     * @private
     */
    otherTabLogout: Ember.observer('localStorage.tabLogoutInfo', function () {
      var tabLogoutInfo = Ember.get(this, 'localStorage.tabLogoutInfo');
      // console.log('otherTabLogout', window.Object.assign({}, tabLogoutInfo));
      if (Ember.get(this, 'loggedIn') && tabLogoutInfo) {
        // If our tab caused this, skip
        if (tabLogoutInfo.tabId === Ember.get(this, 'api.tabid')) {
          return;
        } else {
          this.affinioLogout(false);
        }
      }
    }),
    /**
     * Ember observer to open the modal if another tab is forced to do so early
     * @event otherTabModalEarly
     * @private
     */
    otherTabModalEarly: Ember.observer('localStorage.tabAuthEarly', function () {
      var tabAuthEarly = Ember.get(this, 'localStorage.tabAuthEarly');
      if (Ember.get(this, 'loggedIn') && tabAuthEarly && tabAuthEarly.tabId !== Ember.get(this, 'api.tabid')) {
        this.openModal();
      }
    }),
    /**
     * Used to log the user in to affinio using standard email/password
     * @method affinioLogin
     * @return {Promise} Resolves the results of this.postAuthSetup()
     * @param {String} email
     * @param {String} password
     * @param {Boolean} isNew is this the first time this session that the user is logging in
     */
    affinioLogin: function affinioLogin(email, password, isNew) {
      var _this2 = this;
      var loginPromise = Ember.get(this, 'api').post('/login', {
        data: JSON.stringify({
          email: email.toLowerCase(),
          password: password,
          userAgent: navigator.userAgent
        })
      }).then(function () {
        Ember.get(_this2, 'tracker').track('User successfully signed in', {
          user: email.toLowerCase()
        });
        return Ember.get(_this2, 'graph').query(_queries.default.me).then(function (response) {
          return _this2.postAuthSetup(Ember.get(response, 'account'), isNew);
        });
      }).catch(function (error) {
        var affinioErrorCode = Ember.get(error, 'payload.errors.0.affinioErrorCode');
        if (affinioErrorCode && (affinioErrorCode < 102 || affinioErrorCode > 108 && affinioErrorCode !== 116)) {
          Ember.get(_this2, 'modalManager').openModal('modals/modal-signin-failed', {
            cancelAction: function cancelAction() {
              if (!isNew) {
                Ember.run.scheduleOnce('afterRender', _this2, _this2.openModal);
              }
            },
            resetAction: function resetAction() {
              var notify = Ember.get(_this2, 'notify');
              notify.info('Resetting...');
              _this2.affinioResetPassword(email.toLowerCase()).then(function () {
                return notify.success('Password reset email sent');
              });
              if (!isNew) {
                Ember.run.scheduleOnce('afterRender', _this2, _this2.openModal);
              }
            },
            beforeCloseAction: function beforeCloseAction() {
              if (!isNew) {
                Ember.run.scheduleOnce('afterRender', _this2, _this2.openModal);
              }
            },
            resetText: 'Reset Password'
          });
        }
        Ember.get(_this2, 'tracker').track('Signin error', {
          user: email.toLowerCase(),
          error: JSON.stringify(error),
          affinioErrorCode: affinioErrorCode
        });
        throw error;
      });
      Ember.set(this, 'loginPromise', loginPromise);
      return loginPromise;
    },
    /**
     * Used to reset the app state before a user authenticates
     * @method reset
     * @private
     * @param {Boolean} clearAuth Should we clear the auth variables on localStorage?
     * @param {Boolean} explicitSignOut Should we tell other tabs to sign out too?
     * @param {Boolean} clearTransition Should we clear the saved transition?
     */
    reset: function reset() {
      var _this3 = this;
      var clearAuth = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      var explicitSignOut = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      var clearTransition = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
      if (clearAuth) {
        Ember.get(this, 'localStorage').clearAuth();
      }
      Ember.get(this, 'store').unloadAll(); // Clear the Ember Data store on next run loop
      Ember.run.next(this, function () {
        Ember.run.next(_this3, function () {
          Ember.get(_this3, 'loadMetricTaxonomies')();
        });
      });
      Ember.get(this, 'user').reset();
      Ember.get(this, 'taxos').reset();
      this.closeModalAndNotification();
      Ember.setProperties(this, {
        loggedIn: false,
        loginPromise: null
      });
      if (explicitSignOut) {
        Ember.set(this, 'localStorage.tabLogoutInfo', {
          tabid: Ember.get(this, 'api.tabid')
        });
        (0, _affinio.maybeRunLater)(this, function () {
          return Ember.set(_this3, 'localStorage.tabLogoutInfo', null);
        }, 5000);
      }
      if (clearTransition) {
        Ember.set(this, 'loginTransition', null);
      }
    },
    /**
     * Used to log the user out of Affinio
     * @method affinioLogout
     * @return {Promise} A promise that resolves after reset is called (and transition if set)
     * @param {Boolean} explicitSignOut Should we tell other tabs to sign out too?
     * @param {Boolean} transitionAfter should we transition to sign-in after?
     * @param {Boolean} clearTransition Should we clear the saved transition?
     */
    affinioLogout: function affinioLogout() {
      var _this4 = this;
      var explicitSignOut = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      var transitionAfter = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      var clearTransition = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
      return new Ember.RSVP.Promise(function (resolve) {
        _this4.reset(true, explicitSignOut, clearTransition);
        resolve();
      }).then(function () {
        if (transitionAfter) {
          return Ember.get(_this4, 'router').transitionTo('sign-in');
        }
      });
    },
    /**
     * Used to request a password reset for a given email.
     * @method affinioResetPassword
     * @param {String} email
     * @return {Promise} the api request to reset password
     */
    affinioResetPassword: function affinioResetPassword(email) {
      var _this5 = this;
      return Ember.get(this, 'api').request("requestPasswordResetEmail/".concat(email), {
        dataType: 'text'
      }).then(function () {
        return Ember.get(_this5, 'tracker').track('User requested password change', {
          user: email
        });
      }).catch(function (error) {
        Ember.get(_this5, 'tracker').track('User requested password change but failed', {
          error: JSON.stringify(error)
        });
        Ember.get(_this5, 'modalManager').openModal('modals/modal-error', {
          title: 'Password change error',
          reason: 'There was a problem resetting your password',
          fix: 'Make sure you are entering the correct Email address'
        });
      });
    },
    /**
     * Used to change a password for a user
     * @method affinioChangePassword
     * @param {String} id the user id who's password is changing
     * @param {String} email the user email who's password is changing
     * @param {String} password the password we are changing to
     * @return {Promise} the api request to change password
     */
    affinioChangePassword: function affinioChangePassword(id, email, password) {
      var _this6 = this;
      return Ember.get(this, 'graph').mutate(_mutations.default.updateAccountPassword(id, password)).then(function () {
        return Ember.get(_this6, 'tracker').track('PasswordChange', {
          user: email
        });
      });
    },
    /**
     * Used to check if the current user is authenticated. Checks auth header expiry before trying the token
     * @method checkAuthStatus
     * @return {Promise} the existing or new loginPromise (resovles to true/false from postAuthSetup)
     */
    checkAuthStatus: function checkAuthStatus() {
      var _this7 = this;
      return Ember.get(this, 'loginPromise') || Ember.set(this, 'loginPromise', new Ember.RSVP.Promise(function (resolve) {
        // Check if there is a token expiry date, if before now, auth:false
        var authExpiry = Ember.get(_this7, 'localStorage.affinioAuthExpiry');
        if (authExpiry) {
          if (new Date(parseInt(authExpiry) * 1000) < new Date()) {
            resolve(false);
          } else {
            // Check if there is a token and do the initial setup request
            var authToken = Ember.get(_this7, 'localStorage.affinioAuth');
            if (authToken) {
              resolve(Ember.get(_this7, 'graph').query(_queries.default.me, false).then(function (response) {
                return _this7.postAuthSetup(Ember.get(response, 'account'), true);
              }));
            } else {
              resolve(false);
            }
          }
        } else {
          resolve(false);
        }
      }));
    },
    /**
     * Used to extend the current user's token. If it fails it will open the sign-in modal
     * @method extendAuth
     * @return {Promise} the api request to refresh the token
     */
    extendAuth: function extendAuth() {
      var _this8 = this;
      return Ember.get(this, 'api').refreshToken().then(this.closeModalAndNotification.bind(this)).then(function () {
        // Update localStorage for getting other tabs signedIn
        Ember.set(_this8, 'localStorage.tabAuthInfo', {
          newUser: false,
          tabId: Ember.get(_this8, 'api.tabid')
        });
        _this8.clearTabAuthInfoDelayed();
      }).catch(function () {
        return _this8.openModal(true);
      });
    },
    /**
     * Used to open the extension notification (if the user is signed in and sign in modal isn't open)
     * @method openNotification
     * @private
     */
    openNotification: function openNotification() {
      if (Ember.get(this, 'loggedIn') && !Ember.get(this, 'modalVisible')) {
        Ember.get(this, 'tracker').track('User shown auth notification');
        Ember.set(this, 'notificaitonVisible', true);
      }
    },
    /**
     * Used to close the extension notification and sign in modal
     * @method closeModalAndNotification
     * @private
     */
    closeModalAndNotification: function closeModalAndNotification() {
      Ember.setProperties(this, {
        notificaitonVisible: false,
        modalVisible: false
      });
      if (Ember.get(this, 'modalManager.modal') === 'modals/modal-sign-in') {
        Ember.get(this, 'modalManager').closeModal();
      }
    },
    /**
     * Used to open the sign in modal and close the extension notification (if logged in)
     * @method openModal
     * @param {Boolean} early Should we notify other tabs that we opened early?
     */
    openModal: function openModal() {
      var _this9 = this;
      var early = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      if (Ember.get(this, 'loggedIn')) {
        if (early) {
          Ember.set(this, 'localStorage.tabAuthEarly', {
            tabId: Ember.get(this, 'api.tabid')
          });
          (0, _affinio.maybeRunLater)(this, function () {
            return Ember.set(_this9, 'localStorage.tabAuthEarly', null);
          }, 5000);
        }
        Ember.set(this, 'notificaitonVisible', false);
        Ember.set(this, 'modalVisible', true);
        Ember.get(this, 'modalManager').openModal('modals/modal-sign-in');
        Ember.get(this, 'tracker').track('User shown re authorize modal');
      }
    },
    /**
     * Used to clear localStorage.tabAuthInfo which is used by other tabs to login implicitly. This happens after a 5 second delay to give those tabs time to use it
     * @method clearTabAuthInfoDelayed
     */
    clearTabAuthInfoDelayed: function clearTabAuthInfoDelayed() {
      var _this10 = this;
      (0, _affinio.maybeRunLater)(this, function () {
        return Ember.set(_this10, 'localStorage.tabAuthInfo', null);
      }, 5000);
    },
    /**
     * Sets up App state and identifies the user after authentication
     * @method postAuthSetup
     * @private
     * @param {Models.Account} account the account of the authenticated user resolved from Q.me
     * @param {Boolean} isNew is this a new authentication? if so do initial setup stuff
     * @return {Boolean} Is the user authenticated and setup?
     */
    postAuthSetup: function postAuthSetup(account) {
      var isNew = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      var existingUser = Ember.get(this, 'user.account.id');
      // Check if you were previously logged in and now you are someone else
      if (Ember.get(this, 'loggedIn') && existingUser && existingUser !== Ember.get(account, 'id')) {
        // console.log('you were logged in, but now you are someone different');
        this.reset(false, false);
        return false;
      }

      // State properties
      Ember.setProperties(this, {
        loggedIn: true,
        'user.account': account,
        'localStorage.refreshLock': null
      });

      // Identify user to trackers
      // TODO update woopra identification
      Ember.get(this, 'tracker').identify({
        email: Ember.get(account, 'email'),
        company: Ember.get(account, 'company.name'),
        signupCompany: null,
        signupCountry: null,
        organization: null,
        firstName: Ember.get(account, 'first_name'),
        lastName: Ember.get(account, 'last_name'),
        name: Ember.get(account, 'name'),
        trial: Ember.get(account, 'trial'),
        sfid: Ember.get(account, 'company.sfid'),
        featureTwitAds: Ember.get(account, 'company.hasAdvertising') ? true : false,
        featureMonitoring: Ember.get(account, 'company.hasMonitoring') ? true : false,
        featureImageAnalysis: Ember.get(account, 'company.hasImageAnalysis') ? true : false,
        featureDupReportCreation: Ember.get(account, 'company.hasDupAccess') ? true : false,
        featureApi: Ember.get(account, 'company.hasAPIAccess') ? true : false
      });
      Ember.get(this, 'tracker').track('App Loaded');
      if (isNew) {
        // Load reports, and notifications
        Ember.get(this, 'user').loadUserData();
      }
      Ember.get(this, 'app').loadOutageInfo();

      // Close token extension notification / expired modal
      this.closeModalAndNotification();
      return true;
    },
    /**
     * Used to debounce notification/modal setup for the loggedIn user to extend/sign in again
     * @method setupExpiredTokenHandling
     */
    setupExpiredTokenHandling: function setupExpiredTokenHandling() {
      if (Ember.get(this, 'loggedIn')) {
        Ember.run.debounce(this, this._setupExpireNotification, DEBOUNCE_TIME, false);
        Ember.run.debounce(this, this._setupExpiredModal, DEBOUNCE_TIME, false);
      }
    },
    /**
     * Used to setup the ember run loop to open notification MINUTES_BEFORE_NOTIFICATION before auth token expiry
     * @method _setupExpireNotification
     * @private
     */
    _setupExpireNotification: function _setupExpireNotification() {
      if (Ember.get(this, 'notificaitonVisible')) {
        return;
      }
      var existingEvent = Ember.get(this, 'expireNotificationEvent');
      if (existingEvent) {
        Ember.run.cancel(existingEvent);
      }
      var now = (0, _moment.default)();
      var notifyAt = (0, _moment.default)(Ember.get(this, 'localStorage.affinioAuthExpiry') * 1000).subtract(MINUTES_BEFORE_NOTIFICATION, 'minutes');
      var diff = notifyAt.diff(now);
      Ember.set(this, 'expireNotificationEvent', (0, _affinio.maybeRunLater)(this, this.openNotification, diff));
    },
    /**
     * Used to setup the ember run loop to open modal at auth token expiry time
     * @method _setupExpiredModal
     * @private
     */
    _setupExpiredModal: function _setupExpiredModal() {
      if (Ember.get(this, 'modalVisible')) {
        return;
      }
      var existingEvent = Ember.get(this, 'expiredModalEvent');
      if (existingEvent) {
        Ember.run.cancel(existingEvent);
      }
      var now = (0, _moment.default)();
      var notifyAt = (0, _moment.default)(Ember.get(this, 'localStorage.affinioAuthExpiry') * 1000);
      var diff = notifyAt.diff(now);
      Ember.set(this, 'expiredModalEvent', (0, _affinio.maybeRunLater)(this, this.openModal, diff));
    },
    /**
     * Used to log the user in to affinio using microsoft popup flow
     * @method msLogin
     * @return {Promise} ms login flow that resolves to finishMsLogin results (Resolves the results of this.postAuthSetup())
     * @param {String} email unused, left in for same method signature as affinioLogin
     * @param {String} password unused, left in for same method signature as affinioLogin
     * @param {Boolean} isNew is this the first time this session that the user is logging in
     */
    msLogin: function msLogin(email, password) {
      var _this11 = this;
      var isNew = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
      var msAuth = Ember.get(this, 'msAuth');
      return msAuth.loginPopup(['user.read']).then(function (idToken) {
        return msAuth.acquireTokenSilent(['user.read']).then(function (accessToken) {
          return _this11.finishMsLogin(idToken, accessToken, isNew);
        }, function () {
          return msAuth.acquireTokenPopup(['user.read']).then(function (accessToken) {
            return _this11.finishMsLogin(idToken, accessToken, isNew);
          });
        });
      });
    },
    /**
     * Used to finish the microsoft authentication flow by setting up a special auth token that will be exchanged for a standard affinio JWT
     * @method finishMsLogin
     * @private
     * @return {Promise} api initial request promise. Resolves the results of this.postAuthSetup()
     * @param {String} idToken microsoft identifcation token
     * @param {*} accessToken microsoft access token
     * @param {*} isNew is this the first time this session that the user is logging in
     */
    finishMsLogin: function finishMsLogin(idToken, accessToken) {
      var _this12 = this;
      var isNew = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
      Ember.get(this, 'tracker').track(isNew ? 'Azure Login' : 'Azure reauth');
      Ember.setProperties(this, {
        'localStorage.affinioAuth': JSON.stringify({
          'id_token': idToken,
          'access_token': accessToken
        }),
        'localStorage.lastRefreshTime': new Date().getTime()
      });
      return Ember.get(this, 'graph').query(_queries.default.me).then(function (response) {
        return _this12.postAuthSetup(Ember.get(response, 'account'), isNew);
      });
    }
  });
});