define("affinio/utils/report-builder/advanced-modules", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.metricDepth = _exports.audienceSample = void 0;
  var audienceSample = _exports.audienceSample = {
    label: 'Audience Sample',
    description: 'Increase the size of the audience sample for which we will fetch full results when performing a query.',
    value: 'slider-input',
    min: 50000,
    max: 200000,
    step: 25000,
    valueKey: 'sampleSize',
    accountRequirements: ['hasToolboxAccess'],
    showDivider: true
  };
  var metricDepth = _exports.metricDepth = {
    label: 'Metric Depth',
    description: 'Increase the number of top values we store for a given metric for analysis availability. The number displayed will still remain at 20.',
    value: 'number-input',
    min: 100,
    max: 1000,
    valueKey: 'numberOfInstances',
    accountRequirements: ['hasToolboxAccess'],
    showDivider: true,
    placeholder: 'Metric Depth'
  };
});