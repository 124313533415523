define("affinio/models/account", ["exports", "ember-data", "affinio/utils/data/array-attr"], function (_exports, _emberData, _arrayAttr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;
  var Model = _emberData.default.Model;
  var _default = _exports.default = Model.extend({
    firstName: attr('string'),
    lastName: attr('string'),
    email: attr('string'),
    graphKey: attr('string'),
    dateCreated: attr('date'),
    dateUpdated: attr('date'),
    isActive: attr(),
    notes: attr(),
    twitterCredentials: attr(),
    twitterReports: hasMany('reports/twitter'),
    pinterestReports: hasMany('reports/pinterest'),
    instagramReports: hasMany('reports/instagram'),
    reports: Ember.computed('twitterReports.[]', 'pinterestReports.[]', 'instagramReports.[]', function () {
      return [].concat(_toConsumableArray(Ember.get(this, 'twitterReports').toArray()), _toConsumableArray(Ember.get(this, 'pinterestReports').toArray()), _toConsumableArray(Ember.get(this, 'instagramReports').toArray())).filter(function (r) {
        return Ember.get(r, 'state') !== 'failed';
      });
    }),
    groups: hasMany('group'),
    folders: hasMany('folder'),
    company: belongsTo('company'),
    permissions: attr({
      defaultValue: function defaultValue() {
        return Ember.A();
      }
    }),
    assetGroups: hasMany('asset-group'),
    preferences: attr(),
    thirdPartyIntegrations: _arrayAttr.default,
    name: Ember.computed('firstName', 'lastName', function () {
      var firstName = Ember.get(this, 'firstName');
      var lastName = Ember.get(this, 'lastName');
      return "".concat(firstName, " ").concat(lastName);
    }),
    isAdmin: Ember.computed('permissions', function () {
      return Ember.get(this, 'permissions').includes('Company Admin');
    }),
    canTranslate: Ember.computed('permissions', function () {
      return Ember.get(this, 'permissions').includes('Translation');
    }),
    // isAffinio: false,
    isAffinio: Ember.computed('permissions', function () {
      return Ember.get(this, 'permissions').includes('Super User');
    }),
    isInAffinioCompany: Ember.computed.equal('company.name', 'Affinio Inc'),
    isMicrosoftTrialUser: Ember.computed('company.name', function () {
      return Ember.get(this, 'company.name').startsWith('Microsoft Trial Company');
    }),
    hasCreateReport: Ember.computed('permissions', function () {
      return Ember.get(this, 'permissions').includes('Create Report');
    }),
    hasCreateDuplicateReport: Ember.computed('permissions', function () {
      return Ember.get(this, 'permissions').includes('Create Duplicate Report');
    }),
    hasCreateMonitoredReport: Ember.computed('permissions', function () {
      return Ember.get(this, 'permissions').includes('Create Monitored Report');
    }),
    hasTwitterAdvertising: Ember.computed('permissions', function () {
      return Ember.get(this, 'permissions').includes('Twitter Advertising');
    }),
    hasApiAccess: Ember.computed('permissions', function () {
      return Ember.get(this, 'permissions').includes('Affinio API Access');
    }),
    isViewerSeat: Ember.computed('permissions', function () {
      return Ember.get(this, 'permissions').includes('Viewer Seat');
    }),
    isFullSeat: Ember.computed('permissions', function () {
      return !Ember.get(this, 'permissions').includes('Viewer Seat');
    }),
    hasTranslation: Ember.computed('permissions', function () {
      return Ember.get(this, 'permissions').includes('Translation');
    }),
    role: Ember.computed('permissions', function () {
      if (Ember.get(this, 'permissions').includes('Viewer Seat')) {
        return 'Viewer';
      } else if (Ember.get(this, 'permissions').includes('Company Admin')) {
        return 'Admin';
      } else {
        return 'Regular';
      }
    }),
    hasToolboxAccess: Ember.computed('permissions', function () {
      return Ember.get(this, 'permissions').includes('Toolbox Access');
    })
  });
});