define("affinio/initializers/konami", ["exports", "affinio/config/environment", "affinio/utils/queries", "affinio/utils/mutations", "affinio/utils/taxonomies", "affinio/utils/regex"], function (_exports, _environment, _queries, _mutations, _taxonomies, _regex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(application) {
    var enableAffinioMode = function enableAffinioMode() {
      // Setup global App
      window.App = {
        store: application.__container__.lookup('service:store'),
        graph: application.__container__.lookup('service:graph'),
        schemaEditor: application.__container__.lookup('service:schemaEditor'),
        dataCacher: application.__container__.lookup('service:dataCacher'),
        user: application.__container__.lookup('service:user'),
        path: application.__container__.lookup('service:path'),
        preferences: application.__container__.lookup('service:preferences'),
        Q: _queries.default,
        M: _mutations.default,
        T: _taxonomies.default,
        regex: _regex.default
      };
      window.console.log('Affinio mode activated', window.App);
    };
    if (!['production', 'test'].includes(_environment.default.environment)) {
      enableAffinioMode();
    } else {
      Ember.$(document).on('keyup', Konami.code(enableAffinioMode));
    }
  }
  var _default = _exports.default = {
    name: 'konami',
    initialize: initialize
  };
});