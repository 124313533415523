define("affinio/components/metric-instance", ["exports", "affinio/utils/get-with-default"], function (_exports, _getWithDefault) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Element
    tagName: 'a',
    classNames: ['metric-instance', 'clearfix'],
    classNameBindings: ['compareViewIsRelativized:relativizeCompareViewByAll', 'greaterOrLesser'],
    attributeBindings: ['titleAttr:title'],
    // Services
    path: Ember.inject.service(),
    preferences: Ember.inject.service(),
    user: Ember.inject.service(),
    tracker: Ember.inject.service(),
    modalManager: Ember.inject.service(),
    graph: Ember.inject.service(),
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;
      if (!Ember.get(this, 'records')) {
        Ember.run.scheduleOnce('afterRender', function () {
          return Ember.set(_this, 'records', Ember.A());
        });
      }
    },
    compareViewIsRelativized: Ember.computed.equal('relativizeCompareViewBy', 'all'),
    titleAttr: Ember.computed('globalSaturations.length', 'gap', function () {
      var r = '';
      if (Ember.get(this, 'globalSaturations.length')) {
        var gap = Ember.get(this, 'gap');
        r = "This cluster is ".concat(Math.abs(gap.diff).toFixed(2), "% ").concat(gap.diff > 0 ? 'more' : 'less', " likely to exhibit this behavior than the baseline.");
      }
      return r;
    }),
    // We inherit the majority of our calculations from a single 'instance' property in metric-visualiation.hbs
    // Alias them for efficiency
    label: Ember.computed.alias('instance.label'),
    value: Ember.computed.alias('instance.value'),
    saturations: Ember.computed.alias('instance.saturations'),
    examples: Ember.computed.alias('instance.ids'),
    url: Ember.computed.alias('instance.url'),
    title: Ember.computed.alias('instance.title'),
    delta: Ember.computed.alias('instance.delta'),
    previousSnapshot: Ember.computed.alias('instance.previousSnapshot'),
    nomenclature: Ember.computed.alias('instance.nomenclature'),
    report: Ember.computed.alias('model.cluster.report'),
    metricTitle: Ember.computed('metric', function () {
      var definitions = Ember.get(this, 'cluster.report.metricDefinitions');
      var metric = Ember.get(this, 'metric');
      return definitions.findBy('label', metric) ? Ember.get(definitions.findBy('label', metric), 'title') : metric;
    }),
    // And, some are aliased from the metric itself (aggregate/reduced figures, mostly)
    globalMax: Ember.computed.alias('metric.globalMax'),
    isMouseDown: false,
    computedDelta: Ember.computed('delta', 'normalized', 'value', function () {
      return (0, _getWithDefault.default)(this, 'delta.valueDelta', 0).toFixed(0);
    }),
    heldCluster: Ember.computed('_heldCluster', 'preferences.heldCluster', function () {
      return Ember.get(this, '_heldCluster') || Ember.get(this, 'preferences.heldCluster') || null;
    }),
    hideDelta: Ember.computed('normalized', 'preferences.heldCluster', function () {
      if (Ember.get(this, 'preferences.heldCluster')) {
        return true;
      }
      if (Ember.get(this, 'previousSnapshot.isNew')) {
        return true;
      }
      if (Ember.get(this, 'metric.baselineEstablished') && Ember.get(this, 'normalized') === 'global-comparison') {
        return true;
      }
      var normalized = Ember.get(this, 'normalized');
      return ['timeline', 'niche-ranked'].includes(normalized);
    }),
    audienceSaturation: Ember.computed('saturations', function () {
      var audienceSaturation;
      if (Ember.get(this, 'saturations')) {
        var foundSaturation = Ember.get(this, 'saturations').find(function (sat) {
          return Ember.get(sat, 'filters.type') === 'audience' && (Ember.get(sat, 'filters.value') === 'saturation' || Ember.get(sat, 'filters.value') === 'audience');
        });
        var foundOldStyleSaturation = Ember.get(this, 'saturations').find(function (sat) {
          return Ember.get(sat, 'filterType') === 'audience' && (Ember.get(sat, 'filterValue') === 'saturation' || Ember.get(sat, 'filterValue') === 'audience');
        });
        if (foundSaturation) {
          audienceSaturation = foundSaturation;
          audienceSaturation = (0, _getWithDefault.default)(audienceSaturation, 'value', 0);
        } else if (foundOldStyleSaturation) {
          // filterValue and filterType both === 'audience' is old twitter convention
          audienceSaturation = foundOldStyleSaturation;
          audienceSaturation = audienceSaturation.value || 0;
        }
        return audienceSaturation;
      }
    }),
    // audienceSaturation

    globalSaturations: Ember.computed('saturations', 'compareViewIsRelativized', 'instances', function () {
      var _this2 = this;
      var globalSats = Ember.get(this, 'saturations') || [];
      return globalSats.reject(function (sat) {
        // we get rid of audience saturation from the saturations bars, as it's implied by the main bar in the UI.
        return Ember.get(sat, 'filterType') === 'audience' && Ember.get(sat, 'filterValue') === 'saturation' || Ember.get(sat, 'filters.type') === 'audience' && Ember.get(sat, 'filters.value') === 'audience' // old twitter convention to ignore
        ;
      }).map(function (sat) {
        var widthOrLeftArgument = 'left: 50%';
        if (!Ember.get(sat, 'style')) {
          Ember.set(sat, 'style', Ember.String.htmlSafe(// eslint-disable-line ember/no-side-effects
          "".concat(widthOrLeftArgument, ";\n          background-color: ").concat(Ember.get(Ember.get(_this2, 'saturationsLegend').findBy('label', Ember.get(sat, 'filters.paired')) || {}, 'color'), ";\n          color: ").concat(Ember.get(Ember.get(_this2, 'saturationsLegend').findBy('label', Ember.get(sat, 'filters.paired')) || {}, 'color'), ";\n        ")));
        }
        return sat;
      });
    }),
    gap: Ember.computed('globalSaturations', 'value', function () {
      var saturations = (0, _getWithDefault.default)(this, 'saturations', []);
      var audienceSaturation = saturations.find(function (sat) {
        return Ember.get(sat, 'filterType') === 'audience' && Ember.get(sat, 'filterValue') === 'saturation' // custom data convention ( I _think_ )
        || Ember.get(sat, 'filters.type') === 'audience' && Ember.get(sat, 'filters.value') === 'audience' // twitter convention
        ;
      });
      var instances = Ember.get(this, 'instances');

      // if we want to use a gap, we have to assume a single baseline.
      //  Hence the .objectAt(0)
      //  In the event that we start adding multiple baselines, we should remove the concept of a gap (underline) altogether.
      var baselineSaturation = saturations.without(audienceSaturation).objectAt(0);
      var diff = Ember.get(audienceSaturation, 'value') - Ember.get(baselineSaturation, 'value');

      // We create satMaxDelta in order to have a ceiling for our scale's domain (% diff between a given instance's saturation dots)
      // Basically, the shortest possible line between dots will be 0. But what will the maximum be?
      // TODO: de-side-effect
      instances.forEach(function (instance) {
        if (Ember.get(instance, 'saturations') && Ember.get(instance, 'saturations.length')) {
          var tuple = Ember.get(instance, 'saturations').mapBy('value');
          // TODO REDO: undo all of this!!!
          Ember.set(instance, 'saturationDiff', Math.abs(tuple[0] - tuple[1])); // eslint-disable-line ember/no-side-effects
        }
      });
      var position = diff > 0 ? 'left: 50%' : 'right: 50%';
      var width = Math.abs(diff) / Ember.get(this, 'maxBaselineDiff') * 50;
      // console.log('so leftSpot gonna be', leftSpot, get(this, 'percentage'), get(get(this, 'globalSaturations')[0], 'relativeValue'));

      return {
        diff: diff,
        style: Ember.String.htmlSafe("width: ".concat(width, "%;\n        ").concat(position, ";\n        border-color: ").concat(diff > 0 ? 'rgba(45,222,152,0.5)' : 'rgba(239,69,110,0.5)'))
      };
    }),
    greaterOrLesser: Ember.computed('instance.diffFromBaseline', function () {
      return Ember.get(this, 'instance.diffFromBaseline') > 0 ? 'greater' : 'lesser';
    }),
    // create a 3-part array that represents the 0 mark, 50% mark, and 100% mark of a chart
    saturationDomain: Ember.computed('instance', 'maxBaselineDiff', function () {
      var maxBaselineDiffAmongMetric = Ember.get(this, 'maxBaselineDiff');
      var baselineSaturation = Ember.get(this, 'instance.baselineSaturation');
      return [baselineSaturation - maxBaselineDiffAmongMetric, baselineSaturation, baselineSaturation + maxBaselineDiffAmongMetric];
    }),
    // Determines the width of the bar
    percentage: Ember.computed('value', 'max', 'normalized', 'singleBar', 'audienceSaturation', 'globalMax', 'compareViewIsRelativized', 'instances.[]', function () {
      // console.log('uhhh globsat', get(this, 'globalSaturations'), get(this, 'globalSaturations').objectAt(0));
      if (Ember.get(this, 'singleBar')) {
        return 100;
      } else {
        var value = Ember.get(this, 'value');
        var max = Ember.get(this, 'max');
        if (Ember.get(this, 'metric.baselineEstablished') && Ember.get(this, 'normalized') === 'global-comparison' && Ember.get(this, 'compareViewIsRelativized')) {
          var relativizedScale = d3.scale.linear().domain(Ember.get(this, 'saturationDomain')).range([0, 50, 100]);
          return relativizedScale(Ember.get(this, 'audienceSaturation'));
        } else {
          return (value / max).toFixed(2) * 100;
        }
      }
    }),
    barStyle: Ember.computed('percentage', 'compareViewIsRelativized', 'normalized', function () {
      if (Ember.get(this, 'metric.baselineEstablished') && Ember.get(this, 'normalized') === 'global-comparison' && Ember.get(this, 'compareViewIsRelativized')) {
        return Ember.String.htmlSafe("left: ".concat(Ember.get(this, 'percentage'), "%;"));
      } else {
        return Ember.String.htmlSafe("width: ".concat(Ember.get(this, 'percentage'), "%;"));
      }
    }),
    // To maintain original cluster color when report has removed clusters,
    // we assign the original cluster index +1 to the colorOrder Property.
    // For each cluster, the colorOrder value is assigned to the data-color-order attribute to apply correct color.
    colorOrder: Ember.computed('model.report.clusters.@each.{isClusterRemoved,mergedInto}', 'records.length', function () {
      var _this3 = this;
      if (Ember.get(this, 'records') && Ember.get(this, 'model.report.clusters')) {
        Ember.get(this, 'model.report.clusters').forEach(function (cluster) {
          var record = Ember.get(_this3, 'records').find(function (r) {
            return r.cluster === cluster.suffix;
          });
          if (record) {
            Ember.set(record, 'colorOrder', Ember.get(cluster, 'index') + 1); // eslint-disable-line ember/no-side-effects
          }
        });
        return Ember.get(this, 'records').map(function (r) {
          return r.colorOrder;
        });
      } else {
        return [];
      }
    }),
    timesMoreLikely: Ember.computed('processedRecords.[]', 'preferences.heldCluster', 'normalized', 'path.currentClusterSuffix', function () {
      // Determine which thing we're comparing: a mouse-held cluster or a niche-ranked one
      if (Ember.get(this, 'processedRecords.length')) {
        var heldCluster = Ember.get(this, 'heldCluster');
        if (!heldCluster) {
          if (Ember.get(this, 'preferences.heldCluster')) {
            heldCluster = Ember.get(this, 'preferences.heldCluster');
          } else if (Ember.get(this, 'normalized') === 'niche-ranked') {
            heldCluster = Ember.get(this, 'path.currentClusterSuffix');
          }
        }
        if (heldCluster) {
          // console.log('yoooooo', get(this, 'label'), get(this, 'processedRecords').findBy('cluster', heldCluster).value);
          var record = Ember.get(this, 'processedRecords').findBy('cluster', heldCluster);
          if (record.timesMoreLikely.isInfinite) {
            return 'infinity';
          } else {
            return record.timesMoreLikely.value;
          }
        } else {
          return null;
        }
      }
    }),
    // timesMoreLikely

    isInfinitelyMoreLikely: Ember.computed.equal('timesMoreLikely', 'infinity'),
    processedRecords: Ember.computed('normalized', 'records', 'records.length', function () {
      var _this4 = this;
      var records = Ember.get(this, 'records');
      if (records) {
        // We size records by their normalizedScore if in Normalized view mode. Otherwise, use their value.
        var metricValue;
        if (Ember.get(this, 'normalized') === 'cluster-bias' || Ember.get(this, 'normalized') === 'niche-ranked') {
          metricValue = 'normalizedScore';
        } else if (Ember.get(this, 'normalized') === 'metric-normalized') {
          metricValue = 'metricNormalizedScore';
        } else {
          metricValue = 'value';
        }

        // total, so that cluster widths might be relative compared to it.
        var total = records.reduce(function (alpha, beta) {
          return alpha + beta[metricValue];
        }, 0);
        var normalizedTotal = records.reduce(function (alpha, beta) {
          return alpha + beta.normalizedScore;
        }, 0);
        var metricNormalizedTotal = records.reduce(function (alpha, beta) {
          return alpha + beta.metricNormalizedScore;
        }, 0);
        var rollingOffset = 0; // rolling offset property; how far we transition the bar left when niching on a given record
        var rollingRawOffset = 0; // how far we transition the bar left when niching, when not normalized (bar chart, global comparison)
        var rollingMetricNormalizedOffset = 0;
        return records.map(function (record, i) {
          var normalizedPercentage = record.normalizedScore / normalizedTotal * 100;
          var metricNormalizedPercentage = record.metricNormalizedScore / metricNormalizedTotal * 100;
          var processedRecord = {
            cluster: record.cluster,
            value: record.value,
            normalizedPercentage: normalizedPercentage,
            metricNormalizedPercentage: metricNormalizedPercentage,
            normalizedOffset: rollingOffset,
            metricNormalizedOffset: rollingMetricNormalizedOffset,
            rawOffset: rollingRawOffset,
            timesMoreLikely: record.timesMoreLikely,
            colorOrder: Ember.get(_this4, 'colorOrder')[i],
            style: Ember.String.htmlSafe("width: ".concat((record[metricValue] / total * 100).toFixed(4), "%;"))
          };
          rollingOffset = rollingOffset + normalizedPercentage; // update rolling property
          rollingMetricNormalizedOffset = rollingMetricNormalizedOffset + metricNormalizedPercentage; // update rolling property
          rollingRawOffset = rollingRawOffset + record.value / total * 100; // update rolling property
          return processedRecord;
        }).sortBy('colorOrder');
      } else {
        return [];
      }
    }),
    // processedRecords

    unclickable: Ember.computed('examples.[]', 'instance', function () {
      // TODO: probably get rid of the concept of unclickable altogether, with new focus modal.
      return false;
      //   return !get(this, 'diveIntoAction') && !get(this, 'examples.length');
    }),
    // unclickable
    mouseEnter: function mouseEnter() {
      var hoverAction = Ember.get(this, 'hoverAction');
      if (hoverAction && typeof hoverAction === 'function') {
        hoverAction(Ember.get(this, 'instance'));
      }
    },
    mouseLeave: function mouseLeave() {
      var hoverAction = Ember.get(this, 'hoverAction');
      if (hoverAction && typeof hoverAction === 'function') {
        hoverAction(null);
      }
    },
    actions: {
      diveIntoTerm: function diveIntoTerm() {
        if (!Ember.get(this, 'unclickable')) {
          // console.log('clicky', get(this, 'instance'), get(this, 'metric'));

          var informedInstance = Ember.get(this, 'instance');
          // let informedInstance = get(this, 'model.cluster.report.recordsArray').filterBy('metric', get(this, 'metric')).findBy('label', get(this, 'instance.label'));
          // console.log('inf inst', informedInstance, get(informedInstance, 'value'), get(this, 'model.cluster.report.numberOfMembers.response.value'));
          Ember.set(informedInstance, 'saturation', Ember.get(this, 'model.cluster.isAggregate') ? Ember.get(informedInstance, 'value') / Ember.get(this, 'model.cluster.report.numberOfMembers.response.value') * 100 : Ember.get(informedInstance, 'value') / Ember.get(this, 'model.cluster.numberOfMembers.response.value') * 100);
          Ember.set(informedInstance, 'records', Ember.get(this, 'records'));
          Ember.set(informedInstance, 'timesMoreLikely', {
            value: Ember.get(this, 'model.cluster.isAggregate') ? null : Ember.get(Ember.get(informedInstance, 'records').findBy('cluster', Ember.get(this, 'model.cluster.suffix')), 'timesMoreLikely.value')
          });
          Ember.set(informedInstance, 'records', Ember.get(this, 'records'));
          Ember.set(informedInstance, 'icon', '<svg class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><g class="nc-icon-wrapper"><path fill="#335262" d="M44,5H20c-0.553,0-1,0.447-1,1v4c0,0.553,0.447,1,1,1h24c0.553,0,1-0.447,1-1V6C45,5.447,44.553,5,44,5z"></path> <path fill="#335262" d="M44,21H20c-0.553,0-1,0.447-1,1v4c0,0.553,0.447,1,1,1h24c0.553,0,1-0.447,1-1v-4C45,21.447,44.553,21,44,21 z"></path> <path fill="#335262" d="M44,37H20c-0.553,0-1,0.447-1,1v4c0,0.553,0.447,1,1,1h24c0.553,0,1-0.447,1-1v-4C45,37.447,44.553,37,44,37 z"></path> <circle fill="#72C472" cx="8" cy="8" r="5"></circle> <circle fill="#72C472" cx="8" cy="24" r="5"></circle> <circle fill="#72C472" cx="8" cy="40" r="5"></circle></g></svg>');
          var trackedMetrics = ['trackedHashtags', 'trackedKeywords', 'trackedMentions'];
          if (trackedMetrics.includes(Ember.get(this, 'metric.label')) && (informedInstance.records.length === 0 || informedInstance.value === 0)) {
            return; // focus modal breaks in this case. Indication of term didn't score, but want to show the user that
          }
          Ember.get(this, 'modalManager').openModal('modals/modal-focus', {
            label: Ember.get(this, 'instance.label'),
            networkID: Ember.get(this, 'instance.networkID'),
            metric: Ember.get(this, 'metric.label'),
            cluster: Ember.get(this, 'model.cluster'),
            defaultView: 'examples'
          });
        } // if examples
      } // diveIntoTerm
    } // actions
  });
});