define("affinio/templates/components/modules/engagement-timeline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "uDxaQevq",
    "block": "{\"symbols\":[],\"statements\":[[7,\"header\"],[11,\"class\",\"clearfix\"],[9],[0,\"\\n  \"],[7,\"h4\"],[9],[0,\"Engagement Timeline\"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[4,\"if\",[[27,\"or\",[[23,[\"sortedEngagements\"]],[23,[\"sortedEngagementsCache\"]]],null]],null,{\"statements\":[[0,\"  \"],[1,[27,\"highlight-linechart\",null,[[\"records\",\"highlightedValues\",\"minValues\",\"maxValues\",\"activeRange\"],[[27,\"or\",[[23,[\"sortedEngagements\"]],[23,[\"sortedEngagementsCache\"]]],null],[23,[\"highlightedEngagementTimes\"]],[23,[\"minEngagementTimes\"]],[23,[\"maxEngagementTimes\"]],[23,[\"preferences\",\"activeEngagement\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/modules/engagement-timeline.hbs"
    }
  });
});