define("affinio/components/toolbox/toolbox-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['toolbox-display'],
    tagName: 'div',
    actions: {
      setCurrentlyOpenDrawer: function setCurrentlyOpenDrawer(drawer) {
        Ember.set(this, 'currentlyOpenDrawer', drawer);
      }
    }
  });
});