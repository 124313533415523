define("affinio/routes/settings/index", ["exports", "affinio/mixins/auth-check"], function (_exports, _authCheck) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_authCheck.default, {
    redirect: function redirect(model, transition) {
      if (transition.targetName === 'settings.index') {
        this.transitionTo('settings.preferences');
      }
    }
  });
});