define("affinio/routes/admin", ["exports", "affinio/mixins/auth-check", "affinio/utils/mutations"], function (_exports, _authCheck, _mutations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_authCheck.default, {
    authOnly: true,
    user: Ember.inject.service(),
    graph: Ember.inject.service(),
    tracker: Ember.inject.service(),
    notify: Ember.inject.service('notify'),
    modalManager: Ember.inject.service(),
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      Ember.set(this.controllerFor('admin'), 'user', Ember.get(this, 'user'));
    },
    redirect: function redirect() {
      if (!Ember.get(this, 'user.account.isAdmin')) {
        this.transitionTo('reports');
      }
    },
    actions: {
      showDeleteGroupModal: function showDeleteGroupModal(group) {
        var willTransition = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
        var name = Ember.get(group, 'name');
        Ember.set(this, 'groupToDelete', group);
        Ember.set(this, 'transition', willTransition);
        Ember.set(this, 'company', Ember.get(group, 'company'));
        Ember.get(this, 'modalManager').openModal('modals/modal-boolean', {
          question: "Do you want to delete ".concat(name),
          okText: 'Delete',
          okAction: Ember.get(this, 'actions.deleteGroup').bind(this)
        });
      },
      deleteGroup: function deleteGroup() {
        var _this = this;
        var group = Ember.get(this, 'groupToDelete');
        var company = Ember.get(this, 'company');
        var transition = Ember.get(this, 'transition');
        var notify = Ember.get(this, 'notify');
        var tracker = Ember.get(this, 'tracker');
        var graph = Ember.get(this, 'graph');
        var id = Ember.get(group, 'id');
        var name = Ember.get(group, 'name');
        graph.mutate(_mutations.default.deleteGroup(id)).then(function () {
          Ember.get(company, 'groups').removeObject(group);
          tracker.track('Deleted Group');
          notify.success("Deleted ".concat(name));
          if (transition) {
            _this.transitionTo('admin.groups');
          }
        }).catch(function (err) {
          Ember.get(_this, 'notify').error(err.payload.errors[0].message);
        });
      },
      showToggleAccountActiveModal: function showToggleAccountActiveModal(account, state) {
        var name = "".concat(Ember.get(account, 'firstName'), " ").concat(Ember.get(account, 'lastName'));
        Ember.set(this, 'accountToToggle', account);
        Ember.set(this, 'state', state);
        Ember.get(this, 'modalManager').openModal('modals/modal-boolean', {
          question: "Do you want to set ".concat(name, " to ").concat(state ? 'Active' : 'Inactive'),
          okText: 'Ok',
          okAction: Ember.get(this, 'actions.toggleAccountActive').bind(this)
        });
      },
      toggleAccountActive: function toggleAccountActive() {
        var account = Ember.get(this, 'accountToToggle');
        var state = Ember.get(this, 'state');
        var notify = Ember.get(this, 'notify');
        var tracker = Ember.get(this, 'tracker');
        var graph = Ember.get(this, 'graph');
        var id = Ember.get(account, 'id');
        var name = "".concat(Ember.get(account, 'firstName'), " ").concat(Ember.get(account, 'lastName'));
        graph.mutate(_mutations.default.toggleAccountActive(id, state)).then(function () {
          tracker.track("Toggled User ".concat(state ? 'Active' : 'Inactive'));
          notify.success("Toggled ".concat(name, " ").concat(state ? 'Active' : 'Inactive'));
        }).catch(function (err) {
          notify.error(err.payload.errors[0].message);
        });
      },
      showToggleCompanyActiveModal: function showToggleCompanyActiveModal(company, state) {
        var name = Ember.get(company, 'name');
        Ember.set(this, 'companyToToggle', company);
        Ember.set(this, 'state', state);
        Ember.get(this, 'modalManager').openModal('modals/modal-boolean', {
          question: "Do you want to set ".concat(name, " to ").concat(state ? 'Active' : 'Inactive'),
          okText: 'Ok',
          okAction: Ember.get(this, 'actions.toggleCompanyActive').bind(this)
        });
      },
      toggleCompanyActive: function toggleCompanyActive() {
        var company = Ember.get(this, 'companyToToggle');
        var state = Ember.get(this, 'state');
        var notify = Ember.get(this, 'notify');
        var tracker = Ember.get(this, 'tracker');
        var graph = Ember.get(this, 'graph');
        var id = Ember.get(company, 'id');
        var name = Ember.get(company, 'name');
        graph.mutate(_mutations.default.toggleCompanyActive(id, state)).then(function () {
          tracker.track("Toggled Company ".concat(state ? 'Active' : 'Inactive'));
          notify.success("Toggled ".concat(name, " ").concat(state ? 'Active' : 'Inactive'));
        }).catch(function (err) {
          notify.error(err.payload.errors[0].message);
        });
      }
    }
  });
});