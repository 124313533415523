define("affinio/components/graph-cluster-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    path: Ember.inject.service(),
    classNameBindings: ['spot', 'recordID'],
    attributeBindings: ['lurkerWidthStyle:style'],
    recordSpot: Ember.computed('record', 'metric', 'record.interestAffinity', 'max.value', function () {
      return Ember.get(Ember.get(this, 'record'), Ember.get(this, 'metric'));
    }),
    value: Ember.computed('metric', 'record', 'record.snapshot', 'recordSpot', 'recordSpot.value', function () {
      return Ember.get(this, 'recordSpot.value') || 0;
    }),
    lurkerWidthStyle: Ember.computed('value', 'source', 'record', 'record.snapshot', 'metric', function () {
      if (Ember.get(this, 'metric') === 'lurkers') {
        // this width is setting the parent tag for the cluster bar not the child black bar
        return Ember.String.htmlSafe('width:100%');
      } else {
        return Ember.String.htmlSafe("width:".concat(Ember.get(this, 'width'), "%;"));
      } // if lurkers
    }),
    // lurkerWidthStyle

    recordID: Ember.computed('record', function () {
      return Ember.get(this, 'record.id');
    }),
    // recordID
    spot: Ember.computed('source', 'recordID', function () {
      return "spot_".concat(Ember.get(this, 'source').mapBy('id').indexOf(Ember.get(this, 'recordID')));
    }),
    // spot
    width: Ember.computed('value', 'source', 'metric', 'record', 'record.snapshot', 'max.value', function () {
      if (Ember.get(this, 'metric') === 'lurkers') {
        // console.log('has max?', get(this, 'max'), get(this, 'value'));
        return Ember.get(this, 'value');
      } else if (Ember.get(this, 'metric') === 'interestAffinity') {
        return (Ember.get(this, 'value') / this.max.value * 100).toFixed(2) > 100 ? 100 : (Ember.get(this, 'value') / this.max.value * 100).toFixed(2);
      } else {
        return (Ember.get(this, 'value') / this.max.value * 100).toFixed(2);
      }
    }),
    // width

    recordMetric: Ember.computed('metric', 'record', 'record.snapshot', function () {
      // console.log('hah', get(this, 'metric'), get(this, 'record'), get(this, 'record').get(get(this, 'metric')));
      return Ember.get(this, "record.".concat(Ember.get(this, 'metric')));
    }),
    // recordMetric

    quality: Ember.computed(function () {
      if (Ember.get(this, 'metric') === 'influencerAffinity') {
        if (Ember.get(this, 'value') <= 12.5) {
          return 'Low Quality';
        } else if (Ember.get(this, 'value') <= 25) {
          return 'Medium Quality';
        } else if (Ember.get(this, 'value') <= 37.5) {
          return 'High Quality';
        } else {
          return 'Very High Quality';
        }
      } // if affinity
    }),
    style: Ember.computed('width', 'value', function () {
      // console.log('style');
      if (Ember.get(this, 'metric') === 'lurkers') {
        if (Ember.get(this, 'color')) {
          return Ember.String.htmlSafe("width:".concat(Ember.get(this, 'width'), "%; background-color:").concat(Ember.get(this, 'color'), ";"));
        } else {
          return Ember.String.htmlSafe("width:".concat(Ember.get(this, 'width'), "%;"));
        }
      } else {
        if (Ember.get(this, 'color')) {
          return Ember.String.htmlSafe("width:100%; background-color:".concat(Ember.get(this, 'color'), ";"));
        } else {
          return Ember.String.htmlSafe('width:100%');
        }
      }
    })
  });
});