define("affinio/components/old/animation-effect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    fadeIn: false,
    fadeOut: false,
    slideIn: false,
    popIn: false,
    fadeInTime: 300,
    fadeOutTime: 500,
    slideInTime: 300,
    popInTime: 200,
    didInsertElement: function didInsertElement() {
      var fadeIn = Ember.get(this, 'fadeIn');
      var fadeOut = Ember.get(this, 'fadeOut');
      var slideIn = Ember.get(this, 'slideIn');
      var popIn = Ember.get(this, 'popIn');
      if (fadeIn || fadeOut) {
        self.$().hide().fadeIn(fadeOut ? Ember.get(this, 'fadeOutTime') : Ember.get(this, 'fadeInTime'));
      } else if (slideIn) {
        self.$().hide().slideDown(Ember.get(this, 'slideInTime'));
      } else if (popIn) {
        self.$().css({
          'position': 'relative',
          'opacity': 0,
          'top': '8px'
        }).animate({
          'opacity': 1,
          'top': '0px'
        }, Ember.get(this, 'popInTime'));
      }
    },
    willDestroyElement: function willDestroyElement() {
      var fadeOut = Ember.get(this, 'fadeOut');
      if (fadeOut) {
        var time = Ember.get(this, 'fadeOutTime');
        var clone = self.$().clone();
        self.$().parent().prepend(clone);
        clone.fadeOut(time).next(function () {
          clone.remove();
        });
      }
    }
  });
});