define("affinio/mixins/zendesk-auth", ["exports", "affinio/utils/affinio"], function (_exports, _affinio) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    api: Ember.inject.service(),
    localStorage: Ember.inject.service(),
    afterModel: function afterModel() {
      var _this = this;
      return Ember.get(this, 'api').post('/zendesk', {
        data: {}
      }).then(function (response) {
        // console.log('window', window.location);
        var isZendeskRedirect = (0, _affinio.getParameterByName)('isZendeskRedirect', window.location.href) === 'true';
        // console.log('isZendeskRedirect', isZendeskRedirect);
        Ember.set(_this, 'localStorage.zendeskAuth', response.zendesk);
        // console.log('localStorage.zendeskAuth', get(this, 'localStorage.zendeskAuth'));
        if (isZendeskRedirect) {
          var zendeskReturnURL = (0, _affinio.getParameterByName)('return_to', window.location.href);
          // console.log('zendeskReturnUrl', zendeskReturnURL);
          if (Ember.get(_this, 'localStorage.zendeskAuth') && zendeskReturnURL) {
            var returnToParam = zendeskReturnURL ? "&return_to=".concat(zendeskReturnURL) : '';
            window.location = "https://affinio.zendesk.com/access/jwt?jwt=".concat(Ember.get(_this, 'localStorage.zendeskAuth')).concat(returnToParam);
          }
        }
      });
    }
  });
});