define("affinio/utils/constants/visualizations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.redGreenScale = _exports.default = void 0;
  var redGreenScale = _exports.redGreenScale = d3.scale.linear().domain([0, 50, 100]).range(['#c00', '#eee', '#4DB930']);
  var _default = _exports.default = {
    redGreenScale: redGreenScale
  };
});