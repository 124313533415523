define("affinio/templates/components/affinio-table-cell/taxonomy-selector-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "sCnKAXd1",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"customFeatures\"]]],null,{\"statements\":[[4,\"if\",[[23,[\"row\",\"canEdit\"]]],null,{\"statements\":[[4,\"link-to\",[\"aton.taxonomy\",[27,\"query-params\",null,[[\"taxonomyID\"],[[23,[\"row\",\"id\"]]]]]],[[\"title\"],[\"Edit\"]],{\"statements\":[[0,\"      \"],[1,[27,\"font-awesome\",[\"pencil\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"link-to\",[\"aton.taxonomy\",[27,\"query-params\",null,[[\"duplicate\",\"taxonomyID\"],[true,[23,[\"row\",\"id\"]]]]]],[[\"title\"],[\"Duplicate\"]],{\"statements\":[[0,\"    \"],[1,[27,\"font-awesome\",[\"files-o\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"row\",\"canDelete\"]]],null,{\"statements\":[[0,\"  \"],[7,\"a\"],[11,\"role\",\"button\"],[11,\"title\",\"Delete\"],[9],[0,\"\\n    \"],[1,[27,\"font-awesome\",[\"trash\"],null],false],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],[23,[\"column\",\"deleteAction\"]],[23,[\"row\",\"schema\"]]]],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/affinio-table-cell/taxonomy-selector-actions.hbs"
    }
  });
});