define("affinio/components/trait-chart", ["exports", "affinio/utils/get-with-default"], function (_exports, _getWithDefault) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['trait-chart'],
    tagName: 'article',
    path: Ember.inject.service(),
    tracker: Ember.inject.service(),
    didReceiveAttrs: function didReceiveAttrs() {
      Ember.set(this, '_selectedTraitType', (0, _getWithDefault.default)(this, 'traitTypes', []).findBy('value', Ember.get(this, 'selectedTraitType')));
    },
    instances: Ember.computed('_instances.[]', function () {
      var instances = Ember.get(this, '_instances');
      var sliceSize = Ember.get(this, 'sliceSize') || 10;
      if (instances && instances.length) {
        // some FB metrics will show 0-values. We don't wanna show 'em, I reckon.
        var slicedInstances = instances.slice(0, sliceSize).filter(function (instance) {
          return instance.value !== 0;
        });
        return slicedInstances;
      } else {
        return null;
      }
    }),
    _selectedTraitType: null,
    selectedTraitLabel: Ember.computed('selectedTraitType', function () {
      var selectedTraitType = Ember.get(this, 'selectedTraitType');
      if (selectedTraitType) {
        return Ember.get(this, 'traitTypes').findBy('value', selectedTraitType).label;
      }
    }),
    traitScores: Ember.computed.mapBy('instances', 'traitScore'),
    timesMoreLikelyThanBaselines: Ember.computed.mapBy('instances', 'timesMoreLikelyThanBaseline'),
    maxTraitScore: Ember.computed.max('traitScores'),
    maxTimesMoreLikelyThanBaseline: Ember.computed.max('timesMoreLikelyThanBaselines'),
    actions: {
      changedSummaryView: function changedSummaryView() {
        Ember.get(this, 'tracker').track('traits summary view changed', {
          changedTo: Ember.get(this, 'selectedSummaryView'),
          tab: "".concat(Ember.get(this, 'path.currentCategory'), "/").concat(Ember.get(this, 'path.currentSubcategory'))
        });
      },
      changeTraitType: function changeTraitType(type) {
        Ember.set(this, '_selectedTraitType', type);
        Ember.set(this, 'selectedTraitType', type.value);
        Ember.get(this, 'tracker').track('traits summary type changed', {
          changedTo: Ember.get(this, 'selectedTraitType'),
          tab: "".concat(Ember.get(this, 'path.currentCategory'), "/").concat(Ember.get(this, 'path.currentSubcategory'))
        });
      },
      changedBaselineOption: function changedBaselineOption() {
        Ember.get(this, 'tracker').track('Baselining: traits view vs changed', {
          changedTo: Ember.get(this, 'selectedBaselineOption')
        });
      }
    }
  });
});