define("affinio/components/modules/member-locations", ["exports", "affinio/mixins/affinio-module"], function (_exports, _affinioModule) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_affinioModule.default, {
    // Element
    classNames: ['member-locations'],
    classNameBindings: ['socialNetwork'],
    // Services
    path: Ember.inject.service(),
    store: Ember.inject.service(),
    tracker: Ember.inject.service(),
    user: Ember.inject.service()
  });
});