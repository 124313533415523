define("affinio/controllers/reports/index", ["exports", "affinio/objects/object-promise-proxy"], function (_exports, _objectPromiseProxy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  var _default = _exports.default = Ember.Controller.extend({
    queryParams: {
      groupParam: 'group',
      folderParam: 'folder',
      companyParam: 'company'
    },
    groupParam: null,
    folderParam: null,
    companyParam: false,
    app: Ember.inject.service(),
    api: Ember.inject.service(),
    user: Ember.inject.service(),
    graph: Ember.inject.service(),
    // preferences: service(),
    modalManager: Ember.inject.service(),
    screen: Ember.inject.service(),
    tracker: Ember.inject.service(),
    sorter: 'dateUpdated',
    // default sorting column for reports. Possible values: dateUpdated, creator.name, membersCount, numberOfClusters, name
    sortAscending: true,
    manualFilterInput: '',
    // used to click an option from the possibleKeys array

    // handle absolute-positioned search tips box to push down report list an appropriate amount.
    setSearchTipsPadding: Ember.observer('screen.width', 'selectedEntity.reports.[]', 'showSearchTips', function () {
      var _this = this;
      Ember.run.scheduleOnce('afterRender', this, function () {
        if (Ember.get(_this, 'showSearchTips')) {
          Ember.set(_this, 'searchTipsPadding', Ember.String.htmlSafe("padding-bottom: ".concat(Ember.$('.search-tips').height() + 40, "px")));
        } else {
          Ember.set(_this, 'searchTipsPadding', Ember.String.htmlSafe('padding-bottom: 20px'));
        }
      }); // afterRender
    }),
    // You access as either user.account, user.company, or user.group[n]
    // Certain reports show up for certain parents.
    selectedCompany: Ember.computed('companyParam', function () {
      return Ember.get(this, 'companyParam') ? Ember.get(this, 'user.company') : null;
    }),
    selectedGroup: Ember.computed('groupParam', function () {
      return Ember.get(this, 'groupParam') ? Ember.get(this, 'user.groups').findBy('id', Ember.get(this, 'groupParam')) : null;
    }),
    selectedAccessor: Ember.computed.or('selectedCompany', 'selectedGroup', 'user'),
    // reports: computed('user.reports.[]', 'folderParam', function() {
    //   console.log('reports computing', get(this, 'user.reports'), get(this, 'folderParam'));
    //   // If there's a folderParam, hit up a promise to grab all reports therein.
    //   // Otherwise, read the reports from the user service.
    //   // This reports array will be passed through:
    //   // 1. filteredReports, to handle searching
    //   // 2. networkFilteredReports, to handle filtering social networks from the index dropdown.
    //   if (get(this, 'folderParam')) {
    //     let promise = get(this, 'graph').query(Q.folderReports(get(this, 'folderParam'))).then((response) => {
    //       console.log('!');
    //       console.log('ahhh', response, get(response, 'folder.reports').toArray());
    //       return get(response, 'folder.reports');
    //     }).catch((e1,e2,e3) => {
    //       console.log('you done goofed', e1, e2, e3);
    //     });
    //     return ObjectPromiseProxy.create({ promise });
    //   }
    //   else {
    //     return get(this, 'user.reports');
    //   }
    //   // let reports = [
    //   //   ...get(this, 'store').peekAll('reports/twitter'),
    //   //   ...get(this, 'store').peekAll('reports/pinterest')
    //   // ];
    //   // console.log('reports', reports);
    //   // return reports;
    //   // if (get(this, 'folderParam')) {
    //   //   let promise = get(this, 'graph').query(Q.folderReports(get(this, 'folderParam'))).then((response) => {
    //   //     console.log('!');
    //   //     console.log('ahhh', response, get(response, 'folder.reports').toArray());
    //   //     return get(response, 'folder.reports').toArray(); // toArray or its a promise from the hasMany
    //   //   }).catch((e1,e2,e3) => {
    //   //     console.log('you done goofed', e1, e2, e3);
    //   //   });
    //   //   return ObjectPromiseProxy.create({ promise });
    //   // }
    //   // else {
    //   //   return get(this, 'selectedAccessor').get('reports');
    //   // }

    // }),

    selectedEntity: Ember.computed.or('selectedFolder', 'selectedAccessor'),
    selectedEntityType: Ember.computed('groupParam', 'companyParam', 'folderParam', function () {
      var groupIndex = Ember.get(this, 'groupParam');
      var folderIndex = Ember.get(this, 'folderParam');

      // TODO: this nested ternary is neat but hard to grok at first glance; refactor -PR, May 2017
      var entityType = folderIndex ? 'folder' : Ember.get(this, 'companyParam') ? 'company' : groupIndex ? 'group' : 'account';
      return entityType;
    }),
    isArchive: Ember.computed.equal('selectedEntityType', 'archive'),
    possibleKeys: Ember.computed('selectedEntity.reports.[]', function () {
      var reports = Ember.get(this, 'selectedEntity.reports');
      // console.log('CALC', reports);
      var possibleKeys = Ember.A([{
        'label': 'keywords',
        'value': ['bio_keywords']
      }, {
        'label': 'location',
        'value': ['bio_location']
      }, {
        'label': 'followers of',
        'value': ['followers_of']
      }, {
        'label': 'content',
        'value': ['content_query']
      }, {
        'label': 'tracked terms',
        'value': ['explicitWords']
      }, {
        'label': 'tracked interests groups',
        'value': ['owned_accounts', 'targeted_accounts']
      }]).map(function (obj) {
        return Ember.Object.create(obj);
      });
      possibleKeys.setEach('found', false);
      if (reports && Ember.isArray(reports) && reports.length) {
        reports.mapBy('filters').map(function (n) {
          if (n) {
            Ember.Object.keys(n).forEach(function (key) {
              if (Ember.isArray(n[key]) && !n[key].length) {
                // console.log('we have no length', n, key);
                delete n[key];
              }
            });
          }
          return n;
        }).forEach(function (obj) {
          if (obj) {
            Ember.Object.keys(obj).forEach(function (key) {
              var foundKey = possibleKeys.find(function (obj) {
                return obj.value.indexOf(key) > -1;
              });
              if (foundKey && !foundKey.found) {
                Ember.set(foundKey, 'found', true); // eslint-disable-line ember/no-side-effects

                var example;
                if (typeof obj[key] === 'string') {
                  example = obj[key].replace(/"/g, '');
                } else if (Ember.isArray(obj[key]) && obj[key][0] && typeof obj[key][0] === 'string') {
                  example = obj[key][0].replace(/"/g, '');
                } else if (Ember.isArray(obj[key]) && obj[key][0] && obj[key][0].term) {
                  example = obj[key][0].term.replace(/"/g, '');
                }

                // Sometimes we store arrays in a dash-divided string. Only show the first of these in an example.
                if (example && example.indexOf('-') > -1) {
                  example = example.split('-')[0];
                }
                // Sometimes we store arrays in a comma-divided string. Only show the first of these in an example.
                if (example && example.indexOf(',') > -1) {
                  example = example.split(',')[0];
                }

                // For content reports, we store the filters in "(keyword OR keyword2 AND keyword3)" format;
                // Parse this to just give us an example of a single keyword
                if (Ember.get(foundKey, 'label') === 'content') {
                  example = example.replace(/\(|\)/g, '').split('OR')[0].split('AND')[0].trim();
                }
                Ember.set(foundKey, 'example', example); // eslint-disable-line ember/no-side-effects
              }
            }); // O.keys.forEach
          }
        }); // reports.mapBy('filters').forEach
      }
      return possibleKeys;
    }),
    // possibleKeys
    // TODO: add reports in folder to search
    // entityFolderReports: computed(),
    // TODO: add together folderReports and reports
    // queryReports: computed('reports.[]', ''),
    updateFilteredAssets: function updateFilteredAssets(reports, query) {
      // console.log('updateFilteredAssets', { reports, query });
      if (query) {
        Ember.set(this, 'search', true);
        // console.log('updateFilteredAssets, query true');
        Ember.set(this, 'showFolders', false);
        Ember.set(this, 'filteredReports', reports);
      } else {
        Ember.set(this, 'search', false);
        // console.log('updateFilteredAssets, query false');
        Ember.set(this, 'showFolders', true);
        var _reports = Ember.get(this, 'reports');
        // TODO does this need to be objpromiseproxy?
        if (_reports instanceof _objectPromiseProxy.default) {
          if (_reports.isFulfilled) {
            Ember.set(this, 'filteredReports', _reports.content);
          } else {
            // TODO ?
            Ember.set(this, 'filteredReports', []);
          }
        } else {
          Ember.set(this, 'filteredReports', _reports);
        }
      }
    },
    expandSearchTips: function expandSearchTips() {
      Ember.set(this, 'showSearchTips', true);
    },
    // expandSearchTips
    hideSearchTips: function hideSearchTips() {
      Ember.set(this, 'showSearchTips', false);
    },
    // expandSearchTips

    networkFilteredReports: Ember.computed('filteredReports.[]', 'network', 'sorter', 'sortAscending', function () {
      var network = Ember.get(this, 'network').toLowerCase();
      var filteredReports = Ember.get(this, 'filteredReports');

      // TODO obj promise proxy
      if (filteredReports instanceof _objectPromiseProxy.default) {
        if (filteredReports.isFulfilled) {
          filteredReports = Ember.get(filteredReports, 'content');
        }
      }

      // console.log('filtrep?', filteredReports);
      if (network !== 'all networks') {
        filteredReports = filteredReports.filter(function (report) {
          var reportNetwork = Ember.get(report, 'socialNetwork');
          return network === (reportNetwork && reportNetwork.toLowerCase());
        });
      } // if not all networks

      if (filteredReports.length) {
        filteredReports = filteredReports.sortBy(Ember.get(this, 'sorter'));
        if (Ember.get(this, 'sortAscending')) {
          filteredReports = filteredReports.reverse();
        } // if ascending

        return filteredReports;
      } else {
        return [];
      }
    }),
    addReportToFolder: function addReportToFolder(folder, report) {
      return this.store.createRecord('folder-report-association', {
        folder: folder,
        report: report
      }).save();
    },
    dropThing: function dropThing(targetEntity, targetType, _ref) {
      var _this2 = this;
      var id = _ref.id,
        type = _ref.type;
      var isArchive = Ember.get(this, 'isArchive');
      if (isArchive && type === 'report' && targetType === 'account') {
        return Ember.get(this, 'store').findRecord(type, id).then(function (model) {
          return model.unArchive();
        });
      } else if (targetType === 'archive') {
        if (isArchive || type === 'folder') {
          return;
        }
        return Ember.get(this, 'store').findRecord(type, id).then(function (model) {
          return Ember.get(model, 'creator.id') === Ember.get(_this2, 'user.account.id') && model.archive();
        });
      }
      return this.dropShare(targetEntity, targetType, {
        id: id,
        type: type
      });
    },
    dropShare: function dropShare(targetEntity, targetType, _ref2) {
      var _this3 = this;
      var id = _ref2.id,
        type = _ref2.type;
      Ember.get(targetEntity, "".concat(type, "Associations")).then(function () {
        if (typeof targetEntity.then === 'function') {
          targetEntity.then(function (n) {
            Ember.get(_this3, 'store').findRecord(type, id).then(function (model) {
              Ember.get(_this3, 'modalManager').openModal('modal-drop-share', {
                entity: n,
                entityType: targetType,
                model: model,
                type: type
              });
            });
          });
        } else {
          Ember.get(_this3, 'store').findRecord(type, id).then(function (model) {
            Ember.get(_this3, 'modalManager').openModal('modal-drop-share', {
              entity: targetEntity,
              entityType: targetType,
              model: model,
              type: type
            });
          });
        }
      });
    },
    actions: {
      clearFolderQP: function clearFolderQP() {
        Ember.set(this, 'folderParam', null);
      },
      showNewFolderModal: function showNewFolderModal() {
        var _this4 = this;
        var tracker = Ember.get(this, 'tracker');
        // TODO: make it so folders can't be named "_archive"
        // see account model requestArchive for why
        Ember.get(this, 'modalManager').openModal('modal-new-folder', {
          okAction: function okAction(name, type, group) {
            _this4.store.createRecord('folder', {
              name: name,
              owner: Ember.get(_this4, 'user.account')
            }).save().then(function (folder) {
              folder.hasMany('accountAssociations').reload().then(function (donkeys) {
                if (type !== 'account') {
                  return donkeys.find(function (n) {
                    return n.belongsTo('account').id() === Ember.get(_this4, 'user.account.id');
                  }).destroyRecord();
                } else {
                  return Ember.RSVP.resolve();
                }
              }).then(function () {
                switch (type) {
                  case 'company':
                  case 'group':
                    Ember.get(_this4, 'store').createRecord("".concat(type, "-folder-association"), _defineProperty({
                      sharer: Ember.get(_this4, 'user.account'),
                      canEdit: true,
                      folder: folder
                    }, type, type === 'company' ? Ember.get(_this4, 'user.account.company') : Ember.get(group, 'model'))).save().then(function () {
                      tracker.track("folder created on ".concat(type), {
                        folder: Ember.get(folder, 'id'),
                        user: Ember.get(_this4, 'user.email'),
                        group: group ? Ember.get(group, 'model.id') : null
                      });
                    }).catch(function (reason) {
                      tracker.track("folder not created on ".concat(type), {
                        folder: Ember.get(folder, 'id'),
                        user: Ember.get(_this4, 'user.email'),
                        group: group ? Ember.get(group, 'model.id') : null,
                        error: JSON.stringify(reason)
                      });
                      throw reason;
                    });
                    break;
                }
              }); // destroy account folder association if saving to company/group then
            }, function (error) {
              tracker.track("folder not created on ".concat(type), {
                folder: name,
                user: Ember.get(_this4, 'user.email'),
                group: group ? Ember.get(group, 'model.id') : null,
                error: JSON.stringify(error)
              });
              Ember.get(_this4, 'modalManager').openModal('modal-error', {
                title: 'Folder not created',
                reason: "There already exists a folder named ".concat(name),
                fix: 'Make a new folder with a unique name'
              });
            }); // save folder then
          },
          reports: Ember.get(this, 'user.reports').sortBy('dateUpdated'),
          checklist: true,
          model: Ember.get(this, 'user.account')
        });
        Ember.run.later(function () {
          Ember.$('.new-folder-name').focus();
        }, 200);
      },
      addKeyToSearch: function addKeyToSearch(key) {
        Ember.set(this, 'manualFilterInput', "".concat(key, ":"));
        Ember.$('.suggestionInput').focus();
      } // addKeyToSearch
    } // actions
  });
});