define("affinio/routes/reports", ["exports", "affinio/mixins/auth-check", "affinio/mixins/zendesk-auth"], function (_exports, _authCheck, _zendeskAuth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_zendeskAuth.default, _authCheck.default, {
    loggedInOnly: true
  });
});