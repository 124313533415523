define("affinio/models/reports/custom", ["exports", "ember-data", "affinio/models/reports/-base", "affinio/utils/get-with-default"], function (_exports, _emberData, _base, _getWithDefault) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr; // import T from 'affinio/utils/taxonomies';
  var hasMany = _emberData.default.hasMany;
  var belongsTo = _emberData.default.belongsTo;
  var _default = _exports.default = _base.default.extend({
    everyClusterExportLink: Ember.computed(function () {
      return []; // oof. wish I didn't have to do this but sharing otherwise breaks without it :(
    }),
    isCustom: true,
    socialNetwork: 'custom',
    // Clusters
    _clusters: hasMany('clusters/custom'),
    // clusterAggregation: belongsTo('clusters/custom'),
    clusters: Ember.computed('_clusters', function () {
      var _clusters = Ember.get(this, '_clusters');
      // _clusters.forEach((cluster)=>set(cluster, 'name', get(cluster, 'name').replace(/\./gi, ' ').capitalize()));
      var clusterSorter = Ember.get(this, 'taxonomy.meta.clusterSorter');
      return clusterSorter ? _clusters.sortBy(clusterSorter) : _clusters;
    }),
    // clusters: hasMany('clusters/custom'),
    clusterAggregation: belongsTo('clusters/custom'),
    hasGS: Ember.computed('clusterAggregation', function () {
      window.console.log('TODO: ODC Hackathon');
      return (0, _getWithDefault.default)(this, 'clusterAggregation.metrics', []).findBy('name', 'gsKeywords');
    }),
    // clusterRatios: mapBy('clusters', 'normalizationMultiplier'),

    // Properties
    topicExplorer: attr(),
    callbackURL: attr('string'),
    _filters: attr(),
    filters: Ember.computed('_filters', function () {
      return JSON.parse(Ember.get(this, '_filters') || '[]');
    }),
    // Create a recordsCollection ourselves
    recordsCollection: Ember.computed(function () {
      // console.time('recordsCollection');
      var clusters = Ember.get(this, 'clusters');
      if (!clusters.every(function (x) {
        return Ember.get(x, 'numberOfMembers.response');
      })) {
        return {
          loaded: false,
          response: []
        };
      }
      if (!clusters.every(function (x) {
        return Ember.get(x, '_metrics.loaded');
      })) {
        return {
          loaded: false,
          response: []
        };
      }
      var metrics = Ember.get(this, 'metricDefinitions').reject(function (metric) {
        return Ember.get(metric, 'label').includes('.') || Ember.get(metric, 'defaultValue') === 'number' || Ember.get(metric, 'defaultValue') === 'string';
      });
      var recordsMap = new Map();

      // let clusterRatios = get(this, 'clusterRatios');

      var sliceSize = 1000; // how far the column are we willing to check for values to add to records?

      var emptyRecords = clusters.map(function (cluster) {
        return {
          cluster: Ember.get(cluster, 'suffix'),
          value: 0,
          normalizedScore: 0,
          metricNormalizedScore: 0,
          timesMoreLikely: 0
        };
      });
      metrics.filter(function (metric) {
        return Ember.get(metric, 'defaultValue') === 'array';
      }).forEach(function (metric) {
        var metricID = Ember.get(metric, 'label');
        // First, create an empty Map() for every metric type
        /* eslint-disable */
        // js map
        if (!recordsMap.get(metricID)) {
          recordsMap.set(metricID, new Map());
        }
        /* eslint-enable */

        var metricClusterTotals = clusters.map(function (cluster) {
          Ember.get(cluster, 'metrics');
          return {
            cluster: Ember.get(cluster, 'id'),
            total: Ember.get(cluster, metricID) && !Ember.isEmpty(Ember.get(Ember.get(cluster, metricID), 'response')) ? Ember.get(Ember.get(cluster, metricID), 'response').slice(0, sliceSize).mapBy('value').reduce(function (m, n) {
              return m + n;
            }) : 0
          };
        });

        // for each cluster:
        //     get metric, slice it, iterate over:
        //         if recordMap doesn't have current metric iter, set empty
        //         set value and normalized score
        clusters.forEach(function (cluster) {
          if (Ember.get(cluster, 'metrics').findBy('name', metricID)) {
            var metricClusterTotal = metricClusterTotals.findBy('cluster', Ember.get(cluster, 'id'));
            var metricClusterAverage = metricClusterTotals.mapBy('total').reduce(function (m, n) {
              return m + n;
            }) / metricClusterTotals.length;
            var metricClusterOffset = Ember.get(metricClusterTotal, 'total') / metricClusterAverage;
            var metricClusterRatio = 1 / metricClusterOffset; // multiply stuff by this.
            Ember.get(Ember.get(cluster, 'metrics').findBy('name', metricID), 'data').slice(0, sliceSize).forEach(function (instance) {
              // parse over its instances
              /* eslint-disable */
              // js map
              if (!recordsMap.get(metricID).has(Ember.get(instance, 'label'))) {
                // if it doesn't yet exist in the subMap, create it
                recordsMap.get(metricID).set(Ember.get(instance, 'label'), emptyRecords.map(function (record) {
                  return Object.assign({}, record);
                }));
              }
              recordsMap.get(metricID).get(instance.label)[Ember.get(cluster, 'index')].value = instance.value || 0;
              recordsMap.get(metricID).get(instance.label)[Ember.get(cluster, 'index')].normalizedScore = instance.value ? instance.value / Ember.get(cluster, 'numberOfMembers.response.value') : 0;
              recordsMap.get(metricID).get(instance.label)[Ember.get(cluster, 'index')].metricNormalizedScore = instance.value ? instance.value * metricClusterRatio : 0;

              /* eslint-enable */
            });
          }
        }); // clusters.forEach

        // for each cluster:
        //     get metric, slice it, iterate over
        //         setup data to be our format, and use recordsMap we just created
        clusters.forEach(function (cluster) {
          if (Ember.get(cluster, 'metrics').findBy('name', metricID)) {
            Ember.get(Ember.get(cluster, 'metrics').findBy('name', metricID), 'data').slice(0, sliceSize).forEach(function (instance) {
              /* eslint-disable */
              // js map
              instance.records = recordsMap.get(metricID).get(instance.label);
              /* eslint-enable */
              instance.records.forEach(function (record) {
                var thisLikely = record.normalizedScore;
                // let thisLikely = record.metricNormalizedScore;
                var otherLikely = _.chain(instance.records.without(record)).pluck('normalizedScore')
                // .pluck('metricNormalizedScore')
                .reduce(function (m, n) {
                  return m + n;
                }).value();
                otherLikely = otherLikely / instance.records.without(record).length;
                record.timesMoreLikely = {
                  value: thisLikely / otherLikely,
                  isInfinite: !isFinite(thisLikely / otherLikely)
                };
              }); // records for each
            }); // sliced metric for each
          } // if get(cluster, metric)
        }); // clusters for each
      }); // metrics.forEach

      // Generate as an array. Kind of backwards, but very fast.
      var recordsArray = Array.from(recordsMap).map(function (x) {
        return {
          label: x[0],
          values: Array.from(x[1]).map(function (y) {
            return {
              label: y[0],
              values: y[1]
            };
          })
        };
      });
      Ember.get(Ember.get(this, 'clusterAggregation'), 'metrics'); // TODO: wrong place
      // console.timeEnd('recordsCollection');

      // return recordsMap;
      var store = Ember.get(this, 'store');
      var existingRecords = store.peekRecord('metric', "".concat(Ember.get(this, 'id'), "___").concat(Ember.get(this, 'version'), "___recordsArray"));
      if (existingRecords) {
        return existingRecords;
      } else {
        return store.createRecord('metric', {
          id: "".concat(Ember.get(this, 'id'), "___").concat(Ember.get(this, 'version'), "___recordsArray"),
          response: recordsArray,
          loaded: true,
          network: 'Custom'
        });
      }
    })
  });
});