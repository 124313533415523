define("affinio/templates/components/modules/new-module", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "bKoDHBiF",
    "block": "{\"symbols\":[\"metric\",\"&default\"],\"statements\":[[14,2,[[22,0,[]]]],[0,\"\\n\"],[7,\"form\"],[9],[0,\"\\n  \"],[7,\"label\"],[9],[0,\"\\n    Metric\\n\"],[4,\"power-select\",null,[[\"selected\",\"options\",\"placeholder\",\"onchange\",\"renderInPlace\"],[[23,[\"selectedMetric\"]],[23,[\"possibleMetrics\"]],\"Select a Metric\",[27,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"selectedMetric\"]]],null]],null],true]],{\"statements\":[[0,\"      \"],[1,[22,1,[]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\\n  \"],[7,\"input\"],[11,\"value\",\"Create\"],[11,\"class\",\"button\"],[12,\"disabled\",[27,\"not\",[[23,[\"selectedMetric\"]]],null]],[11,\"type\",\"submit\"],[9],[10],[0,\"\\n\"],[3,\"action\",[[22,0,[]],\"createModule\"],[[\"on\"],[\"submit\"]]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/modules/new-module.hbs"
    }
  });
});