define("affinio/components/old/input-optimizer", ["exports", "affinio/utils/queries", "affinio/utils/regex"], function (_exports, _queries, _regex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  var SUPPORTED_LANGUAGES = [{
    value: 'all',
    label: 'All Languages'
  }, {
    value: 'en',
    label: 'English'
  }, {
    value: 'de',
    label: 'Deutsch'
  }, {
    value: 'es',
    label: 'Español'
  }, {
    value: 'fr',
    label: 'Français'
  }, {
    value: 'nl',
    label: 'Nederlands'
  }, {
    value: 'pt',
    label: 'Português'
  }, {
    value: 'ar',
    label: 'العربية'
  }, {
    value: 'zh',
    label: '中文'
  }, {
    value: 'ja',
    label: '日本語'
  }, {
    value: 'ko',
    label: '한국인'
  }];
  var _default = _exports.default = Ember.Component.extend({
    user: Ember.inject.service(),
    modalManager: Ember.inject.service(),
    tracker: Ember.inject.service(),
    graph: Ember.inject.service(),
    selectedLanguage: 'all',
    supportedLanguages: SUPPORTED_LANGUAGES,
    activeResults: false,
    advancedBoolean: true,
    downvotedWords: Ember.A(),
    initialEngagerQuery: null,
    monitoredTerms: Ember.A(),
    // The new report model will grab from here
    sampleTweets: Ember.A(),
    threshold: 50,
    topTerms: Ember.A(),
    upvotedWords: Ember.A(),
    addSingleAnyway: false,
    explainWhyNoSample: false,
    isTwitter: Ember.computed.equal('controller.network', 'twitter'),
    graphFeaturesToggled: false,
    voteBoxShown: false,
    init: function init() {
      this._super.apply(this, arguments);
      Ember.set(this, 'topTerms', Ember.A());
    },
    reset: Ember.observer('model.resetInputOptimizer', function () {
      this.send('restartQuery');
    }),
    hasVotes: Ember.computed('downvotedWords.[]', 'upvotedWords.[]', 'initialEngagerQuery.[]', function () {
      if ((Ember.get(this, 'downvotedWords.length') || Ember.get(this, 'upvotedWords.length') || Ember.get(this, 'initialEngagerQuery')) && !Ember.get(this, 'advancedBoolean')) {
        return Ember.get(this, 'downvotedWords.length') || Ember.get(this, 'upvotedWords.length') || Ember.get(this, 'initialEngagerQuery') ? Ember.get(this, 'initialEngagerQuery.length') : false;
      } else {
        return false;
      }
    }),
    // hasVotes

    filteredUpvotedWords: Ember.computed('upvotedWords.[]', function () {
      var initial = Ember.get(this, 'initialEngagerQuery');
      var pool = Ember.get(this, 'upvotedWords').sortBy('value').reverse();

      // Remove duplicates and sort by value, descending
      var updatedPool = _.uniq(pool.map(function (obj) {
        var label = obj.label.replace(_regex.curlyDoubleQuoteRegex, '"').replace(_regex.curlySingleQuoteRegex, '\'').replace(_regex.inputOptimizerSpecialRegex, '').toLowerCase();
        var escapeString = label.indexOf(' ') > -1 ? '"' : '';
        Ember.run.scheduleOnce('afterRender', function () {
          return Ember.set(obj, 'label', escapeString + label + escapeString);
        }); // eslint-disable-line ember/no-side-effects
        return obj;
      }), function (obj) {
        return obj.label;
      }); // updatedPool

      pool.clear();

      // Twitter cares about gnip clause limits; FB, for example, doesn't.
      pool.pushObjects(updatedPool);
      pool.forEach(function (hit) {
        // Max the value of each hit at 1.00
        if (hit.value > 1) {
          hit.value = 1;
        }

        // Standardize to lowercase
        Ember.set(hit, 'label', hit.label.toLowerCase()); // eslint-disable-line ember/no-side-effects

        // Remove words that are the same as the initial query
        if (initial.mapBy('label').indexOf(hit.label) > -1) {
          hit.tossed = true; // set to 0 to remove on subsequent filter
        } else {
          hit.tossed = false;
        }
      }); // forEach hit in pool

      pool = pool.filterBy('tossed', false); // remove all those set to 0 / duplicates

      // console.log('this matters, at the end of the filteredUpvotedWords', get(this, 'initialEngagerQuery'));
      return pool;
    }),
    // filteredUpvotedWords

    filteredDownvotedWords: Ember.computed('downvotedWords.[]', function () {
      var initial = Ember.get(this, 'initialEngagerQuery');
      var pool = Ember.get(this, 'downvotedWords').sortBy('value').reverse();

      // Remove duplicates and sort by value, descending
      var updatedPool = _.uniq(pool.map(function (obj) {
        var label = obj.label.replace(_regex.curlyDoubleQuoteRegex, '"').replace(_regex.curlySingleQuoteRegex, '\'').replace(_regex.inputOptimizerSpecialRegex, '').toLowerCase();
        var escapeString = label.indexOf(' ') > -1 ? '"' : '';
        Ember.run.scheduleOnce('afterRender', function () {
          return Ember.set(obj, 'label', escapeString + label + escapeString);
        }); // eslint-disable-line ember/no-side-effects
        // obj.label = obj.label.toLowerCase();
        return obj;
      }), function (obj) {
        return obj.label;
      }); // updatedPool

      pool.clear();
      pool.pushObjects(updatedPool);
      pool.forEach(function (hit) {
        // Max the value of each hit at 1.00
        if (hit.value > 1) {
          hit.value = 1;
        }

        // Standardize to lowercase
        Ember.set(hit, 'label', hit.label.toLowerCase()); // eslint-disable-line ember/no-side-effects

        // Remove words that are the same as the initial query
        if (initial.mapBy('label').indexOf(hit.label) > -1) {
          hit.tossed = true; // set to 0 to remove on subsequent filter
        } else {
          hit.tossed = false;
        }
      }); // forEach hit in pool
      pool = pool.filterBy('tossed', false); // remove all those set to 0 / duplicates
      return pool;
    }),
    // filteredDownvotedWords
    showVoteBox: function showVoteBox(word, ev) {
      if (!Ember.$(ev.target).hasClass('votable-word')) {
        ev.target = Ember.$(ev.target).parents('.votable-word');
      }
      if (word && ev) {
        Ember.set(this, 'voteBoxShown', true);
        Ember.set(this, 'voteBoxTarget', ev.target);
      } else {
        Ember.set(this, 'voteBoxShown', false);
      } // if word/ev
      Ember.set(this, 'voteBoxWord', word);
    },
    // showVoteBox
    getSimilarWords: function getSimilarWords(word, pool, otherpool) {
      var _this = this;
      // console.log('getting words similar to ', word, 'dropping em in ', pool);
      Ember.get(this, 'graph').query(_queries.default.wordSimilarity(word.label), true).then(function (response) {
        Ember.get(response, 'data.wordSimilarity').forEach(function (result) {
          result.label = result.label.toLowerCase();
          if (!otherpool.findBy('label', result.label)) {
            pool.pushObject(Ember.Object.create(result));
          }
        });
        _this.refineQuery();
      });
    },
    refineQuery: function refineQuery() {
      var _this2 = this;
      // console.log('REFINING QUERY');
      Ember.set(this, 'refiningQuery', true);
      var initial = "( ".concat(Ember.get(this, 'initialEngagerQuery').mapBy('label').join(' OR '), " )");
      var mauvais = Ember.get(this, 'filteredDownvotedWords');
      var bon = Ember.get(this, 'filteredUpvotedWords');
      var query = initial;

      // If you have upvoted words, change the query to AND them.
      // console.log('bon length?', bon, bon.length);
      if (bon.length) {
        // For each upvoted word, change the initial query to be (initial AND upvoted) OR ...
        var bons = bon.filter(function (word) {
          return word.value * 100 >= Ember.get(_this2, 'threshold');
        }).map(function (word) {
          var escapeString = word.label.indexOf(' ') > -1 ? '"' : '';
          return escapeString + word.label.replace(_regex.curlyDoubleQuoteRegex, '"').replace(_regex.curlySingleQuoteRegex, '\'').replace(_regex.inputOptimizerSpecialRegex, '').toLowerCase() + escapeString;
        });
        if (bons.length) {
          // if, after threshold filter, you've still got some words to consider
          query += " AND ( ".concat(bons.join(' OR '), " )");
        }
      } // if bon.length
      // console.log('after bon', query);

      // query = "( " + query + " )";

      // For each downvoted word, add a NOT rule to the initial query
      if (mauvais.length) {
        var mauvaises = mauvais.filter(function (word) {
          return word.value * 100 >= Ember.get(_this2, 'threshold');
        }).map(function (word) {
          var escapeString = word.label.indexOf(' ') > -1 ? '"' : '';
          return escapeString + word.label.replace(_regex.curlyDoubleQuoteRegex, '"').replace(_regex.curlySingleQuoteRegex, '\'').replace(_regex.inputOptimizerSpecialRegex, '').toLowerCase() + escapeString;
        }); // mauvais.forEach

        if (mauvaises.length) {
          // if, after threshold filter, you've still got some words to consider
          query += " NOT ( ".concat(mauvaises.join(' OR '), " )");
        }
      }
      var theFilters = _defineProperty(_defineProperty({
        body: query
      }, 'retweet_count', {
        'max': 100
      }), 'twitter_lang', Ember.get(this, 'selectedLanguage'));
      Ember.get(this, 'tracker').track('New Campaign - refining query');

      // console.log('so our real query to launch a report with is', query);

      Ember.$.ajax({
        type: 'POST',
        data: JSON.stringify({
          'search': theFilters,
          'size': 300,
          'from': 0
        }),
        url: 'https://cached-tweets.affinio.com/search',
        success: function success(m1) {
          // console.log('yesssssss');
          // console.log(m1);

          if (!m1.sample_tweets.length) {
            if (Ember.get(_this2, 'controller.activeNetwork.value') === 'Twitter') {
              Ember.set(_this2, 'dontContinue', true);
            }
            Ember.get(_this2, 'tracker').track('New Campaign - refining query no samples', {
              addSingleTerms: Ember.get(_this2, 'addSingleAnyway'),
              forceDontContinue: Ember.get(_this2, 'dontContinue')
            });
            Ember.set(_this2, 'explainWhyNoSample', true);
            Ember.get(_this2, 'modalManager').openModal('modals/modal-text', {
              title: 'No matches found',
              message: 'Your query is too narrow or the formatting is incorrect. Please edit your query and try again. If you need help, feel free to contact support@affinio.com.',
              noClose: false,
              okText: 'Got it',
              okAction: true
            });
          } else {
            Ember.set(_this2, 'dontContinue', false);
            Ember.set(_this2, 'addSingleAnyway', false);
            Ember.set(_this2, 'explainWhyNoSample', false);
          }
          Ember.get(_this2, 'sampleTweets').clear();
          Ember.get(_this2, 'topTerms').clear();
          Ember.get(_this2, 'sampleTweets').pushObjects(m1.sample_tweets.mapBy('body').slice(0, 10));
          var topTerms = m1.keywords.filter(function (obj) {
            // Don't allow a word in the topTerms list if it's already upvoted, downvoted, or the initial query itthis.

            // console.log('ok!', obj.label, initial);

            var matched = false;
            if (JSON.stringify(obj.label) === initial) {
              matched = true;
            }

            // If multi-word non-OR query
            if (initial.length === 1) {
              if (JSON.parse(initial).split(' ').indexOf(obj.label) !== -1) {
                matched = true;
              }
            } // if non-OR

            if (Ember.get(_this2, 'upvotedWords').mapBy('label').indexOf(JSON.stringify(obj.label)) > -1 || Ember.get(_this2, 'downvotedWords').mapBy('label').indexOf(JSON.stringify(obj.label)) > -1) {
              matched = true;
            }

            // If multi-word voted term
            if (_.flatten(Ember.get(_this2, 'upvotedWords').map(function (obj) {
              return obj.label.split(' ');
            })).indexOf(obj.label) > -1 || _.flatten(Ember.get(_this2, 'downvotedWords').map(function (obj) {
              return obj.label.split(' ');
            })).indexOf(obj.label) > -1) {
              matched = true;
            }
            return !matched;
          }); // filter

          topTerms.forEach(function (obj) {
            obj.scaledValue = Math.ceil(obj.value / _.max(topTerms, 'value').value * 5);
          });
          Ember.get(_this2, 'topTerms').pushObjects(topTerms);

          // Replace query NOTs with "-"s and pass to monitoredTerms
          var minusedQuery = query.replace(/NOT /g, '-');
          if (!Ember.get(_this2, 'model.filters.contentQuery')) {
            Ember.set(_this2, 'model.filters.contentQuery', Ember.A());
          }
          if (minusedQuery.length > 2048) {
            Ember.get(_this2, 'modalManager').openModal('modals/modal-text', {
              title: 'Query too large',
              message: 'Our system is optimized to support queries of 2048 characters or fewer. Please remove some keywords or operators',
              okText: 'Got it',
              okAction: true
            });
          }
          Ember.get(_this2, 'model.filters.contentQuery').clear().pushObject(minusedQuery);
          _this2.toggleProperty('model.filterChange');
          Ember.set(_this2, 'refiningQuery', false);
        },
        // success
        error: function error(/* err1, err2, err3 */
        ) {
          // console.log('oh noooooo');
          // console.log(err1, err2, err3);
          Ember.set(_this2, 'currentlyQuerying', false);
          Ember.get(_this2, 'modalManager').openModal('modals/modal-text', {
            title: 'Estimate unavailable',
            message: 'We couldn\'t get an estimated count for this term.',
            noClose: false,
            okText: 'Got it',
            okAction: true
          });
          Ember.set(_this2, 'refiningQuery', false);
        } // error
      }); // ajax
    },
    // refineQuery

    actions: {
      openSupport: function openSupport() {
        Ember.get(this, 'openSupport').apply(void 0, arguments);
      },
      setInitialEngagerQuery: function setInitialEngagerQuery() {
        var _this3 = this;
        var query = Ember.get(this, 'current_engager_query');
        var user = Ember.get(this, 'user');

        // query = '"'+query.replace(/\ /g, '_').replace(/\W/g, '').toLowerCase().replace(/\_/g, ' ')+'"';
        query = query.replace(/, /g, ' or ').replace(_regex.curlyDoubleQuoteRegex, '"').replace(_regex.curlySingleQuoteRegex, '\'').replace(_regex.inputOptimizerSpecialRegex, '').toLowerCase();
        var queryCopy = "".concat(query);
        query = query.split(' or ').map(function (obj) {
          return "\"".concat(obj, "\"");
        });
        queryCopy = queryCopy.split(' or ').map(function (obj) {
          var escapeString = obj.indexOf(' ') > -1 ? '"' : '';
          return escapeString + obj + escapeString;
        });
        // console.log('Setting ' + query + ' as initial query');

        var words = Ember.A();
        var wordsUnescaped = Ember.A();
        query.forEach(function (word) {
          words.pushObject(Ember.Object.create({
            label: word,
            value: 1
          })); // pushObject
        }); // forEach

        queryCopy.forEach(function (word) {
          wordsUnescaped.pushObject(Ember.Object.create({
            label: word,
            value: 1
          })); // pushObject
        }); // forEach

        Ember.set(this, 'model.querying', true);
        Ember.set(this, 'initialEngagerQuery', wordsUnescaped);
        Ember.get(this, 'downvotedWords').clear();
        Ember.get(this, 'upvotedWords').clear();
        words.forEach(function (word) {
          var escapeString = word.label.indexOf(' ') > -1 ? '"' : '';
          word.label = escapeString + word.label.replace(_regex.inputOptimizerSpecialRegex, '').toLowerCase() + escapeString;
          word.value = 1;
          Ember.get(_this3, 'upvotedWords').pushObject(word);
        }); // forEach

        var theFilters = _defineProperty(_defineProperty({
          body: queryCopy.join(' OR ')
        }, 'retweet_count', {
          max: 100
        }), 'twitter_lang', Ember.get(this, 'selectedLanguage'));
        Ember.get(this, 'tracker').track('New Campaign - Input Optimizer Initial Query Set', {
          user: user.email,
          query: query.join(' OR '),
          network: Ember.get(this, 'model.socialNetwork')
        });
        if (queryCopy.join(' OR ').length > 2048) {
          Ember.set(this, 'model.querying', false);
          Ember.set(this, 'initialEngagerQuery', null);
          Ember.get(this, 'modalManager').openModal('modals/modal-text', {
            title: 'Query too large',
            message: 'Our system is optimized to support queries of 2048 characters or fewer. Please remove some keywords or operators',
            okText: 'Got it',
            okAction: true
          });
        } else {
          Ember.$.ajax({
            type: 'POST',
            data: JSON.stringify({
              search: theFilters,
              size: 300,
              from: 0
            }),
            url: 'https://cached-tweets.affinio.com/search',
            success: function success(m1) {
              // console.log('initial m1', m1);
              var continueAnyway = true;
              Ember.set(_this3, 'current_engager_query', null);
              Ember.set(_this3, 'model.querying', false);
              if (!m1.sample_tweets.length) {
                var message;
                if (Ember.get(_this3, 'isTwitter')) {
                  continueAnyway = false;
                  message = 'We couldn\'t find any tweets containing your search. Make sure there your query is spelled correctly, or try using different terms.';
                  Ember.set(_this3, 'initialEngagerQuery', null);
                  Ember.get(_this3, 'upvotedWords').clear();
                }
                Ember.get(_this3, 'tracker').track('New Campaign - initial query no samples', {
                  addSingleTerms: Ember.get(_this3, 'addSingleAnyway'),
                  continueAnyway: continueAnyway
                });
                Ember.get(_this3, 'modalManager').openModal('modals/modal-text', {
                  title: 'No matches found',
                  message: message,
                  noClose: false,
                  okText: 'Got it',
                  okAction: true
                });
              } else {
                Ember.set(_this3, 'addSingleAnyway', false);
              }
              if (continueAnyway) {
                // get(this, 'monitoredTerms').pushObject(obj);
                Ember.get(_this3, 'sampleTweets').clear();
                Ember.get(_this3, 'sampleTweets').pushObjects(m1.sample_tweets.mapBy('body').slice(0, 10));

                // get(this, 'sampleTweets').pushObjects(_.uniq(m1.sampleTweets.mapBy('src_id')));
                Ember.get(_this3, 'topTerms').clear();
                var topTerms = m1.keywords.filter(function (obj) {
                  // declare "safe", and truth to filter, if the returned top_term is neither the initial query,
                  // nor a sub-word of said query.

                  var safe = true;
                  if (query.indexOf(JSON.stringify(obj.label)) !== -1) {
                    safe = false;
                  }

                  // If multi-word non-OR query
                  if (query.length === 1) {
                    if (JSON.parse(query).split(' ').indexOf(obj.label) !== -1) {
                      safe = false;
                    } // if label found in query
                  } // if non-OR

                  return safe;
                }); // filter

                topTerms.forEach(function (obj) {
                  obj.scaledValue = Math.ceil(obj.value / _.max(topTerms, 'value').value * 5);
                });
                Ember.get(_this3, 'topTerms').pushObjects(topTerms);
                query = "( ".concat(query.join(' OR '), " )"); // prevent glued-together comma situation, and add surrounding brackets
                if (!Ember.get(_this3, 'model.filters.contentQuery')) {
                  Ember.set(_this3, 'model.filters.contentQuery', Ember.A());
                }
                Ember.get(_this3, 'model.filters.contentQuery').clear().pushObject(query);
                _this3.toggleProperty('model.filterChange');
                Ember.set(_this3, 'activeResults', true);
              }
            },
            // success
            error: function error() {
              Ember.set(_this3, 'model.querying', false);
              Ember.get(_this3, 'modalManager').openModal('modals/modal-text', {
                title: 'Estimate unavailable',
                message: 'We couldn\'t get an estimated count for this term.',
                noClose: false,
                okText: 'Got it',
                okAction: true
              });
            } // error
          }); // ajax
        }
      } /* query */,
      restartQuery: function restartQuery() {
        var clearAllContentQuery = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
        Ember.get(this, 'tracker').track('New Campaign - Restaring input optimizer', {});
        Ember.get(this, 'downvotedWords').clear();
        Ember.get(this, 'monitoredTerms').clear();
        Ember.get(this, 'sampleTweets').clear();
        Ember.get(this, 'topTerms').clear();
        Ember.get(this, 'upvotedWords').clear();
        Ember.set(this, 'initialEngagerQuery', null);
        Ember.set(this, 'activeResults', false);
        Ember.set(this, 'addSingleAnyway', false);
        Ember.set(this, 'explainWhyNoSample', false);
        Ember.set(this, 'dontContinue', false);
        Ember.set(this, 'current_engager_query', null);
        Ember.set(this, 'currentlyQuerying', false);
        if (clearAllContentQuery) {
          Ember.set(this, 'model.filters.contentQuery', null);
          this.toggleProperty('model.filterChange');
        }
      },
      enableAdvancedBoolean: function enableAdvancedBoolean() {
        this.send('restartQuery');
        Ember.set(this, 'advancedBoolean', true);
        Ember.get(this, 'tracker').track('User toggled advanced boolean for mentions report', {
          user: Ember.get(this, 'user.email'),
          toggledTo: true
        });
      },
      disableAdvancedBoolean: function disableAdvancedBoolean() {
        this.send('restartQuery');
        Ember.set(this, 'advancedBoolean', false);
        Ember.get(this, 'tracker').track('User toggled advanced boolean for mentions report', {
          user: Ember.get(this, 'user.email'),
          toggledTo: false
        });
      },
      // TODO remove not used
      toggleGraphFeatures: function toggleGraphFeatures() {
        this.toggleProperty('graphFeaturesToggled');
      },
      hoverWord: function hoverWord(word, ev) {
        this.showVoteBox(word, ev);
      },
      downvote: function downvote(word) {
        // TODO toast message for vote
        Ember.setProperties(word, {
          label: word.label.replace(_regex.curlyDoubleQuoteRegex, '"').replace(_regex.curlySingleQuoteRegex, '\'').replace(_regex.inputOptimizerSpecialRegex, '').toLowerCase(),
          value: 1
        });

        // Remove the word from the upvote pool if it's there already
        var foundUpvote = Ember.get(this, 'upvotedWords').findBy('label', Ember.get(word, 'label'));
        if (foundUpvote) {
          Ember.get(this, 'upvotedWords').removeObjects(Ember.get(this, 'upvotedWords').filterBy('label', Ember.get(word, 'label')));
        }
        Ember.get(this, 'downvotedWords').pushObject(word);
        this.getSimilarWords(word, Ember.get(this, 'downvotedWords'), Ember.get(this, 'upvotedWords'));
        Ember.get(this, 'tracker').track('New Campaign - User downvoted a word', {
          user: Ember.get(this, 'user.email'),
          word: word.label
        });
      },
      // downvote
      upvote: function upvote(word) {
        // TODO toast message for vote
        Ember.setProperties(word, {
          label: word.label.replace(_regex.curlyDoubleQuoteRegex, '"').replace(_regex.curlySingleQuoteRegex, '\'').replace(_regex.inputOptimizerSpecialRegex, '').toLowerCase(),
          value: 1
        });

        // Remove the word from the downvote pool if it's there already
        var foundDownvote = Ember.get(this, 'downvotedWords').findBy('label', Ember.get(word, 'label'));
        if (foundDownvote) {
          Ember.get(this, 'downvotedWords').removeObjects(Ember.get(this, 'downvotedWords').filterBy('label', Ember.get(word, 'label')));
        }
        Ember.get(this, 'upvotedWords').pushObject(word);
        this.getSimilarWords(word, Ember.get(this, 'upvotedWords'), Ember.get(this, 'downvotedWords'));
        Ember.get(this, 'tracker').track('New Campaign - User upvoted a word', {
          user: Ember.get(this, 'user.email'),
          word: word.label
        });
      },
      // upvote
      removeWord: function removeWord(word, parent) {
        Ember.get(this, 'tracker').track('New Campaign - remove word', {
          word: word.label,
          parent: parent
        });
        var parentBucket;
        if (parent === 'upvoted') {
          parentBucket = 'upvotedWords';
        } else {
          parentBucket = 'downvotedWords';
        }

        // Why not do a simple removeObject() ? Well, it seems like sometimes we receive the same word with different values.
        // This looks like it may be a backend bug. Will (TODO) have to look at it soon. -PR, during a thunderstorm in Sept. 2016
        // And then I never wrote code again. -PR, Feb 2017
        Ember.get(this, parentBucket).removeObjects(Ember.get(this, parentBucket).filterBy('label', word.label));
        this.refineQuery();
      },
      // remove
      thresholdChanged: function thresholdChanged() {
        var _this4 = this;
        Ember.run.later(function () {
          _this4.refineQuery();
        }, 500);
      },
      addClassicBoolean: function addClassicBoolean(term) {
        // console.log('just go hit', term);
        var cleanTerm = term.replace(_regex.curlyDoubleQuoteRegex, '"').replace(_regex.curlySingleQuoteRegex, '\'').replace(/\r?\n|\r/g, ' ');
        var model = Ember.get(this, 'model');
        var existingMonitored = Ember.get(model, 'filters.contentQuery');
        var existingCount = 0;
        if (!existingMonitored) {
          Ember.set(this, 'model.filters.contentQuery', Ember.A());
        } else {
          existingCount = existingMonitored.mapBy('length').reduce(function (a, b) {
            return a + b;
          }, 0);
        }

        // TODO talk to phil about this, seems quite weird
        var fromIndex = cleanTerm.indexOf('fromDate:');
        var toIndex = cleanTerm.indexOf('toDate:');
        if (fromIndex === 0) {
          Ember.set(this, 'current_monitoredTerm', null); // TODO may need to set this on the model itself
          Ember.set(model, 'filters.contentFrom', cleanTerm.substring(9));
          this.toggleProperty('model.filterChange');
          return;
        } else if (toIndex === 0) {
          Ember.set(this, 'currentMonitoredTerm', null); // TODO may need to set this on the model itself
          Ember.set(model, 'filters.contentTo', cleanTerm.substring(7));
          this.toggleProperty('model.filterChange');
          return;
        }
        if (cleanTerm.length + existingCount > 2048) {
          // set(this, 'current_monitored_term', null); //TODO should be able to
          Ember.get(this, 'modalManager').openModal('modals/modal-text', {
            title: 'Query too large',
            message: 'Our system is optimized to support queries of 2048 characters or fewer. Please remove some keywords or operators',
            okText: 'Got it',
            okAction: true
          });
        } else {
          if (!Ember.get(this, 'model.filters.contentQuery')) {
            Ember.set(this, 'model.filters.contentQuery', Ember.A());
          }
          Ember.get(this, 'model.filters.contentQuery').pushObject(cleanTerm);
          Ember.set(this, 'current_monitored_term', null);
          Ember.get(this, 'tracker').track('New Campaign - Classic Boolean Query Set');
          this.toggleProperty('model.filterChange');
        }
      }
    }
  });
});