define("affinio/utils/report-builder/facebook/engagement-graph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    label: 'Engagement Graph',
    // text of the button when selecting report type
    naturalLanguageLabel: 'How they engage with content',
    value: 'engagement-graph',
    // handle
    description: 'despactio',
    stages: [{
      label: 'Build Audience',
      modules: [{
        label: 'What gets shown on the h4?',
        description: 'what gets shown in the helper text',
        value: 'text-input'
      }]
    }]
  };
});