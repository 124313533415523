define("affinio/routes/application", ["exports", "affinio/utils/mutations", "affinio/utils/queries", "affinio/utils/taxonomies", "affinio/mixins/piano-nav", "affinio/utils/taxonomies/utils"], function (_exports, _mutations, _queries, _taxonomies, _pianoNav, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _window = window,
    _window$Object = _window.Object,
    keys = _window$Object.keys,
    values = _window$Object.values;

  // const { testing } = Ember;

  // Add scrolling to top on all routes
  Ember.Route.reopen({
    tracker: Ember.inject.service(),
    path: Ember.inject.service(),
    activate: function activate() {
      this._super.apply(this, arguments);
      Ember.$('html,body').scrollTop(0);
    },
    redirect: function redirect() {
      var _this = this;
      this._super.apply(this, arguments);
      // Dirty maybe? but it works - JK Jan 2016
      // This is needed or else the path's computed properties can fire out of order causing dynamic routing messups - JK Apr 2017
      Ember.run.scheduleOnce('afterRender', function () {
        var category = Ember.get(_this, 'category');
        var subcategory = Ember.get(_this, 'subcategory');
        var inReport = Ember.get(_this, 'path.inReport');
        var documentTitle = Ember.get(_this, 'documentTitle');
        var level = inReport ? Ember.get(_this, 'path.inCluster') ? 'Cluster' : 'Report' : '';
        var trackObj = {
          page: null,
          currentPath: Ember.get(_this, 'path.currentPath')
        };
        if (inReport && category && subcategory) {
          trackObj.category = category;
          trackObj.subcategory = subcategory;
          trackObj.page = "".concat(level, " ").concat(category.capitalize(), " ").concat(subcategory.capitalize());
          trackObj.inCluster = Ember.get(_this, 'path.inCluster');
        } else if (documentTitle) {
          trackObj.page = documentTitle;
        }
        if (trackObj.page) {
          Ember.get(_this, 'tracker').track('Page View', trackObj);
        }
      }); // TODO this is with scheduleOnce
    }
  });
  var _default2 = _exports.default = Ember.Route.extend(_pianoNav.default, {
    // Services
    app: Ember.inject.service(),
    user: Ember.inject.service(),
    tracker: Ember.inject.service(),
    modalManager: Ember.inject.service(),
    graph: Ember.inject.service(),
    path: Ember.inject.service(),
    errorHandler: Ember.inject.service(),
    localStorage: Ember.inject.service(),
    auth: Ember.inject.service(),
    // Lifecycle
    release: Ember.on('init', function () {
      /* eslint-disable */
      console.log("                                                     ___\n                                                  ,o88888\n                                               ,o8888888'\n                         ,:o:o:oooo.        ,8O88Pd8888\"\n                     ,.::.::o:ooooOoOoO. ,oO8O8Pd888'\"\n                   ,.:.::o:ooOoOoOO8O8OOo.8OOPd8O8O\"\n                  , ..:.::o:ooOoOOOO8OOOOo.FdO8O8\"\n                 , ..:.::o:ooOoOO8O888O8O,COCOO\"\n                , . ..:.::o:ooOoOOOO8OOOOCOCO\"\n                 . ..:.::o:ooOoOoOO8O8OCCCC\"o\n                    . ..:.::o:ooooOoCoCCC\"o:o\n                    . ..:.::o:\u30C4:,cooooCo\"oo:o:\n                 `   . . ..:.:cocoooo\"'o:o:::'\n                 .`   . ..::ccccoc\"'o:o:o:::'\n                :.:.    ,c:cccc\"':.:.:.:.:.'\n              ..:.:\"'`::::c:\"'..:.:.:.:.:.'\n            ...:.'.:.::::\"'    . . . . .'\n           .. . ....:.\"' `   .  . . ''\n         . . . ....\"'\n         .. . .\"'        Affinio Platform\n        .                 ".concat(Ember.get(this, 'app.ENV.version'), "\n"));
      /* eslint-enable */
    }),
    // This is needed as the ms popup flow needs the library initialized right away
    authInit: Ember.on('init', function () {
      Ember.get(this, 'auth');
    }),
    setDefaultLocalPref: function setDefaultLocalPref(pref, _default) {
      if (Ember.isNone(Ember.get(this, "localStorage.".concat(pref)))) {
        Ember.set(this, "localStorage.".concat(pref), _default);
      }
    },
    setDefaultPreferences: Ember.on('init', function () {
      this.setDefaultLocalPref('showAccountOwned', true);
      this.setDefaultLocalPref('showGroupOwned', true);
      this.setDefaultLocalPref('showCompanyOwned', true);
      // Clear deprecated localStorage prefs
      Ember.get(this, 'localStorage').clearDeprecated();
    }),
    // auth service affinioLogout unloads teh data store. We need the taxos back
    bindTaxoToAuth: Ember.on('init', function () {
      Ember.set(this, 'auth.loadMetricTaxonomies', this.loadMetricTaxonomies.bind(this));
    }),
    // We create stored models for all categories, subcategories, and modules in all our taxonomies.
    // We also provide IDs when they're not obvious here.
    loadMetricTaxonomies: function loadMetricTaxonomies() {
      var _this2 = this;
      values(_taxonomies.default).forEach(function (network) {
        return (0, _utils.loadJsonTaxonomy)(network, Ember.get(_this2, 'graph'));
      });
    },
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      // These are used in reports, thus top level
      this.loadMetricTaxonomies();
    },
    actions: {
      error: function error(_error, transition) {
        Ember.get(this, 'errorHandler').persistError(_error, 'Transition', transition);
        throw _error;
      },
      trackHelp: function trackHelp() {
        Ember.get(this, 'tracker').track('visited help center');
      },
      signOut: function signOut() {
        var _this3 = this;
        Ember.get(this, 'user').signOut().then(function () {
          _this3.transitionTo('sign-in');
        });
      },
      blurInput: function blurInput() {
        Ember.$('input').blur();
      },
      // Accepts an array of reports. If you only have one report to send in, from handlbars, you can do:
      // {{action "editReports" (array reportObject)}}
      editReports: function editReports(reports, isAdmin) {
        Ember.get(this, 'modalManager').openModal('modals/modal-edit-report', {
          reports: reports,
          isAdmin: isAdmin,
          okAction: Ember.get(this, 'actions.saveReports').bind(this)
        });
      },
      saveReports: function saveReports(reports, editedProperties) {
        var _this4 = this;
        var sendEmail = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
        var notes = arguments.length > 3 ? arguments[3] : undefined;
        var graph = Ember.get(this, 'graph');
        return graph.mutate(_mutations.default.updateReports({
          reports: reports,
          editedProperties: editedProperties,
          sendEmail: sendEmail,
          notes: notes
        })).then(function () {
          if (editedProperties.sharedEntities) {
            Ember.get(_this4, 'tracker').track('Report(s) Shared', {
              user: Ember.get(_this4, 'user').email,
              reports: JSON.stringify(reports.mapBy('id')),
              numberOfReports: Ember.get(reports, 'length'),
              numberOfSharedEntities: editedProperties.sharedEntities.length,
              sharedEmail: sendEmail
            });
          }
          if (editedProperties.unsharedEntities) {
            Ember.get(_this4, 'tracker').track('Report(s) Unshared', {
              user: Ember.get(_this4, 'user').email,
              reports: JSON.stringify(reports.mapBy('id')),
              numberOfReports: Ember.get(reports, 'length'),
              numberOfUnsharedEntities: editedProperties.unsharedEntities.length,
              sharedEmail: sendEmail
            });
          }
          if (editedProperties.name) {
            Ember.get(_this4, 'tracker').track('Report Name Edited', {
              user: Ember.get(_this4, 'user').email,
              report: JSON.stringify(reports.mapBy('id'))
            });
          }
        }).then(function () {
          var foldersToUpdate = Ember.get(editedProperties, 'foldersToUpdate');
          if (foldersToUpdate) {
            return graph.query(_queries.default.updateFoldersReports(foldersToUpdate), false);
          }
        }).then(function () {
          var groupsToUpdate = Ember.get(editedProperties, 'groupsToUpdate');
          if (groupsToUpdate) {
            return graph.query(_queries.default.updateGroupsReports(groupsToUpdate), false);
          }
        }).then(function () {
          reports.setEach('checked', false);
        });
      },
      saveFolder: function saveFolder(folder, editedProperties) {
        var sendEmail = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
        var graph = Ember.get(this, 'graph');
        return graph.mutate(_mutations.default.updateFolder({
          folder: folder,
          editedProperties: editedProperties,
          sendEmail: sendEmail
        })).then(function () {
          var groupsToUpdate = Ember.get(editedProperties, 'groupsToUpdate');
          if (groupsToUpdate) {
            return graph.query(_queries.default.updateGroupsFolders(groupsToUpdate), false);
          }
        }).catch(window.console.log);
      },
      createFolderModal: function createFolderModal() {
        Ember.get(this, 'modalManager').openModal('modals/modal-new-folder', {
          okAction: Ember.get(this, 'actions.createFolder').bind(this)
        });
      },
      createFolder: function createFolder(name, editedProperties) {
        var _this5 = this;
        var sendEmail = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
        var graph = Ember.get(this, 'graph');
        return graph.mutate(_mutations.default.createFolder(name)).then(function (folder) {
          Ember.get(_this5, 'tracker').track('user created a new folder from button', {
            id: Ember.get(folder, 'id')
          });
          // We manually set the relationship rather than refetching the folders
          Ember.get(_this5, 'user.account.folders').pushObject(folder);
          // If they want to share, do that
          if (keys(editedProperties).length) {
            return graph.mutate(_mutations.default.updateFolder({
              folder: folder,
              editedProperties: editedProperties,
              sendEmail: sendEmail
            })).then(function () {
              var groupsToUpdate = Ember.get(editedProperties, 'groupsToUpdate');
              if (groupsToUpdate) {
                return graph.query(_queries.default.updateGroupsFolders(groupsToUpdate), false);
              }
            });
          }
        });
      }
    }
  });
});