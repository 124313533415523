define("affinio/routes/sign-out", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    auth: Ember.inject.service(),
    redirect: function redirect() {
      Ember.get(this, 'auth').affinioLogout();
    }
  });
});