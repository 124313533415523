define("affinio/components/report-builder/boolean-input-optimizer", ["exports", "affinio/mixins/input-module"], function (_exports, _inputModule) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_inputModule.default, {
    // Element
    classNames: ['boolean-input-optimizer'],
    graph: Ember.inject.service(),
    tracker: Ember.inject.service(),
    path: Ember.inject.service(),
    user: Ember.inject.service(),
    actions: {
      openSupport: function openSupport() {
        var path = Ember.get(this, 'path');
        Ember.get(this, 'tracker').track('New Campaign - User clicked Support button', {
          currentCampaign: Ember.get(path, 'audienceID'),
          currentTab: Ember.get(path, 'currentTab'),
          currentPath: Ember.get(path, 'currentPath')
        });
        Ember.get(this, 'modalManager').openModal('modals/modal-feedback');
      }
    }
  });
});