define("affinio/components/note-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Element
    classNames: ['note-field'],
    tagName: 'div',
    // Properties
    note: '',
    showNotes: false,
    isShareNotes: false,
    actions: {
      toggle: function toggle(prop) {
        this.toggleProperty(prop);
      }
    }
  });
});