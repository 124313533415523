define("affinio/utils/taxonomies/utils", ["exports", "ember-copy", "affinio/utils/report-builder/builder-utils"], function (_exports, _emberCopy, _builderUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.baseTaxonomy = void 0;
  _exports.dataSourceModelFromResponse = dataSourceModelFromResponse;
  _exports.loadJsonTaxonomy = loadJsonTaxonomy;
  _exports.makeTaxonomyJson = makeTaxonomyJson;
  _exports.setupDefaultSchema = setupDefaultSchema;
  _exports.taxoModelFromResponse = taxoModelFromResponse;
  _exports.updateExportSchema = void 0;
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  var mapModelJson = function mapModelJson(n) {
    return n.toJSON();
  };
  var baseTaxonomy = _exports.baseTaxonomy = {
    meta: {
      label: '',
      description: '',
      icon: null,
      colors: {
        primary: '#2DDE98',
        secondary: '#2DDE98'
      },
      alwaysLoadMetrics: {
        blocking: true,
        report: ['numberOfMembers'],
        cluster: ['numberOfMembers', '_metrics'],
        aggregate: ['numberOfMembers', '_metrics']
      },
      dateCreated: new Date().toISOString(),
      useInExpress: true,
      useInClassic: true
    },
    builder: {
      runnable: true,
      types: [_builderUtils.custom]
    },
    schema: [{
      label: 'Overview',
      value: 'overview',
      uneditable: true,
      subcategories: [{
        label: 'Summary',
        value: 'summary',
        modules: [{
          label: 'Audience Details and Source',
          value: 'audience-header',
          dependencies: ['cluster.isAggregate'],
          metrics: [{
            clusterAggregation: 'numberOfMembers'
          }]
        }, {
          label: 'Audience Visualization',
          value: 'audience-visualization',
          dependencies: ['cluster.isAggregate'],
          metrics: [{
            report: 'clusterSVGURL'
          }],
          description: 'This diagram represents the different clusters within this audience. The lines connecting clusters indicate their shared interests. More lines = more in common.'
        }, {
          label: 'Baseline Summary',
          value: 'baseline-summary',
          dependencies: ['cluster.isAggregate']
        }, {
          label: 'Cluster Summaries',
          value: 'cluster-summaries',
          dependencies: ['cluster.isAggregate'],
          description: 'This section provides a consolidated overview of key over-indexing attributes by cluster. Click-through to see more.'
        }, {
          label: 'Top Traits',
          value: 'traits-gallery',
          dependencies: ['cluster.isCluster']
        }]
      }]
    }, {
      label: 'Compare',
      value: 'compare',
      uneditable: true,
      dependencies: ['report.showCompare'],
      subcategories: [{
        label: 'Cluster Compare',
        value: 'index',
        modules: [{
          label: 'Compare Display',
          value: 'compare-display'
        }]
      }, {
        label: 'Cluster Overlap',
        value: 'cluster-overlap',
        modules: [{
          label: 'Cluster Overlap',
          value: 'cluster-overlap',
          description: 'This represents how a cluster’s metrics overlap with the metrics of all other clusters in the report. Scores are out of 100%. A relatively high overlap indicates clusters share similar metrics, and a relatively low overlap indicates clusters share few to no similar metrics.'
        }]
      }]
    }, {
      label: 'Actions',
      value: 'actions',
      uneditable: true,
      subcategories: [{
        label: 'Export',
        value: 'export',
        modules: [{
          label: 'Data Downloads (.csv)',
          value: 'csv-exporter'
        }, {
          label: 'Screenshots Export',
          value: 'screenshots-exporter'
        }, {
          label: 'Network Graph Vector (.svg)',
          value: 'network-graph-export',
          metrics: [{
            report: 'clusterSVGURL'
          }]
        }]
      }]
    }],
    exportSchema: [],
    metricDefinitions: [{
      label: 'metrics',
      modelAlias: '_metrics',
      level: 'cluster',
      properties: [],
      defaultValue: 'array'
    }, {
      label: 'numberOfMembers',
      level: 'report',
      properties: [],
      defaultValue: 'number'
    }, {
      label: 'clusterSVGURL',
      title: 'Network Graph',
      properties: [],
      defaultValue: 'string',
      level: 'report'
    }, {
      label: 'numberOfMembers',
      level: 'cluster',
      properties: [],
      defaultValue: 'number',
      aggregate: {
        checked: true,
        type: 'rawValue',
        title: 'Number of Members'
      }
    }, {
      label: 'density',
      level: 'cluster',
      properties: [],
      defaultValue: 'number',
      title: 'Cluster Overview',
      description: ''
    }],
    dataSources: []
  };
  function loadJsonTaxonomy(taxonomy, graph) {
    var properties = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    // Setup any passed in properties
    Object.keys(properties).forEach(function (prop) {
      return taxonomy[prop] = properties[prop];
    });

    // This makes the schema look as if its coming back from graphql
    // That way we can use our graph service to create/update records
    if (taxonomy.schema && taxonomy.schema.length) {
      taxonomy.schema.forEach(function (cat) {
        cat.id = "".concat(taxonomy.id, "_").concat(cat.value);
        cat.subcategories.forEach(function (subcat) {
          subcat.id = "".concat(taxonomy.id, "_").concat(cat.value, "_").concat(subcat.value);
          subcat.modules.forEach(function (module, index) {
            module.id = "".concat(taxonomy.id, "_").concat(cat.value, "_").concat(subcat.value, "_").concat((module.label || subcat.label).underscore(), "_").concat(index);
          });
        });
      });
    }
    if (taxonomy.metricDefinitions && taxonomy.metricDefinitions.length) {
      taxonomy.metricDefinitions.forEach(function (metric) {
        metric.id = "".concat(taxonomy.id, "_").concat(metric.level || 'cluster', "_").concat(metric.label);
      });
    }
    if (taxonomy.exportSchema && taxonomy.exportSchema.length) {
      taxonomy.exportSchema.forEach(function (exportDefinition, i) {
        exportDefinition.id = "".concat(taxonomy.id, "_").concat(i);
      });
    }
    // We then use the graph service to handle setting up the models
    // We do this to ensure that when we switch to api driven there are no hidden issues
    return graph.recordFromGraphData(false, 'taxonomy', taxonomy);
  }
  function makeTaxonomyJson(taxonomy) {
    var modelJson = taxonomy.toJSON({
      includeId: true
    });

    // We handle relationships seperately as .toJSON doesn't cover them beyond ids
    modelJson.exportSchema = Ember.get(taxonomy, 'exportSchema').map(mapModelJson);
    modelJson.metricDefinitions = Ember.get(taxonomy, 'metricDefinitions').map(mapModelJson);
    modelJson.schema = Ember.get(taxonomy, 'schema').map(function (category) {
      var pojoCat = category.toJSON();
      pojoCat.subcategories = Ember.get(category, 'subcategories').map(function (subcategory) {
        var pojoSubcat = subcategory.toJSON();
        pojoSubcat.modules = subcategory.value !== 'hidden' ? Ember.get(subcategory, 'modules').map(mapModelJson) : [];
        return pojoSubcat;
      });
      return pojoCat;
    });

    // For some reason .toJSON is still returning a reference to some of the original
    // attrs like meta. Deep copy it to be sure we are a new object - JK May 2019
    return (0, _emberCopy.copy)({
      id: modelJson.id,
      meta: modelJson.meta,
      builder: modelJson.builder,
      schema: modelJson.schema,
      exportSchema: modelJson.exportSchema,
      metricDefinitions: modelJson.metricDefinitions,
      dataSources: modelJson.dataSources
    }, true);
  }
  function setupDefaultSchema(metricDefinitions) {
    var baseSchema = _toConsumableArray(baseTaxonomy.schema);
    var defaultMetricDefinitionHash = baseTaxonomy.metricDefinitions.map(function (n) {
      return "".concat(n.level, "_").concat(n.label);
    });
    var customMetrics = metricDefinitions.filter(function (md) {
      return !defaultMetricDefinitionHash.includes("".concat(md.level, "_").concat(md.label));
    });
    var subcategories = [];
    var currentSubcat;
    for (var i = 0; i < customMetrics.length; i++) {
      // Start a new subcat and limit to 7 subcats
      if (i % 20 === 0 && i < 140) {
        if (currentSubcat) {
          subcategories.push(currentSubcat);
        }
        currentSubcat = {
          label: "Metric Builder".concat(i === 0 ? '' : " ".concat(subcategories.length + 1)),
          value: i === 0 ? 'index' : "mb".concat(subcategories.length + 1),
          modules: []
        };
      }
      var md = customMetrics[i];
      var customDataType = Ember.get(md, 'customDataType');
      var moduleProperties = {};
      if (customDataType === 'decimal') {
        moduleProperties.visualizationProperties = [{
          label: 'cull',
          value: 2
        }];
      }
      if (Ember.get(md, 'defaultValue') !== 'number') {
        moduleProperties.defaultBarView = 'niche-ranked';
      }
      currentSubcat.modules.pushObject({
        label: md.label,
        value: Ember.get(md, 'defaultValue') === 'number' ? 'numeric-visualization' : 'metric-visualization',
        metrics: [{
          cluster: md.label
        }],
        moduleProperties: moduleProperties
      });
    }
    if (!subcategories.includes(currentSubcat)) {
      subcategories.push(currentSubcat);
    }
    var metricCategory = {
      label: 'Metrics',
      value: 'metrics',
      subcategories: subcategories
    };
    baseSchema.insertAt(1, metricCategory);
    return baseSchema;
  }
  var updateExportSchema = _exports.updateExportSchema = function updateExportSchema(metricDefinition, selected, taxonomy, store) {
    var exportSchema = Ember.get(taxonomy, 'exportSchema');
    var metricLabel = Ember.get(metricDefinition, 'label');
    if (selected) {
      // Add
      var possibleExisting = exportSchema.filter(function (n) {
        return Ember.get(n, 'metrics').includes(metricLabel);
      });
      if (!possibleExisting.length) {
        var idNum = 0;
        var id = "".concat(Ember.get(taxonomy, 'id'), "_").concat(idNum);
        var recordUsingThisId = store.peekRecord('taxonomies/export-definition', id);
        while (recordUsingThisId) {
          idNum++;
          id = "".concat(Ember.get(taxonomy, 'id'), "_").concat(idNum);
          recordUsingThisId = store.peekRecord('taxonomies/export-definition', id);
        }
        var record = store.createRecord('taxonomies/export-definition', {
          id: id,
          metrics: [metricLabel]
        });
        exportSchema.pushObject(record);
      }
    } else {
      // Remove
      var _possibleExisting = exportSchema.filter(function (n) {
        return Ember.get(n, 'metrics').includes(metricLabel);
      });
      _possibleExisting.forEach(function (record) {
        exportSchema.removeObject(record);
        record.unloadRecord();
      });
    }
  };
  function dataSourceModelFromResponse(response, currentUserID, graph) {
    var dataSource;
    try {
      dataSource = JSON.parse(response.json);
    } catch (err) {
      dataSource = {
        accountID: response.accountId
      };
    }
    if (!dataSource.id) {
      dataSource.id = response.id;
    }
    if (!dataSource.label) {
      dataSource.label = response.label;
    }
    if (!dataSource.dataSetName) {
      dataSource.dataSetName = response.dataSetName;
    }
    if (!dataSource.columnsCount) {
      dataSource.columnsCount = response.columnsCount;
    }
    var canEdit = false;
    if (dataSource.accountID === currentUserID && (response.taxonomies ? response.taxonomies.length === 0 : true)) {
      canEdit = true;
    }
    dataSource.canEdit = canEdit;

    // Set ids on columns (relationship model)
    if (dataSource.columns) {
      dataSource.columns.forEach(function (c, index) {
        c.id = "".concat(dataSource.id, "_").concat(index);
      });
    }

    // get the data set id in there
    dataSource.dataSetId = response.dataSet && response.dataSet.id;
    return graph.recordFromGraphData(false, 'taxonomies/data-source', dataSource);
  }
  function taxoModelFromResponse(response, currentUserID, graph) {
    var noReports = response.canEdit;
    var taxonomy;
    try {
      taxonomy = JSON.parse(response.json);
    } catch (error) {
      taxonomy = Object.assign({
        meta: {
          label: response.label,
          dateCreated: new Date(response.dateCreated)
        },
        builder: {
          runnable: response.runnable
        }
      }, response);
    }
    return loadJsonTaxonomy(taxonomy, graph, {
      id: response.id,
      accountID: taxonomy.accountId,
      currentUserIsOwner: taxonomy.accountId === currentUserID,
      noReports: noReports,
      canEdit: taxonomy.accountId === currentUserID && noReports
    });
  }
});