define("affinio/templates/components/report-builder/number-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "rYQYRN9K",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"meetsAccountRequirements\"]]],null,{\"statements\":[[0,\"  \"],[14,1,[[22,0,[]]]],[0,\"\\n  \"],[1,[27,\"input\",null,[[\"type\",\"min\",\"max\",\"placeholder\",\"value\"],[\"number\",[23,[\"moduleSchema\",\"min\"]],[23,[\"max\"]],[23,[\"moduleSchema\",\"placeholder\"]],[27,\"get\",[[23,[\"model\"]],[23,[\"moduleSchema\",\"valueKey\"]]],null]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"moduleSchema\",\"showDivider\"]]],null,{\"statements\":[[0,\"    \"],[7,\"hr\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/report-builder/number-input.hbs"
    }
  });
});