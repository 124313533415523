define("affinio/components/affinio-table-cell/string", ["exports", "affinio/mixins/affinio-table-disabled", "affinio/utils/affinio", "affinio/utils/get-with-default"], function (_exports, _affinioTableDisabled, _affinio, _getWithDefault) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_affinioTableDisabled.default, {
    classNames: ['affinio-table-cell-string'],
    init: function init() {
      this._super.apply(this, arguments);
      var ignoreOn = Ember.get(this, 'column.ignoreOn');
      var ignoreString = ignoreOn ? ",".concat(ignoreOn) : '';
      var depString = "row.{".concat(Ember.get(this, 'column.valuePath')).concat(ignoreString, "}");
      Ember.defineProperty(this, 'isRequired', Ember.computed('column.{uniqValues.[],illegalRegex}', 'row.{alias,aggregatable}', depString, function () {
        var _this = this;
        // allow specifying a field to ignore when
        if (Ember.get(this, 'column.ignoreOn') && Ember.get(this, "row.".concat(Ember.get(this, 'column.ignoreOn')))) {
          return false;
        }
        var alias = (0, _getWithDefault.default)(this, 'row.alias', '').toLowerCase();
        if (Ember.get(this, 'row.aggregatable') && _affinio.badNames.includes(alias)) {
          return true;
        }
        if (alias === 'identifier') {
          return true;
        }
        if (Ember.get(this, 'column.illegalRegex')) {
          if ((0, _getWithDefault.default)(this, 'row.alias', '').match(Ember.get(this, 'column.illegalRegex'))) {
            return true;
          }
        }
        if (Ember.get(this, 'column.uniqValues')) {
          return Ember.get(this, 'column.uniqValues').filter(function (uv) {
            if (Ember.get(_this, 'row.alias')) {
              return uv === Ember.get(_this, 'row.alias');
            } else {
              return uv === Ember.get(_this, 'row.label');
            }
          }).length > 1;
        } else {
          return false;
        }
      }));
    }
  });
});