define("affinio/components/old/report-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    actions: {
      addActionWrapper: function addActionWrapper(currentTerm) {
        var addAction = Ember.get(this, 'addAction');
        if (addAction && typeof addAction === 'function') {
          addAction(currentTerm);
        }
        Ember.set(this, 'currentTerm', null);
      }
    }
  });
});