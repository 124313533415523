define("affinio/templates/components/modules/translate-dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ngXfWzMR",
    "block": "{\"symbols\":[\"language\",\"&default\"],\"statements\":[[14,2,[[22,0,[]]]],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"page-title\"],[9],[0,\"\\n  \"],[7,\"p\"],[11,\"class\",\"helper\"],[9],[0,\"\\n    Affinio offers the ability to translate the Content section keywords to one of ten supported languages. Select the desired language from the drop-down menu below to begin.\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"select\"],[11,\"class\",\"report-translation-selector\"],[12,\"onchange\",[27,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"translationCode\"]]],null]],[[\"value\"],[\"target.value\"]]]],[9],[0,\"\\n  \"],[7,\"option\"],[11,\"value\",\"\"],[9],[0,\"Don't Translate\"],[10],[0,\"\\n\"],[4,\"each\",[[23,[\"expandedLanguages\"]]],null,{\"statements\":[[0,\"    \"],[7,\"option\"],[12,\"value\",[22,1,[\"label\"]]],[9],[1,[22,1,[\"icon\"]],false],[0,\" \"],[1,[22,1,[\"expanded\"]],false],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"],[7,\"p\"],[11,\"class\",\"helper\"],[9],[0,\"We'll automatically translate the content of this report regardless of the original language.\"],[10],[0,\"\\n\\n\"],[7,\"footer\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"translationCode\"]]],null,{\"statements\":[[0,\"    \"],[7,\"a\"],[11,\"class\",\"button primary\"],[11,\"role\",\"button\"],[9],[0,\"\\n      \"],[1,[27,\"if\",[[23,[\"saving\"]],\"Creating Translated Report...\",\"Translate Report\"],null],false],[0,\"\\n    \"],[3,\"action\",[[22,0,[]],\"translateReport\",[23,[\"translationCode\"]]]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/modules/translate-dashboard.hbs"
    }
  });
});