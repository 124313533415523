define("affinio/mixins/affinio-table-disabled", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  var _default = _exports.default = Ember.Mixin.create({
    // TODO this may need to be done in dRA if it doesn't work when changing tables
    init: function init() {
      this._super.apply(this, arguments);
      var enableOn = Ember.get(this, 'column.enableOn');
      var disableOn = Ember.get(this, 'column.disableOn');
      if (enableOn || disableOn) {
        var _Ember;
        var rowKeys = [];
        [enableOn, disableOn].forEach(function (type) {
          if (type && Ember.typeOf(type) === 'array') {
            rowKeys.push.apply(rowKeys, _toConsumableArray(type));
          } else if (type && Ember.typeOf(type) === 'string') {
            rowKeys.push(type);
          }
        });
        var dynamicKeys = ['column', 'row'];
        if (rowKeys.length) {
          if (rowKeys.length > 1) {
            dynamicKeys.push("row.{".concat(rowKeys, "}"));
          } else {
            dynamicKeys.push("row.".concat(rowKeys));
          }
        }
        Ember.defineProperty(this, 'disabled', (_Ember = Ember).computed.apply(_Ember, dynamicKeys.concat([function () {
          var disabledFromDisableOn = false;
          var disabledFromEnableOn = false;
          var row = Ember.get(this, 'row');
          var column = Ember.get(this, 'column');
          var disableOn = Ember.get(column, 'disableOn');
          if (disableOn) {
            if (Ember.typeOf(disableOn) === 'array') {
              disabledFromDisableOn = disableOn.some(function (n) {
                return Ember.get(row, n);
              });
            } else if (Ember.typeOf(disableOn) === 'string') {
              disabledFromDisableOn = Ember.get(row, disableOn);
            }
          }
          var enableOn = Ember.get(column, 'enableOn');
          if (enableOn) {
            if (Ember.typeOf(enableOn) === 'array') {
              disabledFromEnableOn = !enableOn.every(function (n) {
                return Ember.get(row, n);
              });
            } else if (Ember.typeOf(enableOn) === 'string') {
              disabledFromEnableOn = !Ember.get(row, enableOn);
            }
          }
          return disabledFromDisableOn || disabledFromEnableOn;
        }])));
      } else {
        Ember.defineProperty(this, 'disabled', undefined, false);
      }
    }
  });
});