define("affinio/components/report-builder/number-input", ["exports", "affinio/mixins/input-module", "affinio/utils/get-with-default", "affinio/mixins/report-builder-account-requirements"], function (_exports, _inputModule, _getWithDefault, _reportBuilderAccountRequirements) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_inputModule.default, _reportBuilderAccountRequirements.default, {
    classNames: ['number-input'],
    max: Ember.computed('controller.{activeNetwork,activeNetwork.types.0.stages.1.modules.max}', function () {
      if (Ember.get(this, 'moduleSchema.valueKey') === 'numberOfClusters') {
        // Custom breaking out of estimate max
        if (Ember.get(this, 'controller.activeNetwork.isCustom')) {
          return (0, _getWithDefault.default)(this, 'controller.activeNetwork.types.0.stages.1.modules.max', Ember.get(this, 'moduleSchema.max'));
        }
        if (Ember.get(this, 'model.estimateTotal') < 500) {
          return 8;
        } else {
          return Ember.get(this, 'moduleSchema.max');
        }
      } else {
        return Ember.get(this, 'moduleSchema.max');
      }
    })
  });
});