define("affinio/services/error-handler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var genericTitle = 'Failed login request';
  var genericReason = 'We\'ve encountered a problem.';
  var genericFix = 'Please try again. If the problem persists, contact your account representative or reach out to our support team';
  var _default = _exports.default = Ember.Service.extend({
    modalManager: Ember.inject.service(),
    auth: Ember.inject.service(),
    user: Ember.inject.service(),
    router: Ember.inject.service(),
    path: Ember.inject.service(),
    api: Ember.inject.service(),
    tracker: Ember.inject.service(),
    handleAffinioError: function handleAffinioError(affinioErrorCode) {
      switch (affinioErrorCode) {
        case 102:
          // too many unsuccessful attempts
          Ember.get(this, 'modalManager').openModal('modals/modal-error', {
            title: genericTitle,
            reason: genericReason,
            fix: 'If you\'ve entered a valid username, you should receive an email to reset your password. If the problem persists, contact your account representative or reach out to our support team',
            hideSupport: true
          });
          Ember.get(this, 'tracker').track('Login Failed', {
            reasonForLoginFailed: 'too many unsuccessful attempts'
          });
          break;
        case 103:
          // company disabled
          Ember.get(this, 'modalManager').openModal('modals/modal-error', {
            title: genericTitle,
            reason: genericReason,
            fix: genericFix,
            hideSupport: true
          });
          Ember.get(this, 'tracker').track('Login Failed', {
            reasonForLoginFailed: 'company disabled'
          });
          break;
        case 104:
          // account is disabled
          Ember.get(this, 'modalManager').openModal('modals/modal-error', {
            title: genericTitle,
            reason: genericReason,
            fix: genericFix,
            hideSupport: true
          });
          Ember.get(this, 'tracker').track('Login Failed', {
            reasonForLoginFailed: 'account is disabled'
          });
          break;
        case 105:
          // trial not started
          Ember.get(this, 'modalManager').openModal('modals/modal-error', {
            title: genericTitle,
            reason: genericReason,
            fix: genericFix,
            hideSupport: true
          });
          Ember.get(this, 'tracker').track('Login Failed', {
            reasonForLoginFailed: 'trial not started'
          });
          break;
        case 106:
          // trial over
          Ember.get(this, 'modalManager').openModal('modals/modal-error', {
            title: genericTitle,
            reason: genericReason,
            fix: genericFix,
            hideSupport: true
          });
          Ember.get(this, 'tracker').track('Login Failed', {
            reasonForLoginFailed: 'trial over'
          });
          break;
        case 107:
          // jwt is no longer valid
          if (Ember.get(this, 'auth.loggedIn')) {
            Ember.get(this, 'auth').openModal(true);
          } else {
            Ember.get(this, 'router').transitionTo('/');
          }
          break;
        case 108:
          // jwt is invalid
          if (Ember.get(this, 'auth.loggedIn')) {
            Ember.get(this, 'auth').openModal(true);
          } else {
            Ember.get(this, 'router').transitionTo('/');
          }
          break;
        case 111:
          // no or invalid token
          if (Ember.get(this, 'auth.loggedIn')) {
            Ember.get(this, 'auth').openModal(true);
          } else {
            Ember.get(this, 'router').transitionTo('/');
          }
          break;
        case 112:
          // error validating ms account
          Ember.get(this, 'modalManager').openModal('modals/modal-error', {
            title: genericTitle,
            reason: 'Failed microsoft sign-in.',
            fix: 'If the problem persists, contact your account representative or reach out to our support team',
            hideSupport: true
          });
          break;
        case 113:
          // no matching ms account
          Ember.get(this, 'modalManager').openModal('modals/modal-error', {
            title: genericTitle,
            reason: 'No account was found matching these Microsoft credentials.',
            fix: 'If the problem persists, please contact our support team',
            hideSupport: true
          });
          break;
        case 116:
          // SSO login only
          Ember.get(this, 'modalManager').openModal('modals/modal-error', {
            title: genericTitle,
            reason: 'Regular sign-in is disabled for your company, please use the Microsoft sign-in option.',
            fix: 'If the problem persists, please contact our support team',
            hideSupport: true
          });
          break;
      }
      this.persistError(affinioErrorCode, 'Affinio Error', null);
    },
    persistError: function persistError(error, type, transition) {
      var transitionDetails;
      if (transition) {
        transitionDetails = {
          intent: transition.intent,
          params: transition.params,
          queryParams: transition.queryParams,
          targetName: transition.targetName
        };
      }
      var path = {
        currentPath: Ember.get(this, 'path.currentPath'),
        currentCategory: Ember.get(this, 'path.currentCategory'),
        currentSubcategory: Ember.get(this, 'path.currentSubcategory'),
        currentReportId: Ember.get(this, 'path.currentReportId'),
        currentVersion: Ember.get(this, 'path.currentVersion'),
        currentClusterId: Ember.get(this, 'path.currentClusterId'),
        currentClusterSuffix: Ember.get(this, 'path.currentClusterSuffix'),
        currentClusterIndex: Ember.get(this, 'path.currentClusterIndex')
      };
      try {
        Ember.get(this, 'tracker').track('Global Error', {
          type: type,
          error: JSON.stringify(error),
          transition: JSON.stringify(transitionDetails),
          path: JSON.stringify(path),
          href: window.location.href
        });
      } catch (e) {
        Ember.get(this, 'tracker').track('Global Error', {
          type: type,
          error: error,
          transition: transitionDetails,
          path: JSON.stringify(path),
          href: window.location.href
        });
      }
    }
  });
});