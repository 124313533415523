define("affinio/components/modules/cluster-modifier", ["exports", "affinio/utils/mutations", "affinio/utils/queries"], function (_exports, _mutations, _queries) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Element
    classNames: ['cluster-modifier'],
    // Services
    graph: Ember.inject.service(),
    tracker: Ember.inject.service(),
    modalManager: Ember.inject.service(),
    // Lifecycle
    didReceiveAttrs: function didReceiveAttrs() {
      var clusterFromNav = Ember.get(this, 'model.report._clusters').findBy('fromClusterNav');
      if (clusterFromNav) {
        Ember.set(this, 'activeTab', Ember.get(clusterFromNav, 'fromClusterNav') === 'merge' ? Ember.get(this, 'tabs.0.value') : Ember.get(this, 'tabs.1.value'));
        Ember.set(clusterFromNav, 'fromClusterNav', null);
      }
      // it appears we switch clusters each time we select the checkbox
      // thus firing dRA as the model has changed
      if (!Ember.get(this, 'activeTab')) {
        Ember.set(this, 'activeTab', Ember.get(this, 'tabs.0.value'));
      }
    },
    watchClusters: Ember.observer('model.report._clusters.@each.fromClusterNav', function () {
      var clusterFromNav = Ember.get(this, 'model.report._clusters').findBy('fromClusterNav');
      if (clusterFromNav) {
        Ember.set(this, 'activeTab', Ember.get(clusterFromNav, 'fromClusterNav') === 'merge' ? Ember.get(this, 'tabs.0.value') : Ember.get(this, 'tabs.1.value'));
        Ember.set(clusterFromNav, 'fromClusterNav', null);
      }
    }),
    watchSelection: Ember.observer('model.report._clusters.@each.selected', 'parentCluster', function () {
      var parent = Ember.get(this, 'parentCluster');
      if (parent) {
        if (!Ember.get(this, 'selectedClusters').includes(parent)) {
          Ember.set(this, 'parentCluster', null);
        }
      }
    }),
    // Properties
    activeTab: null,
    parentCluster: null,
    // Computed
    tabs: Ember.computed('removedClusters.[]', 'mergedClusters.[]', function () {
      return [{
        label: 'Merge Cluster',
        value: 'Merge Cluster'
      }, {
        label: 'Remove Cluster',
        value: 'Remove Cluster'
      }, Ember.get(this, 'removedClusters.length') || Ember.get(this, 'mergedClusters.length') ? {
        label: 'Reset Clusters',
        value: 'Reset Clusters'
      } : null].compact();
    }),
    isMergeCluster: Ember.computed.equal('activeTab', 'Merge Cluster'),
    isRemoveCluster: Ember.computed.equal('activeTab', 'Remove Cluster'),
    isResetClusters: Ember.computed.equal('activeTab', 'Reset Clusters'),
    selectedClusters: Ember.computed.filterBy('model.report._clusters', 'selected'),
    twoClustersSelected: Ember.computed.gt('selectedClusters.length', 1),
    allClustersSelected: Ember.computed('selectedClusters.length', 'model.report.clusters.length', function () {
      return Ember.get(this, 'model.report.clusters.length') === Ember.get(this, 'selectedClusters.length');
    }),
    removedClusters: Ember.computed.filterBy('model.report._clusters', 'isClusterRemoved'),
    mergedClusters: Ember.computed.filterBy('model.report._clusters', 'mergedInto'),
    makeUpdateRequest: function makeUpdateRequest(clusters) {
      return Ember.get(this, 'graph').mutate(_mutations.default.updateClusters(Ember.get(this, 'model.report.id'), clusters));
    },
    actions: {
      mergeClusters: function mergeClusters() {
        var _this = this;
        var modalManager = Ember.get(this, 'modalManager');
        modalManager.openModal('modals/modal-boolean', {
          question: 'Are you sure you want to update your report? This may take up to 5 minutes and the report will not be eligible for monitored updates.',
          okText: 'Merge Clusters',
          okAction: function okAction() {
            var tracker = Ember.get(_this, 'tracker');
            var request = Ember.get(_this, 'selectedClusters').map(function (n) {
              return {
                id: n.id,
                mergedInto: Ember.get(_this, 'parentCluster.id')
              };
            }).filter(function (n) {
              return n.id !== n.mergedInto;
            });
            _this.makeUpdateRequest(request).then(function () {
              return Ember.get(_this, 'graph').query(_queries.default.getReportState(Ember.get(_this, 'model.report.id')));
            }).then(function () {
              modalManager.openModal('modals/modal-success', {
                title: 'Report is updating',
                message: 'Your request to merge clusters is processing. You will receive an email when your report is ready.',
                okAction: true,
                okText: 'Got it',
                closeAfter: 5000
              });
              Ember.get(_this, 'model.report._clusters').setEach('selected', false);
            }).then(function /* response */
            () {
              var clusters = request.map(function (cluster) {
                return cluster.id;
              });
              tracker.track('requested to merge clusters', {
                report: Ember.get(_this, 'model.report.id'),
                clusters: clusters,
                numberOfClusters: clusters.length,
                parentCluster: request[0].mergedInto
              });
            }).catch(function /* response */
            () {
              var clusters = request.map(function (cluster) {
                return cluster.id;
              });
              tracker.track('failed to request to merge clusters', {
                report: Ember.get(_this, 'model.report.id'),
                clusters: clusters,
                numberOfClusters: clusters.length,
                parentCluster: request[0].mergedInto
              });
              Ember.get(_this, 'notify').error('Failed to merge your clusters.');
            });
          }
        });
      },
      removeClusters: function removeClusters() {
        var _this2 = this;
        var modalManager = Ember.get(this, 'modalManager');
        modalManager.openModal('modals/modal-boolean', {
          question: 'Are you sure you want to update your report? This may take up to 5 minutes and the report will not be eligible for monitored updates.',
          okText: 'Remove Clusters',
          okAction: function okAction() {
            var tracker = Ember.get(_this2, 'tracker');
            var request = Ember.get(_this2, 'selectedClusters').map(function (n) {
              return {
                id: n.id,
                isRemoved: true
              };
            });
            _this2.makeUpdateRequest(request).then(function () {
              return Ember.get(_this2, 'graph').query(_queries.default.getReportState(Ember.get(_this2, 'model.report.id')));
            }).then(function () {
              Ember.get(_this2, 'modalManager').openModal('modals/modal-success', {
                title: 'Report is updating',
                message: 'Your request to remove clusters is processing. You will receive an email when your report is ready.',
                okAction: true,
                okText: 'Got it',
                closeAfter: 5000
              });
              Ember.get(_this2, 'model.report._clusters').setEach('selected', false);
            }).then(function /* response */
            () {
              var clusters = request.map(function (cluster) {
                return cluster.id;
              });
              tracker.track('requested to remove clusters', {
                report: Ember.get(_this2, 'model.report.id'),
                clusters: clusters,
                numberOfClusters: clusters.length
              });
            }).catch(function /* response */
            () {
              var clusters = request.map(function (cluster) {
                return cluster.id;
              });
              tracker.track('failed to request remove clusters', {
                report: Ember.get(_this2, 'model.report.id'),
                clusters: clusters,
                numberOfClusters: clusters.length
              });
              Ember.get(_this2, 'modalManager').openModal('modals/modal-error', {
                title: 'Failed to remove cluster',
                reason: "Sorry, you cannot delete previously merged clusters without unmerging them first using the 'Reset' option",
                hideSupport: true
              });
            });
          }
        });
      },
      resetClusters: function resetClusters(merged, removed) {
        var _this3 = this;
        var modalManager = Ember.get(this, 'modalManager');
        modalManager.openModal('modals/modal-boolean', {
          question: 'Are you sure you want to reset your report clusters? This may take up to 5 minutes.',
          okText: 'Reset Clusters',
          okAction: function okAction() {
            var tracker = Ember.get(_this3, 'tracker');
            _this3.makeUpdateRequest(Ember.get(_this3, 'model.report._clusters').map(function (n) {
              return {
                id: n.id,
                mergedInto: merged ? null : n.mergedInto,
                isRemoved: removed ? false : n.isClusterRemoved
              };
            })).then(function () {
              return Ember.get(_this3, 'graph').query(_queries.default.getReportState(Ember.get(_this3, 'model.report.id')));
            }).then(function () {
              Ember.get(_this3, 'modalManager').openModal('modals/modal-success', {
                title: 'Report is updating',
                message: 'Your request to edit clusters is processing. You will receive an email when your report is ready.',
                okAction: true,
                okText: 'Got it',
                closeAfter: 5000
              });
              Ember.get(_this3, 'model.report._clusters').setEach('selected', false);
            }).then(function () {
              tracker.track('requested to reset clusters', {
                report: Ember.get(_this3, 'model.report.id'),
                resetMerges: merged,
                resetRemovals: removed
              });
            }).catch(function () {
              tracker.track('failed request to reset clusters', {
                report: Ember.get(_this3, 'model.report.id'),
                resetMerges: merged,
                resetRemovals: removed
              });
            });
          }
        });
      }
    }
  });
});