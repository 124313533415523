define("affinio/components/modals/modal-notes", ["exports", "affinio/components/modals/modal-base", "moment", "affinio/utils/constants/date-formats", "affinio/utils/get-with-default"], function (_exports, _modalBase, _moment, _dateFormats, _getWithDefault) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _modalBase.default.extend({
    user: Ember.inject.service(),
    tracker: Ember.inject.service(),
    store: Ember.inject.service(),
    classNames: ['report-notes'],
    maxLength: 1500,
    message: '',
    accentStlye: Ember.computed('tribe_color', function () {
      return Ember.String.htmlSafe("border-left: 7px solid ".concat(Ember.get(this, 'clusterColor'), ";"));
    }),
    placeholderSafe: Ember.computed('placeholder', function () {
      return Ember.String.htmlSafe(Ember.get(this, 'placeholder'));
    }),
    charsLeft: Ember.computed('inputValue.length', function () {
      return Ember.get(this, 'inputValue') ? Ember.get(this, 'maxLength') - Ember.get(this, 'inputValue.length') : 1500;
    }),
    lastEditor: Ember.computed('notes.updatedByID', function () {
      if (Ember.get(this, 'notes.updatedByID')) {
        if (Ember.get(this, 'store').peekRecord('account', Ember.get(this, 'notes.updatedByID'))) {
          var user = Ember.get(this, 'store').peekRecord('account', Ember.get(this, 'notes.updatedByID'));
          return Ember.get(user, 'name');
        } else {
          return 'Unknown';
        }
      }
    }),
    isThereEdits: Ember.computed('notes.text.length', 'inputValue.length', function () {
      return (0, _getWithDefault.default)(this, 'notes.text', '').length !== (0, _getWithDefault.default)(this, 'inputValue', '').length;
    }),
    date: Ember.computed('notes.dateUpdated', function () {
      return (0, _moment.default)(Ember.get(this, 'notes.dateUpdated')).format(_dateFormats.default.fullMonthWithTime);
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.$('textarea').focus();
    },
    actions: {
      ok: function ok() {
        if (Ember.get(this, 'inputValue.length') > Ember.get(this, 'maxLength')) {
          Ember.set(this, 'message', 'Too many characters to save');
          return;
        }
        var okAction = Ember.get(this, 'okAction');
        var input = Ember.get(this, 'inputValue');
        if (!okAction) {
          return;
        }
        if (typeof okAction === 'function') {
          okAction(input);
        }
        if (Ember.get(this, 'saveComing')) {
          Ember.set(this, 'saving', true);
        } else {
          this.closeAction();
        }
      }
    }
  });
});