define("affinio/models/taxonomies/export-definition", ["exports", "ember-data", "affinio/utils/data/array-attr"], function (_exports, _emberData, _arrayAttr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var Model = _emberData.default.Model;
  var _default = _exports.default = Model.extend({
    // Required
    metrics: _arrayAttr.default,
    // Optional
    exportType: attr('string', {
      defaultValue: 'standard'
    }),
    label: attr('string'),
    aggregation: attr('string', {
      defaultValue: 'top'
    }),
    unaggregateable: attr('boolean'),
    aggregateOnly: attr('boolean'),
    clusterOnly: attr('boolean'),
    exportLabelAlias: attr('string'),
    exportValueAlias: attr('string'),
    uniq: attr('string', {
      defaultValue: 'all'
    }),
    uniqable: Ember.computed('exportType', function () {
      return ['interest'].includes(Ember.get(this, 'exportType'));
    })
  });
});