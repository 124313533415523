define("affinio/utils/url-parser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.matchUrl = _exports.default = void 0;
  var matchUrl = _exports.matchUrl = function matchUrl(link, site) {
    if (site && link.label) {
      var url;
      // Add protocol to url if does not have one, otherwise new URL will error
      // new URL breaks down site into url parts
      // There may be a cleaner way to parse, but this works for all edge cases tested.
      if (!site.startsWith('http')) {
        url = new URL("http://".concat(site));
      } else {
        url = new URL(site);
      }
      // extracts the domain (removes www. if present)
      var domain = url.hostname.split('.').slice(-2).join('.');
      return link.label.indexOf(domain) > -1;
    } else {
      return false;
    }
  };
  var _default = _exports.default = {
    matchUrl: matchUrl
  };
});