define("affinio/templates/components/affinio-table-cell/joined-source", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "s1rQLskp",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"value\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"info\"],[9],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"label\"],[9],[1,[23,[\"value\",\"externalSourceLabel\"]],false],[10],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"label\"],[9],[1,[27,\"font-awesome\",[\"code-fork\"],null],false],[0,\": \"],[1,[23,[\"value\",\"externalLabel\"]],false],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"actions\"],[9],[0,\"\\n    \"],[7,\"span\"],[11,\"role\",\"button\"],[9],[1,[27,\"font-awesome\",[\"pencil\"],null],false],[3,\"action\",[[22,0,[]],[23,[\"showJoinSourceModal\"]],[23,[\"row\"]]]],[10],[0,\"\\n    \"],[7,\"span\"],[11,\"role\",\"button\"],[9],[1,[27,\"font-awesome\",[\"trash\"],null],false],[3,\"action\",[[22,0,[]],[23,[\"removeJoinedSource\"]],[23,[\"row\"]]]],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"span\"],[11,\"class\",\"join-action\"],[11,\"title\",\"Join Another Data Source\"],[11,\"role\",\"button\"],[9],[0,\"\\n    \"],[1,[27,\"font-awesome\",[\"code-fork\"],null],false],[0,\" Join Data Source\\n  \"],[3,\"action\",[[22,0,[]],[23,[\"showJoinSourceModal\"]],[23,[\"row\"]]]],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/affinio-table-cell/joined-source.hbs"
    }
  });
});