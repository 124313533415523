define("affinio/utils/queries/integrations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getTalkWalkerSourcePanels = _exports.getTalkWalkerSourcePanelAuthors = _exports.getTalkWalkerProjects = _exports.default = void 0;
  var getTalkWalkerProjects = _exports.getTalkWalkerProjects = function getTalkWalkerProjects() {
    return {
      queryString: "query {\n        getTalkWalkerProjects {\n          id\n          name\n        }\n      }",
      queryMap: {
        getTalkWalkerProjects: {
          array: true,
          modelName: 'integrations/talkwalker-project',
          responseKey: 'getTalkWalkerProjects'
        }
      }
    };
  };
  var getTalkWalkerSourcePanels = _exports.getTalkWalkerSourcePanels = function getTalkWalkerSourcePanels(projectId) {
    return {
      queryString: "query {\n      getTalkWalkerSourcePanels(projectId:\"".concat(projectId, "\"){\n        id\n        title\n        description\n        size\n      }\n    }"),
      queryMap: {
        getTalkWalkerSourcePanels: {
          array: true,
          modelName: 'integrations/talkwalker-source-panel',
          responseKey: 'getTalkWalkerSourcePanels'
        }
      }
    };
  };
  var getTalkWalkerSourcePanelAuthors = _exports.getTalkWalkerSourcePanelAuthors = function getTalkWalkerSourcePanelAuthors(projectId, sourcePanelIDs) {
    return {
      queryString: "query {\n      getTalkWalkerSourcePanelAuthors(\n          projectId:\"".concat(projectId, "\"\n          sourcePanelIDs: [").concat(sourcePanelIDs.map(function (id) {
        return "\"".concat(id, "\"");
      }).join(', '), "]\n        )\n    }")
    };
  };
  var _default = _exports.default = {
    getTalkWalkerProjects: getTalkWalkerProjects,
    getTalkWalkerSourcePanels: getTalkWalkerSourcePanels,
    getTalkWalkerSourcePanelAuthors: getTalkWalkerSourcePanelAuthors
  };
});