define("affinio/templates/components/hover-tip-two", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "jhx+KGff",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"a\"],[9],[0,\"?\"],[10],[0,\"\\n\"],[5,\"ember-popover\",[],[[\"@event\",\"@effect\",\"@side\",\"@popoverHideDelay\"],[\"hover\",\"fade\",\"bottom\",500]],{\"statements\":[[0,\"\\n  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/hover-tip-two.hbs"
    }
  });
});