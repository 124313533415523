define("affinio/components/twitter-stream", ["exports", "affinio/utils/affinio"], function (_exports, _affinio) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var bufferDistance = 800;
  function onScroll(event) {
    Ember.run.debounce(event.data, Ember.get(event.data, 'scrolled'), 500);
  }
  var _default = _exports.default = Ember.Component.extend({
    // Element
    classNames: ['twitter-stream'],
    tagName: 'div',
    init: function init() {
      this._super.apply(this, arguments);
      Ember.setProperties(this, {
        loadedTweets: [],
        metrics: []
      });
    },
    // Properties
    loaded: 0,
    loading: true,
    lazyLoad: false,
    initialLazySize: null,
    lazySize: 10,
    scrollSelector: window,
    tweets: Ember.computed('metrics', function () {
      return Ember.get(this, 'metrics').map(function (interest) {
        if (interest.lastPost) {
          return {
            id: interest.lastPost.id,
            dateCreated: interest.lastPost.dateCreated
          };
        }
      }).sortBy('dateCreated').reverse().compact();
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;
      Ember.set(this, 'loading', true);
      Ember.set(this, 'loaded', 0);
      var initialSize = Ember.get(this, 'initialLazySize') || Ember.get(this, 'lazySize');
      (0, _affinio.maybeRunLater)(this, function () {
        Ember.set(_this, 'loadedTweets', Ember.get(_this, 'lazyLoad') ? Ember.get(_this, 'tweets').slice(0, initialSize) : Ember.get(_this, 'tweets'));
      }, 300);
    },
    didInsertElement: function didInsertElement() {
      if (Ember.get(this, 'lazyLoad')) {
        Ember.$(Ember.get(this, 'scrollSelector')).on('scroll', this, onScroll);
      }
    },
    willDestroyElement: function willDestroyElement() {
      if (Ember.get(this, 'lazyLoad')) {
        Ember.$(Ember.get(this, 'scrollSelector')).off('scroll', onScroll);
      }
    },
    scrolled: function scrolled() {
      // console.log('scroll');

      var loadedLength = Ember.get(this, 'loadedTweets.length');
      if (Ember.get(this, 'loading') && Ember.get(this, 'loaded') >= loadedLength) {
        var boxOffset, boxHeight;
        var scrollSelector = Ember.get(this, 'scrollSelector');
        var scrollElement = Ember.$(scrollSelector);
        var bottomLoaderOffset = this.$('.loader-bottom').offset();
        boxOffset = scrollSelector === window ? {
          top: scrollElement.scrollTop(),
          left: scrollElement.scrollLeft()
        } : scrollElement.offset();
        boxHeight = scrollElement.innerHeight();
        if (boxHeight + boxOffset.top + bufferDistance >= bottomLoaderOffset.top) {
          // console.log('Load some more tweets');
          var tweetsToLoad = [];
          for (var i = loadedLength; i < loadedLength + Ember.get(this, 'lazySize'); i++) {
            tweetsToLoad.push(Ember.get(this, 'tweets').objectAt(i));
          }
          Ember.get(this, 'loadedTweets').pushObjects(tweetsToLoad);
        }
      }
    },
    actions: {
      tweetLoaded: function tweetLoaded() {
        var _this2 = this;
        (0, _affinio.maybeRunLater)(this, function () {
          var loaded = _this2.incrementProperty('loaded');
          // console.log('loaded tweet:', loaded, '/', get(this, 'tweets.length'));
          if (loaded >= Ember.get(_this2, 'tweets.length')) {
            Ember.set(_this2, 'loading', false);
          }
        }, 0);
      }
    }
  });
});