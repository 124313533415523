define("affinio/templates/components/tabbed-interface", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "OzSU6s3j",
    "block": "{\"symbols\":[\"tab\",\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"tabs\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"tabs\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[12,\"class\",[28,[\"tab \",[27,\"if\",[[27,\"eq\",[[23,[\"activeTab\"]],[22,1,[\"value\"]]],null],\"active\"],null]]]],[11,\"role\",\"button\"],[9],[0,\"\\n      \"],[1,[22,1,[\"label\"]],false],[0,\"\\n\"],[4,\"if\",[[23,[\"removeTab\"]]],null,{\"statements\":[[0,\"        \"],[7,\"span\"],[11,\"class\",\"remove\"],[11,\"role\",\"button\"],[9],[0,\"×\"],[3,\"action\",[[22,0,[]],[23,[\"removeTab\"]],[22,1,[\"value\"]]]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[3,\"action\",[[22,0,[]],[27,\"optional\",[[23,[\"selectTab\"]]],null],[22,1,[\"value\"]]]],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"createTab\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"tab new\"],[11,\"role\",\"button\"],[9],[0,\"+\"],[3,\"action\",[[22,0,[]],[23,[\"createTab\"]]]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"content\"],[9],[0,\"\\n  \"],[14,2],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/tabbed-interface.hbs"
    }
  });
});