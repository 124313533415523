define("affinio/components/asset-group-picker", ["exports", "affinio/utils/auto-suggest"], function (_exports, _autoSuggest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  var _default = _exports.default = Ember.Component.extend({
    // Element
    classNames: ['asset-group-picker'],
    tagName: 'div',
    // Services
    user: Ember.inject.service(),
    graph: Ember.inject.service(),
    // Properties
    autoSuggestInput: '',
    twEndpoint: (0, _autoSuggest.twitterHandlesEndpoint)({
      inputKey: 'autoSuggestInput'
    }),
    // Computed Properties
    isTwitter: Ember.computed.equal('socialNetwork', 'Twitter'),
    personalAssets: Ember.computed('type', 'user.company.assetGroups.[]', 'socialNetwork', function () {
      var _this = this;
      var assets = Ember.get(this, 'user.account.assetGroups');
      return assets ? assets.toArray().filter(function (n) {
        return Ember.get(n, 'assetType') === Ember.get(_this, 'type') && Ember.get(n, 'assets.length') && Ember.get(n, 'socialNetwork') === Ember.get(_this, 'socialNetwork');
      }).uniq() : [];
    }),
    companyAssets: Ember.computed('type', 'user.company.assetGroups.[]', 'socialNetwork', function () {
      var _this2 = this;
      var assets = Ember.get(this, 'user.company.assetGroups');
      return assets ? assets.toArray().filter(function (n) {
        return Ember.get(n, 'assetType') === Ember.get(_this2, 'type') && Ember.get(n, 'assets.length') && Ember.get(n, 'socialNetwork') === Ember.get(_this2, 'socialNetwork');
      }).uniq() : [];
    }),
    assetGroups: Ember.computed('companyAssets.[]', 'personalAssets.[]', function () {
      return [].concat(_toConsumableArray(Ember.get(this, 'personalAssets')), _toConsumableArray(Ember.get(this, 'companyAssets')));
    }),
    // Observers
    showIndividualOnNone: Ember.observer('companyAssets.[]', 'personalAssets.[]', function () {
      var _this3 = this;
      if (!Ember.get(this, 'personalAssets.length') && !Ember.get(this, 'companyAssets.length')) {
        Ember.run.scheduleOnce('afterRender', function () {
          return Ember.set(_this3, 'individual', true);
        });
      }
    }).on('init'),
    actions: {
      addAssetGroup: function addAssetGroup(assetGroup) {
        if (!Ember.get(this, 'addingGroup')) {
          Ember.set(this, 'addingGroup', true);
          Ember.get(this, 'addAction')(Ember.get(assetGroup, 'assets'));
          Ember.set(this, 'addingGroup', false);
        }
      },
      addAdditionalAsset: function addAdditionalAsset(asset) {
        if (!Ember.get(this, 'addingAsset') && asset) {
          Ember.set(this, 'addingAsset', true);
          var name = Ember.get(this, 'isTwitter') ? asset.label.replace('@', '').replace(/ /gi, '') : asset.label;
          Ember.get(this, 'addAction')([{
            name: name,
            id: asset.id
          }]);
          Ember.set(this, 'inlineAsset', null);
          Ember.set(this, 'addingAsset', false);
        }
      },
      foldGroup: function foldGroup(group) {
        Ember.set(group, 'folded', !Ember.get(group, 'folded'));
      }
    }
  });
});