define("affinio/components/download-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Element
    tagName: '',
    href: Ember.computed('controller.{forceUpdate,svgURI}', 'hrefKey', function () {
      var href = Ember.get(this, "controller.".concat(Ember.get(this, 'hrefKey')));
      return href ? Ember.String.htmlSafe(href) : null;
    }),
    download: Ember.computed('controller.forceUpdate', 'downloadKey', function () {
      return Ember.String.htmlSafe(Ember.get(this, "controller.".concat(Ember.get(this, 'downloadKey'))));
    })
  });
});