define("affinio/adapters/application", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Adapter = _emberData.default.Adapter;
  var emptyPromise = function emptyPromise() {
    return new Ember.RSVP.Promise(function (resolve) {
      return resolve();
    });
  };
  var _default = _exports.default = Adapter.extend({
    findRecord: function findRecord() {
      return emptyPromise();
    },
    createRecord: function createRecord() {
      return emptyPromise();
    },
    updateRecord: function updateRecord() {
      return emptyPromise();
    },
    deleteRecord: function deleteRecord() {
      return emptyPromise();
    },
    findAll: function findAll() {
      return emptyPromise();
    },
    query: function query() {
      return emptyPromise();
    }
  });
});