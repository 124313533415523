define("affinio/services/screen", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    tracker: Ember.inject.service(),
    size: 'small',
    width: 1280,
    height: 800,
    initial: true,
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      Ember.set(this, 'initial', true);
      this.checkSize();
      Ember.$(window).resize(function () {
        return Ember.run.debounce(_this, _this.checkSize, 1000);
      });
    },
    checkSize: function checkSize() {
      var screenWidth = Ember.$(window).width();
      var screenHeight = Ember.$(window).height();
      var size = 'medium';
      var woopraMessage;
      var initial = Ember.get(this, 'initial');
      Ember.set(this, 'width', screenWidth);
      Ember.set(this, 'height', screenHeight);
      if (screenWidth <= 1280 && screenHeight <= 800) {
        size = 'small';
      } else if (screenWidth <= 1440 && screenHeight <= 900) {
        size = 'medium';
      } else if (screenWidth <= 1920 && screenHeight <= 1080) {
        size = 'large';
      } else if (screenWidth <= 2560 && screenHeight <= 1440) {
        size = 'xlarge';
      } else {
        size = 'xxlarge';
      }
      Ember.set(this, 'size', size);
      if (initial) {
        Ember.set(this, 'initial', false);
        woopraMessage = 'Initial screen size';
      } else {
        woopraMessage = 'Resized screen';
      }
      Ember.get(this, 'tracker').track(woopraMessage, {
        width: screenWidth,
        height: screenHeight,
        aspect: screenWidth / screenHeight,
        size: size
      });
    }
  });
});