define("affinio/components/old/boolean-switcher", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['boolean-switcher'],
    tracker: Ember.inject.service(),
    actions: {
      toggleBoolean: function toggleBoolean(val) {
        // TODO where this is used in report buidler should also use the passed in function
        var passedInFunction = Ember.get(this, 'toggleBoolean');
        if (passedInFunction && typeof passedInFunction === 'function') {
          passedInFunction(val);
        } else {
          Ember.set(this, "model.".concat(Ember.get(this, 'key')), val);
        }
        Ember.get(this, 'tracker').track('Boolean Toggled', {
          boolean: Ember.get(this, 'key'),
          value: val
        });
      }
    }
  });
});