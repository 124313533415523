define("affinio/utils/taxonomies/odc-cpg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.taxonomy = _exports.default = void 0;
  /* eslint-disable */
  var taxonomy = _exports.taxonomy = {
    id: 'ODC-CPG',
    meta: {
      title: 'ODC CPG',
      icon: '/public/assets/icons/odc-cpg.png',
      colors: {
        primary: '#0088cc',
        secondary: '#ff00cc'
      },
      alwaysLoadMetrics: {
        blocking: true,
        report: ['numberOfMembers'],
        cluster: ['numberOfMembers']
      },
      traits: {
        minimumSaturation: 0.001,
        minimumTimesMoreLikely: 1.2
      },
      globalLabel: 'Baseline'
    },
    schema: [{
      label: 'Overview',
      value: 'overview',
      subcategories: [{
        label: 'Summary',
        value: 'summary',
        modules: [{
          label: 'Audience Details and Source',
          value: 'audience-header',
          dependencies: ['cluster.isAggregate'],
          metrics: [{
            clusterAggregation: 'interests'
          }]
        }, {
          label: 'Audience Visualization',
          value: 'audience-visualization',
          dependencies: ['cluster.isAggregate'],
          metrics: [{
            report: 'clusterSVGURL'
          }],
          description: 'This diagram represents the different clusters within this audience. The lines connecting clusters indicate their shared interests. More lines = more in common.'
        },
        // {
        //   label: 'Audience Summary',
        //   value: 'audience-summary',
        //   dependencies: ['cluster.isAggregate'],
        //   // metrics: [
        //   //   { everyCluster: 'shareOfWallet' }
        //   // ]
        // },
        {
          label: 'Scatter Plot',
          value: 'scatter-plot',
          metrics: [{
            everyCluster: 'UPC'
          }],
          dependencies: ['cluster.isAggregate'],
          moduleProperties: {
            visualizationProperties: [{
              label: 'Clusters share of wallet',
              columns: 'report.clusters',
              xAxis: 'brandSpend',
              xAxisLabel: 'Average Household Dollars per Brand',
              yAxis: 'shareOfWallet',
              yAxisLabel: '% of Category Spend on Top Brand',
              radius: 'numberOfMembers',
              radiusLabel: 'Number of Households'
            }, {
              label: 'UPC Share of Wallet',
              columns: 'report.clusters',
              mapBy: 'UPC.response',
              xAxis: 'audience_avg_Netsales',
              xAxisLabel: 'Average Household Dollars',
              yAxis: 'audience_saturation',
              yAxisLabel: '% of Households who purchased',
              radius: 'audience_percentOfCategorySpendOnInstance',
              radiusLabel: '% of In-Brand spend on this UPC'
            },
            // {
            //   label: 'Occupations',
            //   columns: 'report.clusters',
            //   mapBy: 'Occupation.response',
            //   xAxis: 'global_saturation',
            //   yAxis: 'audience_saturation'
            // },
            {
              label: 'Brand Share of Wallet',
              columns: 'report.clusters',
              mapBy: 'Brand.response',
              xAxis: 'audience_avg_Netsales',
              xAxisLabel: 'Average Household Dollars',
              yAxis: 'audience_saturation',
              yAxisLabel: '% of Households who purchased',
              radius: 'audience_percentOfCategorySpendOnInstance',
              radiusLabel: '% of In-Category spend on this brand'
            }, {
              label: 'Categories Share of Wallet',
              columns: 'report.clusters',
              mapBy: 'Categories.response',
              xAxis: 'audience_avg_Netsales',
              xAxisLabel: 'Average Household Dollars',
              yAxis: 'audience_saturation',
              yAxisLabel: '% of Households who purchased',
              radius: 'audience_percentOfCategorySpendOnInstance',
              radiusLabel: '% of all spend on this category'
            }]
          }
        }, {
          label: 'Cluster Summaries',
          value: 'cluster-summaries',
          dependencies: ['cluster.isAggregate'],
          description: 'This section provides a consolidated overview of key over-indexing attributes by cluster. Click-through to see more.'
        }, {
          label: 'Top Traits',
          value: 'traits-gallery',
          dependencies: ['cluster.isCluster']
        }]
      }]
    }, {
      label: 'CPG',
      value: 'cpg',
      subcategories: [{
        label: 'Spend Habits',
        value: 'spend-habits',
        modules: [{
          label: 'UPC Share of Wallet',
          value: 'scatter-plot',
          metrics: [{
            cluster: 'UPC'
          }],
          moduleProperties: {
            visualizationProperties: [{
              label: 'UPC Share of Wallet',
              columns: 'cluster',
              mapBy: 'UPC.response',
              xAxis: 'audience_avg_Netsales',
              yAxis: 'audience_saturation',
              radius: 'audience_percentOfCategorySpendOnInstance'
            }]
          }
        }, {
          label: 'UPC',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'UPC'
          }],
          description: 'UPC'
        }, {
          label: 'Brand Share of Wallet',
          value: 'scatter-plot',
          metrics: [{
            cluster: 'Brand'
          }],
          moduleProperties: {
            visualizationProperties: [{
              label: 'Brand Share of Wallet',
              columns: 'cluster',
              mapBy: 'Brand.response',
              xAxis: 'audience_avg_Netsales',
              yAxis: 'audience_saturation',
              radius: 'audience_percentOfCategorySpendOnInstance'
            }]
          }
        }, {
          label: 'Brand',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Brand'
          }],
          description: 'Brand'
        }, {
          label: 'Categories Share of Wallet',
          value: 'scatter-plot',
          metrics: [{
            cluster: 'Categories'
          }],
          moduleProperties: {
            visualizationProperties: [{
              label: 'Categories Share of Wallet',
              columns: 'cluster',
              mapBy: 'Categories.response',
              xAxis: 'audience_avg_Netsales',
              yAxis: 'audience_saturation',
              radius: 'audience_percentOfCategorySpendOnInstance'
            }]
          }
        }, {
          label: 'Categories',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Categories'
          }],
          description: 'Categories'
        }]
      }, {
        label: 'Grocery Brands',
        value: 'grocery-brands',
        modules: [{
          label: 'Food & Beverage Brands',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Past Purchases > Consumer Packaged Goods (CPG) > Brands > Food & Beverages'
          }],
          description: 'Past Purchases > Consumer Packaged Goods (CPG) > Brands > Food & Beverages'
        }, {
          label: 'Carbonated Beverage Brands',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Beverages > Carbonated > Regular Carbonated'
          }],
          description: 'Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Beverages > Carbonated > Regular Carbonated'
        }, {
          label: 'Juice Brands',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Beverages > Juice'
          }],
          description: 'Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Beverages > Juice'
        }, {
          label: 'Cold Cereal Brands',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Pantry > Cereals & Bars > Cold Cereal'
          }],
          description: 'Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Pantry > Cereals & Bars > Cold Cereal'
        }, {
          label: 'Frozen Vegetables Brands',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Frozen Foods > Frozen Vegetables'
          }],
          description: 'Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Frozen Foods > Frozen Vegetables'
        }, {
          label: 'Salty Snacks Brands',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Pantry > Sweets & Snacks > Salty Snacks'
          }],
          description: 'Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Pantry > Sweets & Snacks > Salty Snacks'
        }, {
          label: 'Meat Brands',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Fresh Foods > Meat & Seafood > Meat'
          }],
          description: 'Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Fresh Foods > Meat & Seafood > Meat'
        }, {
          label: 'DLX CPG Brands',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'DLX CPG Brands'
          }],
          description: 'DLX CPG Brands'
        }, {
          label: 'DLX CPG Proximity',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'DLX CPG Proximity'
          }],
          description: 'DLX CPG Proximity'
        }, {
          label: 'DLX CPG Visitation',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'DLX CPG Visitation'
          }],
          description: 'DLX CPG Visitation'
        }]
      }, {
        label: 'Grocery Categories',
        value: 'grocery-categories',
        modules: [{
          label: 'Food & Beverages',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Past Purchases > Consumer Packaged Goods (CPG) > Food & Beverages'
          }],
          description: 'Past Purchases > Consumer Packaged Goods (CPG) > Food & Beverages'
        }, {
          label: 'Pantry',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Pantry'
          }],
          description: 'Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Pantry'
        }, {
          label: 'Cookies & Candy',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Past Purchases > Consumer Packaged Goods (CPG) > Food & Beverages > Snacks, Cookies & Candy'
          }],
          description: 'Past Purchases > Consumer Packaged Goods (CPG) > Food & Beverages > Snacks, Cookies & Candy'
        }, {
          label: 'Beverages',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Past Purchases > Consumer Packaged Goods (CPG) > Food & Beverages > Beverages'
          }],
          description: 'Past Purchases > Consumer Packaged Goods (CPG) > Food & Beverages > Beverages'
        }, {
          label: 'Condiments, Sauces & Spreads',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Pantry > Condiments, Sauces & Spreads'
          }],
          description: 'Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Pantry > Condiments, Sauces & Spreads'
        }, {
          label: 'Breakfast & Cereals',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Past Purchases > Consumer Packaged Goods (CPG) > Food & Beverages > Breakfast & Cereals'
          }],
          description: 'Past Purchases > Consumer Packaged Goods (CPG) > Food & Beverages > Breakfast & Cereals'
        }, {
          label: 'Meat & Seafood',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Fresh Foods > Meat & Seafood'
          }],
          description: 'Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Fresh Foods > Meat & Seafood'
        }, {
          label: 'Produce',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Past Purchases > Consumer Packaged Goods (CPG) > Food & Beverages > Produce'
          }],
          description: 'Past Purchases > Consumer Packaged Goods (CPG) > Food & Beverages > Produce'
        }, {
          label: 'Frozen Foods',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Frozen Foods'
          }],
          description: 'Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Frozen Foods'
        }, {
          label: 'Sweets & Snacks',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Pantry > Sweets & Snacks'
          }],
          description: 'Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Pantry > Sweets & Snacks'
        }, {
          label: 'Dairy & Eggs',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Fresh Foods > Dairy & Eggs'
          }],
          description: 'Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Fresh Foods > Dairy & Eggs'
        }, {
          label: 'DLX CPG',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'DLX CPG'
          }],
          description: 'DLX CPG'
        }]
      }, {
        label: 'Health & Beauty',
        value: 'health-beauty',
        modules: [{
          label: 'Health & Beauty Products',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Health & Beauty Products'
          }],
          description: 'Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Health & Beauty Products'
        }, {
          label: 'Beauty Purchases',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Past Purchases > Consumer Packaged Goods (CPG) > Health & Beauty > Beauty'
          }],
          description: 'Past Purchases > Consumer Packaged Goods (CPG) > Health & Beauty > Beauty'
        }, {
          label: 'Pain Relief Brands',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Health & Beauty Products > Pain Relief'
          }],
          description: 'Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Health & Beauty Products > Pain Relief'
        }, {
          label: 'Hair Care Purchases',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Past Purchases > Consumer Packaged Goods (CPG) > Health & Beauty > Beauty > Hair Care'
          }],
          description: 'Past Purchases > Consumer Packaged Goods (CPG) > Health & Beauty > Beauty > Hair Care'
        }, {
          label: 'Health & Beauty Brands',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Past Purchases > Consumer Packaged Goods (CPG) > Brands > Health & Beauty'
          }],
          description: 'Past Purchases > Consumer Packaged Goods (CPG) > Brands > Health & Beauty'
        }, {
          label: 'Personal Care Purchases',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Past Purchases > Consumer Packaged Goods (CPG) > Health & Beauty > Personal Care'
          }],
          description: 'Past Purchases > Consumer Packaged Goods (CPG) > Health & Beauty > Personal Care'
        }]
      }, {
        label: 'Home',
        value: 'home',
        modules: [{
          label: 'Household Supplies',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Household Supplies'
          }],
          description: 'Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Household Supplies'
        }, {
          label: 'Cleaning Supplies Brands',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Household Supplies > Cleaning Supplies'
          }],
          description: 'Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Household Supplies > Cleaning Supplies'
        }, {
          label: 'Household Supplies Brands',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Past Purchases > Consumer Packaged Goods (CPG) > Brands > Household Supplies'
          }],
          description: 'Past Purchases > Consumer Packaged Goods (CPG) > Brands > Household Supplies'
        }, {
          label: 'Baby Care Brands',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Past Purchases > Consumer Packaged Goods (CPG) > Brands > Baby Care'
          }],
          description: 'Past Purchases > Consumer Packaged Goods (CPG) > Brands > Baby Care'
        }, {
          label: 'Pet Supplies Brands',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Past Purchases > Consumer Packaged Goods (CPG) > Brands > Pet Supplies'
          }],
          description: 'Past Purchases > Consumer Packaged Goods (CPG) > Brands > Pet Supplies'
        }, {
          label: 'Pet Care Purchases',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Pet Care'
          }],
          description: 'Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Pet Care'
        }]
      }, {
        label: 'Essence Categories',
        value: 'essence-categories',
        modules: [{
          label: 'Affluence - ODC Essence',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'AFFLUENCE'
          }],
          description: 'Identifies affluence based on the amount of premium and value products a household purchases'
        }, {
          label: 'Special Dietary Needs - ODC Essence',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'SPNEEDS'
          }],
          description: 'Identifies if the household exhibits any specific dietary needs based upon CPG purchases'
        }, {
          label: 'Kids - ODC Essence',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'BABYKIDS'
          }],
          description: 'Identifies the family composition based upon the amount of baby and kids CPG products purchased'
        }, {
          label: 'Gender & Age - ODC Essence',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'GENAGE'
          }],
          description: 'Identifies the gender and age affinity of the household based upon the age- and gender-indicating products being purchased'
        }, {
          label: 'Pets - ODC Essence',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'PETS'
          }],
          description: 'Identifies the pets present in the household based upon spending in pet categories'
        }, {
          label: 'Cooking Styles - ODC Essence',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'COOK'
          }],
          description: 'Identifies the cooking style of the household based on the households purchasing skew toward various meal types'
        }, {
          label: 'Ethnicity - ODC Essence',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'ETHNIC'
          }],
          description: 'Identifies the ethnic affinity of the household based upon the various ethnic products significantly purchased by the household'
        }, {
          label: 'Health & Wellness - ODC Essence',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'HW'
          }],
          description: 'Identifies the level of health concern that can be identified via the CPG purchases of the household'
        }, {
          label: 'Diet Style - ODC Essence',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'DIETER'
          }],
          description: 'Identifies the type of dieting style the household skews toward based upon CPG purchases'
        }]
      }, {
        label: 'Essence Segments',
        value: 'essence-segments',
        modules: [{
          label: 'Healthy Living Couples',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Healthy Living Couples'
          }],
          description: 'Healthy Living Couples'
        }, {
          label: 'Healthy Living Families',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Healthy Living Families'
          }],
          description: 'Healthy Living Families'
        }, {
          label: 'Premium On-the-Go Couples & Families',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Premium On-the-Go Couples & Families'
          }],
          description: 'Premium On-the-Go Couples & Families'
        }, {
          label: 'Watching the Waistline Singles/Couples',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Watching the Waistline Singles/Couples'
          }],
          description: 'Watching the Waistline Singles/Couples'
        }, {
          label: 'Watching the Waistline Families',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Watching the Waistline Families'
          }],
          description: 'Watching the Waistline Families'
        }, {
          label: 'Finest-Focused Couples and Families',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Finest-Focused Couples and Families'
          }],
          description: 'Finest-Focused Couples and Families'
        }, {
          label: 'Low-Cooking Singles',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Low-Cooking Singles'
          }],
          description: 'Low-Cooking Singles'
        }, {
          label: 'Quick Cooking Families',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Quick Cooking Families'
          }],
          description: 'Quick Cooking Families'
        }, {
          label: 'Meat & Potatoes Families',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Meat & Potatoes Families'
          }],
          description: 'Meat & Potatoes Families'
        }, {
          label: 'Families with Babies',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Families with Babies'
          }],
          description: 'Families with Babies'
        }, {
          label: 'Kid-Focused Families',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Kid-Focused Families'
          }],
          description: 'Kid-Focused Families'
        }, {
          label: 'Older Adults',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Older Adults'
          }],
          description: 'Older Adults'
        }, {
          label: 'Natural/Organic Households',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Natural/Organic Households'
          }],
          description: 'Natural/Organic Households'
        }, {
          label: 'Hispanic Households',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Hispanic Households'
          }],
          description: 'Hispanic Households'
        }, {
          label: 'Kosher Households',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Kosher Households'
          }],
          description: 'Kosher Households'
        }]
      }]
    }, {
      label: 'Purchase Behavior',
      value: 'purchase-behavior',
      subcategories: [{
        label: 'Retail',
        value: 'retail',
        modules: [{
          label: 'Retail Category',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Category'
          }],
          description: 'Category'
        }, {
          label: 'Retail SubCategory',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'SubCategory'
          }],
          description: 'SubCategory'
        }, {
          label: 'Shopping Interests',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Hobbies & Interests > Shopping'
          }],
          description: 'Oracle > Hobbies & Interests > Shopping'
        }, {
          label: 'Channel',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Channel'
          }],
          description: 'Channel'
        }, {
          label: 'Clothing',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Retail > Purchase-Based > Categories > Fashion & Apparel > Clothing'
          }],
          description: 'Oracle > Retail > Purchase-Based > Categories > Fashion & Apparel > Clothing'
        }, {
          label: 'Outdoor Gear',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Retail > Purchase-Based > Categories > Sports Equipment & Outdoor Gear > Outdoor Gear'
          }],
          description: 'Oracle > Retail > Purchase-Based > Categories > Sports Equipment & Outdoor Gear > Outdoor Gear'
        }, {
          label: 'Clothing Styles',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Past Purchases > Retail > Clothing, Shoes & Accessories > Clothing > Styles'
          }],
          description: 'Past Purchases > Retail > Clothing, Shoes & Accessories > Clothing > Styles'
        }, {
          label: 'Clothing Type',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Past Purchases > Retail > Clothing, Shoes & Accessories > Clothing > Type'
          }],
          description: 'Past Purchases > Retail > Clothing, Shoes & Accessories > Clothing > Type'
        }, {
          label: 'Indoors Home Purchases',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Past Purchases > Retail > Home & Garden > Indoors'
          }],
          description: 'Past Purchases > Retail > Home & Garden > Indoors'
        }, {
          label: 'Fashion & Apparel (Proximity)',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Retail > Proximity > Fashion & Apparel'
          }],
          description: 'Oracle > Retail > Proximity > Fashion & Apparel'
        }, {
          label: 'Home & Garden (Proximity)',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Retail > Proximity > Home & Garden'
          }],
          description: 'Oracle > Retail > Proximity > Home & Garden'
        }, {
          label: 'Department Stores (Proximity)',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Retail > Proximity > Department Stores'
          }],
          description: 'Oracle > Retail > Proximity > Department Stores'
        }, {
          label: 'DLX Retail Proximity: Retailers',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'DLX Retail Proximity: Retailers'
          }],
          description: 'DLX Retail Proximity: Retailers'
        }, {
          label: 'DLX Retail',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'DLX Retail'
          }],
          description: 'DLX Retail'
        }, {
          label: 'DLX Retail Visitation',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'DLX Retail Visitation'
          }],
          description: 'DLX Retail Visitation'
        }, {
          label: 'DLX Retail Brands',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'DLX Retail Brands'
          }],
          description: 'DLX Retail Brands'
        }]
      }, {
        label: 'Dining',
        value: 'dining',
        modules: [{
          label: 'Dining Type',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Restaurants > Dining Type'
          }],
          description: 'Oracle > Restaurants > Dining Type'
        }, {
          label: 'Cuisine Type',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Restaurants > Cuisine Type'
          }],
          description: 'Oracle > Restaurants > Cuisine Type'
        }, {
          label: 'Food & Drink Interests',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Interest > Food & Drink'
          }],
          description: 'Interest > Food & Drink'
        }, {
          label: 'Delivery & Take Out (Proximity)',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Restaurants > Proximity > Delivery & Take Out'
          }],
          description: 'Oracle > Restaurants > Proximity > Delivery & Take Out'
        }, {
          label: 'Fast Food (Proximity)',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Restaurants > Proximity > QSR (Fast Food)'
          }],
          description: 'Oracle > Restaurants > Proximity > QSR (Fast Food)'
        }, {
          label: 'Casual Dining (Proximity)',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Restaurants > Proximity > Casual Dining'
          }],
          description: 'Oracle > Restaurants > Proximity > Casual Dining'
        }, {
          label: 'DLX Restaurants Visitation',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'DLX Restaurants Visitation'
          }],
          description: 'DLX Restaurants Visitation'
        }, {
          label: 'DLX Retail Proximity: Restaurants',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'DLX Retail Proximity: Restaurants'
          }],
          description: 'DLX Retail Proximity: Restaurants'
        }]
      }, {
        label: 'Auto',
        value: 'auto',
        modules: [{
          label: 'Makes',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Autos > Owners > Makes'
          }],
          description: 'Oracle > Autos > Owners > Makes'
        }, {
          label: 'Motorcycles',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Past Purchases > Other Vehicles > Motorcycles > Makes'
          }],
          description: 'Past Purchases > Other Vehicles > Motorcycles > Makes'
        }, {
          label: 'Car Body Style',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Autos > Interest > Body Styles'
          }],
          description: 'Oracle > Autos > Interest > Body Styles'
        }, {
          label: 'Condition',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Autos > Interest > Condition'
          }],
          description: 'Oracle > Autos > Interest > Condition'
        }, {
          label: 'Vehicle Age',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Autos > Owners > Vehicle Age'
          }],
          description: 'Oracle > Autos > Owners > Vehicle Age'
        }, {
          label: 'DLX Auto Proximity',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'DLX Auto Proximity'
          }],
          description: 'DLX Auto Proximity'
        }, {
          label: 'DLX Auto Visitation',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'DLX Auto Visitation'
          }],
          description: 'DLX Auto Visitation'
        }, {
          label: 'Edmunds powered by Oracle',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Edmunds powered by Oracle'
          }],
          description: 'Edmunds powered by Oracle'
        }]
      }, {
        label: 'Seasonal',
        value: 'seasonal',
        modules: [{
          label: 'Spring Seasonal Buyer Type',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Branded Data > Datalogix > DLX Seasonal > Spring Seasonal'
          }],
          description: 'Branded Data > Datalogix > DLX Seasonal > Spring Seasonal'
        }, {
          label: 'Summer Seasonal Buyer Type',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Branded Data > Datalogix > DLX Seasonal > Summer Seasonal'
          }],
          description: 'Branded Data > Datalogix > DLX Seasonal > Summer Seasonal'
        }, {
          label: 'Fall Seasonal Buyer Type',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Branded Data > Datalogix > DLX Seasonal > Fall Seasonal'
          }],
          description: 'Branded Data > Datalogix > DLX Seasonal > Fall Seasonal'
        }, {
          label: 'Winter Seasonal Buyer Type',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Branded Data > Datalogix > DLX Seasonal > Winter Seasonal'
          }],
          description: 'Branded Data > Datalogix > DLX Seasonal > Winter Seasonal'
        }, {
          label: 'Holiday Shoppers Type',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Seasonal & Holiday > Winter > Holiday Shoppers'
          }],
          description: 'Oracle > Seasonal & Holiday > Winter > Holiday Shoppers'
        }, {
          label: 'DLX Seasonal',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'DLX Seasonal'
          }],
          description: 'DLX Seasonal'
        }]
      }, {
        label: 'Buyer Trends',
        value: 'buyer-trends',
        modules: [{
          label: 'CPG Price Sensitivity',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'PriceSegments'
          }],
          description: 'PriceSegments'
        }, {
          label: 'CPG Deal Sensitivity',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'DealSegments'
          }],
          description: 'DealSegments'
        }, {
          label: 'Eating Styles',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Consumer Packaged Goods (CPG) > Interest > Eating Styles'
          }],
          description: 'Oracle > Consumer Packaged Goods (CPG) > Interest > Eating Styles'
        }, {
          label: 'Buy Styles',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > BuyStyles'
          }],
          description: 'Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > BuyStyles'
        }, {
          label: 'DLX CPG BuyStyles',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'DLX CPG BuyStyles'
          }],
          description: 'DLX CPG BuyStyles'
        }, {
          label: 'DLX Other',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'DLX Other'
          }],
          description: 'DLX Other'
        }, {
          label: 'DLX Wellness',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'DLX Wellness'
          }],
          description: 'DLX Wellness'
        }]
      }]
    }, {
      label: 'Personal',
      value: 'personal',
      subcategories: [{
        label: 'Demographics',
        value: 'demographics',
        modules: [{
          label: 'Life Stages',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Demographic > Age > Lifestages'
          }],
          description: 'Demographic > Age > Lifestages'
        }, {
          label: 'Age',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Demographic > Age'
          }],
          description: 'Demographic > Age'
        }, {
          label: 'Gender',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Gender'
          }],
          description: 'Gender'
        }, {
          label: 'Ethnic Group',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Ethnic Group'
          }],
          description: 'Ethnic Group'
        }, {
          label: 'Language',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Language'
          }],
          description: 'Language'
        }, {
          label: 'Country of Origin',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Country of Origin'
          }],
          description: 'Country of Origin'
        }, {
          label: 'Race',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Race'
          }],
          description: 'Race'
        }, {
          label: 'Religion',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Religion'
          }],
          description: 'Religion'
        }
        // {
        //   label: 'DLX Demographics',
        //   value: 'metric-visualization',
        //   metrics: [{ everyCluster: 'DLX Demographics' }],
        //   description: 'DLX Demographics'
        // },
        // {
        //   label: 'DLX Travel',
        //   value: 'metric-visualization',
        //   metrics: [{ everyCluster: 'DLX Travel' }],
        //   description: 'DLX Travel'
        // }
        ]
      }, {
        label: 'Home and Family',
        value: 'home-and-family',
        modules: [{
          label: 'Household Composition',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Household Composition'
          }],
          description: 'Household Composition'
        }, {
          label: 'Marital Status',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Demographic > Marital Status'
          }],
          description: 'Oracle > Demographic > Marital Status'
        }, {
          label: 'Dwelling Type',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Dwelling Type'
          }],
          description: 'Dwelling Type'
        }, {
          label: 'Family Position',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Demographic > Family Composition > Family Position'
          }],
          description: 'Demographic > Family Composition > Family Position'
        }, {
          label: 'Age of Children',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Demographic > Household Composition > Age of Children'
          }],
          description: 'Oracle > Demographic > Household Composition > Age of Children'
        }]
      }, {
        label: 'Career and Education',
        value: 'career-and-education',
        modules: [{
          label: 'Occupation',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Occupation'
          }],
          description: 'Occupation'
        }, {
          label: 'Occupation - Specific',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Occupation - Specific'
          }],
          description: 'Occupation - Specific'
        }, {
          label: 'Education',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Demographic > Education'
          }],
          description: 'Demographic > Education'
        }, {
          label: 'Company Size',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > B2B > Firmographic > Company Size'
          }],
          description: 'Oracle > B2B > Firmographic > Company Size'
        }, {
          label: 'Education & Career Life Stage',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Life Stages & Events > Education & Career'
          }],
          description: 'Oracle > Life Stages & Events > Education & Career'
        }]
      }, {
        label: 'Financial',
        value: 'financial',
        modules: [{
          label: 'Estimated Household Income (USD)',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Demographic > Financial Attributes > Estimated Household Income (USD)'
          }],
          description: 'Oracle > Demographic > Financial Attributes > Estimated Household Income (USD)'
        }, {
          label: 'Median Home Value',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Demographic > Housing Attributes > Median Home Value'
          }],
          description: 'Demographic > Housing Attributes > Median Home Value'
        }, {
          label: 'Payment Type',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Payment Type'
          }],
          description: 'Payment Type'
        }, {
          label: 'Credit Card Types',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Past Purchases > Financial Products & Services > Credit Cards > Types'
          }],
          description: 'Past Purchases > Financial Products & Services > Credit Cards > Types'
        }, {
          label: 'Investments',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Investments'
          }],
          description: 'Investments'
        }, {
          label: 'Wealth Decile',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Demographic > Financial Attributes > Wealth Decile'
          }],
          description: 'Oracle > Demographic > Financial Attributes > Wealth Decile'
        }, {
          label: 'Median Home Value',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Demographic > Housing Attributes > Median Home Value'
          }],
          description: 'Demographic > Housing Attributes > Median Home Value'
        }, {
          label: 'Financial Services',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Financial Services'
          }],
          description: 'Oracle > Financial Services'
        }, {
          label: 'Estimated Net Worth (USD)',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Demographic > Financial Attributes > Estimated Net Worth (USD)'
          }],
          description: 'Oracle > Demographic > Financial Attributes > Estimated Net Worth (USD)'
        }, {
          label: 'DLX Financial Services Visitation',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'DLX Financial Services Visitation'
          }],
          description: 'DLX Financial Services Visitation'
        }, {
          label: 'DLX Finance',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'DLX Finance'
          }],
          description: 'DLX Finance'
        }]
      }, {
        label: 'Lifestyles',
        value: 'lifestyles',
        modules: [{
          label: 'Lifestyle Categories',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Branded Data > Datalogix > DLX Lifestyles'
          }],
          description: 'Branded Data > Datalogix > DLX Lifestyles'
        }, {
          label: 'Lifestyles: Millenials',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Branded Data > Datalogix > DLX Lifestyles > Millennials'
          }],
          description: 'Branded Data > Datalogix > DLX Lifestyles > Millennials'
        }, {
          label: 'Lifestyles: Parents',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Lifestyles > Parents'
          }],
          description: 'Oracle > Lifestyles > Parents'
        }, {
          label: 'Lifestyles: Deal Seekers',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Branded Data > Datalogix > DLX Lifestyles > Deal Seekers'
          }],
          description: 'Branded Data > Datalogix > DLX Lifestyles > Deal Seekers'
        }, {
          label: 'Lifestyles: Frequent Travelers',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Branded Data > Datalogix > DLX Lifestyles > Frequent Travelers'
          }],
          description: 'Branded Data > Datalogix > DLX Lifestyles > Frequent Travelers'
        }, {
          label: 'Lifestyles: Enthusiasts',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Lifestyles > Enthusiasts'
          }],
          description: 'Oracle > Lifestyles > Enthusiasts'
        }, {
          label: 'Lifestyles: Interests',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Interest > Lifestyles'
          }],
          description: 'Interest > Lifestyles'
        }, {
          label: 'Parenting & Family',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Interest > Parenting & Family'
          }],
          description: 'Interest > Parenting & Family'
        }, {
          label: 'DLX Lifestyles',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'DLX Lifestyles'
          }],
          description: 'DLX Lifestyles'
        }, {
          label: 'Visa Audiences powered by Oracle',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Visa Audiences powered by Oracle'
          }],
          description: 'Visa Audiences powered by Oracle'
        }]
      }]
    }, {
      label: 'Interests',
      value: 'interests',
      subcategories: [{
        label: 'Hobbies',
        value: 'hobbies',
        modules: [{
          label: 'Hobbies & Interests',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Hobbies & Interests'
          }],
          description: 'Oracle > Hobbies & Interests'
        }, {
          label: 'Personal Interest: Hobby',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Hobby'
          }],
          description: 'Hobby'
        }, {
          label: 'Auto Enthusiast Type',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Autos > Interest > Auto Enthusiasts'
          }],
          description: 'Oracle > Autos > Interest > Auto Enthusiasts'
        }, {
          label: 'Personal Interest: Reading',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Reading'
          }],
          description: 'Reading'
        }, {
          label: 'Personal Interest: Politics & Society',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Hobbies & Interests > Politics & Society'
          }],
          description: 'Oracle > Hobbies & Interests > Politics & Society'
        }, {
          label: 'DLX Politics',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'DLX Politics'
          }],
          description: 'DLX Politics'
        }, {
          label: 'Personal Interest: Charitable Giving Causes',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Hobbies & Interests > Politics & Society > Charitable Giving > Causes'
          }],
          description: 'Oracle > Hobbies & Interests > Politics & Society > Charitable Giving > Causes'
        }, {
          label: 'DLX Philanthropy',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'DLX Philanthropy'
          }],
          description: 'DLX Philanthropy'
        }, {
          label: 'Personal Interest: Science & Humanities',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Hobbies & Interests > Science & Humanities'
          }],
          description: 'Oracle > Hobbies & Interests > Science & Humanities'
        }, {
          label: 'Education Interests',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Interest > Education'
          }],
          description: 'Interest > Education'
        }, {
          label: 'Hobbies, Games & Toys',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Interest > Hobbies, Games & Toys'
          }],
          description: 'Interest > Hobbies, Games & Toys'
        }]
      }, {
        label: 'Entertainment',
        value: 'entertainment',
        modules: [{
          label: 'Movie Genres',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Media & Entertainment > Movies > Genres'
          }],
          description: 'Oracle > Media & Entertainment > Movies > Genres'
        }, {
          label: 'Video Game Genres',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Interest > Video Games > Genres'
          }],
          description: 'Interest > Video Games > Genres'
        }, {
          label: 'Music Genres',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Media & Entertainment > Music > Genres'
          }],
          description: 'Oracle > Media & Entertainment > Music > Genres'
        }, {
          label: 'Media & Entertainment',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Media & Entertainment'
          }],
          description: 'Oracle > Media & Entertainment'
        }, {
          label: 'Television Genres',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Television > Viewership > Genres'
          }],
          description: 'Television > Viewership > Genres'
        }, {
          label: 'Events & Attractions',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Media & Entertainment > Events & Attractions'
          }],
          description: 'Oracle > Media & Entertainment > Events & Attractions'
        }, {
          label: 'Streaming Providers',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Media & Entertainment > Streaming Media & Downloads > Streaming Providers'
          }],
          description: 'Oracle > Media & Entertainment > Streaming Media & Downloads > Streaming Providers'
        }, {
          label: 'Arts & Entertainment',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Interest > Arts & Entertainment'
          }],
          description: 'Interest > Arts & Entertainment'
        }, {
          label: 'Video Game Genres',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Media & Entertainment > Video Games > Games > Action, Role-Playing & FPS'
          }],
          description: 'Oracle > Media & Entertainment > Video Games > Games > Action, Role-Playing & FPS'
        }, {
          label: 'DLX Media and Entertainment Visitation',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'DLX Media and Entertainment Visitation'
          }],
          description: 'DLX Media and Entertainment Visitation'
        }]
      }, {
        label: 'Television',
        value: 'television',
        modules: [{
          label: 'Television Networks',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Media & Entertainment > Television > Networks'
          }],
          description: 'Oracle > Media & Entertainment > Television > Networks'
        }, {
          label: 'Shows > ABC',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Television > Viewership > Shows > ABC'
          }],
          description: 'Television > Viewership > Shows > ABC'
        }, {
          label: 'Shows > NBC',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Television > Viewership > Shows > NBC'
          }],
          description: 'Television > Viewership > Shows > NBC'
        }, {
          label: 'Shows > CBS',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Television > Viewership > Shows > CBS'
          }],
          description: 'Television > Viewership > Shows > CBS'
        }, {
          label: 'Shows > Fox',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Television > Viewership > Shows > Fox'
          }],
          description: 'Television > Viewership > Shows > Fox'
        },
        // {
        //   label: 'Oracle > Media & Entertainment > Television > Shows',
        //   value: 'metric-visualization',
        //   metrics: [{ everyCluster: 'Oracle > Media & Entertainment > Television > Shows' }],
        //   description: 'Oracle > Media & Entertainment > Television > Shows'
        // },
        {
          label: 'Shows > BBC',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Television > Viewership > Shows > BBC'
          }],
          description: 'Television > Viewership > Shows > BBC'
        }, {
          label: 'Shows > Discovery',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Television > Viewership > Shows > Discovery'
          }],
          description: 'Television > Viewership > Shows > Discovery'
        }, {
          label: 'Shows > HBO',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Television > Viewership > Shows > HBO'
          }],
          description: 'Television > Viewership > Shows > HBO'
        }, {
          label: 'Shows > ESPN',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Television > Viewership > Shows > ESPN'
          }],
          description: 'Television > Viewership > Shows > ESPN'
        }, {
          label: 'Shows > Netflix',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Television > Viewership > Shows > Netflix'
          }],
          description: 'Television > Viewership > Shows > Netflix'
        }, {
          label: 'Shows > Comedy Central',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Television > Viewership > Shows > Comedy Central'
          }],
          description: 'Television > Viewership > Shows > Comedy Central'
        }, {
          label: 'Shows > Showtime',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Television > Viewership > Shows > Showtime'
          }],
          description: 'Television > Viewership > Shows > Showtime'
        }, {
          label: 'Television Events: Awards Shows',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Media & Entertainment > Television > Genres > Awards Shows'
          }],
          description: 'Oracle > Media & Entertainment > Television > Genres > Awards Shows'
        }, {
          label: 'DLX TV powered by TRA',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'DLX TV powered by TRA'
          }],
          description: 'DLX TV powered by TRA'
        }]
      }, {
        label: 'Sports',
        value: 'sports',
        modules: [{
          label: 'Exercise',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Hobbies & Interests > Health & Fitness > Exercise'
          }],
          description: 'Oracle > Hobbies & Interests > Health & Fitness > Exercise'
        }, {
          label: 'Personal Interest: Sporting 1',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Sporting Interest'
          }],
          description: 'Sporting Interest'
        }, {
          label: 'Personal Interest: Sporting 2',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Sporting Interests'
          }],
          description: 'Sporting Interests'
        }, {
          label: 'Water Sports',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Interest > Sports & Recreation > Sports > Water Sports'
          }],
          description: 'Interest > Sports & Recreation > Sports > Water Sports'
        }, {
          label: 'Outdoor Activities',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Hobbies & Interests > Outdoor Activities'
          }],
          description: 'Oracle > Hobbies & Interests > Outdoor Activities'
        }, {
          label: 'Television Events: Sports',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Media & Entertainment > Television > Genres > Sports'
          }],
          description: 'Oracle > Media & Entertainment > Television > Genres > Sports'
        }, {
          label: 'Television: Sports',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Media & Entertainment > Sports'
          }],
          description: 'Oracle > Media & Entertainment > Sports'
        }, {
          label: 'Television: Team Sports',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Media & Entertainment > Sports > Team Sports'
          }],
          description: 'Oracle > Media & Entertainment > Sports > Team Sports'
        }, {
          label: 'Television: Individual Sports',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Media & Entertainment > Sports > Individual Sports'
          }],
          description: 'Oracle > Media & Entertainment > Sports > Individual Sports'
        }]
      }, {
        label: 'Travel',
        value: 'travel',
        modules: [{
          label: 'Travel Type',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Travel'
          }],
          description: 'Travel'
        }, {
          label: 'Airlines',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Travel > In-Market > Products > Air Travel > Airlines'
          }],
          description: 'Oracle > Travel > In-Market > Products > Air Travel > Airlines'
        }, {
          label: 'Travel Products',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Interest > Travel > Products'
          }],
          description: 'Interest > Travel > Products'
        }, {
          label: 'Leisure & Vacation Travelers',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Travel > Interest > Types > Leisure & Vacation Travelers'
          }],
          description: 'Oracle > Travel > Interest > Types > Leisure & Vacation Travelers'
        }]
      }, {
        label: 'Tech',
        value: 'tech',
        modules: [{
          label: 'Internet & Online Activities',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Hobbies & Interests > Internet & Online Activities'
          }],
          description: 'Oracle > Hobbies & Interests > Internet & Online Activities'
        }, {
          label: 'Tech Interests',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Consumer Technology > Interest'
          }],
          description: 'Oracle > Consumer Technology > Interest'
        }, {
          label: 'Brand Affinity',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Consumer Technology > Interest > Brand Affinity'
          }],
          description: 'Oracle > Consumer Technology > Interest > Brand Affinity'
        }, {
          label: 'Mobile Phone OS',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Consumer Technology > Interest > Mobile Phones'
          }],
          description: 'Oracle > Consumer Technology > Interest > Mobile Phones'
        }, {
          label: 'Video Game Platforms',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'Oracle > Media & Entertainment > Video Games > Platforms'
          }],
          description: 'Oracle > Media & Entertainment > Video Games > Platforms'
        }, {
          label: 'DLX Telecommunications Visitation',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'DLX Telecommunications Visitation'
          }],
          description: 'DLX Telecommunications Visitation'
        }, {
          label: 'DLX Consumer Technology Visitation',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'DLX Consumer Technology Visitation'
          }],
          description: 'DLX Consumer Technology Visitation'
        }, {
          label: 'DLX Subscription Services',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'DLX Subscription Services'
          }],
          description: 'DLX Subscription Services'
        }, {
          label: 'DLX Consumer Technology',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'DLX Consumer Technology'
          }],
          description: 'DLX Consumer Technology'
        }, {
          label: 'DLX Telecommunications',
          value: 'metric-visualization',
          metrics: [{
            everyCluster: 'DLX Telecommunications'
          }],
          description: 'DLX Telecommunications'
        }]
      }]
    }, {
      label: 'Compare',
      value: 'compare',
      dependencies: ['report.hasCompare'],
      subcategories: [{
        label: 'Compare Metrics',
        value: 'index',
        dependencies: ['report.hasManyClusters'],
        modules: [{
          label: 'Compare Display',
          value: 'compare-display'
        }]
      }]
    }, {
      label: 'Actions',
      value: 'actions',
      subcategories: [{
        label: 'Export',
        value: 'export',
        modules: [{
          label: 'Audience Export',
          value: 'page-title'
        }, {
          label: 'Screenshots Export',
          value: 'screenshots-exporter'
        }, {
          label: 'Data Downloads (.csv)',
          value: 'csv-exporter',
          metrics: [{
            everyCluster: 'interests'
          }]
        }]
      }]
    }],
    metricDefinitions: [{
      label: 'clusterSVGURL',
      title: 'Network Graph',
      properties: [],
      defaultValue: 'string',
      level: 'report'
    }, {
      label: 'numberOfMembers',
      level: 'cluster',
      properties: [],
      defaultValue: 'number'
    }, {
      label: 'numberOfMembers',
      level: 'report',
      properties: [],
      defaultValue: 'number'
    }, {
      label: 'AFFLUENCE',
      comparable: true
      // aggregate: { checked: true, type: 'instancePercentage', instance: 'Premium', title: '% Premium Affluence' }
    }, {
      label: 'SPNEEDS',
      comparable: true
    }, {
      label: 'COOK',
      comparable: true
    }, {
      label: 'ETHNIC',
      comparable: true
    }, {
      label: 'HW',
      comparable: true
    }, {
      label: 'DIETER',
      comparable: true
    }, {
      label: 'BABYKIDS',
      comparable: true
    }, {
      label: 'GENAGE',
      comparable: true
    }, {
      label: 'PETS',
      comparable: true
    }, {
      label: 'Past Purchases > Consumer Packaged Goods (CPG) > Food & Beverages > Snacks, Cookies & Candy',
      comparable: true
    }, {
      label: 'Past Purchases > Consumer Packaged Goods (CPG) > Food & Beverages > Beverages',
      comparable: true
    }, {
      label: 'In-Market > Consumer Packaged Goods (CPG) > Food & Beverages',
      comparable: true
    }, {
      label: 'Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Pantry > Condiments, Sauces & Spreads',
      comparable: true
    }, {
      label: 'Past Purchases > Consumer Packaged Goods (CPG) > Food & Beverages > Bakery',
      comparable: true
    }, {
      label: 'Past Purchases > Consumer Packaged Goods (CPG) > Food & Beverages > Breakfast & Cereals',
      comparable: true
    }, {
      label: 'Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Fresh Foods > Meat & Seafood > Meat',
      comparable: true
    }, {
      label: 'Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Fresh Foods > Meat & Seafood',
      comparable: true
    }, {
      label: 'Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Pantry > Sweets & Snacks > Salty Snacks',
      comparable: true
    }, {
      label: 'Past Purchases > Consumer Packaged Goods (CPG) > Food & Beverages > Produce',
      comparable: true
    }, {
      label: 'Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Frozen Foods > Frozen Vegetables',
      comparable: true
    }, {
      label: 'Past Purchases > Consumer Packaged Goods (CPG) > Health & Beauty > Personal Care > Dental',
      comparable: true
    }, {
      label: 'Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Health & Beauty Products',
      comparable: true
    }, {
      label: 'Past Purchases > Consumer Packaged Goods (CPG) > Health & Beauty',
      comparable: true
    }, {
      label: 'Past Purchases > Consumer Packaged Goods (CPG) > Health & Beauty > Beauty',
      comparable: true
    }, {
      label: 'Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Health & Beauty Products > Pain Relief',
      comparable: true
    }, {
      label: 'Past Purchases > Consumer Packaged Goods (CPG) > Health & Beauty > Beauty > Hair Care',
      comparable: true
    }, {
      label: 'Past Purchases > Consumer Packaged Goods (CPG) > Brands > Health & Beauty',
      comparable: true
    }, {
      label: 'Past Purchases > Consumer Packaged Goods (CPG) > Household Supplies > Kitchen Supplies',
      comparable: true
    }, {
      label: 'Past Purchases > Consumer Packaged Goods (CPG) > Household Supplies',
      comparable: true
    }, {
      label: 'Past Purchases > Consumer Packaged Goods (CPG) > Household Supplies > Cleaning & Laundry',
      comparable: true
    }, {
      label: 'Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Household Supplies > Cleaning Supplies',
      comparable: true
    }, {
      label: 'Past Purchases > Consumer Packaged Goods (CPG) > Gifts',
      comparable: true
    }, {
      label: 'Past Purchases > Consumer Packaged Goods (CPG) > Brands > Baby Care',
      comparable: true
    }, {
      label: 'Past Purchases > Consumer Packaged Goods (CPG) > Brands > Pet Supplies',
      comparable: true
    }, {
      label: 'Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Pet Care',
      comparable: true
    }, {
      label: 'Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Pet Care > Dog Food & Supplies',
      comparable: true
    }, {
      label: 'Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Pet Care > Cat Food & Supplies',
      comparable: true
    }, {
      label: 'Category',
      comparable: true
    }, {
      label: 'SubCategory',
      comparable: true
    }, {
      label: 'Payment Type',
      comparable: true
    }, {
      label: 'Channel',
      comparable: true
    }, {
      label: 'Oracle > Retail > Purchase-Based > Categories > Fashion & Apparel > Clothing',
      comparable: true
    }, {
      label: 'Oracle > Retail > Purchase-Based > Categories > Sports Equipment & Outdoor Gear > Outdoor Gear',
      comparable: true
    }, {
      label: 'Past Purchases > Retail > Clothing, Shoes & Accessories > Clothing > Styles',
      comparable: true
    }, {
      label: 'Past Purchases > Retail > Clothing, Shoes & Accessories > Clothing > Type',
      comparable: true
    }, {
      label: 'Oracle > Consumer Technology > Interest > Brand Affinity',
      comparable: true
    }, {
      label: 'Oracle > Consumer Technology > Interest > Mobile Phones',
      comparable: true
    }, {
      label: 'Oracle > Consumer Technology > Interest',
      comparable: true
    }, {
      label: 'Oracle > Media & Entertainment > Video Games > Platforms',
      comparable: true
    }, {
      label: 'Travel',
      comparable: true
    }, {
      label: 'Television > Viewership > Shows > BBC',
      comparable: true
    }, {
      label: 'Television > Viewership > Shows > Discovery',
      comparable: true
    }, {
      label: 'Television > Viewership > Shows > ABC',
      comparable: true
    }, {
      label: 'Television > Viewership > Shows > NBC',
      comparable: true
    }, {
      label: 'Television > Viewership > Shows > CBS',
      comparable: true
    }, {
      label: 'Television > Viewership > Shows > Fox',
      comparable: true
    }, {
      label: 'Television > Viewership > Shows > HBO',
      comparable: true
    }, {
      label: 'Television > Viewership > Shows > ESPN',
      comparable: true
    }, {
      label: 'Television > Viewership > Shows > Netflix',
      comparable: true
    }, {
      label: 'Television > Viewership > Shows > Comedy Central',
      comparable: true
    }, {
      label: 'Television > Viewership > Shows > Showtime',
      comparable: true
    }, {
      label: 'Oracle > Media & Entertainment > Movies > Genres',
      comparable: true
    }, {
      label: 'Interest > Video Games > Genres',
      comparable: true
    }, {
      label: 'Oracle > Media & Entertainment > Music > Genres',
      comparable: true
    }, {
      label: 'Television > Viewership > Genres',
      comparable: true
    }, {
      label: 'Oracle > Media & Entertainment > Television > Genres > Sports',
      comparable: true
    }, {
      label: 'Oracle > Media & Entertainment > Television > Genres > Awards Shows',
      comparable: true
    }, {
      label: 'Oracle > Media & Entertainment > Sports > Individual Sports',
      comparable: true
    }, {
      label: 'Reading',
      comparable: true
    }, {
      label: 'Hobby',
      comparable: true
    }, {
      label: 'Sporting Interest',
      comparable: true
    }, {
      label: 'Sporting Interests',
      comparable: true
    }, {
      label: 'Household Composition',
      comparable: true
    }, {
      label: 'Occupation',
      comparable: true
    }, {
      label: 'Occupation - Specific',
      comparable: true
    }, {
      label: 'Oracle > Demographic > Household Composition > Age of Children',
      comparable: true
    }, {
      label: 'Investments',
      comparable: true
    }, {
      label: 'Ethnic Group',
      comparable: true
    }, {
      label: 'Oracle > Demographic > Financial Attributes > Wealth Decile',
      comparable: true
    }, {
      label: 'Demographic > Housing Attributes > Median Home Value',
      comparable: true
    }, {
      label: 'Oracle > Demographic > Financial Attributes > Estimated Household Income (USD)',
      comparable: true
    }, {
      label: 'Oracle > Demographic > Language',
      comparable: true
      // aggregate: { checked: true, type: 'instancePercentage', instance: 'Russian', title: '% Russian Language Spkrs' }
    }, {
      label: 'Country of Origin',
      comparable: true
    }, {
      label: 'Oracle > Demographic > Marital Status',
      comparable: true
    }, {
      label: 'Dwelling Type',
      comparable: true
    }, {
      label: 'Race',
      comparable: true
    }, {
      label: 'Religion',
      comparable: true
    }, {
      label: 'Demographic > Education',
      comparable: true
    }, {
      label: 'Oracle > B2B > Firmographic > Company Size',
      comparable: true
    }, {
      label: 'Branded Data > Datalogix > DLX Lifestyles',
      comparable: true
    }, {
      label: 'Branded Data > Datalogix > DLX Lifestyles > Millennials',
      comparable: true
    }, {
      label: 'Branded Data > Datalogix > DLX Lifestyles > Moms',
      comparable: true
    }, {
      label: 'Branded Data > Datalogix > DLX Lifestyles > Deal Seekers',
      comparable: true
    }, {
      label: 'Branded Data > Datalogix > DLX Lifestyles > Frequent Travelers',
      comparable: true
    }, {
      label: 'Oracle > Autos > Owners > Makes',
      comparable: true
    }, {
      label: 'Interest > Autos > Classes',
      comparable: true
    }, {
      label: 'Past Purchases > Other Vehicles > Motorcycles > Makes',
      comparable: true
    }, {
      label: 'Oracle > Autos > Interest > Auto Enthusiasts',
      comparable: true
    }, {
      label: 'Oracle > Autos > Interest > Body Styles',
      comparable: true
    }, {
      label: 'Oracle > Autos > Interest > Condition',
      comparable: true
    }, {
      label: 'Oracle > Autos > Owners > Vehicle Age',
      comparable: true
    }, {
      label: 'PriceSegments',
      comparable: true
    }, {
      label: 'DealSegments',
      comparable: true
    }, {
      label: 'Brand',
      comparable: true,
      aggregate: {
        checked: true,
        property: {
          aggregateProperty: 'saturations',
          filterType: 'audience',
          filterValue: 'avg_Netsales'
        },
        type: 'average',
        slice: 500,
        title: 'Average Brand Spend',
        instanceTitle: '$ per Brand'
      }
    }, {
      label: 'UPC',
      comparable: true,
      aggregate: {
        checked: true,
        property: {
          aggregateProperty: 'saturations',
          filterType: 'audience',
          filterValue: 'avg_Netsales'
        },
        type: 'average',
        slice: 500,
        title: 'Average UPC Spend',
        instanceTitle: '$ per UPC'
      }
    }, {
      label: 'Categories',
      comparable: true,
      aggregate: {
        checked: true,
        property: {
          aggregateProperty: 'saturations',
          filterType: 'audience',
          filterValue: 'avg_Netsales'
        },
        type: 'average',
        slice: 500,
        title: 'Average Category Spend',
        instanceTitle: '$ per Category'
      }
    }, {
      label: 'Healthy Living Couples',
      comparable: true
    }, {
      label: 'Healthy Living Families',
      comparable: true
    }, {
      label: 'Premium On-the-Go Couples & Families',
      comparable: true
    }, {
      label: 'Watching the Waistline Singles/Couples',
      comparable: true
    }, {
      label: 'Watching the Waistline Families',
      comparable: true
    }, {
      label: 'Finest-Focused Couples and Families',
      comparable: true
    }, {
      label: 'Low-Cooking Singles',
      comparable: true
    }, {
      label: 'Quick Cooking Families',
      comparable: true
    }, {
      label: 'Meat & Potatoes Families',
      comparable: true
    }, {
      label: 'Families with Babies',
      comparable: true
    }, {
      label: 'Kid-Focused Families',
      comparable: true
    }, {
      label: 'Older Adults',
      comparable: true
    }, {
      label: 'Natural/Organic Households',
      comparable: true
    }, {
      label: 'Hispanic Households',
      comparable: true
    }, {
      label: 'Kosher Households',
      comparable: true
    }, {
      label: 'Television > Viewership > Shows',
      comparable: true
    }, {
      label: 'Oracle > Travel > In-Market > Products > Air Travel > Airlines',
      comparable: true
    }, {
      label: 'Oracle > Travel > In-Market > Advance Booking',
      comparable: true
    },
    // Potentials

    {
      label: 'Interest > Food & Drink',
      comparable: true
    }, {
      label: 'Oracle > Consumer Packaged Goods (CPG) > Interest > Eating Styles',
      comparable: true
    }, {
      label: 'Oracle > Media & Entertainment > Events & Attractions',
      comparable: true
    }, {
      label: 'Past Purchases > Retail > Home & Garden > Indoors',
      comparable: true
    }, {
      label: 'Oracle > Financial Services',
      comparable: true
    }, {
      label: 'Oracle > Life Stages & Events > Education & Career',
      comparable: true
    }, {
      label: 'Oracle > Hobbies & Interests > Internet & Online Activities',
      comparable: true
    }, {
      label: 'Oracle > Lifestyles > Enthusiasts',
      comparable: true
    }, {
      label: 'Demographic > Age > Lifestages',
      comparable: true
    }, {
      label: 'Oracle > Retail > Proximity > Fashion & Apparel',
      comparable: true
    }, {
      label: 'Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Household Supplies',
      comparable: true
    }, {
      label: 'Oracle > Retail > Proximity > Home & Garden',
      comparable: true
    }, {
      label: 'Interest > Education',
      comparable: true
    }, {
      label: 'Oracle > Media & Entertainment > Sports > Team Sports',
      comparable: true
    }, {
      label: 'Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Pantry > Cereals & Bars > Cold Cereal',
      comparable: true
    }, {
      label: 'Branded Data > Datalogix > DLX Seasonal > Winter Seasonal',
      comparable: true
    }, {
      label: 'Oracle > Travel > Interest > Types',
      comparable: true
    }, {
      label: 'Oracle > Demographic > Financial Attributes > Estimated Net Worth (USD)',
      comparable: true
    }, {
      label: 'Oracle > Hobbies & Interests > Politics & Society',
      comparable: true
    }, {
      label: 'Oracle > Media & Entertainment > Television > Networks',
      comparable: true
    }, {
      label: 'Oracle > Restaurants > Proximity > Casual Dining',
      comparable: true
    }, {
      label: 'Oracle > Media & Entertainment > Streaming Media & Downloads > Streaming Providers',
      comparable: true
    }, {
      label: 'Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Frozen Foods',
      comparable: true
    }, {
      label: 'Branded Data > Datalogix > DLX Seasonal > Fall Seasonal',
      comparable: true
    }, {
      label: 'Oracle > Lifestyles > Parents',
      comparable: true
    }, {
      label: 'Interest > Lifestyles',
      comparable: true
    }, {
      label: 'Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Beverages > Juice',
      comparable: true
    }, {
      label: 'Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > BuyStyles',
      comparable: true
    }, {
      label: 'Interest > Travel > Products',
      comparable: true
    }, {
      label: 'Oracle > Retail > Proximity > Department Stores',
      comparable: true
    }, {
      label: 'Oracle > Hobbies & Interests > Health & Fitness > Exercise',
      comparable: true
    }, {
      label: 'Oracle > Hobbies & Interests > Shopping',
      comparable: true
    }, {
      label: 'Past Purchases > Consumer Packaged Goods (CPG) > Health & Beauty > Personal Care',
      comparable: true
    }, {
      label: 'Interest > Parenting & Family',
      comparable: true
    }, {
      label: 'Oracle > Consumer Packaged Goods (CPG) > Interest > Product Categories > Beverages',
      comparable: true
    }, {
      label: 'Oracle > Seasonal & Holiday > Winter > Holiday Shoppers',
      comparable: true
    }, {
      label: 'Oracle > Restaurants > Proximity > Delivery & Take Out',
      comparable: true
    }, {
      label: 'Interest > Arts & Entertainment',
      comparable: true
    }, {
      label: 'Oracle > Lifestyles > Moms',
      comparable: true
    }, {
      label: 'Language',
      comparable: true
    }, {
      label: 'Oracle > Hobbies & Interests > Science & Humanities',
      comparable: true
    }, {
      label: 'Interest > Politics & Society',
      comparable: true
    }, {
      label: 'Oracle > Media & Entertainment > Video Games > Games > Action, Role-Playing & FPS',
      comparable: true
    }, {
      label: 'Branded Data > Datalogix > DLX Seasonal > Spring Seasonal',
      comparable: true
    }, {
      label: 'Past Purchases > Financial Products & Services > Credit Cards > Types',
      comparable: true
    }, {
      label: 'Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Pantry > Sweets & Snacks',
      comparable: true
    }, {
      label: 'Interest > Sports & Recreation > Sports > Water Sports',
      comparable: true
    }, {
      label: 'Oracle > Hobbies & Interests > Outdoor Activities',
      comparable: true
    }, {
      label: 'Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Fresh Foods > Dairy & Eggs',
      comparable: true
    }, {
      label: 'Oracle > Restaurants > Cuisine Type',
      comparable: true
    }, {
      label: 'Oracle > Consumer Packaged Goods (CPG) > Interest > Product Categories > Health & Beauty Products',
      comparable: true
    }, {
      label: 'Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Pantry',
      comparable: true
    }, {
      label: 'Interest > Arts & Entertainment > Events & Attractions',
      comparable: true
    }, {
      label: 'Past Purchases > Consumer Packaged Goods (CPG) > Brands > Household Supplies',
      comparable: true
    }, {
      label: 'Oracle > Hobbies & Interests',
      comparable: true
    }, {
      label: 'Branded Data > Datalogix > DLX Seasonal > Summer Seasonal',
      comparable: true
    }, {
      label: 'Past Purchases > Consumer Packaged Goods (CPG) > Food & Beverages',
      comparable: true
    }, {
      label: 'Past Purchases > Consumer Packaged Goods (CPG) > Brands > Food & Beverages',
      comparable: true
    }, {
      label: 'Oracle > Media & Entertainment > Television > Shows',
      comparable: true
    }, {
      label: 'Oracle > Media & Entertainment',
      comparable: true
    }, {
      label: 'Gender',
      comparable: true
    }, {
      label: 'Demographic > Age',
      comparable: true
    }, {
      label: 'Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Beverages > Carbonated > Regular Carbonated',
      comparable: true
    }, {
      label: 'Oracle > Restaurants > Proximity > QSR (Fast Food)',
      comparable: true
    }, {
      label: 'Oracle > Hobbies & Interests > Politics & Society > Charitable Giving > Causes',
      comparable: true
    }, {
      label: 'Oracle > Restaurants > Dining Type',
      comparable: true
    }, {
      label: 'Oracle > Travel > Interest > Types > Leisure & Vacation Travelers',
      comparable: true
    }, {
      label: 'Interest > Hobbies, Games & Toys',
      comparable: true
    }, {
      label: 'Oracle > Media & Entertainment > Sports',
      comparable: true
    }, {
      label: 'Demographic > Family Composition > Family Position',
      comparable: true
    }, {
      label: 'DLX Subscription Services',
      comparable: true
    }, {
      label: 'DLX Auto Proximity',
      comparable: true
    }, {
      label: 'DLX Retail Proximity: Retailers',
      comparable: true
    }, {
      label: 'Visa Audiences powered by Oracle',
      comparable: true
    }, {
      label: 'DLX Lifestyles',
      comparable: true
    }, {
      label: 'DLX Telecommunications Visitation',
      comparable: true
    }, {
      label: 'DLX Financial Services Visitation',
      comparable: true
    }, {
      label: 'DLX Consumer Technology Visitation',
      comparable: true
    }, {
      label: 'DLX CPG',
      comparable: true
    }, {
      label: 'DLX Retail Visitation',
      comparable: true
    }, {
      label: 'DLX CPG Brands',
      comparable: true
    }, {
      label: 'DLX Media and Entertainment Visitation',
      comparable: true
    },
    // {
    //   label: 'DLX Demographics',
    //   comparable: true
    // },
    {
      label: 'DLX Wellness',
      comparable: true
    }, {
      label: 'DLX CPG BuyStyles',
      comparable: true
    },
    // {
    //   label: 'DLX Travel',
    //   comparable: true
    // },
    {
      label: 'DLX Seasonal',
      comparable: true
    }, {
      label: 'DLX Restaurants Visitation',
      comparable: true
    }, {
      label: 'DLX Retail',
      comparable: true
    }, {
      label: 'DLX CPG Visitation',
      comparable: true
    }, {
      label: 'DLX Other',
      comparable: true
    }, {
      label: 'DLX Retail Brands',
      comparable: true
    }, {
      label: 'DLX Auto Visitation',
      comparable: true
    }, {
      label: 'DLX Retail Proximity: Restaurants',
      comparable: true
    }, {
      label: 'DLX Finance',
      comparable: true
    }, {
      label: 'DLX Politics',
      comparable: true
    }, {
      label: 'DLX Consumer Technology',
      comparable: true
    }, {
      label: 'DLX Telecommunications',
      comparable: true
    }, {
      label: 'DLX TV powered by TRA',
      comparable: true
    }, {
      label: 'Edmunds powered by Oracle',
      comparable: true
    }, {
      label: 'DLX Philanthropy',
      comparable: true
    }, {
      label: 'DLX CPG Proximity',
      comparable: true
    }, {
      label: 'brandSpend',
      defaultValue: 'number'
    }, {
      label: 'upcSpend',
      defaultValue: 'number'
    }, {
      label: 'categoriesSpend',
      defaultValue: 'number'
    }, {
      label: 'shareOfWallet',
      defaultValue: 'number'
    }],
    exportSchema: [{
      metrics: ['AFFLUENCE']
    }, {
      metrics: ['SPNEEDS']
    }, {
      metrics: ['COOK']
    }, {
      metrics: ['ETHNIC']
    }, {
      metrics: ['HW']
    }, {
      metrics: ['DIETER']
    }, {
      metrics: ['BABYKIDS']
    }, {
      metrics: ['GENAGE']
    }, {
      metrics: ['PETS']
    }, {
      metrics: ['Past Purchases > Consumer Packaged Goods (CPG) > Food & Beverages > Snacks, Cookies & Candy']
    }, {
      metrics: ['Past Purchases > Consumer Packaged Goods (CPG) > Food & Beverages > Beverages']
    }, {
      metrics: ['In-Market > Consumer Packaged Goods (CPG) > Food & Beverages']
    }, {
      metrics: ['Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Pantry > Condiments, Sauces & Spreads']
    }, {
      metrics: ['Past Purchases > Consumer Packaged Goods (CPG) > Food & Beverages > Bakery']
    }, {
      metrics: ['Past Purchases > Consumer Packaged Goods (CPG) > Food & Beverages > Breakfast & Cereals']
    }, {
      metrics: ['Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Fresh Foods > Meat & Seafood > Meat']
    }, {
      metrics: ['Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Fresh Foods > Meat & Seafood']
    }, {
      metrics: ['Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Pantry > Sweets & Snacks > Salty Snacks']
    }, {
      metrics: ['Past Purchases > Consumer Packaged Goods (CPG) > Food & Beverages > Produce']
    }, {
      metrics: ['Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Frozen Foods > Frozen Vegetables']
    }, {
      metrics: ['Past Purchases > Consumer Packaged Goods (CPG) > Health & Beauty > Personal Care > Dental']
    }, {
      metrics: ['Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Health & Beauty Products']
    }, {
      metrics: ['Past Purchases > Consumer Packaged Goods (CPG) > Health & Beauty']
    }, {
      metrics: ['Past Purchases > Consumer Packaged Goods (CPG) > Health & Beauty > Beauty']
    }, {
      metrics: ['Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Health & Beauty Products > Pain Relief']
    }, {
      metrics: ['Past Purchases > Consumer Packaged Goods (CPG) > Health & Beauty > Beauty > Hair Care']
    }, {
      metrics: ['Past Purchases > Consumer Packaged Goods (CPG) > Brands > Health & Beauty']
    }, {
      metrics: ['Past Purchases > Consumer Packaged Goods (CPG) > Household Supplies > Kitchen Supplies']
    }, {
      metrics: ['Past Purchases > Consumer Packaged Goods (CPG) > Household Supplies']
    }, {
      metrics: ['Past Purchases > Consumer Packaged Goods (CPG) > Household Supplies > Cleaning & Laundry']
    }, {
      metrics: ['Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Household Supplies > Cleaning Supplies']
    }, {
      metrics: ['Past Purchases > Consumer Packaged Goods (CPG) > Gifts']
    }, {
      metrics: ['Past Purchases > Consumer Packaged Goods (CPG) > Brands > Baby Care']
    }, {
      metrics: ['Past Purchases > Consumer Packaged Goods (CPG) > Brands > Pet Supplies']
    }, {
      metrics: ['Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Pet Care']
    }, {
      metrics: ['Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Pet Care > Dog Food & Supplies']
    }, {
      metrics: ['Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Pet Care > Cat Food & Supplies']
    }, {
      metrics: ['Category']
    }, {
      metrics: ['SubCategory']
    }, {
      metrics: ['Payment Type']
    }, {
      metrics: ['Channel']
    }, {
      metrics: ['Oracle > Retail > Purchase-Based > Categories > Fashion & Apparel > Clothing']
    }, {
      metrics: ['Oracle > Retail > Purchase-Based > Categories > Sports Equipment & Outdoor Gear > Outdoor Gear']
    }, {
      metrics: ['Past Purchases > Retail > Clothing, Shoes & Accessories > Clothing > Styles']
    }, {
      metrics: ['Past Purchases > Retail > Clothing, Shoes & Accessories > Clothing > Type']
    }, {
      metrics: ['Oracle > Consumer Technology > Interest > Brand Affinity']
    }, {
      metrics: ['Oracle > Consumer Technology > Interest > Mobile Phones']
    }, {
      metrics: ['Oracle > Consumer Technology > Interest']
    }, {
      metrics: ['Oracle > Media & Entertainment > Video Games > Platforms']
    }, {
      metrics: ['Travel']
    }, {
      metrics: ['Television > Viewership > Shows > BBC']
    }, {
      metrics: ['Television > Viewership > Shows > Discovery']
    }, {
      metrics: ['Television > Viewership > Shows > ABC']
    }, {
      metrics: ['Television > Viewership > Shows > NBC']
    }, {
      metrics: ['Television > Viewership > Shows > CBS']
    }, {
      metrics: ['Television > Viewership > Shows > Fox']
    }, {
      metrics: ['Television > Viewership > Shows > HBO']
    }, {
      metrics: ['Television > Viewership > Shows > ESPN']
    }, {
      metrics: ['Television > Viewership > Shows > Netflix']
    }, {
      metrics: ['Television > Viewership > Shows > Comedy Central']
    }, {
      metrics: ['Television > Viewership > Shows > Showtime']
    }, {
      metrics: ['Oracle > Media & Entertainment > Movies > Genres']
    }, {
      metrics: ['Interest > Video Games > Genres']
    }, {
      metrics: ['Oracle > Media & Entertainment > Music > Genres']
    }, {
      metrics: ['Television > Viewership > Genres']
    }, {
      metrics: ['Oracle > Media & Entertainment > Television > Genres > Sports']
    }, {
      metrics: ['Oracle > Media & Entertainment > Television > Genres > Awards Shows']
    }, {
      metrics: ['Oracle > Media & Entertainment > Sports > Individual Sports']
    }, {
      metrics: ['Reading']
    }, {
      metrics: ['Hobby']
    }, {
      metrics: ['Sporting Interest']
    }, {
      metrics: ['Sporting Interests']
    }, {
      metrics: ['Household Composition']
    }, {
      metrics: ['Occupation']
    }, {
      metrics: ['Occupation - Specific']
    }, {
      metrics: ['Oracle > Demographic > Household Composition > Age of Children']
    }, {
      metrics: ['Investments']
    }, {
      metrics: ['Ethnic Group']
    }, {
      metrics: ['Oracle > Demographic > Financial Attributes > Wealth Decile']
    }, {
      metrics: ['Demographic > Housing Attributes > Median Home Value']
    }, {
      metrics: ['Oracle > Demographic > Financial Attributes > Estimated Household Income (USD)']
    }, {
      metrics: ['Oracle > Demographic > Language']
    }, {
      metrics: ['Country of Origin']
    }, {
      metrics: ['Oracle > Demographic > Marital Status']
    }, {
      metrics: ['Dwelling Type']
    }, {
      metrics: ['Race']
    }, {
      metrics: ['Religion']
    }, {
      metrics: ['Demographic > Education']
    }, {
      metrics: ['Oracle > B2B > Firmographic > Company Size']
    }, {
      metrics: ['Branded Data > Datalogix > DLX Lifestyles']
    }, {
      metrics: ['Branded Data > Datalogix > DLX Lifestyles > Millennials']
    }, {
      metrics: ['Branded Data > Datalogix > DLX Lifestyles > Moms']
    }, {
      metrics: ['Branded Data > Datalogix > DLX Lifestyles > Deal Seekers']
    }, {
      metrics: ['Branded Data > Datalogix > DLX Lifestyles > Frequent Travelers']
    }, {
      metrics: ['Oracle > Autos > Owners > Makes']
    }, {
      metrics: ['Interest > Autos > Classes']
    }, {
      metrics: ['Past Purchases > Other Vehicles > Motorcycles > Makes']
    }, {
      metrics: ['Oracle > Autos > Interest > Auto Enthusiasts']
    }, {
      metrics: ['Oracle > Autos > Interest > Body Styles']
    }, {
      metrics: ['Oracle > Autos > Interest > Condition']
    }, {
      metrics: ['Oracle > Autos > Owners > Vehicle Age']
    }, {
      metrics: ['PriceSegments']
    }, {
      metrics: ['DealSegments']
    }, {
      metrics: ['Brand']
    }, {
      metrics: ['UPC']
    }, {
      metrics: ['Categories']
    }, {
      metrics: ['Healthy Living Couples']
    }, {
      metrics: ['Healthy Living Families']
    }, {
      metrics: ['Premium On-the-Go Couples & Families']
    }, {
      metrics: ['Watching the Waistline Singles/Couples']
    }, {
      metrics: ['Watching the Waistline Families']
    }, {
      metrics: ['Finest-Focused Couples and Families']
    }, {
      metrics: ['Low-Cooking Singles']
    }, {
      metrics: ['Quick Cooking Families']
    }, {
      metrics: ['Meat & Potatoes Families']
    }, {
      metrics: ['Families with Babies']
    }, {
      metrics: ['Kid-Focused Families']
    }, {
      metrics: ['Older Adults']
    }, {
      metrics: ['Natural/Organic Households']
    }, {
      metrics: ['Hispanic Households']
    }, {
      metrics: ['Kosher Households']
    }, {
      metrics: ['Television > Viewership > Shows']
    }, {
      metrics: ['Oracle > Travel > In-Market > Products > Air Travel > Airlines']
    }, {
      metrics: ['Oracle > Travel > In-Market > Advance Booking']
    },
    // Potentials

    {
      metrics: ['Interest > Food & Drink']
    }, {
      metrics: ['Oracle > Consumer Packaged Goods (CPG) > Interest > Eating Styles']
    }, {
      metrics: ['Oracle > Media & Entertainment > Events & Attractions']
    }, {
      metrics: ['Past Purchases > Retail > Home & Garden > Indoors']
    }, {
      metrics: ['Oracle > Financial Services']
    }, {
      metrics: ['Oracle > Life Stages & Events > Education & Career']
    }, {
      metrics: ['Oracle > Hobbies & Interests > Internet & Online Activities']
    }, {
      metrics: ['Oracle > Lifestyles > Enthusiasts']
    }, {
      metrics: ['Demographic > Age > Lifestages']
    }, {
      metrics: ['Oracle > Retail > Proximity > Fashion & Apparel']
    }, {
      metrics: ['Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Household Supplies']
    }, {
      metrics: ['Oracle > Retail > Proximity > Home & Garden']
    }, {
      metrics: ['Interest > Education']
    }, {
      metrics: ['Oracle > Media & Entertainment > Sports > Team Sports']
    }, {
      metrics: ['Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Pantry > Cereals & Bars > Cold Cereal']
    }, {
      metrics: ['Branded Data > Datalogix > DLX Seasonal > Winter Seasonal']
    }, {
      metrics: ['Oracle > Travel > Interest > Types']
    }, {
      metrics: ['Oracle > Demographic > Financial Attributes > Estimated Net Worth (USD)']
    }, {
      metrics: ['Oracle > Hobbies & Interests > Politics & Society']
    }, {
      metrics: ['Oracle > Media & Entertainment > Television > Networks']
    }, {
      metrics: ['Oracle > Restaurants > Proximity > Casual Dining']
    }, {
      metrics: ['Oracle > Media & Entertainment > Streaming Media & Downloads > Streaming Providers']
    }, {
      metrics: ['Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Frozen Foods']
    }, {
      metrics: ['Branded Data > Datalogix > DLX Seasonal > Fall Seasonal']
    }, {
      metrics: ['Oracle > Lifestyles > Parents']
    }, {
      metrics: ['Interest > Lifestyles']
    }, {
      metrics: ['Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Beverages > Juice']
    }, {
      metrics: ['Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > BuyStyles']
    }, {
      metrics: ['Interest > Travel > Products']
    }, {
      metrics: ['Oracle > Retail > Proximity > Department Stores']
    }, {
      metrics: ['Oracle > Hobbies & Interests > Health & Fitness > Exercise']
    }, {
      metrics: ['Oracle > Hobbies & Interests > Shopping']
    }, {
      metrics: ['Past Purchases > Consumer Packaged Goods (CPG) > Health & Beauty > Personal Care']
    }, {
      metrics: ['Interest > Parenting & Family']
    }, {
      metrics: ['Oracle > Consumer Packaged Goods (CPG) > Interest > Product Categories > Beverages']
    }, {
      metrics: ['Oracle > Seasonal & Holiday > Winter > Holiday Shoppers']
    }, {
      metrics: ['Oracle > Restaurants > Proximity > Delivery & Take Out']
    }, {
      metrics: ['Interest > Arts & Entertainment']
    }, {
      metrics: ['Oracle > Lifestyles > Moms']
    }, {
      metrics: ['Language']
    }, {
      metrics: ['Oracle > Hobbies & Interests > Science & Humanities']
    }, {
      metrics: ['Interest > Politics & Society']
    }, {
      metrics: ['Oracle > Media & Entertainment > Video Games > Games > Action, Role-Playing & FPS']
    }, {
      metrics: ['Branded Data > Datalogix > DLX Seasonal > Spring Seasonal']
    }, {
      metrics: ['Past Purchases > Financial Products & Services > Credit Cards > Types']
    }, {
      metrics: ['Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Pantry > Sweets & Snacks']
    }, {
      metrics: ['Interest > Sports & Recreation > Sports > Water Sports']
    }, {
      metrics: ['Oracle > Hobbies & Interests > Outdoor Activities']
    }, {
      metrics: ['Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Fresh Foods > Dairy & Eggs']
    }, {
      metrics: ['Oracle > Restaurants > Cuisine Type']
    }, {
      metrics: ['Oracle > Consumer Packaged Goods (CPG) > Interest > Product Categories > Health & Beauty Products']
    }, {
      metrics: ['Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Pantry']
    }, {
      metrics: ['Interest > Arts & Entertainment > Events & Attractions']
    }, {
      metrics: ['Past Purchases > Consumer Packaged Goods (CPG) > Brands > Household Supplies']
    }, {
      metrics: ['Oracle > Hobbies & Interests']
    }, {
      metrics: ['Branded Data > Datalogix > DLX Seasonal > Summer Seasonal']
    }, {
      metrics: ['Past Purchases > Consumer Packaged Goods (CPG) > Food & Beverages']
    }, {
      metrics: ['Past Purchases > Consumer Packaged Goods (CPG) > Brands > Food & Beverages']
    }, {
      metrics: ['Oracle > Media & Entertainment > Television > Shows']
    }, {
      metrics: ['Oracle > Media & Entertainment']
    }, {
      metrics: ['Gender']
    }, {
      metrics: ['Demographic > Age']
    }, {
      metrics: ['Oracle > Consumer Packaged Goods (CPG) > Datalogix (DLX) Purchase-Based > Categories & Brands > Beverages > Carbonated > Regular Carbonated']
    }, {
      metrics: ['Oracle > Restaurants > Proximity > QSR (Fast Food)']
    }, {
      metrics: ['Oracle > Hobbies & Interests > Politics & Society > Charitable Giving > Causes']
    }, {
      metrics: ['Oracle > Restaurants > Dining Type']
    }, {
      metrics: ['Oracle > Travel > Interest > Types > Leisure & Vacation Travelers']
    }, {
      metrics: ['Interest > Hobbies, Games & Toys']
    }, {
      metrics: ['Oracle > Media & Entertainment > Sports']
    }, {
      metrics: ['Demographic > Family Composition > Family Position']
    }, {
      metrics: ['DLX Subscription Services']
    }, {
      metrics: ['DLX Auto Proximity']
    }, {
      metrics: ['DLX Retail Proximity: Retailers']
    }, {
      metrics: ['Visa Audiences powered by Oracle']
    }, {
      metrics: ['DLX Lifestyles']
    }, {
      metrics: ['DLX Telecommunications Visitation']
    }, {
      metrics: ['DLX Financial Services Visitation']
    }, {
      metrics: ['DLX Consumer Technology Visitation']
    }, {
      metrics: ['DLX CPG']
    }, {
      metrics: ['DLX Retail Visitation']
    }, {
      metrics: ['DLX CPG Brands']
    }, {
      metrics: ['DLX Media and Entertainment Visitation']
    }, {
      metrics: ['DLX Wellness']
    }, {
      metrics: ['DLX CPG BuyStyles']
    }, {
      metrics: ['DLX Seasonal']
    }, {
      metrics: ['DLX Restaurants Visitation']
    }, {
      metrics: ['DLX Retail']
    }, {
      metrics: ['DLX CPG Visitation']
    }, {
      metrics: ['DLX Other']
    }, {
      metrics: ['DLX Retail Brands']
    }, {
      metrics: ['DLX Auto Visitation']
    }, {
      metrics: ['DLX Retail Proximity: Restaurants']
    }, {
      metrics: ['DLX Finance']
    }, {
      metrics: ['DLX Politics']
    }, {
      metrics: ['DLX Consumer Technology']
    }, {
      metrics: ['DLX Telecommunications']
    }, {
      metrics: ['DLX TV powered by TRA']
    }, {
      metrics: ['Edmunds powered by Oracle']
    }, {
      metrics: ['DLX Philanthropy']
    }, {
      metrics: ['DLX CPG Proximity']
    }]
  };
  /* eslint-enable */
  var _default = _exports.default = {
    taxonomy: taxonomy
  };
});