define("affinio/templates/components/modules/engagement-grid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "mriO7YnD",
    "block": "{\"symbols\":[],\"statements\":[[7,\"header\"],[11,\"class\",\"clearfix\"],[9],[0,\"\\n  \"],[7,\"h4\"],[9],[0,\"Hour & Day Heatmap\"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"postsTimeSeries\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"bar-chart\",null,[[\"source\",\"class\",\"type\",\"activeRange\"],[[23,[\"postsTimeSeries\",\"daySummary\"]],\"day-summary\",\"vertical\",[23,[\"preferences\",\"activeEngagement\"]]]]],false],[0,\"\\n  \"],[1,[27,\"heatmap-grid\",null,[[\"records\"],[[23,[\"postsTimeSeries\"]]]]],false],[0,\"\\n  \"],[1,[27,\"bar-chart\",null,[[\"source\",\"class\",\"type\",\"activeRange\"],[[23,[\"postsTimeSeries\",\"hourSummary\"]],\"hour-summary\",\"horizontal\",[23,[\"preferences\",\"activeEngagement\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/modules/engagement-grid.hbs"
    }
  });
});