define("affinio/components/modals/modal-edit-cluster", ["exports", "affinio/components/modals/modal-base", "affinio/utils/mutations"], function (_exports, _modalBase, _mutations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _modalBase.default.extend({
    classNames: ['name-suggestions'],
    tracker: Ember.inject.service(),
    user: Ember.inject.service(),
    // to get user.account.preferences.shouldNameClusters (template)
    router: Ember.inject.service(),
    notify: Ember.inject.service(),
    modalManager: Ember.inject.service(),
    path: Ember.inject.service(),
    graph: Ember.inject.service(),
    accentStlye: Ember.computed('tribe_color', function () {
      return Ember.String.htmlSafe("border-left: 7px solid ".concat(Ember.get(this, 'clusterColor'), ";"));
    }),
    placeholderSafe: Ember.computed('placeholder', function () {
      return Ember.String.htmlSafe(Ember.get(this, 'placeholder'));
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.$('input').focus(function () {
        this.select();
      });
      this.$('input').focus();
      // console.log('here tho', get(this, 'traits'));
    },
    actions: {
      requestName: function requestName() {
        var _this = this;
        Ember.get(this, 'graph').mutate(_mutations.default.requestClusterNameRecommendations(Ember.get(this, 'report'))).then(function () {
          Ember.get(_this, 'notify').success('Your request for cluster name recommendations is now processing.');
          Ember.set(_this, 'report.clustersBeingNamed', true);
          Ember.get(_this, 'tracker').track('User requested recommend names be generated', {
            clusterID: Ember.get(_this, 'cluster.id')
          });
          _this.send('close');
        }).catch(function () {
          Ember.get(_this, 'modalManager').openModal('modals/modal-error', {
            title: 'Error',
            reason: 'Due to unforeseen circumstances your request failed',
            fix: 'Please refresh and try again'
          });
        });
      },
      useRecommendedName: function useRecommendedName() {
        Ember.set(this, 'inputValue', Ember.get(this, 'recommendedName'));
        Ember.get(this, 'tracker').track('User clicked Recommended Name Save button', {
          clusterID: Ember.get(this, 'cluster.id'),
          recommendedName: Ember.get(this, 'recommendedName')
        });
        this.send('ok');
      },
      ok: function ok() {
        var okAction = Ember.get(this, 'okAction');
        var input = Ember.get(this, 'inputValue');
        if (!okAction || !input) {
          return;
        }
        if (typeof okAction === 'function') {
          okAction(input);
        }
        if (Ember.get(this, 'saveComing')) {
          Ember.set(this, 'saving', true);
        } else {
          this.closeAction();
        }
      },
      headToPreferences: function headToPreferences() {
        Ember.get(this, 'router').transitionTo('settings.preferences');
        this.send('close');
      },
      seeAllTraits: function seeAllTraits() {
        Ember.get(this, 'router').transitionTo('reports.report.version.cluster.category.subcategory', Ember.get(this, 'path.currentReportId'), Ember.get(this, 'path.currentVersion'), Ember.get(this, 'cluster.id'), 'overview', 'traits');
        this.send('close');
      }
    } // actions
  });
});