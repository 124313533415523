define("affinio/templates/components/highlight-linechart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "qdPN9od3",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\"],[11,\"class\",\"line-chart\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"isMouseOver\"]]],null,{\"statements\":[[0,\"    \"],[7,\"g\"],[11,\"class\",\"mouseover-highlight\"],[9],[0,\"\\n      \"],[7,\"rect\"],[11,\"class\",\"date\"],[9],[0,\"\\n        \"],[7,\"text\"],[9],[0,\"Date:\"],[10],[0,\"\\n        \"],[7,\"text\"],[9],[0,\"\\n          \"],[1,[21,\"mouseOverDate\"],false],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/highlight-linechart.hbs"
    }
  });
});