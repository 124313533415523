define("affinio/components/network-graph", ["exports", "affinio/utils/get-with-default", "affinio/utils/affinio", "affinio/utils/constants/svg-font"], function (_exports, _getWithDefault, _affinio, _svgFont) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
  var _default = _exports.default = Ember.Component.extend({
    // Element
    classNames: ['vis', 'network-graph', 'no-min-height'],
    attributeBindings: ['width', 'height'],
    width: '100%',
    height: '100%',
    // Services
    preferences: Ember.inject.service(),
    path: Ember.inject.service(),
    networkGraph: Ember.inject.service(),
    tracker: Ember.inject.service(),
    router: Ember.inject.service(),
    // Lifecycle
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (!Ember.get(this, 'noLabels')) {
        Ember.set(this, 'showLabels', true);
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      // if no svgElement yet, set. Or if tribes do not match old tribes
      // console.log('dRA',  get(this, 'tribes').join(), get(this, 'oldTribes').join(), get(this, 'tribes').mapBy('name').join(), get(this, 'oldTribes') ? get(this, 'oldTribes').mapBy('name').join() : null );
      if (!Ember.get(this, 'svgEl') || Ember.get(this, 'reportId') !== Ember.get(this, 'oldreportId')) {
        if (Ember.get(this, 'svg')) {
          Ember.set(this, 'svgEl', Ember.get(this, 'networkGraph').getCached(Ember.get(this, 'svg'), Ember.get(this, 'clusters')));
          Ember.set(this, 'oldreportId', Ember.get(this, 'reportId'));
        }
      }
    },
    didRender: function didRender() {
      var _this = this;
      // on didRender, check if svgEl.isFulfilled and labelRoute
      // also check if a label transition closure route action was passed down
      // if so, initialize the click event event on each label with the passed down route-action.
      // bubbles to the default transition action defined in audience.js route unless a substitute is supplied in the specific page's route
      // default behaviour is 'transition to same page but with model of passed in cluster'
      if (Ember.get(this, 'svgEl.isFulfilled') && Ember.get(this, 'labelRoute')) {
        this.$('g[data-cluster]').on('click', function (e) {
          var cluster = d3.select(e.currentTarget).attr('data-cluster');
          if (cluster !== Ember.get(_this, 'current')) {
            Ember.get(_this, 'router').transitionTo(Ember.get(_this, 'labelRoute'), Ember.get(_this, 'path.currentReportId'), Ember.get(_this, 'path.currentVersion'), cluster, (0, _getWithDefault.default)(_this, 'labelCategory', Ember.get(_this, 'path.currentCategory')), (0, _getWithDefault.default)(_this, 'labelSubcategory', Ember.get(_this, 'path.currentSubcategory')));
          }
        });
      }
      if (!Ember.get(this, 'traits')) {
        d3.selectAll('g[data-cluster]').on('mouseout', null);
        d3.selectAll('g[data-cluster]').on('mouseover', null);
        d3.selectAll('.frame').on('mouseout', null);
        d3.selectAll('.frame').on('mouseover', null);
      }
      this.styleByCurrentTribe();
      this.showHideLabels();
      if (Ember.get(this, 'baselined')) {
        this.send('fadeIndistinctClusters', Ember.get(this, 'baselined'));
      } else if (Ember.get(this, 'indistinctClustersFaded')) {
        this.send('fillClusterOpacity');
      }
    },
    willDestroyElement: function willDestroyElement() {
      this.$('g[data-cluster]').off('click');
    },
    updateSvgURI: function updateSvgURI() {
      window.console.warn('updateSvgURI not passed in to network-graph');
    },
    // Observers
    svgLoaded: Ember.observer('svgEl.isFulfilled', function () {
      var _this2 = this;
      if (Ember.get(this, 'svgEl.isFulfilled')) {
        Ember.run.scheduleOnce('afterRender', function () {
          _this2.styleByCurrentTribe();
          _this2.setCustomCategoricalColors();
          _this2.showHideLabels(_this2);
          Ember.run.later(_this2, function () {
            _this2.styleLabels();
          });
          if (Ember.get(_this2, 'traits')) {
            _this2.setTraitEvents();
          }
        });
      }
    }),
    styleByCurrentTribe: Ember.observer('current', 'currents.length', 'clusters.@each.{isHidden}', function () {
      var current = Ember.get(this, 'current');
      var currents = Ember.get(this, 'currents');
      var clusters = Ember.get(this, 'clusters');
      var svg = d3.select(this.$('svg')[0]);
      var hiddenClusters = clusters.filterBy('isHidden');
      var hiddenIds = hiddenClusters.mapBy('id');
      if (current && clusters) {
        svg.selectAll('.node').style('opacity', function () {
          if (hiddenIds.includes(d3.select(this).attr('data-cluster'))) {
            return 0.1;
          } else {
            return d3.select(this).attr('data-cluster') === current ? 1 : 0.1;
          }
        });
        svg.selectAll('g').style('opacity', function () {
          if (hiddenIds.includes(d3.select(this).attr('data-cluster'))) {
            return 0;
          } else {
            return d3.select(this).attr('data-cluster') === current ? 1 : 0.125;
          }
        });
        svg.selectAll('.group').style('opacity', function () {
          return d3.select(this).attr('data-cluster') === current ? 0.2 : 0;
        });
        svg.selectAll('g').classed('showLabels', function () {
          return hiddenIds.includes(d3.select(this).attr('data-cluster')) ? false : true;
        });
        svg.selectAll('.link').style('opacity', function () {
          if (hiddenIds.includes(d3.select(this).attr('data-target-cluster')) || hiddenIds.includes(d3.select(this).attr('data-source-cluster'))) {
            return 0;
          } else {
            return d3.select(this).attr('data-target-cluster') === current ? 1 : 0;
          }
        });
      } else if (currents && currents.length) {
        // multiple compared clusters
        svg.selectAll('.node').style('opacity', function () {
          return currents.mapBy('id').indexOf(d3.select(this).attr('data-cluster')) > -1 ? 1 : 0;
        });
        svg.selectAll('g').style('opacity', function () {
          return currents.mapBy('id').indexOf(d3.select(this).attr('data-cluster')) > -1 ? 1 : 0;
        });
        svg.selectAll('.group').style('opacity', function () {
          return currents.mapBy('id').indexOf(d3.select(this).attr('data-cluster')) > -1 ? 0.2 : 0;
        });
        svg.selectAll('.link').style('opacity', function () {
          return currents.mapBy('id').indexOf(d3.select(this).attr('data-target-cluster')) > -1 ? 1 : 0;
        });
      } else {
        svg.selectAll('.node').style('opacity', function () {
          return hiddenIds.includes(d3.select(this).attr('data-cluster')) ? 0.1 : 1;
        });
        svg.selectAll('g').classed('showLabels', function () {
          return hiddenIds.includes(d3.select(this).attr('data-cluster')) ? false : true;
        });
        svg.selectAll('g').style('opacity', function () {
          return hiddenIds.includes(d3.select(this).attr('data-cluster')) ? 0 : 1;
        });
        svg.selectAll('.group').style('opacity', 0);
        svg.selectAll('.link').style('opacity', function () {
          return hiddenIds.includes(d3.select(this).attr('data-target-cluster')) || hiddenIds.includes(d3.select(this).attr('data-source-cluster')) ? 0.1 : 1;
        });
      }
    }),
    styleLabels: Ember.observer('clusters.@each.{index,name}', 'preferences.categoricalColors', 'labelBackground', function () {
      var _this3 = this;
      // NOTE: Had to move this here out of the loop in audience afterModel
      // due to render specific properties not being ready if initail load was
      // from any page other than the audience.overview or tribe.overview.graphics

      // Ember run loop here feels bad, but without it, nodes aren't found / the !nodes.length condition kicks us out
      (0, _affinio.maybeRunLater)(this, function () {
        var svgD3 = d3.select(_this3.$('svg')[0]);
        var clusters = Ember.get(_this3, 'clusters');
        if (clusters) {
          clusters.forEach(function (cluster) {
            var clusterId = cluster.id;
            var box = svgD3.selectAll("g[data-cluster=\"".concat(clusterId, "\"]"));
            var nodes = svgD3.selectAll(".node[data-cluster=\"".concat(clusterId, "\"]"))[0] || [];
            if (!nodes.length) {
              return;
            }
            var cxAvg = nodes.reduce(function (totalX, node) {
              return totalX + parseFloat(d3.select(node).attr('cx'));
            }, 0) / nodes.length;
            var cyAvg = nodes.reduce(function (totalY, node) {
              return totalY + parseFloat(d3.select(node).attr('cy'));
            }, 0) / nodes.length;
            var label = box.selectAll('svg').style('opacity', '0').attr('x', cxAvg - 5).attr('y', cyAvg - 15);
            var rect = box.selectAll('.label-rect');
            var accent = box.selectAll('.accent').attr('x', 2).attr('y', 2).attr('fill', d3.select(nodes[0]).style('fill'));
            var text = box.selectAll('text').text(Ember.get(cluster, 'name')).attr('x', 12).attr('y', 15).attr('width', null);
            text.attr('width', function () {
              return this.getBBox().width > 10 ? this.getBBox().width : 150;
            });
            rect.attr('width', function () {
              return parseFloat(text.attr('width')) + 20;
            });
            rect.attr('fill', Ember.get(_this3, 'labelBackground'));
            var labelBackground = Ember.get(_this3, 'labelBackground');
            if (labelBackground === 'white') {
              box.attr('display', 'initial');
              text.attr('fill', 'black');
            } else if (labelBackground === 'black') {
              box.attr('display', 'initial');
              text.attr('fill', 'white');
            } else {
              box.attr('display', 'none');
            }
            label.attr('width', function () {
              return parseFloat(text.attr('width')) + 20;
            });
            accent.attr('width', 4);
            // prevent x-axis out of bounds
            label.attr('x', function () {
              var x = parseFloat(label.attr('x')) - parseFloat(text.attr('width')) / 2;
              if (x < 0) {
                return 0;
              } else if (x + parseFloat(label.attr('width')) > 500) {
                return 500 - parseFloat(label.attr('width'));
              } else {
                return x;
              }
            }); // label attr x
            // prevent y-axis out of bounds
            label.attr('y', function () {
              var y = parseFloat(label.attr('y'));
              if (y < 0) {
                return 0;
              } else if (y + 20 > 500) {
                return 500 - 20;
              } else {
                return y;
              }
            }); // label attr y
          }); // forEach

          var labels = svgD3.selectAll('g svg');
          var alpha = 1;
          var spacing = 20;

          // Test for collision between two objects. Unsure if needed to be parseFloat() or Number() - JS
          var contains = function contains(targetA, targetB) {
            return !(Number(targetB.attr('x')) > Number(targetA.attr('x')) + Number(targetA.attr('width')) || Number(targetB.attr('x')) + Number(targetB.attr('width')) < Number(targetA.attr('x')) || Number(targetB.attr('y')) > Number(targetA.attr('y')) + Number(targetA.attr('height')) || Number(targetB.attr('y')) + Number(targetB.attr('height')) < Number(targetA.attr('y')));
          }; // contains

          var _relax = function relax(nodes) {
            var again = false;
            // select label to compare
            nodes.each(function () {
              // label element
              var a = this;
              // d3 label element object
              var da = d3.select(a);
              var y1 = da.attr('y');
              // compare <a> to every other element
              nodes.each(function () {
                // label element to compare against
                var b = this;
                // <a> & <b> are the same element and don't collide
                if (a === b) {
                  return;
                }
                // d3 object for other label element
                var db = d3.select(b);
                // skip relax if no collision
                var collision = contains(db, da);
                if (!collision) {
                  return;
                }
                // Calculate the distance between
                var y2 = db.attr('y');
                var deltaY = y1 - y2;
                // If spacing is greater than specified spacing they don't collide
                if (Math.abs(deltaY) > spacing) {
                  return;
                }
                // If the labels collide, push each of the two labels up and down a little bit
                again = true;
                var sign = deltaY > 0 ? 1 : -1;
                var adjust = sign * alpha;
                da.attr('y', +y1 + adjust);
                db.attr('y', +y2 - adjust);
              });
            });
            // Adjust if collision again
            if (again) {
              Ember.run.later(_this3, function () {
                return _relax(nodes);
              }, 20);
            } else {
              labels.transition().style('opacity', '1');
              _this3.genSvgURI();
            }
          };

          // run relax() on labels during didInsertElement
          _relax(labels);
        }
      }, 1);
    }),
    setCustomCategoricalColors: Ember.observer('preferences.categoricalColors', '_clusters.@each.{index,color}', function () {
      var _this4 = this;
      var categoricalColors = Ember.get(this, 'preferences.categoricalColors');
      var clusters = Ember.get(this, 'model.report._clusters');
      if (categoricalColors && clusters) {
        // maybe run later or it doesn't work when you update colors and go back into report
        (0, _affinio.maybeRunLater)(this, function () {
          var svg = d3.select(_this4.$('svg')[0]);
          clusters.map(function (cluster) {
            var id = cluster.id;
            var clusterColor = Ember.get(cluster, 'color');
            svg.selectAll(".node[data-cluster=\"".concat(id, "\"]")).style('fill', clusterColor);
            svg.selectAll(".link[data-target-cluster=\"".concat(id, "\"]")).style('stroke', clusterColor);
            svg.selectAll(".group[data-cluster=\"".concat(id, "\"]")).style('fill', clusterColor).style('stroke', clusterColor);
          }); // map tribes by id
        }, 1);
      } // if color is set
    }),
    showHideLabels: function showHideLabels() {
      var _this5 = this;
      Ember.run.scheduleOnce('afterRender', function () {
        d3.select(_this5.$('svg')[0]).selectAll('g').attr('visibility', Ember.get(_this5, 'showLabels') ? 'visible' : 'hidden');
        _this5.genSvgURI();
      });
    },
    genSvgURI: function genSvgURI() {
      // We clone the svg as we make changes here because its weird with the runloop shenanigans - JK May 2019
      var _this$$$clone = this.$('svg').clone(),
        _this$$$clone2 = _slicedToArray(_this$$$clone, 1),
        svg = _this$$$clone2[0];

      // Fix the labels from the weirdness
      var showLabels = Ember.get(this, 'showLabels');
      var d3Svg = d3.select(svg);
      d3Svg.selectAll('g').attr('visibility', showLabels ? 'visible' : 'hidden');
      d3Svg.selectAll('g svg').style('opacity', showLabels ? '1' : '0');
      d3Svg.insert('style', ':first-child').text(_svgFont.default);
      var serializer = new XMLSerializer();
      var source = serializer.serializeToString(svg);

      // add svg meta info
      if (!source.match(/^<svg[^>]+xmlns="http:\/\/www\.w3\.org\/2000\/svg"/)) {
        source = source.replace(/^<svg/, '<svg xmlns="http://www.w3.org/2000/svg"');
      }
      if (!source.match(/^<svg[^>]+"http:\/\/www\.w3\.org\/1999\/xlink"/)) {
        source = source.replace(/^<svg/, '<svg xmlns:xlink="http://www.w3.org/1999/xlink"');
      }
      source = "<?xml version=\"1.0\" standalone=\"no\"?>\r\n".concat(source);

      // convert svg source to URI data scheme.
      var data = new Blob([source], {
        type: 'image/svg+xml'
      });
      var dataUrl = URL.createObjectURL(data);

      // send it up via passed in action
      Ember.get(this, 'updateSvgURI')(dataUrl);
    },
    // TODO: This is where you can solve for the mouse hover!!!!!!!
    // Functions
    setTraitEvents: function setTraitEvents() {
      var _this6 = this;
      var self = this;
      d3.select('.frame').on('mouseover', function () {
        var current = Ember.get(_this6, 'path.currentClusterID');
        d3.selectAll('g[data-cluster]').transition().style('opacity', function () {
          return d3.select(this).attr('data-cluster') === current ? 1 : 0.3;
        });
      }).on('mouseout', function () {
        var current = Ember.get(_this6, 'path.currentClusterID');
        d3.selectAll('g[data-cluster]').transition().style('opacity', function () {
          return d3.select(this).attr('data-cluster') === current ? 1 : 0.125;
        });
      });
      d3.selectAll('g[data-cluster]').on('mouseover', function () {
        var current = Ember.get(self, 'path.currentClusterID');
        var highlighted = d3.select(this).attr('data-cluster');
        d3.selectAll('g[data-cluster]').transition().style('opacity', function () {
          return d3.select(this).attr('data-cluster') === current ? 1 : 0.3;
        });
        d3.selectAll('.node').transition(400).style('opacity', function () {
          if (d3.select(this).attr('data-cluster') === highlighted && d3.select(this).attr('data-cluster') !== current) {
            return 0.3;
          }
          if (d3.select(this).attr('data-cluster') === current) {
            return 1;
          } else {
            return 0.1;
          }
        });
        d3.selectAll('.group').transition(400).style('opacity', function () {
          if (d3.select(this).attr('data-cluster') === highlighted && d3.select(this).attr('data-cluster') !== current) {
            return 0.05;
          }
          if (d3.select(this).attr('data-cluster') === current) {
            return 0.2;
          } else {
            return 0;
          }
        });
        d3.selectAll('.link').transition(400).style('opacity', function () {
          if (d3.select(this).attr('data-target-cluster') === highlighted && d3.select(this).attr('data-target-cluster') !== current) {
            return 0.45;
          }
          if (d3.select(this).attr('data-target-cluster') === current) {
            return 1;
          } else {
            return 0;
          }
        });
      }) // mouseover
      .on('mouseout', function () {
        var current = Ember.get(this, 'path.currentClusterID');
        var highlighted = d3.select(this).attr('data-cluster');
        // d3.selectAll('g[data-cluster]')
        //   .transition()
        //   .style('opacity', function(){
        //     return d3.select(this).attr('data-cluster') === current ? 1 : 0.3;
        //   });
        d3.selectAll('.node').transition(400).style('opacity', function () {
          if (d3.select(this).attr('data-cluster') === highlighted && d3.select(this).attr('data-cluster') !== current) {
            return 0.1;
          }
          if (d3.select(this).attr('data-cluster') === current) {
            return 1;
          } else {
            return 0.1;
            // console.log('not fired');
          }
        });
        d3.selectAll('.group').transition(400).style('opacity', function () {
          if (d3.select(this).attr('data-cluster') === highlighted && d3.select(this).attr('data-cluster') !== current) {
            return 0;
          }
          if (d3.select(this).attr('data-cluster') === current) {
            return 0.2;
          } else {
            return 0;
          }
        });
        d3.selectAll('.link').transition(400).style('opacity', function () {
          if (d3.select(this).attr('data-target-cluster') === highlighted && d3.select(this).attr('data-target-cluster') !== current) {
            return 0;
          }
          if (d3.select(this).attr('data-target-cluster') === current) {
            return 1;
          } else {
            // console.log('not fired');
            return 0;
          }
        });
      }); // mouseout
    },
    actions: {
      fadeIndistinctClusters: function fadeIndistinctClusters() {
        var _this7 = this;
        Ember.run.scheduleOnce('afterRender', function () {
          var svg = d3.select(_this7.$('svg')[0]);
          if (Ember.get(_this7, 'model.report.clusters').any(function (c) {
            return Ember.get(c, 'soWhat');
          })) {
            var clusterDistinctions = Ember.get(_this7, 'model.report.clusters').map(function (cluster) {
              return {
                id: Ember.get(cluster, 'id'),
                soWhat: Ember.get(cluster, 'soWhat') || 0
              };
            });
            var maxDomain = _.max(_affinio.baselineAudienceDistinctionRanges.mapBy('minSoWhat'));
            var opacityScale = d3.scale.linear().domain([0, maxDomain]).range([0.1, 1]);
            svg.selectAll('.node').style('fill-opacity', function () {
              var cluster = d3.select(this).attr('data-cluster');
              return opacityScale(Ember.get(clusterDistinctions.findBy('id', cluster), 'soWhat'));
            });
            svg.selectAll('g').style('fill-opacity', function () {
              var cluster = d3.select(this).attr('data-cluster');
              return opacityScale(Ember.get(clusterDistinctions.findBy('id', cluster), 'soWhat')) * 1.5;
            });
            svg.selectAll('.link').style('opacity', function () {
              var cluster = d3.select(this).attr('data-target-cluster');
              return opacityScale(Ember.get(clusterDistinctions.findBy('id', cluster), 'soWhat'));
            });
            Ember.set(_this7, 'indistinctClustersFaded', true);
          }
        });
      },
      fillClusterOpacity: function fillClusterOpacity() {
        var svg = d3.select(this.$('svg')[0]);
        svg.selectAll('.node').style('fill-opacity', 1);
        svg.selectAll('g').style('fill-opacity', 1);
        svg.selectAll('.link').style('opacity', 1);
      }
    }
  });
});