define("affinio/helpers/string-replace", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.stringReplace = stringReplace;
  function stringReplace(params, _ref) {
    var changeFrom = _ref.changeFrom,
      changeTo = _ref.changeTo,
      _ref$g = _ref.g,
      g = _ref$g === void 0 ? true : _ref$g,
      _ref$i = _ref.i,
      i = _ref$i === void 0 ? false : _ref$i,
      _ref$m = _ref.m,
      m = _ref$m === void 0 ? false : _ref$m,
      _ref$trim = _ref.trim,
      trim = _ref$trim === void 0 ? false : _ref$trim;
    if (!changeFrom || !changeTo) {
      throw new Error('Incorrect helper usage: missing named param changeFrom or changeTo');
    } else {
      if (params) {
        var flags = "".concat(g ? 'g' : '').concat(i ? 'i' : '').concat(m ? 'm' : '');
        var regex = new RegExp(changeFrom, flags);
        return params.map(function (n) {
          return typeof n === 'string' ? trim ? n.replace(regex, changeTo).trim() : n.replace(regex, changeTo) : n;
        });
      } else {
        return params;
      }
    }
  }
  var _default = _exports.default = Ember.Helper.helper(stringReplace); // {{string-replace "myString" "mySecondString"
  //    changeFrom="my"
  //    changeTo="your"
  //    g=true
  //    i=false
  //    m=false
  //    trim=false
  // }}
});