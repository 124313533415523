define("affinio/utils/constants/data-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.filterTypes = _exports.default = _exports.dataTypes = void 0;
  var dataTypes = _exports.dataTypes = ['integer', 'decimal', 'category'];
  var filterTypes = _exports.filterTypes = ['text', 'numeric', 'date'];
  var _default = _exports.default = {
    dataTypes: dataTypes,
    filterTypes: filterTypes
  };
});