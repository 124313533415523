define("affinio/models/asset-group", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var Model = _emberData.default.Model;
  var _default = _exports.default = Model.extend({
    assetType: attr('string'),
    name: attr('string'),
    assets: attr({
      defaultValue: function defaultValue() {
        return Ember.A();
      }
    }),
    socialNetwork: attr('string')
  });
});