define("affinio/components/modals/modal-persona", ["exports", "affinio/components/modals/modal-base", "affinio/utils/constants/personas"], function (_exports, _modalBase, _personas) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _modalBase.default.extend({
    personas: _personas.personas,
    selectedPersona: undefined,
    actions: {
      apply: function apply() {
        Ember.get(this, 'applyAction')(Ember.get(this, 'selectedPersona'));
      },
      setSelectedPersona: function setSelectedPersona(persona) {
        Ember.set(this, 'selectedPersona', persona);
      }
    }
  });
});