define("affinio/templates/components/affinio-table-cell/select-apply-all", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "p7IDHHpu",
    "block": "{\"symbols\":[\"type\"],\"statements\":[[4,\"power-select\",null,[[\"selected\",\"options\",\"onchange\",\"renderInPlace\",\"searchEnabled\"],[[27,\"get\",[[23,[\"row\"]],[23,[\"column\",\"valuePath\"]]],null],[23,[\"column\",\"availableTypes\"]],[27,\"action\",[[22,0,[]],[27,\"mut\",[[27,\"get\",[[23,[\"row\"]],[23,[\"column\",\"valuePath\"]]],null]],null]],null],true,false]],{\"statements\":[[0,\"  \"],[1,[22,1,[]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[7,\"span\"],[11,\"role\",\"button\"],[11,\"class\",\"apply-to-all\"],[9],[0,\"\\n  Apply to all\\n\"],[3,\"action\",[[22,0,[]],\"applyToAll\"]],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/affinio-table-cell/select-apply-all.hbs"
    }
  });
});