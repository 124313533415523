define("affinio/templates/components/google-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "c/hpcsgM",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"map-holster\"],[9],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/google-map.hbs"
    }
  });
});