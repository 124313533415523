define("affinio/utils/queries/estimation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.estimateReport = _exports.estimatePublisherReport = _exports.checkTwitterCache = void 0;
  // TODO this should be estimateFilter (backend make change first)
  // as it only accepts a single filter
  var estimateReport = _exports.estimateReport = function estimateReport(taxoID, filters) {
    return {
      networkName: 'estimateReport',
      queryString: "query($filters: ReportEstimateFiltersArg!) {\n      estimateReport(id: \"".concat(taxoID, "\" filters: $filters) {\n        size\n        couldEstimate\n      }\n    }"),
      queryMap: {},
      variables: {
        filters: filters
      }
    };
  };
  var checkTwitterCache = _exports.checkTwitterCache = function checkTwitterCache(handle, startDate, endDate) {
    return {
      networkName: 'checkTwitterCache',
      queryString: "query {\n      checkTwitterCache(handle: \"".concat(handle, "\", startDate: \"").concat(startDate, "\", endDate: \"").concat(endDate, "\") {\n        valid\n        alternativeStart\n        alternativeEnd\n      }\n    }"),
      queryMap: {}
    };
  };
  var estimatePublisherReport = _exports.estimatePublisherReport = function estimatePublisherReport(filters) {
    return {
      networkName: 'estimatePublisherReport',
      queryString: "query($filters: String!) {\n      estimatePublisherReport(filters: $filters) {\n        size\n        couldEstimate\n      }\n    }",
      queryMap: {},
      variables: {
        // TODO this is code smell, the API should have a proper type for these filters for the benefits of type checking
        filters: JSON.stringify(filters)
      }
    };
  };
});