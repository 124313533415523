define("affinio/components/modules/activation-dashboard", ["exports", "affinio/mixins/affinio-module"], function (_exports, _affinioModule) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // const networks = [
  //   {
  //     label: 'Twitter',
  //     value: 'twitter',
  //     component: 'advertising/activate-twitter-tailored-audience',
  //     icon: 'twitter'
  //   },
  //   {
  //     label: 'Facebook / Instagram',
  //     value: 'facebook',
  //     component: 'advertising/activate-facebook-custom-audience',
  //     icon: 'facebook'
  //   }
  // ];
  var _default = _exports.default = Ember.Component.extend(_affinioModule.default, {
    // Element
    classNames: ['activation-dashboard'],
    tagName: 'div',
    // Services
    user: Ember.inject.service()
    // localStorage: service(),

    // Properties
    // networks,
    // selectedNetwork: null,

    // Events
    // didInsertElement() {
    //   this._super(...arguments);
    //   let previousNetwork = get(this, 'localStorage.lastAdvertisingActivation');
    //   if (previousNetwork) {
    //     let network = get(this, 'networks').findBy('value', previousNetwork);
    //     if (network) {
    //       set(this, 'selectedNetwork', network);
    //     }
    //     set(this, 'localStorage.lastAdvertisingActivation', null);
    //   }
    // },

    // actions: {
    //   selectNetwork(network) {
    //     set(this, 'selectedNetwork', network);
    //   }
    // }
  });
});