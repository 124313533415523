define("affinio/initializers/asset-map", ["exports", "affinio/services/asset-map", "affinio/config/environment"], function (_exports, _assetMap, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(application) {
    application.deferReadiness();
    if (_environment.default.environment === 'test') {
      _assetMap.default.reopen({
        resolve: function resolve(name) {
          return name;
        },
        enabled: true
      });
      application.register('service:asset-map', _assetMap.default);
      application.advanceReadiness();
    } else {
      new Promise(function (resolve, reject) {
        return Ember.$.getJSON('/assets/assetMap.json', resolve).fail(reject);
      }).then(function () {
        var map = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        _assetMap.default.reopen({
          map: map.assets,
          // prepend: map.prepend, // Seems this is empty string, but `/` is needed
          enabled: true
        });
      }).catch(function () {
        _assetMap.default.reopen({
          enabled: true
        });
      }).then(function () {
        application.register('service:asset-map', _assetMap.default);
        application.advanceReadiness();
      });
    }
  }
  var _default = _exports.default = {
    initialize: initialize
  };
});