define("affinio/components/data-source-creator-visualization", ["exports", "d3v5", "affinio/utils/get-with-default"], function (_exports, _d3v, _getWithDefault) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['data-source-creator-visualization'],
    tagName: 'div',
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.send('draw');
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      this.$('svg').remove();
      this.send('draw');
    },
    actions: {
      draw: function draw() {
        var dataType = Ember.get(this, 'dataType');
        var data = dataType === 'integer' || dataType === 'decimal' ? Ember.get(this, 'dataSet').map(function (x) {
          return parseFloat(x);
        }).filter(function (z) {
          return !isNaN(z);
        }).sort(function (a, b) {
          return a - b;
        }) : dataType === 'category' || dataType === 'relationship' ? (0, _getWithDefault.default)(this, 'dataSet', []).map(function (x) {
          return x.length;
        }).sort(function (a, b) {
          return a - b;
        }) : null;
        if (!data || data && !data.length) {
          return;
        }
        var h = 20;
        var w = 280;
        var p = 2;
        var min = Math.min.apply(Math, _toConsumableArray(data));
        var max = Math.max.apply(Math, _toConsumableArray(data));
        var len = data.length;
        var xscale = _d3v.default.scaleLinear().domain([0, len]).range([p, w - p]);
        var yscale = _d3v.default.scaleLinear().domain([min, max]).range([h - p, p]);
        var line = _d3v.default.line().x(function (d, i) {
          return xscale(i);
        }).y(function (d) {
          return yscale(d);
        }).curve(_d3v.default.curveMonotoneX);
        var svg = _d3v.default.select(this.$()[0]).append('svg:svg').attr('height', h).attr('width', w);
        var g = svg.append('svg:g');
        return g.append('svg:path').attr('d', line(data)).attr('stroke', '#3F9EEA').attr('fill', 'none');
      }
    }
  });
});