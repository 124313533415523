define("affinio/templates/components/multi-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "KcznWGT3",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[4,\"if\",[[23,[\"items\"]]],null,{\"statements\":[[0,\"  \"],[7,\"button\"],[12,\"disabled\",[21,\"disabled\"]],[12,\"class\",[28,[[27,\"if\",[[23,[\"open\"]],\"open\"],null],\" \",[21,\"class\"]]]],[9],[0,\"\\n    \"],[1,[27,\"html-safe\",[[23,[\"label\"]]],null],false],[0,\"\\n    \"],[1,[27,\"font-awesome\",[[27,\"if\",[[23,[\"open\"]],\"chevron-up\",\"chevron-down\"],null]],null],false],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],[27,\"toggle\",[\"open\",[22,0,[]]],null]]],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"open\"]]],null,{\"statements\":[[0,\"    \"],[7,\"ul\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"items\"]]],null,{\"statements\":[[0,\"        \"],[7,\"li\"],[11,\"role\",\"button\"],[9],[0,\"\\n          \"],[1,[22,1,[\"label\"]],false],[0,\"\\n        \"],[3,\"action\",[[22,0,[]],\"callback\",[22,1,[\"action\"]]]],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/multi-button.hbs"
    }
  });
});