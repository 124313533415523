define("affinio/templates/components/modules/page-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "1202AlfJ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[22,0,[]]]],[0,\"\\n\"],[7,\"span\"],[11,\"class\",\"info-notification\"],[9],[0,\"\\n  \"],[7,\"span\"],[9],[1,[23,[\"moduleRequest\",\"message\"]],false],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"moduleRequest\",\"returnURL\"]]],null,{\"statements\":[[0,\"    \"],[4,\"link-to\",[[23,[\"moduleRequest\",\"returnURL\"]]],null,{\"statements\":[[1,[27,\"font-awesome\",[\"arrow-left\"],null],false],[0,\" \"],[1,[23,[\"moduleRequest\",\"returnCopy\"]],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/modules/page-message.hbs"
    }
  });
});