define("affinio/components/affinio-table-cell/radio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Element
    // Tagless as its easier to bind the checked class in template via get helper
    tagName: '',
    actions: {
      click: function click() {
        var key = "row.".concat(Ember.get(this, 'column.valuePath'));
        var current = Ember.get(this, key);
        if (!current) {
          Ember.get(this, 'rows').without(Ember.get(this, 'row')).setEach(key.replace('row.', ''), false);
        }
        Ember.set(this, key, !current);
      }
    }
  });
});