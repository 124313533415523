define("affinio/routes/aton/taxonomy", ["exports", "affinio/mixins/document-title"], function (_exports, _documentTitle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_documentTitle.default, {
    documentTitle: 'Taxonomy - ATON - Affinio',
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        // Clear query parameters properties
        Ember.setProperties(controller, {
          taxonomyID: null,
          dataSourceID: null,
          duplicate: null
        });
      }
    }
  });
});