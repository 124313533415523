define("affinio/templates/components/modules/audience-summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "7o+VzU19",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[22,0,[]]]],[0,\"\\n\"],[4,\"if\",[[23,[\"metric\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"cluster-bar-graph\",null,[[\"model\",\"source\",\"version\",\"processedModuleSchema\",\"id\",\"metric\",\"isAffinity\",\"isEngagement\",\"network\",\"hasLurkers\",\"isContentReport\"],[[23,[\"model\"]],[23,[\"model\",\"report\",\"clusters\"]],[23,[\"model\",\"version\"]],[23,[\"processedModuleSchema\"]],[23,[\"model\",\"report\",\"id\"]],[23,[\"metric\"]],[27,\"is-equal\",[[23,[\"metric\"]],\"interestAffinity\"],null],[23,[\"isEngagement\"]],[23,[\"model\",\"report\",\"socialNetwork\"]],[23,[\"model\",\"hasLurkers\"]],[23,[\"isContentReport\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/modules/audience-summary.hbs"
    }
  });
});