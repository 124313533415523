define("affinio/utils/constants/metric-icons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.metricIcons = _exports.default = void 0;
  /**
   * @module Utils
   * @submodule Constants
   */

  /**
   * An array icon images to metrics
   * @property metricIcons
   * @type Array of label/value objects
   * @example
   * ```
   * [
   *   {label: 'hashtags', value: '/assets/images/icons/hashtag.png' }
   * ]
   * ```
   */

  var metricIcons = _exports.metricIcons = [{
    label: 'links',
    value: '/assets/images/metric-icons/links.png'
  }, {
    label: 'domains',
    value: '/assets/images/metric-icons/links.png'
  }, {
    label: 'mentions',
    value: '/assets/images/metric-icons/at.png'
  }, {
    label: 'emojis',
    value: '/assets/images/metric-icons/emoji.png'
  }, {
    label: 'hashtags',
    value: '/assets/images/metric-icons/hashtag.png'
  }, {
    label: 'keywords',
    value: '/assets/images/metric-icons/keywords.png'
  }, {
    label: 'locations',
    value: '/assets/images/metric-icons/locations.png'
  }, {
    label: 'bioKeywords',
    value: '/assets/images/metric-icons/bio-keywords.png'
  }, {
    label: 'interests',
    value: '/assets/images/metric-icons/emoji.png'
  }, {
    label: 'locationInterests',
    value: '/assets/images/metric-icons/emoji.png'
  }, {
    label: 'posts',
    value: '/assets/images/metric-icons/links.png'
  }];
  var _default = _exports.default = {
    metricIcons: metricIcons
  };
});