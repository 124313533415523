define("affinio/templates/components/x-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "/yoQljVJ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"h3\"],[11,\"role\",\"button\"],[9],[0,\"\\n  \"],[1,[27,\"font-awesome\",[[27,\"if\",[[23,[\"open\"]],\"chevron-up\",\"chevron-down\"],null]],null],false],[0,\"\\n  \"],[1,[21,\"title\"],false],[0,\"\\n\"],[3,\"action\",[[22,0,[]],[27,\"toggle\",[\"open\",[22,0,[]]],null]]],[10],[0,\"\\n\\n\"],[7,\"div\"],[12,\"class\",[28,[\"content \",[27,\"if\",[[23,[\"open\"]],\"open\"],null]]]],[9],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/x-details.hbs"
    }
  });
});