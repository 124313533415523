define("affinio/mixins/module-preference", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    classNameBindings: ['preference.value', 'dasherizedValueKey'],
    dasherizedValueKey: Ember.computed('preference.valueKey', function () {
      return Ember.get(this, 'preference.valueKey') ? Ember.String.dasherize(Ember.get(this, 'preference.valueKey')).replace('.', ' ') : null;
    })
  });
});