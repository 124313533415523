define("affinio/components/folder-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['folder-list-item'],
    tagName: 'li',
    actions: {
      filterReports: function filterReports(report) {
        return Ember.get(report, 'socialNetowrk') !== 'Facebook' && Ember.get(report, 'state') !== 'failed';
      }
    }
  });
});