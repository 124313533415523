define("affinio/components/old/sample-tweet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['sample-tweet'],
    words: Ember.computed('post', function () {
      var words = Ember.get(this, 'post').replace(/\n/g, ' ').split(' ');
      words = words.map(function (obj) {
        return Ember.Object.create({
          votable: true,
          label: obj.trim(),
          value: obj.value ? obj.value : 1
        });
      });
      return words;
    }),
    actions: {
      hoverWord: function hoverWord(word, ev) {
        var hoverWord = Ember.get(this, 'hoverWord');
        if (hoverWord && typeof hoverWord === 'function') {
          hoverWord(word, ev);
        }
      },
      mouseLeave: function mouseLeave() {
        var hoverWord = Ember.get(this, 'hoverWord');
        if (hoverWord && typeof hoverWord === 'function') {
          hoverWord(null, null);
        }
      }
    }
  });
});