define("affinio/templates/components/loading-spinner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "LZQERMpE",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\"],[11,\"class\",\"spinner\"],[11,\"viewBox\",\"0 0 100 100\"],[9],[0,\"\\n  \"],[7,\"circle\"],[11,\"cx\",\"50\"],[11,\"cy\",\"50\"],[11,\"r\",\"25\"],[9],[10],[0,\"\\n  \"],[7,\"circle\"],[11,\"cx\",\"50\"],[11,\"cy\",\"50\"],[11,\"r\",\"25\"],[12,\"class\",[21,\"color\"]],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/loading-spinner.hbs"
    }
  });
});