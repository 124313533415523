define("affinio/mixins/document-title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    documentTitle: null,
    // Overwrites document.title with this exactly
    afterModel: function afterModel(model) {
      this._super.apply(this, arguments);
      var documentTitle = Ember.get(this, 'documentTitle');
      if (documentTitle) {
        document.title = documentTitle;
      } else {
        var category = Ember.get(model, 'category.label');
        var subcategory = Ember.get(model, 'subcategory.label');
        var title = '';

        // Such as interests/index (which has both cat and subcat of interests)
        if (subcategory && subcategory !== category) {
          title += "".concat(subcategory, " - ");
        }
        title += "".concat(category, " - ");
        document.title = "".concat(title, "Affinio");
      }
    }
  });
});