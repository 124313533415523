define("affinio/mixins/dependency-check", ["exports", "affinio/utils/data/array-attr"], function (_exports, _arrayAttr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    dependencies: _arrayAttr.default,
    // valid: attr(),
    // Check if this entity is valid to display, given a report-state model
    dependencyCheck: function dependencyCheck(model) {
      // console.log(`dependencyCheck on ${get(this, 'constructor.modelName')}:${get(this, 'id')}`, { model, dependencies: get(this, 'dependencies') });
      var valid = Ember.get(this, 'dependencies').every(function (depKey) {
        return Ember.get(model, depKey);
      });
      Ember.set(this, 'valid', valid);
      return valid;
    }
  });
});