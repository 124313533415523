define("affinio/templates/components/modules/engagement-timezone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "RNFlBkIj",
    "block": "{\"symbols\":[],\"statements\":[[7,\"header\"],[11,\"class\",\"clearfix\"],[9],[0,\"\\n  \"],[7,\"h4\"],[9],[0,\"Timezone Select\"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[1,[27,\"auto-suggest\",null,[[\"inputValue\",\"endpoint\",\"network\",\"submitAction\",\"closeAfterSubmit\",\"allowCustomValue\",\"sliceCount\"],[[23,[\"typedTimezone\"]],[23,[\"timezoneEndpoint\"]],[27,\"lowercase\",[[23,[\"model\",\"socialNetwork\"]]],null],[27,\"action\",[[22,0,[]],\"setTimezone\"],null],true,false,35]]],false],[0,\"\\n\"],[4,\"if\",[[23,[\"preferences\",\"timezone\"]]],null,{\"statements\":[[0,\"  \"],[7,\"h4\"],[9],[0,\"Selected Timezone\"],[10],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"timezone\"],[9],[0,\"\\n    \"],[1,[23,[\"preferences\",\"timezone\"]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/modules/engagement-timezone.hbs"
    }
  });
});