define("affinio/templates/components/modals/modal-sign-in", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ofYevcBe",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"modal\"],[9],[0,\"\\n  \"],[1,[27,\"sign-in\",null,[[\"type\"],[\"existing\"]]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"backdrop\"],[9],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/modals/modal-sign-in.hbs"
    }
  });
});