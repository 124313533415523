define("affinio/templates/components/modules/delta-timeline-gallery", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "HRoThXyT",
    "block": "{\"symbols\":[\"delta\",\"&default\"],\"statements\":[[14,2],[0,\"\\n\\n\"],[4,\"each\",[[23,[\"model\",\"cluster\",\"topRankedMetricDelta\",\"response\"]]],null,{\"statements\":[[4,\"if\",[[27,\"not-eq\",[[22,1,[\"type\"]],\"bioKeywords\"],null]],null,{\"statements\":[[4,\"spark-line\",null,[[\"class\",\"times\",\"term\",\"record\",\"showPoints\",\"showY\",\"showX\"],[\"delta\",[22,1,[\"origin\",\"times\"]],[22,1,[\"label\"]],[22,1,[\"origin\"]],true,true,true]],{\"statements\":[],\"parameters\":[]},null],[0,\"    \"],[7,\"hr\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"spark-line delta empty\"],[9],[10],[0,\"\\n    \"],[7,\"hr\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/modules/delta-timeline-gallery.hbs"
    }
  });
});