define("affinio/templates/admin/users/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "sl2B5NbU",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h2\"],[9],[0,\"Users\"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"canAddAccount\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\"],[11,\"class\",\"add-more\"],[9],[0,\"(Seats Left: \"],[1,[21,\"fullAccountsRemaining\"],false],[0,\") (Viewer Seats Left: \"],[1,[21,\"viewerAccountsRemaining\"],false],[0,\")\"],[10],[0,\"\\n  \"],[4,\"link-to\",[\"admin.users.company.user\",[23,[\"model\",\"company\",\"id\"]],\"new\"],[[\"class\"],[\"create-new-button\"]],{\"statements\":[[0,\"Create Account \"],[1,[27,\"font-awesome\",[\"plus\"],null],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"span\"],[11,\"class\",\"no-add\"],[9],[0,\"Deactivate a user to add another\"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[1,[27,\"admin-list\",null,[[\"entities\",\"isAccounts\",\"showAccounts\",\"sectionSearch\",\"toggleAccountActive\"],[[23,[\"model\",\"company\",\"accounts\"]],true,true,\"accounts-search\",[27,\"route-action\",[\"showToggleAccountActiveModal\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/admin/users/index.hbs"
    }
  });
});