define("affinio/templates/admin/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "M6dwJC4v",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"centered-admin-loading\"],[9],[0,\"\\n  \"],[1,[21,\"loading-spinner\"],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/admin/loading.hbs"
    }
  });
});