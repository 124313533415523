define("affinio/routes/settings/integrations", ["exports", "affinio/mixins/auth-check", "affinio/mixins/document-title"], function (_exports, _authCheck, _documentTitle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_authCheck.default, _documentTitle.default, {
    documentTitle: 'Preferences - Integrations - Affinio',
    // Services
    user: Ember.inject.service(),
    tracker: Ember.inject.service(),
    model: function model() {
      Ember.get(this, 'tracker').track('Integrations page visited', {
        pageName: Ember.get(this, 'documentTitle')
      });
      return Ember.get(this, 'user.account');
    }
  });
});