define("affinio/templates/components/affinio-table-cell/metrics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "poGoQ33+",
    "block": "{\"symbols\":[\"min\",\"max\",\"mean\"],\"statements\":[[4,\"unless\",[[27,\"or\",[[27,\"is-equal\",[[27,\"get\",[[23,[\"row\"]],\"dataType\"],null],\"category\"],null],[27,\"not\",[[27,\"get\",[[23,[\"row\"]],\"dataSet\"],null]],null]],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"mini-metrics\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"metric-visual\"],[9],[0,\"\\n      \"],[1,[27,\"data-source-creator-visualization\",null,[[\"dataType\",\"dataSet\"],[[27,\"get\",[[23,[\"row\"]],\"dataType\"],null],[27,\"get\",[[23,[\"row\"]],\"dataSet\"],null]]]],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"metrics-block\"],[9],[0,\"\\n\"],[4,\"let\",[[27,\"cull-number\",[[23,[\"value\",\"min\"]],2],null],[27,\"cull-number\",[[23,[\"value\",\"max\"]],2],null],[27,\"cull-number\",[[23,[\"value\",\"mean\"]],2],null]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"metric-block\"],[12,\"title\",[27,\"concat\",[\"Min: \",[22,1,[]]],null]],[9],[0,\"\\n          \"],[7,\"span\"],[9],[0,\"Min:\"],[10],[0,\"\\n          \"],[7,\"span\"],[9],[1,[22,1,[]],false],[10],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"metric-block\"],[12,\"title\",[27,\"concat\",[\"Max: \",[22,2,[]]],null]],[9],[0,\"\\n          \"],[7,\"span\"],[9],[0,\"Max:\"],[10],[0,\"\\n          \"],[7,\"span\"],[9],[1,[22,2,[]],false],[10],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"metric-block\"],[12,\"title\",[27,\"concat\",[\"Mean: \",[22,3,[]]],null]],[9],[0,\"\\n          \"],[7,\"span\"],[9],[0,\"Mean:\"],[10],[0,\"\\n          \"],[7,\"span\"],[9],[1,[22,3,[]],false],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[1,2,3]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"span\"],[11,\"class\",\"no-metrics\"],[9],[0,\"No Metrics\"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/affinio-table-cell/metrics.hbs"
    }
  });
});