define("affinio/components/support-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    path: Ember.inject.service(),
    user: Ember.inject.service(),
    auth: Ember.inject.service(),
    modalManager: Ember.inject.service(),
    tracker: Ember.inject.service(),
    tagName: 'button',
    classNames: ['support-button'],
    classNameBindings: ['isButton:button', 'worldly'],
    click: function click() {
      if (!Ember.get(this, 'auth.loggedIn')) {
        window.location.href = 'mailto:support@affinio.com';
        return;
      }
      window.onerror = function () {
        window.location.href = 'mailto:support@affinio.com';
      };
      var user = Ember.get(this, 'user');
      var path = Ember.get(this, 'path');
      Ember.get(this, 'tracker').track('User clicked Support button', {
        user: user.email ? user.email : 'loggedout',
        currentCampaign: Ember.get(path, 'audienceID'),
        currentTab: Ember.get(path, 'currentTab'),
        currentPath: Ember.get(path, 'currentPath')
      });
      window.open('https://share.hsforms.com/18p4jAMetTLekdi8abWY3jA7wwl');
    }
  });
});