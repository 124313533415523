define("affinio/templates/components/affinio-table-cell/string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "IqhrR9Q/",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"input\",null,[[\"class\",\"type\",\"value\",\"placeholder\",\"disabled\"],[[27,\"if\",[[23,[\"isRequired\"]],\"required\"],null],\"text\",[27,\"get\",[[23,[\"row\"]],[23,[\"column\",\"valuePath\"]]],null],[23,[\"column\",\"placeholder\"]],[23,[\"disabled\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/affinio-table-cell/string.hbs"
    }
  });
});