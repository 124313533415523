define("affinio/templates/admin/toolbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "3r635aeN",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h2\"],[9],[0,\"CSM Toolbox\"],[10],[0,\"\\n\"],[1,[27,\"toolbox/toolbox-display\",null,[[\"drawers\"],[[23,[\"model\",\"toolboxSchema\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/admin/toolbox.hbs"
    }
  });
});