define("affinio/models/clusters/instagram", ["exports", "ember-data", "affinio/models/clusters/-base", "affinio/mixins/models/breakdown-overlap"], function (_exports, _emberData, _base, _breakdownOverlap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var _default = _exports.default = _base.default.extend(_breakdownOverlap.default, {
    _name: attr(),
    name: Ember.computed('_name', function () {
      return Ember.get(this, '_name').replace(/\./gi, ' ').capitalize();
    })
  });
});