define("affinio/components/admin-selector", ["exports", "affinio/objects/object-promise-proxy", "affinio/utils/get-with-default"], function (_exports, _objectPromiseProxy, _getWithDefault) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
  var _default = _exports.default = Ember.Component.extend({
    // Element
    classNames: ['admin-selector'],
    tagName: 'div',
    // Services
    user: Ember.inject.service(),
    tracker: Ember.inject.service(),
    // Computed Properties
    account: Ember.computed.alias('user.account'),
    show: false,
    allEntities: null,
    selectedEntities: null,
    isStrings: false,
    // Because the entities are promises (hasMany/belongsTo)
    // we need to do some wrapping in RSVP.all to resolve before we can do some logic with them
    // return in an ObjectPromiseProxy for ease of use
    // - Joel & Justin April 2017
    unSelectedEntities: Ember.computed('allEntities.[]', 'selectedEntities.[]', function () {
      var all = Ember.get(this, 'allEntities');
      var selected = Ember.get(this, 'selectedEntities');
      var isStrings = Ember.get(this, 'isStrings');
      if (!isStrings) {
        var promise = Ember.RSVP.all([all, selected]).then(function (_ref) {
          var _ref2 = _slicedToArray(_ref, 2),
            _all = _ref2[0],
            _selected = _ref2[1];
          var unselectedIds = _.difference(_all.mapBy('id'), _selected.mapBy('id'));
          return _all.filter(function (n) {
            return unselectedIds.includes(Ember.get(n, 'id'));
          });
        });
        return _objectPromiseProxy.default.create({
          promise: promise
        });
      } else {
        return _.difference(all, selected);
      }
    }),
    filteredUnselectedEmtities: Ember.computed('unSelectedEntities.content.[]', 'unSelectedSearch', function () {
      var filter = Ember.get(this, 'unSelectedSearch');
      var isStrings = Ember.get(this, 'isStrings');
      if (filter && typeof filter === 'string') {
        filter = filter.toLowerCase();
      }
      var entities = isStrings ? (0, _getWithDefault.default)(this, 'unSelectedEntities', []) : (0, _getWithDefault.default)(this, 'unSelectedEntities.content', []);
      return filter ? isStrings ? entities.filter(function (n) {
        return n.toLowerCase().indexOf(filter) > -1;
      }) : entities.filter(function (n) {
        return Ember.get(n, 'name').toLowerCase().indexOf(filter) > -1;
      }) : entities;
    }),
    filteredSelectedEmtities: Ember.computed('selectedSearch', 'selectedEntities.[]', function () {
      var filter = Ember.get(this, 'selectedSearch');
      var isStrings = Ember.get(this, 'isStrings');
      if (filter && typeof filter === 'string') {
        filter = filter.toLowerCase();
      }
      var entities = (0, _getWithDefault.default)(this, 'selectedEntities', []);
      return filter ? isStrings ? entities.filter(function (n) {
        return n.toLowerCase().indexOf(filter) > -1;
      }) : entities.filter(function (n) {
        return Ember.get(n, 'name').toLowerCase().indexOf(filter) > -1;
      }) : entities;
    }),
    // Lifecycle
    init: function init() {
      this._super.apply(this, arguments);
      // If this component uses an array and it needs to be independent of other component instances, set it here
      Ember.set(this, 'myArray', Ember.A());
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
    },
    actions: {
      inputFocusIn: function inputFocusIn(section) {
        Ember.$(".".concat(section)).css('box-shadow', '0px 2px 12px -2px rgba(0, 0, 0, 0.4)');
      },
      inputFocusOut: function inputFocusOut(section) {
        Ember.$(".".concat(section)).css('box-shadow', '0px 2px 12px -2px rgba(0, 0, 0, 0.2)');
      },
      toggle: function toggle(prop) {
        this.toggleProperty(prop);
      }
    }
  });
});