define("affinio/utils/queries/groups", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.updateGroupsReports = _exports.updateGroupsFolders = _exports.groups = _exports.group = _exports.default = void 0;
  var groups = _exports.groups = function groups(companyId) {
    return {
      queryString: "query {\n      company(id:\"".concat(companyId, "\") {\n        id\n        groups{\n          id\n          name\n          numberOfAccounts\n        }\n      }\n    }"),
      queryMap: {
        company: {
          array: false,
          modelName: 'company',
          responseKey: 'company'
        }
      }
    };
  };
  var group = _exports.group = function group(groupId, companyId) {
    return {
      queryString: "query {\n      company(id:\"".concat(companyId, "\") {\n        id\n        name\n        accounts{\n          id\n          firstName\n          lastName\n          email\n        }\n      }\n      group(id:\"").concat(groupId, "\"){\n        id\n        name\n        accounts{\n          id\n          firstName\n          lastName\n          email\n        }\n        reports{\n          id\n          name\n          numberOfClusters\n          numberOfMembers\n          dateCreated\n          socialNetwork\n          creator{\n            id\n            firstName\n            lastName\n            email\n          }\n          accounts {\n            id\n            email\n            shareInfo {\n              canEdit\n            }\n          }\n          folders {\n            id\n            name\n            shareInfo {\n              canEdit\n            }\n          }\n          companies {\n            id\n            name\n            shareInfo {\n              canEdit\n            }\n          }\n        }\n      }\n    }"),
      queryMap: {
        company: {
          array: false,
          modelName: 'company',
          responseKey: 'company'
        },
        group: {
          array: false,
          modelName: 'group',
          responseKey: 'group'
        }
      }
    };
  };
  var updateGroupsReports = _exports.updateGroupsReports = function updateGroupsReports(groups) {
    var queries = Ember.A();
    var queryMap = {};
    groups.forEach(function (group, i) {
      var alias = "group".concat(i);
      queries.pushObject("\n      ".concat(alias, ":group(id: \"").concat(Ember.get(group, 'id'), "\"){\n        id\n        reports {\n          id\n          groups {\n            id\n            shareInfo {\n              canEdit\n            }\n          }\n        }\n      }\n    "));
      queryMap[alias] = {
        array: false,
        modelName: 'group',
        responseKey: alias
      };
    });
    return {
      networkName: 'updateGroupsReports',
      queryString: "query {\n      ".concat(queries, "\n    }"),
      queryMap: queryMap
    };
  };
  var updateGroupsFolders = _exports.updateGroupsFolders = function updateGroupsFolders(groups) {
    var queries = Ember.A();
    var queryMap = {};
    groups.forEach(function (group, i) {
      var alias = "group".concat(i);
      queries.pushObject("\n      ".concat(alias, ":group(id: \"").concat(Ember.get(group, 'id'), "\"){\n        id\n        folders {\n          id\n          groups {\n            id\n            shareInfo {\n              canEdit\n            }\n          }\n        }\n      }\n    "));
      queryMap[alias] = {
        array: false,
        modelName: 'group',
        responseKey: alias
      };
    });
    return {
      networkName: 'updateGroupsFolders',
      queryString: "query {\n      ".concat(queries, "\n    }"),
      queryMap: queryMap
    };
  };
  var _default = _exports.default = {
    groups: groups,
    group: group,
    updateGroupsReports: updateGroupsReports,
    updateGroupsFolders: updateGroupsFolders
  };
});