define("affinio/templates/components/old/boolean-switcher", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "FOhaBBwV",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\"],[11,\"class\",\"toggle\"],[9],[0,\"-- \"],[1,[21,\"boolean\"],false],[0,\" --\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"switch\"],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"boolean\"],[11,\"role\",\"button\"],[9],[0,\"AND \"],[3,\"action\",[[22,0,[]],\"toggleBoolean\",\"AND\"]],[10],[0,\" / \"],[7,\"span\"],[11,\"class\",\"boolean\"],[11,\"role\",\"button\"],[9],[0,\" OR\"],[3,\"action\",[[22,0,[]],\"toggleBoolean\",\"OR\"]],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/old/boolean-switcher.hbs"
    }
  });
});