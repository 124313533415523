define("affinio/templates/components/requirements-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "H2fePwSQ",
    "block": "{\"symbols\":[\"requirement\"],\"statements\":[[4,\"each\",[[23,[\"requirements\"]]],null,{\"statements\":[[0,\"  \"],[7,\"li\"],[9],[0,\"\\n    \"],[1,[27,\"font-awesome\",[[27,\"if\",[[22,1,[\"value\"]],\"check\",\"times\"],null]],null],false],[0,\"\\n    \"],[1,[22,1,[\"label\"]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/requirements-list.hbs"
    }
  });
});