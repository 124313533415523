define("affinio/templates/components/modals/modal-version-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "MjfNkmwk",
    "block": "{\"symbols\":[\"date\"],\"statements\":[[7,\"form\"],[11,\"class\",\"modal datechange\"],[11,\"tabindex\",\"0\"],[9],[0,\"\\n  \"],[7,\"a\"],[11,\"class\",\"close-modal\"],[11,\"href\",\"#\"],[11,\"role\",\"button\"],[9],[0,\"×\"],[3,\"action\",[[22,0,[]],\"close\"]],[10],[0,\"\\n  \"],[7,\"h3\"],[11,\"class\",\"modal-sub-nav-header\"],[9],[0,\"Rewind Data to a previous date\"],[10],[0,\"\\n  \"],[7,\"ul\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"cleanDates\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\"],[9],[0,\"\\n        \"],[1,[27,\"moment-format\",[[22,1,[\"date\"]],\"LL\"],null],false],[0,\"\\n\"],[4,\"if\",[[22,1,[\"active\"]]],null,{\"statements\":[[0,\"          \"],[7,\"button\"],[11,\"class\",\"inline\"],[11,\"disabled\",\"disabled\"],[9],[0,\"Active\"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[7,\"button\"],[11,\"class\",\"inline\"],[9],[0,\"Load\"],[3,\"action\",[[22,0,[]],[23,[\"loadVersion\"]],[22,1,[\"version\"]]]],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n  \"],[7,\"p\"],[11,\"class\",\"modal-loader\"],[9],[0,\" \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"backdrop\"],[11,\"role\",\"button\"],[9],[3,\"action\",[[22,0,[]],\"close\"]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/modals/modal-version-picker.hbs"
    }
  });
});