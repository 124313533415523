define("affinio/templates/components/folder-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ap9ZwnWT",
    "block": "{\"symbols\":[\"folder\"],\"statements\":[[4,\"each\",[[23,[\"sortedFolders\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"folder-list-item\",null,[[\"route\",\"folderParam\",\"model\",\"openEdit\"],[\"reports\",[22,1,[\"id\"]],[22,1,[]],[27,\"action\",[[22,0,[]],[23,[\"openEdit\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/folder-list.hbs"
    }
  });
});