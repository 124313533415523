define("affinio/components/preferences/tag-filter", ["exports", "affinio/utils/interests", "lodash"], function (_exports, _interests, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Element
    classNames: ['tag-filter'],
    tagName: 'section',
    classNameBindings: ['open'],
    /* ------------------------------ toggle switch ----------------------------- */
    open: false,
    showSubCategory: false,
    /* ------------------------------- properties ------------------------------- */
    metricKey: undefined,
    // current tab's metric key
    tags: undefined,
    // Array of ["tagName"]
    selectedTag: undefined,
    // tag name
    selectedTags: undefined,
    // array of tag
    searchText: undefined,
    // string - search text
    /* ----------------------------- end properties ----------------------------- */
    init: function init() {
      this._super.apply(this, arguments);
      var tags = Ember.get(this, 'controller.tags');
      var selectedTags = Ember.get(this, 'controller.selectedTags');
      var metricKey = Ember.get(this, 'controller.metric.key');
      Ember.set(this, 'tags', tags);
      Ember.set(this, 'open', false);
      Ember.set(this, 'showSubCategory', false);
      Ember.set(this, 'selectedTags', selectedTags);
      Ember.set(this, 'searchText', '');
      Ember.set(this, 'metricKey', metricKey);
    },
    // convert metric.response.tags to lowercase tags - ["tag1", "tag2", "tag1"]
    lowercaseTags: Ember.computed('tags', function () {
      var flattenTags = Ember.get(this, 'tags').flatten().compact();
      var lowercaseTags = flattenTags.map(function (item) {
        return item.toLowerCase();
      });
      return lowercaseTags;
    }),
    // generate counts for each tags: e.g.: {'brand' : 765, 'media': 322}
    tagCounts: Ember.computed('lowercaseTags', function () {
      var tagCounts = _lodash.default.countBy(this.lowercaseTags);
      return tagCounts;
    }),
    // find brands count at tagCounts: {brands: 411}
    brandsTagCount: Ember.computed('tagCounts.[]', function () {
      var _this = this;
      if (this.tagCounts) {
        var count = 0;
        _interests.brands.children.forEach(function (child) {
          var tagName = child.value; // lowercase tag value - "alcohol"
          if (_this.tagCounts[tagName]) {
            count += _this.tagCounts[tagName];
          }
        });
        return count;
      } else {
        return 0;
      }
    }),
    // find uniq lowercase tags
    uniqTags: Ember.computed('lowercaseTags', function () {
      var uniqTags = _lodash.default.uniq(this.lowercaseTags);
      return uniqTags;
    }),
    // items is an array filled with objects that have:
    /** {
     *    value: string - tag name in lower case
     *    label: string - tag name capitalized
     *    count: number - number of the tag
     *    active: boolean - for render button style
     *    children: [childItem] array of childItems
     * }
     * a childItem is: {
     *    value: string - tag name in lower case
     *    label: string - tag name capitalized
     *    count: number - number of the tag
     *    active: boolean - for render button style
     *    parent: Item - the parent object
     * }
     */

    items: Ember.computed('uniqTags', 'selectedTags.[]', function () {
      var _this2 = this;
      var uniqTags = Ember.get(this, 'uniqTags');
      var tagCounts = Ember.get(this, 'tagCounts');
      var items = _interests.tagHierarchyMap.map(function (hierarchy) {
        return _this2.hierarchyMapCount(hierarchy, tagCounts, uniqTags);
      }).compact();
      return items;
    }),
    flattenItems: Ember.computed('items', function () {
      var flattenItems = [];
      this.items.forEach(function (item) {
        flattenItems.push(item);
        if (item.children) {
          item.children.forEach(function (childItem) {
            flattenItems.push(childItem);
          });
        }
      });
      return flattenItems;
    }),
    searchItems: Ember.computed('flattenItems', 'searchText', function () {
      var searchText = this.searchText.toLowerCase();
      var resultItems = this.flattenItems.filter(function (item) {
        if (item.value.includes(searchText)) {
          return item;
        }
      });
      return resultItems;
    }),
    hierarchyMapCount: function hierarchyMapCount(rawHierarchy, tagCounts, uniqTags) {
      var _this3 = this;
      var rawTag = rawHierarchy.value;
      if (uniqTags.includes(rawTag)) {
        var item = {
          value: rawHierarchy.value,
          label: rawHierarchy.label,
          count: tagCounts[rawTag],
          // count number of a hierarchy e.g.:{count: 765}
          active: this.selectedTags.includes(rawTag),
          isParent: true
        };

        // if the hierachy has children, keep attaching children with counts to the parent
        if (rawHierarchy.children) {
          item.children = rawHierarchy.children.map(function (hierarchy) {
            // if the tag is available in uniqTags
            if (uniqTags.includes(hierarchy.value)) {
              var childItem = {
                value: hierarchy.value,
                label: hierarchy.label,
                count: tagCounts[hierarchy.value],
                active: _this3.selectedTags.includes(hierarchy.value),
                parent: item
              };
              return childItem;
            }
          }).compact();
        }
        return item;
      } else {
        return null;
      }
    },
    actions: {
      toggleOpen: function toggleOpen() {
        this.toggleProperty('open');
      },
      toggleOpenAtBackground: function toggleOpenAtBackground(event) {
        var modal = document.querySelector('.tag-filter .cluster-modal');
        var target = event.target;
        if (modal === target) {
          this.toggleProperty('open');
        }
      },
      toggleSubCategory: function toggleSubCategory() {
        this.toggleProperty('showSubCategory');
      },
      selectParentTag: function selectParentTag(item) {
        var selectedTags = Ember.get(this, 'selectedTags');

        // if the category is active,
        // we need to turn off the category with all sub categories
        if (item.active) {
          Ember.setProperties(item, {
            active: false
          });
          selectedTags.removeObject(item.value);
          if (item.children) {
            // item.value and childItem.value is tag in lowercase.  e.g.: brands, sport
            item.children.forEach(function (childItem) {
              if (selectedTags.includes(childItem.value)) {
                selectedTags.removeObject(childItem.value);
              }
              Ember.setProperties(childItem, {
                active: false
              });
            });
          }
        }
        // if the category is not active
        // we need to select the parent tag and its sub tags
        else {
          Ember.setProperties(item, {
            active: true
          });
          selectedTags.addObject(item.value);
          if (item.children) {
            item.children.forEach(function (childItem) {
              if (!selectedTags.includes(childItem.value)) {
                selectedTags.addObject(childItem.value);
              }
              Ember.setProperties(childItem, {
                active: true
              });
            });
          }
        }
      },
      selectChildTag: function selectChildTag(childItem, item) {
        var selectedTags = Ember.get(this, 'selectedTags');
        var childTag = childItem.value;
        var parentTag = item.value;
        if (childItem.active) {
          // remove childTag
          selectedTags.removeObject(childTag);
          if (selectedTags.includes(parentTag)) {
            // remove parent tag
            selectedTags.removeObject(parentTag);
          }
          Ember.setProperties(childItem, {
            active: false
          });
          if (selectedTags.length === 0) {
            selectedTags.clear();
          }
        } else {
          // add childItem tag
          selectedTags.addObject(childTag);
          Ember.setProperties(childItem, {
            active: true
          });
        }

        // if all childItems are selected, set item.active to true
        var isAllChildrenActive = item.children.every(function (childItem) {
          return childItem.active;
        });
        Ember.setProperties(item, {
          active: isAllChildrenActive
        });
      },
      selectBrands: function selectBrands() {
        var selectedTags = Ember.get(this, 'selectedTags');
        _interests.brands.children.forEach(function (child) {
          if (!selectedTags.includes(child.value)) {
            selectedTags.addObject(child.value);
          }
        });
      },
      clearAllToggles: function clearAllToggles(items) {
        Ember.get(this, 'selectedTags').clear();
        items.forEach(function (item) {
          Ember.setProperties(item, {
            active: false
          });
          if (item.children) {
            item.children.forEach(function (childItem) {
              Ember.setProperties(childItem, {
                active: false
              });
            });
          }
        });
      },
      onTyping: function onTyping(text) {
        Ember.set(this, 'searchText', text);
      }
    }
  });
});