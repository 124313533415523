define("affinio/components/heat-block", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['heat-block'],
    classNameBindings: ['record.active:active', 'record.highlight:highlight', 'record.stickied:stickied'],
    attributeBindings: ['style'],
    style: Ember.computed('record', 'opacityScale', 'colorScale', function () {
      if (Ember.get(this, 'opacityScale')) {
        // console.log('colorScale', get(this, 'record'));
        return Ember.String.htmlSafe("\n        background-color: ".concat(Ember.get(this, 'colorScale')(Ember.get(this, 'record.average')), ";\n        transition: opacity 250ms ").concat(Ember.get(this, 'delayScale')(Ember.get(this, 'record.average')), "ms;\n        opacity: ").concat(Ember.get(this, 'opacityScale')(Ember.get(this, 'record.average')), ";\n      "));
      }
    }) // style
  });
});