define("affinio/templates/components/bar-graph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "YTXeEA2/",
    "block": "{\"symbols\":[\"instance\"],\"statements\":[[7,\"div\"],[11,\"class\",\"metric-visualization\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"chart\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"records\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"metric-instance\",null,[[\"instance\",\"metric\",\"records\",\"socialNetwork\",\"normalized\",\"max\",\"model\"],[[22,1,[]],[23,[\"metric\"]],[27,\"map-get\",[[23,[\"recordsMap\"]],[22,1,[\"label\"]]],null],[23,[\"socialNetwork\"]],\"normalized\",[23,[\"max\"]],[23,[\"model\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/bar-graph.hbs"
    }
  });
});