define("affinio/templates/components/affinio-table-cell/datasource-column-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "K/ZFzuYu",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\"],[11,\"role\",\"button\"],[9],[0,\"\\n  \"],[1,[27,\"font-awesome\",[\"code-fork\"],null],false],[0,\"\\n\"],[3,\"action\",[[22,0,[]],\"openDataSourceActions\",[23,[\"row\"]]]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/affinio-table-cell/datasource-column-actions.hbs"
    }
  });
});