define("affinio/components/skeleton-img", ["exports", "affinio/utils/affinio"], function (_exports, _affinio) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['skeleton-img'],
    classNameBindings: ['hidden'],
    hadError: false,
    emptyFallback: Ember.computed.empty('fallbackSrc'),
    hidden: Ember.computed.and('hadError', 'emptyFallback'),
    actions: {
      onError: function onError() {
        var _this = this;
        var externalAction = Ember.get(this, 'onError');
        if (externalAction && typeof externalAction === 'function') {
          externalAction();
        }
        (0, _affinio.maybeRunLater)(this, function () {
          Ember.set(_this, 'hadError', true);
          Ember.set(_this, 'errorSrc', Ember.get(_this, 'fallbackSrc'));
        });
      }
    }
  });
});