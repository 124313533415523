define("affinio/templates/components/range-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "qL0ARyqt",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\"],[11,\"class\",\"bar\"],[9],[10],[0,\"\\n\"],[7,\"span\"],[12,\"class\",[28,[\"min \",[27,\"if\",[[27,\"is-equal\",[[23,[\"activeRange\"]],\"min\"],null],\"active\"],null]]]],[9],[1,[27,\"commafy-number\",[[23,[\"min\"]]],null],false],[10],[0,\"\\n\"],[7,\"span\"],[12,\"class\",[28,[\"max \",[27,\"if\",[[27,\"is-equal\",[[23,[\"activeRange\"]],\"max\"],null],\"active\"],null]]]],[9],[1,[27,\"commafy-number\",[[23,[\"max\"]]],null],false],[10],[0,\"\\n\"],[7,\"span\"],[12,\"class\",[28,[\"highlight-value \",[27,\"if\",[[27,\"is-defined\",[[23,[\"highlighted\",\"value\"]]],null],\"highlight\"],null]]]],[12,\"style\",[21,\"highlightValueStyle\"]],[9],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/range-bar.hbs"
    }
  });
});