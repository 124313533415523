define("affinio/components/report-annotation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Element
    classNames: ['report-annotation-container'],
    classNameBindings: ['annotation:open:shut', 'completed:shut'],
    tagName: 'section',
    init: function init() {
      this._super.apply(this, arguments);
      Ember.set(this, 'annotationSets', [{
        id: '64783322701219522',
        set: 'cmo'
      }, {
        id: '610715167367833752',
        set: 'vp insights'
      }, {
        id: '264986627544969821',
        set: 'data scientist'
      }, {
        id: '713188891119940599',
        set: 'brand manager'
      }]);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;
      // Quick reroute here to make sure they end up on summary page on all refreshes
      Ember.run.scheduleOnce('afterRender', this, function () {
        var annotationSet = Ember.get(_this, 'annotationSets').findBy('id', Ember.get(_this, 'model.report.id'));
        if (annotationSet) {
          if (Ember.get(_this, 'path.currentCategory') !== 'overview' && Ember.get(_this, 'path.currentSubcategory') !== 'summary') {
            Ember.get(_this, 'router').transitionTo('reports.report.version.cluster.category.subcategory', 'overview', 'summary'); // TODO dynamic router test this may need report/version/cluster
          }
          annotationSet = Ember.get(annotationSet, 'set');
        } else {
          annotationSet = null;
        }
        Ember.set(_this, 'annotationSet', annotationSet);
      });
    },
    // Get all annotations from a given report type, flattened
    reportAnnotations: Ember.computed('model.report.{platformSchema,socialNetwork}', 'annotationSet', function () {
      var _this2 = this;
      // Make sure this report is in the annotations list

      var platformSchema = Ember.get(this, 'model.report.platformSchema');
      if (platformSchema) {
        return platformSchema.map(function (category) {
          // check through all categories like "Members", "Interests"
          return Ember.get(category, 'subcategories').map(function (subcategory) {
            // check each subcategory, like "Profiles" or "Locations"
            var annotation = Ember.get(subcategory, 'annotation');
            if (annotation) {
              // if the subcategory has an annotation object...
              return Ember.get(annotation, 'articles').filter(function (article) {
                // filter to just the right set
                Ember.set(article, 'active', false); // eslint-disable-line ember/no-side-effects
                return Ember.get(article, 'appliesTo').includes(Ember.get(_this2, 'annotationSet'));
              }).map(function (article) {
                // map its articles to have the parent category and subcategory names
                Ember.set(article, 'category', Ember.get(category, 'value')); // eslint-disable-line ember/no-side-effects
                Ember.set(article, 'subcategory', Ember.get(subcategory, 'value')); // eslint-disable-line ember/no-side-effects
                return article;
              });
            } else {
              return null;
            }
          }).flatten().compact(); // return the subcategory as a list of its annotation's articles
        }).flatten().compact(); // and don't both with any categories/subcategories that don't have annotations
      } else {
        return [];
      }
    }),
    annotation: Ember.computed('model.report.platformSchema', 'path.{currentCategory,currentSubcategory}', 'reportAnnotations', function () {
      var _this3 = this;
      var platformSchema = Ember.get(this, 'model.report.platformSchema');
      var subcategories = Ember.get(platformSchema.findBy('value', Ember.get(this, 'path.currentCategory')), 'subcategories');
      var currentSubcategory = subcategories.findBy('value', Ember.get(this, 'path.currentSubcategory'));
      var annotation = Ember.get(currentSubcategory, 'annotation');
      var annotationHasApplicableArticles;
      if (annotation) {
        annotationHasApplicableArticles = Ember.get(annotation, 'articles').any(function (article) {
          return Ember.get(article, 'appliesTo').includes(Ember.get(_this3, 'annotationSet'));
        });
      } else {
        return false;
      }
      if (annotationHasApplicableArticles) {
        // If none currently active, set the first one here as active
        if (annotation && !Ember.get(this, 'reportAnnotations').findBy('active')) {
          var activeAnnotation = Ember.get(this, 'reportAnnotations').find(function (annotation) {
            return Ember.get(annotation, 'category') === Ember.get(_this3, 'path.currentCategory') && Ember.get(annotation, 'subcategory') === Ember.get(_this3, 'path.currentSubcategory');
          });
          Ember.set(activeAnnotation, 'active', true); // eslint-disable-line ember/no-side-effects
        }
        return annotation;
      } else {
        return false;
      }
    }),
    // Services
    path: Ember.inject.service(),
    router: Ember.inject.service(),
    actions: {
      loadPreviousAnnotation: function loadPreviousAnnotation() {
        var annotations = Ember.get(this, 'reportAnnotations');
        var active = annotations.findBy('active');
        var prev = annotations.indexOf(active) === 0 ? annotations.objectAt(annotations.length - 1) : annotations.objectAt(annotations.indexOf(active) - 1);
        Ember.set(active, 'active', false);
        Ember.set(prev, 'active', true);
        this.send('checkToHeadElsewhere');
      },
      loadNextAnnotation: function loadNextAnnotation() {
        var annotations = Ember.get(this, 'reportAnnotations');
        var active = annotations.findBy('active');
        var next = annotations.indexOf(active) + 1 === annotations.length ? annotations.objectAt(0) : annotations.objectAt(annotations.indexOf(active) + 1);
        Ember.set(active, 'active', false);
        Ember.set(next, 'active', true);
        this.send('checkToHeadElsewhere');
      },
      checkToHeadElsewhere: function checkToHeadElsewhere() {
        var activeAnnotation = Ember.get(this, 'reportAnnotations').findBy('active');
        if (Ember.get(activeAnnotation, 'category') !== Ember.get(this, 'path.currentCategory') || Ember.get(activeAnnotation, 'subcategory') !== Ember.get(this, 'path.currentSubcategory')) {
          Ember.get(this, 'router').transitionTo('reports.report.version.cluster.category.subcategory', Ember.get(this, 'model.report.clusterAggregation.id'), Ember.get(activeAnnotation, 'category'), Ember.get(activeAnnotation, 'subcategory'));
        }
      },
      endTour: function endTour() {
        Ember.set(this, 'completed', true);
      }
    }
  });
});