define("affinio/utils/report-builder/custom/custom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    label: 'Custom',
    naturalLanguageLabel: 'custom',
    value: 'custom',
    stages: [{
      label: 'Attributes',
      heading: 'Input audience attributes:',
      description: 'Filter an audience down to those who have a given attribute(s). Input one attribute at a time. Attributes can be AND or OR separated.',
      modules: []
    }, {
      label: 'Options',
      heading: 'Adjust advanced report features:',
      description: 'Changing these options will not impact the makeup of your audience but can be used to fine tune the desired output of your report.',
      modules: [{
        label: 'Number of Clusters:',
        description: 'We auto-recommend a number based on estimated audience size, but you can adjust between 1-20 clusters. Select fewer clusters to break out high-level segments or more clusters to uncover niche segments. Small audiences are capped at 6 clusters (size 150-499).',
        value: 'number-input',
        min: 1,
        max: 20,
        valueKey: 'numberOfClusters',
        placeholder: 'Number of Clusters'
      }]
    }, {
      label: 'Confirm and Launch',
      heading: 'Confirm and launch:',
      description: 'Review summary and name report to launch.',
      modules: [{
        label: null,
        value: 'launch-input',
        placeholder: 'Report Name'
      }]
    }]
  };
});