define("affinio/services/data-cacher", ["exports", "affinio/objects/object-promise-proxy"], function (_exports, _objectPromiseProxy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @module Services
   * @class DataCacher
   */
  var _default = _exports.default = Ember.Service.extend({
    auth: Ember.inject.service(),
    store: Ember.inject.service(),
    api: Ember.inject.service(),
    graph: Ember.inject.service(),
    modalManager: Ember.inject.service(),
    tracker: Ember.inject.service(),
    localStorage: Ember.inject.service(),
    notify: Ember.inject.service(),
    router: Ember.inject.service(),
    path: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      if ('caches' in window) {
        Ember.set(this, 'caches', caches);
        if ('serviceWorker' in navigator) {
          navigator.serviceWorker.register('/service-worker.js');
        }
      } else {
        Ember.get(this, 'tracker').track('window.caches not supported in user browser');
      }
    },
    currentReportCached: Ember.computed('path.{currentReportId,currentVersion}', 'bumper', 'caches', function () {
      var _this = this;
      var caches = Ember.get(this, 'caches');
      if (caches) {
        var promise = caches.keys().then(function (cacheNames) {
          return cacheNames.includes("".concat(Ember.get(_this, 'path.currentReportId'), "_").concat(Ember.get(_this, 'path.currentVersion')));
        });
        return _objectPromiseProxy.default.create({
          promise: promise
        });
      }
    }),
    deleteCaches: function deleteCaches() {
      var caches = Ember.get(this, 'caches');
      if (caches) {
        var promise = caches.keys().then(function (cacheNames) {
          return Promise.all(cacheNames.map(function (cacheName) {
            return caches.delete(cacheName);
          }));
        });
        // TODO why is this an ObjPromProxy?
        return _objectPromiseProxy.default.create({
          promise: promise
        });
      } else {
        return Ember.RSVP.resolve();
      }
    },
    /**
     * Checks that the cached num of clusters matches the loaded num. Clears cache if not
     * @param {String} cacheName
     * @param {Integer} filteredClusterLength num of clusters needed to be valid
     * @returns {Promise}
     */
    ensureReportCacheValidity: function ensureReportCacheValidity(cacheName, filteredClusterLength) {
      var _this2 = this;
      // It appears window.caches isn't returning a proper promise
      // therefore ember doesn't wait when returning it in model/afterModel
      // to get around this we wrap in a promise - Joel Nov 2018
      var invalidClusterSuffix = ['all', 'clusterSVGURL', 'imageAnalysis', 'recordsCollection', 'topicExplorer', 'storedBenchmarkGroups', 'numberOfMembers'];
      return new Promise(function (resolve) {
        Ember.get(_this2, 'caches').open(cacheName).then(function (cache) {
          cache.keys().then(function (keys) {
            var clusters = keys.map(function (key) {
              return key.url.replace("".concat(window.location.origin, "/"), '').split('_')[1];
            }).filter(function (key) {
              return !invalidClusterSuffix.includes(key);
            }).uniq();
            // if there are cached clusters and not the same length as the model's filtered for removed clusters
            if (clusters.length && clusters.length !== filteredClusterLength) {
              // console.log('cache invalid, different # of clusters');
              _this2.deleteCaches().then(function () {
                return resolve();
              });
            } else {
              resolve();
            }
          });
        });
      });
    }
  });
});