define("affinio/components/active-interest", ["exports", "affinio/utils/get-with-default"], function (_exports, _getWithDefault) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['active-interest'],
    // TODO rename with classes css scss here style
    classNameBindings: ['on', 'righty', 'bottom'],
    attributeBindings: ['style'],
    init: function init() {
      this._super.apply(this, arguments);
      Ember.set(this, 'instance', {});
    },
    style: Ember.computed('interest', function () {
      var leftOffset, verticalPosition;
      var topOffset = Ember.get(this, 'interest.topSpot');
      var bottomOffset = Ember.get(this, 'interest.bottomSpot');
      if (Ember.get(this, 'interest.leftSpot') + 200 >= Ember.$("".concat((0, _getWithDefault.default)(this, 'parentSelector', ''), " .interests.chart")).width()) {
        leftOffset = Ember.get(this, 'interest.leftSpot') - 185;
      } else {
        leftOffset = Ember.get(this, 'interest.leftSpot');
      }
      if (!Ember.isEmpty(bottomOffset)) {
        verticalPosition = "bottom: ".concat(bottomOffset + 5, "px;");
      } else {
        verticalPosition = "top: ".concat(topOffset, "px;");
      }
      return Ember.String.htmlSafe("left:".concat(leftOffset, "px; ").concat(verticalPosition));
    }),
    uncategorized: Ember.computed('interest.tags.lenght', function () {
      var tags = Ember.get(this, 'interest.tags');
      return tags ? tags.includes('uncategorized') : false;
    }),
    righty: Ember.computed('interest', function () {
      return Ember.get(this, 'interest.leftSpot') + 200 >= Ember.$("".concat((0, _getWithDefault.default)(this, 'parentSelector', ''), " .interests.chart")).width();
    }),
    bottom: Ember.computed('interest', function () {
      return !Ember.isEmpty(Ember.get(this, 'interest.bottomSpot'));
    }),
    locationScoreStyle: Ember.computed('interest', function () {
      if (Ember.get(this, 'interest.locationRelevance')) {
        return Ember.String.htmlSafe("width: ".concat(Ember.get(this, 'interest.locationRelevance'), "%;"));
      } else {
        return Ember.String.htmlSafe('width:0%;');
      }
    }),
    on: Ember.computed('interest', function () {
      return Ember.get(this, 'interest') ? true : false;
    }),
    scoreStyle: Ember.computed('interest', function () {
      if (Ember.get(this, 'interest.relevance')) {
        return Ember.String.htmlSafe("width: ".concat(Ember.get(this, 'interest.relevance'), "%;"));
      } else {
        return Ember.String.htmlSafe('width: 0%;');
      }
    }),
    bannerStyle: Ember.computed('interest', 'network', function () {
      return Ember.String.htmlSafe('');
    }),
    mouseLeave: function mouseLeave() {
      Ember.set(this, 'interest', null);
    } /* event*/
  });
});