define("affinio/controllers/reports", ["exports", "affinio/utils/affinio"], function (_exports, _affinio) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    queryParams: ['export'],
    export: null,
    app: Ember.inject.service(),
    api: Ember.inject.service(),
    notify: Ember.inject.service(),
    localStorage: Ember.inject.service(),
    exportObserver: Ember.observer('export', function () {
      var _this = this;
      var filename = Ember.get(this, 'export');
      if (filename) {
        // NOTE: jquery ajax calls do not support blob so we use XMLHttpRequest
        // https://stackoverflow.com/questions/34586671/download-pdf-file-using-jquery-ajax/34587987#34587987
        var req = new XMLHttpRequest();
        req.open('GET', "".concat(Ember.get(this, 'app.ENV.hosts.api'), "/exportscreenshots/").concat(filename), true);
        req.setRequestHeader('Authorization', Ember.get(this, 'localStorage.affinioAuth'));
        req.responseType = 'blob';
        req.onload = function () {
          var blob = req.response;
          var dataUrl = window.URL.createObjectURL(blob);
          filename = "".concat(filename, ".zip");
          (0, _affinio.downloadURL)(blob, dataUrl, filename);
          Ember.set(_this, 'export', null);
        };
        req.onreadystatechange = function () {
          if (req.readyState === 4) {
            if (req.status === 200) {
              Ember.get(_this, 'notify').success('Downloading Export Zipfile');
            } else {
              Ember.get(_this, 'notify').error('Error Downloading Export Zipfile');
            }
          }
        };
        req.send();
      }
    })
  });
});