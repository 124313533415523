define("affinio/routes/reports/report/version/cluster/category/subcategory", ["exports", "affinio/mixins/document-title", "affinio/mixins/metrics-route"], function (_exports, _documentTitle, _metricsRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
  var _default = _exports.default = Ember.Route.extend(_documentTitle.default, _metricsRoute.default, {
    path: Ember.inject.service(),
    modalManager: Ember.inject.service(),
    tracker: Ember.inject.service(),
    model: function model(_ref) {
      var _this = this;
      var subcategory = _ref.subcategory;
      var is404 = subcategory === '404';
      var checkedForValidSubcats = false;
      var report = this.modelFor('reports.report');
      var version = this.modelFor('reports.report.version');
      var cluster = this.modelFor('reports.report.version.cluster');
      var category = this.modelFor('reports.report.version.cluster.category');
      var subcategoryRecord = is404 ? null : Ember.get(this, 'store').peekRecord('taxonomies/subcategory', "".concat(Ember.get(report, 'socialNetwork') !== 'Custom' ? Ember.get(report, 'socialNetwork') : Ember.get(report, '_taxonomy.id'), "_").concat(Ember.get(category, 'value'), "_").concat(subcategory));
      var tryValidInstead = function tryValidInstead() {
        // console.log('checking validity of', subcategory);
        // console.log('record', subcategoryRecord);
        var validSubcats = Ember.get(category, 'subcategories').filter(function (subcat) {
          return subcat.dependencyCheck({
            report: report,
            cluster: cluster
          });
        });
        // console.log('other valid subcats are', validSubcats);
        if (validSubcats.length) {
          _this.transitionTo('reports.report.version.cluster.category.subcategory', Ember.get(validSubcats, '0.value'));
        } else {
          is404 = true;
          checkedForValidSubcats = true;
        }
      };

      // Ensure this subcategory is within the schema
      if (!subcategoryRecord) {
        is404 = true;
      }
      // Ensure that the dependency check results in valid
      // If not redirect to a valid subcat, or 404
      else if (!subcategoryRecord.dependencyCheck({
        report: report,
        cluster: cluster
      })) {
        // see if there are any valid subcategories, transition there
        tryValidInstead();
      }

      // See if there are any valid subcategories in the category instead of a 404 page
      if (is404 && category && !checkedForValidSubcats) {
        tryValidInstead();
      }

      // Update the path service
      // TODO lets see if we can refactor away from path references, and use report-state instead
      Ember.setProperties(Ember.get(this, 'path'), {
        subcategoryCache: Ember.get(this, 'path.currentSubcategory'),
        currentCategory: category ? Ember.get(category, 'value') : null,
        currentSubcategory: is404 ? null : subcategory
      });

      // Update this route's category/subcategory properties
      // which is used for woopra tracking in application route
      // TODO we should do something better
      Ember.setProperties(this, {
        category: Ember.get(category, 'label'),
        subcategory: Ember.get(subcategoryRecord, 'label')
      });
      var reportState = Ember.get(this, 'store').peekRecord('report-state', Ember.get(report, 'id'));
      if (reportState) {
        // We update reportState with our routes model waterfall
        Ember.setProperties(reportState, {
          version: version,
          cluster: cluster,
          category: category,
          subcategory: is404 ? '404' : subcategoryRecord
        });
        return reportState;
      } else {
        // update label map
        var metricDefinitions = Ember.get(report, 'metricDefinitions');
        var metricLabelMap = new Map();
        metricDefinitions.forEach(function (metricDef) {
          var metricLabel = metricDef.label;
          var _metricDef$referencin = _slicedToArray(metricDef.referencingModules, 1),
            firstModule = _metricDef$referencin[0];
          if (firstModule && firstModule.label !== metricLabel) {
            metricLabelMap.set(metricLabel, firstModule.label); // eslint-disable-line ember/use-ember-get-and-set
          }
        });
        return Ember.get(this, 'store').createRecord('report-state', {
          id: Ember.get(report, 'id'),
          report: report,
          version: version,
          cluster: cluster,
          category: category,
          subcategory: is404 ? '404' : subcategoryRecord,
          metricLabelMap: metricLabelMap
        });
      }
    },
    actions: {
      willTransition: function willTransition(transition) {
        // Don't do the transition if you are trying to transition to category level that you are currently in (will get a blank page)
        if (transition.intent.name === 'reports.report.version.cluster.category' && Ember.get(this.controller, 'model.category.value') === transition.params['reports.report.version.cluster.category'].category) {
          transition.abort();
        }
      },
      toggleVersionModal: function toggleVersionModal() {
        var _this2 = this;
        Ember.get(this, 'modalManager').openModal('modals/modal-version-picker', {
          report: Ember.get(this.controller, 'model.report'),
          loadVersion: function loadVersion(v) {
            Ember.get(_this2, 'modalManager').closeModal(); // TODO lets make the UX a bit better - Joel
            _this2.send('changeVersion', v);
          }
        });
      },
      changeVersion: function changeVersion(v) {
        Ember.get(this, 'tracker').track('Loading different monitored version', {
          version: v
        });
        var reportState = Ember.get(this.controller, 'model');
        this.transitionTo('reports.report.version.cluster.category.subcategory', Ember.get(reportState, 'report.id'), v, Ember.get(reportState, 'cluster.id'), Ember.get(reportState, 'category.value'), Ember.get(reportState, 'subcategory.value'));
      }
    }
  });
});