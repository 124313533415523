define("affinio/mixins/input-module", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    classNameBindings: ['required:required'],
    init: function init() {
      this._super.apply(this, arguments);
      if (Ember.get(this, 'moduleSchema.requiredFilters')) {
        var dependencyString = "model.filters.{".concat(Ember.get(this, 'moduleSchema.requiredFilters').map(function (filter) {
          return "".concat(filter, ".[]");
        }).join(','), "}");
        Ember.defineProperty(this, 'required', Ember.computed(dependencyString, function () {
          var _this = this;
          return !Ember.get(this, 'moduleSchema.requiredFilters').every(function (filterKey) {
            return Ember.isPresent(Ember.get(_this, "model.filters.".concat(filterKey)));
          });
        }));
      }
    }
  });
});