define("affinio/templates/components/modals/modal-asset-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "jczVrUfJ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"form\"],[11,\"class\",\"modal\"],[11,\"tabindex\",\"0\"],[12,\"style\",[21,\"accentStlye\"]],[9],[0,\"\\n  \"],[7,\"a\"],[11,\"class\",\"close-modal\"],[11,\"role\",\"button\"],[9],[0,\"×\"],[3,\"action\",[[22,0,[]],\"close\"]],[10],[0,\"\\n  \"],[7,\"h3\"],[9],[1,[21,\"title\"],false],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"message\"]]],null,{\"statements\":[[0,\"    \"],[7,\"p\"],[9],[1,[21,\"message\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"section\"],[11,\"class\",\"modal-main\"],[9],[0,\"\\n    \"],[7,\"section\"],[11,\"class\",\"main-ui\"],[9],[0,\"\\n      \"],[1,[27,\"asset-group\",null,[[\"model\",\"index\",\"inputValue\",\"isToolbox\",\"toolboxID\"],[[23,[\"assetGroup\"]],[23,[\"index\"]],[23,[\"inputValue\"]],[23,[\"isToolbox\"]],[23,[\"toolboxID\"]]]]],false],[0,\"\\n      \"],[7,\"footer\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"errorMessage\"]]],null,{\"statements\":[[0,\"          \"],[7,\"p\"],[11,\"class\",\"warning\"],[9],[0,\"\\n            \"],[1,[27,\"font-awesome\",[\"exclamation-triangle\"],null],false],[0,\"\\n            You have an unsaved benchmark. If you don't need this press \"],[1,[21,\"okText\"],false],[0,\" again to continue without it.\\n          \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[7,\"a\"],[11,\"class\",\"button primary\"],[11,\"role\",\"button\"],[9],[1,[21,\"okText\"],false],[3,\"action\",[[22,0,[]],\"ok\"]],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[3,\"action\",[[22,0,[]],\"ok\"],[[\"on\"],[\"submit\"]]],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"backdrop\"],[11,\"role\",\"button\"],[9],[3,\"action\",[[22,0,[]],\"close\"]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/modals/modal-asset-group.hbs"
    }
  });
});