define("affinio/utils/mutations/integrations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.pushReportClusterToTalkWalkerTopicQuery = _exports.pushReportClusterToTalkWalkerSourcePanel = _exports.default = void 0;
  var pushReportClusterToTalkWalkerSourcePanel = _exports.pushReportClusterToTalkWalkerSourcePanel = function pushReportClusterToTalkWalkerSourcePanel(reportID, version, clusterIDs, projectId) {
    return {
      queryString: "mutation {\n      pushReportClusterToTalkWalkerSourcePanel(\n        reportID: \"".concat(reportID, "\",\n        version:  ").concat(version, ",\n        clusterIDs: [").concat(clusterIDs.map(function (id) {
        return "\"".concat(id, "\"");
      }).join(', '), "],\n        projectId: \"").concat(projectId, "\"\n      )\n    }")
    };
  };
  var pushReportClusterToTalkWalkerTopicQuery = _exports.pushReportClusterToTalkWalkerTopicQuery = function pushReportClusterToTalkWalkerTopicQuery(reportID, version, clusterIDs, projectId, topicType) {
    return {
      queryString: "mutation {\n      pushReportClusterToTalkWalkerTopicQuery(\n        reportID: \"".concat(reportID, "\",\n        version:  ").concat(version, ",\n        clusterIDs: [").concat(clusterIDs.map(function (id) {
        return "\"".concat(id, "\"");
      }).join(', '), "],\n        projectId: \"").concat(projectId, "\",\n        topicType: \"").concat(topicType, "\" \n      )\n    }")
    };
  };
  var _default = _exports.default = {
    pushReportClusterToTalkWalkerSourcePanel: pushReportClusterToTalkWalkerSourcePanel,
    pushReportClusterToTalkWalkerTopicQuery: pushReportClusterToTalkWalkerTopicQuery
  };
});