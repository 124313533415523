define("affinio/components/modules/audience-growth", ["exports", "affinio/mixins/affinio-module"], function (_exports, _affinioModule) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_affinioModule.default, {
    // Element
    classNames: ['audience-growth', 'card'],
    tagName: 'div',
    path: Ember.inject.service(),
    preferences: Ember.inject.service()
  });
});