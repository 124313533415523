define("affinio/components/word-cloud", ["exports", "affinio/utils/get-with-default"], function (_exports, _getWithDefault) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MIN_FONT_SIZE = 30;
  var MAX_FONT_SIZE = 80;
  var MAX_TEXT_LENGTH = 30;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['word-cloud', 'clearfix'],
    didInsertElement: function didInsertElement() {
      this.send('draw');
    },
    // didinsertelement
    didReceiveAttrs: function didReceiveAttrs() {
      if (this.$()) {
        this.send('draw');
      }
    },
    metricTitle: Ember.computed.alias('metric.title'),
    words: Ember.computed('source', 'sizeWordCloudBy', 'recordsMap', function () {
      var recordsMap = Ember.get(this, 'recordsMap');
      var cluster = Ember.get(this, 'metric.cluster');
      var isHashtag = Ember.get(this, 'metricTitle') === 'Hashtags';
      var isReddit = Ember.get(this, 'socialNetwork') === 'Reddit';
      var sizeBy = Ember.get(this, 'sizeWordCloudBy');
      return (0, _getWithDefault.default)(this, 'source', []).map(function (item) {
        var isLinkLike = item.url || isReddit && (item.label.startsWith('http://') || item.label.startsWith('https://'));
        var text = isLinkLike ? item.title || item.label || item.url : item.label;
        if (isHashtag && !item.label.startsWith('#')) {
          text = "#".concat(item.label);
        }
        if (!text) {
          text = '';
        }
        text = text.length > MAX_TEXT_LENGTH ? "".concat(text.substring(0, MAX_TEXT_LENGTH), "...") : text;
        /* eslint-disable */
        // js map has .get
        if (recordsMap) {
          var record = recordsMap.get(Ember.get(item, 'label'));
          /* eslint-enable */
          var timesMoreLikely = (0, _getWithDefault.default)(item, 'value', 0);
          if (record) {
            var recordSpot = record.findBy('cluster', Ember.get(cluster, 'suffix'));
            if (recordSpot) {
              if (Ember.get(recordSpot, 'timesMoreLikely.isInfinite')) {
                timesMoreLikely = 1000;
              } else {
                timesMoreLikely = Ember.get(recordSpot, 'timesMoreLikely.value');
              }
            }
          }
          // eslint-disable-next-line ember/no-side-effects
          Ember.set(item, 'timesMoreLikely', timesMoreLikely);
          if (isFinite(timesMoreLikely)) {
            return {
              text: text,
              size: Ember.get(item, sizeBy)
            };
          }
        }
      }).compact();
    }),
    getLinkPrefix: function getLinkPrefix(linkType) {
      var ret = '';
      switch (linkType) {
        case 'twitterHastag':
          ret = 'https://twitter.com/search?q=%23';
          break;
        case 'instaHashtag':
          ret = 'https://instagram.com/explore/tags/';
          break;
        case 'twitterHandle':
          ret = 'https://twitter.com/';
          break;
        case 'instaHandle':
          ret = 'https://instagram.com/';
          break;
      }
      return ret;
    },
    actions: {
      draw: function draw() {
        var _this = this;
        var words = Ember.get(this, 'words');
        var linkType = Ember.get(this, 'linkType');
        var width = Ember.get(this, 'width') || 800;
        var height = Ember.get(this, 'height') || 600;
        var element = this.$();
        element.find('svg').remove();
        var logScale = d3.scale.linear().domain([_.min(words.mapBy('size')), _.max(words.mapBy('size'))]).range([MIN_FONT_SIZE, MAX_FONT_SIZE]);
        var logScaleThickness = d3.scale.linear().domain([0, 100]).range([100, 900]);
        var svg = d3.select(element[0]).append('svg').attr('viewBox', "0 0 ".concat(width, " ").concat(height)).append('g').attr('transform', "translate(".concat(width / 2, ", ").concat(height / 2, ")"));
        var layout = d3.layout.cloud().size([width, height]).words(words).padding(5).rotate(0).fontSize(function (d) {
          return logScale(d.size);
        }).on('end', function () {
          var opacityScale = d3.scale.linear().domain([_.min(words.mapBy('size')), _.max(words.mapBy('size'))]).range([0.5, 1]);
          var vis = svg.selectAll('text').data(words.sortBy('size').reverse()).enter();
          if (linkType) {
            vis.append('a').attr('xlink:href', function (d) {
              return "".concat(_this.getLinkPrefix(linkType)).concat(d.text.slice(1));
            }).attr('target', '_blank');
          }
          vis.append('text').style('font-size', function (d) {
            return "".concat(d.size, "px");
          }).style('font-weight', function (d) {
            return Math.round(logScaleThickness(d.size) / 100) * 100;
          }).style('fill-opacity', function (d) {
            return opacityScale(d.size);
          }).attr('text-anchor', 'middle').attr('transform', function (d) {
            return "translate(".concat(d.x, ", ").concat(d.y, ")rotate(").concat(d.rotate, ")");
          }).text(function (d) {
            return d.text;
          });
        });
        layout.start();
      }
    }
  });
});