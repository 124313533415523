define("affinio/templates/components/instagram-stream", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "NKx24xqB",
    "block": "{\"symbols\":[\"image\",\"&default\"],\"statements\":[[4,\"if\",[[23,[\"images\"]]],null,{\"statements\":[[0,\"  \"],[7,\"h4\"],[9],[0,\"The following images come from real-time top Instagram posts tagged in a similar way. These images were not posted by members of the current audience or cluster, so actual context and timing may differ from Facebook intent.\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"each\",[[23,[\"images\"]]],null,{\"statements\":[[0,\"  \"],[7,\"img\"],[11,\"class\",\"ig-loaded-image\"],[12,\"src\",[27,\"concat\",[\"http://instagram.com/p/\",[22,1,[]],\"/media\"],null]],[11,\"alt\",\"image\"],[9],[10],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"  \"],[7,\"span\"],[9],[0,\"No Images in Stream\"],[10],[0,\"\\n\"]],\"parameters\":[]}],[4,\"if\",[[23,[\"loading\"]]],null,{\"statements\":[[0,\"  \"],[1,[21,\"loading-spinner\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[14,2],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/instagram-stream.hbs"
    }
  });
});