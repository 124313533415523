define("affinio/templates/components/modules/traits-summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "z6HecwO8",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[22,0,[]]]],[0,\"\\n\"],[1,[27,\"cluster-bar-graph\",null,[[\"model\",\"source\",\"version\",\"id\",\"metric\",\"isAffinity\",\"network\",\"singular\",\"currentCluster\"],[[23,[\"model\"]],[23,[\"model\",\"report\",\"clusters\"]],[23,[\"model\",\"version\"]],[23,[\"model\",\"report\",\"id\"]],[23,[\"metric\"]],[27,\"is-equal\",[[23,[\"metric\"]],\"interestAffinity\"],null],[23,[\"model\",\"report\",\"socialNetwork\"]],true,[23,[\"model\",\"cluster\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "affinio/templates/components/modules/traits-summary.hbs"
    }
  });
});