define("affinio/components/score-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['score-cell'],
    classNameBindings: ['isHidden'],
    attributeBindings: ['style', 'title'],
    style: Ember.computed('shadeBy', 'isTML', 'isLocationTimesMoreLikely', 'isLocationRelevance', 'colorCluster', 'locationtmlCluster', 'locationscoreCluster', 'tmlCluster', function () {
      var color;
      if (Ember.get(this, 'isLocationTimesMoreLikely')) {
        var colorCluster = Ember.get(this, 'shadeBy') === 'column' ? Ember.get(this, 'tribe.locationtmlcolor') : Ember.get(this, 'tribe.locationtmlCluster');
        color = colorCluster;
      } else if (Ember.get(this, 'isLocationRelevance')) {
        var _colorCluster = Ember.get(this, 'shadeBy') === 'column' ? Ember.get(this, 'tribe.locationscorecolor') : Ember.get(this, 'tribe.locationscoreCluster');
        color = _colorCluster;
      } else if (Ember.get(this, 'isTML')) {
        var _colorCluster2 = Ember.get(this, 'shadeBy') === 'column' ? Ember.get(this, 'tribe.tmlcolor') : Ember.get(this, 'tribe.tmlCluster');
        color = _colorCluster2;
      } else {
        var _colorCluster3 = Ember.get(this, 'shadeBy') === 'column' ? Ember.get(this, 'tribe.color') : Ember.get(this, 'tribe.colorCluster');
        color = _colorCluster3;
      }
      return Ember.String.htmlSafe(color);
    })
  });
});