define("affinio/components/content-editable", ["exports", "ember-content-editable/components/content-editable"], function (_exports, _contentEditable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _contentEditable.default;
    }
  });
});