define("affinio/models/query", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var Model = _emberData.default.Model;
  function getReadableString(queryNode) {
    var showParenthesis = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    if (queryNode.branches) {
      var useParenthesis = showParenthesis && (queryNode.branches.some(function (branch) {
        return !branch.hasOwnProperty('value');
      }) // there are sub branches
      || queryNode.branches.every(function (branch) {
        return branch.hasOwnProperty('value');
      }) && queryNode.branches.length > 1);
      return "".concat(queryNode.op === 'NOT' ? 'NOT ' : '').concat(useParenthesis ? '(' : '').concat(queryNode.branches.map(function (n) {
        var readable = getReadableString(n, showParenthesis);
        if (readable.startsWith('NOT ((') && readable.endsWith('))')) {
          readable = "NOT (".concat(readable.slice(6, -2), ")");
        }
        return readable;
      }).join(" ".concat(queryNode.op, " "))).concat(useParenthesis ? ')' : '');
    } else {
      // Leaf
      return queryNode.value || '';
    }
  }
  var _default = _exports.default = Model.extend({
    pinned: attr(),
    query: attr(),
    sourceId: attr(),
    parsedQueries: attr(),
    queryString: Ember.computed('parsedQueries', function () {
      return JSON.parse(Ember.get(this, 'parsedQueries')).map(function (n) {
        return getReadableString(n, true);
      })
      // Remove wrapping parens
      // TODO incorporate to getReadableString somehow
      .map(function (n) {
        if (n[0] === '(' && n[n.length - 1] === ')') {
          return n.slice(1, -1);
        }
        return n;
      }).join(' AND ');
    })
  });
});